import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props<T extends string> = {
  name: T;
  title: string;
  index: number;
  currentStep: { name: T; index: number };
  lastStepIndex: number;
  goToStep: (name: T) => void;
  disabled?: boolean;
  isStepNumberVisible?: boolean;
  isVertical?: boolean;
};

const Step = <T extends string>({
  name,
  index,
  currentStep,
  lastStepIndex,
  goToStep,
  disabled = false,
  title,
  isStepNumberVisible = false,
  isVertical,
}: Props<T>) => {
  const { t } = useTranslation();
  const isCurrentStep = currentStep.index === index;
  const isDoneStep = index < currentStep.index;
  const isLastStep = index === lastStepIndex;
  const isEditAllowed = isDoneStep && !disabled && !isVertical;

  const goToDoneStep = () => {
    goToStep(name);
  };

  return (
    <li className={cn({ [styles.vertical]: isVertical })}>
      <span
        className={cn(styles.status, {
          [styles.status__done]: isDoneStep,
          [styles.status__current]: isCurrentStep,
          [styles.status__last]: isLastStep,
        })}
      >
        {isDoneStep ? <IconSVG name={IconsNames.check_mark} /> : index + 1}
      </span>
      <p className={styles.title}>
        {isEditAllowed && (
          <button type="button" onClick={goToDoneStep}>
            <IconSVG name={IconsNames.edit} />
          </button>
        )}
        <span>
          {isStepNumberVisible && (
            <>
              {t('campaign.step')} {index + 1}
            </>
          )}
          <span className={styles.title__name}>{t(title)}</span>
        </span>
      </p>
      <div
        className={cn(styles.line, {
          [styles.line__done]: isDoneStep,
          [styles.line__current]: isCurrentStep,
          [styles.line__last]: isLastStep,
        })}
      />
    </li>
  );
};

export default Step;
