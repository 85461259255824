import useEscapeEvent from 'hooks/useEscapeEvent';
import useModal from 'contexts/ModalContext';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const ModalWrapper: React.FC = ({ children }) => {
  const { closeModal } = useModal();
  useEscapeEvent(closeModal);

  return (
    <div className={styles.container}>
      <button type="button" className={styles.close} onClick={closeModal}>
        <IconSVG name={IconsNames.delete} className={styles.svg} />
      </button>
      {children}
    </div>
  );
};

export default ModalWrapper;
