import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const initializeSentry = () => {
  if (process.env.REACT_APP_SENTRY_KEY && process.env.REACT_APP_SENTRY_ID) {
    Sentry.init({
      dsn: `https://${process.env.REACT_APP_SENTRY_KEY}.ingest.sentry.io/${process.env.REACT_APP_SENTRY_ID}`,
      integrations: [new BrowserTracing()],
      environment: process.env.REACT_APP_ENV,
      tracesSampleRate: 1.0,
    });
  }
};
