import { Navigate, Outlet } from 'react-router-dom';

import { PrivatePaths } from 'constants/routes';

import useAuth from 'contexts/AuthContext';

type Props = {
  isAllowed: boolean;
};

const ProtectedRoute: React.FC<Props> = ({ isAllowed, children }) => {
  const { user } = useAuth();

  if (user?.current_group_name && !isAllowed) {
    return <Navigate to={PrivatePaths.INDEX} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
