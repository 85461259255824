import { useMutation } from 'react-query';

import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';

const useDeleteContactsReport = () => {
  const { axios } = useAuth();

  const mutation = useMutation<
    void,
    AxiosError,
    { campaignId: string | number; fileId: string | number }
  >(async ({ campaignId, fileId }) => {
    try {
      await axios.delete(
        `/vendor-campaigns/${campaignId}/contacts-uploads/${fileId}/`
      );
    } catch (error) {
      throw error;
    }
  });

  return mutation;
};

export default useDeleteContactsReport;
