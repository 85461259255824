import type { AxiosError } from 'axios';

type ErrorResponse = {
  [x: string]: string[] | string;
};

export default (error: AxiosError): string => {
  const data: ErrorResponse = error.response?.data;
  if (!data) return error.message;

  const errorKeys = Object.keys(data);
  const firstErrorData =
    errorKeys?.length &&
    typeof error?.response?.data === 'object' &&
    data[errorKeys[0]];
  const message = Array.isArray(firstErrorData)
    ? firstErrorData[0]
    : firstErrorData;

  return message || error.message;
};
