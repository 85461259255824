import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import type { Milestone } from 'types/models';

import useConvertCampaignData from 'hooks/useConvertCampaignData';

import { MilestoneType } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  selectedMilestone?: { id: string | undefined; date: string | undefined };
  setSelectedMilestone: (milestone: {
    id: string | undefined;
    date: string | undefined;
  }) => void;
  milestones: Milestone[];
  milestoneDeadlineType: MilestoneType;
  setPage: (page: number) => void;
};

const MilestoneTab = ({
  selectedMilestone,
  setSelectedMilestone,
  milestones,
  milestoneDeadlineType,
  setPage,
}: Props) => {
  const { t } = useTranslation();
  const { convertMilestoneDate } = useConvertCampaignData();

  const inputHandler = ({
    currentTarget: { value },
    target: { parentNode },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMilestone({
      id: value,
      date: parentNode?.textContent || '',
    });
    setPage(1);
  };

  return (
    <ul className={cn(styles.tabs, styles.milestones)}>
      <li className={styles.milestone}>
        <label
          htmlFor="milestone-all"
          className={cn({
            [styles.active]: !selectedMilestone?.id,
          })}
        >
          <input
            type="radio"
            id="milestone-all"
            onChange={inputHandler}
            checked={!selectedMilestone?.id}
            value={undefined}
          />
          {t('common.field.all')}
        </label>
      </li>

      {milestones.map((milestone, index) => {
        const isChecked = selectedMilestone?.id === milestone.id.toString();

        return (
          <li key={milestone.id} className={styles.milestone}>
            <label
              key={milestone.id}
              htmlFor={`milestone-${milestone.id}`}
              className={cn({
                [styles.active]: isChecked,
              })}
            >
              <input
                type="radio"
                id={`milestone-${milestone.id}`}
                onChange={inputHandler}
                checked={isChecked}
                value={milestone.id}
              />
              {convertMilestoneDate(
                milestoneDeadlineType,
                milestone.delivery_date,
                index === milestones.length - 1
              )}
            </label>
          </li>
        );
      })}
    </ul>
  );
};

export default MilestoneTab;
