import cn from 'classnames';
import { useFormContext, useController } from 'react-hook-form';

import type { OffersFormValues } from 'components/CampaignManage/models';

import InputWrapper from 'components/common/InputWrapper';
import NumberField from 'components/common/NumberField';
import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  isEditable?: boolean;
  milestoneIndex: number;
  offerIndex: number;
  assetIndex: number;
  assetTitle: string;
  difference: number;
  leadsFieldName: 'leads_required' | 'leads_required_vendor';
  isLeadsSumError: boolean;
};

const MilestoneAssetItem = ({
  isEditable,
  milestoneIndex,
  assetIndex,
  assetTitle,
  offerIndex,
  difference,
  leadsFieldName,
  isLeadsSumError,
}: Props) => {
  const { control, trigger } = useFormContext<OffersFormValues>();
  const { field, fieldState } = useController({
    control,
    name: `offers.${offerIndex}.value.campaign_offer_milestones.${milestoneIndex}.campaign_offer_assets.${assetIndex}.${leadsFieldName}`,
  });

  const isError = isEditable && difference < 0 && ' ';

  const handleLeadsCountChange = (value?: string | number) => {
    if (isLeadsSumError) {
      trigger(
        `offers.${offerIndex}.value.campaign_offer_milestones.${milestoneIndex}.${leadsFieldName}`
      );
    }
    if (fieldState.error?.message) {
      trigger(
        `offers.${offerIndex}.value.campaign_offer_milestones.${milestoneIndex}.campaign_offer_assets.${assetIndex}.${leadsFieldName}`
      );
    }
    field.onChange(value);
  };

  return (
    <div className={styles.assets_item}>
      <InputWrapper
        isSmallInput
        isErrorHidden={!fieldState.error}
        validationError={isError || fieldState.error?.message}
        wrapperClasses={cn({
          [styles['label-error']]:
            isError || isLeadsSumError || fieldState.error,
        })}
      >
        <div className={cn(styles.assets_item, styles['assets_item-editable'])}>
          <span className={styles.assets_name}>{assetTitle}</span>
          <NumberField
            field={{ ...field, onChange: handleLeadsCountChange }}
            disabled={!isEditable}
            max={(field.value || 0) + difference}
          />
          {isEditable && (
            <span
              className={cn(styles.difference, {
                [styles.error]: isError,
              })}
            >
              /{difference}
              {isError && (
                <IconSVG name={IconsNames.error} className={styles.icon} />
              )}
            </span>
          )}
        </div>
      </InputWrapper>
    </div>
  );
};

export default MilestoneAssetItem;
