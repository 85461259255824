import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const useIncreaseAssetDownloads = ({
  email,
  assetId,
  companySlug,
}: {
  email?: string;
  assetId: number;
  companySlug?: string;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(async () => {
    try {
      if (!email || !companySlug) return;

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/companies/${companySlug}/product-asset-downloads/`,
        { email, product_asset: assetId }
      );
      queryClient.refetchQueries('get-product-assets');
    } catch (err) {
      throw err;
    }
  });

  return mutation;
};
