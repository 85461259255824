import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useQueryClient, useMutation } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';

import type { OfferResponse } from 'types/models';
import type { AxiosError } from 'axios';
import type { UseFormSetValue } from 'react-hook-form';
import {
  OfferAction,
  OffersFormValues,
} from 'components/CampaignManage/models';

import InputWrapper from 'components/common/InputWrapper';
import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import { validationSchemaDeclineOffer } from 'utils/validations';

import { UserTypes } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  campaignName: string;
  offerId: number;
  campaignId: number;
  setFormValue: UseFormSetValue<OffersFormValues>;
  offersValues: OffersFormValues;
};

type FormValues = {
  comment: string;
};

const DeclineModal = ({
  campaignName,
  offerId,
  campaignId,
  setFormValue,
  offersValues,
}: Props) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const { user, axios } = useAuth();
  const queryClient = useQueryClient();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchemaDeclineOffer),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const isVendor = user?.current_group_name === UserTypes.vendor;

  const { isLoading, error, mutate } = useMutation<
    OfferResponse,
    AxiosError,
    string
  >(
    async (comment: string) => {
      try {
        const { data } = await axios.post<OfferResponse>(
          `/${
            isVendor ? 'vendor-campaigns' : 'campaigns'
          }/${campaignId}/offers/${offerId}/decline/`,
          isVendor ? { comment_vendor: comment } : { comment }
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async response => {
        const queryKey = isVendor
          ? ['vendor-offers', user?.id.toString(), campaignId.toString()]
          : [
              'vendor-manager-offers',
              campaignId.toString(),
              user?.company.id.toString(),
            ];
        queryClient.invalidateQueries(
          isVendor ? 'vendor-campaigns-list' : 'campaign-list'
        );

        queryClient.setQueryData<OfferResponse[]>(queryKey, oldData => {
          return (
            oldData?.map(offer => {
              const isCurrentOffer = offer.id === response.id;

              return {
                ...offer,
                status: isCurrentOffer ? response.status : offer.status,
                last_responded_by: isCurrentOffer
                  ? response.last_responded_by
                  : offer.last_responded_by,
                comment: isCurrentOffer ? response.comment : offer.comment,
                comment_vendor: isCurrentOffer
                  ? response.comment_vendor
                  : offer.comment_vendor,
              };
            }) || [response]
          );
        });

        const newValues = offersValues.offers.map(offer => {
          const isCurrentOffer = offer.value.id === response.id;

          return {
            ...offer,
            value: {
              ...offer.value,
              status: isCurrentOffer ? response.status : offer.value.status,
              last_responded_by: isCurrentOffer
                ? response.last_responded_by
                : offer.value.last_responded_by,
              comment: isCurrentOffer ? response.comment : offer.value.comment,
              comment_vendor: isCurrentOffer
                ? response.comment_vendor
                : offer.value.comment_vendor,
            },
          };
        });
        setFormValue('offers', newValues);

        closeModal();
      },
    }
  );

  const handleFormSubmit = (values: FormValues) => {
    mutate(values.comment);
  };

  const comment = watch('comment');

  return (
    <>
      {isLoading && <LoaderScreen />}
      <ModalWindow
        primaryTitle={campaignName}
        title={t('offers.confirm-action-question', {
          action: OfferAction.decline,
        })}
        positiveButtonProps={{
          children: t('common.button.yes'),
          red: true,
          type: 'submit',
          disabled: !comment,
          onClick: () => {
            mutate(getValues().comment);
          },
        }}
        negativeButtonProps={{
          children: t('common.button.cancel'),
          onClick: closeModal,
        }}
        className={styles.modal}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <InputWrapper validationError={errors?.comment?.message}>
            <TextareaAutoHeight
              methods={register('comment')}
              control={control}
              placeholder={`${t('offers.comment')}...`}
            />
          </InputWrapper>
        </form>
        {error && <Error message={getResponseError(error)} />}
      </ModalWindow>
    </>
  );
};

export default DeclineModal;
