import { useTranslation } from 'react-i18next';

import AuthHeader from 'components/common/AuthHeader';

import styles from './index.module.scss';

const TwoFAWrapper: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <AuthHeader hideLink title={t('auth.set-two-factor')} />
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default TwoFAWrapper;
