import { usePopperTooltip } from 'react-popper-tooltip';
import { useTranslation } from 'react-i18next';

import SimpleTooltip from 'components/UI/SimpleTooltip';
import Button from 'components/common/Button';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  onClick: () => void;
};

const AddQuestionButton = ({ onClick }: Props) => {
  const { t } = useTranslation();
  const tooltipProps = usePopperTooltip({ placement: 'top' });

  return (
    <>
      <Button
        white
        iconProps={{ name: IconsNames.plus }}
        className={styles.button}
        onClick={onClick}
        setTooltipTriggerRef={tooltipProps.setTriggerRef}
        aria-label="add script question"
      />
      {tooltipProps.visible && (
        <SimpleTooltip options={tooltipProps}>
          {t('common.button.add-question')}
        </SimpleTooltip>
      )}
    </>
  );
};

export default AddQuestionButton;
