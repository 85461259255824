import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import InputWrapper from 'components/common/InputWrapper';

import UploadSingleFile from 'components/CreateCampaign/Step1/LeadDetails/UploadSingleFile';
import BlockHeader from 'components/CreateCampaign/Step1/BlockHeader';
import BlockWrapper from 'components/CreateCampaign/Step1/BlockWrapper';

import useGetLeadFile from 'components/CreateCampaign/useGetLeadFile';
import useGetReportTemplate from 'components/CreateCampaign/useGetReportTemplate';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const LeadDetails = () => {
  const { t } = useTranslation();
  const { control, register, getValues, setValue } =
    useFormContext<CreateCampaignValues>();
  const { isLoading: isLeadFileLoading } = useGetLeadFile({
    campaign: getValues(),
    setFormValue: setValue,
  });
  const { isLoading: isReportTemplateLoading } = useGetReportTemplate({
    campaign: getValues(),
    setFormValue: setValue,
  });

  const setupReportFileFormData = (file: File) => {
    const formData = new FormData();
    formData.append('report_template', file);
    formData.append('report_template_name', file.name);

    return formData;
  };

  const setupLeadFileFormData = (file: File) => {
    const formData = new FormData();
    formData.append('lead_file', file);
    formData.append('lead_file_name', file.name);

    return formData;
  };

  return (
    <>
      <BlockHeader
        heading={t('campaign.lead-details')}
        description={t('campaign.upload-and-manage-required-leads')}
        iconClassName={styles.header}
        iconName={IconsNames.user_groups}
      />

      <BlockWrapper>
        <div className={styles.checkbox}>
          <InputWrapper
            label="common.field.casl-compliant"
            wrapperClasses={styles.checkbox__label}
          >
            <input
              type="checkbox"
              {...register('campaign_details.is_casl_compliant')}
            />
          </InputWrapper>
          <InputWrapper
            label="common.field.gdpr-compliant"
            wrapperClasses={styles.checkbox__label}
          >
            <input
              type="checkbox"
              {...register('campaign_details.is_gdpr_compliant')}
            />
          </InputWrapper>
        </div>
        <div className={styles.wrapper}>
          <UploadSingleFile
            control={control}
            sign={t('common.field.single-lead-file')}
            title="common.field.lead-file"
            className={styles.upload}
            fieldName="campaign_files.lead_file"
            url={`/campaigns/${getValues().id}/lead-mapping/`}
            setupFormData={setupLeadFileFormData}
            isGetLoading={isLeadFileLoading}
          />
          <UploadSingleFile
            control={control}
            sign={t('common.field.single-report-template')}
            title="common.field.report-template"
            className={styles.upload}
            fieldName="campaign_files.report_template"
            url={`/campaigns/${getValues().id}/report-template-mapping/`}
            setupFormData={setupReportFileFormData}
            isGetLoading={isReportTemplateLoading}
          />
        </div>
      </BlockWrapper>
    </>
  );
};

export default LeadDetails;
