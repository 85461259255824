import React, { useRef, useState, forwardRef, useCallback } from 'react';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';

import type { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import type {
  OptionsOrGroups,
  GroupBase,
} from 'react-select/dist/declarations/src/types';
import type {
  MultiValue,
  ActionMeta,
  SingleValue,
  InputActionMeta,
} from 'react-select';
import type { SelectOption } from 'types/models';

import Option from 'components/UI/ReactSelect/Option';
import Menu from 'components/UI/ReactSelect/SelectAllMenu';
import Button from 'components/common/Button';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import { selectAllOptionValue, CustomDefaultValue } from 'constants/constants';

import styles from './index.module.scss';

interface Props<OptionType> extends StateManagerProps<OptionType> {
  isError?: boolean;
}

const MultiSelect = <OptionType extends SelectOption>(
  props: Omit<Props<OptionType>, 'styles'>,
  ref: React.Ref<any> | undefined
) => {
  const [isFullListVisible, setListVisibility] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { t } = useTranslation();
  const valueRef = useRef(props.value);
  valueRef.current = props.value;
  const options = (props.options as OptionType[]) || undefined;

  const selectAllOption = {
    value: selectAllOptionValue,
    label: t('common.field.all'),
  };

  const handleInputChange = (value: string, meta: InputActionMeta) => {
    if (meta.action === 'input-change') {
      setSearchValue(value);
    }
  };

  const isSelectAllSelected = () =>
    !!valueRef.current &&
    !!Array.isArray(valueRef.current) &&
    valueRef.current?.length ===
      options?.filter(item => item.value !== CustomDefaultValue.custom).length;

  const isOptionSelected = (option: OptionType) => {
    if (option.value === CustomDefaultValue.custom) return false;

    return (
      (!!valueRef.current &&
        !!Array.isArray(valueRef.current) &&
        valueRef.current?.some(({ value }) => value === option.value)) ||
      isSelectAllSelected()
    );
  };

  const getOptions = () =>
    [...(options || [])] as unknown as OptionsOrGroups<
      OptionType,
      GroupBase<OptionType>
    >;

  const getValue = () =>
    isSelectAllSelected()
      ? props.options?.filter(
          item => 'value' in item && item.value !== CustomDefaultValue.custom
        )
      : props.value;

  const onChange = (
    newValue: MultiValue<OptionType> | SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (!props.onChange) return;
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option?.value === selectAllOption.value) {
      props.onChange(
        options?.filter(item => item.value !== CustomDefaultValue.custom) || [],
        actionMeta
      );
    } else if (
      (action === 'deselect-option' &&
        option?.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        removedValue?.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === 'deselect-option' &&
      isSelectAllSelected()
    ) {
      props.onChange(
        options?.filter(
          ({ value }) =>
            value !== option?.value && value !== CustomDefaultValue.custom
        ),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };

  const toggleCountriesListVisibility = () => {
    setListVisibility(!isFullListVisible);
  };

  const isToggleButtonVisible =
    Array.isArray(props.value) && Number(props.value?.length) > 5;

  const selectAll = () => {
    if (!props.onChange) return;
    if (getValue() === options) {
      props.onChange(null, { action: 'deselect-option', option: undefined });
    } else {
      props.onChange(
        options?.filter(item => item.value !== CustomDefaultValue.custom),
        { action: 'select-option', option: undefined }
      );
    }
  };

  const CustomMenu = useCallback(
    ({ ...menuProps }) => (
      <Menu
        {...menuProps}
        selectAll={selectAll}
        isSelectAllVisible={!searchValue}
      />
    ),
    [searchValue]
  );

  const customComponents = {
    Option,
    Menu: CustomMenu,
  };

  return (
    <div className={styles.wrapper}>
      {isToggleButtonVisible && (
        <Button
          type="button"
          white
          className={styles.toggle}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            toggleCountriesListVisibility();
          }}
        >
          {isFullListVisible
            ? t('common.button.collapse')
            : t('common.button.expand')}
        </Button>
      )}
      <div
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <ReactSelect
          {...props}
          ref={ref}
          isOptionSelected={isOptionSelected}
          options={getOptions()}
          value={getValue() as MultiValue<OptionType>}
          onChange={onChange}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          isMulti
          onInputChange={handleInputChange}
          inputValue={searchValue}
          styles={getDefaultSelectStylesWithError({
            isNoBackgroundSelectedOption: true,
            error: props.isError,
            isSmall: true,
            fullHeight: isFullListVisible,
          })}
          components={customComponents}
        />
      </div>
    </div>
  );
};

export default forwardRef(MultiSelect);
