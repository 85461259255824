import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TwoFATypes, TwoFADevice } from 'components/TwoFA/models';

import ModalWindow from 'components/common/ModalWindow';
import Step1 from 'components/Profile/Security/AppAuth/Step1';
import Step2 from 'components/Profile/Security/AppAuth/Step2';

import useModal from 'contexts/ModalContext';

import styles from '../index.module.scss';

const AppAuth = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [responseData, setResponseData] = useState<TwoFADevice | null>(null);

  return (
    <ModalWindow
      title={
        <div className={styles.primaryTitle}>
          <h2>{t('auth.new-device', { type: TwoFATypes.totp })}</h2>
          <span
            className={styles.progress}
            style={{ width: `${(currentStep / 2) * 100}%` }}
          />
        </div>
      }
      className={styles.wrapper}
      closeButtonClassName={styles.close}
      onClose={closeModal}
      isLockBodyScroll
      closeOnClickAway={false}
    >
      {!responseData ? (
        <Step1
          setCurrentStep={setCurrentStep}
          setResponseData={setResponseData}
        />
      ) : (
        <Step2 device={responseData} />
      )}
    </ModalWindow>
  );
};

export default AppAuth;
