import { useQuery } from 'react-query';

import type { PaginationReturn, VendorCampaignResponse } from 'types/models';
import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';

import { PAGE_SIZE } from 'components/CampaignsListDeliveries';

import { UserTypes, OfferStatuses } from 'constants/constants';

export const useGetAcceptedCampaignsList = (page: number) => {
  const { axios, user } = useAuth();

  const query = useQuery<
    PaginationReturn<VendorCampaignResponse[]>,
    AxiosError
  >(
    ['vendor-campaigns-list', user?.current_group_name, page],
    async () => {
      try {
        const { data } = await axios.get<
          PaginationReturn<VendorCampaignResponse[]>
        >(
          `/vendor-campaigns/?page_size=${PAGE_SIZE}&page=${page}&status=${OfferStatuses.accepted}&is_qc_required=true`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    { enabled: user?.current_group_name === UserTypes.vendor }
  );

  return query;
};
