import { createContext, useState, useContext } from 'react';

export type State = null | React.ReactNode;

export type LoaderScreenState = {
  visible: boolean;
  Content: React.ReactNode | null;
};

export const AsideContext = createContext<{
  AsideContent: State;
  setAsideContent: React.Dispatch<React.SetStateAction<State>>;
}>({
  AsideContent: null,
  setAsideContent: () => {},
});

export const AsideProvider: React.FC = ({ children }) => {
  const [AsideContent, setAsideContent] = useState<State>(null);

  return (
    <AsideContext.Provider value={{ AsideContent, setAsideContent }}>
      {children}
    </AsideContext.Provider>
  );
};

const useAside = () => useContext(AsideContext);

export default useAside;
