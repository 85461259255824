import { useState } from 'react';
import PhoneInput, {
  getCountryCallingCode,
} from 'react-phone-number-input/mobile';
import { useController } from 'react-hook-form';
import Select, { components } from 'react-select';
import Flags from 'country-flag-icons/react/1x1';

import type { CSSObjectWithLabel, OptionProps, GroupBase } from 'react-select';
import type { Control } from 'react-hook-form';
import type { FlagComponent } from 'country-flag-icons/react/1x1';

import { ReactComponent as PhoneSVG } from 'assets/images/common/phone-icon.svg';

import 'react-phone-number-input/style.css';
import styles from './index.module.scss';

type Props = {
  control: Control<any>;
  name: string;
  isBasicStyles?: boolean;
  placeholder?: string;
};

const customStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    borderStyle: 'none',
    boxShadow: 'none',
    paddingLeft: 0,
    backgroundColor: 'transparent',
  }),
  valueContainer: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => {
    return {
      ...provided,
      padding: 0,
      paddingLeft: '4px',
      backgroundColor: 'transparent',
    };
  },
  indicatorContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
  }),
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    position: 'static',
    padding: 0,
    marginRight: '4px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (
    provided: CSSObjectWithLabel,
    { isSelected }: { isDisabled: boolean; isSelected: boolean }
  ) => ({
    ...provided,
    backgroundColor: isSelected ? '#f1f1f1' : '#fff',
    color: '#000',
    padding: 10,
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    zIndex: 5,
    left: 0,
  }),
};

const Option = ({ ...props }: OptionProps<any, false, GroupBase<any>>) => {
  const Flag: new () => React.Component<any, any> =
    Flags[props.data.value as keyof FlagComponent];
  return (
    <components.Option {...props}>
      <span className={styles.labelWrapper}>
        <div className={styles.left}>
          {!!Flag && <Flag className={styles.flagIcon} />}
          <span>{props.label}</span>
        </div>
        {props.data.value && (
          <span>+{getCountryCallingCode(props.data.value)}</span>
        )}
      </span>
    </components.Option>
  );
};

const PhoneField = ({ control, name, isBasicStyles, placeholder }: Props) => {
  const { field } = useController({ control, name });
  const [phone, setPhone] = useState(field.value);

  return (
    <PhoneInput
      placeholder={placeholder}
      international
      className={isBasicStyles ? styles.basic : ''}
      internationalIcon={PhoneSVG}
      value={phone}
      onChange={x => {
        field.onChange(x);
        setPhone(x);
      }}
      countrySelectComponent={props => {
        return (
          <div className={styles.wrapper}>
            <props.iconComponent
              label={props.label || ''}
              country={props.value}
            />
            <Select
              styles={customStyles}
              {...props}
              isSearchable
              onChange={({ value }) => props.onChange(value)}
              components={{ Option }}
            />
          </div>
        );
      }}
    />
  );
};

export default PhoneField;
