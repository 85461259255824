import { useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { AssetValues } from 'components/CreateCampaign/models';

import Button from 'components/common/Button';
import ImageCropper from 'components/common/ImageCropper';

import styles from './index.module.scss';

const IMAGE_WIDTH = 544;
const IMAGE_HEIGHT = 332;

const Step2 = () => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<AssetValues>();
  const {
    field: { onChange, value },
  } = useController({
    control,
    name: 'landing_image',
  });
  const { field } = useController({
    name: 'landingImageCroppingArea',
    control,
  });

  const handleChangeImage = () => {
    setValue('landing_image', { renderLink: '' });
  };

  return (
    <div className={styles.wrapper}>
      <ImageCropper
        value={value}
        setCroppedArea={field.onChange}
        imageWidth={544}
        imageHeight={332}
        uploadImage={onChange}
        className={styles.cropper}
        controllerClassName={styles.control}
        cropSize={{ width: IMAGE_WIDTH, height: IMAGE_HEIGHT }}
      />

      {value?.renderLink && (
        <Button
          type="button"
          onClick={handleChangeImage}
          className={styles.change}
          white
        >
          {t('common.button.change-image')}
        </Button>
      )}
    </div>
  );
};

export default Step2;
