import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { ProductResponse } from 'types/models';

import TopInfo from 'components/ProductPage/TopInfo';
import AboutProduct from 'components/ProductPage/AboutProduct';
import CompanyFeatures from 'components/ProductPage/CompanyFeatures';
import PricingPlans from 'components/ProductPage/PricingPlans';
import AssetShortcut from 'components/ProductPage/AssetShortcut';
import GetDemoModal from 'components/ProductPage/GetDemoModal';
import AssetsList from 'components/ProductPage/AssetsList';
import LoaderScreen from 'components/common/LoaderScreen';

import useModal from 'contexts/ModalContext';

import { AssetAttachedType, AssetContentType } from 'constants/assets';
import { IconsNames } from 'constants/constants';

import defaultLogo from 'assets/images/product/default-image.svg';

import styles from './index.module.scss';

type Props = {
  data: ProductResponse;
  refetch?: () => Promise<{ data: ProductResponse | undefined }>;
};

const ProductPage = ({ data: productData, refetch }: Props) => {
  const { t } = useTranslation();
  const { companySlug, productSlug } = useParams();
  const { openModal } = useModal();
  const [isLoaderScreenVisible, setIsLoaderScreenVisible] = useState(false);

  const contactData = productData.share_contact_data;

  const handleOpenGetDemoModal = useCallback(async () => {
    if (!productData || !contactData) return;

    if (productData.schedule_demo_link && refetch) {
      setIsLoaderScreenVisible(true);
      const { data } = await refetch();
      setIsLoaderScreenVisible(false);
      window.open(data?.schedule_demo_link, '_blank')?.focus();
    } else {
      openModal({
        Content: (
          <GetDemoModal
            productSlug={productSlug}
            companySlug={companySlug}
            companyName={productData?.company_name}
            defaultValues={{
              ...contactData,
              job_title: {
                value: contactData.job_title,
                label: contactData.job_title,
              },
            }}
          />
        ),
      });
    }
  }, [productData]);

  const assetSlug =
    contactData?.asset_type !== AssetAttachedType.product &&
    contactData?.asset_slug;
  const shortcutAssetData = productData?.product_assets?.filter(
    ({ slug }) => slug === assetSlug
  )[0];
  const isGetADemoAvailable = !!contactData && !!productData;

  return (
    <>
      <div className={styles.container}>
        <img
          src={productData?.background_image || defaultLogo}
          alt="bgImage"
          className={cn(styles.bgImage, {
            [styles.default]: productData && !productData?.background_image,
          })}
        />
        <main className={styles.flex}>
          <div className={styles.mainWidth}>
            <TopInfo
              name={productData?.name}
              productPhoto={productData?.product_photo}
            />
            {(!!productData?.description ||
              !!productData?.email ||
              !!productData?.website ||
              !!productData?.languages) && (
              <AboutProduct
                description={productData?.description}
                email={productData?.email}
                website={productData?.website}
                languages={productData?.languages}
                instagram={productData.instagram_link}
                facebook={productData.facebook_link}
                linkedin={productData.linkedin_link}
                twitter={productData.twitter_link}
              />
            )}
            {!!productData?.pricing_plans.length && (
              <PricingPlans
                pricingPlans={productData?.pricing_plans}
                isGetADemoAvailable={isGetADemoAvailable}
                onOpenGetDemo={handleOpenGetDemoModal}
                isAssetsExists={Number(productData?.product_assets.length) > 0}
              />
            )}
            <AssetsList
              companySlug={companySlug}
              contentType={AssetContentType.productVideo}
              title={t('manage-products.product-videos')}
              iconName={IconsNames.play_arrow}
              productData={productData}
            />
            <AssetsList
              companySlug={companySlug}
              contentType={AssetContentType.advertiseVideo}
              title={t('manage-products.advertise-videos')}
              iconName={IconsNames.play_arrow}
              productData={productData}
            />
            <AssetsList
              companySlug={companySlug}
              contentType={AssetContentType.whitepaperEbook}
              title={t('manage-products.whitepaper')}
              iconName={IconsNames.book}
              productData={productData}
            />
            <AssetsList
              companySlug={companySlug}
              contentType={AssetContentType.caseStudy}
              title={t('manage-products.case-studies')}
              iconName={IconsNames.case_study}
              productData={productData}
            />
            <AssetsList
              companySlug={companySlug}
              contentType={AssetContentType.webinarOnDemand}
              title={t('manage-products.webinars-on-demand')}
              iconName={IconsNames.on_demand_video}
              productData={productData}
            />
          </div>

          <div>
            <CompanyFeatures
              benefits={productData?.benefits}
              disadvantages={productData?.disadvantages}
              isGetADemoAvailable={isGetADemoAvailable}
              onOpenGetDemo={handleOpenGetDemoModal}
              buttonName={productData?.schedule_demo_button_name}
              productId={productData?.id}
              companySlug={companySlug}
              shareContactData={productData?.share_contact_data}
            />

            {assetSlug && (
              <AssetShortcut
                assetTitle={shortcutAssetData?.title}
                assetLogo={shortcutAssetData?.html_template_logo}
                assetSlug={assetSlug}
              />
            )}
          </div>
        </main>
        {productData?.privacy_policy_url && (
          <footer className={styles.footer}>
            <a
              href={productData?.privacy_policy_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('product.privacy-policy')}
            </a>
          </footer>
        )}
      </div>
      {isLoaderScreenVisible && <LoaderScreen />}
    </>
  );
};

export default ProductPage;
