import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';

import InputWrapper from 'components/common/InputWrapper';

import styles from './index.module.scss';

type Props = {
  name: string;
  isEditMode: boolean;
  error: AxiosError | null;
};

const NameCell = ({ name, isEditMode, error }: Props) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return isEditMode ? (
    <InputWrapper
      validationError={errors.name?.message}
      submitError={error?.response?.data?.name[0]}
      wrapperClasses={styles.name__wrapper}
    >
      <input
        type="text"
        {...register('name')}
        className={styles.input}
        placeholder={t('common.field.name')}
      />
    </InputWrapper>
  ) : (
    <span className={styles.name}>{name}</span>
  );
};

export default NameCell;
