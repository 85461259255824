import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ToastContainer, Zoom } from 'react-toastify';

import BasicInfoForm from 'components/InvitesContent/InviteForm/BasicInfoForm';
import EmailForm from 'components/InvitesContent/InviteForm/EmailForm';

import useAuth from 'contexts/AuthContext';

import { UserTypes } from 'constants/constants';

import 'react-toastify/dist/ReactToastify.css';

import styles from './index.module.scss';

enum FormVariants {
  email = 'email',
  basicInfo = 'basicInfo',
}

const InviteForm = () => {
  const [formVariant, setFormVariant] = useState<string>(FormVariants.email);
  const { user } = useAuth();
  const { t } = useTranslation();

  const inputHandler = ({ currentTarget: { id } }: React.ChangeEvent) => {
    setFormVariant(id);
  };

  const isEmailForm = formVariant === FormVariants.email;
  const adminAvailableUserTypes = user?.current_group_name ===
    UserTypes.admin && [
    { label: t('user.type.admin'), value: UserTypes.admin },
    { label: t('user.type.vendor-manager'), value: UserTypes.vendorManager },
  ];
  const vendorManagerAvailableUserTypes = user?.current_group_name ===
    UserTypes.vendorManager && [
    { label: t('user.type.vendor'), value: UserTypes.vendor },
  ];
  const userTypes =
    adminAvailableUserTypes || vendorManagerAvailableUserTypes || [];

  return (
    <>
      <ToastContainer style={{ width: '456px' }} transition={Zoom} />
      <div className={styles.container}>
        <label
          htmlFor={FormVariants.email}
          className={cn(styles.label, { [styles.active]: isEmailForm })}
        >
          <span className={styles.small}>{t('invites.invite-by')}</span>
          <span>{t('common.field.email')}</span>
          <input
            type="radio"
            name="invite"
            id={FormVariants.email}
            checked={isEmailForm}
            onChange={inputHandler}
          />
        </label>
        <label
          htmlFor={FormVariants.basicInfo}
          className={cn(styles.label, { [styles.active]: !isEmailForm })}
        >
          <span className={styles.small}>{t('invites.invite-by')}</span>
          <span>{t('invites.basic-info')}</span>
          <input
            type="radio"
            name="invite"
            id={FormVariants.basicInfo}
            checked={!isEmailForm}
            onChange={inputHandler}
          />
        </label>
      </div>
      {isEmailForm ? (
        <EmailForm userTypes={userTypes} />
      ) : (
        <BasicInfoForm userTypes={userTypes} />
      )}
    </>
  );
};

export default InviteForm;
