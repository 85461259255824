import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { Country } from 'components/CreateCampaign/models';

import Button from 'components/common/Button';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames, Regions } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  label: Regions;
  toggleRegion: (label: Regions) => void;
  countries: Country[];
  toggleCountry: (country: Country) => void;
  selectedCountries?: Country[];
  isDemoCampaign?: boolean;
  searchValue: string;
};

type CountryItemProps = {
  country: Country;
  selectedCountries?: Country[];
  toggleCountry: (country: Country) => void;
};

const CountryItem = ({
  country,
  selectedCountries,
  toggleCountry,
}: CountryItemProps) => {
  const handleSelectCountry = () => {
    toggleCountry(country);
  };

  const hasISO2 = 'iso2' in country;
  const countryId = hasISO2 ? country.iso2 : country.key;

  const isChecked = selectedCountries?.some(item =>
    'iso2' in item ? item.iso2 === countryId : item.key === countryId
  );

  return (
    <li
      key={countryId}
      onClick={handleSelectCountry}
      className={styles.listItem}
    >
      <span
        className={cn(styles.checkbox, {
          [styles.checked]: isChecked,
        })}
      />
      <span>{country?.label}</span>
    </li>
  );
};

const Option = ({
  label,
  toggleRegion,
  countries,
  toggleCountry,
  selectedCountries,
  isDemoCampaign,
  searchValue = '',
}: Props) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSelectAll = () => {
    toggleRegion(label);
  };

  const handleShowCountries = () => {
    setIsListOpen(prev => !prev);
  };

  const countryDisplayNames = new Intl.DisplayNames([i18n.language], {
    type: 'region',
  });

  const filteredCountries = isDemoCampaign
    ? countries.filter(item => {
        if ('key' in item)
          return item.key !== `${Regions['us-states']}.california`;
      })
    : countries;

  const translatedCoutries = filteredCountries
    .map(country => {
      const hasISO2 = 'iso2' in country;
      const translatedName = hasISO2
        ? countryDisplayNames.of(country.iso2)
        : t(`region.states.${country.key}`);
      return { ...country, label: translatedName };
    })
    .filter(country =>
      country.label?.toLowerCase().includes(searchValue?.toLowerCase())
    );

  return !!translatedCoutries.length ? (
    <li className={styles.options__item}>
      <span onClick={handleShowCountries} className={styles.group}>
        {t(`region.${label}`)}{' '}
        <IconSVG
          name={IconsNames.select_arrow}
          className={cn(styles.group_arrow, {
            [styles['group_arrow-open']]: isListOpen,
          })}
        />
      </span>
      {isListOpen && (
        <ul className={styles.suboptions}>
          {!searchValue && (
            <li className={cn(styles.itemWrapper, styles.all)}>
              <Button
                type="button"
                onClick={handleSelectAll}
                white
                className={styles.button}
                isSmall
              >
                {t('common.button.select-all')}
              </Button>
            </li>
          )}
          {translatedCoutries.map(country => {
            return (
              <CountryItem
                key={'iso2' in country ? country.iso2 : country.key}
                country={country}
                toggleCountry={toggleCountry}
                selectedCountries={selectedCountries}
              />
            );
          })}
        </ul>
      )}
    </li>
  ) : null;
};

export default Option;
