import { useRef } from 'react';
import {
  useController,
  useFieldArray,
  useWatch,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { ReactEditor } from 'slate-react';

import type { UseFieldArrayRemove, UseFieldArrayAppend } from 'react-hook-form';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { CampaignScriptQuestion } from 'types/models';

import RadioButtonsTab from 'components/common/RadioButtonsTab';
import Error from 'components/common/Error';

import IconSVG from 'components/UI/IconSVG';
import RichText from 'components/RichText';

import Answer from 'components/CreateCampaign/Step1/ScriptBuilder/Question/Answer';
import AddQuestionButton from 'components/CreateCampaign/Step1/ScriptBuilder/Question/AddQuestionButton';
import DuplicateQuestionButton from 'components/CreateCampaign/Step1/ScriptBuilder/Question/DuplicateQuestionButton';
import DeleteQuestionButton from 'components/CreateCampaign/Step1/ScriptBuilder/Question/DeleteQuestionButton';
import DisplayOnAssetPage from 'components/CreateCampaign/Step1/ScriptBuilder/Question/DisplayOnAssetPage';

import FocusedWrapper from 'components/RichText/FocusedWrapper';

import {
  emptyQuestion,
  emptyQuestionAnswer,
  createQuestionAnswerOther,
} from 'constants/scriptTemplates';
import { IconsNames, ScriptQuestionType } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  index: number;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  isFocused: boolean;
  remove: UseFieldArrayRemove | null;
  append: UseFieldArrayAppend<
    CreateCampaignValues,
    'script_builder.questions'
  > | null;
  dataForPreview?: CampaignScriptQuestion;
};

const MIN_ANSWERS_COUNT = 2;

const Question = ({
  dataForPreview,
  setIsFocused,
  isFocused,
  remove,
  index,
  append,
}: Props) => {
  const { t } = useTranslation();
  const formSettings = useFormContext<CreateCampaignValues>();
  const { register, control, setValue, getValues, formState } =
    formSettings || {};
  const questionEditorRef = useRef<ReactEditor>();
  const isPreviewMode = !!dataForPreview;

  const { field } = !isPreviewMode
    ? useController({
        control,
        name: `script_builder.questions.${index}.name`,
      })
    : { field: null };
  const {
    fields,
    remove: removeAnswer,
    append: appendAnswer,
  } = !isPreviewMode
    ? useFieldArray({
        control,
        name: `script_builder.questions.${index}.answers`,
        keyName: 'key',
      })
    : { fields: null, remove: null, append: null };

  const handleAddQuestion = () => {
    setIsFocused(false);
    append?.(emptyQuestion);
  };

  const handleDuplicateQuestion = () => {
    const question = getValues().script_builder?.questions?.[index];
    if (!question) return;
    setIsFocused(false);

    append?.({
      ...question,
      shouldFocus: true,
    });
  };

  const handleDeleteQuestion = () => {
    remove?.(index);
  };
  const isDisplayOnAssetPage = !isPreviewMode
    ? useWatch({
        name: `script_builder.questions.${index}.display_on_asset_page`,
        control,
      })
    : dataForPreview.display_on_asset_page;
  const isOtherOptionExists =
    !isPreviewMode &&
    useWatch({
      name: `script_builder.questions.${index}.answers`,
      control,
    })?.some(answer => answer.is_other);

  const isDeleteAnswerAvailable = (fields?.length || 0) > MIN_ANSWERS_COUNT;
  const isErrors = formState?.errors.script_builder?.questions?.[index];
  const validationError = isErrors?.name?.message;
  const answers = isPreviewMode ? dataForPreview.answers : fields;

  return (
    <li className={cn({ [styles.wrapper]: !isFocused })}>
      <div className={styles.header}>
        <FocusedWrapper
          hasStyling={false}
          className={styles.editor}
          isPreviewMode={isPreviewMode}
        >
          {props =>
            isPreviewMode ? (
              <RichText
                htmlString={dataForPreview.name}
                className={styles.preview}
              />
            ) : (
              <RichText
                editorRef={questionEditorRef}
                {...props}
                value={field?.value}
                onChange={val => field?.onChange(val)}
                className={cn(styles.editor, {
                  [styles.notFocused]: !isFocused,
                })}
                placeholder={t('script.question')}
                isReadOnly={!isFocused}
                validationError={validationError}
              />
            )
          }
        </FocusedWrapper>
        {isFocused && !isPreviewMode && (
          <RadioButtonsTab
            radioProps={[
              {
                id: 'radio',
                ...register?.(`script_builder.questions.${index}.answer_type`),
                value: ScriptQuestionType.radiobutton,
                label: t('script.radiobutton'),
              },
              {
                id: 'checkbox',
                ...register?.(`script_builder.questions.${index}.answer_type`),
                value: ScriptQuestionType.checkbox,
                label: t('script.checkbox'),
              },
            ]}
          />
        )}
        {!isFocused && isDisplayOnAssetPage && (
          <p className={styles.sign}>
            {t('common.field.display-on-asset-page')}
          </p>
        )}
      </div>
      <table
        className={cn(styles.answers, {
          [styles.readonly]: !isFocused || isPreviewMode,
        })}
      >
        <colgroup>
          <col />
          <col />
          <col />
        </colgroup>

        {isFocused && (
          <thead>
            <tr>
              <th>{t('common.field.answer-options')}</th>
              <th>{t('common.field.disqualifier')}</th>
              <th>{isDeleteAnswerAvailable && t('common.button.delete')}</th>
            </tr>
          </thead>
        )}

        <tbody>
          {answers?.map((answer, inx) => {
            const isFormFields = 'key' in answer;

            return (
              <Answer
                key={isFormFields ? answer.key : answer.id}
                index={inx}
                questionIndex={index}
                remove={removeAnswer}
                isFocused={isFocused}
                isDeleteAnswerAvailable={isDeleteAnswerAvailable}
                dataForPreview={isFormFields ? undefined : answer}
                isOther={answer.is_other}
              />
            );
          })}
          {isFocused && !isPreviewMode && (
            <tr>
              <td>
                <div className={styles.addAnswer}>
                  <IconSVG name={IconsNames.plus} />
                  <button
                    type="button"
                    className={styles.button}
                    onClick={() => appendAnswer?.(emptyQuestionAnswer)}
                    aria-label="add question option"
                  >
                    {t('common.button.add-option')}
                  </button>
                  {!isOtherOptionExists && (
                    <>
                      <span className={styles.caps}>
                        {t('common.field.or')}
                      </span>
                      <button
                        type="button"
                        className={styles.button}
                        onClick={() => {
                          appendAnswer?.(createQuestionAnswerOther(t));
                        }}
                        aria-label="add question other"
                      >
                        {t('common.button.add-other')}
                      </button>
                    </>
                  )}
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className={styles.button}
                  onClick={() => {
                    fields?.forEach((_, idx) => {
                      setValue(
                        `script_builder.questions.${index}.answers.${idx}.is_disqualifier`,
                        false
                      );
                    });
                  }}
                >
                  {t('common.button.clear-selection')}
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {isFocused && !isPreviewMode && (
        <div className={styles.actions}>
          <DuplicateQuestionButton onClick={handleDuplicateQuestion} />
          <DeleteQuestionButton onClick={handleDeleteQuestion} />
          <DisplayOnAssetPage index={index} />
        </div>
      )}
      {isFocused && !isPreviewMode && (
        <AddQuestionButton onClick={handleAddQuestion} />
      )}
      {isErrors?.message && <Error message={isErrors.message} />}
    </li>
  );
};

export default Question;
