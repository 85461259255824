import { useMutation } from 'react-query';

import type { AxiosError } from 'axios';
import type {
  FormValues,
  ContactsMappingFields,
} from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/models';
import type { UseFormGetValues, UseFormReset } from 'react-hook-form';

import useAuth from 'contexts/AuthContext';

export const useUploadContactsReport = ({
  campaignId,
  getValues,
  reset,
}: {
  campaignId: string | number;
  getValues: UseFormGetValues<FormValues>;
  reset: UseFormReset<FormValues>;
}) => {
  const { axios } = useAuth();

  const mutation = useMutation<
    {
      id: number | string;
      file_headers: string[];
      mapping_fields: ContactsMappingFields;
    },
    AxiosError,
    File
  >(
    async uploadedFile => {
      try {
        const formData = new FormData();
        formData.append('report_file', uploadedFile);
        formData.append('product_asset', getValues().asset?.toString());

        const { data } = await axios.post<{
          id: number | string;
          file_headers: string[];
          mapping_fields: ContactsMappingFields;
        }>(`/vendor-campaigns/${campaignId}/contacts-uploads/`, formData);

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: response => {
        const mappingFields = Object.entries(response.mapping_fields) as [
          keyof ContactsMappingFields,
          string | null
        ][];
        const mappingFieldsValues = mappingFields.reduce(
          (acc, [key, value]) => {
            acc[key] = value ? { value, label: value } : null;

            return acc;
          },
          {} as FormValues['mapping_fields']
        );

        const values = getValues();
        reset({
          ...values,
          id: response.id,
          product_asset: values.asset,
          headers: response.file_headers,
          mapping_fields: mappingFieldsValues,
        });
      },
    }
  );

  return mutation;
};
