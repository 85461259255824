import { useController, useWatch, useFormContext } from 'react-hook-form';
import { useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import type { UseFieldArrayRemove } from 'react-hook-form';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { ReactEditor } from 'slate-react';
import type { CampaignScriptQuestionAnswer } from 'types/models';

import RichText from 'components/RichText';
import Button from 'components/common/Button';

import FocusedWrapper from 'components/RichText/FocusedWrapper';

import { IconsNames, ScriptQuestionType } from 'constants/constants';
import { NOT_ALLOWED_ANSWERS_SYMBOLS_REGEXP } from 'constants/scriptTemplates';

import styles from './index.module.scss';

type Props = {
  index: number;
  isFocused: boolean;
  remove: UseFieldArrayRemove | null;
  questionIndex: number;
  isDeleteAnswerAvailable: boolean;
  isOther: boolean;
  dataForPreview?: CampaignScriptQuestionAnswer;
};

const Answer = ({
  index,
  questionIndex,
  isFocused,
  remove,
  isDeleteAnswerAvailable,
  dataForPreview,
  isOther,
}: Props) => {
  const { t } = useTranslation();
  const formSettings = useFormContext<CreateCampaignValues>();
  const { control, formState, getValues, setValue } = formSettings || {};
  const answerEditorRef = useRef<ReactEditor>();

  const isPreviewMode = !!dataForPreview;

  const { field: answerField } = !isPreviewMode
    ? useController({
        control,
        name: `script_builder.questions.${questionIndex}.answers.${index}.name`,
      })
    : { field: null };
  const { field: disqualifierField } = !isPreviewMode
    ? useController({
        control,
        name: `script_builder.questions.${questionIndex}.answers.${index}.is_disqualifier`,
      })
    : { field: null };

  const isDisqualifier = isPreviewMode
    ? dataForPreview.is_disqualifier
    : useWatch({
        control,
        name: `script_builder.questions.${questionIndex}.answers.${index}.is_disqualifier`,
      });
  const answersType = !isPreviewMode
    ? useWatch({
        control,
        name: `script_builder.questions.${questionIndex}.answer_type`,
      })
    : null;

  const validationError =
    formState?.errors.script_builder?.questions?.[questionIndex]?.answers?.[
      index
    ]?.name?.message;

  const handleDisqualifierChange = () => {
    getValues?.().script_builder?.questions[questionIndex]?.answers.forEach(
      (_, idx) => {
        setValue(
          `script_builder.questions.${questionIndex}.answers.${idx}.is_disqualifier`,
          idx === index
        );
      }
    );
  };

  return (
    <tr aria-label="script question answer option">
      <td className={styles.answer}>
        <FocusedWrapper
          hasStyling={false}
          className={styles.editor}
          isPreviewMode={isPreviewMode}
        >
          {props => (
            <>
              {isOther && (
                <p
                  className={cn(styles.other, {
                    [styles.preview]: isPreviewMode || !isFocused,
                  })}
                >
                  {t('script.other')}...
                </p>
              )}
              {isPreviewMode && !isOther && (
                <RichText
                  htmlString={dataForPreview.name}
                  className={styles.preview}
                />
              )}
              {!isPreviewMode && isFocused && (
                <input
                  type={
                    answersType === ScriptQuestionType.radiobutton
                      ? 'radio'
                      : ScriptQuestionType.checkbox
                  }
                  name={`question answer type ${questionIndex.toString()}`}
                  className={styles.input}
                  disabled
                />
              )}
              {!isPreviewMode && !isOther && (
                <>
                  <RichText
                    {...props}
                    isFocused={isFocused && props.isFocused}
                    editorRef={answerEditorRef}
                    value={answerField?.value}
                    isReadOnly={!isFocused}
                    onChange={val => answerField?.onChange(val)}
                    placeholder={t('script.option', { number: index + 1 })}
                    className={cn({
                      [styles.notFocused]: !isFocused,
                      [styles.focused]: isFocused,
                    })}
                    validationError={validationError}
                    forbiddenCharactersRegexp={
                      NOT_ALLOWED_ANSWERS_SYMBOLS_REGEXP
                    }
                  />
                </>
              )}
            </>
          )}
        </FocusedWrapper>
      </td>
      {isFocused && !isPreviewMode && (
        <>
          <td>
            <input
              type="radio"
              aria-label="is answer disqualifier"
              checked={disqualifierField?.value === true}
              name={`${questionIndex} disqualifier`}
              onChange={handleDisqualifierChange}
            />
          </td>
          <td className={styles.delete}>
            {isDeleteAnswerAvailable && (
              <Button
                type="button"
                onClick={() => remove?.(index)}
                isOnlyIcon
                iconProps={{ name: IconsNames.close }}
                aria-label="delete question answer option"
              />
            )}
          </td>
        </>
      )}
      {!isFocused && isDisqualifier && (
        <td className={styles.sign} colSpan={2}>
          {t('common.field.disqualifier')}
        </td>
      )}
    </tr>
  );
};

export default Answer;
