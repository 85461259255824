import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import type { UseQueryResult } from 'react-query';

// TODO will implement
// import useAuth from 'contexts/AuthContext';

import CampaignTable from 'components/CampaignDetails/InfoSectionWrapper';
// TODO will implement
// import VendorAssets from 'components/CampaignManage/AssetsSection/VendorAssets';
import AssetsItem from 'components/CampaignManage/AssetsSection/AssetsItem';
// TODO will implement
// import VMAssets from 'components/CampaignManage/AssetsSection/VMAssets';

import type { CampaignAssetFileResponse } from 'types/models';

import Loader from 'components/common/LoaderScreen/Loader';

// TODO will implement
// import { UserTypes } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  getAssetsRequestSettings: UseQueryResult<
    CampaignAssetFileResponse[],
    AxiosError
  >;
  campaignId?: number;
  campaignName?: string;
};

const AssetsSection = ({
  getAssetsRequestSettings: { isLoading, data },
  campaignId,
  campaignName,
}: Props) => {
  // TODO will implement
  // const { user } = useAuth();
  const { t } = useTranslation();

  // TODO will implement
  // const isVendor = user?.current_group_name === UserTypes.vendor;

  return (
    <>
      <CampaignTable className={styles.table}>
        <CampaignTable.Header>
          <CampaignTable.Title>{t('common.field.assets')}</CampaignTable.Title>
        </CampaignTable.Header>
        {isLoading && <Loader className={styles.loading} />}
        <CampaignTable.Content className={styles.table_content}>
          <ul className={styles.list}>
            {!!data?.length &&
              data.map(file => (
                <AssetsItem
                  data={file}
                  key={file.id}
                  campaignId={campaignId}
                  campaignName={campaignName}
                />
              ))}
          </ul>
        </CampaignTable.Content>
        {/* <CampaignTable.Content
          className={cn(styles.table_content, styles.table_content_second)}
        >
          {/* TODO will implement */}
        {/* {isVendor ? <VendorAssets /> : <VMAssets />} 
        </CampaignTable.Content> */}
      </CampaignTable>
    </>
  );
};

export default AssetsSection;
