import { useTranslation } from 'react-i18next';

import type { CampaignResponse } from 'types/models';

import CampaignTable from 'components/CampaignDetails/InfoSectionWrapper';
import Loader from 'components/common/LoaderScreen/Loader';
import Button from 'components/common/Button';
import ToggleSwitch from 'components/common/ToggleSwitch';

import DownloadReport from 'components/CampaignManage/CampaignSpecifications/DownloadReportTemplate';

import { UserTypes } from 'constants/constants';

import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';
import useGetLeadMapping from 'hooks/api/useGetLeadMapping';
import useGetSAL from 'hooks/api/useGetSAL';
import useGetTAL from 'hooks/api/useGetTAL';

import getLongStringView from 'helpers/getLongStringView';

import styles from './index.module.scss';

type Props = {
  campaignDetails: CampaignResponse['campaign_details'];
  campaignSpecification: CampaignResponse['campaign_specification'];
  campaignId?: string;
  companyName: string | undefined;
};

const CampaignSpecifications = ({
  campaignDetails,
  campaignSpecification,
  companyName,
  campaignId,
}: Props) => {
  const { t, i18n } = useTranslation();
  const {
    name,
    campaign_owner_email: campaignOwnerEmail,
    notice,
    contacts_per_company: CPC,
    is_qc_required: isQCRequired,
  } = campaignDetails;
  const { user } = useAuth();
  const { convertGeography } = useConvertCampaignData();
  const isVendor = user?.current_group_name === UserTypes.vendor;

  const { isLoading: isTALLoading, data: TALData } = useGetTAL(campaignId);
  const { isLoading: isSALLoading, data: SALData } = useGetSAL(campaignId);
  const { isLoading: isLeadMappingLoading, data: leadMapping } =
    useGetLeadMapping(campaignId);

  const {
    job_titles: jobTitles,
    industry,
    geography,
    revenue,
    size,
    notice: specialInstructions,
    management_level: managementLevel,
  } = campaignSpecification;

  const translateValue = (options: string[]) =>
    options.map(option => {
      return i18n.exists(`common.field.${option}`)
        ? t(`common.field.${option}`)
        : option.replace('_', '-');
    });

  const revenueAfterTranslation = revenue && translateValue(revenue);
  const companySizeAfterTranslation = size && translateValue(size);
  const industryAfterTranslation = industry && translateValue(industry);
  const countriesAfterTransition = convertGeography(geography);
  const managementLevelAfterTranslation =
    managementLevel && translateValue(managementLevel);

  return (
    <>
      <CampaignTable className={styles.table}>
        <CampaignTable.Header className={styles.header}>
          <CampaignTable.Title>{name}</CampaignTable.Title>
        </CampaignTable.Header>
        <CampaignTable.Content className={styles.content}>
          <div className={styles.column}>
            {!isVendor && (
              <div className={styles.info}>
                <span className={styles.info__title}>
                  {t('campaign.campaign-owner-email-id')}
                </span>
                <span className={styles.info__data}>
                  {campaignOwnerEmail ? campaignOwnerEmail : '-'}
                </span>
              </div>
            )}

            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.company-name')}
              </span>
              <span className={styles.info__data}>
                {isVendor && (companyName || '-')}
                {!isVendor && user?.company.name}
              </span>
            </div>

            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.cpc')}
              </span>
              <span className={styles.info__data}>{CPC}</span>
            </div>

            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.job-titles')}
              </span>
              <span className={styles.info__data}>
                {!!jobTitles?.length
                  ? jobTitles.join(', ')
                  : t('common.field.all')}
              </span>
            </div>
            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.geography')}
              </span>
              <span className={styles.info__data}>
                {countriesAfterTransition
                  ? countriesAfterTransition.join(', ')
                  : '-'}
              </span>
            </div>
            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.company-size')}
              </span>
              <span className={styles.info__data}>
                {!!size?.length ? companySizeAfterTranslation?.join(', ') : '-'}
              </span>
            </div>
            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.revenue')}
              </span>
              <span className={styles.info__data}>
                {!!revenue?.length ? revenueAfterTranslation?.join(', ') : '-'}
              </span>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.industry')}
              </span>
              <span className={styles.info__data}>
                {!!industry?.length
                  ? industryAfterTranslation?.join(', ')
                  : '-'}
              </span>
            </div>
            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.management-level')}
              </span>
              <span className={styles.info__data}>
                {!!managementLevel?.length
                  ? managementLevelAfterTranslation?.join(', ')
                  : '-'}
              </span>
            </div>
            <div className={styles.info}>
              <span className={styles.info__title}>{t('common.field.qc')}</span>
              <span className={styles.info__data}>
                <ToggleSwitch disabled checked={isQCRequired} id="qc" />
              </span>
            </div>
            {(isTALLoading || isSALLoading || isLeadMappingLoading) && (
              <Loader className={styles.loading} />
            )}

            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.target-account-list')}
              </span>
              <span className={styles.info__data}>
                {!!TALData?.length
                  ? TALData.map(file => (
                      <a
                        href={file.tal_file}
                        key={file.tal_file}
                        download={file.tal_file_name}
                      >
                        <Button className={styles.button}>
                          {getLongStringView(file.tal_file_name, 12)}
                        </Button>
                      </a>
                    ))
                  : '-'}
              </span>
            </div>
            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.suppression-account-list')}
              </span>
              <span className={styles.info__data}>
                {!!SALData?.length
                  ? SALData.map(file => (
                      <a
                        href={file.sal_file}
                        download={file.sal_file}
                        key={file.id}
                      >
                        <Button className={styles.button} isSmall>
                          {getLongStringView(file.sal_file_name, 12)}
                        </Button>
                      </a>
                    ))
                  : '-'}
              </span>
            </div>
            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.report-template')}
              </span>

              <DownloadReport />
            </div>
            <div className={styles.info}>
              <span className={styles.info__title}>
                {t('common.field.lead-file')}
              </span>
              {leadMapping?.lead_file ? (
                <a
                  href={leadMapping?.lead_file}
                  download={leadMapping.lead_file_name}
                >
                  <Button type="button" className={styles.button} isSmall>
                    {t('common.button.download')}
                  </Button>
                </a>
              ) : (
                '-'
              )}
              <div className={styles.checkboxes}>
                <label className={styles.info__title}>
                  <input
                    type="checkbox"
                    checked={campaignDetails.is_casl_compliant}
                    disabled
                  />
                  {t('common.field.casl-compliant')}
                </label>
                <label className={styles.info__title}>
                  <input
                    type="checkbox"
                    checked={campaignDetails.is_gdpr_compliant}
                    disabled
                  />
                  {t('common.field.gdpr-compliant')}
                </label>
              </div>
            </div>
          </div>

          <div className={styles.notice}>
            <span className={styles.info__title}>
              {t('common.field.special-instructions')}
            </span>
            <p>{notice || '—'}</p>
          </div>
          <div className={styles.notice}>
            <span className={styles.info__title}>
              {t('common.field.special-instructions')}
            </span>
            <p>{specialInstructions || '—'}</p>
          </div>
        </CampaignTable.Content>
      </CampaignTable>
    </>
  );
};

export default CampaignSpecifications;
