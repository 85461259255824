import { useFormContext } from 'react-hook-form';

import type { UseFormWatch } from 'react-hook-form';
import type { CampaignAssetFileResponse } from 'types/models';
import type {
  OffersFormValues,
  AssetsLeadsByMilestone,
  LeadsRequiredPerMilestoneDictionary,
  OfferFormValues,
  CampaignOfferAsset,
} from 'components/CampaignManage/models';

import MilestoneItem from 'components/CampaignManage/OfferItem/MilestoneItem';
import MilestoneAssetItem from 'components/CampaignManage/OfferItem/MilestoneAssetItem';

import {
  getMilestoneLeadsCountDifference,
  getAssetLeadsCountDifference,
  getNoPreferenceAssetLeadsCountDifference,
} from 'helpers/offers';

import { OfferStatuses } from 'constants/constants';
import { AssetDistributionType } from 'constants/assets';

type Props = {
  isEditable?: boolean;
  offerIndex: number;
  index: number;
  assets: CampaignAssetFileResponse[];
  assignedLeadsBySavedAssets: AssetsLeadsByMilestone;
  milestoneId: number;
  milestone: OfferFormValues['campaign_offer_milestones'][0];
  watchAssignedOffersForm: UseFormWatch<OffersFormValues>;
  watchNewOffersForm: UseFormWatch<OffersFormValues>;
  requiredLeadsCountPerMilestone?: LeadsRequiredPerMilestoneDictionary;
  assetDistribution?: AssetDistributionType | null;
};

const MilestoneVMOfferItem: React.FC<Props> = ({
  isEditable = false,
  children,
  index,
  offerIndex,
  assets,
  assignedLeadsBySavedAssets,
  milestoneId,
  watchNewOffersForm,
  watchAssignedOffersForm,
  requiredLeadsCountPerMilestone,
  assetDistribution,
}) => {
  const { watch } = useFormContext<OffersFormValues>();
  const newOffers = watchNewOffersForm('offers');
  const assignedOffers = watchAssignedOffersForm
    ? watchAssignedOffersForm('offers')
    : [];
  const difference = getMilestoneLeadsCountDifference(
    milestoneId,
    assignedOffers.filter(
      option => option.value.status !== OfferStatuses.declined
    ),
    newOffers,
    requiredLeadsCountPerMilestone
  );

  const isNoPreferenceChecked = watch(
    `offers.${offerIndex}.value.no_preference`
  );

  const renderMilestoneAssetItem = ({
    asset,
    assetIndex,
    isError,
  }: {
    asset: CampaignOfferAsset;
    assetIndex: number;
    isError: boolean;
  }) => {
    const assignedOffersOptions = watchAssignedOffersForm
      ? watchAssignedOffersForm('offers')
      : [];
    const newOffersOptions = watchNewOffersForm('offers');
    const assetLeadsAmountData = assignedLeadsBySavedAssets?.get(milestoneId);
    const defaultLeadsAmount =
      assetLeadsAmountData &&
      assetLeadsAmountData[asset.asset?.toString()]?.leads_amount;
    const isNoPreferenceDistribution =
      assetDistribution === AssetDistributionType['no-preference'];

    const noPreferenceDistributionAssetDifference =
      isNoPreferenceDistribution &&
      !isNoPreferenceChecked &&
      getNoPreferenceAssetLeadsCountDifference(
        assignedOffersOptions.filter(
          option => option.value.status !== OfferStatuses.declined
        ),
        newOffersOptions,
        defaultLeadsAmount || 0
      );

    const specifiedDistributionAssetDifference =
      !isNoPreferenceDistribution &&
      !isNoPreferenceChecked &&
      getAssetLeadsCountDifference(
        asset.asset,
        assignedOffersOptions.filter(
          option => option.value.status !== OfferStatuses.declined
        ),
        newOffersOptions,
        defaultLeadsAmount
      );

    return (
      <MilestoneAssetItem
        isEditable={isEditable}
        offerIndex={offerIndex}
        milestoneIndex={index}
        assetIndex={assetIndex}
        assetTitle={asset.asset_title}
        leadsFieldName="leads_required"
        difference={
          noPreferenceDistributionAssetDifference ||
          specifiedDistributionAssetDifference ||
          0
        }
        isLeadsSumError={isError}
      />
    );
  };

  return (
    <MilestoneItem
      offerIndex={offerIndex}
      index={index}
      difference={difference}
      milestoneId={milestoneId}
      leadsFieldName="leads_required"
      assets={assets}
      isEditable={isEditable}
      renderMilestoneAssetItem={
        isNoPreferenceChecked || assets.length === 1
          ? undefined
          : renderMilestoneAssetItem
      }
    >
      {children}
    </MilestoneItem>
  );
};

export default MilestoneVMOfferItem;
