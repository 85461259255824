import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import type { AxiosError } from 'axios';
import type { PricingPlanResponse } from 'types/models';

import CreatePricingPlanModal from 'components/CreateProductContent/PricingPlans/CreatePricingPlanModal';

import ModalWindow from 'components/common/ModalWindow';
import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  planData: PricingPlanResponse;
  companySlug?: string;
  productSlug?: string | null;
  productId?: number;
  productName?: string;
  isGetADemoAvailable?: boolean;
  onOpenGetDemo?: () => void;
};

const BENEFITS_MAX_SHOW_LENGTH = 55;

const PricingPlanItem: React.FC<Props> = ({
  planData,
  companySlug,
  productSlug,
  productId,
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const { axios } = useAuth();
  const { name, price, period, id, pricing_benefits: benefits } = planData;
  const queryClient = useQueryClient();

  const { mutate: deletePlan, isLoading } = useMutation<void, AxiosError>(
    async () => {
      try {
        await axios.delete(
          `/companies/${companySlug}/products/${productSlug}/pricing-plans/${id}/`
        );
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => {
        closeModal();
        queryClient.setQueryData<PricingPlanResponse[]>(
          ['get pricing plans', companySlug, productId?.toString()],
          oldData => oldData?.filter(item => item.id !== id) || []
        );
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const handleEdit = () => {
    openModal({
      Content: (
        <CreatePricingPlanModal
          isEdit
          companySlug={companySlug}
          closeModal={closeModal}
          planId={id}
          productId={productId}
          defaultValues={planData}
        />
      ),
    });
  };

  const handleDelete = () => {
    openModal({
      Content: (
        <DeleteConfirmationModal
          instanceName={t('manage-products.pricing-plan').toLowerCase()}
          itemName={name}
          onDelete={deletePlan}
        />
      ),
    });
  };

  const formatPrice = (receivedPrice: number) =>
    receivedPrice === 0 ? t('auth.free') : `$${receivedPrice} `;

  return (
    <tr className={styles.row}>
      <td>{name}</td>
      <td>{formatPrice(+price)}</td>
      <td>
        {+price > 0 && (
          <span className={styles.period}>{t(`common.field.${period}`)}</span>
        )}
      </td>
      <td className={styles.benefits}>
        {benefits.length > BENEFITS_MAX_SHOW_LENGTH
          ? `${benefits.slice(0, BENEFITS_MAX_SHOW_LENGTH)}...`
          : benefits}
      </td>
      <td className={styles.wrapper}>
        <div className={styles.actions}>
          <Button
            type="button"
            className={styles.edit}
            onClick={handleEdit}
            iconProps={{ name: IconsNames.edit }}
          />
          <Button
            type="button"
            className={styles.delete}
            onClick={handleDelete}
            iconProps={{ name: IconsNames.close }}
            white
          />
        </div>
      </td>
      {isLoading && <LoaderScreen />}
    </tr>
  );
};

export default PricingPlanItem;
