import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useLoaderScreen from 'contexts/LoaderScreenContext';

import styles from './index.module.scss';

const LoaderScreen = () => {
  const { openLoaderScreen, closeLoaderScreen } = useLoaderScreen();
  const { t } = useTranslation();

  useEffect(() => {
    openLoaderScreen({
      Content: (
        <div className={styles.wrapper} aria-label="loading">
          <p className={styles.animation}>{t('home.beeleads')}</p>
        </div>
      ),
    });

    return () => closeLoaderScreen();
  }, []);

  return null;
};

export default LoaderScreen;
