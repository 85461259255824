import { useMemo } from 'react';
import Select, { components } from 'react-select';
import { useController } from 'react-hook-form';
import countryList from 'react-select-country-list';
import Flags from 'country-flag-icons/react/1x1';

import type { UseFormReturn, FieldErrors } from 'react-hook-form';
import type {
  GroupBase,
  InputProps,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import type { FlagComponent } from 'country-flag-icons/react/1x1';

import {
  authSelectStyles,
  getDefaultSelectStylesWithError,
} from 'utils/selectStyles';
import styles from './index.module.scss';

type Props = {
  formSettings: UseFormReturn<any, object>;
  name: string;
  message?: FieldErrors;
  label?: string;
  isSmall?: boolean;
};

const Input = (rest: InputProps<any, false, GroupBase<any>>) => {
  return (
    <components.Input
      {...rest}
      autoComplete={`password-${rest.selectProps.name}`}
      id={`password-${rest.selectProps.name}`}
    />
  );
};

const Option = (props: OptionProps<any, false, GroupBase<any>>) => {
  const Flag: new () => React.Component<any, any> =
    Flags[props.data.value as keyof FlagComponent];

  return (
    <components.Option {...props}>
      <span className={styles.labelWrapper}>
        {!!Flag && <Flag className={styles.flagIcon} />}
        {props.label}
      </span>
    </components.Option>
  );
};

const SingleValue = (props: SingleValueProps<any, false, GroupBase<any>>) => {
  const Flag: new () => React.Component<any, any> =
    Flags[props.data.value as keyof FlagComponent];

  return (
    <components.SingleValue {...props} className={styles.labelWrapper}>
      {!!Flag && <Flag className={styles.flagIcon} />}
      {props.children}
    </components.SingleValue>
  );
};

const CountrySelectField = ({ name, formSettings, isSmall }: Props) => {
  const { field } = useController({
    name,
    control: formSettings.control,
  });
  const options = useMemo(() => countryList().getData(), []);

  return (
    <>
      <Select
        styles={
          isSmall
            ? getDefaultSelectStylesWithError({
                isSmall: true,
              })
            : authSelectStyles
        }
        options={options}
        placeholder=""
        {...field}
        components={{ Input, Option, SingleValue }}
      />
    </>
  );
};

export default CountrySelectField;
