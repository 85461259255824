import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { SelectOption } from 'types/models';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { ActionMeta, SingleValue } from 'react-select';

import FieldsetWrapper from 'components/common/FieldsetWrapper';
import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import Error from 'components/common/Error';
import Loader from 'components/common/LoaderScreen/Loader';
import ChooseOptionsSelect from 'components/common/ChooseOptionsSelect';

import CustomAttribute from 'components/CreateCampaign/Step2/CustomAttribute';

import useSaveReportTemplate from 'components/CreateCampaign/useSaveReportTemplate';

import { IconsNames, companyOptions, leadOptions } from 'constants/constants';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

type Props = {
  setIsNextStepDisabled: (isDisabled: boolean) => void;
  isNextStepDisabled: boolean;
};

const ReportTemplateMapping = ({
  setIsNextStepDisabled,
  isNextStepDisabled,
}: Props) => {
  const { t } = useTranslation();
  const { getValues, setValue, watch, control } =
    useFormContext<CreateCampaignValues>();

  const {
    append,
    remove,
    fields: customFields,
  } = useFieldArray({
    control,
    name: 'campaign_files.report_template.custom_attributes',
  });

  const handleSelect = (
    newValue: SingleValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    if (!isNextStepDisabled) setIsNextStepDisabled(true);
    if (actionMeta.action === 'select-option') {
      setValue(
        // @ts-ignore
        `campaign_files.report_template.${newValue?.value.replace(/-/g, '_')}`,
        actionMeta.name
      );
    }
    if (actionMeta.action === 'clear') {
      actionMeta.removedValues.forEach(({ value }) => {
        setValue(
          // @ts-ignore
          `campaign_files.report_template.${value}`,
          null
        );
      });
    }
  };

  const { mutate, isLoading, error } = useSaveReportTemplate(getValues().id);

  const reportTemplate = watch('campaign_files.report_template');
  const reportTemplateEntries =
    typeof reportTemplate === 'object' && Object.entries(reportTemplate);

  const handleSaveMapping = () => {
    if (!reportTemplate) return;
    mutate(reportTemplate, {
      onSettled: (_, err) => {
        if (!err) {
          setIsNextStepDisabled(false);
        }
      },
    });
  };

  const customFieldsWatched = watch(
    'campaign_files.report_template.custom_attributes'
  );

  const removeCustomAttribute = (index: number) => {
    if (!isNextStepDisabled) setIsNextStepDisabled(true);
    if (!reportTemplateEntries) return;

    const removedItemName =
      customFieldsWatched && customFieldsWatched[index].name;
    if (removedItemName) {
      reportTemplateEntries?.some(([key, value]) => {
        if (value === removedItemName) {
          setValue(
            // @ts-ignore
            `campaign_files.report_template.${key}`,
            null
          );

          return true;
        }
      });

      setValue(
        // @ts-ignore
        `campaign_files.report_template.${customFieldsWatched[index].name
          .toLowerCase()
          .replace(/ /g, '_')}`,
        null
      );
    }

    remove(index);
  };

  const headers = reportTemplate?.headers;
  const matchedHeadersDictionary =
    reportTemplateEntries &&
    reportTemplateEntries?.reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (typeof value === 'string') {
        acc[value] = key;
      }
      return acc;
    }, {});

  const selectedOptions = [
    ...Object.values(matchedHeadersDictionary),
    ...(customFieldsWatched?.map(field => field.name) || []),
  ];

  const translatedCompanyOptions = companyOptions
    .filter(option => !selectedOptions?.includes(option))
    .map(option => ({
      value: option,
      label: t(`common.field.${option.replace(/_/g, '-')}`),
    }));
  const translatedLeadOptions = leadOptions
    .filter(option => !selectedOptions?.includes(option))
    .map(option => ({
      value: option,
      label: t(`common.field.${option.replace(/_/g, '-')}`),
    }));

  return (
    <>
      <div>
        <FieldsetWrapper
          legend={t('campaign.report-mapping')}
          wrapperClassName={styles.container}
        >
          {headers?.map((value, index) => {
            const isFieldMatched =
              matchedHeadersDictionary && matchedHeadersDictionary[value];

            return (
              <InputWrapper
                key={index}
                label={value}
                number={index + 1}
                wrapperClasses={styles.label}
                numberClassName={isFieldMatched ? styles.matched : ''}
              >
                <ChooseOptionsSelect
                  onChange={handleSelect}
                  isClearable
                  name={value}
                  defaultValue={
                    isFieldMatched
                      ? {
                          value: isFieldMatched,
                          label: t(
                            `common.field.${isFieldMatched.replace(/_/g, '-')}`
                          ),
                        }
                      : undefined
                  }
                  firstOptions={{
                    options: translatedLeadOptions,
                    label: t('common.field.lead-info'),
                  }}
                  secondOptions={{
                    options: translatedCompanyOptions,
                    label: t('common.field.company-info'),
                  }}
                />
              </InputWrapper>
            );
          })}

          {customFields.map((field, index) => (
            <CustomAttribute
              key={field.id}
              baseLength={headers?.length || 0}
              index={index}
              remove={removeCustomAttribute}
              selectedOptions={selectedOptions}
            />
          ))}

          <Button
            type="button"
            onClick={() => append({ name: '' })}
            className={styles.add}
            iconProps={{ name: IconsNames.plus }}
          >
            {t('common.button.add-attribute')}
          </Button>

          <div className={styles.wrapper}>
            <Error
              message={error ? getResponseError(error) : ''}
              className={styles.error}
            />

            {isLoading ? (
              <Loader className={styles.loader} size={50} />
            ) : (
              <Button
                isBig
                type="button"
                withArrow={isNextStepDisabled}
                className={styles.submit}
                onClick={handleSaveMapping}
                iconProps={
                  !isNextStepDisabled
                    ? {
                        name: IconsNames.check_mark,
                        className: styles.submit__svg,
                      }
                    : undefined
                }
              >
                {!isNextStepDisabled
                  ? t('common.button.mapped')
                  : t('common.button.map')}
              </Button>
            )}
          </div>
        </FieldsetWrapper>
      </div>
    </>
  );
};

export default ReportTemplateMapping;
