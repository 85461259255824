import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { useDebounce } from 'react-use';
import cn from 'classnames';

import type { AxiosError } from 'axios';

import LoaderScreen from 'components/common/LoaderScreen';
import ModalWindow from 'components/common/ModalWindow';
import IconSVG from 'components/UI/IconSVG';
import Loader from 'components/common/LoaderScreen/Loader';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Company = { id: number; name: string };
type Props = {
  onBeforeAction?: (func: () => void) => void;
};

const SuperAdminSelectCompany = ({ onBeforeAction }: Props) => {
  const { axios, user } = useAuth();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  useDebounce(() => setDebouncedValue(searchValue), 250, [searchValue]);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useModal();

  const {
    data: options,
    isLoading,
    isRefetching,
  } = useQuery<Company[]>(
    ['superadmin-companies', debouncedValue],
    async () => {
      try {
        const { data } = await axios.get<Company[]>(
          `/companies/?search=${debouncedValue}&page_size=15`
        );

        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading: isChangeCompanyLoading } = useMutation<
    Company,
    AxiosError,
    number
  >(
    async (selectedCompanyId: number) => {
      try {
        const { data } = await axios.post<Company>(
          `/companies/${selectedCompanyId}/impersonate/`
        );
        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('profile');
        queryClient.resetQueries();
        closeModal();
      },
      onError: err =>
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        }),
    }
  );

  const handleChangeCompany = (companyId: number) => {
    if (companyId !== user?.company.id) {
      const changeCompany = () => mutate(companyId);
      if (onBeforeAction) {
        onBeforeAction(changeCompany);
        return;
      }

      changeCompany();
    }
  };

  return (
    <>
      <ModalWindow
        primaryTitle={t('common.modal.change-company')}
        className={styles.modal}
      >
        <div className={styles.search}>
          {!isRefetching ? (
            <IconSVG name={IconsNames.magnifier} className={styles.magnifier} />
          ) : (
            <Loader size={20} className={styles.loader} />
          )}
          <input
            type="text"
            value={searchValue}
            onChange={e => setSearchValue(e.currentTarget.value)}
            className={styles.input}
            placeholder={t('common.field.search')}
          />
        </div>

        {!!options?.length && (
          <>
            <p className={styles.count}>
              {options?.length} {t('common.modal.companies')}
            </p>
            <ul className={styles.results}>
              {options?.map(company => (
                <li
                  key={company.id}
                  className={cn(styles.company, {
                    [styles.selected]: user?.company.id === company.id,
                  })}
                  role="presentation"
                  onClick={() => handleChangeCompany(company.id)}
                >
                  <span>{company.name}</span>
                  <IconSVG
                    name={IconsNames.check_mark}
                    className={styles.chekmark}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {isLoading && <Loader size={36} className={styles.loading} />}
      </ModalWindow>
      {isChangeCompanyLoading && <LoaderScreen />}
    </>
  );
};

export default SuperAdminSelectCompany;
