import { useQuery } from 'react-query';

import useAuth from 'contexts/AuthContext';

const useFetchProductsOptions = (enabled = true) => {
  const { axios, user } = useAuth();
  const companySlug = user?.company.slug;

  const query = useQuery(
    ['product-list-mapping', companySlug],
    async () => {
      try {
        const { data } = await axios.get<
          { name: string; id: number; description: string }[]
        >(`/companies/${companySlug}/product-list-mapping/`);

        const options = data.map(product => ({
          label: product.name,
          value: product.id,
          description: product.description,
        }));

        return options;
      } catch (error) {
        throw error;
      }
    },
    { enabled: !!enabled && !!companySlug }
  );

  return query;
};

export default useFetchProductsOptions;
