import { useTranslation } from 'react-i18next';

import {
  TwoFADevice,
  DevicesDictionary,
  TwoFATypes,
} from 'components/TwoFA/models';

import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';

import TwoFAWrapper from 'components/TwoFA/TwoFAWrapper';

import useSendOTPCode from 'hooks/api/useSendOTPCode';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';
import DevicesList from './DevicesList';

type Props = {
  onDeviceChange: React.Dispatch<React.SetStateAction<TwoFADevice | undefined>>;
  devices: DevicesDictionary;
};

const TwoFaSelect = ({ devices, onDeviceChange }: Props) => {
  const { t } = useTranslation();

  const { mutate, error, isLoading } = useSendOTPCode(device => {
    onDeviceChange(device);
  });

  const devicesTypes = Object.keys(devices) as TwoFATypes[];

  return (
    <TwoFAWrapper>
      <h2 className={styles.title}>{t('auth.how-receive-code')}</h2>
      <Error message={error ? getResponseError(error) : ''} />
      {devicesTypes.map((type, index) => {
        const devicesList = devices[type];

        return !!devicesList.length ? (
          <DevicesList
            key={index}
            onDeviceChange={onDeviceChange}
            type={type}
            devicesList={devicesList}
            mutate={mutate}
          />
        ) : null;
      })}
      {isLoading && <LoaderScreen />}
    </TwoFAWrapper>
  );
};

export default TwoFaSelect;
