import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { EmailServerResponse } from 'types/models';

import Table from 'components/common/Table';
import Loader from 'components/common/LoaderScreen/Loader';
import IconSVG from 'components/UI/IconSVG';
import Button from 'components/common/Button';
import ModalWindow from 'components/common/ModalWindow';
import Error from 'components/common/Error';

import EditableForm from 'components/IntegrationsContent/VendorContent/EditableForm';

import { useGetEmailServers } from 'hooks/api/useGetEmailServers';

import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';
import { getESPServerName } from 'helpers/mailServers';

import { IconsNames, ServerVariants } from 'constants/constants';

import styles from './index.module.scss';

enum TableAccessors {
  name = 'name',
  serverType = 'server-type',
  view_details = 'view-details',
}

const ServersList = () => {
  const { t } = useTranslation();
  const infoRef = useRef(null);
  const { openModal } = useModal();
  const { data, isLoading, dataUpdatedAt, error } = useGetEmailServers(true);

  const openViewDetailsModal = (server: EmailServerResponse) => {
    openModal({
      Content: (
        <ModalWindow
          primaryTitle={t('integrations.server-details')}
          className={styles.modal}
        >
          <EditableForm {...server} />
        </ModalWindow>
      ),
    });
  };

  const columnsData = useMemo(
    () => [
      {
        Header: t(`common.field.${TableAccessors.name}`),
        accessor: TableAccessors.name,
      },
      {
        Header: t(`common.field.${TableAccessors.serverType}`),
        accessor: TableAccessors.serverType,
      },
      {
        Header: '',
        accessor: TableAccessors.view_details,
        Cell: ({ value }: { value: EmailServerResponse }) => (
          <Button
            type="button"
            className={styles.details}
            onClick={() => openViewDetailsModal(value)}
            white
          >
            <span>{t('common.button.view-details')}</span>
          </Button>
        ),
      },
    ],
    []
  );

  const rowsData = useMemo(() => {
    return data?.map(server => {
      const credentialsName =
        server.integration_type === ServerVariants.smtp
          ? server.decrypted_credentials.host
          : getESPServerName(server.decrypted_credentials.provider_name);
      return {
        [TableAccessors.name]: server.integration_name || credentialsName,
        [TableAccessors.serverType]: t(
          `integrations.${server.integration_type}`
        ),
        [TableAccessors.view_details]: server,
      };
    });
  }, [data, dataUpdatedAt]);

  return (
    <>
      {isLoading && <Loader className={styles.loader} />}
      {error && <Error message={getResponseError(error)} />}
      {!isLoading && (
        <div ref={infoRef}>
          <Table
            tableOptions={{ columns: columnsData, data: rowsData || [] }}
            wrapperClassName={styles.table}
            rowClassName={styles.row}
            headClassName={styles.thead}
            isFirstColumnFixed
            noDataComponent={
              <div className={styles.empty}>
                <IconSVG className={styles.icon} name={IconsNames.servers} />
                <p className={styles.title}>
                  {t('integrations.table.no-servers')}
                </p>
                <p className={styles.description}>
                  {t('integrations.table.your-servers')}
                </p>
              </div>
            }
          />
        </div>
      )}
    </>
  );
};

export default ServersList;
