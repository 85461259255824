export enum TermsOfServiceIDs {
  interpretation = 'interpretation',
  accounts = 'accounts',
  eligibility = 'eligibility',
  suspension_and_termination = 'suspension-and-termination',
  services_and_balance = 'services-and-balance',
  taxes = 'taxes',
  contractual_relationship_between_client_and_vendor = 'contractual-relationship-between-client-and-vendor',
  license = 'license',
  warranties_and_representations = 'warranties-and-representations',
  prohibited_use = 'prohibited-use',
  personal_data_and_data_protection = 'personal-data-and-data-protection',
  third_party_content_and_services = 'third-party-content-and-services',
  user_content = 'user-content',
  updates_availability_and_access = 'updates-availability-and-access',
  proprietary_rights = 'proprietary-rights',
  void_transactions = 'void-transactions',
  no_advice = 'no-advice',
  no_warranty = 'no-warranty',
  waiver_of_fiduciary_duties = 'waiver-of-fiduciary-duties',
  no_partnership_agency_or_employment = 'no-partnership-agency-or-employment',
  limitation_of_liability = 'limitation–of–liability',
  indemnification = 'indemnification',
  injunction = 'injunction',
  force_majeure = 'force–majeure',
  applicable_law = 'applicable–law',
  disputes_resolution = 'disputes–resolution',
  communication = 'communication',
  modification = 'modification',
  no_waiver = 'no-waiver',
  entire_agreement = 'entire–agreement',
  personal_data = 'personal–data',
  term_termination_and_survival = 'term–termination–and–survival',
  language = 'language',
  assignability = 'assignability',
  severability = 'severability',
  payments = 'payments',
}

export enum ServiceContractIDs {
  interpretation = 'interpretation',
  services = 'services',
  compensation_and_payment_terms = 'compensation-and-payment-terms',
  intellectual_property_rights = 'intellectual-property-rights',
  confidential_information = 'confidential-information',
  warranties_and_representations = 'warranties-and-representations',
  limitation_of_liability = 'limitation-of-liability',
  indemnification = 'indemnification',
  term_and_termination = 'term-and-termination',
  no_partnership_or_agency = 'no-partnership-or-agency',
  applicable_law_and_dispute_resolution = 'applicable-law-and-dispute-resolution',
  personal_data_and_data_protection = 'personal-data-and-data-protection',
  miscellaneous = 'miscellaneous',
}

export enum DataProcessingIDs {
  definitions = 'definitions',
  data_processing = 'data-processing',
  instructions = 'instructions',
  confidentiality = 'confidentiality',
  security = 'security',
  sub_processing = 'sub-processing',
  assistance_with_data_subject_requests = 'assistance-with-data-subject-requests',
  security_incident_notification = 'security-incident-notification',
  audits_assessment_and_consultations = 'audits-assessment-and-consultations',
  transfers_of_personal_data = 'transfers-of-personal-data',
  termination_of_the_dpa = 'termination-of-the-dpa',
  return_or_deletion_of_customer_data = 'return-or-deletion-of-customer-data',
  duties_to_inform = 'duties-to-inform',
  entire_agreement_conflict = 'entire-agreement-conflict',
  exhibit_I = 'exhibit-I',
  exhibit_I_annex_I = 'exhibit-I-annex-I',
  exhibit_I_annex_II = 'exhibit-I-annex-II',
  exhibit_I_annex_III = 'exhibit-I-annex-III',
  exhibit_I_annex_IV = 'exhibit-I-annex-IV',
}

export enum PrivacyNoticeIDs {
  introduction = 'introduction',
  contact_details = 'contact-details',
  our_principles = 'our-principles',
  what_data_we_collect = 'what-data-we-collect',
  how_we_use_data = 'how-we-use-data',
  how_we_share_data = 'how-we-share-data',
  how_long_we_retain_data = 'how-long-we-retain-data',
  what_are_your_rights = 'what-are-your-rights',
  third_party_links = 'third-party-links',
  data_of_minors = 'data-of-minors',
  changes_to_this_privacy_notice = 'changes-to-this-privacy-notice',
}
