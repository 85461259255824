import { Navigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import AuthLayout from 'layouts/AuthLayout';

import { PrivatePaths } from 'constants/routes';
import { LocalStorageKeys } from 'constants/constants';

const PublicRoute = () => {
  const [value] = useLocalStorage<string>(LocalStorageKeys.TOKEN);
  const [OTPDevice] = useLocalStorage<string>(LocalStorageKeys.OTP_DEVICE_ID);

  return !value || !OTPDevice ? (
    <AuthLayout />
  ) : (
    <Navigate to={PrivatePaths.INDEX} />
  );
};

export default PublicRoute;
