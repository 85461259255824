import { useMutation } from 'react-query';
import axios from 'axios';

import type { AxiosError } from 'axios';
import { TwoFADevice } from 'components/TwoFA/models';

const useSendOTPCode = (onSuccess?: (device: TwoFADevice) => void) => {
  const mutation = useMutation<TwoFADevice, AxiosError, TwoFADevice>(
    async (device: TwoFADevice) => {
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/otp/send/`, {
          device_id: device.persistent_id,
        });

        return device;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess,
    }
  );

  return mutation;
};

export default useSendOTPCode;
