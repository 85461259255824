import { useTranslation } from 'react-i18next';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';

import type { MultiValue, SingleValue } from 'react-select';
import type { SelectOption } from 'types/models';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import InputWrapper from 'components/common/InputWrapper';
import NumberField from 'components/common/NumberField';
import Button from 'components/common/Button';
import MultiSelectWithAll from 'components/common/MultiSelectWithAll';

import { CustomDefaultValue, MAX, IconsNames } from 'constants/constants';

import { ReactComponent as DeleteSVG } from 'assets/images/common/trash.svg';

import styles from './index.module.scss';

type Props = {
  onChange: (newValue: MultiValue<SelectOption>) => void;
  options: SelectOption[];
  value?: SelectOption[];
  customFieldPath: 'custom_revenue' | 'custom_size';
};

const CustomValueSelect = ({
  options,
  onChange,
  value,
  customFieldPath,
}: Props) => {
  const { t } = useTranslation();
  const {
    control,
    clearErrors,
    formState: {
      errors: { campaign_specification: errors },
    },
  } = useFormContext<CreateCampaignValues>();
  const {
    fields: customFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `campaign_specification.${customFieldPath}`,
  });

  const addCustom = () => {
    append({
      from: 0,
      to: MAX,
    });
  };

  const selectHandler = (
    values: MultiValue<SelectOption> | SingleValue<SelectOption>
  ) => {
    if (Array.isArray(values)) {
      const isCustomChosen = values?.some(
        item => item.value === CustomDefaultValue.custom
      );
      if (isCustomChosen) {
        addCustom();
      }

      onChange(
        values.filter(option => option.value !== CustomDefaultValue.custom)
      );
    }
    if (!values) onChange([]);
  };

  const resetCustomValue = (index: number) => {
    clearErrors(`campaign_specification.${customFieldPath}.${index}`);
    remove(index);
  };

  const isError =
    (errors && customFieldPath in errors && errors[customFieldPath]) || '';
  const isCustomFields = !!customFields.length;

  return (
    <div className={styles.wrapper}>
      <MultiSelectWithAll
        isMulti
        options={
          isCustomFields
            ? options.filter(item => item.value !== CustomDefaultValue.custom)
            : options
        }
        onChange={selectHandler}
        value={value}
        placeholder={t('common.field.select')}
      />
      {isCustomFields && (
        <ul className={styles.custom__list}>
          {customFields.map((item, index) => (
            <li className={styles.custom} key={item.id}>
              <InputWrapper
                wrapperClasses={styles.custom__label}
                validationError={
                  isError && isError[index] ? isError[index]?.from?.message : ''
                }
                isMediumInput
              >
                <Controller
                  control={control}
                  name={`campaign_specification.${customFieldPath}.${index}.from`}
                  render={({ field }) => (
                    <NumberField
                      field={field}
                      placeholder={t('common.field.from')}
                    />
                  )}
                />
              </InputWrapper>
              <InputWrapper
                wrapperClasses={styles.custom__label}
                validationError={
                  isError && isError[index] ? isError[index]?.to?.message : ''
                }
                isMediumInput
              >
                <Controller
                  control={control}
                  name={`campaign_specification.${customFieldPath}.${index}.to`}
                  render={({ field }) => (
                    <input
                      type="text"
                      {...field}
                      placeholder={t('common.field.to')}
                    />
                  )}
                />
              </InputWrapper>
              <button
                type="button"
                className={styles.delete}
                onClick={e => {
                  e.preventDefault();
                  resetCustomValue(index);
                }}
              >
                <DeleteSVG />
              </button>
            </li>
          ))}
          <Button
            type="button"
            iconProps={{ name: IconsNames.plus }}
            className={styles.button}
            onClick={addCustom}
          >
            {t('common.button.add-custom')}
          </Button>
        </ul>
      )}
    </div>
  );
};

export default CustomValueSelect;
