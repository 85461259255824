import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';

import { TwoFADevice, TwoFATypes } from 'components/TwoFA/models';
import type { AxiosError } from 'axios';

import useAuth, { User } from 'contexts/AuthContext';

type NewEmailDeviceFormValues = {
  email: string;
  password: string;
};

type NewSMSDeviceFormValues = {
  phone_number: string;
  password: string;
};

type PasswordType = {
  password: string;
};

type Props = {
  devices?: TwoFADevice[];
  type: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setResponseData?: React.Dispatch<React.SetStateAction<TwoFADevice | null>>;
};

const useAddAuthMethod = ({
  devices,
  type,
  setCurrentStep,
  setResponseData,
}: Props) => {
  const { axios: axiosInstance } = useAuth();
  const queryClient = useQueryClient();

  const {
    mutate,
    isLoading,
    data: newDeviceResponse,
    error,
  } = useMutation<
    TwoFADevice,
    AxiosError,
    NewEmailDeviceFormValues | NewSMSDeviceFormValues | PasswordType
  >(
    async values => {
      try {
        if (devices) {
          devices.forEach(async device => {
            await axiosInstance.delete(
              `/accounts/devices/${device.persistent_id}/`,
              {
                data: values,
              }
            );
          });
        }
        const { data } = await axiosInstance.post<TwoFADevice>(
          `/accounts/${type}-devices/`,
          values
        );

        if (type !== TwoFATypes.totp) {
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/otp/send/`, {
            device_id: data.persistent_id,
          });
        }

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: data => {
        setCurrentStep(prev => prev + 1);
        setResponseData?.(data);
        const oldProfileData = queryClient.getQueryData<User>('profile');
        if (oldProfileData) {
          const newDevices = [...(oldProfileData.devices || []), data];
          const filtered = newDevices.filter(
            item =>
              !devices?.some(
                device => device.persistent_id === item.persistent_id
              )
          );
          queryClient.setQueryData<User>('profile', {
            ...oldProfileData,
            devices: devices ? filtered : newDevices,
          });
        }
      },
    }
  );

  return { mutate, newDeviceResponse, error, isLoading };
};

export default useAddAuthMethod;
