import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import type { AxiosError } from 'axios';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { CampaignResponse } from 'types/models';
import type { UseFormReturn } from 'react-hook-form';
import type { UseMutateFunction } from 'react-query';

import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';
import useSaveReportTemplate from 'components/CreateCampaign/useSaveReportTemplate';
import useSaveCampaignAsset from 'components/CreateCampaign/useSaveCampaignAsset';
import useSaveTAL from 'components/CreateCampaign/useSaveTAL';
import useSaveSAL from 'components/CreateCampaign/useSaveSAL';
import useSaveCampaignScript from 'components/CreateCampaign/useSaveCampaignScript';

import { CampaignCreatingStep } from 'constants/constants';

type Props = {
  confirmNavigation: (shouldReplace?: boolean) => void;
  campaignId: number | string;
  step: CampaignCreatingStep;
  formMethods: UseFormReturn<CreateCampaignValues>;
  saveDraft: UseMutateFunction<
    {
      campaign: CampaignResponse;
      callback: () => void;
    },
    AxiosError,
    {
      campaignData: CreateCampaignValues;
      callback: () => void;
    },
    unknown
  >;
};

const SaveDraftModal = ({
  confirmNavigation,
  step,
  formMethods,
  saveDraft,
  campaignId,
}: Props) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const { mutate: saveReportMapping, isLoading: isSaveReportTemplateLoading } =
    useSaveReportTemplate(campaignId);
  const { mutate: saveAsset, isLoading: isSaveAssetLoading } =
    useSaveCampaignAsset(campaignId);
  const { mutate: saveSALFile, isLoading: isSaveSALLoading } = useSaveSAL({
    campaignId,
  });
  const { mutate: saveTALFile, isLoading: isSaveTALLoading } = useSaveTAL({
    campaignId,
  });
  const { mutate: saveScript, isLoading: isSaveScriptLoading } =
    useSaveCampaignScript({ campaignId });

  return (
    <>
      <ModalWindow
        title={t('campaign.save-campaign-progress-confirmation')}
        positiveButtonProps={{
          onClick: () => {
            closeModal();
            if (step === CampaignCreatingStep['fourth-step']) {
              return confirmNavigation();
            }
            const data = formMethods.getValues();
            if (data.campaign_files?.report_template) {
              saveReportMapping(data.campaign_files?.report_template);
            }
            if (data.campaign_files?.assets?.length) {
              data.campaign_files?.assets.forEach(asset => {
                saveAsset({
                  asset,
                  id: asset.id,
                });
              });
            }
            if (data.campaign_files?.suppression_accounts?.length) {
              data.campaign_files.suppression_accounts.forEach(file => {
                saveSALFile({ file });
              });
            }
            if (data.campaign_files?.target_accounts?.length) {
              data.campaign_files.target_accounts.forEach(file => {
                saveTALFile({ file });
              });
            }
            if (data.script_builder) {
              saveScript(data.script_builder);
            }
            saveDraft({
              campaignData: data,
              callback: confirmNavigation,
            });
          },
          green: true,
          children: t('common.button.yes'),
        }}
        negativeButtonProps={{
          children: t('common.button.no'),
          red: true,
          onClick: () => {
            closeModal();
            queryClient.removeQueries([
              'campaign-create-disable-requests',
              campaignId.toString(),
            ]);
            queryClient.removeQueries([
              'campaign-list',
              user?.current_group_name,
            ]);
            confirmNavigation();
          },
        }}
      />
      {(isSaveReportTemplateLoading ||
        isSaveAssetLoading ||
        isSaveSALLoading ||
        isSaveTALLoading ||
        isSaveScriptLoading) && <LoaderScreen />}
    </>
  );
};

export default SaveDraftModal;
