import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import { useDebounce } from 'react-use';
import { useInfiniteQuery, useMutation } from 'react-query';
import cn from 'classnames';

import type { AxiosError } from 'axios';
import type { CampaignAssetFileResponse, PaginationReturn } from 'types/models';

import ModalWindow from 'components/common/ModalWindow';
import ToggleSwitch from 'components/common/ToggleSwitch';
import InputWrapper from 'components/common/InputWrapper';
import Loader from 'components/common/LoaderScreen/Loader';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';

import IconSVG from 'components/UI/IconSVG';

import SelectAssetItem from 'components/CreateCampaign/Step1/Asset/AddExistingAssetModal/SelectAssetItem';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';
import useObserver from 'hooks/useObserver';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  campaignId: number | string;
  handleAddExistingAssets: (
    assets: CampaignAssetFileResponse[]
  ) => Promise<void>;
};

enum TableAccessors {
  name = 'name',
  type = 'type',
  product = 'product',
  selection = 'selection',
}

const DEBOUNCE_DELAY = 250;
const PAGE_SIZE = 20;

export type RowsType = { [key in TableAccessors]: number | string };

const AddExistingAssetModal = ({
  campaignId,
  handleAddExistingAssets,
}: Props) => {
  const { t } = useTranslation();
  const { axios } = useAuth();
  const { closeModal } = useModal();
  const [isShowOnlySelected, setIsShowOnlySelected] = useState(false);
  const [unselectConfirmationAsset, setUnselectConfirmationAsset] =
    useState<CampaignAssetFileResponse | null>(null);
  const [selectedAssets, setSelectedAssets] = useState<
    CampaignAssetFileResponse[]
  >([]);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  useDebounce(() => setDebouncedValue(searchValue), DEBOUNCE_DELAY, [
    searchValue,
  ]);

  const loadMoreRef = useRef(null);
  const assetsListRef = useRef(null);

  const {
    data: assets,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    isRefetching,
  } = useInfiniteQuery<
    PaginationReturn<CampaignAssetFileResponse[]>,
    AxiosError
  >(
    ['available-existing-assets', campaignId, debouncedValue],
    async ({ pageParam }) => {
      try {
        let page;
        if (typeof pageParam === 'string') {
          const url = new URL(pageParam);
          page = url.searchParams.get('page');
        }
        const pageNumber = page || 1;

        const { data } = await axios.get<
          PaginationReturn<CampaignAssetFileResponse[]>
        >(
          `/campaigns/${campaignId}/available-assets/?search=${debouncedValue}&page=${pageNumber}&page_size=${PAGE_SIZE}`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getNextPageParam: page => page.next || undefined,
    }
  );

  const {
    isLoading: isSubmitLoading,
    error: submitError,
    mutate,
  } = useMutation<void, AxiosError>(
    async () => {
      try {
        await axios.post(`/campaigns/${campaignId}/connect-assets/`, {
          assets: selectedAssets.map(asset => asset.id),
        });
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => {
        await handleAddExistingAssets(selectedAssets);
        closeModal();
      },
    }
  );

  useObserver(
    loadMoreRef,
    (isIntersecting: boolean) => {
      if (isIntersecting) fetchNextPage();
    },
    { root: assetsListRef.current, rootMargin: '30px' }
  );

  const handleUnselectAsset = () => {
    if (unselectConfirmationAsset)
      setSelectedAssets(selected =>
        selected.filter(item => item.id !== unselectConfirmationAsset.id)
      );
    setUnselectConfirmationAsset(null);
  };

  const handleSelectAsset = (
    isSelected: boolean,
    assetData: CampaignAssetFileResponse
  ) => {
    if (isSelected) {
      return setUnselectConfirmationAsset(assetData);
    }

    setSelectedAssets(selected => [...selected, assetData]);
  };

  const assetList = assets?.pages.map(page => page.results).flat();
  const errorMessage = error || submitError;
  const isEmptyMessageVisible =
    isSuccess && !assetList?.length && !isRefetching;

  return (
    <>
      <ModalWindow
        primaryTitle={`${t('common.button.add')} ${t(
          'common.button.existing-asset'
        ).toLowerCase()}`}
        className={styles.modal}
        positiveButtonProps={{
          children: t('common.button.cancel'),
          white: true,
          onClick: closeModal,
        }}
        negativeButtonProps={{
          children: t('common.button.add-selected', {
            count: selectedAssets.length,
          }),
          disabled: !selectedAssets.length,
          onClick: () => mutate(),
        }}
        isNarrowButtons
        closeOnClickAway={!unselectConfirmationAsset}
      >
        <div className={styles.controls}>
          <span className={styles.tab}>
            {t('common.field.asset')} ({selectedAssets.length})
          </span>
          <InputWrapper
            isMediumInput
            isErrorHidden
            wrapperClasses={styles.search}
          >
            {isRefetching && !isFetchingNextPage ? (
              <Loader size={20} className={styles.magnifier} />
            ) : (
              <IconSVG
                name={IconsNames.magnifier}
                className={styles.magnifier}
              />
            )}
            <input
              type="text"
              value={searchValue}
              onChange={e => setSearchValue(e.currentTarget.value)}
              placeholder={t('common.field.search')}
              aria-label="search existing assets"
            />
          </InputWrapper>
        </div>

        <InputWrapper
          label="common.button.show-selected-only"
          wrapperClasses={styles.toggle}
          isErrorHidden
        >
          <ToggleSwitch
            id="show selected only"
            checked={isShowOnlySelected}
            onChange={setIsShowOnlySelected}
          />
        </InputWrapper>
        <div
          className={cn(styles.fields, {
            [styles.fields__empty]: isEmptyMessageVisible,
          })}
        >
          {isSuccess && !!assetList?.length && (
            <>
              <span>{t('common.field.name')}</span>
              <span>{t('common.field.type')}</span>
              <span>{t('common.field.product')}</span>
            </>
          )}
          {isEmptyMessageVisible && (
            <p className={styles.empty}>
              {searchValue
                ? t('campaign.no-assets-found')
                : t('campaign.there-no-assets')}
            </p>
          )}
        </div>
        <div className={styles.wrapper}>
          {isLoading && <Loader size={20} className={styles.loader} />}
          {errorMessage && <Error message={getResponseError(errorMessage)} />}

          <ul
            className={cn(styles.list, {
              [styles.hidden]: isShowOnlySelected,
            })}
            ref={assetsListRef}
          >
            {assetList?.map(item => (
              <SelectAssetItem
                key={item.id}
                data={item}
                isSelected={selectedAssets.some(asset => asset.id === item.id)}
                onSelectAsset={handleSelectAsset}
              />
            ))}

            <li
              ref={loadMoreRef}
              className={cn({ [styles.more]: hasNextPage })}
            >
              {hasNextPage && isFetchingNextPage && <Loader size={20} />}
            </li>
          </ul>
          {isShowOnlySelected && (
            <ul
              className={cn(styles.list, {
                [styles.selected]: isShowOnlySelected,
              })}
            >
              {selectedAssets
                .sort((a, b) => b.id - a.id)
                .map(item => (
                  <SelectAssetItem
                    key={item.id}
                    data={item}
                    isSelected
                    onSelectAsset={handleSelectAsset}
                  />
                ))}
            </ul>
          )}
        </div>
      </ModalWindow>

      {unselectConfirmationAsset && (
        <ModalWindow
          className={styles.confirmation}
          primaryTitle={`${t('common.button.unselect')} ${t(
            'common.field.asset'
          ).toLowerCase()}?`}
          description={t('common.modal.unselected-confirmation', {
            name: unselectConfirmationAsset.title,
          })}
          isNarrowButtons
          negativeButtonProps={{
            children: t('common.button.unselect'),
            type: 'button',
            onClick: handleUnselectAsset,
          }}
          positiveButtonProps={{
            children: t('common.button.cancel'),
            type: 'button',
            onClick: () => setUnselectConfirmationAsset(null),
            white: true,
          }}
        />
      )}

      {isSubmitLoading && <LoaderScreen />}
    </>
  );
};

export default AddExistingAssetModal;
