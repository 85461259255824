import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import type { AxiosError } from 'axios';
import type { ProductFormValues } from 'components/CreateProductContent/models';

import SectionWrapper from 'components/CreateProductContent/SectionWrapper';
import PreviewButton from 'components/CreateProductContent/PreviewButton';

import InputWrapper from 'components/common/InputWrapper';
import IconSVG from 'components/UI/IconSVG';

import { SCHEDULE_DEMO_MAX_CHARACTERS_AMOUNT } from 'components/CreateProductContent/validationSchema';

import { PrivatePaths } from 'constants/routes';
import { IconsNames, CustomDefaultValue } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  isFirstStepDone: boolean;
  error?: AxiosError | null;
};

const ButtonName: React.FC<Props> = ({ isFirstStepDone, error }) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<ProductFormValues>();

  const isCustomChosen = !!watch('schedule_demo_link');

  const [chosenOption, setChosenOption] = useState(CustomDefaultValue.default);

  useEffect(() => {
    if (isCustomChosen) {
      setChosenOption(CustomDefaultValue.custom);
    }
  }, [isCustomChosen]);

  useEffect(() => {
    if (chosenOption === CustomDefaultValue.default) {
      setValue('schedule_demo_link', '');
    }
  }, [chosenOption]);

  const displayButtonName =
    watch('schedule_demo_button_name') || t('common.button.get-demo');
  const isDefault = chosenOption === CustomDefaultValue.default;

  return (
    <SectionWrapper
      title={t('manage-products.button-name')}
      disabled={isFirstStepDone}
      iconName={IconsNames.list}
    >
      <p className={styles.subtitle}>
        {t('manage-products.text-for-main-button')}
      </p>
      <div className={styles.labelContainer}>
        <label
          htmlFor={CustomDefaultValue.default}
          className={cn(styles.label, {
            [styles.active]: isDefault,
          })}
        >
          <span className={styles.labelText}>{t('common.field.default')}</span>
          <input
            type="radio"
            id={CustomDefaultValue.default}
            name="option"
            checked={isDefault}
            onChange={() => setChosenOption(CustomDefaultValue.default)}
          />
        </label>
        <label
          htmlFor={CustomDefaultValue.custom}
          className={cn(styles.label, {
            [styles.active]: !isDefault,
          })}
        >
          <span className={styles.labelText}>{t('common.field.custom')}</span>
          <input
            id={CustomDefaultValue.custom}
            type="radio"
            name="option"
            onChange={() => setChosenOption(CustomDefaultValue.custom)}
            checked={!isDefault}
          />
        </label>
      </div>
      <div className={styles.inputContainer}>
        <InputWrapper
          wrapperClasses={styles.inputWrapper}
          label="common.field.button-name"
          validationError={errors?.schedule_demo_button_name?.message}
          submitError={error?.response?.data?.schedule_demo_button_name?.[0]}
          isMediumInput
          charactersLength={[
            displayButtonName?.length,
            SCHEDULE_DEMO_MAX_CHARACTERS_AMOUNT,
          ]}
        >
          <input
            type="text"
            placeholder={t('common.button.get-demo')}
            {...register('schedule_demo_button_name')}
          />
        </InputWrapper>
        <PreviewButton className={styles.displayButtonName}>
          {displayButtonName}
        </PreviewButton>
      </div>
      {!isDefault && (
        <InputWrapper
          wrapperClasses={styles.inputWrapper}
          label="common.field.link"
          validationError={errors?.schedule_demo_link?.message}
          submitError={error?.response?.data?.schedule_demo_link?.[0]}
          isMediumInput
        >
          <input type="text" {...register('schedule_demo_link')} />
        </InputWrapper>
      )}
      <div className={cn(styles.disclaimer, { [styles.warning]: !isDefault })}>
        <IconSVG
          name={isDefault ? IconsNames.info : IconsNames.warning_filled}
          className={styles.infoIcon}
        />
        <p>
          {isDefault
            ? t('manage-products.cta-disclaimer')
            : t('manage-products.cta-disclaimer-not-displayed')}
          <NavLink
            to={`/${PrivatePaths.REQUESTS}`}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{t('manage-requests.heading')}</span>
          </NavLink>{' '}
          {t('manage-products.page')}
        </p>
      </div>
    </SectionWrapper>
  );
};

export default ButtonName;
