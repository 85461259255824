import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import type { UseMutateFunction } from 'react-query';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { CampaignResponse, CampaignAssetFileResponse } from 'types/models';
import type { AxiosError } from 'axios';

import IconSVG from 'components/UI/IconSVG';
import Loader from 'components/common/LoaderScreen/Loader';

import HTMLForm from 'components/CreateCampaign/Step3/HTMLForm';
import SavedView from 'components/CreateCampaign/Step3/SavedView';

import useSaveCampaignAsset from 'components/CreateCampaign/useSaveCampaignAsset';

import { IconsNames, CampaignCreatingStep } from 'constants/constants';
import { AssetHTMLStatus } from 'constants/assets';

import styles from './index.module.scss';

type Props = {
  heading: string;
  isSubmitted: boolean;
  campaignId?: number;
  asset: CampaignAssetFileResponse;
  index: number;
  setIsSubmitError?: React.Dispatch<React.SetStateAction<boolean>>;
  campaignName?: string;
  updateCampaign: UseMutateFunction<
    CampaignResponse,
    AxiosError<any, any>,
    CampaignCreatingStep,
    unknown
  >;
};

const Accordion = ({
  heading,
  isSubmitted,
  campaignId,
  asset,
  updateCampaign,
  index,
  setIsSubmitError,
  campaignName,
}: Props) => {
  const { t } = useTranslation();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const { getValues, setValue } = useFormContext<CreateCampaignValues>();

  const { mutate: updateAsset, isLoading } = useSaveCampaignAsset(campaignId);

  const handleEditClick = () => {
    if (
      getValues('campaign_details.creating_step') !==
      CampaignCreatingStep['third-step']
    ) {
      updateCampaign(CampaignCreatingStep['third-step'], {
        onSuccess: () =>
          setValue(
            'campaign_details.creating_step',
            CampaignCreatingStep['third-step']
          ),
      });
    }
    updateAsset(
      {
        asset: { ...asset, asset_html_status: AssetHTMLStatus.draft },
        id: asset.id,
      },
      {
        onSettled: () => setIsDetailsOpen(true),
      }
    );
  };

  return (
    <details
      className={styles.details}
      open={isDetailsOpen}
      onToggle={(e: React.ChangeEvent<HTMLDetailsElement>) =>
        setIsDetailsOpen(e.target.open)
      }
    >
      <summary className={styles.summary}>
        <h2 className={styles.heading}>{heading}</h2>
        {isSubmitted && (
          <>
            <p className={styles.submitted}>
              <IconSVG name={IconsNames.success_checkmark} />
              {t('common.field.submitted')}
            </p>
            {isLoading ? (
              <Loader className={styles.edit} size={26} />
            ) : (
              <button
                type="button"
                onClick={handleEditClick}
                className={styles.edit}
              >
                <IconSVG name={IconsNames.edit} />
                {t('common.button.edit')}
              </button>
            )}
          </>
        )}
        <IconSVG name={IconsNames.select_arrow} className={styles.arrow} />
      </summary>
      <div className={styles.content}>
        {!isSubmitted ? (
          <HTMLForm
            index={index}
            isSubmitted={isSubmitted}
            setIsSubmitError={setIsSubmitError}
            isDetailsOpen={isDetailsOpen}
          />
        ) : (
          <SavedView
            data={asset}
            campaignId={campaignId}
            campaignName={campaignName}
          />
        )}
      </div>
    </details>
  );
};

export default Accordion;
