import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import cn from 'classnames';

import type { SelectOption } from 'types/models';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import ChooseOptionsSelect from 'components/common/ChooseOptionsSelect';
import InputWrapper from 'components/common/InputWrapper';
import IconSVG from 'components/UI/IconSVG';

import { companyOptions, leadOptions, IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  index: number;
  remove: (index: number) => void;
  baseLength: number;
  selectedOptions?: string[];
  value?: SelectOption;
};

const CustomAttribute = ({
  index,
  remove,
  baseLength,
  selectedOptions,
}: Props) => {
  const { control, setValue } = useFormContext<CreateCampaignValues>();
  const { field } = useController({
    control,
    name: `campaign_files.report_template.custom_attributes.${index}`,
  });
  const { t, i18n } = useTranslation();
  const translatedCompanyOptions = companyOptions
    .filter(option => !selectedOptions?.includes(option))
    .map(option => ({
      value: option,
      label: t(`common.field.${option.replace(/_/g, '-')}`),
    }));
  const translatedLeadOptions = leadOptions
    .filter(option => !selectedOptions?.includes(option))
    .map(option => ({
      value: option,
      label: t(`common.field.${option.replace(/_/g, '-')}`),
    }));

  const deleteItem = () => remove(index);

  const handleChange = (newValue: SelectOption | undefined) => {
    if (newValue) {
      setValue(
        `campaign_files.report_template.custom_attributes.${index}.name`,
        newValue.value
      );
    }
  };

  const number = baseLength + index + 1;

  return (
    <div className={styles.custom}>
      <span
        className={cn(styles.number, { [styles.matched]: !!field.value.name })}
      >
        {number}
      </span>
      <InputWrapper wrapperClasses={styles.select}>
        <ChooseOptionsSelect
          onChange={handleChange}
          value={
            field.value.name
              ? {
                  value: field.value.name,
                  label: i18n.exists(
                    `common.field.${field.value.name.replace(/_/g, '-')}`
                  )
                    ? t(`common.field.${field.value.name.replace(/_/g, '-')}`)
                    : field.value.name,
                }
              : undefined
          }
          firstOptions={{
            options: translatedLeadOptions,
            label: t('common.field.lead-info'),
          }}
          secondOptions={{
            options: translatedCompanyOptions,
            label: t('common.field.company-info'),
          }}
        />
      </InputWrapper>

      <button type="button" className={styles.delete} onClick={deleteItem}>
        <IconSVG name={IconsNames.delete} />
      </button>
    </div>
  );
};

export default CustomAttribute;
