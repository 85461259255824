import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import type { FieldArrayWithId } from 'react-hook-form';
import type {
  CreateCampaignValues,
  AssetValues,
} from 'components/CreateCampaign/models';
import type { CampaignAssetFileResponse } from 'types/models';

import InputWrapper from 'components/common/InputWrapper';
import ModalWindow from 'components/common/ModalWindow';
import NumberField from 'components/common/NumberField';
import CreateAssetModal from 'components/common/CreateAssetModal';
import LoaderScreen from 'components/common/LoaderScreen';
import EditableAssetItem from 'components/common/EditableAssetItem';

import AddProductPageModal from 'components/CreateCampaign/Step1/Asset/AddProductPageModal';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import { getIsAutomaticallyDistributed } from 'helpers/assets';
import getResponseError from 'helpers/getResponseError';

import useDeleteAsset from 'components/CreateCampaign/Step1/useDeleteAsset';
import useSaveCampaignAsset from 'components/CreateCampaign/useSaveCampaignAsset';

import { CampaignCreatingStep } from 'constants/constants';
import {
  AssetAttachedType,
  AssetDistributionType,
  AssetStatus,
} from 'constants/assets';

import styles from './index.module.scss';

type Props = {
  remove: (index: number) => void;
  index: number;
  item: FieldArrayWithId<CreateCampaignValues, 'campaign_files.assets', 'key'>;
  distributionType?: AssetDistributionType;
  distributionDifference?: number;
};

const AssetItem = ({ item, remove, index, distributionDifference }: Props) => {
  const { t } = useTranslation();
  const {
    setValue,
    control,
    watch,
    formState: { errors },
    getValues,
  } = useFormContext<CreateCampaignValues>();
  const { user, axios } = useAuth();
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutateAsync: saveAsset, isLoading } = useSaveCampaignAsset(
    getValues().id,
    setValue
  );
  const assets = watch('campaign_files.assets');
  const distributionType = watch('campaign_details.asset_distribution');
  const fieldData = assets?.length && assets[index];
  const { id } = fieldData || {};

  const removeAsset = () => {
    if (assets?.length === 2) {
      setValue(
        'campaign_details.asset_distribution',
        AssetDistributionType['no-preference']
      );
      setValue(
        'campaign_details.creating_step',
        CampaignCreatingStep['first-step']
      );
      assets?.forEach((field, inx) =>
        setValue(`campaign_files.assets.${inx}.distribution_value`, 0)
      );
    }
    remove(index);
  };

  const { mutate: deleteAsset, isLoading: isDeleteLoading } = useDeleteAsset({
    onSuccess: async () => {
      const campaignValues = getValues();

      const isDeletedAllAssetsAfterSubmitFirstStep =
        campaignValues.campaign_details.creating_step !==
        CampaignCreatingStep['first-step'];

      if (isDeletedAllAssetsAfterSubmitFirstStep) {
        await axios.patch(`/campaigns/${campaignValues.id}/`, {
          campaign_details: {
            creating_step: CampaignCreatingStep['first-step'],
          },
        });
      }
      removeAsset();
      setValue(
        'campaign_details.creating_step',
        CampaignCreatingStep['first-step']
      );
      queryClient.setQueryData<CampaignAssetFileResponse[]>(
        ['campaign', getValues().id.toString(), 'assets', user?.company.id],
        oldData => oldData?.filter(assetItem => assetItem.id !== id) || []
      );
    },
    onError: err => {
      openModal({
        Content: (
          <ModalWindow
            title={t('common.error.something-went-wrong')}
            errorMessage={getResponseError(err)}
          />
        ),
      });
    },
  });

  const handleDelete = () => {
    if (id) return deleteAsset({ assetId: id, campaignId: getValues().id });
    removeAsset();
  };

  const handleEditClick = () => {
    const onSubmit = (values: AssetValues) => {
      saveAsset(
        {
          asset: values,
          id: item.id,
        },
        {
          onSuccess: closeModal,
          onError: err => {
            openModal({
              Content: (
                <ModalWindow
                  title={t('common.error.something-went-wrong')}
                  errorMessage={getResponseError(err)}
                />
              ),
            });
          },
        }
      );
    };

    const typeOption = {
      value: item.type,
      label: t(`common.field.${item.type}`),
    };
    const productOption = {
      value: item.product_id || item.product,
      label: item.product_name,
    };

    if (item.asset_type === AssetAttachedType.product) {
      return openModal({
        Content: (
          <AddProductPageModal
            defaultValues={{
              title: item.title,
              description: item.description,
              type: typeOption,
              product: productOption,
              asset_type: item.asset_type,
            }}
            assetId={item.id}
            onSubmit={onSubmit}
          />
        ),
      });
    }
    openModal({
      Content: (
        <CreateAssetModal
          defaultValues={{
            ...item,
            content_type: {
              label: t(`common.field.${item.content_type}`),
              value: item.content_type,
            },
            landing_image: { renderLink: item.landing_image },
            type: typeOption,
            product: productOption,
          }}
          assetId={item.id}
          onSubmit={onSubmit}
          isSelectProductPage
          isInCampaignUse={item.active_campaign_exists}
          isEditWarningVisible={item.asset_status === AssetStatus.published}
        />
      ),
    });
  };

  const isValidationErrors = errors?.campaign_files?.assets?.[index];
  const isPercentage = distributionType === AssetDistributionType.percentage;
  const countPlaceholder = isPercentage ? '%' : 'common.field.no-of-leads';

  const isDistributionValue =
    distributionType && !getIsAutomaticallyDistributed(distributionType);

  return (
    <>
      <EditableAssetItem
        asset={item}
        onDeleteAsset={handleDelete}
        onEdit={handleEditClick}
        isDragDropEnabled={false}
      >
        {isDistributionValue && (
          <InputWrapper
            wrapperClasses={styles.distribution}
            isMediumInput
            validationError={isValidationErrors?.distribution_value?.message}
          >
            <Controller
              name={`campaign_files.assets.${index}.distribution_value`}
              control={control}
              render={({ field }) => (
                <NumberField
                  field={field}
                  placeholder={t(countPlaceholder)}
                  max={(distributionDifference || 0) + (field.value || 0)}
                />
              )}
            />
            {isPercentage && <span>%</span>}
          </InputWrapper>
        )}
      </EditableAssetItem>
      {(isDeleteLoading || isLoading) && <LoaderScreen />}
    </>
  );
};

export default AssetItem;
