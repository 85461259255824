import * as Yup from 'yup';

import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { Descendant } from 'slate';
import type { TestContextExtended } from 'utils/validations';

import {
  CustomDefaultValue,
  MAX,
  CampaignCreatingStep,
} from 'constants/constants';
import { AssetDistributionType } from 'constants/assets';

import { convertDescendantToString } from 'utils/richTextEditor';

import {
  REQUIRED_MESSAGE,
  GREATER_THAN_MESSAGE,
  MAX_LENGTH_MESSAGE,
  validationSchemaCampaignDelivery,
} from 'utils/validations';

function isValidFieldTo(
  value: string | undefined,
  parent: { from: number; to: number | string },
  createError: (
    params?: Yup.CreateErrorOptions | undefined
  ) => Yup.ValidationError,
  path: string
) {
  return (Number(parent.from) < Number(value) && value?.match(/^\d+$/)) ||
    value?.toLowerCase() === MAX
    ? true
    : createError({
        message: 'common.error.to-greater-than-from-or-max',
        path: path,
      });
}

export const validationSchemaCreateCampaign = (step: CampaignCreatingStep) => {
  const isFirstStep = step === CampaignCreatingStep['first-step'];
  const isThirdStep = step === CampaignCreatingStep['third-step'];
  const isFourthStep = step === CampaignCreatingStep['fourth-step'];
  if (isFirstStep) {
    return Yup.lazy(() =>
      Yup.object<Record<keyof CreateCampaignValues, Yup.AnySchema>>().shape({
        campaign_details: Yup.object().shape({
          name: Yup.string()
            .required(REQUIRED_MESSAGE)
            .onlyLettersSpacesNumbersCommaDashSlash()
            .test('name', REQUIRED_MESSAGE, value => !!value)
            .nullable(),
          delivery_emails: Yup.array().min(1, REQUIRED_MESSAGE),
          total_leads: Yup.number()
            .required(REQUIRED_MESSAGE)
            .typeError(REQUIRED_MESSAGE)
            .positive({ key: GREATER_THAN_MESSAGE, values: { number: 0 } }),
          contacts_per_company: Yup.number()
            .typeError(REQUIRED_MESSAGE)
            .min(3, { key: GREATER_THAN_MESSAGE, values: { number: 2 } })
            .required(REQUIRED_MESSAGE),
          cost_per_lead: Yup.number()
            .typeError(REQUIRED_MESSAGE)
            .required(REQUIRED_MESSAGE),
          is_qc_required: Yup.boolean().required(REQUIRED_MESSAGE),
          notice: Yup.string()
            .max(1000, {
              key: MAX_LENGTH_MESSAGE,
              values: { length: 1000 },
            })
            .nullable(),
          asset_distribution: Yup.string()
            .nullable()
            .test('asset_distribution', function (value) {
              const { from } = this as Yup.TestContext & TestContextExtended;
              const assets = from[1].value.campaign_files?.assets;

              if (assets?.length > 1 && !value) {
                return this.createError({
                  path: this.path,
                  type: 'required',
                  message: REQUIRED_MESSAGE,
                });
              }

              if (value === AssetDistributionType.percentage) {
                const percent = assets?.reduce(
                  // @ts-ignore
                  (acc, asset) => {
                    acc += Number(asset.distribution_value) || 0;
                    return acc;
                  },
                  0
                );

                if (percent !== 100) {
                  return this.createError({
                    path: 'campaign_details.asset_distribution',
                    message: {
                      key: 'common.error.asset-distribution-equal',
                      values: { count: '100%' },
                    },
                  });
                }

                return true;
              }

              if (value === AssetDistributionType['no-of-leads']) {
                const count = from[1].value.campaign_files?.assets?.reduce(
                  // @ts-ignore
                  (acc, asset) => {
                    acc += Number(asset.distribution_value) || 0;
                    return acc;
                  },
                  0
                );

                if (
                  +this.parent.total_leads !== +count &&
                  this.parent.total_leads
                ) {
                  return this.createError({
                    path: 'campaign_details.asset_distribution',
                    message: {
                      key: 'common.error.asset-distribution-equal',
                      values: {
                        count: this.parent.total_leads,
                      },
                    },
                  });
                }

                return true;
              }
              return true;
            }),
        }),
        campaign_specification: Yup.object()
          .shape({
            job_titles: Yup.array(),
            management_level: Yup.array()
              .of(
                Yup.object().shape({
                  value: Yup.string(),
                })
              )
              .nullable(),
            custom_revenue: Yup.array().of(
              Yup.object().shape({
                from: Yup.number()
                  .typeError(REQUIRED_MESSAGE)
                  .required(REQUIRED_MESSAGE),
                to: Yup.string()
                  .required(REQUIRED_MESSAGE)
                  .test({
                    name: 'to',
                    test: function (value) {
                      const { parent, createError, path } = this;
                      return isValidFieldTo(value, parent, createError, path);
                    },
                  }),
              })
            ),
            custom_size: Yup.array().of(
              Yup.object().shape({
                from: Yup.number()
                  .required(REQUIRED_MESSAGE)
                  .typeError(REQUIRED_MESSAGE),
                to: Yup.string()
                  .required(REQUIRED_MESSAGE)
                  .test({
                    name: 'to',
                    test: function (value) {
                      const { parent, createError, path } = this;
                      return isValidFieldTo(value, parent, createError, path);
                    },
                  }),
              })
            ),
            notice: Yup.string()
              .max(1000, {
                key: MAX_LENGTH_MESSAGE,
                values: { length: 1000 },
              })
              .nullable(),
          })
          .test({
            name: 'campaign_specification',
            test: function (value) {
              const isJobTitlesInvalid =
                this.parent.campaign_files?.target_accounts?.length &&
                (!value.job_titles || value.job_titles?.length === 0);

              return isJobTitlesInvalid
                ? this.createError({
                    message: REQUIRED_MESSAGE,
                    path: 'campaign_specification.job_titles',
                  })
                : true;
            },
          })
          .test({
            name: 'campaign_specification',
            test: function (value) {
              const isManagementLevelInvalid =
                this.parent.campaign_files?.target_accounts?.length &&
                (!value.management_level ||
                  value.management_level?.length === 0);

              return isManagementLevelInvalid
                ? this.createError({
                    message: REQUIRED_MESSAGE,
                    path: 'campaign_specification.management_level',
                  })
                : true;
            },
          }),
        campaign_delivery: validationSchemaCampaignDelivery,
        script_builder: Yup.object().shape({
          introduction_block: Yup.mixed().test(
            'introduction_block',
            REQUIRED_MESSAGE,
            (value: Descendant[]) =>
              value ? convertDescendantToString(value).length > 0 : true
          ),
          asset_block: Yup.mixed().test(
            'asset_block',
            REQUIRED_MESSAGE,
            (value: Descendant[]) =>
              value ? convertDescendantToString(value).length > 0 : true
          ),
          closing_block: Yup.mixed().test(
            'closing_block',
            REQUIRED_MESSAGE,
            (value: Descendant[]) =>
              value ? convertDescendantToString(value).length > 0 : true
          ),
          questions: Yup.array().of(
            Yup.object().shape({
              name: Yup.mixed().test(
                'name',
                REQUIRED_MESSAGE,
                (value: Descendant[]) =>
                  value ? convertDescendantToString(value).length > 0 : true
              ),
              answers: Yup.array().of(
                Yup.object().shape({
                  name: Yup.mixed().test(
                    'name',
                    REQUIRED_MESSAGE,
                    (value: Descendant[]) =>
                      value ? convertDescendantToString(value).length > 0 : true
                  ),
                })
              ),
            })
          ),
        }),
        campaign_files: Yup.object().shape({
          suppression_accounts: Yup.array().of(
            Yup.object().shape({
              first_name: Yup.object().nullable().optional(),
              last_name: Yup.object().nullable().optional(),
              company_name: Yup.object()
                .shape({
                  value: Yup.string(),
                })
                .test('value', REQUIRED_MESSAGE, function (value) {
                  const {
                    from: [, salFile],
                  } = this as Yup.TestContext & TestContextExtended;
                  const isNameSelected =
                    !!salFile.value.first_name || !!salFile.value.last_name;
                  if (!isNameSelected) return true;
                  if (!value) {
                    return this.createError({
                      path: `${this.path}.value`,
                    });
                  }

                  return true;
                })
                .nullable(),
              company_domain: Yup.object()
                .shape({
                  value: Yup.string(),
                })
                .test('value', REQUIRED_MESSAGE, function (value) {
                  const {
                    from: [, salFile],
                  } = this as Yup.TestContext & TestContextExtended;
                  const isNameSelected =
                    !!salFile.value.first_name || !!salFile.value.last_name;
                  if (!isNameSelected) return true;
                  if (!value) {
                    return this.createError({
                      path: `${this.path}.value`,
                    });
                  }

                  return true;
                })
                .nullable(),
            })
          ),
          target_accounts: Yup.array().of(
            Yup.object().shape({
              company_name: Yup.object().required(REQUIRED_MESSAGE).nullable(),
              company_domain: Yup.object()
                .required(REQUIRED_MESSAGE)
                .nullable(),
              name: Yup.string().required(REQUIRED_MESSAGE).nullable(),
            })
          ),
          assets: Yup.array()
            .of(
              Yup.object().shape({
                distribution_value: Yup.mixed()
                  .optional()
                  .test({
                    name: 'distribution_value',
                    test: function (
                      distributionValue: number | undefined | null
                    ) {
                      const {
                        from: [, campaignFiles, campaignData],
                      } = this as Yup.TestContext & TestContextExtended;

                      const assetDistributionType =
                        campaignData.value?.campaign_details
                          ?.asset_distribution;
                      const isNumberValueRequired =
                        assetDistributionType ===
                          AssetDistributionType['no-of-leads'] ||
                        assetDistributionType ===
                          AssetDistributionType.percentage;

                      if (
                        campaignFiles.value.assets.length > 1 &&
                        isNumberValueRequired &&
                        !Number(distributionValue)
                      ) {
                        return this.createError({
                          path: this.path,
                          message: {
                            key: GREATER_THAN_MESSAGE,
                            values: { number: 0 },
                          },
                        });
                      }

                      return true;
                    },
                  }),
              })
            )
            .min(1, REQUIRED_MESSAGE),
        }),
      })
    );
  }

  if (isThirdStep) {
    return Yup.lazy(() =>
      Yup.object<Record<keyof CreateCampaignValues, Yup.AnySchema>>().shape({
        campaign_files: Yup.object().shape({
          assets: Yup.array().of(
            Yup.object().shape({
              html_template_heading: Yup.string()
                .required(REQUIRED_MESSAGE)
                .typeError(REQUIRED_MESSAGE),
              html_template_company_name: Yup.string()
                .required(REQUIRED_MESSAGE)
                .typeError(REQUIRED_MESSAGE),
              html_template_sub_heading: Yup.string().nullable(),
              html_template_subject: Yup.string()
                .max(60, {
                  key: MAX_LENGTH_MESSAGE,
                  values: { length: 60 },
                })
                .required(REQUIRED_MESSAGE)
                .typeError(REQUIRED_MESSAGE),
              html_template_logo: Yup.mixed().required(REQUIRED_MESSAGE),
              html_template_image: Yup.mixed().required(REQUIRED_MESSAGE),
              html_template_privacy_value: Yup.string()
                .when(['html_template_privacy_is_default'], {
                  is: (privacy: string) =>
                    privacy === CustomDefaultValue.custom,
                  then: Yup.string()
                    .required(REQUIRED_MESSAGE)
                    .typeError(REQUIRED_MESSAGE)
                    .url('common.error.invalid-url'),
                  otherwise: Yup.string().nullable(),
                })
                .typeError(REQUIRED_MESSAGE),
              html_template_content_para: Yup.string()
                .required(REQUIRED_MESSAGE)
                .typeError(REQUIRED_MESSAGE),
              html_template_footer_content: Yup.string()
                .required(REQUIRED_MESSAGE)
                .typeError(REQUIRED_MESSAGE),
              html_template_privacy_is_default: Yup.string(),
            })
          ),
        }),
      })
    );
  }

  if (isFourthStep) {
    return Yup.lazy(() =>
      Yup.object<Record<keyof CreateCampaignValues, Yup.AnySchema>>().shape({
        campaign_delivery: validationSchemaCampaignDelivery,
      })
    );
  }

  return Yup.lazy(() => Yup.object());
};
