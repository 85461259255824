import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import type { ScheduleDemoValues } from 'components/ProductPage/models';

import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';
import Button from 'components/common/Button';
import Steps from 'components/common/Steps';

import TimeSlotPicker from 'components/ProductPage/GetDemoModal/TimeSlotPicker';
import UserInfo from 'components/ProductPage/GetDemoModal/UserInfo';

import { useScheduleDemo } from 'components/ProductPage/GetDemoModal/useScheduleDemo';

import { validationSchemaGetADemo } from 'utils/validations';

import { StepNavigation } from 'constants/constants';

import styles from './index.module.scss';

enum StepName {
  'share-data' = 'share-data',
  'choose-time-slot' = 'choose-time-slot',
}

const steps = [
  {
    name: StepName['choose-time-slot'],
    title: 'manage-products.choose-time-slot',
    index: 0,
    Component: TimeSlotPicker,
  },
  {
    name: StepName['share-data'],
    title: 'manage-products.share-data',
    index: 1,
    Component: UserInfo,
  },
];

type Props = {
  companySlug?: string;
  productSlug?: string;
  companyName: string;
  defaultValues: Partial<ScheduleDemoValues> & { email: string };
};

const GetDemoModal: React.FC<Props> = ({
  companySlug,
  productSlug,
  defaultValues,
  companyName,
}) => {
  const [step, setStep] = useState(steps[0]);
  const { t } = useTranslation();

  const methods = useForm<ScheduleDemoValues>({
    defaultValues,
    resolver:
      step.name === StepName['share-data']
        ? yupResolver(validationSchemaGetADemo)
        : undefined,
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useScheduleDemo({
    companySlug,
    productSlug,
    companyName,
  });

  const goToStep = (name: StepName | StepNavigation) => {
    const stepIndex = steps.findIndex(item => item.name === name);
    if (typeof stepIndex === 'number') setStep(steps[stepIndex]);
  };

  const isTimePicked = !!methods.watch('date') && !!methods.watch('timeslot');

  const onSubmit = (data: ScheduleDemoValues) => {
    const formattedData = {
      ...data,
      job_title: data.job_title.value,
      timeslot: `${data.date}${data.timeslot}`,
      client_timezone: data.client_timezone.value,
    };

    mutate(formattedData);
  };

  return (
    <>
      <ModalWindow
        primaryTitle={t('common.button.get-demo')}
        className={styles.modalContainer}
      >
        <Steps<StepName>
          steps={steps}
          step={step}
          goToStep={goToStep}
          className={styles.steps}
        />
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            aria-label="get a demo form"
          >
            <step.Component />

            <div className={styles.buttonContainer}>
              {step.index === 0 ? (
                <Button
                  className={styles.button}
                  aria-label="choose a time slot"
                  disabled={!isTimePicked}
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    goToStep(StepName['share-data']);
                  }}
                >
                  {t('common.button.choose-a-time-slot')}
                </Button>
              ) : (
                <Button
                  type="submit"
                  className={styles.button}
                  aria-label="schedule demo"
                >
                  {t('common.button.schedule-a-demo')}
                </Button>
              )}
            </div>
          </form>
        </FormProvider>
      </ModalWindow>
      {isLoading && <LoaderScreen />}
    </>
  );
};

export default GetDemoModal;
