import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isMobile } from 'react-device-detect';

import type { AxiosError } from 'axios';
import type { CampaignResponse, Milestone } from 'types/models';
import type { MilestonesFormValues } from 'components/CampaignManage/models';

import MilestoneItem from 'components/CampaignManage/MilestoneItem';
import CampaignTable from 'components/CampaignDetails/InfoSectionWrapper';
import ToggleSwitch from 'components/common/ToggleSwitch';
import Button from 'components/common/Button';
import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';

import getResponseError from 'helpers/getResponseError';

import { validationMilestones } from 'utils/validations';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';

import {
  IconsNames,
  UserTypes,
  MILESTONE_END_DATE_DIFFERENCE,
} from 'constants/constants';
import { AssetDistributionType } from 'constants/assets';

import styles from './index.module.scss';

type Props = {
  campaignDelivery: CampaignResponse['campaign_delivery'];
  campaignDetails: CampaignResponse['campaign_details'];
  campaignId: number;
  isAssetsDistributionVisible: boolean;
};

const DeliveryDetails = ({
  campaignDelivery,
  campaignDetails,
  campaignId,
  isAssetsDistributionVisible,
}: Props) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const { user, axios } = useAuth();
  const queryClient = useQueryClient();
  const { convertMilestoneDate, convertMilestonesRequestBody } =
    useConvertCampaignData();
  const [autoQualifiedToggle, setAutoQualifiedToggle] = useState<
    boolean | undefined
  >(campaignDetails.is_auto_qualified);

  const isVendorManager =
    user && user.current_group_name === UserTypes.vendorManager;

  const {
    start_date: startDate,
    end_date: endDate,
    milestones: milestoneData,
    milestone_deadline_type: milestoneType,
    is_front_load_scoring: frontLoad,
  } = campaignDelivery || {};

  const {
    cost_per_lead: costPerLead,
    total_leads: totalLeads,
    asset_distribution: assetDistribution,
  } = campaignDetails || {};

  const stateOffers = queryClient.getQueryState([
    'vendor-manager-offers',
    campaignId.toString(),
    user?.company.id.toString(),
  ]);

  const isNoPreference =
    assetDistribution &&
    assetDistribution === AssetDistributionType['no-preference'];

  const renderAssetLeadsAllocation =
    !isNoPreference &&
    milestoneData[0].leads_per_asset.map(milestone => milestone);

  const isOffersExists =
    Array.isArray(stateOffers?.data) && !!stateOffers?.data.length;

  const onError = (err: AxiosError) => {
    openModal({
      Content: (
        <ModalWindow
          title={t('common.error.something-went-wrong')}
          errorMessage={getResponseError(err)}
        />
      ),
    });
  };

  const { mutate, isLoading } = useMutation<
    Milestone[],
    AxiosError,
    Milestone[]
  >(
    async (values: Milestone[]) => {
      try {
        const response = await axios.put<{ milestones: Milestone[] }>(
          `/campaigns/${campaignId}/milestone-requested-leads/`,
          { milestones: values }
        );
        return response.data.milestones;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: response => {
        const previousMilestones = queryClient.getQueryData<CampaignResponse>([
          'campaign',
          campaignId.toString(),
          user?.current_group_name,
          user?.company.id.toString(),
        ]);

        queryClient.setQueryData(
          [
            'campaign',
            campaignId.toString(),
            user?.current_group_name,
            user?.company.id.toString(),
          ],
          {
            ...previousMilestones,
            campaign_delivery: {
              ...previousMilestones?.campaign_delivery,
              milestones: response,
            },
          }
        );
        queryClient.removeQueries([
          'vendor-manager-offers',
          campaignId.toString(),
          user?.company.id.toString(),
        ]);
        closeModal();
      },
      onError,
    }
  );

  const Content = () => {
    const formMethods = useForm<MilestonesFormValues>({
      defaultValues: {
        campaign_details: campaignDetails,
        campaign_delivery: {
          ...campaignDelivery,
          milestones: milestoneData,
        },
      },
      resolver: yupResolver(validationMilestones),
      mode: 'onSubmit',
    });
    const { fields, append, remove } = useFieldArray({
      control: formMethods.control,
      name: 'campaign_delivery.milestones',
    });

    const addMilestone = () => {
      const milestonesData =
        formMethods.getValues().campaign_delivery.milestones;
      const lastMilestoneDate =
        milestonesData[milestonesData.length - 1]?.delivery_date;

      append({
        total_leads_amount: 0,
        delivery_date: dayjs(lastMilestoneDate || new Date()).format(
          'YYYY-MM-DD'
        ),
      });
    };

    const onSubmit = (values: MilestonesFormValues) => {
      mutate(
        convertMilestonesRequestBody({
          milestones: values.campaign_delivery.milestones,
          campaignEndDate: values.campaign_delivery.end_date,
          milestoneDeadlineType:
            values.campaign_delivery.milestone_deadline_type,
        })
      );
    };

    return (
      <>
        <div className={styles.deadlines}>
          <p className={styles.info}>
            {t('common.field.campaign-start-date')}
            <span>
              {dayjs(campaignDelivery.start_date).format('DD/MM/YYYY')}
            </span>
          </p>
          <p className={styles.info}>
            {t('common.field.campaign-end-date')}
            <span>{dayjs(campaignDelivery.end_date).format('DD/MM/YYYY')}</span>
          </p>
          <p className={styles.info}>
            {t('common.error.max-milestone-date')}
            <span>
              {dayjs(campaignDelivery.start_date)
                .subtract(MILESTONE_END_DATE_DIFFERENCE)
                .format('DD/MM/YYYY')}
            </span>
          </p>
        </div>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <ul className={styles.list}>
            {fields?.map((milestone, index) => (
              <MilestoneItem
                type={milestoneType}
                key={milestone.id}
                index={index}
                formMethods={formMethods}
                startDate={startDate}
                endDate={endDate}
                remove={remove}
              />
            ))}
          </ul>
          {isLoading && <LoaderScreen />}
          <Button
            onClick={addMilestone}
            type="button"
            iconProps={{ name: IconsNames.plus }}
            className={styles.button}
            white
          >
            {t('common.button.add-milestone')}
          </Button>

          <Button type="submit" isBig className={styles.button}>
            {t('common.button.submit')}
          </Button>
        </form>
      </>
    );
  };

  const { mutate: switchToggle } = useMutation<void, AxiosError, boolean>(
    ['auto-qualified'],
    async (value: boolean) => {
      try {
        await axios.put<void>(`/campaigns/${campaignId}/auto-qualify-leads/`, {
          is_auto_qualified: value,
        });
      } catch (err) {
        throw err;
      }
    },
    {
      onError,
    }
  );

  const openMilestonesModal = () => {
    openModal({
      Content: (
        <ModalWindow primaryTitle={t('campaign.milestones')}>
          <Content />
        </ModalWindow>
      ),
    });
  };

  return (
    <CampaignTable className={styles.table}>
      <CampaignTable.Header>
        <CampaignTable.Title>
          {t('campaign.delivery-details')}
        </CampaignTable.Title>
      </CampaignTable.Header>
      <CampaignTable.Content>
        <div className={styles.item}>
          <span className={styles.item__title}>
            {t('common.field.start-date')}:
          </span>
          <span className={styles.item__value}>
            {dayjs(startDate).format('DD/MM/YYYY')}
          </span>
        </div>
        <div className={styles.item}>
          <span className={styles.item__title}>
            {t('common.field.end-date')}:
          </span>
          <span className={styles.item__value}>
            {dayjs(endDate).format('DD/MM/YYYY')}
          </span>
        </div>
        <div className={styles.item}>
          <span className={styles.item__title}>
            {t('common.field.front-load')}:
          </span>
          <span className={styles.item__value}>
            <ToggleSwitch disabled checked={frontLoad} id="front load" />
          </span>
        </div>

        {isVendorManager && (
          <>
            <div className={styles.item}>
              <span className={styles.item__title}>
                {t('common.field.cpl')}:
              </span>
              <span className={styles.item__value}>{costPerLead}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.item__title}>
                {t('campaign.total-leads-amount')}
              </span>
              <span className={styles.item__value}>{totalLeads}</span>
            </div>
            <div className={styles.item}>
              <span className={styles.item__title}>
                {t('campaign.leads-allocation')}:
              </span>
              <span className={styles.item__value}>
                {assetDistribution && isAssetsDistributionVisible ? (
                  <div className={styles.leadsAllocation}>
                    {renderAssetLeadsAllocation
                      ? renderAssetLeadsAllocation.map(asset => (
                          <p key={asset.id} className={styles.leadsAllocation}>
                            {asset.title}: {asset.leads_amount}
                          </p>
                        ))
                      : t('asset-distribution.no-preference')}
                  </div>
                ) : (
                  '-'
                )}
              </span>
            </div>
            <div className={styles.item}>
              <span className={styles.item__title}>
                {t('common.field.auto-qualified')}:
              </span>
              <span className={styles.item__value}>
                <ToggleSwitch
                  id="switch"
                  disabled={isOffersExists}
                  checked={autoQualifiedToggle}
                  onChange={(isChecked: boolean) => {
                    setAutoQualifiedToggle(isChecked);
                    switchToggle(isChecked);
                  }}
                />
              </span>
            </div>
            <div className={styles.milestone}>
              <span className={styles.milestone__title}>
                {t('campaign.milestones')}:
              </span>
              <div className={styles.dates}>
                {milestoneData?.map((milestone, index) => (
                  <div className={styles.render_milestone} key={index}>
                    <p>
                      {convertMilestoneDate(
                        milestoneType,
                        milestone.delivery_date,
                        index === milestoneData.length - 1
                      )}
                    </p>
                    <p>
                      <span>{t('campaign.requested-leads')}: </span>
                      {milestone.total_leads_amount}
                    </p>
                  </div>
                ))}
              </div>
              {isVendorManager && !isOffersExists && !isMobile && (
                <Button
                  type="button"
                  onClick={openMilestonesModal}
                  className={styles.edit}
                  iconProps={{ name: IconsNames.edit }}
                >
                  {t('common.button.edit')}
                </Button>
              )}
            </div>
          </>
        )}
      </CampaignTable.Content>
    </CampaignTable>
  );
};

export default DeliveryDetails;
