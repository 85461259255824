import { useQuery } from 'react-query';

import type { ContactUploadResponse } from 'types/models';
import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';

import { UserTypes } from 'constants/constants';

const useGetUploadDetails = ({
  campaignId,
  reportId,
}: {
  campaignId?: string;
  reportId?: string;
}) => {
  const { axios, user } = useAuth();

  const query = useQuery<ContactUploadResponse, AxiosError>(
    ['contacts-uploads-details', campaignId, reportId],
    async () => {
      try {
        const { data } = await axios.get<ContactUploadResponse>(
          `/vendor-campaigns/${campaignId}/contacts-uploads/${reportId}/`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled:
        !!campaignId &&
        !!reportId &&
        user?.current_group_name === UserTypes.vendor,
    }
  );

  return query;
};

export default useGetUploadDetails;
