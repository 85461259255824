import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Table from 'components/common/Table';
import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import IconSVG from 'components/UI/IconSVG';

import useGetUploadDetails from 'components/CampaignsListDeliveries/ContactsUploadHistory/useGetUploadDetails';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

enum TableAccessors {
  email = 'email',
  personLinkedin = 'person-linkedin',
  companyLinkedin = 'company-linkedin',
  errorType = 'error-type',
}

const UploadErrorDetails = () => {
  const { t } = useTranslation();
  const { reportId, id } = useParams();
  const { data, isLoading, error, dataUpdatedAt } = useGetUploadDetails({
    campaignId: id,
    reportId,
  });

  const columnsData = useMemo(
    () => [
      {
        Header: t(`common.field.${TableAccessors.email}`),
        accessor: TableAccessors.email,
      },
      {
        Header: t(`common.field.${TableAccessors.personLinkedin}`),
        accessor: TableAccessors.personLinkedin,
      },
      {
        Header: t(`common.field.${TableAccessors.companyLinkedin}`),
        accessor: TableAccessors.companyLinkedin,
      },
      {
        Header: t(`common.field.${TableAccessors.errorType}`),
        accessor: TableAccessors.errorType,
        Cell: ({ value }: { value: string }) => (
          <p className={styles.name}>
            <IconSVG name={IconsNames.warning} className={styles.warning} />
            {value}
          </p>
        ),
      },
    ],
    []
  );

  const rowsData = useMemo(() => {
    return data?.execution_details.map(details => ({
      [TableAccessors.email]: details.contact.email,
      [TableAccessors.personLinkedin]:
        details.contact.linkedin_contact_profile_link,
      [TableAccessors.companyLinkedin]:
        details.contact.linkedin_contact_profile_link,
      [TableAccessors.errorType]: details.error,
    }));
  }, [data, dataUpdatedAt]);

  return (
    <>
      {!!rowsData?.length && (
        <Table
          wrapperClassName={styles.table}
          tableOptions={{
            columns: columnsData,
            data: rowsData || [],
          }}
          headClassName={styles.thead}
          rowClassName={styles.row}
          isEvenRowGrey
        />
      )}
      {isLoading && <Loader size={36} className={styles.loading} />}
      {error && <Error message={getResponseError(error)} />}
    </>
  );
};

export default UploadErrorDetails;
