import AdminContent from 'components/IntegrationsContent/AdminContent';
import VendorContent from 'components/IntegrationsContent/VendorContent';

import useAuth from 'contexts/AuthContext';

import { UserTypes } from 'constants/constants';

const IntegrationsContent = () => {
  const { user } = useAuth();

  const isAdmin = user?.current_group_name === UserTypes.admin;

  return isAdmin ? <AdminContent /> : <VendorContent />;
};

export default IntegrationsContent;
