import { useMutation, useQueryClient } from 'react-query';

import type {
  AssetValues,
  CreateCampaignValues,
} from 'components/CreateCampaign/models';
import type { AxiosError } from 'axios';
import type { CampaignAssetFileResponse } from 'types/models';
import type { UseFormSetValue } from 'react-hook-form';

import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';

import { createAssetFormData } from 'helpers/assets';

const useSaveCampaignAsset = (
  campaignId?: number | string,
  setFormValue?: UseFormSetValue<CreateCampaignValues>
) => {
  const { axios, user } = useAuth();
  const queryClient = useQueryClient();
  const { convertAssetFileResponse } = useConvertCampaignData();

  const mutation = useMutation<
    {
      asset: CampaignAssetFileResponse;
      index?: number;
    },
    AxiosError,
    {
      asset: Partial<CampaignAssetFileResponse | AssetValues>;
      id?: number;
      index?: number;
      privacyPolicyLink?: string;
    }
  >(
    async ({ asset, id, index, privacyPolicyLink }) => {
      try {
        const assetData = {
          asset,
          privacyPolicyLink,
        };

        if (id) {
          const updatedAsset = await axios.patch<CampaignAssetFileResponse>(
            `/campaigns/${campaignId}/assets/${id}/`,
            createAssetFormData(assetData),
            { headers: { 'Content-Type': 'multipart/form-data' } }
          );
          return { asset: updatedAsset.data, index };
        } else {
          const { data } = await axios.post<CampaignAssetFileResponse>(
            `/campaigns/${campaignId}/assets/`,
            createAssetFormData(assetData),
            { headers: { 'Content-Type': 'multipart/form-data' } }
          );

          return { asset: data, index };
        }
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: ({ asset: updatedAsset, index }) => {
        if (setFormValue && typeof index === 'number') {
          setFormValue(
            `campaign_files.assets.${index}`,
            convertAssetFileResponse(updatedAsset)
          );
        }

        queryClient.setQueryData<CampaignAssetFileResponse[]>(
          ['campaign', campaignId?.toString(), 'assets', user?.company.id],
          oldData => {
            if (!oldData) return [updatedAsset];

            const oldAssetsArray = oldData ? [...oldData] : [];
            const assetIndex = oldAssetsArray.findIndex(
              asset => asset.id === updatedAsset.id
            );

            if (assetIndex !== -1) {
              oldAssetsArray[assetIndex] = updatedAsset;
              return oldAssetsArray;
            }

            return [updatedAsset, ...oldAssetsArray];
          }
        );
      },
    }
  );

  return mutation;
};

export default useSaveCampaignAsset;
