import { components } from 'react-select';

import type { OptionProps, GroupBase } from 'react-select';

import IconSVG, { IconProps } from 'components/UI/IconSVG';

import styles from './index.module.scss';

const IconOption = <
  T extends { value: string; label: string; iconProps: IconProps },
  IsMulti extends boolean
>(
  props: OptionProps<T, IsMulti, GroupBase<T>>
) => {
  return (
    <components.Option {...props}>
      <div className={styles.container}>
        <IconSVG {...props.data.iconProps} />
        <label className={styles.label}>{props.label}</label>
      </div>
    </components.Option>
  );
};

export default IconOption;
