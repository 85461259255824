import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';
import type { ReportTemplateResponse } from 'types/models';

import ModalWindow from 'components/common/ModalWindow';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

const useGetReportTemplate = (campaignId?: string | number) => {
  const { axios } = useAuth();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const getReportTemplateRequestSettings = useQuery<
    ReportTemplateResponse | undefined,
    AxiosError
  >(
    ['campaign', campaignId, 'report-mapping'],
    async () => {
      try {
        const { data } = await axios.get<ReportTemplateResponse>(
          `/campaigns/${campaignId}/report-template-mapping/`
        );

        return data;
      } catch (error) {
        if ((error as AxiosError).response?.status !== 404) throw error;
      }
    },
    {
      enabled: !!campaignId,
      retry: (count, error) => error.response?.status !== 404,
      refetchOnWindowFocus: false,
      onError: err => {
        if (err.response?.status !== 404) {
          openModal({
            Content: (
              <ModalWindow
                title={t('common.error.something-went-wrong')}
                errorMessage={getResponseError(err)}
              />
            ),
          });
        }
      },
    }
  );

  return getReportTemplateRequestSettings;
};

export default useGetReportTemplate;
