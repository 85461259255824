import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const TwoFADescription = ({ text }: { text: string }) => {
  const { t } = useTranslation();

  return <p className={styles.description}>{t(text)}</p>;
};

export default TwoFADescription;
