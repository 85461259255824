import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReportUploadStatus } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  status: ReportUploadStatus;
};

const UploadStatus = ({ status }: Props) => {
  const { t } = useTranslation();

  return (
    <span
      className={cn(styles.status, {
        [styles.created]: status === ReportUploadStatus.created,
        [styles.failed]: status === ReportUploadStatus.failed,
        [styles.done]: status === ReportUploadStatus.done,
        [styles.inProgress]: status === ReportUploadStatus['in-progress'],
      })}
    >
      {t(`campaign-deliveries.upload-status.${status}`)}
    </span>
  );
};

export default UploadStatus;
