import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from 'react-query';
import cn from 'classnames';
import axios from 'axios';

import type { AxiosError } from 'axios';
import type { ProductAssetResponse } from 'types/models';
import type { FormValues } from 'components/AssetDownloadContent/models';

import Button from 'components/common/Button';
import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';
import RichText from 'components/RichText';

import Answer from 'components/AssetDownloadContent/Answer';

import useModal from 'contexts/ModalContext';
import { useIncreaseAssetDownloads } from 'hooks/api/useIncreaseAssetDownloads';

import { createDownloadFileLink } from 'helpers/downloadFile';
import {
  getDefaultEmailTermsAndConditions,
  getDefaultTermsAndConditions,
  getLearnMoreLinkTitle,
} from 'helpers/assets';
import getResponseError from 'helpers/getResponseError';

import { validationSchemaDownloadAsset } from 'utils/validations';

import { VIDEO_ASSET_CONTENT_TYPES } from 'constants/assets';

import styles from './index.module.scss';

type Props = {
  data: ProductAssetResponse;
  isDownloadDisabled: boolean;
  setIsVideoPreview: React.Dispatch<React.SetStateAction<boolean>>;
  isModalContent?: boolean;
  companySlug?: string;
  productSlug?: string;
  assetSlug?: string;
  contactShareId?: string;
  isAssetAvailable: boolean;
};

type SubmitAnswersPayload = {
  script_builder_option?: number;
  custom_answer: string;
}[];

const DownloadForm = ({
  data,
  isDownloadDisabled,
  setIsVideoPreview,
  isModalContent,
  companySlug,
  productSlug,
  assetSlug,
  contactShareId,
  isAssetAvailable,
}: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const queryClient = useQueryClient();

  const {
    control,
    getValues,
    setValue,
    formState: { isValid },
    trigger,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchemaDownloadAsset),
    mode: 'all',
    defaultValues: {
      questions: data.script_builder_questions?.map(question => ({
        ...question,
        answers: question.answers?.map(answer => ({
          ...answer,
          custom_answer: '',
          checked: false,
        })),
      })),
    },
  });

  const isVideoContent = VIDEO_ASSET_CONTENT_TYPES.some(
    type => data.content_type === type
  );

  const { mutate: increasePopularity } = useIncreaseAssetDownloads({
    companySlug,
    email: data.share_contact_data?.email,
    assetId: data.id,
  });

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    SubmitAnswersPayload
  >(
    async payload => {
      try {
        const { data: response } = await axios.post<{
          attached_file?: string;
          attached_file_link?: string;
        }>(
          `${process.env.REACT_APP_BACKEND_URL}/shares/${contactShareId}/companies/${companySlug}/products/${productSlug}/assets/${assetSlug}/submit-answers/`,
          { campaign_lead_answers: payload }
        );

        if (!isVideoContent) {
          increasePopularity();

          if (response.attached_file) {
            createDownloadFileLink(
              response.attached_file,
              data.attached_file_name
            );
          }
          if (response.attached_file_link) {
            window.open(response.attached_file_link, '_blank')?.focus();
          }

          setValue('questions', []);
        }

        await queryClient.refetchQueries([
          'asset-public',
          companySlug,
          productSlug,
          assetSlug,
        ]);
        if (isVideoContent) {
          setIsVideoPreview(true);
        }
      } catch (err) {
        throw err;
      }
    },
    {
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const { fields } = useFieldArray({
    control,
    name: 'questions',
    keyName: 'key',
  });

  const handleSubmitAnswers = () => {
    const payload = getValues()
      .questions.map(item => item.answers)
      .flat()
      .filter(item => item.checked)
      .map(item => ({
        script_builder_option: item.id,
        custom_answer: item.custom_answer,
      }));

    mutate(payload);
  };

  return (
    <>
      <div className={styles.form}>
        {!!fields.length && !isAssetAvailable && (
          <ul>
            {fields.map((question, questionIndex) => (
              <li key={question.id}>
                <RichText
                  htmlString={question.name}
                  className={styles.questionName}
                />
                <ul>
                  {question.answers.map((answer, answerIndex) => (
                    <Answer
                      questionIndex={questionIndex}
                      answer={answer}
                      key={answer.id}
                      control={control}
                      answerType={question.answer_type}
                      getValues={getValues}
                      setValue={setValue}
                      answerIndex={answerIndex}
                      trigger={trigger}
                    />
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        )}
        <p className={styles.termsCondition}>
          {getDefaultTermsAndConditions(data.share_contact_data?.campaign)}{' '}
          {data.share_contact_data?.email &&
            getDefaultEmailTermsAndConditions(
              data.share_contact_data.campaign,
              undefined,
              data.share_contact_data.email
            )}
        </p>
        {!isAssetAvailable && (
          <Button
            className={styles.button}
            isBig
            onClick={handleSubmitAnswers}
            disabled={isDownloadDisabled || !isValid}
          >
            {data.attached_file_link_name || t('common.button.download')}
          </Button>
        )}

        {isAssetAvailable && !isVideoContent && (
          <>
            {data.attached_file && (
              <Button
                className={styles.button}
                isBig
                onClick={() => {
                  createDownloadFileLink(
                    data.attached_file,
                    data.attached_file_name
                  );
                  increasePopularity();
                }}
                disabled={isDownloadDisabled}
              >
                {data.attached_file_link_name || t('common.button.download')}
              </Button>
            )}
            {data.attached_file_link && (
              <a
                className={cn(styles.link, {
                  [styles.disabled]: isDownloadDisabled,
                })}
                href={data.attached_file_link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => increasePopularity()}
              >
                <Button
                  className={styles.button}
                  isBig
                  disabled={isDownloadDisabled}
                >
                  {data.attached_file_link_name || t('common.button.download')}
                </Button>
              </a>
            )}
          </>
        )}

        {isVideoContent && isAssetAvailable && (
          <Button
            className={styles.button}
            isBig
            onClick={() => setIsVideoPreview(true)}
            disabled={isDownloadDisabled}
          >
            {data.attached_file_link_name || t('common.button.download')}
          </Button>
        )}

        {!isModalContent && (
          <Link to=".." className={styles.toProductLink}>
            <Button isBig className={styles.button} type="button" transparent>
              {getLearnMoreLinkTitle(data.share_contact_data?.campaign)}
            </Button>
          </Link>
        )}
      </div>
      {isLoading && <LoaderScreen />}
    </>
  );
};

export default DownloadForm;
