import type {
  SelectOption,
  TALFileResponse,
  SALFileResponse,
  CampaignAssetFileResponse,
  ReportTemplateResponse,
  UserGroup,
  LeadFileResponse,
} from 'types/models';
import type { Descendant } from 'slate';
import type { UseQueryResult } from 'react-query';
import type { AxiosError } from 'axios';
import type { Area } from 'react-easy-crop';

import {
  CampaignStatuses,
  CampaignCreatingStep,
  ScriptType,
  MilestoneType,
  StepNavigation,
  Regions,
  CustomDefaultValue,
  SALFileStatus,
  TALFileStatus,
  ScriptQuestionType,
} from 'constants/constants';
import {
  AssetDistributionType,
  AssetStatus,
  AssetAttachedType,
  AssetContentType,
  AssetTypes,
} from 'constants/assets';

export type RegionsStrings = keyof typeof Regions;
export type Country =
  | { iso2: string; region: Regions; label?: string }
  | { key: string; region: Regions; label?: string };

export type TALFile = {
  id: number | null;
  tal_file_name: string;
  tal_file: string;
  company_name: SelectOption | null;
  company_domain: SelectOption | null;
  headers?: string[];
  status: TALFileStatus;
  name: string;
};

export type SALFile = {
  id: number | null;
  sal_file_name: string;
  sal_file: string;
  first_name: SelectOption | null;
  last_name: SelectOption | null;
  company_name: SelectOption | null;
  company_domain: SelectOption | null;
  phone: SelectOption | null;
  email: SelectOption | null;
  headers?: string[];
  status: SALFileStatus;
};

export type AssetHTMLValues = {
  html_template_subject: string;
  html_template_heading: string;
  html_template_sub_heading?: string;
  html_template_company_name: string;
  html_template_logo: string | File;
  html_template_image: string | File;
  html_template_content_para: string;
  html_template_terms_is_default: CustomDefaultValue;
  html_template_terms_value: string;
  html_template_privacy_is_default: CustomDefaultValue;
  html_template_privacy_value: string;
  html_template_footer_content: string;
  attached_file_link_name: string;
};

export type CreateCampaignStepProps = {
  currentStep: { name: CampaignCreatingStep; index: number };
  goToStep: (
    step: CampaignCreatingStep | StepNavigation,
    newData?: Partial<CreateCampaignValues>
  ) => void;
  steps: {
    View?: React.FC<Step4ViewProps>;
    name: CampaignCreatingStep;
    disabled: boolean;
    title: string;
  }[];
};

export type AssetValues = {
  order?: number;
  landingImageCroppingArea?: null | Area;
  asset_id?: number;
  content_type?: { value: AssetContentType; label: string };
  attached_file_name: string;
  title: string;
  description: string;
  distribution_value?: number;
  type: { value: AssetTypes; label: string };
  attached_file?: File | string;
  asset_status: AssetStatus;
  landing_image?: { file?: File; renderLink: string };
  product?: { label: string; value: number };
  product_name?: string;
  attached_file_link?: string;
  asset_type: AssetAttachedType;
  default_privacy_value?: string;
} & Partial<AssetHTMLValues>;

export type MilestoneValues = {
  total_leads_amount: number;
  delivery_date: string | null;
  id?: number | null;
};

export type CampaignFiles = {
  target_accounts?: TALFile[];
  company_target_accounts?: { value: number; label: string }[];
  suppression_accounts?: SALFile[];
  company_suppression_accounts?: { value: number; label: string }[];
  assets?: CampaignAssetFileResponse[];
  attached_file_link?: string;
  report_template?: ReportTemplate;
  lead_file?: LeadFileResponse;
};

export type ReportTemplate = ReportTemplateResponse & {
  custom_attributes?: { name: string }[];
};

export type CampaignScriptValues = {
  is_casl_compliant: boolean;
  is_gdpr_compliant: boolean;
  script_types: ScriptType[];

  introduction_block: Descendant[];
  asset_block: Descendant[];
  closing_block: Descendant[];
  compliance_casl_block?: Descendant[] | null;
  compliance_gdpr_block?: Descendant[] | null;
  questions: {
    id?: number;
    name: Descendant[];
    answer_type: ScriptQuestionType;
    display_on_asset_page: boolean;
    shouldFocus?: boolean;
    answers: {
      name: Descendant[];
      is_disqualifier: boolean;
      shouldFocus?: boolean;
      is_other: boolean;
    }[];
  }[];
  notes_block?: Descendant[];
};

export type CampaignScriptQuestionValues = Omit<
  CampaignScriptValues['questions'][0],
  'id'
>;

export type CreateCampaignValues = {
  id: number;
  isJustCreated?: boolean;
  campaign_details: {
    name: string;
    owner_user_group: { value: UserGroup; label: string } | null;
    delivery_emails: SelectOption[];
    total_leads: number;
    contacts_per_company: number;
    cost_per_lead: number;
    is_qc_required: boolean;
    notice?: string;
    status: CampaignStatuses;
    creating_step: CampaignCreatingStep;
    asset_distribution?: AssetDistributionType | null;
    is_casl_compliant: boolean;
    is_gdpr_compliant: boolean;
  };
  campaign_specification: {
    id: number | null;
    job_titles?: SelectOption[];
    management_level?: SelectOption[];
    industry?: SelectOption[];
    size?: SelectOption[];
    geography?: Country[];
    selected_regions?: Regions[];
    revenue?: SelectOption[];
    custom_revenue: { from: number; to: number | string }[];
    custom_size: { from: number; to: number | string }[];
    notice?: string;
  };
  script_builder?: CampaignScriptValues;
  campaign_delivery: {
    is_front_load_scoring: boolean;
    start_date: string | null;
    end_date: string | null;
    milestone_deadline_type: { value: MilestoneType; label: string };
    milestones: MilestoneValues[];
  };
  campaign_files?: CampaignFiles;
};

export type Step4ViewProps = {
  campaignData: CreateCampaignValues;
  getTALRequestSettings: UseQueryResult<TALFileResponse[], AxiosError>;
  getSALRequestSettings: UseQueryResult<SALFileResponse[], AxiosError>;
  getAssetsRequestSettings: UseQueryResult<
    CampaignAssetFileResponse[],
    AxiosError
  >;
  getReportTemplateRequestSettings: UseQueryResult<
    ReportTemplateResponse | undefined,
    AxiosError
  >;
  getLeadFileRequestSettings: UseQueryResult<
    LeadFileResponse | undefined,
    AxiosError
  >;
};

export enum DeliveryLabels {
  start_date = 'campaign-start-date',
  end_date = 'campaign-end-date',
  milestone = 'milestone',
}
