import cn from 'classnames';

import styles from '../index.module.scss';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  size?: number;
}

const Loader = ({ size = 50, className, ...props }: Props) => (
  <span
    {...props}
    className={cn(styles.loader, className)}
    title="loading"
    style={{
      width: size,
      height: size,
    }}
  />
);

export default Loader;
