import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { ScheduleDemoValues } from 'components/ProductPage/models';

import InputWrapper from 'components/common/InputWrapper';
import JobTitleAutocomplete from 'components/common/JobTitleAutocomplete';
import PhoneField from 'components/common/PhoneField';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import styles from './index.module.scss';

const UserInfo = () => {
  const { t } = useTranslation();
  const { register, formState, control } = useFormContext<ScheduleDemoValues>();

  return (
    <>
      <div className={styles.row}>
        <InputWrapper
          label="common.field.first-name"
          wrapperClasses={styles.inputWrapper}
          isMediumInput
          validationError={formState.errors?.first_name?.message}
        >
          <input
            type="text"
            placeholder={t('manage-products.name-placeholder')}
            {...register('first_name')}
            aria-label="first name"
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.last-name"
          wrapperClasses={styles.inputWrapper}
          isMediumInput
          validationError={formState.errors?.last_name?.message}
        >
          <input
            type="text"
            placeholder={t('manage-products.last-name-placeholder')}
            {...register('last_name')}
            aria-label="last name"
          />
        </InputWrapper>
      </div>
      <div className={styles.row}>
        <InputWrapper
          label="common.field.job-title"
          wrapperClasses={styles.inputWrapper}
          isMediumInput
          validationError={formState.errors?.job_title?.value?.message}
        >
          <Controller
            name="job_title"
            control={control}
            render={({ field }) => (
              <JobTitleAutocomplete
                field={field}
                styles={getDefaultSelectStylesWithError({
                  isSmall: true,
                  error: !!formState.errors?.job_title?.value?.message,
                })}
                placeholder={t('manage-products.job-title-placeholder')}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.company-name"
          wrapperClasses={styles.inputWrapper}
          isMediumInput
          validationError={formState.errors?.company_name?.message}
        >
          <input
            type="text"
            placeholder={t('manage-products.company-name-placeholder')}
            {...register('company_name', { required: true })}
            aria-label="company name"
          />
        </InputWrapper>
      </div>
      <div className={styles.row}>
        <InputWrapper
          label="common.field.email"
          wrapperClasses={styles.inputWrapper}
          isMediumInput
          validationError={formState.errors?.email?.message}
        >
          <input
            type="text"
            placeholder={t('manage-products.email-placeholder')}
            {...register('email', { required: true })}
            aria-label="email"
            disabled
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.phone-number"
          wrapperClasses={styles.inputWrapper}
          isMediumInput
          validationError={formState.errors?.phone_number?.message}
        >
          <PhoneField
            control={control}
            name={'phone_number'}
            placeholder={t('manage-products.phone-placeholder')}
            arial-label="phone field"
          />
        </InputWrapper>
      </div>
    </>
  );
};

export default UserInfo;
