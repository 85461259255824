import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom';
import cn from 'classnames';
import { ToastContainer, Zoom } from 'react-toastify';

import useAuth from 'contexts/AuthContext';
import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';
import { PrivatePaths } from 'constants/routes';

import 'react-toastify/dist/ReactToastify.css';
import styles from './index.module.scss';

const Profile = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const getLinkClasses = ({ isActive }: { isActive?: boolean }) =>
    cn(styles.menuLink, {
      [styles.active]: isActive,
    });

  return (
    <>
      <ToastContainer transition={Zoom} />
      <header className={styles.header}>
        <div className={styles.userInfo}>
          <h3 className={styles.title}>{t('profile.profile-settings')}</h3>
          <div className={styles.nameContainer}>
            <span className={styles.avatar}>
              {user?.first_name[0].toUpperCase()}
              {user?.last_name[0].toUpperCase()}
            </span>
            <span className={styles.name}>{`${user?.first_name || ''} ${
              user?.last_name || ''
            }`}</span>
          </div>
        </div>
        <div className={styles.geometrics}>
          <div className={styles.box1} />
          <div className={styles.box2} />
          <div className={styles.box3} />
          <div className={styles.box4} />
        </div>
      </header>
      <main className={styles.main}>
        <div className={styles.menuWrapper}>
          <nav className={styles.navMenu}>
            <ul className={styles.menuList}>
              <li className={styles.menuItem}>
                <NavLink to={PrivatePaths.GENERAL} className={getLinkClasses}>
                  <IconSVG name={IconsNames.person} className={styles.icon} />
                  <span>{t('profile.general')}</span>
                </NavLink>
              </li>
              <li className={styles.menuItem}>
                <NavLink to={PrivatePaths.CONTACTS} className={getLinkClasses}>
                  <IconSVG name={IconsNames.email} className={styles.icon} />
                  <span>{t('profile.contacts')}</span>
                </NavLink>
              </li>
              <li className={styles.menuItem}>
                <NavLink to={PrivatePaths.COMPANY} className={getLinkClasses}>
                  <IconSVG name={IconsNames.company} className={styles.icon} />
                  <span>{t('profile.company')}</span>
                </NavLink>
              </li>
              <li className={styles.menuItem}>
                <NavLink to={PrivatePaths.SECURITY} className={getLinkClasses}>
                  <IconSVG name={IconsNames.shield} className={styles.icon} />
                  <span>{t('profile.security')}</span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        {user && <Outlet />}
      </main>
    </>
  );
};

export default Profile;
