import { useParams, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import type { Path } from 'react-hook-form';

import AuthHeader from 'components/common/AuthHeader';
import AuthForm from 'components/common/AuthForm';
import LoaderScreen from 'components/common/LoaderScreen';

import { InputData } from 'types/models';

import { validationSchemaNewPassword } from 'utils/validations';

import { PublicPaths } from 'constants/routes';

import styles from './index.module.scss';

type NewPasswordData = {
  new_password: string;
  re_new_password: string;
};

type Field = InputData<NewPasswordData> & { name: Path<NewPasswordData> };

const fields: Field[] = [
  {
    name: 'new_password',
    type: 'password',
    label: 'new-password',
    required: true,
  },
  {
    name: 're_new_password',
    type: 'password',
    label: 'confirm-password',
    required: true,
  },
];

const NewPasswordContent = () => {
  const accessParams = useParams();
  const { t } = useTranslation();

  const { mutate, isLoading, error, isSuccess } = useMutation<
    NewPasswordData,
    AxiosError,
    NewPasswordData
  >(async (userData: NewPasswordData) => {
    try {
      const { data } = await axios.post<NewPasswordData>(
        `${process.env.REACT_APP_BACKEND_URL}/accounts/password/reset/confirm/`,
        { ...userData, ...accessParams }
      );

      return data;
    } catch (err) {
      throw err;
    }
  });

  const onSubmit = (data: NewPasswordData) => {
    mutate(data);
  };

  return (
    <div className={styles.wrapper}>
      <AuthHeader title={t('auth.new-password')} linkClass={styles.link} />
      {isSuccess ? (
        <>
          <p className={styles.success}>{t('auth.new-password-success')}</p>
          <Link to={`/${PublicPaths.LOG_IN}`} className={styles.login}>
            {t('auth.back-to-login')}
          </Link>
        </>
      ) : (
        <AuthForm<NewPasswordData>
          fields={fields}
          btnTitle="create-password"
          onSubmit={onSubmit}
          submitError={error}
          validationSchema={validationSchemaNewPassword}
        />
      )}
      {isLoading && <LoaderScreen />}
    </div>
  );
};

export default NewPasswordContent;
