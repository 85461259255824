import { CampaignScript } from './../../types/models';
import { useMutation, useQueryClient } from 'react-query';

import type { AxiosError } from 'axios';
import type { CampaignScriptValues } from 'components/CreateCampaign/models';

import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';

const useSaveCampaignScript = ({
  campaignId,
}: {
  campaignId: string | number;
}) => {
  const { axios } = useAuth();
  const { convertCampaignScriptRequestBody } = useConvertCampaignData();
  const queryClient = useQueryClient();

  const mutation = useMutation<
    CampaignScript,
    AxiosError,
    CampaignScriptValues
  >(
    async values => {
      try {
        const { data } = await axios.put(
          `/campaigns/${campaignId}/script-builder/`,
          convertCampaignScriptRequestBody(values)
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: response => {
        queryClient.setQueryData<CampaignScript>(
          ['campaign-script-builder', campaignId],
          response
        );
      },
    }
  );

  return mutation;
};

export default useSaveCampaignScript;
