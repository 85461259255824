export enum TwoFATypes {
  email = 'email',
  totp = 'totp',
  sms = 'sms',
}

export type DevicesDictionary = {
  [key in TwoFATypes]: TwoFADevice[];
};

export type AppDeviceType = {
  type: TwoFATypes.totp;
  persistent_id: string;
  config_url: string;
  confirmed: boolean;
};

export type TwoFADevice =
  | {
      type: TwoFATypes.email;
      email: string;
      persistent_id: string;
      confirmed: boolean;
    }
  | {
      type: TwoFATypes.sms;
      persistent_id: string;
      phone_number: string;
      confirmed: boolean;
    }
  | AppDeviceType;

export type TwoFaComponentProps = {
  device: TwoFADevice;
  onDeviceChange?: React.Dispatch<
    React.SetStateAction<TwoFADevice | undefined>
  >;
};
