import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useMutation, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import type { AxiosError } from 'axios';
import type { ProductResponse } from 'types/models';

import InputWrapper from 'components/common/InputWrapper';
import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import { ProductAssetsOrdering } from 'constants/assets';

import styles from './index.module.scss';

type Props = {
  isFirstStepDone: boolean;
  companySlug?: string;
  ordering?: ProductAssetsOrdering;
};

const AssetSorting = ({ isFirstStepDone, ordering, companySlug }: Props) => {
  const { t } = useTranslation();
  const { axios } = useAuth();
  const { openModal } = useModal();
  const [searchParams] = useSearchParams();
  const productSlug = searchParams.get('productSlug');
  const queryClient = useQueryClient();

  const { isLoading, mutate: updateOrdering } = useMutation<
    ProductResponse,
    AxiosError,
    ProductAssetsOrdering
  >(
    async option => {
      try {
        const formData = new FormData();
        formData.append('product_asset_ordering', option);
        const { data } = await axios.patch<ProductResponse>(
          `/companies/${companySlug}/products/${productSlug}/`,
          formData
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async product => {
        queryClient.setQueryData<ProductResponse>(
          ['get-product', companySlug, productSlug],
          product
        );
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const orderingVariants = Object.values(ProductAssetsOrdering);

  return (
    <section className={cn({ [styles.disabled]: !isFirstStepDone })}>
      <h2 className={styles.title}>{t('common.field.asset')}</h2>
      <p className={styles.description}>
        {t('campaign.select-and-manage-asset-files')}
      </p>
      <p className={styles.type}>{t('common.field.sort-type')}</p>
      {orderingVariants.map(item => (
        <InputWrapper
          label={`manage-products.assets-ordering.${item}`}
          wrapperClasses={styles.label}
          isErrorHidden
          key={item}
        >
          <input
            type="radio"
            value={item}
            checked={ordering === item}
            onChange={() => updateOrdering(item)}
          />
        </InputWrapper>
      ))}
      {isLoading && <LoaderScreen />}
    </section>
  );
};

export default AssetSorting;
