import type { SingleValue } from 'react-select';
import { useMemo } from 'react';
import Select from 'react-select';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';

import {
  getCalendarSelectorStyles,
  getDefaultSelectStylesWithError,
} from 'utils/selectStyles';

import InputWrapper from 'components/common/InputWrapper';
import Error from 'components/common/Error';

import styles from './index.module.scss';

type YearOption = {
  value: number;
  label: number;
};

type MonthOption = {
  value: number;
  label: string;
};

type Props = {
  date: Date;
  onChange?: (date: Date) => void;
  fromMonth: Date;
  toMonth: Date;
  isMonthly?: boolean;
  changeYear?: (year: number) => void;
  changeMonth?: (month: number) => void;
  validationError?: string;
  maxErrorLength?: number;
  isSmallSelector?: boolean;
};

dayjs.extend(localeData);

const getLastDayInMonth = (currentYear: number, currentMonth: number) => {
  const selectedDate = dayjs(new Date(currentYear, currentMonth));
  const lastDayInSelectedMonth = selectedDate.daysInMonth();

  return lastDayInSelectedMonth;
};

const MonthSelect = ({
  date,
  onChange,
  fromMonth,
  toMonth,
  isMonthly,
  changeYear,
  changeMonth,
  validationError,
  maxErrorLength,
  isSmallSelector = false,
}: Props) => {
  const monthsOptions = useMemo(
    () =>
      dayjs.months().map((label, index) => ({
        value: index,
        label,
      })),
    []
  );
  const year: YearOption = {
    value: date.getFullYear(),
    label: date.getFullYear(),
  };
  const month: MonthOption = {
    value: date.getMonth(),
    label: monthsOptions[date.getMonth()].label,
  };

  const years = useMemo(() => {
    const result: YearOption[] = [];
    const startYear = fromMonth.getFullYear();
    const endYear = toMonth.getFullYear();

    for (let i = startYear; i <= endYear; i += 1) {
      result.push({ value: i, label: i });
    }

    return result;
  }, [fromMonth]);

  const handleChangeMonth = (newMonth: SingleValue<MonthOption>) => {
    if (changeMonth && newMonth) {
      changeMonth(newMonth.value);
      return;
    }

    if (newMonth && onChange) {
      onChange(
        new Date(
          year.value,
          newMonth.value,
          getLastDayInMonth(year.value, newMonth.value)
        )
      );
    }
  };

  const handleChangeYear = (newYear: SingleValue<YearOption>) => {
    if (changeYear && newYear) {
      changeYear(newYear.value);
      return;
    }

    if (newYear && onChange) {
      onChange(
        new Date(
          newYear.value,
          month.value,
          getLastDayInMonth(newYear.value, month.value)
        )
      );
    }
  };
  const selectStyles = isSmallSelector
    ? getDefaultSelectStylesWithError({ isSmall: true })
    : getCalendarSelectorStyles(isMonthly, !!validationError);
  const wrapperClasses = isMonthly ? styles.monthly : styles.custom;
  const monthLabel = isMonthly ? 'pick-month' : undefined;
  const yearLabel = isMonthly ? 'pick-year' : undefined;
  const labelClasses = !isMonthly ? styles.label : '';

  return (
    <div className={wrapperClasses}>
      <InputWrapper
        label={monthLabel && `common.field.${monthLabel}`}
        wrapperClasses={labelClasses}
        isErrorHidden
      >
        <Select<MonthOption>
          options={monthsOptions}
          onChange={handleChangeMonth}
          value={month}
          styles={selectStyles}
          isSearchable={false}
        />
      </InputWrapper>
      <InputWrapper
        label={yearLabel && `common.field.${yearLabel}`}
        wrapperClasses={labelClasses}
        isErrorHidden
      >
        <Select<YearOption>
          options={years}
          onChange={handleChangeYear}
          value={year}
          styles={selectStyles}
          isSearchable={false}
        />
      </InputWrapper>
      {validationError && (
        <Error
          message={validationError}
          className={styles.error}
          maxErrorLength={maxErrorLength}
        />
      )}
    </div>
  );
};

export default MonthSelect;
