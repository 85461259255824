import { useQuery } from 'react-query';
import { useTable, useRowSelect } from 'react-table';
import React, { useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import {
  CampaignResponse,
  OfferResponse,
  PaginationReturn,
  CampaignLeadResponse,
} from 'types/models';
import type { AxiosError } from 'axios';
import type { ReportedLeadsCount } from 'components/CampaignManage/models';
import type {
  Column,
  UseRowSelectRowProps,
  UseTableRowProps,
} from 'react-table';

import Table from 'components/common/Table';
import Loader from 'components/common/LoaderScreen/Loader';
import LeadDetailsFilter, {
  FilterItem,
} from 'components/common/ReportedLeadsTable/LeadDetailsFilter';
import Error from 'components/common/Error';
import Pagination from 'components/common/Pagination';

import QualityCell from 'components/common/ReportedLeadsTable/QualityCell';
import VendorTab from 'components/common/ReportedLeadsTable/VendorTab';
import MilestoneTab from 'components/common/ReportedLeadsTable/MilestoneTab';
import LeadsRatioVM from 'components/common/ReportedLeadsTable/LeadsRatioVM';
import LeadsRatioVendor from 'components/common/ReportedLeadsTable/LeadsRatioVendor';
import IndeterminateCheckbox from 'components/common/ReportedLeadsTable/Checkbox';
import BulkActionButtons from 'components/common/ReportedLeadsTable/BulkActionButtons';

import useAuth from 'contexts/AuthContext';

import {
  LeadsTableAccessors,
  UserTypes,
  LeadStatus,
} from 'constants/constants';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

export enum TabFilters {
  vendor = 'vendor',
  milestone = 'milestone',
}

type Props = {
  campaign: CampaignResponse;
  acceptedOffers?: OfferResponse[];
  canceledOffers?: OfferResponse[];
  setIsDataEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type RowsType = { [key in LeadsTableAccessors]: any };

const PAGE_SIZE = 15;

const addPlusToPhoneNumber = (phone?: string | null) =>
  phone && !phone.trim().startsWith('+') ? `+${phone}` : phone;

const LeadsTable = ({
  campaign,
  acceptedOffers,
  setIsDataEmpty,
  canceledOffers,
}: Props) => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();
  const [filterList, setFilterList] = useState<FilterItem[]>([]);
  const [selectedVendor, setSelectedVendor] =
    useState<{ id: string | undefined; companyName: string | undefined }>();
  const [selectedMilestone, setSelectedMilestone] =
    useState<{ id: string | undefined; date: string | undefined }>();
  const totalUploadedLeadsCount = useRef(0);
  const [page, setPage] = useState(1);
  const infoRef = useRef<HTMLDivElement>(null);

  const isVendor = user?.current_group_name === UserTypes.vendor;
  const isAdmin = user?.current_group_name === UserTypes.admin;
  const isVendorManager = user?.current_group_name === UserTypes.vendorManager;

  const {
    data: leadsPage,
    isLoading,
    isRefetching,
    error,
  } = useQuery<
    PaginationReturn<CampaignLeadResponse[]> & {
      delivered_count: ReportedLeadsCount;
    },
    AxiosError
  >(
    [
      'reported-leads',
      page,
      filterList,
      selectedMilestone,
      selectedVendor,
      campaign.id,
    ],
    async () => {
      try {
        const queryString = filterList.reduce((acc, val) => {
          acc = `${acc}&${val.id}=${val.value}`;
          return acc;
        }, '');

        const { data } = await axios.get<
          PaginationReturn<CampaignLeadResponse[]> & {
            delivered_count: ReportedLeadsCount;
          }
        >(
          `/${isVendor ? 'vendor-campaigns' : 'campaigns'}/${
            campaign.id
          }/leads/?page_size=${PAGE_SIZE}&page=${page}${queryString}${
            selectedMilestone?.id
              ? `&campaign_milestone=${selectedMilestone.id}`
              : ''
          }${selectedVendor?.id ? `&created_by=${selectedVendor.id}` : ''}`
        );

        const isDefaultRequest =
          page === 1 && !filterList.length && !selectedVendor?.id;

        if (isDefaultRequest) {
          totalUploadedLeadsCount.current = data.count;
        }

        setIsDataEmpty?.(!data.results.length);

        return data;
      } catch (err) {
        throw err;
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const rowsData = useMemo(() => {
    return (
      leadsPage?.results.map(
        lead =>
          ({
            [LeadsTableAccessors.firstName]: lead.first_name,
            [LeadsTableAccessors.lastName]: lead.last_name,
            [LeadsTableAccessors.email]: lead.email,
            [LeadsTableAccessors.location]: lead.location,
            [LeadsTableAccessors.country]: lead.person_country,
            [LeadsTableAccessors.company]: lead.company,
            [LeadsTableAccessors.extension]: lead.extension,
            [LeadsTableAccessors.asset]: lead.campaign_asset?.title,
            [LeadsTableAccessors.date]: lead.date_of_scoring,
            [LeadsTableAccessors.zip]: lead.person_zip_code,
            [LeadsTableAccessors.companyPhone]: addPlusToPhoneNumber(
              lead.company_hq_phone
            ),
            [LeadsTableAccessors.phone]: addPlusToPhoneNumber(lead.phone),
            [LeadsTableAccessors.qc]: {
              lead,
              campaignId: campaign.id,
            },
          } as RowsType)
      ) || []
    );
  }, [leadsPage]);

  const columnsData = useMemo<Column<RowsType>[]>(
    () => [
      {
        Header: t('user.type.vendor'),
        accessor: LeadsTableAccessors.vendor,
        isVisible: !!selectedVendor?.id,
        Cell: () => <span>{selectedVendor?.companyName}</span>,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.milestone}`),
        accessor: LeadsTableAccessors.milestone,
        isVisible: !!selectedMilestone?.id,
        Cell: () => <span>{selectedMilestone?.date}</span>,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.firstName}`),
        accessor: LeadsTableAccessors.firstName,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.lastName}`),
        accessor: LeadsTableAccessors.lastName,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.email}`),
        accessor: LeadsTableAccessors.email,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.phone}`),
        accessor: LeadsTableAccessors.phone,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.extension}`),
        accessor: LeadsTableAccessors.extension,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.location}`),
        accessor: LeadsTableAccessors.location,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.country}`),
        accessor: LeadsTableAccessors.country,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.zip}`),
        accessor: LeadsTableAccessors.zip,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.company}`),
        accessor: LeadsTableAccessors.company,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.companyPhone}`),
        accessor: LeadsTableAccessors.companyPhone,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.asset}`),
        accessor: LeadsTableAccessors.asset,
      },
      {
        Header: t(`common.field.${LeadsTableAccessors.date}`),
        accessor: LeadsTableAccessors.date,
        Cell: ({ value }: { value: string }) =>
          value ? dayjs(value).format('DD/MM/YYYY') : '',
      },

      ...(!isAdmin
        ? [
            {
              Header: t('common.field.action'),
              accessor: LeadsTableAccessors.qc,
              Cell: ({
                value,
              }: {
                value: {
                  lead: CampaignLeadResponse;
                  campaignId: number;
                };
              }) => <QualityCell {...value} isVendor={isVendor} />,
            },
          ]
        : []),
    ],
    [selectedVendor, selectedMilestone]
  );

  const setFiltersListHandler = (filters: FilterItem[]) => {
    setFilterList(filters);
    setPage(1);
  };

  const tableProps = useTable(
    {
      columns: columnsData,
      data: rowsData || [],
      initialState: {
        hiddenColumns: columnsData
          .filter(column => column.isVisible === false)
          .map(header => header.accessor as string),
      },
    },

    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',

          Header: ({
            toggleRowSelected,
            rows,
            isAllRowsSelected,
          }: {
            toggleRowSelected: (rowPath: string, set?: boolean) => void;
            rows: (UseTableRowProps<RowsType> &
              UseRowSelectRowProps<RowsType>)[];
            isAllRowsSelected: boolean;
          }) => {
            const isAwaitingRows = rows.filter(
              item =>
                item.original.qc.lead.status ===
                LeadStatus['wait-qualification']
            );

            let selectableRowsInCurrentPage = 0;
            let selectedRowsInCurrentPage = 0;

            rows.forEach(row => {
              if (row.isSelected) selectedRowsInCurrentPage++;
              if (
                row.original.qc.lead.status === LeadStatus['wait-qualification']
              )
                selectableRowsInCurrentPage++;
            });

            const isMinus =
              selectedRowsInCurrentPage !== 0 &&
              selectableRowsInCurrentPage !== selectedRowsInCurrentPage;

            const customOnChange = (
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              rows.forEach(row => {
                const isRowAwaitingQualification =
                  row.original.qc.lead.status ===
                  LeadStatus['wait-qualification'];

                if (isMinus) {
                  toggleRowSelected(row.id, false);
                }
                if (isRowAwaitingQualification && !isMinus) {
                  toggleRowSelected(row.id, event?.currentTarget.checked);
                }
              });
            };

            const checked =
              isAllRowsSelected ||
              selectableRowsInCurrentPage === selectedRowsInCurrentPage;

            return !!isAwaitingRows.length ? (
              <div className={styles.headerCheckbox}>
                <IndeterminateCheckbox
                  customOnChange={customOnChange}
                  checked={checked}
                  isMinus={isMinus}
                />
              </div>
            ) : (
              <></>
            );
          },

          Cell: ({ row }) => {
            return row.original.qc.lead.status ===
              LeadStatus['wait-qualification'] ? (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ) : (
              <></>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  return (
    <>
      {isLoading && <Loader className={styles.loader} />}
      {error && <Error message={getResponseError(error)} />}
      {!isLoading && (
        <div className={styles.wrapper}>
          <div className={styles.header} ref={infoRef}>
            {!isVendor && !isAdmin && (
              <VendorTab
                selectedVendor={selectedVendor}
                setSelectedVendor={setSelectedVendor}
                acceptedOffers={acceptedOffers}
                canceledOffers={canceledOffers}
                setPage={setPage}
              />
            )}

            {!isAdmin && (
              <MilestoneTab
                selectedMilestone={selectedMilestone}
                setSelectedMilestone={setSelectedMilestone}
                milestones={campaign.campaign_delivery.milestones}
                milestoneDeadlineType={
                  campaign.campaign_delivery.milestone_deadline_type
                }
                setPage={setPage}
              />
            )}

            <LeadDetailsFilter
              filterList={filterList}
              setFilterList={setFiltersListHandler}
            />

            <div className={styles.counts}>
              {isVendorManager && !!leadsPage && (
                <LeadsRatioVM leadsCount={leadsPage.delivered_count} />
              )}

              {isVendor &&
                !!leadsPage &&
                (!!acceptedOffers?.length || !!canceledOffers?.length) && (
                  <LeadsRatioVendor
                    selectedMilestone={selectedMilestone}
                    totalUploadedLeadsCount={totalUploadedLeadsCount.current}
                    acceptedOffers={acceptedOffers}
                    canceledOffers={canceledOffers}
                  />
                )}

              {isRefetching && <Loader size={36} />}
            </div>
          </div>

          {!!tableProps.selectedFlatRows.length && (
            <BulkActionButtons selectedRows={tableProps.selectedFlatRows} />
          )}
          <Table
            wrapperClassName={styles.table}
            tableOptions={
              !isVendorManager
                ? {
                    columns: columnsData,
                    data: rowsData || [],
                    initialState: {
                      hiddenColumns: columnsData
                        ?.filter(column => column.isVisible === false)
                        .map(header => header.accessor as string),
                    },
                  }
                : undefined
            }
            tableProps={isVendorManager ? tableProps : undefined}
          />

          <Pagination
            totalCount={leadsPage?.count || 0}
            pageSize={PAGE_SIZE}
            currentPage={page}
            setPage={setPage}
            scrollToRef={infoRef}
            className={styles.pagination}
          />
        </div>
      )}
    </>
  );
};

export default LeadsTable;
