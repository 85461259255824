import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { UseQueryResult } from 'react-query';
import type { AxiosError } from 'axios';
import type {
  TALFileResponse,
  SALFileResponse,
  CampaignResponse,
  SelectOption,
} from 'types/models';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import SavedField from 'components/common/FileCard/SavedField';
import Loader from 'components/common/LoaderScreen/Loader';

import useConvertCampaignData from 'hooks/useConvertCampaignData';

import getLongStringView from 'helpers/getLongStringView';

import styles from './index.module.scss';

type Props = {
  specification:
    | CreateCampaignValues['campaign_specification']
    | CampaignResponse['campaign_specification'];
  getTALRequestSettings: UseQueryResult<TALFileResponse[], AxiosError>;
  getSALRequestSettings: UseQueryResult<SALFileResponse[], AxiosError>;
};

const Specification = ({
  specification,
  getSALRequestSettings: { isLoading: isSALLoading, data: SALData },
  getTALRequestSettings: { isLoading: isTALLoading, data: TALData },
}: Props) => {
  const { t, i18n } = useTranslation();
  const { convertGeography } = useConvertCampaignData();

  const {
    job_titles: jobTitles,
    industry,
    revenue,
    management_level: managementLevel,
    size,
    geography,
    notice,
  } = specification;

  const translateValue = (option: string) =>
    i18n.exists(`common.field.${option}`)
      ? t(`common.field.${option}`)
      : option.replace('_', '-');
  const countriesAfterTransition = convertGeography(geography);

  const formatOptionsWithCustom = (
    defaultOptions?: string[] | SelectOption[],
    customOptions?: { from: number; to: number | string }[]
  ) => {
    const defaultTranslated =
      defaultOptions?.map(item =>
        translateValue(typeof item === 'string' ? item : item.label)
      ) || [];
    const customTranslated =
      customOptions?.map(item => `${item.from}-${item.to}`) || [];

    return [...defaultTranslated, ...customTranslated].join(', ');
  };

  const revenueTranslated = formatOptionsWithCustom(
    revenue,
    'custom_revenue' in specification ? specification.custom_revenue : undefined
  );
  const sizeTranslated = formatOptionsWithCustom(
    size,
    'custom_size' in specification ? specification.custom_size : undefined
  );

  return (
    <>
      <h4 className={styles.subheading}>
        {t('campaign.campaign-specification')}
      </h4>
      <ul className={styles.specification}>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.job-title')}:</h5>
          <ul className={styles.options}>
            {!!jobTitles?.length
              ? jobTitles.map(title => {
                  const value = typeof title === 'string' ? title : title.label;

                  return (
                    <li className={styles.item} key={value}>
                      {value}
                    </li>
                  );
                })
              : t('common.field.all')}
          </ul>
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.industry')}:</h5>
          <ul className={styles.options}>
            {!!industry?.length
              ? industry.map(item => {
                  const value = typeof item === 'string' ? item : item.label;

                  return (
                    <li className={styles.item} key={value}>
                      {translateValue(value)}
                    </li>
                  );
                })
              : '—'}
          </ul>
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.revenue')}:</h5>
          <p className={styles.options}>{revenueTranslated}</p>
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>
            {t('common.field.management-level')}:
          </h5>
          <ul className={styles.options}>
            {!!managementLevel?.length
              ? managementLevel.map(level => {
                  const value = typeof level === 'string' ? level : level.label;

                  return (
                    <li className={styles.item} key={value}>
                      {translateValue(value)}
                    </li>
                  );
                })
              : '—'}
          </ul>
        </li>

        <li className={cn(styles.item, styles.specification__size)}>
          <h5 className={styles.title}>{t('common.field.company-size')}:</h5>
          <p className={styles.options}>{sizeTranslated}</p>
        </li>
        <li className={cn(styles.item, styles.mapping)}>
          <h5 className={styles.title}>
            {t('common.field.target-account-list')}:
          </h5>
          {!!TALData?.length &&
            TALData.map(file => (
              <div className={styles.mapping__item} key={file.tal_file}>
                <a
                  className={styles.link}
                  href={file.tal_file}
                  download="file.tal_file"
                >
                  {getLongStringView(file.tal_file_name, 40)}
                </a>
                <SavedField
                  isArrow
                  fieldName={t('common.field.company-name')}
                  value={file.company_name || '—'}
                />
                <SavedField
                  isArrow
                  fieldName={t('common.field.company-domain')}
                  value={file.company_domain || '—'}
                />
              </div>
            ))}
          {!TALData?.length && isTALLoading && <Loader />}
          {!TALData?.length && !isTALLoading && '—'}
        </li>
        <li className={cn(styles.item, styles.mapping)}>
          <h5 className={styles.title}>
            {t('common.field.suppression-account-list')}:
          </h5>

          {!!SALData?.length &&
            SALData.map(file => (
              <div className={styles.mapping__item} key={file.id}>
                <a
                  className={styles.link}
                  href={file.sal_file}
                  download={file.sal_file}
                >
                  {getLongStringView(file.sal_file_name, 40)}
                </a>
                <SavedField
                  isArrow
                  fieldName={t('common.field.first-name')}
                  value={file.first_name || '—'}
                />
                <SavedField
                  isArrow
                  fieldName={t('common.field.last-name')}
                  value={file.last_name || '—'}
                />
                <SavedField
                  isArrow
                  fieldName={t('common.field.company-name')}
                  value={file.company_name || '—'}
                />
                <SavedField
                  isArrow
                  fieldName={t('common.field.company-domain')}
                  value={file.company_domain || '—'}
                />
                <SavedField
                  isArrow
                  fieldName={t('common.field.phone')}
                  value={file.phone || '—'}
                />
                <SavedField
                  isArrow
                  fieldName={t('common.field.email')}
                  value={file.email || '—'}
                />
              </div>
            ))}
          {!SALData?.length && !isSALLoading && '—'}
          {!SALData?.length && isSALLoading && <Loader />}
        </li>
        <li className={styles.geography}>
          <h5 className={styles.title}>{t('common.field.geography')}:</h5>
          {countriesAfterTransition ? countriesAfterTransition.join(', ') : '—'}
        </li>
        <li className={styles.notice}>
          <h5 className={styles.title}>
            {t('common.field.special-instructions')}:
          </h5>
          {notice || '—'}
        </li>
      </ul>
    </>
  );
};

export default Specification;
