import { useTranslation } from 'react-i18next';

import ScriptBuilder from 'components/CreateCampaign/Step1/ScriptBuilder';

import styles from './index.module.scss';

const Script = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className={styles.subheading}>{t('script.script')}</h4>
      <ScriptBuilder isPreviewMode />
    </>
  );
};

export default Script;
