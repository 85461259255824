import { useFormContext, useController } from 'react-hook-form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import type { AssetValues } from 'components/CreateCampaign/models';

import InputWrapper from 'components/common/InputWrapper';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';

import useTranslateSelectOptions from 'hooks/useTranslateSelectOptions';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import { AssetTypes } from 'constants/assets';
import { assetTypes } from 'constants/selectOptions';

import styles from './index.module.scss';

const AddInfo = () => {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<AssetValues>();
  const translateOptions = useTranslateSelectOptions();
  const assetTypeOptions = translateOptions<AssetTypes>(assetTypes);

  const { field } = useController({
    control,
    name: 'type',
  });

  const registerDescriptionMethods = register('description');

  return (
    <fieldset className={styles.fields}>
      <InputWrapper
        label="common.field.title"
        isMediumInput
        validationError={errors?.title?.message}
      >
        <input
          type="text"
          {...register('title')}
          placeholder={`${t('common.field.enter')} ${t(
            'common.field.title'
          ).toLowerCase()}`}
        />
      </InputWrapper>
      <InputWrapper
        label="common.field.type"
        validationError={errors?.type?.message}
      >
        <Select
          options={assetTypeOptions}
          {...field}
          styles={getDefaultSelectStylesWithError({
            isSmall: true,
            error: !!errors?.type?.message,
          })}
          placeholder={`${t('common.field.select')} ${t(
            'common.field.type'
          ).toLowerCase()}`}
          noOptionsMessage={() => t('common.field.no-options')}
          menuPlacement="auto"
          isSearchable={false}
          menuPosition="fixed"
        />
      </InputWrapper>
      <InputWrapper
        label="common.field.description"
        validationError={errors?.description?.message}
      >
        <TextareaAutoHeight
          methods={registerDescriptionMethods}
          control={control}
          placeholder={`${t('common.field.enter')} ${t(
            'common.field.description'
          ).toLowerCase()}`}
        />
      </InputWrapper>
    </fieldset>
  );
};

export default AddInfo;
