import * as Yup from 'yup';

import { MAX_LENGTH_MESSAGE } from 'utils/validations';

export const MAX_FEATURE_CHARACTERS_LENGTH = 30;
export const validationSchemaCompanyFeatures = Yup.lazy(() =>
  Yup.object().shape({
    feature: Yup.string().max(MAX_FEATURE_CHARACTERS_LENGTH, {
      key: MAX_LENGTH_MESSAGE,
      values: { length: MAX_FEATURE_CHARACTERS_LENGTH },
    }),
  })
);
