import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';
import type { EmailServer } from 'components/IntegrationsContent/VendorContent/models';

import ModalWindow from 'components/common/ModalWindow';

import getResponseError from 'helpers/getResponseError';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

const useUpdateServer = () => {
  const { axios } = useAuth();
  const { t } = useTranslation();
  const { openModal } = useModal();
  const queryClient = useQueryClient();

  const updateServer = useMutation<void, AxiosError, EmailServer>(
    async data => {
      try {
        await axios.put(`/mailing-integrations/${data?.id}/`, data);
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('servers-list');
        openModal({
          Content: (
            <ModalWindow
              successMessage={t('integrations.form.server-successfuly-updated')}
            />
          ),
        });
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  return updateServer;
};

export default useUpdateServer;
