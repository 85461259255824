import { Controller, useFormContext } from 'react-hook-form';
import { useQueryClient, useIsFetching, useIsMutating } from 'react-query';
import cn from 'classnames';

import type { AxiosError } from 'axios';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { CampaignAssetFileResponse } from 'types/models';

import InputWrapper from 'components/common/InputWrapper';
import NumberField from 'components/common/NumberField';
import Loader from 'components/common/LoaderScreen/Loader';

import { CampaignCreatingStep } from 'constants/constants';
import { AssetAttachedType } from 'constants/assets';

import useAuth from 'contexts/AuthContext';
import useDeleteAsset from 'components/CreateCampaign/Step1/useDeleteAsset';
import useGetAssets from 'components/CreateCampaign/useGetAssets';

import getResponseError from 'helpers/getResponseError';

import styles from '../index.module.scss';

type Props = {
  saveDraftErrors?: AxiosError | null;
};

const CPLField = ({ saveDraftErrors }: Props) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<CreateCampaignValues>();
  const { user, axios } = useAuth();
  const mutation = useDeleteAsset();
  const queryClient = useQueryClient();
  const isFetching = !!useIsFetching('campaign');
  const isMutating = !!useIsMutating();

  const { error: getAssetsError } = useGetAssets({
    campaign: getValues(),
    enabled: false,
  });

  const handleResetFields = async (value?: number | string) => {
    const newValue = Number(value);
    if (Number.isNaN(newValue)) return;

    const savedAssets = getValues().campaign_files?.assets;
    const isDemoCampaignValue = newValue === 0;
    const checkIsAssetInvalid = (asset: CampaignAssetFileResponse) =>
      isDemoCampaignValue
        ? asset.asset_type !== AssetAttachedType.product
        : asset.asset_type === AssetAttachedType.product;

    if (isDemoCampaignValue) {
      setValue('campaign_details.is_qc_required', true);
      setValue('campaign_specification.geography', []);
    }

    if (!savedAssets?.some(checkIsAssetInvalid)) return;

    const isNotFirstStep =
      getValues().campaign_details.creating_step !==
      CampaignCreatingStep['first-step'];
    if (isNotFirstStep) {
      await axios.patch(`/campaigns/${getValues().id}/`, {
        campaign_details: {
          creating_step: CampaignCreatingStep['first-step'],
        },
      });
      setValue(
        'campaign_details.creating_step',
        CampaignCreatingStep['first-step']
      );
    }

    const validAssets = savedAssets.filter(asset => {
      if (checkIsAssetInvalid(asset)) {
        mutation.mutate({
          assetId: asset.id,
          campaignId: getValues().id,
        });
      }
      return !checkIsAssetInvalid(asset);
    });

    const isResetDistribution =
      validAssets.length <= 1 && savedAssets.length > 1;
    if (isResetDistribution) {
      setValue('campaign_details.asset_distribution', undefined);
    }

    queryClient.setQueryData<CampaignAssetFileResponse[]>(
      ['campaign', getValues().id.toString(), 'assets', user?.company.id],
      isResetDistribution
        ? validAssets.map(item => ({
            ...item,
            distribution_value: undefined,
          }))
        : validAssets
    );
  };

  return (
    <InputWrapper
      isMediumInput
      label="common.field.cpl"
      wrapperClasses={cn(styles.item, styles.cpl)}
      validationError={errors?.campaign_details?.cost_per_lead?.message}
      submitError={
        (getAssetsError && getResponseError(getAssetsError)) ||
        saveDraftErrors?.response?.data?.campaign_details?.cost_per_lead?.[0] ||
        undefined
      }
    >
      {isFetching && <Loader size={12} className={styles.loader} />}
      <Controller
        name="campaign_details.cost_per_lead"
        control={control}
        render={({ field }) => (
          <NumberField
            field={{
              ...field,
              onChange: value => {
                field.onChange(value);
                handleResetFields(value);
              },
            }}
            placeholder="$"
            disabled={isFetching || !!getAssetsError || isMutating}
          />
        )}
      />
    </InputWrapper>
  );
};

export default CPLField;
