import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';

import LoaderScreen from 'components/common/LoaderScreen';
import FeatureForm from 'components/CreateProductContent/CompanyFeatures/FeatureForm';
import FeatureItem from 'components/CreateProductContent/CompanyFeatures/FeatureItem';
import SectionWrapper from 'components/CreateProductContent/SectionWrapper';

import { useGetFeaturesList } from 'components/CreateProductContent/CompanyFeatures/useGetFeaturesList';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  isFirstStepDone: boolean;
  companySlug?: string;
  productId?: number;
  isProductJustSubmitted: boolean;
};

const CompanyFeatures: React.FC<Props> = ({
  isFirstStepDone,
  companySlug,
  productId,
  isProductJustSubmitted,
}) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const productSlug = searchParams.get('productSlug');

  const { isLoading: isLoadingFeatures, data: companyFeatures } =
    useGetFeaturesList({
      companySlug,
      productId,
      productSlug,
      isProductJustSubmitted,
    });
  const benefits = companyFeatures?.filter(feature => feature.is_benefit);
  const disadvantages = companyFeatures?.filter(feature => !feature.is_benefit);

  return (
    <SectionWrapper
      disabled={!isFirstStepDone}
      title={t('manage-products.company-features')}
      iconName={IconsNames.star_bordered}
    >
      {isLoadingFeatures && <LoaderScreen />}
      <div className={styles.featuresContainer}>
        <FeatureForm
          isBenefit
          companySlug={companySlug}
          productSlug={productSlug}
          productId={productId}
        />
        {!!benefits?.length && (
          <ul className={cn(styles.list, styles.benefits)}>
            {benefits.map(feature => (
              <FeatureItem
                key={feature.id}
                feature={feature}
                productSlug={productSlug}
                companySlug={companySlug}
                productId={productId}
              />
            ))}
          </ul>
        )}
      </div>
      <div className={styles.featuresContainer}>
        <FeatureForm
          companySlug={companySlug}
          productSlug={productSlug}
          productId={productId}
        />
        {!!disadvantages?.length && (
          <ul className={styles.list}>
            {disadvantages.map(feature => (
              <FeatureItem
                key={feature.id}
                feature={feature}
                productSlug={productSlug}
                companySlug={companySlug}
                productId={productId}
              />
            ))}
          </ul>
        )}
      </div>
    </SectionWrapper>
  );
};

export default CompanyFeatures;
