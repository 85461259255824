import { ServerVariants } from 'constants/constants';
export enum EmailServices {
  postmark = 'postmark',
  sendgrid = 'sendgrid',
  mandrill = 'mandrill',

  // TODO: not for MVP
  // mailgun = 'mailgun',
  // mailjet = 'mailjet',
  // postal = 'postal', // TODO: add icon
  // sendinblue = 'sendinblue',
  // sparkpost = 'sparkpost',
}

export const typesWithApiKey = [
  // EmailServices.mailgun,
  // EmailServices.postal,
  EmailServices.sendgrid,
  // EmailServices.sendinblue,
  // EmailServices.sparkpost,
  EmailServices.mandrill,
];
export const typesWithSecretKey = [
  // EmailServices.mailjet,
];

export type SMPTServer = {
  id?: string | number;
  integration_type: ServerVariants.smtp;
  integration_name: string;
  credentials: {
    host: string;
    port: string;
    user_name: string;
    user_password: string;
    from_email: string;
  };
};

export type ESPServer = {
  id?: string | number;
  integration_type: ServerVariants.esp;
  integration_name: string;
  credentials: {
    provider_name: EmailServices;
    api_key?: string;
    secret_key?: string;
    server_token?: string;
    from_email: string;
  };
};

export type EmailServer = ESPServer | SMPTServer;
