import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import type { AxiosError } from 'axios';
import type { UseTableRowProps } from 'react-table';

import Button from 'components/common/Button';
import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';

import { IconsNames } from 'constants/constants';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';
import { RowsType } from 'components/common/ReportedLeadsTable';

enum LeadActions {
  qualify = 'qualify',
  disqualify = 'disqualify',
}

type Props = {
  selectedRows: UseTableRowProps<RowsType>[];
};

const BulkActionButtons = ({ selectedRows }: Props) => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useModal();

  const campaignId = selectedRows[0].original.qc.campaignId;

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    { action: LeadActions; leadsIds: number[] }
  >(
    async ({
      action,
      leadsIds,
    }: {
      action: LeadActions;
      leadsIds: number[];
    }) => {
      try {
        await axios.post(
          `/campaigns/${campaignId}/leads/bulk-${action.toLowerCase()}/`,
          {
            campaign_leads: leadsIds,
          }
        );
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => {
        closeModal();
        await queryClient.refetchQueries('reported-leads');

        queryClient.refetchQueries([
          'campaign',
          campaignId.toString(),
          user?.current_group_name,
          user?.company.id.toString(),
        ]);
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const onSubmit = (action: LeadActions) => {
    const leadsIds = selectedRows.map(item => item.original.qc.lead.id);

    mutate({ action, leadsIds });
  };

  const openConfirmModal = ({ action }: { action: LeadActions }) => {
    openModal({
      Content: (
        <ModalWindow
          title={
            <h3>
              {t('common.modal.are-you-sure')}
              <span>
                {action[0].toLowerCase()}
                {action.slice(1)}
              </span>
              {t('common.field.leads').toLowerCase()}?
            </h3>
          }
        >
          <div className={styles.modalButtons}>
            <Button className={styles.modalButton} onClick={closeModal} white>
              {t('common.button.no')}
            </Button>
            <Button
              className={styles.modalButton}
              onClick={() => onSubmit(action)}
            >
              {t('common.button.yes')}
            </Button>
          </div>
        </ModalWindow>
      ),
    });
  };

  return (
    <>
      {isLoading && <LoaderScreen />}
      {!isLoading && (
        <div className={styles.buttonsWrapper}>
          <Button
            className={styles.button}
            transparent
            iconProps={{
              name: IconsNames.check_mark,
            }}
            onClick={() => openConfirmModal({ action: t('lead.qualify') })}
            isLoading={isLoading}
          >
            {t('lead.qualify')}
          </Button>
          <Button
            className={styles.button}
            transparent
            iconProps={{
              name: IconsNames.close,
            }}
            onClick={() => openConfirmModal({ action: t('lead.disqualify') })}
            isLoading={isLoading}
          >
            {t('lead.disqualify')}
          </Button>
        </div>
      )}
    </>
  );
};

export default BulkActionButtons;
