import { useNavigate } from 'react-router-dom';

import SideMenu from 'components/DocumentsContent/DataProcessing/SideMenu';
import ScrollToTopButton from 'components/DocumentsContent/ScrollToTopButton';

import { CommonPaths } from 'constants/routes';
import { DataProcessingIDs } from 'components/DocumentsContent/constants';

import styles from '../index.module.scss';

const DataProcessing = () => {
  const navigate = useNavigate();

  return (
    <main className={styles.main}>
      <SideMenu />
      <div className={styles.content_container}>
        <h1 className={styles.content_header}>
          Leadforce Data Processing Addendum
        </h1>
        <div className={styles.noteWrapper}>
          Effective Date:
          <span>October 7, 2022</span>
          <div className={styles.semibold}>Version: 2.0</div>
        </div>

        <div className={styles.text}>
          <p>
            This Data Processing Addendum (
            <span className={styles.semibold}> “DPA”</span>) supplements the
            <span
              className={styles.linkToPage}
              onClick={() =>
                navigate(`/${CommonPaths.DOCUMENTS}/${CommonPaths.TERMS}`)
              }
            >
              Leadforce Terms of Service
            </span>
            , as updated from time to time (the
            <span className={styles.semibold}> “Terms”</span>). Unless otherwise
            defined in this DPA, all terms used in this DPA shall have the
            meanings given to them in the Terms.
          </p>
        </div>

        <div className={styles.chapter} id={DataProcessingIDs.definitions}>
          <h2 className={styles.subheading}>1. Definitions</h2>
          <p>
            The following terms used in this DPA will have the meanings given to
            them below:
          </p>
          <p>
            <span className={styles.semibold}>“Affiliates” </span>
            means entities controlling, controlled by, or under the same control
            with us.
          </p>
          <p>
            <span className={styles.semibold}>
              “Applicable Data Protection Legislation”{' '}
            </span>
            means the applicable data protection legislation as may be updated
            from time to time, including the GDPR if and where applicable.
          </p>
          <p>
            <span className={styles.semibold}>“Controller” </span>
            means the natural or legal person, which, alone or jointly with
            others, determines the purposes and means of the processing of
            personal data.
          </p>
          <p>
            <span className={styles.semibold}>“Customer Data” </span>
            means the “personal data” of the Prospects or Leads that is uploaded
            to the Platform.
          </p>
          <p>
            <span className={styles.semibold}>“data subjects” </span>
            means an identified or identifiable natural person, which means a
            natural person who can be identified, directly or indirectly, in
            particular by reference to an identifier such as a name or an
            identification number.
          </p>
          <p>
            <span className={styles.semibold}>“GDPR” </span>
            means Regulation 2016/679 of the European Parliament and of the
            Council of 27 April 2016 on the protection of natural persons with
            regard to the processing of personal data and on the free movement
            of such data, and repealing Directive 95/46/EC (General Data
            Protection Regulation).
          </p>
          <p>
            <span className={styles.semibold}>“personal data” </span>
            has the meaning assigned to it in the Applicable Data Protection
            Legislation. If there is no such a term or the Applicable Data
            Protection Legislation is unclear, “personal data” means any
            information relating to a data subject. The term “personal data”
            shall also include “personal information” and “personal identifiable
            information”.
          </p>
          <p>
            <span className={styles.semibold}>“Processor” </span>
            means a natural or legal person, which processes personal data on
            behalf of the Controller.
          </p>
          <p>
            <span className={styles.semibold}>“Security Incident” </span>
            means a breach of our security leading to the accidental or unlawful
            destruction, loss, alteration, unauthorized disclosure of, or access
            to, the Customer Data.
          </p>
          <p>
            <span className={styles.semibold}>
              “Standard Contractual Clauses”{' '}
            </span>
            means the standard contractual clauses for the transfer of Personal
            Data to third countries pursuant to Regulation (EU) 2016/679 of the
            European Parliament and the Council approved by the European
            Commission Implementing Decision (EU) 2021/914 of 4 June 2021 as
            currently set out at{' '}
            <a
              href="https://eurlex.europa.eu/eli/dec_impl/2021/914/oj"
              className={styles.link}
            >
              https://eurlex.europa.eu/eli/dec_impl/2021/914/oj
            </a>
            . Only Modules 2 and 3 shall apply depending on the User&rsquo;s
            role (Controller or Processor) and, unless otherwise stated in
            Exhibit I hereto, no optional clauses shall apply.
          </p>
          <p>
            <span className={styles.semibold}>“User”</span> means a Client or
            Vendor.
          </p>
        </div>

        <div className={styles.chapter} id={DataProcessingIDs.data_processing}>
          <h2 className={styles.subheading}> 2. Data Processing</h2>
          <p>
            <span className={styles.semibold}>2.1. Scope. </span>
            This DPA applies when you upload, download, or access the Customer
            Data when you use the Platform.
          </p>
          <p className={styles.semibold}>2.2. Roles. </p>
          <div className={styles.ml24}>
            <p>
              <span className={styles.semibold}>
                a&#041; Uploading Customer Data.
              </span>
              Subject to this DPA, when a User uploads the Customer Data to the
              Platform, we will act as a Processor of such a User with respect
              to the uploaded Customer Data. The User uploading the Customer
              Data to the Platform can act either as a Controller or Processor
              of the respective Customer Data.
            </p>
            <p>
              <span className={styles.semibold}>
                b&#041; Downloading Customer Data.
              </span>
              The User downloading the Customer Data from the Platform can act
              either as a Controller or Processor of the respective Customer
              Data.
            </p>
          </div>
          <p className={styles.semibold}>2.3. Details of Data Processing.</p>
          <div className={styles.ml24}>
            <p>
              <span className={styles.semibold}>a&#041; Subject matter.</span>
              The subject matter of the data processing under this DPA is the
              Customer Data.
            </p>
            <p>
              <span className={styles.semibold}>b&#041; Duration.</span>
              When we act as a Processor, subject to the applicable law or a
              valid and binding order of a governmental body, the duration of
              the data processing under this DPA is limited to the term of the
              respective Campaign or existence of your respective Account,
              whichever is later.
            </p>
            <p>
              <span className={styles.semibold}>c&#041; Purpose.</span>
              The purpose of the data processing under this DPA is the provision
              of the Platform functionality.
            </p>
            <p>
              <span className={styles.semibold}>
                d&#041; Nature of the processing.
              </span>
              Storage, structuring, adaptation, retrieval, providing Mailing
              Services, verification/validation (includes QC Services), and such
              other activities as available within the Platform and/or initiated
              by the respective User.
            </p>
            <p>
              <span className={styles.semibold}>
                e&#041; Type of Customer Data.
              </span>
              The Customer Data uploaded to the Platform by a User, which may
              include the following: full name, email address, phone number,
              country, position.
            </p>
            <p>
              <span className={styles.semibold}>
                f&#041; Categories of data subjects.
              </span>
              The data subjects could include Prospects and Leads.
            </p>
          </div>
          <p>
            <span className={styles.semibold}>2.4. Compliance with Laws. </span>
            We and you will comply with all applicable and binding laws, rules,
            and regulations, in relation to the performance of this DPA,
            including with the Applicable Data Protection Legislation. When we
            act as a Processor, we will reasonably assist you with your
            obligations under the Applicable Data Protection Legislation,
            including your obligations to respond to requests from data
            subjects. Taking into account the nature of the processing, you
            agree that it is unlikely that we would become aware that any of the
            Customer Data is inaccurate or outdated. Nonetheless, if we become
            aware that the Customer Data is inaccurate or outdated, we will
            inform you without undue delay. We will cooperate with you to erase
            or rectify inaccurate or outdated Customer Data, if you are unable
            to do so independently within the Platform interface.
          </p>
        </div>
        <div className={styles.chapter} id={DataProcessingIDs.instructions}>
          <h2 className={styles.subheading}>3. Instructions</h2>
          <p>
            When we act as a Processor, subject to the applicable law, including
            the Applicable Data Protection Legislation, and the Terms, we will
            act upon your documented instructions regarding the Customer Data
            uploaded by you to the Platform (
            <span className={styles.semibold}> “Documented Instructions”</span>
            ). We will process the Customer Data only in accordance with
            Documented Instructions (which if you are acting as a Processor,
            could be based on the instructions of the respective Controller(s)).
            Additional instructions outside the scope of the Documented
            Instructions (if any) require prior written agreement between you
            and us, including agreement on any additional fees payable by you to
            us for carrying out such instructions. Taking into account the
            nature of the processing, it is unlikely that we can form an opinion
            on whether Documented Instructions infringe the Applicable Data
            Protection Legislation. However, if we form such an opinion, we will
            immediately inform you, in which case, you are entitled to withdraw
            or modify respective Documented Instructions.
          </p>
        </div>
        <div className={styles.chapter} id={DataProcessingIDs.confidentiality}>
          <h2 className={styles.subheading}>4. Confidentiality</h2>
          <p>
            <span className={styles.semibold}>4.1. </span>
            We will not access or use, or disclose to any third party, any
            Customer Data, except, in each case, (I) as outlined in this DPA or
            Terms, (II) as necessary to maintain or provide the Platform, or
            (III) as necessary to comply with the law or a valid and binding
            order of a governmental body (such as a subpoena or court order). If
            a governmental body sends us a demand for the Customer Data, we will
            attempt to redirect the governmental body to request that data
            directly from you. As part of this effort, we may provide your basic
            contact information to the governmental body. If compelled to
            disclose the Customer Data to a governmental body, then we will give
            you reasonable notice of the demand to allow you to seek a
            protective order or other appropriate remedy unless we are legally
            prohibited from doing so.
          </p>
          <p>
            <span className={styles.semibold}>4.2. </span>
            We restrict our personnel from processing the Customer Data without
            our authorisation. We impose appropriate contractual obligations
            upon our personnel, including relevant obligations regarding
            confidentiality, data protection, and data security.
          </p>
        </div>
        <div className={styles.chapter} id={DataProcessingIDs.security}>
          <h2 className={styles.subheading}>5. Security</h2>
          <p>
            <span className={styles.semibold}>5.1. </span>
            We have implemented and will maintain reasonably necessary technical
            and organisational measures to protect the confidentiality,
            integrity and availability of the Customer Data. This includes (I)
            information security measures, (II) physical security measures,
            (III) measures to control access rights for our personnel and
            contractors, and (IV) processes for regularly testing, assessing and
            evaluating the effectiveness of the technical and organisational
            measures implemented by us. Security measures may be updated by us
            from time to time.
          </p>
          <p>
            <span className={styles.semibold}>5.2. </span>
            You hereby certify that after your assessment of the Applicable Data
            Protection Legislation, the technical and organisational measures
            implemented by us adequately protect the Customer Data contemplated
            herein against accidental or unlawful destruction, loss, alteration,
            unauthorised or unlawful disclosure or access, in particular when
            the processing involves data transmission over a network, and
            against other forms of unlawful or unauthorised processing.
          </p>
        </div>

        <div className={styles.chapter} id={DataProcessingIDs.sub_processing}>
          <h2 className={styles.subheading}>6. Sub-processing</h2>
          <p>
            <span className={styles.semibold}>6.1. </span>
            You hereby provide us with general authorisation to use of
            sub-processors to provide processing activities on the Customer Data
            on your behalf of Customer in accordance with this Section. If we
            engage any subprocessor, we will list each subprocessor in this DPA
            at least thirty (30) days before we engage a subprocessor. To object
            to a subprocessor, you can (I) terminate the Terms pursuant to their
            terms and/or (II) cease using the Platform.
          </p>
          <p>
            <span className={styles.semibold}>6.2. </span>
            Where we engage a subprocessor: (I) we will restrict the
            subprocessor&apos;s access to the Customer Data only to what is
            necessary to provide or maintain the respective activities and we
            will prohibit the subprocessor from accessing the Customer Data for
            any other purpose; (II) we will enter into a written agreement with
            the subprocessor and, to the extent that the subprocessor performs
            the same data processing services provided by us under this DPA, we
            will impose on the subprocessor the same contractual obligations
            that we have under this DPA; and (III) we will remain responsible
            for subprocessor&apos;s compliance with the obligations of this DPA
            and for any acts or omissions of the subprocessor that cause us to
            breach any of our obligations under this DPA.
          </p>
        </div>

        <div
          className={styles.chapter}
          id={DataProcessingIDs.assistance_with_data_subject_requests}
        >
          <h2 className={styles.subheading}>
            7. Assistance with Data Subject Requests
          </h2>
          <p>
            Taking into account the nature of the processing, we will assist you
            in fulfilling your obligations to respond to data subjects’ requests
            under the Applicable Data Protection Legislation. If a data subject
            makes a request to us, we will promptly forward such request to you
            once we have identified that the request is from a data subject for
            whom you are responsible. You hereby authorise us to respond on your
            behalf to any data subject who makes a request to us, to confirm
            that we have forwarded the request to you.
          </p>
        </div>

        <div
          className={styles.chapter}
          id={DataProcessingIDs.security_incident_notification}
        >
          <h2 className={styles.subheading}>
            8. Security Incident Notification
          </h2>
          <p>
            <span className={styles.semibold}>8.1. </span>
            We will (a) notify you of a Security Incident without undue delay
            after becoming aware of the Security Incident, and (b) take
            appropriate measures to address the Security Incident, including
            measures to mitigate any adverse effects resulting from the Security
            Incident.
          </p>
          <p>
            <span className={styles.semibold}>8.2. </span>
            To enable you to notify a Security Incident to the appropriate
            government authorities or data subjects (as applicable), we will
            cooperate with and assist you by including in the notification under
            clause 8.1 above such information about the Security Incident as we
            are able to disclose to you, taking into account the nature of the
            processing, the information available to us, and any restrictions on
            disclosing the information, such as confidentiality. Taking into
            account the nature of the processing, you agree that you are best
            able to determine the likely consequences of a Security Incident.
          </p>
          <p>
            <span className={styles.semibold}>8.3. </span>
            You agree that: (I) an unsuccessful Security Incident will not be
            subject to this section 8. An unsuccessful Security Incident is one
            that results in no unauthorised access to the Customer Data or to
            any of our equipment or facilities storing the Customer Data, and
            could include, without limitation, pings and other broadcast attacks
            on firewalls or edge servers, port scans, unsuccessful log-on
            attempts, denial of service attacks, packet sniffing (or other
            unauthorised access to traffic data that does not result in access
            beyond headers) or similar incidents; and (II) our obligation to
            report or respond to a Security Incident under this section 8 is not
            and will not be construed as an acknowledgement by us of any our
            fault or liability with respect to the Security Incident.
          </p>
          <p>
            <span className={styles.semibold}>8.4. </span>
            Notification(s) of Security Incidents, if any, will be delivered to
            you by any means we select, including via email. It is your sole
            responsibility to ensure that you maintain accurate contact
            information on the Platform and secure transmission at all times.
          </p>
        </div>

        <div
          className={styles.chapter}
          id={DataProcessingIDs.audits_assessment_and_consultations}
        >
          <h2 className={styles.subheading}>
            9. Audits, Assessments, and Consultations
          </h2>
          <p>
            <span className={styles.semibold}>9.1. </span>
            At your written request, and provided that we have an applicable
            non-disclosure agreement in place, we will contribute to audits
            conducted by you or nominated third party so that you can reasonably
            verify our compliance with our obligations under this DPA.
          </p>
          <p>
            <span className={styles.semibold}>9.2. </span>
            If you choose to conduct any audit, including any inspection, you
            have the right to request or mandate on your own behalf, and on
            behalf of your Controllers when you are acting as a Processor, an
            audit. If you wish to change your instruction regarding the audit,
            then you have the right to request a change to the respective
            instructions by sending us a written notice as provided for in the
            Terms. If we decline to follow any instruction requested by you
            regarding audits, including inspections, you are entitled to
            terminate the Terms in accordance with their terms.
          </p>
          <p>
            <span className={styles.semibold}>9.3. </span>
            Taking into account the nature of the processing and the information
            available to us, we will assist you in complying with your
            obligations in respect of data protection impact assessments and
            prior consultation, by providing the reasonably necessary
            information.
          </p>
        </div>

        <div
          className={styles.chapter}
          id={DataProcessingIDs.transfers_of_personal_data}
        >
          <h2 className={styles.subheading}>10. Transfers of Personal Data</h2>
          <p>
            <span className={styles.semibold}>10.1. </span>
            As a general rule, we process personal data in the United States of
            America, which jurisdiction may not provide the same level of
            privacy and data protection as other jurisdictions, such as the
            European Economic Area, United Kingdom, or Switzerland.
          </p>
          <p>
            <span className={styles.semibold}>10.2. </span>
            For transfers of Customer Data from the European Economic Area,
            United Kingdom, or Switzerland, the Standard Contractual Clauses are
            incorporated herein and completed as set forth in and subject to
            Exhibit I, as an appropriate safeguard. When you are acting as a
            Controller, the Module 2 of the Standard Contractual Clauses will
            apply to the respective transfer. When you are acting as a
            processor, the Module 3 of the Standard Contractual Clauses will
            apply to the respective transfer. Taking into account the nature of
            the processing, you agree that it is unlikely that we will know the
            identity of your respective Controllers because we may have no
            direct relationship with your respective Controllers and therefore,
            you will fulfil our obligations to your respective Controllers under
            the Module 3 of the Standard Contractual Clauses.
          </p>
          <p>
            <span className={styles.semibold}>10.3. </span>
            Additional Terms to the Standard Contractual Clauses (if and to the
            extent the Standard Contractual Clauses apply):
            <div className={styles.ml24}>
              <p>
                <span className={styles.semibold}>a&#041; </span>
                <span className={styles.semibold}>Conflict.</span> In the event
                of any conflict or inconsistency between this DPA and the
                Standard Contractual Clauses, the Standard Contractual Clauses
                shall prevail.
              </p>
              <p>
                <span className={styles.semibold}>b&#041; </span>
                <span className={styles.semibold}>Docking Clause.</span>
                The option under Clause 7 shall not apply.
              </p>
              <p>
                <span className={styles.semibold}>c&#041; </span>
                <span className={styles.semibold}>
                  Security of Processing.{' '}
                </span>
                You are solely responsible for making an independent
                determination as to whether the technical and organisational
                measures set forth in this DPA meet your requirements and agrees
                that (taking into account the state of the art, the costs of
                implementation, and the nature, scope, context and purposes of
                the processing of the Customer Data as well as the risks to
                individuals) the security measures and policies implemented and
                maintained by us provide a level of security appropriate to the
                risk with respect to the Customer Data.
              </p>
              <p>
                <span className={styles.semibold}>d&#041; </span>
                <span className={styles.semibold}>Audits. </span>
                The audits described in the Standard Contractual Clauses shall
                be carried out in accordance with this DPA.
              </p>
              <p>
                <span className={styles.semibold}>e&#041; </span>
                <span className={styles.semibold}>Sub-processors.</span>
                Pursuant to the Standard Contractual Clauses, we have your
                general authorisation for the engagement of sub-processor(s).
                Appointment and notification of sub-processors shall be carried
                out in accordance with this DPA.
              </p>
              <p>
                <span className={styles.semibold}>f&#041; </span>
                <span className={styles.semibold}>Data Subject Rights. </span>
                Pursuant to the Standard Contractual Clauses, data subject
                rights shall be carried out in accordance with this DPA.
              </p>
              <p>
                <span className={styles.semibold}>g&#041; </span>
                <span className={styles.semibold}>Liability. </span>
                Pursuant to the Standard Contractual Clauses, our liability
                shall be subject to the Terms.
              </p>
              <p>
                <span className={styles.semibold}>h&#041; </span>
                <span className={styles.semibold}>
                  Court-review safeguard.{' '}
                </span>
                If we receive demands for data access through national security
                process for personal data related to you or the Customer Data
                provided by you, to the extent we conclude there are reasonable
                grounds to consider that the request is unlawful under the laws
                of the third country of destination, we shall use commercially
                reasonable legal mechanisms to challenge such demands as well as
                any non-disclosure provisions attached thereto.
              </p>
              <p>
                <span className={styles.semibold}>i&#041; </span>
                <span className={styles.semibold}>Notice of demand. </span>
                To the extent legally permissible, we shall promptly notify you
                if we receive demands for data access through the national
                security process for data related to you or the Customer Data
                provided by you and we shall make all reasonable legal efforts
                to refrain from providing data until you have had an opportunity
                to challenge such demands.
              </p>
              <p>
                <span className={styles.semibold}>j&#041; </span>
                <span className={styles.semibold}>
                  Certification of Deletion.{' '}
                </span>
                The certification of deletion of the Customer Data that is
                described in the Standard Contractual Clauses shall be provided
                by us to you only upon your written request.
              </p>
              <p>
                <span className={styles.semibold}>k&#041; </span>
                <span className={styles.semibold}>Annexes. </span>
                The contents of Annexes to the Standard Contractual Clauses is
                provided in Exhibit I below.
              </p>
              <p>
                <span className={styles.semibold}>l&#041; </span>
                <span className={styles.semibold}>
                  Data Exports from the United Kingdom and Switzerland under the
                  Standard Contractual Clauses.{' '}
                </span>
                In the case of any transfers of the Customer Data from the
                United Kingdom that is exclusively subject to United Kingdom
                Data Protection Laws and Regulations (
                <span className={styles.semibold}>
                  “UK Data Protection Laws”
                </span>
                ), and/or in case of any transfers of the Customer Data from
                Switzerland that is exclusively subject to Swiss Data Protection
                Laws and Regulations (
                <span className={styles.semibold}>
                  “Swiss Data Protection Laws”
                </span>
                ), then general and specific references or obligation in the
                Standard Contractual Clauses to the GDPR or EU or Member State
                Law shall refer to the equivalent reference in either UK Data
                Protection Laws or Swiss Data Protection Laws, as applicable. In
                respect of data transfers governed by Swiss Data Protection
                Laws, the Standard Contractual Clauses also apply to the
                transfer of information relating to an identifiable legal entity
                where such information is protected similarly as personal data
                under Swiss Data Protection Laws until such laws are amended to
                no longer apply to a legal entity. In respect of data transfers
                governed by UK Data Protection Laws, by entering into this DPA,
                the we and you are hereby deemed to have entered the United
                Kingdom Information Commissioner’s International Data Transfer
                Addendum to the EU Commission Standard Contractual Clauses
                (version B1.0 of which is available at
                <a
                  href=" https://ico.org.uk/media/for-organisations/documents/4019539/international-data-transferaddendum.pdf,"
                  className={styles.link}
                >
                  {' '}
                  https://ico.org.uk/media/for-organisations/documents/4019539/international-data-transferaddendum.pdf,
                </a>
                which are completed as follows:
                <div className={styles.ml24}>
                  <p>
                    Table 1: the parties’ details and key contacts are set forth
                    in Exhibit I below.
                  </p>
                  <p>
                    Table 2: the Approved Standard Contractual Clauses
                    referenced in Table 2 shall be the Standard Contractual
                    Clauses as set forth in Clause 10.2 of this DPA.
                  </p>
                  <p>
                    Table 3: Annexes 1A, 1B, II and III are as set forth in as
                    set forth in Exhibit I below.
                  </p>
                  <p>
                    Table 4: We may end the UK Standard Contractual Clauses as
                    set out in Section 19.
                  </p>
                  <p>
                    Subject to the terms of this DPA, by entering into this DPA,
                    the parties are deemed to be signing the UK Standard
                    Contractual Clauses.
                  </p>
                </div>
              </p>
            </div>
          </p>
        </div>

        <div
          className={styles.chapter}
          id={DataProcessingIDs.termination_of_the_dpa}
        >
          <h2 className={styles.subheading}>11. Termination of the DPA</h2>
          <p>
            This DPA will continue in force until the termination of the Terms
            (the <span className={styles.semibold}> “Termination Date”</span>).
          </p>
        </div>
        <div
          className={styles.chapter}
          id={DataProcessingIDs.return_or_deletion_of_customer_data}
        >
          <h2 className={styles.subheading}>
            12. Return or Deletion of Customer Data
          </h2>
          <p>
            When we act as a Processor, at any time up to the Termination Date,
            subject to the requirements of the applicable legislation and the
            terms and conditions of the Terms, we will return or delete the
            Customer Data when you (I) request such return or deletion or (II)
            do this using the Platform functionality.
          </p>
        </div>

        <div className={styles.chapter} id={DataProcessingIDs.duties_to_inform}>
          <h2 className={styles.subheading}>13. Duties to Inform</h2>
          <p>
            Where the Customer Data becomes subject to confiscation during
            bankruptcy or insolvency proceedings, or similar measures by third
            parties while being processed by us, we will inform you without
            undue delay. We will, without undue delay, notify all relevant
            parties in such action (for example, creditors, bankruptcy trustee)
            that any Customer Data subjected to those proceedings is your
            property and area of responsibility and that the Customer Data is at
            your sole disposition.
          </p>
        </div>

        <div
          className={styles.chapter}
          id={DataProcessingIDs.entire_agreement_conflict}
        >
          <h2 className={styles.subheading}>14. Entire Agreement; Conflict</h2>
          <p>
            <span className={styles.semibold}>14.1. </span>
            In the cases explicitly outlined herein, this DPA incorporates the
            Standard Contractual Clauses by reference. Unless otherwise
            explicitly prescribed herein, nothing in this DPA varies or modifies
            the Standard Contractual Clauses.
          </p>
          <p>
            <span className={styles.semibold}>14.2. </span>
            Exhibit I constitutes an integral part of this DPA.
          </p>
          <p>
            <span className={styles.semibold}>14.3. </span>
            Except as amended by this DPA, the Terms will remain in full force
            and effect. Unless otherwise expressly prescribed herein, if there
            is a conflict between the Terms and this DPA, the terms of this DPA
            will control with respect to the terms of processing the Customer
            Data.
          </p>
          <p>
            <span className={styles.semibold}>14.4. </span>
            Nothing in this DPA amends or affects the “Personal Data and Data
            Protection” Section of the Terms as may be updated from time to
            time.
          </p>
        </div>

        <div className={styles.chapter} id={DataProcessingIDs.exhibit_I}>
          <h2 className={styles.subheading}>Exhibit I.</h2>
          <p
            className={styles.semibold}
            id={DataProcessingIDs.exhibit_I_annex_I}
          >
            Annex I (Details of the Processing Activities and Transfer)
          </p>
          <p className={styles.semibold}>List of Parties</p>
          <p className={styles.semibold}>Data exporter(s):</p>
          <div className={styles.ml24}>
            <p>
              <span className={styles.semibold}>Name: </span>
              The User, as defined in the DPA, uploading the personal data to
              the Platform, as defined in the DPA.
            </p>
            <p>
              <span className={styles.semibold}>Address: </span>
              The address associated with the respective User’s Admin Account or
              Vendor Account, as defined in the Terms.
            </p>
            <p>
              <span className={styles.semibold}>
                Contact person’s name, position and contact details:{' '}
              </span>
              The contact details associated with the respective User’s Admin
              Account or Vendor Account.
            </p>
            <p>
              <span className={styles.semibold}>
                Activities relevant to the data transferred under these Clauses:{' '}
              </span>
              The activities specified in Clause 2.3 of the DPA.
            </p>
            <p>
              <span className={styles.semibold}>Signature and date: </span>
              Subject to the DPA, by using the Platform to transfer the Customer
              Data to Leadforce or other Users, the data exporter will be deemed
              to have signed the Standard Contractual Clauses.
            </p>
            <p>
              <span className={styles.semibold}>
                Role (controller / processor):{' '}
              </span>
              Controller or Processor (as the case may be).
            </p>
          </div>
          <p className={styles.semibold}>Data importer(s): </p>
          <div className={styles.ml24}>
            <p>
              <span className={styles.semibold}>Name: </span>
              “Leadforce” as identified in the DPA.
            </p>
            <p>
              <span className={styles.semibold}>Address: </span>
              The address for Leadforce specified in the Terms.
            </p>
            <p>
              <span className={styles.semibold}>
                Contact person’s name, position and contact details:{' '}
              </span>
              The contact details for Leadforce specified in the Terms.
            </p>
            <p>
              <span className={styles.semibold}>
                Activities relevant to the data transferred under these Clauses:{' '}
              </span>
              The activities specified in Clause 2.3 of the DPA.
            </p>
            <p>
              <span className={styles.semibold}>Signature and date: </span>
              Subject to the DPA, by receiving the Customer Data within the
              Platform, the data importer will be deemed to have signed the
              Standard Contractual Clauses.
            </p>
            <p>
              <span className={styles.semibold}>
                Role (controller / processor):{' '}
              </span>
              Processor
            </p>
          </div>
          <p className={styles.semibold}>Description of Transfer</p>
          <p>
            <span className={styles.semibold}>
              1. Categories of data subjects whose personal data is transferred:{' '}
            </span>
            Categories of data subjects are specified in Clause 2.3 of the DPA.
          </p>
          <p>
            <span className={styles.semibold}>
              2. Categories of personal data transferred:{' '}
            </span>
            Categories of personal data are described in Clause 2.3 of the DPA.
          </p>
          <p>
            <span className={styles.semibold}>
              3. Sensitive data transferred (if applicable) and applied
              restrictions or safeguards that fully take into consideration the
              nature of the data and the risks involved, such as for instance
              strict purpose limitation, access restrictions (including access
              only for staff having followed specialised training), keeping a
              record of access to the data, restrictions for onward transfers or
              additional security measures:{' '}
            </span>
            The data exporter is not likely to include sensitive personal data
            in the personal data described in Clause 2.3 of the DPA.
          </p>
          <p>
            <span className={styles.semibold}>
              4. The frequency of the transfer (e.g. whether the data is
              transferred on a one-off or continuous basis):{' '}
            </span>
            Continuous, depending on User’s use of the Platform.
          </p>
          <p>
            <span className={styles.semibold}>
              5. Nature of the processing:{' '}
            </span>
            The nature of the processing is described in Clause 2.3 of the DPA.
          </p>
          <p>
            <span className={styles.semibold}>
              6. Purpose(s) of the data transfer and further processing:{' '}
            </span>
            To provide the Platform functionality.
          </p>
          <p>
            <span className={styles.semibold}>
              7. The period for which the personal data will be retained, or, if
              that is not possible, the criteria used to determine that period:
            </span>
            As outlined in Clause 2.3 of the DPA.
          </p>
          <p>
            <span className={styles.semibold}>
              8. For transfers to (sub-) processors, also specify subject
              matter, nature and duration of the processing:{' '}
            </span>
            Subject to Section 6 of the DPA, the subject matter, nature and
            duration of the processing are described in Clause 2.3 of the DPA.
          </p>
          <p className={styles.semibold}>Competent Supervisory Authority</p>
          <p>
            The competent supervisory authority is the supervisory authority
            where (I) the Data exporter is incorporated, if the Data exporter is
            incorporated in an EU Member State, or (II) the Data exporter’s
            establishment is located, if the Data exporter is incorporated not
            in an EU Member State, but has an establishment in an EU Member
            State, or (III) the Data exporter’s EU representative is located, if
            the Data exporter is incorporated not in an EU Member State and has
            no establishment in an EU Member State.
          </p>
          <p>
            Where the data exporter is established in Switzerland or falls
            within the territorial scope of application of Swiss Data Protection
            Laws and Regulations, the Swiss Federal Data Protection and
            Information Commissioner shall act as competent supervisory
            authority insofar as the relevant data transfer is governed by Swiss
            Data Protection Laws and Regulations.
          </p>
          <p>
            Where the data exporter is established in the United Kingdom or
            falls within the territorial scope of application of UK Data
            Protection Laws and Regulations, the Information Commissioner&apos;s
            Office shall act as the competent supervisory authority insofar as
            the relevant data transfer is governed by UK Data Protection Laws
            and Regulations.
          </p>
          <p
            className={styles.semibold}
            id={DataProcessingIDs.exhibit_I_annex_II}
          >
            Annex II (Technical and Organisational Measures Including Technical
            and Organisational Measures to Ensure the Security of the Data)
          </p>
          <p>
            The technical and organizational measures as well as the scope and
            the extent of the assistance required to respond to data subjects’
            requests, are described in the DPA.
          </p>
          <p>
            The technical and organisational measures that the data importer
            will impose on sub-processors are described in the DPA.
          </p>
          <p
            className={styles.semibold}
            id={DataProcessingIDs.exhibit_I_annex_III}
          >
            Annex III (List of Sub-processors)
          </p>
          <p>N/A</p>
          <p
            className={styles.semibold}
            id={DataProcessingIDs.exhibit_I_annex_IV}
          >
            Annex IV (Additional Clauses)
          </p>
          <p>
            The Limitation of Liability Section of the Terms (Section 22 of the
            Terms) is an additional clause pursuant to Clause 2 of the Standard
            Contractual Clauses.
          </p>
        </div>
        <ScrollToTopButton />
      </div>
    </main>
  );
};

export default DataProcessing;
