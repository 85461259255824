import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import type { ReactEditor } from 'slate-react';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import RichText from 'components/RichText';
import FocusedWrapper from 'components/RichText/FocusedWrapper';

import BlockHeading from 'components/CreateCampaign/Step1/ScriptBuilder/BlockHeading';
import EditWarning from 'components/CreateCampaign/Step1/ScriptBuilder/EditWarning';

import { useEditWarning } from 'components/CreateCampaign/Step1/ScriptBuilder/useEditWarning';

import { createIntroductionTemplate } from 'constants/scriptTemplates';
import { IconsNames } from 'constants/constants';

type Props = {
  htmlString?: string;
};

const Introduction = ({ htmlString }: Props) => {
  const { t } = useTranslation();
  const introductionEditorRef = useRef<ReactEditor>();
  const formSettings = useFormContext<CreateCampaignValues>();
  const { control, formState } = formSettings || {};
  const isPreviewMode = !!htmlString;

  const { field: introductionField } = !isPreviewMode
    ? useController({
        name: 'script_builder.introduction_block',
        control,
      })
    : { field: null };

  const { showWarningOnChange, isWarningVisible } = useEditWarning({
    defaultInitialTemplate: createIntroductionTemplate(t),
    currentTemplate: introductionField?.value,
  });

  const validationError =
    formState?.errors?.script_builder?.introduction_block?.message;

  return (
    <>
      <BlockHeading iconName={IconsNames.hello_hand}>
        {t('script.introduction')}
      </BlockHeading>

      <FocusedWrapper isPreviewMode={isPreviewMode}>
        {props =>
          isPreviewMode ? (
            <RichText htmlString={htmlString} />
          ) : (
            <>
              <RichText
                value={introductionField?.value}
                onChange={val => {
                  showWarningOnChange(val);
                  introductionField?.onChange(val);
                }}
                editorRef={introductionEditorRef}
                placeholder={t('script.introduction')}
                validationError={validationError}
                {...props}
              />
              {isWarningVisible && <EditWarning />}
            </>
          )
        }
      </FocusedWrapper>
    </>
  );
};

export default Introduction;
