import useAuth from 'contexts/AuthContext';
import { useInfiniteQuery } from 'react-query';

import type { CampaignResponse, PaginationReturn } from 'types/models';
import type { AxiosError } from 'axios';

import { UserTypes } from 'constants/constants';

const useFetchСampaigns = (readOnly?: boolean) => {
  const { axios, user } = useAuth();

  const query = useInfiniteQuery<
    PaginationReturn<CampaignResponse>,
    AxiosError
  >(
    ['campaign-list', user?.current_group_name],
    async ({ pageParam }) => {
      let page;
      if (typeof pageParam === 'string') {
        const url = new URL(pageParam);
        page = url.searchParams.get('page');
      }
      const pageNumber = page || 1;

      try {
        const { data } = await axios.get<PaginationReturn<CampaignResponse>>(
          `/campaigns/?page_size=15&page=${pageNumber}`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      getNextPageParam: page => {
        return page.next || undefined;
      },
      enabled:
        !readOnly &&
        !!user &&
        (user?.current_group_name === UserTypes.admin ||
          user?.current_group_name === UserTypes.vendorManager),
    }
  );

  return query;
};

export default useFetchСampaigns;
