import { ServiceContractIDs } from 'components/DocumentsContent/constants';

import styles from 'components/DocumentsContent/index.module.scss';

const SideMenu = () => {
  return (
    <div className={styles.sidemenu_container}>
      <div className={styles.sidemenu_content}>
        <h4 className={styles.heading}>Table of contents</h4>
        <ul className={styles.list}>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.interpretation}`}
              className={styles.anchor_link}
            >
              1. Interpretation
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.services}`}
              className={styles.anchor_link}
            >
              2. Services
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.compensation_and_payment_terms}`}
              className={styles.anchor_link}
            >
              3. Compensation and Payment Terms
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.intellectual_property_rights}`}
              className={styles.anchor_link}
            >
              4. Intellectual Property Rights
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.confidential_information}`}
              className={styles.anchor_link}
            >
              5. Confidential Information
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.warranties_and_representations}`}
              className={styles.anchor_link}
            >
              6. Warranties and Representations
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.limitation_of_liability}`}
              className={styles.anchor_link}
            >
              7. Limitation of Liability
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.indemnification}`}
              className={styles.anchor_link}
            >
              8. Indemnification
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.term_and_termination}`}
              className={styles.anchor_link}
            >
              9. Term and Termination
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.no_partnership_or_agency}`}
              className={styles.anchor_link}
            >
              10. No Partnership or Agency
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.applicable_law_and_dispute_resolution}`}
              className={styles.anchor_link}
            >
              11. Applicable Law and Dispute Resolution
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.personal_data_and_data_protection}`}
              className={styles.anchor_link}
            >
              12. Personal Data and Data Protection
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${ServiceContractIDs.miscellaneous}`}
              className={styles.anchor_link}
            >
              13. Miscellaneous
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
