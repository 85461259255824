import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import type { Path } from 'react-hook-form';

import AuthHeader from 'components/common/AuthHeader';
import AuthForm from 'components/common/AuthForm';
import LoaderScreen from 'components/common/LoaderScreen';

import { validationSchemaResetPassword } from 'utils/validations';

import { InputData } from 'types/models';

import styles from './index.module.scss';

type ResetPasswordData = {
  email: string;
};

type Field = InputData<ResetPasswordData> & { name: Path<ResetPasswordData> };

const fields: Field[] = [
  {
    name: 'email',
    type: 'email',
    required: true,
    label: 'email',
  },
];

const ResetPasswordContent = () => {
  const { t } = useTranslation();
  const { mutate, isLoading, error, isSuccess } = useMutation<
    ResetPasswordData,
    AxiosError,
    ResetPasswordData
  >(async (userData: ResetPasswordData) => {
    try {
      const { data } = await axios.post<ResetPasswordData>(
        `${process.env.REACT_APP_BACKEND_URL}/accounts/password/reset/`,
        userData
      );

      return data;
    } catch (err) {
      throw err;
    }
  });

  const onSubmit = (data: ResetPasswordData) => {
    mutate(data);
  };

  return (
    <div className={styles.wrapper}>
      <AuthHeader title={t('auth.reset-password')} linkClass={styles.link} />
      {isSuccess ? (
        <p>{t('auth.reset-password-success')}</p>
      ) : (
        <AuthForm<ResetPasswordData>
          fields={fields}
          btnTitle="restore"
          onSubmit={onSubmit}
          loading={isLoading}
          submitError={error}
          validationSchema={validationSchemaResetPassword}
        />
      )}
      {isLoading && <LoaderScreen />}
    </div>
  );
};

export default ResetPasswordContent;
