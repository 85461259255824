import useAuth from 'contexts/AuthContext';
import { useQuery } from 'react-query';

const useFetchDeliveryEmails = ({
  searchValue,
  debouncedValue,
}: {
  searchValue: string;
  debouncedValue: string;
}) => {
  const { axios } = useAuth();

  const query = useQuery(
    ['delivery-emails', debouncedValue],
    async () => {
      try {
        const { data } = await axios.get<{ results: { email: string }[] }>(
          `/campaigns/delivery-emails/?page_size=20&search=${searchValue}`
        );
        const selectOptions = data.results.length
          ? data.results.map(({ email }) => ({
              value: email,
              label: email,
            }))
          : [];

        return selectOptions;
      } catch (error) {
        throw error;
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export default useFetchDeliveryEmails;
