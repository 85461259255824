import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';

import ModalWindow from 'components/common/ModalWindow';

import DepositNowButton from 'components/PaymentsComponents/DepositNowButton';

import useGetBalance from 'hooks/api/useGetBalance';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

type Props = {
  error: AxiosError;
  onCheckoutModalClose?: (() => void) | (() => Promise<void>);
};

const NoEnoughCreditsModal = ({ error, onCheckoutModalClose }: Props) => {
  const { t } = useTranslation();
  const { data: balance } = useGetBalance();

  return (
    <ModalWindow className={styles.modal}>
      <h2>
        {t('payments.should-have-at-least', {
          value: Number(getResponseError(error)) + Number(balance?.in_account),
        })}
      </h2>
      <p>
        {t('payments.your-current-balance', {
          balance: balance?.in_account,
        })}
      </p>
      <p>{t('payments.click-here-to-deposit')}</p>
      <DepositNowButton
        paymentOptions={{ amount: Number(getResponseError(error)) }}
        className={styles.button}
        checkoutOptions={{
          onClose: onCheckoutModalClose || (() => {}),
        }}
      />
    </ModalWindow>
  );
};

export default NoEnoughCreditsModal;
