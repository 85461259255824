import { useMutation } from 'react-query';

import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';

type Props = {
  onSuccess?: () => void;
  onError?: (err: any) => void;
};

const useDeleteAsset = (props?: Props) => {
  const { axios } = useAuth();

  const { onSuccess, onError } = props || {};

  const mutation = useMutation<
    void,
    AxiosError,
    { assetId: number; campaignId: number }
  >(
    async ({
      assetId,
      campaignId,
    }: {
      assetId: number;
      campaignId: number;
    }) => {
      try {
        await axios.delete(`/campaigns/${campaignId}/assets/${assetId}/`);
      } catch (err) {
        throw err;
      }
    },
    { onSuccess, onError }
  );

  return mutation;
};

export default useDeleteAsset;
