import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';

import type { ReactEditor } from 'slate-react';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import RichText from 'components/RichText';

import BlockHeading from 'components/CreateCampaign/Step1/ScriptBuilder/BlockHeading';
import FocusedWrapper from 'components/RichText/FocusedWrapper';
import EditWarning from 'components/CreateCampaign/Step1/ScriptBuilder/EditWarning';

import { useEditWarning } from 'components/CreateCampaign/Step1/ScriptBuilder/useEditWarning';

import { createWhitepaperTemplate } from 'constants/scriptTemplates';
import { IconsNames } from 'constants/constants';

type Props = {
  assetEditorRef: React.MutableRefObject<ReactEditor | undefined>;
  htmlString?: string;
};

const Asset = ({ assetEditorRef, htmlString }: Props) => {
  const { t } = useTranslation();
  const formSettings = useFormContext<CreateCampaignValues>();
  const { control, formState } = formSettings || {};

  const { field: assetField } = control
    ? useController({
        name: 'script_builder.asset_block',
        control,
      })
    : { field: null };

  const {
    showWarningOnChange: showWhitepaperWarning,
    isWarningVisible: isWhitepaperWarningVisible,
  } = useEditWarning({
    defaultInitialTemplate: createWhitepaperTemplate(t),
    currentTemplate: assetField?.value,
  });

  const validationError =
    formState?.errors.script_builder?.asset_block?.message;

  return (
    <>
      <BlockHeading iconName={IconsNames.file_stroke}>
        {t('script.asset')}
      </BlockHeading>

      <FocusedWrapper isPreviewMode={!!htmlString}>
        {props =>
          htmlString ? (
            <RichText htmlString={htmlString} />
          ) : (
            <>
              <RichText
                value={assetField?.value}
                onChange={val => {
                  showWhitepaperWarning(val);
                  assetField?.onChange(val);
                }}
                editorRef={assetEditorRef}
                validationError={validationError}
                {...props}
                placeholder={t('script.asset')}
              />
              {isWhitepaperWarningVisible && <EditWarning />}
            </>
          )
        }
      </FocusedWrapper>
    </>
  );
};

export default Asset;
