import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import cn from 'classnames';

import type { TwoFaComponentProps } from 'components/TwoFA/models';

import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';

import TwoFADescription from 'components/TwoFA/TwoFADescription';
import TwoFAInput from 'components/TwoFA/TwoFAInput';
import TwoFATitle from 'components/TwoFA/TwoFATitle';
import TwoFABackLink from 'components/TwoFA/TwoFABackLink';
import ResendCode from 'components/TwoFA/ResendCode';

import useVerifyOTPCode from 'hooks/api/useVerifyOTPCode';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

const Step2 = ({ device, onDeviceChange }: TwoFaComponentProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const { mutate: verify, isLoading, error } = useVerifyOTPCode();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    verify({ device_id: device.persistent_id, token: value.replace(/ /g, '') });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.currentTarget.value);

  return (
    <div className={styles.wrapper}>
      {onDeviceChange && <TwoFABackLink onDeviceChange={onDeviceChange} />}
      <TwoFATitle>{t('auth.we-sent-code-to-email')}</TwoFATitle>
      <TwoFADescription text="auth.please-enter-2-factor-code" />
      <form className={styles.form} onSubmit={handleSubmit}>
        <TwoFAInput
          value={value}
          onChange={handleInputChange}
          className={styles.input}
        />
        <Error message={error ? getResponseError(error) : ''} />
        <ResendCode
          className={cn(styles.button, styles.resend)}
          device={device}
        />
        <Button className={cn(styles.button, styles.submit)} type="submit">
          {t('common.button.connect')}
        </Button>
      </form>
      {isLoading && <LoaderScreen />}
    </div>
  );
};

export default Step2;
