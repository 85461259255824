import { usePopperTooltip } from 'react-popper-tooltip';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import Loader from 'components/common/LoaderScreen/Loader';

import { useDownloadContactsTemplate } from 'components/CampaignsListDeliveries/CampaignDetails/useDownloadContactsTemplate';

import { IconsNames } from 'constants/constants';

import { ReactComponent as TableSVG } from 'assets/images/deliveries/table.svg';

import styles from './index.module.scss';

const DownloadTemplate = () => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      interactive: true,
      offset: [0, 0],
      placement: 'bottom-start',
    });
  const { t } = useTranslation();
  const { id } = useParams();

  const { isLoading, refetch } = useDownloadContactsTemplate(id);

  return (
    <div className={styles.wrapper}>
      <div ref={setTriggerRef}>
        <Button
          className={styles.tip}
          white
          iconProps={{ name: IconsNames.bulb }}
          aria-label="tip"
        >
          {t('common.button.tip')}
        </Button>
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: cn('tooltip-container', styles.info),
          })}
          aria-label="tooltip"
        >
          <div className={styles.tooltip}>
            <TableSVG className={styles.table} />
            <p className={styles.title}>
              {t('campaign-deliveries.save-your-save')}
            </p>
            <p className={styles.description}>
              {t('campaign-deliveries.blank-template-table')}
            </p>
            {isLoading ? (
              <Loader size={40} />
            ) : (
              <Button
                iconProps={{ name: IconsNames.download }}
                onClick={() => refetch()}
              >
                {t('common.button.download-template')}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadTemplate;
