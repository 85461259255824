import { useQuery } from 'react-query';

import type { AxiosError } from 'axios';
import type { VendorAvailableMilestone } from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/models';
import type { CampaignResponse } from 'types/models';

import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';

export const useGetVendorMilestones = ({
  campaign,
}: {
  campaign: CampaignResponse;
}) => {
  const { axios } = useAuth();
  const { convertMilestoneDate } = useConvertCampaignData();

  const query = useQuery<VendorAvailableMilestone[], AxiosError>(
    ['upload-contacts-assets', campaign.id],
    async () => {
      try {
        const { data } = await axios.get<
          Omit<VendorAvailableMilestone, 'delivery_date' | 'is_available'>[]
        >(`/vendor-campaigns/${campaign.id}/milestones/`);

        const { milestones, milestone_deadline_type: deadlineType } =
          campaign.campaign_delivery;

        return data.reduce<VendorAvailableMilestone[]>((acc, item) => {
          const campaignMilestone = campaign.campaign_delivery.milestones.find(
            milestone => milestone.id === item.milestone
          );
          const isNotAllAssetsDelivered =
            item.leads_required >
            item.campaign_offer_assets.reduce((leads, val) => {
              leads += val.leads_qualified;

              return leads;
            }, 0);

          const isPreviousMilestonesAvailable = acc.some(
            milestone => milestone.is_available
          );
          const isLastMilestone =
            campaignMilestone?.id === milestones[milestones.length - 1].id;

          const deliveryDate = convertMilestoneDate(
            deadlineType,
            campaignMilestone?.delivery_date || null,
            isLastMilestone,
            'DD MMM, YYYY',
            'MMM YYYY'
          );

          acc.push({
            ...item,
            is_available:
              isNotAllAssetsDelivered && !isPreviousMilestonesAvailable,
            delivery_date: campaignMilestone?.delivery_date ? deliveryDate : '',
          });

          return acc;
        }, []);
      } catch (err) {
        throw err;
      }
    }
  );

  return query;
};
