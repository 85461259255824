import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  CreateCampaignStepProps,
  CreateCampaignValues,
} from 'components/CreateCampaign/models';

import IconSVG from 'components/UI/IconSVG';

import InfoSectionWrapper from 'components/CampaignDetails/InfoSectionWrapper';
import TotalCost from 'components/CreateCampaign/Step4/TotalCost';

import useGetSALFiles from 'components/CreateCampaign/useGetSALFiles';
import useGetTALFiles from 'components/CreateCampaign/useGetTALFiles';
import useGetReportTemplate from 'components/CreateCampaign/useGetReportTemplate';
import useGetLeadFile from 'components/CreateCampaign/useGetLeadFile';
import useGetAssets from 'components/CreateCampaign/useGetAssets';

import { CampaignCreatingStep, IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const Review = ({ goToStep, steps }: CreateCampaignStepProps) => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext<CreateCampaignValues>();

  const campaign = getValues();

  const isDemoCampaign =
    useWatch({ name: 'campaign_details.cost_per_lead' }) === 0;

  const getSALRequestSettings = useGetSALFiles({
    campaign,
    setFormValue: setValue,
  });
  const getTALRequestSettings = useGetTALFiles({
    campaign,
    setFormValue: setValue,
  });
  const getReportTemplateRequestSettings = useGetReportTemplate({
    campaign,
    setFormValue: setValue,
  });
  const getLeadFileRequestSettings = useGetLeadFile({
    campaign,
    setFormValue: setValue,
  });
  const getAssetsRequestSettings = useGetAssets({
    campaign,
    setFormValue: setValue,
  });

  const editStep = ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
    goToStep(currentTarget.id as CampaignCreatingStep);
  };

  return (
    <>
      <h2 className={styles.heading} id="demo">
        {t('campaign.review-and-submit')}
      </h2>
      <div className={styles.container}>
        {steps.map(({ name, disabled, View, title }) => {
          return (
            View && (
              <InfoSectionWrapper className={styles.section} key={name}>
                <InfoSectionWrapper.Header>
                  <InfoSectionWrapper.Title className={styles.title}>
                    {t(title)}
                    {!disabled && (
                      <button
                        type="button"
                        className={styles.edit}
                        id={name}
                        onClick={editStep}
                      >
                        <IconSVG name={IconsNames.edit} />
                        {t('common.button.edit')}
                      </button>
                    )}
                  </InfoSectionWrapper.Title>
                </InfoSectionWrapper.Header>
                <InfoSectionWrapper.Content>
                  <View
                    campaignData={campaign}
                    getSALRequestSettings={getSALRequestSettings}
                    getAssetsRequestSettings={getAssetsRequestSettings}
                    getTALRequestSettings={getTALRequestSettings}
                    getReportTemplateRequestSettings={
                      getReportTemplateRequestSettings
                    }
                    getLeadFileRequestSettings={getLeadFileRequestSettings}
                  />
                </InfoSectionWrapper.Content>
              </InfoSectionWrapper>
            )
          );
        })}
        <TotalCost isDemoCampaign={isDemoCampaign} goToStep={goToStep} />
      </div>
    </>
  );
};

export default Review;
