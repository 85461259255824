import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ProductAssetResponse } from 'types/models';

import AssetContentPreview from 'components/AssetDownloadContent/AssetContentPreview';
import AssetVideoPreview from 'components/AssetDownloadContent/AssetVideoPreview';

import logoSmall from 'assets/images/logo-small.svg';

import styles from './index.module.scss';

type Props = {
  data: ProductAssetResponse;
  isModalContent?: boolean;
  companySlug?: string;
  productSlug?: string;
  assetSlug?: string;
  contactShareId?: string;
};

const AssetDownloadContent = ({
  data,
  isModalContent = false,
  companySlug,
  productSlug,
  assetSlug,
  contactShareId,
}: Props) => {
  const { t } = useTranslation();
  const [isVideoPreview, setIsVideoPreview] = useState(false);

  return (
    <div
      className={cn(styles.main, { [styles.skipBackground]: isModalContent })}
    >
      <main className={styles.section}>
        {!isVideoPreview ? (
          <AssetContentPreview
            data={data}
            setIsVideoPreview={setIsVideoPreview}
            isModalContent={isModalContent}
            companySlug={companySlug}
            productSlug={productSlug}
            assetSlug={assetSlug}
            contactShareId={contactShareId}
          />
        ) : (
          <AssetVideoPreview
            data={data}
            isModalContent={isModalContent}
            companySlug={companySlug}
          />
        )}
      </main>
      {!isModalContent && (
        <footer className={styles.footer}>
          <img src={logoSmall} />
          <span>{t('product.made-with-beeleads')}</span>
        </footer>
      )}
    </div>
  );
};

export default AssetDownloadContent;
