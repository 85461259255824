import { useMutation, useQueryClient } from 'react-query';
import cn from 'classnames';

import type { FeatureResponse } from 'components/CreateProductContent/models';
import type { AxiosError } from 'axios';

import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';
import Button from 'components/common/Button';
import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import styles from '../index.module.scss';

type Props = {
  companySlug?: string;
  productSlug?: string | null;
  feature: FeatureResponse;
  readonly?: boolean;
  productId?: number | undefined;
};

const FeatureItem = ({
  companySlug,
  productSlug,
  readonly,
  productId,
  feature: { name, id, is_benefit: isBenefit },
}: Props) => {
  const { axios } = useAuth();
  const queryClient = useQueryClient();

  const {
    mutateAsync: deleteFeature,
    isLoading: isLoadingDelete,
    error,
  } = useMutation<void, AxiosError, number>(
    async () => {
      try {
        await axios.delete(
          `/companies/${companySlug}/products/${productSlug}/company-features/${id}/`
        );
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => {
        queryClient.setQueryData<FeatureResponse[]>(
          ['featuresList', companySlug, productId],
          oldData => oldData?.filter(item => item.id !== id) || []
        );
      },
    }
  );

  return (
    <>
      {isLoadingDelete && <LoaderScreen />}
      <li className={styles.featureItem} key={id}>
        <IconSVG
          className={cn(styles.icon, {
            [styles.benefit]: isBenefit,
            [styles.disadvantage]: !isBenefit,
          })}
          name={isBenefit ? IconsNames.check_mark : IconsNames.horizontal_rule}
        />
        {name}
        {!readonly && (
          <Button
            type="button"
            onClick={() => deleteFeature(id)}
            iconProps={{ name: IconsNames.close }}
            isOnlyIcon
          />
        )}
      </li>
      {error && <Error message={getResponseError(error)} />}
    </>
  );
};

export default FeatureItem;
