import { useState } from 'react';
import Select from 'react-select';
import { useDebounce } from 'react-use';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import type {
  OfferResponse,
  InvitedUser,
  CampaignResponse,
} from 'types/models';
import type { AxiosError } from 'axios';
import type { MultiValue, ActionMeta, InputActionMeta } from 'react-select';
import type {
  MembersOption,
  OffersFormValues,
} from 'components/CampaignManage/models';

import Error from 'components/common/Error';

import useAuth from 'contexts/AuthContext';

import { selectStyles } from 'utils/selectStyles';

import getResponseError from 'helpers/getResponseError';

type Props = {
  campaign: CampaignResponse;
  offersData: OfferResponse[];
  field: {
    name: string;
    value: MultiValue<MembersOption>;
    onChange: (
      val: MultiValue<OffersFormValues['offers'][0]>,
      meta: ActionMeta<OffersFormValues['offers'][0]>
    ) => void;
  };
};

const SelectVendor = ({ offersData, field, campaign }: Props) => {
  const { user, axios } = useAuth();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  useDebounce(() => setDebouncedValue(searchValue), 250, [searchValue]);

  const {
    isLoading,
    data: options,
    error,
  } = useQuery<MembersOption[], AxiosError>(
    ['assign-vendor', debouncedValue, offersData],
    async () => {
      try {
        const isDemoCampaign = campaign.campaign_details.cost_per_lead === 0;

        const { data } = await axios.get<{ results: InvitedUser[] }>(
          `/companies/${
            user?.company.id
          }/available-vendors/?page_size=20&search=${debouncedValue}${
            isDemoCampaign ? '&is_demo_only=true' : ''
          }`
        );

        const selectOptions: MembersOption[] = data.results.length
          ? data.results.map(({ id, display_name: displayName }) => ({
              value: {
                vendor_display_name: displayName,
                vendor: id,
                cpl: isDemoCampaign ? 0 : 1,
                no_preference: false,
                campaign_offer_milestones:
                  campaign.campaign_delivery.milestones.map(milestone => ({
                    milestone,
                    selected_assets: undefined,
                  })),
              },
              label: displayName,
            }))
          : [];

        return selectOptions;
      } catch (err) {
        throw err;
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleInputChange = (value: string, meta: InputActionMeta) => {
    if (meta.action === 'input-change') {
      setSearchValue(value);
    }
  };

  return (
    <>
      <Select
        {...field}
        loadingMessage={() => t('common.field.loading')}
        isLoading={isLoading}
        options={options}
        isMulti
        styles={selectStyles}
        onInputChange={handleInputChange}
        inputValue={searchValue}
        placeholder={t('common.field.select')}
        noOptionsMessage={() => t('offers.no-available-vendors')}
        menuPlacement="auto"
        aria-label="select vendor"
        hideSelectedOptions={false}
        getOptionValue={option => option.value + uuidv4()}
      />
      {error && <Error message={getResponseError(error)} />}
    </>
  );
};

export default SelectVendor;
