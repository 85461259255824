import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { OfferResponse } from 'types/models';

import styles from '../index.module.scss';

type Props = {
  acceptedOffers?: OfferResponse[];
  canceledOffers?: OfferResponse[];
  totalUploadedLeadsCount: number;
  selectedMilestone?: { id: string | undefined; date: string | undefined };
};

const LeadsRatioVendor = ({
  acceptedOffers,
  totalUploadedLeadsCount,
  selectedMilestone,
  canceledOffers,
}: Props) => {
  const { t } = useTranslation();

  const requestedLeadsByMilestoneDictionary = useMemo(
    () =>
      [...(acceptedOffers || []), ...(canceledOffers || [])]
        ?.map(offer => offer.campaign_offer_milestones)
        .flat()
        .reduce<Map<number | string, number>>((acc, offer) => {
          const milestoneValue = acc.get(offer.milestone);
          acc.set(
            offer.milestone,
            offer.leads_required + (milestoneValue || 0)
          );
          const allRequestedLeads = acc.get('all');
          acc.set('all', (allRequestedLeads || 0) + offer.leads_required);

          return acc;
        }, new Map()),
    [acceptedOffers, canceledOffers]
  );

  return (
    <p className={styles.info}>
      {t('lead.leads-uploaded')}
      <span className={styles.delivered}>{totalUploadedLeadsCount}</span>/
      <span>
        {requestedLeadsByMilestoneDictionary?.get(
          selectedMilestone?.id ? Number(selectedMilestone.id) : 'all'
        )}
      </span>
    </p>
  );
};

export default LeadsRatioVendor;
