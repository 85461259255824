import { useTranslation } from 'react-i18next';
import { useMemo, useCallback } from 'react';
import { useExpanded } from 'react-table';
import cn from 'classnames';

import type { CellProps } from 'react-table';
import type { ReportResponse } from 'types/models';

import Table from 'components/common/Table';
import ModalWindow from 'components/common/ModalWindow';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from '../index.module.scss';

export enum TableAccessors {
  rowNumber = 'row-number',
  reason = 'reason',
  details = 'details',
}

type Props = {
  data: ReportResponse['execution_details'];
};

const FailedReasonsModal = ({ data }: Props) => {
  const { t } = useTranslation();

  const rowsData = useMemo(() => {
    return data?.map(item => ({
      [TableAccessors.rowNumber]: `#${item.row_number + 1}`,
      [TableAccessors.reason]: item.detail.replace('non_field_errors:', ''),
      [TableAccessors.details]: item.row_data,
    }));
  }, []);

  const columnsData = useMemo(
    () => [
      {
        Header: t('common.field.row-number'),
        accessor: TableAccessors.rowNumber,
      },
      {
        Header: t('common.field.reason'),
        accessor: TableAccessors.reason,
      },
      {
        Header: '',
        id: 'expander',
        accessor: TableAccessors.details,
        Cell: ({ row }: CellProps<any, any>) => (
          <IconSVG
            {...row.getToggleRowExpandedProps({
              className: cn(styles.arrow, { [styles.up]: row.isExpanded }),
            })}
            name={IconsNames.arrow}
          />
        ),
      },
    ],
    []
  );

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <ul className={styles.details}>
        {row.values.expander.map((value: string[]) => (
          <li key={String(value)}>{JSON.stringify(value)}, </li>
        ))}
      </ul>
    ),
    []
  );

  return (
    <ModalWindow
      primaryTitle={t('reports.reasons')}
      title=""
      className={styles.reasons}
    >
      <Table
        tableOptions={{ columns: columnsData, data: rowsData }}
        plugins={[useExpanded]}
        renderRowSubComponent={renderRowSubComponent}
        wrapperClassName={styles.modal}
      />
    </ModalWindow>
  );
};

export default FailedReasonsModal;
