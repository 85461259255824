import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useFormContext, useWatch, useController } from 'react-hook-form';

import type { AssetValues } from 'components/CreateCampaign/models';

import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import Error from 'components/common/Error';
import FileItem from 'components/common/FileItem';
import IconSVG from 'components/UI/IconSVG';

import {
  AssetAttachedType,
  VIDEO_ASSET_CONTENT_TYPES,
  VIDEO_ASSET_ALLOWED_MIME_TYPES,
} from 'constants/assets';
import { IconsNames } from 'constants/constants';

import styles from '../index.module.scss';

const SelectMethod = () => {
  const { t } = useTranslation();
  const [file] = useState(undefined);
  const {
    control,
    register,
    formState: { errors },
    setValue,
    clearErrors,
    setFocus,
  } = useFormContext<AssetValues>();
  const attachedType = useWatch({ control, name: 'asset_type' });
  const uploadedFile = useWatch({ control, name: 'attached_file' });
  const contentType = useWatch({ control, name: 'content_type' });

  const { field } = useController({ control, name: 'attached_file' });
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e.currentTarget.files?.[0] || undefined);
    e.currentTarget.files = null;
    e.currentTarget.value = '';
  };

  const isVideoAsset = VIDEO_ASSET_CONTENT_TYPES.some(
    type => type === contentType?.value
  );

  return (
    <>
      <p className={styles.file}>{t('common.field.file')}</p>
      <div className={styles.select}>
        <InputWrapper
          label="common.field.manually"
          wrapperClasses={styles.radio}
          isErrorHidden
        >
          <input
            type="radio"
            {...register('asset_type')}
            onChange={e => {
              setValue('attached_file_link', '');
              register('asset_type').onChange(e);
              clearErrors('attached_file_link');
            }}
            value={AssetAttachedType.file}
          />
        </InputWrapper>
        <InputWrapper
          label={
            isVideoAsset ? 'common.field.youtube-link' : 'common.field.link'
          }
          wrapperClasses={styles.radio}
          isErrorHidden
        >
          <input
            type="radio"
            {...register('asset_type')}
            onChange={e => {
              setValue('attached_file', undefined);
              register('asset_type').onChange(e);
              clearErrors('attached_file');
              setTimeout(() => {
                setFocus('attached_file_link');
              }, 0);
            }}
            value={AssetAttachedType.link}
          />
        </InputWrapper>
      </div>
      {attachedType === AssetAttachedType.file ? (
        <>
          {!uploadedFile && (
            <Button
              type="button"
              white
              iconProps={{ name: IconsNames.upload_file }}
              className={styles.choose}
            >
              <input
                type="file"
                value={file}
                onChange={handleFileChange}
                accept={
                  isVideoAsset
                    ? VIDEO_ASSET_ALLOWED_MIME_TYPES.join(', ')
                    : undefined
                }
              />
              {t('common.button.choose-file')}
            </Button>
          )}

          {uploadedFile && (
            <FileItem
              {...(typeof uploadedFile === 'string'
                ? {
                    link: uploadedFile,
                    name: t('common.button.view-file'),
                  }
                : { name: uploadedFile.name })}
              deleteFile={() => setValue('attached_file', undefined)}
              Icon={
                <IconSVG
                  name={IconsNames.file_stroke}
                  className={styles.icon}
                />
              }
              className={styles.uploaded}
            />
          )}

          <Error
            message={t(errors.attached_file?.message || '')}
            className={styles.error}
          />
        </>
      ) : (
        <InputWrapper
          isMediumInput
          validationError={errors.attached_file_link?.message}
        >
          <input type="text" {...register('attached_file_link')} />
        </InputWrapper>
      )}
    </>
  );
};

export default SelectMethod;
