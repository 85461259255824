import cn from 'classnames';
import { useMemo } from 'react';

import Button from 'components/common/Button';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  scrollToRef: React.MutableRefObject<HTMLDivElement | null>;
  className?: string;
};

const getRange = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
};

const SIBLING_COUNT = 1;
const SEPARATOR = '...';

const Pagination = ({
  totalCount,
  pageSize,
  currentPage,
  setPage,
  scrollToRef,
  className,
}: Props) => {
  const pagesCount = Math.ceil(totalCount / pageSize);
  const pages = new Array(pagesCount)
    .fill(null)
    .map((item, index) => index + 1);

  const paginationRange = useMemo(() => {
    const totalPageNumbers = SIBLING_COUNT + 5;

    if (totalPageNumbers >= pagesCount) {
      return getRange(1, pagesCount);
    }

    const leftSiblingIndex = Math.max(currentPage - SIBLING_COUNT, 1);
    const rightSiblingIndex = Math.min(currentPage + SIBLING_COUNT, pagesCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < pagesCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = pagesCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * SIBLING_COUNT;
      const leftRange = getRange(1, leftItemCount);

      return [...leftRange, SEPARATOR, pagesCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * SIBLING_COUNT;
      const rightRange = getRange(pagesCount - rightItemCount + 1, pagesCount);

      return [firstPageIndex, SEPARATOR, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = getRange(leftSiblingIndex, rightSiblingIndex);

      return [
        firstPageIndex,
        SEPARATOR,
        ...middleRange,
        SEPARATOR,
        lastPageIndex,
      ];
    }
  }, [currentPage, pagesCount]);

  const scrollTo = () =>
    scrollToRef.current?.scrollIntoView({
      behavior: 'smooth',
    });

  const goToNextPage = () => {
    setPage(page => page + 1);
    scrollTo();
  };

  const gotToPreviousPage = () => {
    setPage(page => page - 1);
    scrollTo();
  };

  const handlePageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const pageNumber = Number(event.currentTarget.id);
    setPage(pageNumber);
    scrollTo();
  };

  const isOnlyOnePage =
    !!paginationRange?.length && paginationRange.length === 1;

  return !!paginationRange?.length ? (
    <div className={cn(styles.wrapper, className)}>
      <Button
        type="button"
        className={cn(styles.control, styles.prev)}
        disabled={currentPage === 1 || isOnlyOnePage}
        onClick={gotToPreviousPage}
        white
        iconProps={{ name: IconsNames.arrow }}
      />

      {paginationRange.map((item, index) => {
        return typeof item === 'string' ? (
          <span key={index}>{item}</span>
        ) : (
          <Button
            key={index}
            onClick={e => {
              if (currentPage !== item) {
                handlePageClick(e);
              }
            }}
            white={currentPage !== item}
            id={item.toString()}
          >
            {item}
          </Button>
        );
      })}

      <Button
        type="button"
        onClick={goToNextPage}
        className={styles.control}
        disabled={currentPage === pages[pages.length - 1] || isOnlyOnePage}
        white
        iconProps={{ name: IconsNames.arrow }}
      />
    </div>
  ) : null;
};

export default Pagination;
