// import { ReactComponent as MailJetSVG } from 'assets/images/integrations/mail-jet.svg';
// import { ReactComponent as MailgunSVG } from 'assets/images/integrations/mailgun.svg';
import { ReactComponent as PostmarkSVG } from 'assets/images/integrations/postmark.svg';
import { ReactComponent as SendgridSVG } from 'assets/images/integrations/sendgrid.svg';
// import { ReactComponent as SendinSVG } from 'assets/images/integrations/sendin.svg';
// import { ReactComponent as SparkpostSVG } from 'assets/images/integrations/sparkpost.svg';
import { ReactComponent as MandrillSVG } from 'assets/images/integrations/mandrill.svg';
// import { ReactComponent as PostalSVG } from 'assets/images/integrations/postal.svg';

import { EmailServices } from 'components/IntegrationsContent/VendorContent/models';

const serverIcons = {
  //   [EmailServices.mailgun]: MailgunSVG,
  //   [EmailServices.mailjet]: MailJetSVG,
  [EmailServices.mandrill]: MandrillSVG,
  //   [EmailServices.postal]: PostalSVG,
  [EmailServices.postmark]: PostmarkSVG,
  [EmailServices.sendgrid]: SendgridSVG,
  //   [EmailServices.sendinblue]: SendinSVG,
  //   [EmailServices.sparkpost]: SparkpostSVG,
};

export const getServerIcon = (serverName: EmailServices) =>
  serverIcons[serverName];

const serverNames = {
  //   [EmailServices.mailgun]: 'Mailgun',
  //   [EmailServices.mailjet]: 'Mailjet',
  [EmailServices.mandrill]: 'Mandrill',
  //   [EmailServices.postal]: 'Postal',
  [EmailServices.postmark]: 'Postmark',
  [EmailServices.sendgrid]: 'SendGrid',
  //   [EmailServices.sendinblue]: 'Sendinblue',
  //   [EmailServices.sparkpost]: 'SparkPost',
};

export const getESPServerName = (serverName: EmailServices) =>
  serverNames[serverName];
