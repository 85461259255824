import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import type { SMPTServer } from 'components/IntegrationsContent/VendorContent/models';
import type { SMTPServerResponse } from 'types/models';

import InputWrapper from 'components/common/InputWrapper';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';
import Button from 'components/common/Button';
import NumberField from 'components/common/NumberField';

import useModal from 'contexts/ModalContext';

import useAddEmailServer from 'components/IntegrationsContent/VendorContent/useAddEmailServer';
import useUpdateServer from 'components/IntegrationsContent/VendorContent/useUpdateServer';
import useDeleteServer from 'components/IntegrationsContent/VendorContent/useDeleteServer';

import getResponseError from 'helpers/getResponseError';

import { validationSchemaIntegrationsServerSmtp } from 'utils/validations';

import { IconsNames, ServerVariants } from 'constants/constants';

import styles from '../FormServer/index.module.scss';

type FormValues = {
  integration_name: string;
  credentials: SMPTServer['credentials'];
};

type Props = {
  data?: SMTPServerResponse;
};

const SmtpForm = ({ data }: Props) => {
  const { closeModal } = useModal();
  const isDefaultValues = !!data;
  const [isEditDisabled, setIsEditDisabled] = useState(!!data);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowUserName, setIsShowUserName] = useState(false);

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchemaIntegrationsServerSmtp),
    defaultValues: { ...data, credentials: data?.decrypted_credentials },
  });
  const { field: portField } = useController({
    control,
    name: 'credentials.port',
  });

  const { isLoading, error, mutate: addServer } = useAddEmailServer();
  const { isLoading: isUpdateServerLoading, mutate: updateServer } =
    useUpdateServer();
  const { mutate: deleteServer, isLoading: isDeleteLoading } =
    useDeleteServer();

  const handleFormSubmit = (values: FormValues) => {
    if (data) {
      updateServer({
        credentials: values.credentials,
        id: data.id,
        integration_type: ServerVariants.smtp,
        integration_name: values.integration_name,
      });
    } else {
      addServer({
        credentials: values.credentials,
        integration_type: ServerVariants.smtp,
        integration_name: values.integration_name,
      });
    }
  };

  return (
    <>
      {isLoading && <LoaderScreen />}
      <>
        <form className={styles.form} onSubmit={handleSubmit(handleFormSubmit)}>
          <InputWrapper
            label="common.field.name"
            wrapperClasses={styles.label}
            validationError={errors.integration_name?.message}
            isMediumInput
          >
            <input
              type="text"
              placeholder={!isEditDisabled ? t('common.field.name') : ''}
              disabled={isEditDisabled}
              {...register('integration_name')}
            />
          </InputWrapper>
          <InputWrapper
            label="integrations.form.host"
            wrapperClasses={styles.label}
            isMediumInput
          >
            <input
              type="text"
              placeholder={t('integrations.form.host')}
              disabled={isEditDisabled}
              {...register('credentials.host')}
            />
          </InputWrapper>
          <InputWrapper
            label="integrations.form.port"
            validationError={errors.credentials?.port?.message}
            wrapperClasses={styles.label}
            isMediumInput
          >
            <NumberField
              placeholder={t('integrations.form.port')}
              disabled={isEditDisabled}
              field={portField}
            />
          </InputWrapper>
          <InputWrapper
            label="integrations.form.user-name"
            validationError={errors.credentials?.user_name?.message}
            wrapperClasses={styles.labelPassword}
            tip={t('integrations.form.user-name-tip')}
            isMediumInput
            setIsHiddenInputContent={setIsShowUserName}
          >
            <input
              type={isShowUserName ? 'text' : 'password'}
              placeholder={t('integrations.form.user-name')}
              disabled={isEditDisabled}
              {...register('credentials.user_name')}
            />
          </InputWrapper>
          <InputWrapper
            label="integrations.form.from-email"
            validationError={errors.credentials?.from_email?.message}
            wrapperClasses={styles.label}
            tip={t('integrations.form.email-tip')}
            isMediumInput
          >
            <input
              type="email"
              placeholder={t('integrations.form.from-email')}
              disabled={isEditDisabled}
              {...register('credentials.from_email')}
            />
          </InputWrapper>
          <InputWrapper
            label="integrations.form.user-password"
            validationError={errors.credentials?.user_password?.message}
            wrapperClasses={styles.labelPassword}
            isMediumInput
            setIsHiddenInputContent={setIsShowPassword}
          >
            <input
              type={!isShowPassword ? 'password' : 'text'}
              placeholder={t('integrations.form.user-password')}
              disabled={isEditDisabled}
              {...register('credentials.user_password')}
            />
          </InputWrapper>
          {!data?.is_in_use && (
            <>
              {isDefaultValues && isEditDisabled && (
                <div className={styles.edit}>
                  <Button
                    onClick={() => setIsEditDisabled(false)}
                    type="button"
                    className={styles.wrapperButton}
                    iconProps={{
                      name: IconsNames.edit,
                      className: styles.iconEdit,
                    }}
                  >
                    {t('common.button.edit')}
                  </Button>
                </div>
              )}

              {isDefaultValues && !isEditDisabled && (
                <div className={styles.buttonsBlock}>
                  {data && (
                    <Button
                      onClick={() => {
                        if (data?.id) deleteServer(data.id);
                      }}
                      type="button"
                      className={styles.delete}
                      transparent
                      iconProps={{
                        name: IconsNames.trash,
                      }}
                    >
                      {t('common.button.delete')}
                    </Button>
                  )}
                  <div className={styles.buttons}>
                    <Button onClick={closeModal} type="button" white>
                      {t('common.button.cancel')}
                    </Button>
                    <Button type="submit" className={styles.button}>
                      <span>{t('common.button.save-changes')}</span>
                    </Button>
                  </div>
                </div>
              )}

              {!isDefaultValues && (
                <div className={styles.buttons}>
                  <Button onClick={closeModal} type="button" white>
                    {t('common.button.cancel')}
                  </Button>
                  <Button type="submit" className={styles.button}>
                    <span>{t('common.button.add')}</span>
                  </Button>
                </div>
              )}
            </>
          )}
        </form>
      </>
      {error && <Error message={getResponseError(error)} />}
      {(isLoading || isDeleteLoading || isUpdateServerLoading) && (
        <LoaderScreen />
      )}
    </>
  );
};

export default SmtpForm;
