import styles from './index.module.scss';

type Props = {
  legend: string;
};

const Fieldset: React.FC<Props> = ({ legend, children }) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend>{legend}</legend>
      <div>{children}</div>
    </fieldset>
  );
};

export default Fieldset;
