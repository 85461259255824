import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import dayjs from 'dayjs';

import type { AnyObject, Maybe } from 'yup/lib/types';
import type { BaseSchema } from 'yup';
import type { Milestone } from 'types/models';

import {
  MILESTONE_END_DATE_DIFFERENCE,
  MIN_CAMPAIGN_DURATION_DAYS,
  MilestoneType,
  OfferStatuses,
  UserTypes,
} from 'constants/constants';
import { PaymentOptions } from 'components/PaymentsComponents/models';
import {
  EmailServices,
  typesWithApiKey,
  typesWithSecretKey,
} from 'components/IntegrationsContent/VendorContent/models';

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends BaseSchema<TType, TContext, TOut> {
    onlyLettersSpacesNumbers(): StringSchema<TType, TContext>;
    onlyLetterAndSpaces(): StringSchema<TType, TContext>;
    password(): StringSchema<TType, TContext>;
    phoneNumber(): StringSchema<TType, TContext>;
    onlyLettersSpacesNumbersComma(): StringSchema<TType, TContext>;
    onlyLettersSpacesNumbersCommaDashSlash(): StringSchema<TType, TContext>;
  }
}
export type TestContextExtended = {
  from: {
    schema: Yup.ObjectSchema<any>;
    value: any;
  }[];
};

export const POSITIVE_NUMBER_REGEXP = /[^0-9]/g;
export const LETTER_SPACES_NUMBERS_COMMA_REGEXP =
  /^[A-Za-z0-9 ,]*[A-Za-z0-9][A-Za-z0-9 ,]*$/;
const emailRegexp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const YOUTUBE_LINK_REGEXP =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
export const TWO_DIGITS_AFTER_COMMA_REGEXP = /^\d+(\.\d{0,2})?$/;

export const REQUIRED_MESSAGE = 'common.error.required';
export const GREATER_THAN_MESSAGE = 'common.error.greater-than';
const MISMATCH_PASSWORDS_MESSAGE = 'common.error.mismatch-password';
export const MAX_LENGTH_MESSAGE = 'common.error.max-length';
export const INVALID_VALUE = 'common.error.invalid-value';
export const INVALID_URL_ERROR_MESSAGE = 'common.error.invalid-url';
export const MAXIMUM_DIGITS_AFTER_DECIMAL =
  'common.error.max-digits-after-decimal';

export const DEFAULT_CHARFIELD_MAX_LENGTH = 255;

Yup.addMethod<Yup.StringSchema>(Yup.string, 'onlyLetterAndSpaces', function () {
  return this.matches(/^[a-zA-Z\s]*$/, 'common.error.only-letters-spaces');
});

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'onlyLettersSpacesNumbers',
  function () {
    return this.matches(
      /^$|^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
      'common.error.only-letters-spaces-numbers'
    );
  }
);

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'onlyLettersSpacesNumbersComma',
  function () {
    return this.matches(
      LETTER_SPACES_NUMBERS_COMMA_REGEXP,
      'common.error.only-letters-spaces-numbers-comma'
    );
  }
);

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'onlyLettersSpacesNumbersCommaDashSlash',
  function () {
    return this.matches(
      /^[A-Za-z0-9 ,-/]*[A-Za-z0-9][A-Za-z0-9 ,-/]*$/,
      'common.error.only-letters-spaces-numbers-comma-dash-slash'
    );
  }
);

Yup.addMethod<Yup.StringSchema>(Yup.string, 'password', function () {
  return this.min(6, { key: 'common.error.min-length', values: { length: 6 } })
    .matches(/.*[0-9].*/, 'common.error.number-required')
    .matches(
      /^(?=.*[~`!@#$%^&*()-+={}\[\]|\\:;"'<>,.?/_])/,
      'common.error.special-character'
    );
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'phoneNumber', function () {
  return this.test({
    name: 'tel',
    exclusive: true,
    message: INVALID_VALUE,
    test: value => {
      if (value) {
        return isValidPhoneNumber(value);
      }

      return true;
    },
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'onlyDigits', function () {
  return this.matches(POSITIVE_NUMBER_REGEXP, 'test');
});

export const validateMilestonesLeadsCount = (
  milestones: { total_leads_amount: number }[] | undefined,
  totalLeads: number
) => {
  const milestonesLeadsCount = milestones?.reduce((acc, item) => {
    acc += Number(item.total_leads_amount) || 0;
    return acc;
  }, 0);

  return milestonesLeadsCount === Number(totalLeads || 0);
};

const locationValidation = {
  country_code: Yup.object().required(REQUIRED_MESSAGE).nullable(),
  city: Yup.mixed().required(REQUIRED_MESSAGE),
  state: Yup.mixed().when('country_code', {
    is: (country: { value: string } | undefined) => {
      const { value } = country || {};
      const isStateProvinceNeeded = value === 'US' || value === 'CA';

      return value && isStateProvinceNeeded;
    },
    then: Yup.string().required(REQUIRED_MESSAGE).typeError(REQUIRED_MESSAGE),
    otherwise: Yup.mixed(),
  }),
};

export const validationSchemaLogIn = Yup.lazy(() =>
  Yup.object().shape({
    email: Yup.string().email(INVALID_VALUE).required(REQUIRED_MESSAGE),
    password: Yup.string().password().required(REQUIRED_MESSAGE),
  })
);

export const validationSchemaSignUp = Yup.lazy(() =>
  Yup.object().shape({
    first_name: Yup.string()
      .max(30, { key: MAX_LENGTH_MESSAGE, values: { length: 30 } })
      .onlyLetterAndSpaces()
      .required(REQUIRED_MESSAGE),
    last_name: Yup.string()
      .max(30, { key: MAX_LENGTH_MESSAGE, values: { length: 30 } })
      .onlyLetterAndSpaces()
      .required(REQUIRED_MESSAGE),
    job_title: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    company: Yup.object().shape({
      name: Yup.string()
        .max(30, { key: MAX_LENGTH_MESSAGE, values: { length: 30 } })
        .required(REQUIRED_MESSAGE),
      ...locationValidation,
      address: Yup.string()
        .typeError(REQUIRED_MESSAGE)
        .required(REQUIRED_MESSAGE),
      department: Yup.object().shape({
        value: Yup.string().required(REQUIRED_MESSAGE),
      }),
    }),
    phone_number: Yup.string()
      .phoneNumber()
      .typeError(REQUIRED_MESSAGE)
      .required(REQUIRED_MESSAGE),
    email: Yup.string().email(INVALID_VALUE).required(REQUIRED_MESSAGE),
    password: Yup.string().password().required(REQUIRED_MESSAGE),
    re_password: Yup.string()
      .required(REQUIRED_MESSAGE)
      .oneOf([Yup.ref('password'), null], MISMATCH_PASSWORDS_MESSAGE),
    acceptTerms: Yup.boolean().oneOf([true], REQUIRED_MESSAGE),
  })
);

export const validationSchemaResetPassword = Yup.lazy(() =>
  Yup.object().shape({
    email: Yup.string().email(INVALID_VALUE).required(REQUIRED_MESSAGE),
  })
);

export const validationSchemaNewPassword = Yup.lazy(() =>
  Yup.object().shape({
    new_password: Yup.string().password().required(REQUIRED_MESSAGE),
    re_new_password: Yup.string()
      .required(REQUIRED_MESSAGE)
      .oneOf([Yup.ref('new_password'), null], MISMATCH_PASSWORDS_MESSAGE),
  })
);

export const validationSchemaProfileGeneral = Yup.lazy(() =>
  Yup.object().shape({
    date_of_birth: Yup.string().nullable(),
    country_code: Yup.object().shape({ value: Yup.string().nullable() }),
    timezone: Yup.object().shape({ value: Yup.string().nullable() }),
  })
);

export const validationSchemaProfileContacts = Yup.lazy(() =>
  Yup.object().shape({
    phone_number: Yup.string().phoneNumber().required(REQUIRED_MESSAGE),
  })
);

export const validationSchemaProfileCompany = Yup.lazy(() =>
  Yup.object().shape({
    job_title: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    department: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    company: Yup.object().shape({
      ...locationValidation,
      address: Yup.string()
        .typeError(REQUIRED_MESSAGE)
        .required(REQUIRED_MESSAGE),
    }),
  })
);

export const validationSchemaEditProfile = Yup.lazy(() =>
  Yup.object().shape({
    first_name: Yup.string()
      .max(30, { key: MAX_LENGTH_MESSAGE, values: { length: 30 } })
      .onlyLetterAndSpaces()
      .required(REQUIRED_MESSAGE),
    last_name: Yup.string()
      .max(30, { key: MAX_LENGTH_MESSAGE, values: { length: 30 } })
      .onlyLetterAndSpaces()
      .required(REQUIRED_MESSAGE),
    job_title: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    country_code: Yup.object().shape({ value: Yup.string().nullable() }),
    phone_number: Yup.string().phoneNumber().required(REQUIRED_MESSAGE),
    date_of_birth: Yup.string().nullable(),
    company: Yup.object().shape({
      ...locationValidation,
    }),
  })
);

export const validationSchemaChangeRules = Yup.lazy(() =>
  Yup.object().shape({
    privacy_policy_url: Yup.string()
      .url(INVALID_URL_ERROR_MESSAGE)
      .max(DEFAULT_CHARFIELD_MAX_LENGTH, {
        key: MAX_LENGTH_MESSAGE,
        values: { length: DEFAULT_CHARFIELD_MAX_LENGTH },
      })
      .required(REQUIRED_MESSAGE),
  })
);

const serverIntegrationNameValidation = Yup.string()
  .trim()
  .max(128, {
    key: MAX_LENGTH_MESSAGE,
    values: { length: 128 },
  })
  .required(REQUIRED_MESSAGE);

export const validationSchemaIntegrationsServerSmtp = Yup.lazy(() =>
  Yup.object().shape({
    integration_name: serverIntegrationNameValidation,
    credentials: Yup.object().shape({
      host: Yup.string().required(REQUIRED_MESSAGE),
      port: Yup.number().required(REQUIRED_MESSAGE),
      user_name: Yup.string().required(REQUIRED_MESSAGE),
      user_password: Yup.string().required(REQUIRED_MESSAGE),
      from_email: Yup.string().email(INVALID_VALUE).required(REQUIRED_MESSAGE),
    }),
  })
);

export const validationSchemaIntegrationsServerEsp = Yup.lazy(() =>
  Yup.object().shape({
    integration_name: serverIntegrationNameValidation,
    credentials: Yup.object().shape({
      from_email: Yup.string().email(INVALID_VALUE).required(REQUIRED_MESSAGE),
      api_key: Yup.string().test('api_key', function (value) {
        const isAPIKey = typesWithApiKey.some(
          service => service === this.parent.provider_name.value
        );

        if (isAPIKey && !value) {
          return this.createError({
            path: this.path,
            message: REQUIRED_MESSAGE,
          });
        }

        return true;
      }),
      secret_key: Yup.string().test('secret_key', function (value) {
        const isSecretKey = typesWithSecretKey.some(
          service => service === this.parent.provider_name.value
        );
        if (isSecretKey && !value) {
          return this.createError({
            path: this.path,
            message: REQUIRED_MESSAGE,
          });
        }

        return true;
      }),

      server_token: Yup.string().test('server_token', function (value) {
        if (
          this.parent.provider_name.value === EmailServices.postmark &&
          !value
        ) {
          return this.createError({
            path: this.path,
            message: REQUIRED_MESSAGE,
          });
        }

        return true;
      }),
    }),
  })
);

export const validationSchemaChangePassword = Yup.lazy(() =>
  Yup.object().shape({
    current_password: Yup.string().password().required(REQUIRED_MESSAGE),
    new_password: Yup.string().password().required(REQUIRED_MESSAGE),
    re_new_password: Yup.string()
      .required(REQUIRED_MESSAGE)
      .oneOf([Yup.ref('new_password'), null], MISMATCH_PASSWORDS_MESSAGE),
  })
);

const validationSchemaMilestoneDeliveryDate = Yup.string()
  .required(REQUIRED_MESSAGE)
  .nullable()
  .test({
    name: 'campaign_delivery',
    test: function (value) {
      const {
        from: [, delivery],
      } = this as Yup.TestContext & TestContextExtended;

      const campaignStartDate = delivery.value.start_date;
      const campaignEndDate = delivery.value.end_date;
      const milestonesMaxDeliveryDate = dayjs(campaignEndDate).subtract(
        MILESTONE_END_DATE_DIFFERENCE,
        'day'
      );

      const isMonthlyDeadlineType =
        delivery.value.milestone_deadline_type.value === MilestoneType.monthly;

      if (isMonthlyDeadlineType) {
        if (campaignEndDate) {
          const isValueAfterMaxDeadline = dayjs(value).isAfter(
            milestonesMaxDeliveryDate,
            'month'
          );

          if (isValueAfterMaxDeadline) {
            return this.createError({
              path: this.path,
              message: {
                key: 'common.error.earlier-date-error',
                values: {
                  date: dayjs(campaignEndDate).format('YYYY-MM-DD'),
                  days: MILESTONE_END_DATE_DIFFERENCE,
                },
              },
            });
          }
        }

        const startDateMonthDaysCount = dayjs(campaignStartDate)
          .endOf('month')
          .daysInMonth();
        const startDate = dayjs(campaignStartDate).get('date');
        const isSelectedLastDateInMonth = startDateMonthDaysCount === startDate;
        const milestoneSelectedMonth = dayjs(value).get('month');
        if (
          isSelectedLastDateInMonth &&
          milestoneSelectedMonth === dayjs(campaignStartDate).get('month')
        ) {
          return this.createError({
            path: this.path,
            message: 'common.error.not-valid-milestone-month',
          });
        }

        const isBeforeStartDateMonth = dayjs(value).isBefore(
          dayjs(campaignStartDate),
          'month'
        );
        if (isBeforeStartDateMonth) {
          return this.createError({
            path: this.path,
            message: 'common.error.before-start-date',
          });
        }

        const isBeforeSevenDaysMonth = dayjs(value).isBefore(
          dayjs(campaignStartDate).add(7, 'day'),
          'month'
        );
        if (isBeforeSevenDaysMonth) {
          return this.createError({
            path: this.path,
            message: {
              key: 'common.error.should-be-later',
              values: { count: 7 },
            },
          });
        }
      }

      if (!isMonthlyDeadlineType) {
        const isBeforeOrEqualStartDate = dayjs(value).isBefore(
          dayjs(campaignStartDate).add(8, 'day'),
          'day'
        );
        if (isBeforeOrEqualStartDate) {
          return this.createError({
            path: this.path,
            message: {
              key: 'common.error.should-be-later',
              values: { count: 7 },
            },
          });
        }

        const isAfterOrEqualEndDate =
          campaignEndDate && dayjs(value).isAfter(milestonesMaxDeliveryDate);

        if (isAfterOrEqualEndDate) {
          return this.createError({
            path: this.path,
            message: {
              key: 'common.error.earlier-date-error',
              values: {
                date: dayjs(campaignEndDate).format('YYYY-MM-DD'),
                days: MILESTONE_END_DATE_DIFFERENCE,
              },
            },
          });
        }
      }

      const format = isMonthlyDeadlineType ? 'YYYY-MM' : 'YYYY-MM-DD';
      const milestonesDates = delivery.value.milestones.map(
        (milestone: Milestone) => dayjs(milestone.delivery_date).format(format)
      );
      const firstMatchIndex =
        value && milestonesDates?.indexOf(dayjs(value).format(format));
      const lastMatchIndex =
        value && milestonesDates?.lastIndexOf(dayjs(value).format(format));

      const isDeliveryDateRepeated = firstMatchIndex !== lastMatchIndex;
      if (isDeliveryDateRepeated) {
        return this.createError({
          path: this.path,
          message: 'common.error.repeating-milestone',
        });
      }

      const previousMilestoneDate =
        firstMatchIndex !== 0
          ? delivery.value.milestones[firstMatchIndex - 1].delivery_date
          : null;
      const isDateBeforePrevious =
        previousMilestoneDate &&
        dayjs(value).isBefore(dayjs(previousMilestoneDate));

      if (isDateBeforePrevious) {
        return this.createError({
          path: this.path,
          message: 'common.error.date-before-previous-milestone',
        });
      }

      return true;
    },
  });

export const validationSchemaCampaignDelivery = Yup.object().shape({
  start_date: Yup.string()
    .typeError('not a string')
    .nullable()
    .test({
      name: 'start_date',
      test: function (value) {
        return dayjs(value).isBefore(dayjs(new Date()).subtract(1, 'day'))
          ? this.createError({
              message: 'common.error.start-date-before',
              path: 'campaign_delivery.start_date',
            })
          : true;
      },
    })
    .required(REQUIRED_MESSAGE),
  end_date: Yup.string()
    .required(REQUIRED_MESSAGE)
    .nullable()
    .test({
      name: 'end_date',
      test: function (value) {
        const minEndDate = dayjs(this.parent.start_date).add(
          MIN_CAMPAIGN_DURATION_DAYS,
          'day'
        );
        const isEarlierMinEndDate = dayjs(value).isBefore(minEndDate);

        return isEarlierMinEndDate
          ? this.createError({
              message: {
                key: 'common.error.end-date-before',
                values: { date: minEndDate.format('YYYY-MM-DD') },
              },
              path: 'campaign_delivery.end_date',
            })
          : true;
      },
    }),
  milestone_deadline_type: Yup.object().shape({
    value: Yup.string().required(REQUIRED_MESSAGE),
  }),
  milestones: Yup.array().of(
    Yup.object().shape({
      delivery_date: validationSchemaMilestoneDeliveryDate,
      total_leads_amount: Yup.number().positive({
        key: GREATER_THAN_MESSAGE,
        values: { number: 0 },
      }),
    })
  ),
});

export const validationSchemaInviteEmail = Yup.lazy(() =>
  Yup.object().shape({
    group_name: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    email: Yup.string().email(INVALID_VALUE).required(REQUIRED_MESSAGE),
    invited_name: Yup.string().when('group_name', {
      is: (group: { label: string; value: string }) => {
        return group.value === UserTypes.vendor;
      },
      then: Yup.string()
        .max(100, { key: MAX_LENGTH_MESSAGE, values: { length: 100 } })
        .required(REQUIRED_MESSAGE),
      otherwise: Yup.string().nullable(),
    }),
  })
);

export const validationSchemaInviteBasic = Yup.lazy(() =>
  Yup.object().shape({
    user: Yup.object().shape({
      value: Yup.string(),
    }),
    invited_name: Yup.string().when('group_name', {
      is: (group: { label: string; value: string }) => {
        return group.value === UserTypes.vendor;
      },
      then: Yup.string()
        .max(100, { key: MAX_LENGTH_MESSAGE, values: { length: 100 } })
        .required(REQUIRED_MESSAGE),
      otherwise: Yup.string().nullable(),
    }),
    first_name: Yup.string()
      .max(30, { key: MAX_LENGTH_MESSAGE, values: { length: 30 } })
      .onlyLetterAndSpaces(),
    last_name: Yup.string()
      .max(30, { key: MAX_LENGTH_MESSAGE, values: { length: 30 } })
      .onlyLetterAndSpaces(),
    email: Yup.string().email(INVALID_VALUE).required(REQUIRED_MESSAGE),
    phone_number: Yup.string().phoneNumber(),
    acceptTerms: Yup.boolean().oneOf([true], REQUIRED_MESSAGE),
  })
);

export const validationSchemaEditGroup = Yup.lazy(() =>
  Yup.object().shape({
    name: Yup.string()
      .max(30, { key: MAX_LENGTH_MESSAGE, values: { length: 30 } })
      .required(REQUIRED_MESSAGE),
    users: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required(REQUIRED_MESSAGE),
          value: Yup.string().required(REQUIRED_MESSAGE),
        })
      )
      .min(1, REQUIRED_MESSAGE)
      .required(REQUIRED_MESSAGE),
  })
);

export const validationMilestones = Yup.lazy(() =>
  Yup.object().shape({
    campaign_delivery: Yup.object().shape({
      milestones: Yup.array().of(
        Yup.object().shape({
          total_leads_amount: Yup.number()
            .required(REQUIRED_MESSAGE)
            .test({
              name: 'milestones',
              test: function () {
                const {
                  from: [, delivery, campaignData],
                } = this as Yup.TestContext & TestContextExtended;
                const totalLeads =
                  campaignData.value.campaign_details.total_leads;
                const isValid = validateMilestonesLeadsCount(
                  delivery.value.milestones,
                  totalLeads
                );

                return !isValid
                  ? this.createError({
                      path: this.path,
                      message: {
                        key: 'common.error.milestone-leads-count',
                        values: {
                          count: totalLeads,
                        },
                      },
                    })
                  : true;
              },
            }),
          delivery_date: validationSchemaMilestoneDeliveryDate,
        })
      ),
    }),
  })
);

export const validationSchemaChangeCampaignGroup = Yup.lazy(() =>
  Yup.object().shape({
    campaigns: Yup.array().of(
      Yup.object().shape({
        group: Yup.object().shape({
          value: Yup.string().required(REQUIRED_MESSAGE),
        }),
      })
    ),
  })
);

export const validationSchemaCreateOffer = (isDemoCampaign: boolean) =>
  Yup.lazy(() =>
    Yup.object().shape({
      offers: Yup.array().of(
        Yup.object().shape({
          value: Yup.object().shape({
            id: Yup.number(),
            cpl: isDemoCampaign
              ? Yup.number()
              : Yup.number().min(1, {
                  key: GREATER_THAN_MESSAGE,
                  values: { number: 0 },
                }),
            comment: Yup.string().test('comment', function (value) {
              const isCommentRequired =
                this.parent.id && this.parent.status !== OfferStatuses.accepted;
              const isInvalid = isCommentRequired && !value;

              if (
                isCommentRequired &&
                value &&
                value.length > DEFAULT_CHARFIELD_MAX_LENGTH
              ) {
                return this.createError({
                  path: this.path,
                  message: {
                    key: MAX_LENGTH_MESSAGE,
                    values: { length: DEFAULT_CHARFIELD_MAX_LENGTH },
                  },
                });
              }

              return (
                !isInvalid ||
                this.createError({
                  path: this.path,
                  message: REQUIRED_MESSAGE,
                })
              );
            }),
            campaign_offer_milestones: Yup.array()
              .of(
                Yup.object().shape({
                  selected_assets: Yup.array()
                    .nullable()
                    .test('selected_assets', function (value) {
                      const {
                        from: [milestone],
                      } = this as Yup.TestContext & TestContextExtended;

                      const isValid =
                        (Number(milestone.value.leads_required || 0) > 0 &&
                          Boolean(value?.length)) ||
                        !milestone.value.leads_required;

                      return (
                        isValid ||
                        this.createError({
                          path: this.path,
                          message: REQUIRED_MESSAGE,
                        })
                      );
                    }),
                  leads_required: Yup.number().test(
                    'leads_required',
                    function (value) {
                      const {
                        from: [milestone, offer],
                      } = this as Yup.TestContext & TestContextExtended;

                      if (offer.value.no_preference) return true;
                      if (!milestone.value.selected_assets?.length) return true;

                      const assetsRequiredLeadsCount = (
                        milestone.value.campaign_offer_assets as {
                          leads_required: number;
                        }[]
                      ).reduce<number>((acc, asset) => {
                        acc += asset.leads_required;
                        return acc;
                      }, 0);

                      return (
                        Number(value) === assetsRequiredLeadsCount ||
                        this.createError({
                          path: this.path,
                          message: {
                            key: 'offers.error-sum-assets-leads',
                            values: { value },
                          },
                        })
                      );
                    }
                  ),
                  campaign_offer_assets: Yup.array().of(
                    Yup.object().shape({
                      leads_required: Yup.number().test(
                        'leads_required',
                        function (value) {
                          const {
                            from: [, milestone, offer],
                          } = this as Yup.TestContext & TestContextExtended;
                          if (
                            !offer.value.no_preference &&
                            value === 0 &&
                            milestone.value.leads_required > 0
                          ) {
                            return this.createError({
                              path: this.path,
                              message: {
                                key: GREATER_THAN_MESSAGE,
                                values: { number: 0 },
                              },
                            });
                          }

                          return true;
                        }
                      ),
                    })
                  ),
                })
              )
              .test(
                'campaign_offer_milestones',
                'common.error.leads-required-not-specified',
                function (value) {
                  const isEveryLeadsRequiredMissed = value?.every(
                    milestone => milestone.leads_required === 0
                  );
                  return !isEveryLeadsRequiredMissed;
                }
              ),
          }),
        })
      ),
    })
  );

export const validationSchemaReBidVendorOffer = Yup.lazy(() =>
  Yup.object().shape({
    offers: Yup.array().of(
      Yup.object().shape({
        value: Yup.object().shape({
          cpl_vendor: Yup.number().min(1, {
            key: GREATER_THAN_MESSAGE,
            values: { number: 0 },
          }),
          comment_vendor: Yup.string()
            .max(DEFAULT_CHARFIELD_MAX_LENGTH, {
              key: MAX_LENGTH_MESSAGE,
              values: { length: DEFAULT_CHARFIELD_MAX_LENGTH },
            })
            .required(REQUIRED_MESSAGE),
          campaign_offer_milestones: Yup.array()
            .of(
              Yup.object().shape({
                leads_required_vendor: Yup.number()
                  .required(REQUIRED_MESSAGE)
                  .typeError(REQUIRED_MESSAGE)
                  .test('leads_required_vendor', function (value) {
                    const {
                      from: [milestone, offer],
                    } = this as Yup.TestContext & TestContextExtended;

                    if (offer.value.no_preference) return true;

                    const assetsRequiredLeadsCount = (
                      milestone.value.campaign_offer_assets as {
                        leads_required_vendor: number;
                      }[]
                    ).reduce<number>((acc, asset) => {
                      acc += asset.leads_required_vendor;
                      return acc;
                    }, 0);

                    return (
                      Number(value) === assetsRequiredLeadsCount ||
                      this.createError({
                        path: this.path,
                        message: {
                          key: 'offers.error-sum-assets-leads',
                          values: { value },
                        },
                      })
                    );
                  }),
              })
            )
            .test(
              'campaign_offer_milestones',
              'common.error.leads-required-not-specified',
              function (value) {
                const isEveryLeadsRequiredMissed = value?.every(
                  milestone => milestone.leads_required_vendor === 0
                );
                return !isEveryLeadsRequiredMissed;
              }
            ),
        }),
      })
    ),
  })
);

export const validationSchemaDeclineOffer = Yup.lazy(() =>
  Yup.object().shape({
    comment: Yup.string()
      .required(REQUIRED_MESSAGE)
      .max(DEFAULT_CHARFIELD_MAX_LENGTH, {
        key: MAX_LENGTH_MESSAGE,
        values: { length: DEFAULT_CHARFIELD_MAX_LENGTH },
      }),
  })
);

export const validationSchemaGetADemo = Yup.lazy(() =>
  Yup.object().shape({
    first_name: Yup.string()
      .required(REQUIRED_MESSAGE)
      .max(20, { key: MAX_LENGTH_MESSAGE, values: { length: 20 } })
      .onlyLettersSpacesNumbers(),
    last_name: Yup.string()
      .required(REQUIRED_MESSAGE)
      .max(20, { key: MAX_LENGTH_MESSAGE, values: { length: 20 } })
      .onlyLettersSpacesNumbers(),
    company_name: Yup.string()
      .max(60, { key: MAX_LENGTH_MESSAGE, values: { length: 60 } })
      .required(REQUIRED_MESSAGE)
      .onlyLettersSpacesNumbers(),
    job_title: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    email: Yup.string().email(INVALID_VALUE).required(REQUIRED_MESSAGE),
    phone_number: Yup.string()
      .phoneNumber()
      .typeError(REQUIRED_MESSAGE)
      .required(REQUIRED_MESSAGE),
  })
);

export const validationSchemaEditAccountingPerson = Yup.lazy(() =>
  Yup.object().shape({
    accounting_person_first_name: Yup.string()
      .onlyLetterAndSpaces()
      .nullable()
      .required(REQUIRED_MESSAGE),
    accounting_person_last_name: Yup.string()
      .onlyLetterAndSpaces()
      .nullable()
      .required(REQUIRED_MESSAGE),
    accounting_person_email: Yup.string()
      .email(INVALID_VALUE)
      .nullable()
      .required(REQUIRED_MESSAGE),
  })
);

export const validationSchemaDepositAmount = Yup.lazy(() =>
  Yup.object().shape({
    amount: Yup.number()
      .required(REQUIRED_MESSAGE)
      .min(0.1, { key: GREATER_THAN_MESSAGE, values: { number: 0 } }),
    method: Yup.object().required(REQUIRED_MESSAGE),
    term: Yup.mixed().when('option', {
      is: (option: PaymentOptions) => {
        return option === PaymentOptions.later;
      },
      then: Yup.object().required(REQUIRED_MESSAGE).typeError(REQUIRED_MESSAGE),
      otherwise: Yup.mixed().nullable(),
    }),
  })
);

export const validationSchemaWithdrawAmount = Yup.lazy(() =>
  Yup.object().shape({
    amount: Yup.number()
      .required(REQUIRED_MESSAGE)
      .min(1, { key: GREATER_THAN_MESSAGE, values: { number: 0 } }),
  })
);

export const validationSchemaCreateDevice = (isTOTOP: boolean) =>
  Yup.lazy(() =>
    Yup.object().shape({
      ...(isTOTOP
        ? {}
        : {
            phone_number: Yup.string().phoneNumber().required(REQUIRED_MESSAGE),
          }),
      password: Yup.string().password().required(REQUIRED_MESSAGE),
    })
  );

export const checkIsEmailValid = (value: string) => {
  return emailRegexp.test(value);
};

export const validationSchemaDownloadAsset = Yup.lazy(() =>
  Yup.object().shape({
    questions: Yup.array().of(
      Yup.object().shape({
        answers: Yup.array()
          .of(
            Yup.object()
              .shape({
                checked: Yup.boolean(),
                is_other: Yup.boolean(),
                custom_answer: Yup.string(),
              })
              .test('answer', function (answer) {
                if (
                  answer.checked &&
                  answer.is_other &&
                  !answer.custom_answer?.length
                ) {
                  return false;
                }
                return true;
              })
          )
          .test('answers', function (answers) {
            return answers?.some(answer => answer.checked) || false;
          }),
      })
    ),
  })
);
