import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ReportedLeadsTable from 'components/common/ReportedLeadsTable';
import Button from 'components/common/Button';
import Loader from 'components/common/LoaderScreen/Loader';

import CampaignInformation from 'components/CampaignDetails/CampaignInformation';

import { useGetCampaign } from 'hooks/api/useGetCampaign';
import useGetReportTemplateDownload from 'hooks/api/useGetReportTemplateDownload';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const CampaignDetails = () => {
  const { t } = useTranslation();
  const [isLeadsDataEmpty, setIsLeadsDataEmpty] = useState(true);
  const { campaignId } = useParams();
  const { data: campaign } = useGetCampaign(campaignId);
  const { isLoading: isLoadingDownloadReport, refetch } =
    useGetReportTemplateDownload(campaignId);

  const handleDownloadReport = () => refetch();

  return (
    <>
      <CampaignInformation />

      {!isLoadingDownloadReport && campaign?.id && (
        <Button
          disabled={isLeadsDataEmpty}
          className={styles.download}
          iconProps={{
            name: IconsNames.download,
            isRightPosition: true,
          }}
          type="button"
          onClick={handleDownloadReport}
        >
          {t('common.button.download-report')}
        </Button>
      )}
      {isLoadingDownloadReport && (
        <Loader size={40} className={styles.loader} />
      )}

      {campaign && (
        <ReportedLeadsTable
          campaign={campaign}
          setIsDataEmpty={setIsLeadsDataEmpty}
        />
      )}
    </>
  );
};

export default CampaignDetails;
