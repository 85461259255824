import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useMountedState } from 'react-use';

import type { ShareContactData } from 'types/models';

import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';

import useModal from 'contexts/ModalContext';

import styles from './index.module.scss';

const PROD_MARKETO_BAMBOOHR_FORM_ID = 3277;

type Props = {
  shareContactData?: ShareContactData;
};

const MarketoForm = ({ shareContactData }: Props) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const isMounted = useMountedState();

  useEffect(() => {
    // @ts-ignore
    window.MktoForms2?.loadForm(
      'https://grow.bamboohr.com',
      '195-LOZ-515',
      PROD_MARKETO_BAMBOOHR_FORM_ID,
      function (form: any) {
        form.vals({
          FirstName: shareContactData?.first_name,
          LastName: shareContactData?.last_name,
          Email: shareContactData?.email,
          Phone: shareContactData?.phone,
          Title: shareContactData?.job_title,
          UTM_Campaign_Capture__c: 'TRUL-DG-CP+demoreq-2023EG',
          UTM_Content_Capture__c: 'demo',
          UTM_Term_Capture__c: 'blank',
          UTM_Source_Capture__c: 'content-syndication',
          UTM_Medium_Capture__c: 'paid-media',
          cSDemoRequestCheckbox: true,
        });
        form.onSuccess(function (values: any) {
          closeModal();
          // @ts-ignore
          window.ChiliPiper?.submit('bamboohr', 'trueleads-beeleads-router', {
            map: true,
            formId: `mktoForm_${PROD_MARKETO_BAMBOOHR_FORM_ID}`,
            lead: values,
          });
          return false;
        });
      }
    );
    // @ts-ignore
    window.MktoForms2?.onFormRender(function () {
      if (isMounted()) setIsLoaded(true);
    });
  }, []);

  return (
    <>
      <ModalWindow
        className={cn(styles.marketo, { [styles.loaded]: isLoaded })}
        primaryTitle={t('common.button.get-demo')}
      >
        <form id={`mktoForm_${PROD_MARKETO_BAMBOOHR_FORM_ID}`}></form>
      </ModalWindow>
      {!isLoaded && <LoaderScreen />}
    </>
  );
};

export default MarketoForm;
