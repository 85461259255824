import { useState } from 'react';
import DayPicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import dayjs from 'dayjs';

import MonthSelect from 'components/common/DatePicker/MonthSelect';
import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  value: string;
  onChange: (newDate: string) => void;
  isMonthly?: boolean;
  label?: string;
  wrapperClassName?: string;
  placeholder?: string;
  fromMonth?: Date;
  toMonth?: Date;
  inputClassName?: string;
  validationError?: string;
  maxErrorLength?: number;
  isSmallSelector?: boolean;
};

const currentYear = new Date().getFullYear();
const fromMonthDefault = new Date(currentYear, 0);
const toMonthDefault = new Date(currentYear + 10, 11);

const DatePicker = ({
  value,
  onChange,
  isMonthly,
  placeholder,
  fromMonth = fromMonthDefault,
  toMonth = toMonthDefault,
  inputClassName,
  validationError,
  maxErrorLength,
  isSmallSelector,
  wrapperClassName,
}: Props) => {
  const { t } = useTranslation();
  const handleYearMonthChange = (newMonth: Date): void => {
    onChange(dayjs(newMonth).format('YYYY-MM-DD'));
  };

  const [customValue, setCustomValue] = useState(
    value ? dayjs(value).toDate() : null
  );

  const onDayChange = (day: Date) => {
    if (!day) {
      return onChange('');
    }

    setCustomValue(day);

    return onChange(dayjs(day).format('YYYY-MM-DD'));
  };

  return isMonthly ? (
    <MonthSelect
      date={value ? new Date(value) : new Date()}
      onChange={handleYearMonthChange}
      fromMonth={fromMonth}
      toMonth={toMonth}
      isMonthly
      validationError={validationError}
      maxErrorLength={maxErrorLength}
      isSmallSelector={isSmallSelector}
    />
  ) : (
    <div className={styles.wrapper}>
      <DayPicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className={styles.header}>
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className={styles.navigation}
            >
              <IconSVG name={IconsNames.arrow} />
            </button>
            <MonthSelect
              date={date}
              fromMonth={fromMonth}
              toMonth={toMonth}
              changeYear={changeYear}
              changeMonth={changeMonth}
            />
            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className={styles.navigation}
            >
              <IconSVG name={IconsNames.arrow} />
            </button>
          </div>
        )}
        selected={customValue || null}
        onChange={onDayChange}
        dateFormat="yyyy-MM-dd"
        calendarClassName={styles.cal}
        wrapperClassName={wrapperClassName}
        className={cn(styles.input, inputClassName)}
        dayClassName={() => styles.day}
        placeholderText={placeholder || t('common.field.select-day')}
        minDate={fromMonth}
        maxDate={toMonth}
      />
      <IconSVG name={IconsNames.calendar} className={styles.icon} />
    </div>
  );
};

export default DatePicker;
