import { useController } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { Control } from 'react-hook-form';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { LeadFileResponse, ReportTemplateResponse } from 'types/models';
import type { AxiosError } from 'axios';

import UploadFile from 'components/common/UploadFile';
import FileItem from 'components/common/FileItem';
import LoaderScreen from 'components/common/LoaderScreen';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

type Props = {
  control: Control<CreateCampaignValues, unknown>;
  sign: string;
  title: string;
  className: string;
  fieldName: 'campaign_files.lead_file' | 'campaign_files.report_template';
  setupFormData: (file: File) => FormData;
  url: string;
  isGetLoading: boolean;
};

const UploadSingleFile = <
  TFile extends ReportTemplateResponse | LeadFileResponse
>({
  control,
  sign,
  title,
  className,
  fieldName,
  setupFormData,
  url,
  isGetLoading,
}: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name: fieldName, control });
  const { t } = useTranslation();
  const { axios } = useAuth();
  const { openModal, closeModal } = useModal();

  const {
    mutate: uploadFile,
    isLoading,
    error: uploadError,
  } = useMutation<TFile, AxiosError, FormData>(
    async (file: FormData) => {
      try {
        const { data } = await axios.post<TFile>(url, file);

        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: file => onChange(file),
    }
  );

  const { mutate: deleteFile, error: deleteError } = useMutation<
    void,
    AxiosError
  >(
    async () => {
      try {
        await axios.delete<TFile>(url);
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => onChange(null),
    }
  );

  const insertSingle = (file: File | null) => {
    if (file) {
      uploadFile(setupFormData(file));
    }
  };

  const isReportTemplate = value && 'report_template_name' in value;
  const isLeadFile = value && 'lead_file_name' in value;

  const fileName =
    (isLeadFile && value.lead_file_name) ||
    (isReportTemplate && value.report_template_name) ||
    '';

  const handleDeleteFile = () => {
    openModal({
      Content: (
        <DeleteConfirmationModal
          instanceName={t(title).toLowerCase()}
          itemName={fileName || t('common.field.file')}
          onDelete={() => {
            closeModal();
            deleteFile();
          }}
        />
      ),
    });
  };

  const fileLink =
    (isLeadFile && value.lead_file) ||
    (isReportTemplate && value.report_template) ||
    undefined;

  return (
    <UploadFile
      accept={[
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ]}
      title={title}
      sign={sign}
      wrapperClasses={className}
      isLoading={isGetLoading}
      onChangeOptions={{
        maxFiles: 1,
        insertSingle,
      }}
      error={uploadError?.message || deleteError?.message}
      disabled={!!fileLink}
    >
      {value && (
        <FileItem
          name={fileName}
          deleteFile={handleDeleteFile}
          link={fileLink}
        />
      )}
      {isLoading && <LoaderScreen />}
    </UploadFile>
  );
};

export default UploadSingleFile;
