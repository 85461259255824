import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';

import useAuth, { User } from 'contexts/AuthContext';

import styles from './index.module.scss';

const useUpdateProfileData = () => {
  const { axios, setState } = useAuth();
  const { t } = useTranslation();

  const { mutate, error, isLoading } = useMutation<User, AxiosError, any>(
    async userData => {
      try {
        const { data } = await axios.patch<User>(
          '/accounts/profile/',
          userData
        );
        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: data => {
        setState({ user: data });

        toast.success(t('profile.data-updated'), {
          autoClose: 3000,
          hideProgressBar: true,
          className: styles.successToast,
        });
      },
    }
  );

  return { mutate, error, isLoading };
};

export default useUpdateProfileData;
