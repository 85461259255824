import Select from 'react-select';
import { useFormContext, useController } from 'react-hook-form';

import type { ScheduleDemoValues } from 'components/ProductPage/models';

import { selectStyles } from 'utils/selectStyles';

import { timezones } from 'constants/selectOptions';

const SelectTimezone = () => {
  const { control } = useFormContext<ScheduleDemoValues>();
  const { field } = useController({
    name: 'client_timezone',
    control,
    rules: { required: true },
    defaultValue: timezones[0],
  });

  return <Select {...field} options={timezones} styles={selectStyles} />;
};

export default SelectTimezone;
