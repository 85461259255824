import { useForm, useController } from 'react-hook-form';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';

import useInvite from 'components/InvitesContent/InviteForm/useInvite';

import { selectStyles } from 'utils/selectStyles';
import { validationSchemaInviteEmail } from 'utils/validations';

import { UserTypes } from 'constants/constants';

import styles from './index.module.scss';

type FormValues = {
  email: string;
  group_name: { value: UserTypes; label: string };
  invited_name: string;
};

type Props = {
  userTypes: { value: UserTypes; label: string }[];
};

const EmailForm = ({ userTypes }: Props) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    register,
    unregister,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchemaInviteEmail),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const registerEmailMethods = register('email');

  const {
    field: { value, onChange },
  } = useController<FormValues, 'group_name'>({
    name: 'group_name',
    control,
  });

  const { mutate, error, isLoading, reset } = useInvite();

  const groupNameValue = watch('group_name');
  const isVendorSelected = groupNameValue?.value === UserTypes.vendor;

  useEffect(() => {
    onChange(userTypes[0]);
  }, [userTypes]);

  useEffect(() => {
    if (!isVendorSelected) {
      unregister('invited_name');
    }
  }, [isVendorSelected, unregister]);

  const submit = (data: FormValues) => {
    const requestBody = {
      ...data,
      group_name: data.group_name.value,
    };

    mutate(requestBody);
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(submit)}>
        <InputWrapper
          label="common.field.role"
          wrapperClasses={styles.label}
          validationError={errors.group_name?.value?.message}
          submitError={
            error?.response?.data.group_name &&
            error?.response?.data.group_name[0]
          }
        >
          <Select
            options={userTypes}
            styles={selectStyles}
            value={value}
            onChange={onChange}
            placeholder={t('common.field.select')}
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.email"
          wrapperClasses={styles.label}
          validationError={errors.email?.message}
          submitError={
            error?.response?.data.email && error?.response?.data.email[0]
          }
        >
          <input
            type="email"
            placeholder={t('common.field.email')}
            {...registerEmailMethods}
            onChange={e => {
              registerEmailMethods.onChange(e);
              reset();
            }}
          />
        </InputWrapper>
        {isVendorSelected && (
          <InputWrapper
            label="common.field.vendor-name"
            wrapperClasses={styles.label}
            validationError={errors.invited_name?.message}
            submitError={
              error?.response?.data.invited_name &&
              error?.response?.data.invited_name[0]
            }
          >
            <input
              type="text"
              placeholder={t('common.field.name')}
              {...register('invited_name')}
            />
          </InputWrapper>
        )}
        <Button withArrow className={styles.button} type="submit">
          {t('common.button.send')}
        </Button>
        {isLoading && <LoaderScreen />}
      </form>
    </div>
  );
};

export default EmailForm;
