import { useTranslation } from 'react-i18next';

import type { TwoFADevice } from 'components/TwoFA/models';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  onDeviceChange: React.Dispatch<React.SetStateAction<TwoFADevice | undefined>>;
};

const TwoFABackLink = ({ onDeviceChange }: Props) => {
  const { t } = useTranslation();

  const clickHandler = () => onDeviceChange(undefined);

  return (
    <span onClick={clickHandler} className={styles.wrapper}>
      <IconSVG name={IconsNames.arrow} />
      {t('common.button.back')}
    </span>
  );
};

export default TwoFABackLink;
