import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  PayoutStatus,
  PaymentStatus,
} from 'components/PaymentsComponents/models';

import styles from './index.module.scss';

type Props = {
  status: PaymentStatus | PayoutStatus;
  isPayout?: boolean;
};

const TransactionStatus = ({ status, isPayout = false }: Props) => {
  const { t } = useTranslation();

  return (
    <span
      className={cn(styles.status, {
        [styles.pending]:
          status === PaymentStatus.pending || status === PayoutStatus.pending,
        [styles.failed]:
          status === PaymentStatus.failed ||
          status === PaymentStatus.canceled ||
          status === PayoutStatus.canceled ||
          status === PayoutStatus.failed,
        [styles.charged]:
          status === PaymentStatus.charged || status === PayoutStatus.paid,
      })}
    >
      {t(`payments.${isPayout ? 'payout-' : ''}status.${status}`)}
    </span>
  );
};

export default TransactionStatus;
