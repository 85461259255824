import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useBeforeUnload } from 'react-use';
import { useForm, FormProvider } from 'react-hook-form';

import type { CampaignResponse } from 'types/models';
import type { FormValues } from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/models';

import ModalWindow from 'components/common/ModalWindow';

import SelectAsset from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/SelectAsset';
import UploadFile from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/UploadFile';
import SelectServer from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/SelectServer';

import useModal from 'contexts/ModalContext';

import useDeleteContactsReport from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/useDeleteContactsReport';

import styles from './index.module.scss';

type Props = {
  campaign: CampaignResponse;
};

const steps = [SelectAsset, UploadFile, SelectServer];

const UploadContactsModal = ({ campaign }: Props) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const [step, setStep] = useState(0);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  useBeforeUnload(true, t('common.modal.you-have-unsaved-changes'));
  const { mutate: deleteReport } = useDeleteContactsReport();

  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      mapping_fields: {
        email: null,
        linkedin_contact_profile_link: null,
        linkedin_company_profile_link: null,
      },
    },
  });

  const handleCloseModal = () => {
    setIsConfirmVisible(true);
  };

  const cancelCreation = () => {
    const fileId = formMethods.getValues().id;
    if (fileId) {
      deleteReport({
        campaignId: campaign.id,
        fileId,
      });
    }
    closeModal();
  };

  const CurrentStepComponent = steps[step];

  return (
    <>
      <ModalWindow
        className={styles.wrapper}
        title={
          <div className={styles.title}>
            <h2>{t('campaign-deliveries.import-contacts-deliver-emails')}</h2>
            <span
              className={styles.progress}
              style={{ width: `${((step + 1) / steps.length) * 100}%` }}
            />
          </div>
        }
        closeButtonClassName={styles.close}
        onClose={handleCloseModal}
        isLockBodyScroll
        closeOnClickAway={false}
      >
        <FormProvider {...formMethods}>
          <CurrentStepComponent
            campaignId={campaign.id}
            setStep={setStep}
            stepsCount={steps.length}
            currentStep={step}
            campaign={campaign}
          />
        </FormProvider>
      </ModalWindow>
      {isConfirmVisible && (
        <ModalWindow
          className={styles.confirm}
          primaryTitle={t('campaign-deliveries.leave-import-wizard')}
          onClose={() => setIsConfirmVisible(false)}
          closeButtonClassName={styles.close}
          description={t('common.modal.data-not-saved')}
          positiveButtonProps={{
            children: t('common.button.cancel'),
            white: true,
            onClick: () => setIsConfirmVisible(false),
          }}
          negativeButtonProps={{
            children: t('common.button.leave'),
            onClick: cancelCreation,
          }}
        />
      )}
    </>
  );
};

export default UploadContactsModal;
