import { usePopperTooltip } from 'react-popper-tooltip';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import Error from 'components/common/Error';

import FormServer from 'components/IntegrationsContent/VendorContent/FormServer';
import UploadContactsModal from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal';

import useModal from 'contexts/ModalContext';
import { useGetCampaign } from 'hooks/api/useGetCampaign';
import { useGetEmailServers } from 'hooks/api/useGetEmailServers';

import { IconsNames } from 'constants/constants';

import getResponseError from 'helpers/getResponseError';

import { ReactComponent as NoServersSVG } from 'assets/images/deliveries/no-servers.svg';

import styles from './index.module.scss';

const ImportContactsButton = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { id } = useParams();
  const { data: campaign } = useGetCampaign(id);
  const { data: isSMTPConnected, isFetched, error } = useGetEmailServers(true);

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      interactive: true,
      offset: [0, 0],
      placement: 'bottom-start',
    });

  const handleOpenModal = () => {
    if (campaign)
      openModal({ Content: <UploadContactsModal campaign={campaign} /> });
  };

  const openAddServerModal = () => {
    openModal({
      Content: <FormServer />,
    });
  };

  const isDisabled = !isFetched || !isSMTPConnected?.length;

  return (
    <>
      <div
        ref={isDisabled ? setTriggerRef : undefined}
        className={styles.wrapper}
      >
        <Button
          type="button"
          disabled={isDisabled}
          iconProps={{ name: IconsNames.arrow, isRightPosition: true }}
          onClick={handleOpenModal}
          aria-label="import contacts"
        >
          {t('campaign-deliveries.import-contacts-deliver-emails')}
        </Button>

        {error && <Error message={getResponseError(error)} />}

        {visible && isDisabled && (
          <div
            ref={setTooltipRef}
            {...getTooltipProps({
              className: cn('tooltip-container', styles.info),
            })}
            aria-label="no servers connected"
          >
            <div className={styles.tooltip}>
              <NoServersSVG className={styles.image} />
              <p className={styles.title}>
                {t('campaign-deliveries.add-mail-service')}
              </p>
              <p className={styles.description}>
                {t('campaign-deliveries.we-cannot-import')}
              </p>
              <Button
                iconProps={{ name: IconsNames.plus }}
                onClick={openAddServerModal}
                aria-label="add server"
              >
                {t('common.button.add-server')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ImportContactsButton;
