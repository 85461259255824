import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import IconSVG from 'components/UI/IconSVG';
import SimpleTooltip from 'components/UI/SimpleTooltip';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = { message: string; className?: string; maxErrorLength?: number };

const Error = ({ message, className, maxErrorLength }: Props) => {
  const { t } = useTranslation();
  const tooltipProps = usePopperTooltip();

  const isTooltipNeeded = maxErrorLength && t(message).length > maxErrorLength;
  const errorMessage = isTooltipNeeded
    ? `${t(message).slice(0, maxErrorLength)}...`
    : t(message || '');

  return (
    <>
      <p
        className={cn(styles.error, className)}
        ref={isTooltipNeeded ? tooltipProps.setTriggerRef : undefined}
      >
        {message && <IconSVG name={IconsNames.error} role="alert" />}
        {errorMessage}
      </p>

      {isTooltipNeeded && tooltipProps.visible && (
        <SimpleTooltip options={tooltipProps}>{t(message)}</SimpleTooltip>
      )}
    </>
  );
};

export default Error;
