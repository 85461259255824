import type { MultiValue } from 'react-select';
import type { Milestone, OfferResponse, CampaignResponse } from 'types/models';
import type {
  AssetsLeadsByMilestone,
  OffersFormValues,
  OfferFormValues,
  LeadsRequiredPerMilestoneDictionary,
  MembersOption,
} from 'components/CampaignManage/models';

import { UserTypes } from 'constants/constants';

export const getLeadsByAssetsPerMilestoneDictionary = (
  campaignMilestones: Milestone[]
): AssetsLeadsByMilestone => {
  // dictionary for access by id
  const calculationMap = campaignMilestones.reduce<AssetsLeadsByMilestone>(
    (acc, val) => {
      if (val.id) {
        const assetsData = val.leads_per_asset.reduce<{
          [key: string]: Milestone['leads_per_asset'][0];
        }>((assetsAcc, asset) => {
          assetsAcc[asset.id.toString()] = asset;
          return assetsAcc;
        }, {});

        acc.set(val.id, assetsData);
      }

      return acc;
    },
    new Map()
  );

  return calculationMap;
};

export const getAssetLeadsCount = (
  offers: OffersFormValues['offers'],
  assetId: number
) =>
  offers
    ?.map(offerOption => offerOption.value)
    .map(offer => offer.campaign_offer_milestones)
    .flat()
    .map(milestones => milestones.campaign_offer_assets)
    .flat()
    .reduce((acc, asset) => {
      if (asset?.asset === assetId) {
        acc += asset?.leads_required || 0;
      }

      return acc;
    }, 0);

export const getRequestedLeadsPerMilestoneDictionary = (
  campaignMilestones: Milestone[]
): LeadsRequiredPerMilestoneDictionary => {
  const calculationMap =
    campaignMilestones.reduce<LeadsRequiredPerMilestoneDictionary>(
      (acc, val) => {
        acc.set(val.id, val.total_leads_amount);

        return acc;
      },
      new Map()
    );

  return calculationMap;
};

export const getAssignedRequestedLeadsCountByMilestone = (
  offers: OffersFormValues['offers'],
  milestoneId: number
) =>
  Array.isArray(offers)
    ? offers
        ?.map(offer => offer.value.campaign_offer_milestones)
        .flat()
        .reduce((acc, milestone) => {
          if (milestone?.milestone?.id === milestoneId) {
            acc += milestone?.leads_required || 0;
          }

          return acc;
        }, 0)
    : 0;

export const getAssetLeadsCountDifference = (
  assetId: number,
  assignedOffersOptions: MultiValue<MembersOption>,
  newOffersOptions: MultiValue<MembersOption>,
  defaultAssetLeadsAmount?: number
) => {
  const assignedAssetLeadsCountInputted = getAssetLeadsCount(
    assignedOffersOptions,
    assetId
  );
  const newAssetLeadsCountInputted = getAssetLeadsCount(
    newOffersOptions,
    assetId
  );

  const difference =
    Number(defaultAssetLeadsAmount) -
    assignedAssetLeadsCountInputted -
    newAssetLeadsCountInputted;

  return difference;
};

export const getNoPreferenceAssetLeadsCountDifference = (
  assignedOffersValues: MultiValue<MembersOption>,
  newOffersValues: MultiValue<MembersOption>,
  campaignTotalLeads: number
) => {
  const getAllAssetsValues = (offersValues: MultiValue<MembersOption>) => {
    const count = offersValues
      ?.map(({ value }) => {
        const assetLeadsCountInMilestone = value.campaign_offer_milestones
          .map(milestone => {
            if (value.no_preference) {
              return milestone.leads_required || 0;
            }

            const leadsCountByAssets = milestone.campaign_offer_assets?.reduce(
              (acc, asset) => {
                acc += asset?.leads_required || 0;
                return acc;
              },
              0
            );

            return leadsCountByAssets || 0;
          })
          .flat()
          .reduce((acc, assetLeadsCount) => {
            acc += assetLeadsCount;
            return acc;
          }, 0);

        return assetLeadsCountInMilestone;
      })
      .reduce((acc, offerLeadsCount) => {
        acc += offerLeadsCount;
        return acc;
      }, 0);

    return count;
  };

  const assignedLeadsCountByAssets = getAllAssetsValues(assignedOffersValues);
  const newLeadsCountByAssets = getAllAssetsValues(newOffersValues);

  return (
    campaignTotalLeads - assignedLeadsCountByAssets - newLeadsCountByAssets
  );
};

export const getMilestoneLeadsCountDifference = (
  milestoneId: number,
  assignedOffersOptions: MultiValue<MembersOption>,
  newOffersOptions: MultiValue<MembersOption>,
  requiredLeadsCountPerMilestone?: LeadsRequiredPerMilestoneDictionary
) => {
  const milestoneDefaultRequiredLeadsCount =
    requiredLeadsCountPerMilestone?.get(milestoneId);

  const assignedOffersRequestedLeadsPerMilestone =
    getAssignedRequestedLeadsCountByMilestone(
      assignedOffersOptions,
      milestoneId
    );
  const newOffersRequestedLeadsPerMilestone =
    getAssignedRequestedLeadsCountByMilestone(newOffersOptions, milestoneId);
  const difference =
    Number(milestoneDefaultRequiredLeadsCount) -
    Number(assignedOffersRequestedLeadsPerMilestone) -
    Number(newOffersRequestedLeadsPerMilestone);

  return difference;
};

export const convertOfferResponseToFormValues = ({
  offer,
  campaign,
  updatedBy,
  isShowEmptyMilestones,
}: {
  offer: OfferResponse;
  campaign: CampaignResponse;
  updatedBy?: OfferResponse['last_responded_by'];
  isShowEmptyMilestones?: boolean;
}) => {
  const isLastUpdatedByVM =
    offer.last_responded_by === UserTypes.vendorManager ||
    updatedBy === UserTypes.vendorManager;

  const campaignOfferMilestones = campaign.campaign_delivery.milestones.reduce<
    OfferFormValues['campaign_offer_milestones']
  >((acc, milestone) => {
    const offerMilestone = offer.campaign_offer_milestones.find(
      item => item.milestone === milestone.id
    );

    if (offerMilestone) {
      acc.push({
        ...offerMilestone,
        leads_required: isLastUpdatedByVM
          ? offerMilestone.leads_required
          : offerMilestone.leads_required_vendor,
        leads_required_vendor: isLastUpdatedByVM
          ? offerMilestone.leads_required
          : offerMilestone.leads_required_vendor,
        selected_assets: offerMilestone.campaign_offer_assets.map(asset => ({
          value: asset.asset,
          label: asset.asset_title,
        })),
        milestone,
        campaign_offer_assets: offerMilestone.campaign_offer_assets.map(
          asset => ({
            ...asset,
            leads_required: isLastUpdatedByVM
              ? asset.leads_required
              : asset.leads_required_vendor,
            leads_required_vendor: isLastUpdatedByVM
              ? asset.leads_required
              : asset.leads_required_vendor,
          })
        ),
      });
    }

    if (!offerMilestone && isShowEmptyMilestones) {
      acc.push({
        milestone,
        selected_assets: undefined,
      });
    }

    return acc;
  }, []);

  return {
    value: {
      ...offer,
      cpl: isLastUpdatedByVM ? offer.cpl : offer.cpl_vendor,
      cpl_vendor: isLastUpdatedByVM ? offer.cpl : offer.cpl_vendor,
      campaign_offer_milestones: campaignOfferMilestones,
    },
    label: offer.vendor_display_name,
  };
};
