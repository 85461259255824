import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import ToggleSwitch from 'components/common/ToggleSwitch';
import InputWrapper from 'components/common/InputWrapper';
import NumberField from 'components/common/NumberField';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';

import UserGroupsField from 'components/CreateCampaign/Step1/CampaignDetails/UserGroupsField';
import DeliveryEmailsField from 'components/CreateCampaign/Step1/CampaignDetails/DeliveryEmailsField';
import CPLField from 'components/CreateCampaign/Step1/CampaignDetails/CPLFeild';
import BlockHeader from 'components/CreateCampaign/Step1/BlockHeader';
import BlockWrapper from 'components/CreateCampaign/Step1/BlockWrapper';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  saveDraftErrors?: AxiosError | null;
};

const CampaignDetails = ({ saveDraftErrors }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<CreateCampaignValues>();

  const isDemoCampaign =
    useWatch({ name: 'campaign_details.cost_per_lead' }) === 0;

  return (
    <>
      <BlockHeader
        heading={t('campaign.campaign-details')}
        description={t('campaign.set-up-basic-info')}
        iconClassName={styles.icon}
        iconName={IconsNames.list}
      />
      <BlockWrapper>
        <div className={styles.container}>
          <div className={styles.column}>
            <InputWrapper
              isMediumInput
              label="common.field.campaign-name"
              validationError={errors?.campaign_details?.name?.message}
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_details?.name
                )
                  ? saveDraftErrors?.response?.data?.campaign_details?.name[0]
                  : undefined
              }
            >
              <input
                type="text"
                {...register('campaign_details.name', { required: true })}
                placeholder={t('common.field.campaign-name')}
              />
            </InputWrapper>

            <InputWrapper
              label="common.field.owner-user-group"
              isMediumInput
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_details
                    ?.owner_user_group
                )
                  ? saveDraftErrors?.response?.data?.campaign_details
                      ?.owner_user_group[0]
                  : undefined
              }
            >
              <UserGroupsField />
            </InputWrapper>

            <InputWrapper
              label="common.field.delivery-emails"
              isMediumInput
              validationError={
                errors?.campaign_details?.delivery_emails
                  ? // @ts-ignore
                    errors.campaign_details.delivery_emails?.message
                  : undefined
              }
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_details
                    ?.delivery_emails
                )
                  ? saveDraftErrors?.response?.data?.campaign_details
                      ?.delivery_emails[0]
                  : undefined
              }
            >
              <DeliveryEmailsField />
            </InputWrapper>

            <InputWrapper
              label="common.field.qc"
              isMediumInput
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_details
                    ?.is_qc_required
                )
                  ? saveDraftErrors?.response?.data?.campaign_details
                      ?.is_qc_required[0]
                  : undefined
              }
              wrapperClasses={styles.toggle}
            >
              <Controller
                name="campaign_details.is_qc_required"
                control={control}
                render={({ field }) => {
                  return (
                    <ToggleSwitch
                      id="QC"
                      register={field}
                      checked={field.value}
                      disabled={isDemoCampaign}
                    />
                  );
                }}
              />
            </InputWrapper>
          </div>

          <div className={styles.column}>
            <InputWrapper
              label="common.field.total-leads"
              isMediumInput
              wrapperClasses={styles.item}
              validationError={errors?.campaign_details?.total_leads?.message}
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_details?.total_leads
                )
                  ? saveDraftErrors?.response?.data?.campaign_details
                      ?.total_leads[0]
                  : undefined
              }
            >
              <Controller
                name="campaign_details.total_leads"
                control={control}
                render={({ field }) => (
                  <NumberField
                    field={field}
                    placeholder={t('common.field.total-leads')}
                  />
                )}
              />
            </InputWrapper>

            <div className={styles.row}>
              <InputWrapper
                isMediumInput
                label="common.field.cpc"
                wrapperClasses={styles.item}
                validationError={
                  errors?.campaign_details?.contacts_per_company?.message
                }
                submitError={
                  Array.isArray(
                    saveDraftErrors?.response?.data?.campaign_details
                      ?.contacts_per_company
                  )
                    ? saveDraftErrors?.response?.data?.campaign_details
                        ?.contacts_per_company[0]
                    : undefined
                }
              >
                <Controller
                  name="campaign_details.contacts_per_company"
                  control={control}
                  render={({ field }) => (
                    <NumberField field={field} placeholder="$" />
                  )}
                />
              </InputWrapper>
              <CPLField saveDraftErrors={saveDraftErrors} />
            </div>
          </div>
          <div className={styles.notice}>
            <InputWrapper
              isMediumInput
              label="common.field.special-instructions"
              wrapperClasses={styles.notice__label}
              validationError={errors?.campaign_details?.notice?.message}
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_details?.notice
                )
                  ? saveDraftErrors?.response?.data?.campaign_details?.notice[0]
                  : undefined
              }
            >
              <TextareaAutoHeight
                methods={register('campaign_details.notice')}
                control={control}
                placeholder={t('common.field.special-instructions')}
              />
            </InputWrapper>
          </div>
        </div>
      </BlockWrapper>
    </>
  );
};

export default CampaignDetails;
