import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import type { CampaignResponse, CampaignAssetFileResponse } from 'types/models';
import type { AxiosError } from 'axios';
import {
  CreateCampaignValues,
  CreateCampaignStepProps,
  CampaignFiles,
  SALFile,
  TALFile,
  CampaignScriptValues,
} from 'components/CreateCampaign/models';
import type { UseFormSetError, Path } from 'react-hook-form';

import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import ModalWindow from 'components/common/ModalWindow';
import NoEnoughCreditsModal from 'components/PaymentsComponents/NoEnoughCreditsModal';

import CampaignDetails from 'components/CreateCampaign/Step1/CampaignDetails';
import LeadDetails from 'components/CreateCampaign/Step1/LeadDetails';
import Specification from 'components/CreateCampaign/Step1/Specification';
import Asset from 'components/CreateCampaign/Step1/Asset';
import Delivery from 'components/CreateCampaign/Step1/Delivery';
import ScriptBuilder from 'components/CreateCampaign/Step1/ScriptBuilder';

import useConvertCampaignData from 'hooks/useConvertCampaignData';
import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';
import useSaveCampaignAsset from 'components/CreateCampaign/useSaveCampaignAsset';
import useSaveSAL from 'components/CreateCampaign/useSaveSAL';
import useSaveTAL from 'components/CreateCampaign/useSaveTAL';
import useSaveCampaignScript from 'components/CreateCampaign/useSaveCampaignScript';

import { getNextStep } from 'helpers/createCampaign';
import getResponseError from 'helpers/getResponseError';

import { validateMilestonesLeadsCount } from 'utils/validations';

import { StepNavigation } from 'constants/constants';

import styles from './index.module.scss';

const handleFileError = <
  T extends CampaignAssetFileResponse | SALFile | TALFile
>({
  error,
  setError,
  fileObj,
  path,
  index,
}: {
  error: AxiosError;
  setError: UseFormSetError<CreateCampaignValues>;
  fileObj: T;
  path: 'assets' | 'suppression_accounts' | 'target_accounts';
  index: number;
}) => {
  const errorData = error.response?.data;
  const entries = errorData
    ? (Object.entries(errorData) as [Path<T>, string[]][])
    : undefined;
  if (entries) {
    entries.forEach(([key, value]) => {
      if (fileObj.hasOwnProperty(key)) {
        // @ts-ignore
        setError(`campaign_files.${path}.${index}.${key}`, {
          message: value[0],
        });
      } else {
        setError(`campaign_files.${path}.${index}`, { message: value[0] });
      }
    });
  }
};

const Step1 = ({ goToStep, currentStep }: CreateCampaignStepProps) => {
  const { t } = useTranslation();
  const { axios } = useAuth();
  const {
    convertRequestBody,
    convertResponse,
    convertSALRequestBody,
    convertTALRequestBody,
  } = useConvertCampaignData();
  const queryClient = useQueryClient();
  const { getValues, setValue, reset, handleSubmit, setError } =
    useFormContext<CreateCampaignValues>();
  const { openModal } = useModal();

  const campaignId = getValues().id;

  const { mutateAsync: saveAsset } = useSaveCampaignAsset(campaignId, setValue);
  const { mutateAsync: saveSAL } = useSaveSAL({
    campaignId,
    setFormValue: setValue,
  });
  const { mutateAsync: saveTAL } = useSaveTAL({
    campaignId,
    setFormValue: setValue,
  });
  const { mutateAsync: saveScript } = useSaveCampaignScript({ campaignId });

  const { mutate, isLoading, error } = useMutation<
    { campaign: CampaignResponse },
    AxiosError,
    {
      campaign: Partial<CampaignResponse>;
      files?: CampaignFiles;
      scriptBuilder?: CampaignScriptValues;
    }
  >(
    async ({ campaign, files, scriptBuilder }) => {
      try {
        const saveFilesRequests: Promise<unknown>[] = [];
        if (files) {
          if (files.assets && files.assets.length > 0) {
            const { assets } = files;
            for (let i = assets.length - 1; i >= 0; i--) {
              const assetItem = assets[i];

              await saveAsset(
                {
                  asset: { distribution_value: assetItem.distribution_value },
                  id: assetItem.id,
                  index: i,
                },
                {
                  onError: err => {
                    if (assets && assetItem) {
                      handleFileError({
                        error: err,
                        fileObj: assetItem,
                        path: 'assets',
                        index: i,
                        setError,
                      });
                    }
                  },
                }
              );
            }
          }

          files.suppression_accounts?.forEach((sal, index) => {
            saveFilesRequests.push(
              saveSAL(
                { file: sal, index },
                {
                  onError: err => {
                    handleFileError({
                      error: err,
                      fileObj: sal,
                      path: 'suppression_accounts',
                      index,
                      setError,
                    });
                  },
                }
              )
            );
          });
          files.target_accounts?.forEach((tal, index) => {
            saveFilesRequests.push(
              saveTAL(
                { file: tal, index },
                {
                  onError: err => {
                    handleFileError({
                      error: err,
                      fileObj: tal,
                      path: 'target_accounts',
                      index,
                      setError,
                    });
                  },
                }
              )
            );
          });
        }

        if (scriptBuilder) {
          saveFilesRequests.push(
            saveScript(scriptBuilder, {
              onError: err => {
                setError('script_builder', {
                  message: getResponseError(err),
                });
              },
            })
          );
        }

        await Promise.all(saveFilesRequests);
        const { data } = await axios.put<CampaignResponse>(
          `/campaigns/${campaign.id}/?strict=true`,
          campaign
        );

        return { campaign: data };
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async data => {
        const updatedFiles = getValues().campaign_files;
        const converted = convertResponse({
          ...data.campaign,
          campaign_files: updatedFiles,
        });
        reset(converted);
        goToStep(StepNavigation.next, converted);
        queryClient.setQueriesData(
          ['campaign', data.campaign.id.toString(), 'SAL'],
          updatedFiles?.suppression_accounts?.map(item =>
            convertSALRequestBody(item)
          )
        );
        queryClient.setQueriesData(
          ['campaign', data.campaign.id.toString(), 'TAL'],
          updatedFiles?.target_accounts?.map(item =>
            convertTALRequestBody(item)
          )
        );
        queryClient.removeQueries([
          'campaign-create-disable-requests',
          data.campaign.id.toString(),
        ]);
      },
      onError: err => {
        if (err.response?.status === 402) {
          openModal({
            Content: <NoEnoughCreditsModal error={err} />,
          });

          return;
        }
        openModal({
          Content: (
            <ModalWindow
              errorMessage={getResponseError(err)}
              title={t('common.error.something-went-wrong')}
            />
          ),
        });
      },
    }
  );

  const onSubmit = (data: CreateCampaignValues) => {
    const {
      data: { name },
    } = getNextStep(currentStep, data);

    const totalLeads = data.campaign_details.total_leads;

    const isValid = validateMilestonesLeadsCount(
      data.campaign_delivery.milestones,
      totalLeads
    );

    if (!isValid) {
      return setError('campaign_delivery.milestones', {
        message: t('common.error.milestone-leads-count', {
          count: totalLeads,
        }),
      });
    }

    mutate({
      campaign: convertRequestBody(data, name),
      files: data.campaign_files,
      scriptBuilder: data.script_builder,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CampaignDetails saveDraftErrors={error} />

        <LeadDetails />

        <Specification saveDraftErrors={error} />

        <Asset />

        <ScriptBuilder />

        <Delivery saveDraftErrors={error} />

        <Button withArrow className={styles.next} type="submit" isBig>
          {t('campaign.report-mapping')}
        </Button>
      </form>
      {isLoading && <LoaderScreen />}
    </>
  );
};

export default Step1;
