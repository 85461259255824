import { DataProcessingIDs } from 'components/DocumentsContent/constants';

import styles from 'components/DocumentsContent/index.module.scss';

const SideMenu = () => {
  return (
    <div className={styles.sidemenu_container}>
      <div className={styles.sidemenu_content}>
        <h4 className={styles.heading}>Table of contents</h4>
        <ul className={styles.list}>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.definitions}`}
              className={styles.anchor_link}
            >
              1. Definitions
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.data_processing}`}
              className={styles.anchor_link}
            >
              2. Data Processing
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.instructions}`}
              className={styles.anchor_link}
            >
              3. Instructions
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.confidentiality}`}
              className={styles.anchor_link}
            >
              4. Confidentiality
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.security}`}
              className={styles.anchor_link}
            >
              5. Security
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.sub_processing}`}
              className={styles.anchor_link}
            >
              6. Sub-processing
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.assistance_with_data_subject_requests}`}
              className={styles.anchor_link}
            >
              7. Assistance with Data Subject Requests
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.security_incident_notification}`}
              className={styles.anchor_link}
            >
              8. Security Incident Notification
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.audits_assessment_and_consultations}`}
              className={styles.anchor_link}
            >
              9. Audits, Assessments, and Consultations
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.transfers_of_personal_data}`}
              className={styles.anchor_link}
            >
              10. Transfers of Personal Data
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.termination_of_the_dpa}`}
              className={styles.anchor_link}
            >
              11. Termination of the DPA
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.return_or_deletion_of_customer_data}`}
              className={styles.anchor_link}
            >
              12. Return or Deletion of Customer Data
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.duties_to_inform}`}
              className={styles.anchor_link}
            >
              13. Duties to Inform
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.entire_agreement_conflict}`}
              className={styles.anchor_link}
            >
              14. Entire Agreement; Conflict
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${DataProcessingIDs.exhibit_I}`}
              className={styles.anchor_link}
            >
              Exhibit I. Controller-to-Processor Clauses
            </a>
          </li>
          <li className={styles.list_item}>
            <ul className={styles.ml24}>
              <li className={styles.list_item}>
                <a
                  href={`#${DataProcessingIDs.exhibit_I_annex_I}`}
                  className={styles.anchor_link}
                >
                  Annex I
                </a>
              </li>
              <li className={styles.list_item}>
                <a
                  href={`#${DataProcessingIDs.exhibit_I_annex_II}`}
                  className={styles.anchor_link}
                >
                  Annex II
                </a>
              </li>
              <li className={styles.list_item}>
                <a
                  href={`#${DataProcessingIDs.exhibit_I_annex_III}`}
                  className={styles.anchor_link}
                >
                  Annex III
                </a>
              </li>
              <li className={styles.list_item}>
                <a
                  href={`#${DataProcessingIDs.exhibit_I_annex_IV}`}
                  className={styles.anchor_link}
                >
                  Annex IV
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
