import cn from 'classnames';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const PreviewButton: React.FC<Props> = ({ children, className }) => (
  <div className={cn(styles.preview, className)}>{children}</div>
);

export default PreviewButton;
