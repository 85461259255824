import { useQuery } from 'react-query';

import type { AxiosError } from 'axios';
import type { EmailServerResponse } from 'types/models';

import useAuth from 'contexts/AuthContext';

export const useGetEmailServers = (enabled: boolean) => {
  const { axios, user } = useAuth();

  const query = useQuery<EmailServerResponse[], AxiosError>(
    ['servers-list', user?.company.id.toString()],
    async () => {
      try {
        const { data } = await axios.get<EmailServerResponse[]>(
          '/mailing-integrations/'
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    { enabled: !!user?.company.id && enabled }
  );

  return query;
};
