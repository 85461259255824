import { ReactEditor } from 'slate-react';

export enum SlateElementTypes {
  paragraph = 'paragraph',
  variable = 'variable',
}

export type CustomElement = {
  type: SlateElementTypes;
  value?: string;
  children: any[];
};

declare module 'slate' {
  interface CustomTypes {
    Editor: ReactEditor;
    Element: CustomElement;
  }
}
