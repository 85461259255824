import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { usePopperTooltip } from 'react-popper-tooltip';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

import type { AxiosError } from 'axios';

import IconSVG from 'components/UI/IconSVG';
import LoaderScreen from 'components/common/LoaderScreen';
import ModalWindow from 'components/common/ModalWindow';

import SuperAdminSelectCompany from 'components/common/PageContainer/SuperAdminSelectCompany';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import useEscapeEvent from 'hooks/useEscapeEvent';

import getResponseError from 'helpers/getResponseError';

import { IconsNames, UserTypes } from 'constants/constants';
import { PrivatePaths, CommonPaths } from 'constants/routes';

import styles from './index.module.scss';

type Props = {
  onBeforeAction?: (func: () => void) => void;
  readOnly?: boolean;
};

const SettingsMenu = ({ onBeforeAction, readOnly }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menuRef = useRef(null);
  const queryClient = useQueryClient();
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      interactive: true,
      offset: [0, -8],
      placement: isMobile ? undefined : 'right-start',
    });

  const { openModal } = useModal();
  const { axios, user, logout } = useAuth();

  const closeMenu = () => setIsMenuVisible(false);

  useEscapeEvent(closeMenu);
  useClickAway(menuRef, closeMenu);

  const { mutate, isLoading } = useMutation(
    async () => {
      try {
        await axios.post('/auth/token/logout/');
      } catch (err) {
        throw err;
      }
    },
    { onSuccess: logout }
  );

  const { mutate: selectRole, isLoading: isSelectRoleLoading } = useMutation<
    void,
    AxiosError,
    string
  >(
    async (groupName: string) => {
      try {
        await axios.post(`/accounts/group-switch/${groupName}/`);
        await queryClient.refetchQueries('profile');
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => {
        navigate(PrivatePaths.INDEX);
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const selectRoleHandler = (group: UserTypes) => {
    if (readOnly) return;
    const isNewRoleSelected = group !== user?.current_group_name;

    if (isNewRoleSelected) {
      if (onBeforeAction) {
        onBeforeAction(() => selectRole(group));
        return;
      }

      selectRole(group);
    }
    closeMenu();
  };

  const handleLogoutClick = () => {
    if (readOnly) return;
    const logoutCallback = () => {
      mutate();
    };
    if (onBeforeAction) {
      onBeforeAction(logoutCallback);
      return;
    }
    logoutCallback();
  };

  const handleCompanyClick = () => {
    if (user?.is_superadmin) {
      openModal({
        Content: <SuperAdminSelectCompany onBeforeAction={onBeforeAction} />,
      });
    }
  };

  return (
    <>
      <div ref={menuRef}>
        <button
          type="button"
          onClick={() => {
            if (readOnly) return;
            setIsMenuVisible(prev => !prev);
          }}
          className={styles.button}
        >
          <span
            className={cn(styles.avatar, { [styles.active]: isMenuVisible })}
          >
            {user?.first_name[0].toUpperCase()}
            {user?.last_name[0].toUpperCase()}
          </span>
        </button>

        {isMenuVisible && (
          <>
            <nav className={cn(styles.nav, styles.settings)}>
              <ul className={styles.list}>
                <li className={cn(styles.li, styles.group)} ref={setTriggerRef}>
                  <IconSVG name={IconsNames.person} />
                  <span className={styles.type}>
                    {t(`user.type.${user?.current_group_name}`)}
                  </span>
                  <IconSVG name={IconsNames.arrow} />
                </li>
                <li
                  className={cn(styles.li, styles.noPadding, styles.divider, {
                    [styles.disabled]: !user?.is_superadmin,
                  })}
                >
                  <button type="button" onClick={handleCompanyClick}>
                    <IconSVG name={IconsNames.company} />
                    {user?.company.name}
                  </button>
                </li>
                <li className={cn(styles.li, styles.noPadding)}>
                  <Link
                    to={`/${PrivatePaths.PROFILE}/${PrivatePaths.GENERAL}`}
                    className={styles.item}
                  >
                    {t('profile.heading')}
                  </Link>
                </li>
                <li className={cn(styles.li, styles.noPadding)}>
                  <Link
                    to={`/${CommonPaths.DOCUMENTS}/${CommonPaths.TERMS}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('documents.terms-of-service')}
                  </Link>
                </li>
                <li className={cn(styles.li, styles.noPadding)}>
                  <button
                    type="button"
                    className={styles.item}
                    onClick={handleLogoutClick}
                  >
                    <IconSVG name={IconsNames.log_out} />
                    {t('common.button.log-out')}
                  </button>
                </li>
              </ul>

              {visible && (
                <ul
                  ref={setTooltipRef}
                  {...getTooltipProps({
                    className: cn('tooltip-container', styles.tooltip),
                  })}
                >
                  {user?.available_groups.map(group => (
                    <li
                      key={group}
                      className={cn(styles.li, styles.noPadding, styles.role, {
                        [styles.selected]: group === user?.current_group_name,
                      })}
                    >
                      <button
                        type="button"
                        onClick={() => selectRoleHandler(group)}
                      >
                        <IconSVG
                          name={IconsNames.check_mark}
                          className={styles.checkmark}
                        />
                        {t(`user.type.${group}`)}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </nav>
          </>
        )}
      </div>
      {(isLoading || isSelectRoleLoading) && <LoaderScreen />}
    </>
  );
};

export default SettingsMenu;
