import { usePopperTooltip } from 'react-popper-tooltip';
import { useTranslation } from 'react-i18next';

import SimpleTooltip from 'components/UI/SimpleTooltip';
import Button from 'components/common/Button';

import { IconsNames } from 'constants/constants';

type Props = {
  onClick: () => void;
};

const DuplicateQuestionButton = ({ onClick }: Props) => {
  const { t } = useTranslation();
  const tooltipProps = usePopperTooltip({ placement: 'top' });

  return (
    <div>
      <Button
        type="button"
        onClick={onClick}
        setTooltipTriggerRef={tooltipProps.setTriggerRef}
        iconProps={{ name: IconsNames.copy }}
        isOnlyIcon
      />
      {tooltipProps.visible && (
        <SimpleTooltip options={tooltipProps}>
          {t('common.button.duplicate-question')}
        </SimpleTooltip>
      )}
    </div>
  );
};

export default DuplicateQuestionButton;
