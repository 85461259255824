import { useForm, useController } from 'react-hook-form';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import type { SelectOption } from 'types/models';

import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import PhoneField from 'components/common/PhoneField';
import LoaderScreen from 'components/common/LoaderScreen';
import AcceptTerms from 'components/common/AcceptTerms';

import useInvite, {
  InviteData,
} from 'components/InvitesContent/InviteForm/useInvite';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';
import { validationSchemaInviteBasic } from 'utils/validations';

import { UserTypes } from 'constants/constants';

import styles from './index.module.scss';

type FormValues = Omit<InviteData, 'group_name' | 'country_code'> & {
  group_name: { value: UserTypes; label: string };
  country_code?: SelectOption;
  acceptTerms?: boolean;
};

type Props = {
  userTypes: { value: UserTypes; label: string }[];
};

const BasicInfoForm = ({ userTypes }: Props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    register,
    unregister,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchemaInviteBasic),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { field: groupField } = useController({
    name: 'group_name',
    control,
  });
  const registerEmailMethods = register('email');

  const { mutate, error, isLoading, reset } = useInvite();

  const groupNameValue = watch('group_name');
  const isVendorSelected = groupNameValue?.value === UserTypes.vendor;

  useEffect(() => {
    groupField.onChange(userTypes[0]);
  }, [userTypes]);

  useEffect(() => {
    if (!isVendorSelected) {
      unregister('invited_name');
    }
  }, [isVendorSelected, unregister]);

  const submit = (data: FormValues) => {
    const requestBody = {
      ...data,
      group_name: data.group_name.value,
    };
    delete requestBody.acceptTerms;

    mutate(requestBody);
  };

  const groupNameSubmitError =
    error?.response?.data.group_name && error?.response?.data.group_name[0];
  const groupNameValidationError = errors.group_name?.value?.message;

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(submit)}>
        <InputWrapper
          label="common.field.role"
          validationError={groupNameValidationError}
          submitError={groupNameSubmitError}
        >
          <Select
            options={userTypes}
            styles={getDefaultSelectStylesWithError({
              error: !!(groupNameSubmitError || groupNameValidationError),
            })}
            {...groupField}
            placeholder={t('common.field.select')}
          />
        </InputWrapper>
        {isVendorSelected && (
          <InputWrapper
            label="common.field.vendor-name"
            wrapperClasses={styles.label}
            validationError={errors.invited_name?.message}
            submitError={
              error?.response?.data.invited_name &&
              error?.response?.data.invited_name[0]
            }
          >
            <input
              type="text"
              placeholder={t('common.field.name')}
              {...register('invited_name')}
            />
          </InputWrapper>
        )}
        <InputWrapper
          label="common.field.name"
          validationError={errors.first_name?.message}
          submitError={
            error?.response?.data.first_name &&
            error?.response?.data.first_name[0]
          }
        >
          <input
            type="text"
            placeholder={t('common.field.name')}
            {...register('first_name')}
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.surname"
          validationError={errors.last_name?.message}
          submitError={
            error?.response?.data.last_name &&
            error?.response?.data.last_name[0]
          }
        >
          <input
            type="text"
            placeholder={t('common.field.surname')}
            {...register('last_name')}
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.email"
          validationError={errors.email?.message}
          submitError={
            error?.response?.data.email && error?.response?.data.email[0]
          }
        >
          <input
            type="email"
            placeholder={t('common.field.email')}
            {...registerEmailMethods}
            onChange={e => {
              registerEmailMethods.onChange(e);
              reset();
            }}
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.phone"
          validationError={errors.phone_number?.message}
          submitError={
            error?.response?.data.phone_number &&
            error?.response?.data.phone_number[0]
          }
        >
          <PhoneField control={control} name="phone_number" isBasicStyles />
        </InputWrapper>
        <InputWrapper
          wrapperClasses={styles.terms}
          validationError={errors.acceptTerms?.message}
        >
          <input type="checkbox" id="terms" {...register('acceptTerms')} />
          <AcceptTerms className={styles.terms__text} />
        </InputWrapper>
        <Button withArrow className={styles.button} type="submit">
          {t('common.button.send')}
        </Button>
        {isLoading && <LoaderScreen />}
      </form>
    </div>
  );
};

export default BasicInfoForm;
