import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';
import { downloadFile } from 'helpers/downloadFile';

import Button from 'components/common/Button';
import ModalWindow from 'components/common/ModalWindow';
import Loader from 'components/common/LoaderScreen/Loader';

import styles from './index.module.scss';

const DownloadReportTemplate = () => {
  const { t } = useTranslation();
  const { campaignId } = useParams();
  const { openModal } = useModal();
  const { axios } = useAuth();

  const { isLoading, refetch } = useQuery<void, AxiosError>(
    ['download-report-template', campaignId],
    async () => {
      try {
        const { data, headers } = await axios.get<string>(
          `/vendor-campaigns/${campaignId}/download-report-template/`,
          {
            responseType: 'arraybuffer',
          }
        );

        downloadFile(
          data,
          headers['content-type'],
          headers['content-disposition']
        );
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: false,
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const handleCopyScript = () => {
    refetch();
  };

  return (
    <div className={styles.wrapper}>
      {isLoading && <Loader className={styles.loading} />}
      {!isLoading && (
        <Button className={styles.button} onClick={handleCopyScript} isSmall>
          <span>{t('common.button.download')}</span>
        </Button>
      )}
    </div>
  );
};

export default DownloadReportTemplate;
