import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import type { Area } from 'react-easy-crop';

import ModalWindow from 'components/common/ModalWindow';
import Button from 'components/common/Button';
import Error from 'components/common/Error';
import ImageCropper, {
  Props as CropperProps,
} from 'components/common/ImageCropper';

import { IconsNames } from 'constants/constants';

import useModal from 'contexts/ModalContext';

import getCroppedImg from 'utils/cropImage';

import styles from './index.module.scss';

type Props = Partial<CropperProps> & {
  title: string;
  imageHeight: number;
  imageWidth: number;
  uploadImage: any;
  file?: File | null;
};

const CropImageModal = ({
  image,
  title,
  imageWidth,
  imageHeight,
  uploadImage,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const [croppedArea, setCroppedArea] = useState<null | Area>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [getImageError, setGetImageError] = useState('');

  const onSaveResult = async () => {
    setIsLoading(true);
    try {
      const croppedImage = await getCroppedImg(image as string, croppedArea);
      uploadImage(croppedImage);
      closeModal();
    } catch (err) {
      setGetImageError(t('common.error.something-went-wrong'));
      Sentry.captureMessage(
        `Get cropping image error: ${image}, ${JSON.stringify(croppedArea)}`
      );
    }
    setIsLoading(false);
  };

  return (
    <ModalWindow primaryTitle={title} className={styles.modalWrapper}>
      <ImageCropper
        image={image as string}
        setCroppedArea={setCroppedArea}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        className={styles.cropper}
        {...props}
      />
      {getImageError && <Error message={getImageError} />}
      <div className={styles.buttonsWrapper}>
        <Button white onClick={closeModal}>
          {t('common.button.change-image')}
        </Button>
        <Button
          iconProps={{ name: IconsNames.check_mark }}
          onClick={onSaveResult}
          isLoading={isLoading}
          className={styles.apply}
        >
          {t('common.button.apply')}
        </Button>
      </div>
    </ModalWindow>
  );
};

export default CropImageModal;
