import MaskedInput from 'react-text-mask';

import type { Props } from 'react-input-mask';

import styles from './index.module.scss';

const TwoFAInput = ({ ...props }: Partial<Props>) => {
  return (
    <MaskedInput
      className={styles.input}
      {...props}
      mask={[/\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/]}
      showMask
    />
  );
};

export default TwoFAInput;
