import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import cn from 'classnames';

import type { FormValues } from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/models';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';

import NavigationButtons from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/NavigationButtons';
import ServerCheckbox from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/SelectServer/ServerCheckbox';
import Finish from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/Finish';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import { useGetEmailServers } from 'hooks/api/useGetEmailServers';
import useUpdateContactsReport from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/useUpdateContactsReport';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

type Props = {
  campaignId: number | string;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  stepsCount: number;
  currentStep: number;
};

const SelectServer = ({
  setStep,
  stepsCount,
  currentStep,
  campaignId,
}: Props) => {
  const { watch, getValues } = useFormContext<FormValues>();
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetEmailServers(true);
  const { openModal } = useModal();
  const { axios, user } = useAuth();
  const { mutateAsync: updateReport } = useUpdateContactsReport();
  const queryClient = useQueryClient();

  const { isLoading: isFinishLoading, mutate: finishCreation } = useMutation(
    async () => {
      try {
        const { id, integration_ids: servers } = getValues();
        if (id) {
          await updateReport({
            campaignId,
            fileId: id,
            integration_ids: servers,
          });

          await axios.post(
            `/vendor-campaigns/${campaignId}/contacts-uploads/${id}/finish/`
          );
        }
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => {
        openModal({ Content: Finish });
        await queryClient.refetchQueries('vendor-contacts-list');
        await queryClient.refetchQueries([
          'campaign',
          campaignId.toString(),
          user?.current_group_name,
          user?.company.id.toString(),
        ]);
      },
    }
  );

  const isNextStepDisabled = !watch('integration_ids')?.length;

  return (
    <section
      className={cn(styles.wrapper, { [styles.disabled]: isFinishLoading })}
    >
      <h3 className={styles.title}>
        {t('campaign-deliveries.select-mail-server')}
      </h3>

      {!!data?.length && (
        <ul className={styles.list}>
          {data.map(server => (
            <ServerCheckbox key={server.id} server={server} />
          ))}
        </ul>
      )}

      {isLoading && <Loader size={48} className={styles.spinner} />}
      {error && (
        <div className={styles.error}>
          <p className={styles.wrong}>
            {t('common.error.something-went-wrong')}
          </p>
          <Error message={getResponseError(error)} />
        </div>
      )}

      <NavigationButtons
        isNextDisabled={isNextStepDisabled}
        setStep={setStep}
        stepsCount={stepsCount}
        className={styles.navigation}
        currentStep={currentStep}
        onGoToNextStep={finishCreation}
        isNextStepLoading={isFinishLoading}
      />
    </section>
  );
};

export default SelectServer;
