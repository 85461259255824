import { useRef, useState } from 'react';
import cn from 'classnames';
import { useClickAway } from 'react-use';
import { isSafari } from 'react-device-detect';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: (props: {
    wrapperRef: React.RefObject<HTMLDivElement>;
    setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
    isFocused: boolean;
  }) => React.ReactElement;
  hasStyling?: boolean;
  defaultFocused?: boolean;
  focusClassName?: string;
  isPreviewMode?: boolean;
}

const FocusedWrapper = ({
  children,
  hasStyling = true,
  className,
  defaultFocused = false,
  focusClassName,
  isPreviewMode,
  ...props
}: Props) => {
  const [isFocused, setIsFocused] = useState(defaultFocused);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickAway(wrapperRef, () => {
    if (isFocused) {
      setIsFocused(false);

      if (isSafari) {
        const htmlElementHeight = document
          .querySelector('html')
          ?.getBoundingClientRect().height;
        if (
          htmlElementHeight &&
          htmlElementHeight < window.scrollY + window.innerHeight
        ) {
          window.scrollTo(window.scrollX, window.scrollY - 1);
        }
      }
    }
  });

  const handleSetFocusedState = () => {
    if (!isFocused && !isPreviewMode) setIsFocused(true);
  };

  const isFocusClasses = isFocused && hasStyling && !isPreviewMode;

  return (
    <div
      className={cn(
        styles.slate,
        className,
        isFocusClasses ? focusClassName : '',
        {
          [styles.active]: isFocusClasses,
        }
      )}
      ref={!isPreviewMode ? wrapperRef : undefined}
      onClick={handleSetFocusedState}
      {...props}
    >
      {children({
        wrapperRef,
        setIsFocused,
        isFocused,
      })}
    </div>
  );
};

export default FocusedWrapper;
