import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import type { SelectOption } from 'types/models';

import ModalWindow from 'components/common/ModalWindow';
import SelectEmail from 'components/common/SelectEmail';

import styles from './index.module.scss';

type Props = {
  onSubmit: (emails?: string[]) => void;
};

const InvoiceEmailModal = ({ onSubmit }: Props) => {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<SelectOption[]>([]);

  return (
    <ModalWindow
      className={styles.modal}
      primaryTitle={t('payments.add-recipients')}
      negativeButtonProps={{
        type: 'button',
        children: t('common.button.send-emails'),
        onClick: () => onSubmit(emails.map(item => item.value)),
      }}
      positiveButtonProps={{
        type: 'button',
        children: t('common.button.skip'),
        onClick: () => onSubmit(),
        white: true,
      }}
    >
      <h4 className={styles.description}>
        {t('payments.add-email-addresses')}
      </h4>
      <SelectEmail field={{ value: emails, onChange: setEmails }} />
    </ModalWindow>
  );
};

export default InvoiceEmailModal;
