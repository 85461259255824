import useAuth from 'contexts/AuthContext';

import PageContainer from 'components/common/PageContainer';
import AdminHomeContent from 'components/AdminHomeContent';
import VendorManagerHomeContent from 'components/VendorManagerHomeContent';
import VendorHomeContent from 'components/VendorHomeContent';

import { UserTypes } from 'constants/constants';

const Home = () => {
  const { user } = useAuth();

  const isAdmin = user && user?.current_group_name === UserTypes.admin;
  const isVendorManager =
    user && user?.current_group_name === UserTypes.vendorManager;
  const isVendor = user && user?.current_group_name === UserTypes.vendor;

  return (
    <PageContainer path={['home.heading']}>
      {isAdmin && <AdminHomeContent />}
      {isVendorManager && <VendorManagerHomeContent />}
      {isVendor && <VendorHomeContent />}
    </PageContainer>
  );
};

export default Home;
