import { useEffect } from 'react';

const OBSERVER_OPTIONS = {
  rootMargin: '0px',
  threshold: 0.25,
};

const useObserver = (
  ref: React.RefObject<HTMLElement>,
  callback: (isIntersect: boolean) => void,
  observerOptions?: Partial<IntersectionObserverInit>
) => {
  const observerCallback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    callback(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
        ? { ...OBSERVER_OPTIONS, ...observerOptions }
        : OBSERVER_OPTIONS
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref.current, observerOptions?.root]);
};

export default useObserver;
