import useAuth from 'contexts/AuthContext';
import { useInfiniteQuery } from 'react-query';

import type { RequestResponse, PaginationReturn } from 'types/models';
import type { AxiosError } from 'axios';
import { UserTypes } from 'constants/constants';

const useFetchRequests = () => {
  const { axios, user } = useAuth();

  const query = useInfiniteQuery<PaginationReturn<RequestResponse>, AxiosError>(
    ['requests-list', user?.current_group_name, user?.company.id],
    async ({ pageParam }) => {
      let page;
      if (typeof pageParam === 'string') {
        const url = new URL(pageParam);
        page = url.searchParams.get('page');
      }
      const pageNumber = page || 1;

      try {
        const { data } = await axios.get<PaginationReturn<RequestResponse>>(
          `/accounts/manage-demo-requests/?page_size=15&page=${pageNumber}&company=${user?.company.id}`
        );
        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      getNextPageParam: page => {
        return page.next || undefined;
      },
      enabled: user?.current_group_name === UserTypes.admin,
    }
  );

  return query;
};

export default useFetchRequests;
