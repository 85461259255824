import Button from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PrivatePaths } from 'constants/routes';

import styles from './index.module.scss';

type Props = {
  isFirstStepDone: boolean;
};

const Header: React.FC<Props> = ({ isFirstStepDone }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();
    navigate(`/${PrivatePaths.PRODUCTS}`);
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    navigate(`/${PrivatePaths.PRODUCTS}`);
  };

  return (
    <header className={styles.header}>
      <p className={styles.text}>{t('manage-products.create-product-page')}</p>
      <div className={styles.buttons}>
        <Button
          type="button"
          className={styles.button}
          onClick={onCancel}
          white
        >
          {t('common.button.cancel')}
        </Button>
        <Button
          type="button"
          className={styles.button}
          disabled={!isFirstStepDone}
          onClick={onSubmit}
        >
          {t('common.button.submit')}
        </Button>
      </div>
    </header>
  );
};

export default Header;
