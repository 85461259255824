import { useTranslation } from 'react-i18next';

import {
  CheckoutOptions,
  CreatePaymentPayload,
} from 'components/PaymentsComponents/models';

import Button from 'components/common/Button';
import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';

import DepositCountModal from 'components/PaymentsComponents/DepositCountModal';
import EditAccountingPersonModal from 'components/PaymentsComponents/EditAccountingPersonModal';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';
import useCreatePayment from 'hooks/api/useCreatePayment';

import { IconsNames } from 'constants/constants';

import getResponseError from 'helpers/getResponseError';

type Props = {
  paymentOptions?: CreatePaymentPayload;
  checkoutOptions?: Partial<CheckoutOptions>;
  className?: string;
  getPaymentOptions?: () => CreatePaymentPayload;
  disabled?: boolean;
  getIsPaymentEnabled?: () => Promise<boolean>;
};

const DepositNowButton = ({
  paymentOptions,
  checkoutOptions,
  className,
  getPaymentOptions,
  disabled,
  getIsPaymentEnabled,
}: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { user } = useAuth();
  const { isLoading, mutate } = useCreatePayment();

  const handleCheckout = async () => {
    const isEnabled = await getIsPaymentEnabled?.();
    if (getIsPaymentEnabled && !isEnabled) return;

    const handleCreateTransaction = (options?: CreatePaymentPayload) => {
      if (!options?.amount || !options.method) {
        return openModal({
          Content: <DepositCountModal amount={options?.amount} />,
        });
      }

      mutate(
        { ...options, checkoutOptions },
        {
          onError: err =>
            openModal({
              Content: (
                <ModalWindow
                  title={t('common.error.something-went-wrong')}
                  errorMessage={getResponseError(err)}
                />
              ),
            }),
        }
      );
    };

    const currentPaymentOptions = getPaymentOptions
      ? getPaymentOptions()
      : paymentOptions;

    const isAccountingPersonInfo =
      user?.company.accounting_person_first_name &&
      user?.company.accounting_person_last_name &&
      user?.company.accounting_person_email;
    if (!isAccountingPersonInfo) {
      return openModal({
        Content: (
          <EditAccountingPersonModal
            onSuccess={() => handleCreateTransaction(currentPaymentOptions)}
          />
        ),
      });
    }

    handleCreateTransaction(currentPaymentOptions);
  };

  return (
    <>
      <Button
        type="button"
        iconProps={{ name: IconsNames.dollar }}
        className={className}
        onClick={handleCheckout}
        disabled={disabled}
        isBig
      >
        {t('common.button.deposit-now')}
      </Button>
      {isLoading && <LoaderScreen />}
    </>
  );
};

export default DepositNowButton;
