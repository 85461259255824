import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';

import ModalWindow from 'components/common/ModalWindow';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import { downloadFile } from 'helpers/downloadFile';
import getResponseError from 'helpers/getResponseError';

export const useDownloadContactsTemplate = (campaignId?: string | number) => {
  const { axios } = useAuth();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const query = useQuery<void, AxiosError>(
    ['download-contacts-template'],
    async () => {
      try {
        const { data, headers } = await axios.get<string>(
          `/vendor-campaigns/${campaignId}/contacts-uploads/template/`,
          {
            responseType: 'arraybuffer',
          }
        );

        downloadFile(
          data,
          headers['content-type'],
          headers['content-disposition']
        );
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: false,
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  return query;
};
