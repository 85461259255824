import { useQueryClient } from 'react-query';

import PageContainer from 'components/common/PageContainer';
import PageTitle from 'components/common/PageContainer/PageTitle';

import CurrentBalanceDepositNow from 'components/PaymentsComponents/CurrentBalanceDepositNow';
import TransactionsHistory from 'components/PaymentsComponents/TransactionsHistory';

import useAuth from 'contexts/AuthContext';

const Payments = () => {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  return (
    <PageContainer>
      <CurrentBalanceDepositNow
        onModalClose={() => {
          queryClient.refetchQueries([
            'transactions-history',
            user?.company.id.toString(),
          ]);
        }}
      />
      <PageTitle path={['payments.heading']} />
      <TransactionsHistory />
    </PageContainer>
  );
};

export default Payments;
