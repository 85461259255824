import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import cn from 'classnames';

import type {
  CreateCampaignValues,
  AssetValues,
} from 'components/CreateCampaign/models';
import type { CampaignAssetFileResponse } from 'types/models';

import Button from 'components/common/Button';
import CreateAssetModal from 'components/common/CreateAssetModal';
import LoaderScreen from 'components/common/LoaderScreen';

import AddProductPageModal from 'components/CreateCampaign/Step1/Asset/AddProductPageModal';
import AddExistingAssetModal from 'components/CreateCampaign/Step1/Asset/AddExistingAssetModal';

import IconSVG from 'components/UI/IconSVG';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';
import useEnabledFeatures from 'contexts/EnabledFeaturesContext';
import useSaveCampaignAsset from 'components/CreateCampaign/useSaveCampaignAsset';

import { IconsNames, CampaignCreatingStep } from 'constants/constants';
import { ToggledFeatures } from 'constants/features';

import styles from './index.module.scss';

const AddButton = () => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const { axios, user } = useAuth();
  const { getIsFeatureEnabled } = useEnabledFeatures();
  const { setValue, getValues } = useFormContext<CreateCampaignValues>();
  const queryClient = useQueryClient();
  const { mutateAsync: saveAsset, isLoading } = useSaveCampaignAsset(
    getValues().id,
    setValue
  );
  const { setTriggerRef, visible, getTooltipProps, setTooltipRef } =
    usePopperTooltip({
      interactive: true,
      offset: [0, 0],
      placement: 'bottom-start',
      trigger: 'click',
    });
  const isDemoCampaign =
    useWatch({ name: 'campaign_details.cost_per_lead' }) === 0;

  const handleCampaignStep = async () => {
    const campaignValues = getValues();
    const isAddAssetAfterSubmitFirstStep =
      getValues().campaign_details.creating_step !==
      CampaignCreatingStep['first-step'];

    if (isAddAssetAfterSubmitFirstStep) {
      await axios.patch(`/campaigns/${campaignValues.id}/`, {
        campaign_details: {
          creating_step: CampaignCreatingStep['first-step'],
        },
      });
    }
  };

  const handleAddExistingAssets = async (
    selectedAssets: CampaignAssetFileResponse[]
  ) => {
    await handleCampaignStep();
    const newAssets = [
      ...selectedAssets,
      ...(getValues().campaign_files?.assets || []),
    ];

    queryClient.setQueryData<CampaignAssetFileResponse[]>(
      ['campaign', getValues().id.toString(), 'assets', user?.company.id],
      newAssets.sort((a, b) => b.id - a.id)
    );
  };

  const handleAddAsset = async (asset: AssetValues) => {
    await handleCampaignStep();
    saveAsset({ asset }, { onSuccess: closeModal });
  };

  const handleAddAssetClick = () => {
    openModal({
      Content: (
        <CreateAssetModal onSubmit={handleAddAsset} isSelectProductPage />
      ),
    });
  };

  const handleAddExistingAsset = () => {
    openModal({
      Content: (
        <AddExistingAssetModal
          campaignId={getValues().id}
          handleAddExistingAssets={handleAddExistingAssets}
        />
      ),
    });
  };

  const handleAddProductPageClick = () => {
    openModal({
      Content: <AddProductPageModal onSubmit={handleAddAsset} />,
    });
  };

  const isSelectExistingAssetEnabled = getIsFeatureEnabled(
    ToggledFeatures.select_existing_assets
  );

  return (
    <div className={styles.container} ref={setTriggerRef}>
      <Button
        type="button"
        iconProps={{
          name: IconsNames.arrow,
          isRightPosition: true,
          className: cn(styles.arrow, { [styles.arrow__open]: visible }),
        }}
      >
        {t('common.button.add')}
      </Button>
      {visible && (
        <div
          className={styles.tooltip}
          {...getTooltipProps({
            className: cn('tooltip-container', styles.tooltip),
          })}
          ref={setTooltipRef}
        >
          <ul>
            {!isDemoCampaign ? (
              <>
                <li onClick={handleAddAssetClick} role="presentation">
                  <IconSVG
                    name={IconsNames.file_stroke}
                    className={styles.icon}
                  />
                  {t('common.button.new-asset')}
                </li>
                {isSelectExistingAssetEnabled && (
                  <li onClick={handleAddExistingAsset} role="presentation">
                    <IconSVG
                      name={IconsNames.folder_open}
                      className={styles.icon}
                    />
                    {t('common.button.existing-asset')}
                  </li>
                )}
              </>
            ) : (
              <li onClick={handleAddProductPageClick} role="presentation">
                <IconSVG
                  name={IconsNames.folder_open}
                  className={styles.icon}
                />
                {t('common.button.existing-product-page')}
              </li>
            )}
          </ul>
        </div>
      )}
      {isLoading && <LoaderScreen />}
    </div>
  );
};

export default AddButton;
