import { useTranslation } from 'react-i18next';

import ModalWindow from 'components/common/ModalWindow';
import IconSVG from 'components/UI/IconSVG';

import useModal from 'contexts/ModalContext';

import { IconsNames } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  campaigns: string[];
  email: string;
};

const CampaignsModal = ({ campaigns, email }: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const openCampaignModal = () => {
    openModal({
      Content: (
        <ModalWindow
          title={`${email} ${t('common.field.campaigns').toLowerCase()}:`}
        >
          <ol className={styles.list}>
            {campaigns.map((name, index) => (
              <li key={`${name} ${index}`}>{name}</li>
            ))}
          </ol>
        </ModalWindow>
      ),
    });
  };

  return !!campaigns?.length ? (
    <button className={styles.campaign_button} onClick={openCampaignModal}>
      <IconSVG name={IconsNames.eye} />
      {t('common.button.view')}
    </button>
  ) : null;
};

export default CampaignsModal;
