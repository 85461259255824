import { useTranslation } from 'react-i18next';
import { useMemo, useState, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

import type { PaginationReturn, UserGroup } from 'types/models';
import type { AxiosError } from 'axios';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import Table from 'components/common/Table';

import ActionCell from 'components/UserGroupsContent/GroupsTable/ActionCell';
import NameCell from 'components/UserGroupsContent/GroupsTable/NameCell';
import EmailsCell from 'components/UserGroupsContent/GroupsTable/EmailsCell';

import useAuth from 'contexts/AuthContext';
import useObserver from 'hooks/useObserver';

import { validationSchemaEditGroup } from 'utils/validations';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

enum TableAccessors {
  name = 'name',
  emails = 'emails',
  action = 'action',
}

type EditGroupFormFields = Omit<UserGroup, 'users'> & {
  users: { value: string; label: string }[];
};

const PAGE_SIZE = 25;

const GroupsTable = () => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();
  const [editableRowIndex, setEditableRowIndex] = useState<number | null>(null);
  const queryClient = useQueryClient();
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const {
    data: groupsData,
    fetchNextPage,
    isFetchingNextPage,
    error: getGroupsError,
    isLoading: isGetGroupsLoading,
  } = useInfiniteQuery<PaginationReturn<UserGroup>, AxiosError>(
    'groupsList',
    async ({ pageParam = 1 }: { pageParam?: number | string }) => {
      let getPageParam;
      if (typeof pageParam === 'string') {
        const url = new URL(pageParam);
        getPageParam = url.searchParams.get('page');
      }
      const pageNumber =
        typeof pageParam === 'number' ? pageParam : getPageParam;
      try {
        const { data } = await axios.get<PaginationReturn<UserGroup>>(
          `/companies/${user?.company.id}/user-groups/?page=${pageNumber}&page_size=${PAGE_SIZE}`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      getNextPageParam: lastPage => (lastPage.next ? lastPage.next : undefined),
      enabled: Boolean(user?.company.id),
    }
  );

  useObserver(loadMoreRef, (isIntersecting: boolean) => {
    if (isIntersecting && user?.company.id) fetchNextPage();
  });

  const { isLoading, mutate, error } = useMutation<void, AxiosError, UserGroup>(
    async ({ name, id, users, company }) => {
      try {
        await axios.put(`/companies/${company}/user-groups/${id}/`, {
          name,
          users,
        });
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('groupsList');
        setEditableRowIndex(null);
      },
    }
  );

  const editGroupFormMethods = useForm<EditGroupFormFields>({
    resolver: yupResolver(validationSchemaEditGroup),
    mode: 'onBlur',
  });

  const rowsData = useMemo(() => {
    return groupsData?.pages
      .map(page => page.results)
      .flat()
      .map(group => ({
        [TableAccessors.name]: group.name,
        [TableAccessors.emails]: group.users,
        [TableAccessors.action]: group,
      }));
  }, [groupsData]);

  const columnsData = useMemo(
    () => [
      {
        Header: t(`common.field.${TableAccessors.name}`),
        accessor: TableAccessors.name,
        Cell: ({
          value,
          row: { index },
        }: {
          value: string;
          row: { index: number };
        }) => (
          <NameCell
            name={value}
            isEditMode={index === editableRowIndex}
            error={error}
          />
        ),
      },
      {
        Header: t(`common.field.${TableAccessors.emails}`),
        accessor: TableAccessors.emails,
        Cell: ({
          value,
          row: { index },
        }: {
          value: UserGroup['users'];
          row: { index: number };
        }) => (
          <EmailsCell isEditMode={index === editableRowIndex} users={value} />
        ),
      },
      {
        Header: '',
        accessor: TableAccessors.action,
        Cell: ({
          value,
          row: { index },
        }: {
          value: UserGroup;
          row: { index: number };
        }) => (
          <ActionCell
            group={value}
            rowIndex={index}
            setEditableRowIndex={setEditableRowIndex}
            editableRowIndex={editableRowIndex}
            isLoading={isLoading}
          />
        ),
      },
    ],
    [editableRowIndex, isLoading]
  );

  const handleEditSubmit = (data: EditGroupFormFields) => {
    if (data.users.length) {
      const requestData = {
        ...data,
        users: data.users.map(item => item.value),
      };
      mutate(requestData);
    }
  };

  const isLoader = isGetGroupsLoading || isFetchingNextPage;
  const isNoGroups =
    !isGetGroupsLoading && !rowsData?.length && !getGroupsError;
  const isGroupsLength = !isGetGroupsLoading && !!rowsData?.length;

  return (
    <FormProvider {...editGroupFormMethods}>
      {isGroupsLength && (
        <form onSubmit={editGroupFormMethods.handleSubmit(handleEditSubmit)}>
          <Table
            tableOptions={{ columns: columnsData, data: rowsData }}
            wrapperClassName={styles.table}
            headClassName={styles.thead}
          />
        </form>
      )}

      {getGroupsError && <Error message={getResponseError(getGroupsError)} />}
      {isLoader && <Loader className={styles.loader} />}
      {isNoGroups && <p>{t('user-groups.no-groups')}</p>}

      <div ref={loadMoreRef} />
    </FormProvider>
  );
};

export default GroupsTable;
