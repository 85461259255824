import PageContainer from 'components/common/PageContainer';
import PageTitle from 'components/common/PageContainer/PageTitle';

import CurrentBalanceDepositNow from 'components/PaymentsComponents/CurrentBalanceDepositNow';
import VendorTransactionsHistory from 'components/PaymentsComponents/VendorTransactionsHistory';

const Payouts = () => {
  return (
    <PageContainer>
      <CurrentBalanceDepositNow />
      <PageTitle path={['payments.payouts']} />
      <VendorTransactionsHistory />
    </PageContainer>
  );
};

export default Payouts;
