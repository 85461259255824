import { useFormContext, Controller } from 'react-hook-form';

import { UserGroup } from 'types/models';

import InputWrapper from 'components/common/InputWrapper';
import SelectEmail from 'components/common/SelectEmail';

import styles from './index.module.scss';

type Props = {
  users: UserGroup['users'];
  isEditMode: boolean;
};

const EmailsCell = ({ users, isEditMode }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return isEditMode ? (
    <div className={styles.email}>
      <InputWrapper validationError={errors.users?.message}>
        <Controller
          name="users"
          control={control}
          rules={{ required: true, minLength: 1 }}
          render={({ field }) => (
            <SelectEmail field={field} isError={!!errors.users?.message} />
          )}
        />
      </InputWrapper>
    </div>
  ) : (
    <ul className={styles.list}>
      {users.map(item => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
};

export default EmailsCell;
