import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useState } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';

import type {
  ActiveCampaignOffers,
  PaginationReturn,
  ActiveOffers,
} from 'types/models';

import { UserStatuses, UserTypes } from 'constants/constants';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

enum UserActions {
  activate = 'activate',
  deactivate = 'deactivate',
}

type Props = {
  status: UserStatuses;
  id: number;
  email: string;
};

const InvitesAction = ({ id, email, status }: Props) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState('');
  const { openModal, closeModal } = useModal();
  const { user, axios } = useAuth();
  const queryClient = useQueryClient();

  const successMessage = (action: UserActions) => {
    setSuccess(`invites.${action}d`);
    setTimeout(() => {
      setSuccess('');
    }, 5000);
  };

  const {
    mutate: activateMutate,
    error: activateError,
    isLoading: isActivateLoading,
  } = useMutation<void, AxiosError, number>(
    async (memberId: number) => {
      try {
        await axios.post(
          `/companies/${user?.company.id}/members/${memberId}/activate/`
        );
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'invitesList',
          user?.current_group_name,
        ]);
        successMessage(UserActions.activate);
      },
    }
  );

  const {
    mutate: deactivateMutate,
    error: deactivateError,
    isLoading: isDeactivateLoading,
  } = useMutation<void, AxiosError, number>(
    async (memberId: number) => {
      try {
        await axios.post(
          `/companies/${user?.company.id}/members/${memberId}/deactivate/`
        );
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'invitesList',
          user?.current_group_name,
        ]);
        successMessage(UserActions.deactivate);
      },
    }
  );

  const { isLoading: isActiveOffers, refetch: refetchActiveOffers } = useQuery<
    PaginationReturn<ActiveCampaignOffers>,
    AxiosError
  >(
    ['active-campaign-offers', id],
    async () => {
      try {
        const { data } = await axios.get<
          PaginationReturn<ActiveCampaignOffers>
        >(`/companies/${user?.company.id}/members/active-campaign-offers/`);

        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: data => {
        const activeOffers =
          Array.isArray(data.results) &&
          data.results
            .filter((item: ActiveCampaignOffers) => item.id === id)
            .map((item: ActiveCampaignOffers) => item?.active_offers)
            .flat()
            .map((item: ActiveOffers) => item.campaign);

        const isEmpty = Array.isArray(activeOffers) && activeOffers.length;

        if (!isEmpty) {
          deactivateMutate(id);
        } else {
          openModal({
            Content: (
              <ModalWindow
                title={<h3>{t('common.modal.you-cant-deactivate')}</h3>}
                positiveButtonProps={{
                  className: styles.yes,
                  onClick: () => closeModal(),
                  children: t('common.button.ok'),
                }}
              >
                <ol className={styles.modalCantDeactivate}>
                  {activeOffers.map(item => (
                    <li key={item.id}>{item.name}</li>
                  ))}
                </ol>
              </ModalWindow>
            ),
          });
        }
      },
      enabled: false,
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const confirmModalSubmit = (btnName: UserActions) => {
    if (btnName === UserActions.deactivate) {
      if (user?.current_group_name === UserTypes.vendorManager) {
        refetchActiveOffers();
      } else {
        deactivateMutate(id);
      }
    } else {
      activateMutate(id);
    }
    closeModal();
  };

  const openConfirmModal = ({
    currentTarget: { id: targetId, name: targetName },
  }: React.MouseEvent<HTMLButtonElement>) => {
    openModal({
      Content: (
        <ModalWindow
          title={
            <h3>
              {t('common.modal.are-you-sure')}
              <span>{t(`user.action.${targetId}`)}</span>
              {email}?
            </h3>
          }
          positiveButtonProps={{
            className: styles.yes,
            onClick: () => confirmModalSubmit(targetName as UserActions),
            children: t('common.button.yes'),
          }}
        />
      ),
    });
  };

  const error = deactivateError || activateError;
  const errorMessage = error ? getResponseError(error) : undefined;
  const isInvited = status === UserStatuses.invited;
  const isActive = status === UserStatuses.active;
  const isSuspended = status === UserStatuses.suspended;

  return (
    <>
      <div className={cn(styles.action, { [styles.space]: isInvited })}>
        {isSuspended && (
          <button
            type="button"
            name={UserActions.activate}
            className={styles.button}
            onClick={openConfirmModal}
            id={UserActions.activate}
          >
            {t('common.button.activate')}
          </button>
        )}

        {isActive && (
          <button
            type="button"
            name={UserActions.deactivate}
            className={cn(styles.button, styles.grey)}
            onClick={openConfirmModal}
            id={UserActions.deactivate}
          >
            {t('common.button.deactivate')}
          </button>
        )}
      </div>
      {error && <span className={styles.error}>{errorMessage}</span>}
      <span className={styles.success}>{t(success)}</span>
      {(isActivateLoading || isDeactivateLoading || isActiveOffers) && (
        <LoaderScreen />
      )}
    </>
  );
};

export default InvitesAction;
