import { useQuery } from 'react-query';

import type { UseFormSetValue } from 'react-hook-form';
import type { AxiosError } from 'axios';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { ReportTemplateResponse, CampaignResponse } from 'types/models';

import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';

const useGetReportTemplate = ({
  campaign,
  setFormValue,
}: {
  campaign?:
    | CreateCampaignValues
    | (CampaignResponse & { isJustCreated?: boolean });
  setFormValue: UseFormSetValue<CreateCampaignValues>;
}) => {
  const { axios } = useAuth();
  const { convertReportTemplateResponse } = useConvertCampaignData();

  const getReportTemplateRequestSettings = useQuery<
    ReportTemplateResponse | undefined,
    AxiosError
  >(
    ['campaign', campaign?.id, 'report-mapping'],
    async () => {
      try {
        const { data } = await axios.get<ReportTemplateResponse>(
          `/campaigns/${campaign?.id}/report-template-mapping/`
        );

        return data;
      } catch (error) {
        if ((error as AxiosError).response?.status !== 404) throw error;
      }
    },
    {
      enabled: !!campaign && !!campaign?.id && !campaign.isJustCreated,
      retry: (count, error) => error.response?.status !== 404,
      refetchOnWindowFocus: false,
      onSuccess: reportFile => {
        setFormValue(
          'campaign_files.report_template',
          reportFile ? convertReportTemplateResponse(reportFile) : undefined
        );
      },
    }
  );

  return getReportTemplateRequestSettings;
};

export default useGetReportTemplate;
