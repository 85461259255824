import * as Yup from 'yup';

import {
  MAX_LENGTH_MESSAGE,
  REQUIRED_MESSAGE,
  MAXIMUM_DIGITS_AFTER_DECIMAL,
  TWO_DIGITS_AFTER_COMMA_REGEXP,
} from 'utils/validations';

export const PRICING_PLAN_BENEFITS_MAX_LENGTH = 250;

export const validationSchemaCreatePricingPlan = Yup.lazy(() =>
  Yup.object().shape({
    name: Yup.string()
      .max(20, { key: MAX_LENGTH_MESSAGE, values: { length: 20 } })
      .required(REQUIRED_MESSAGE)
      .onlyLettersSpacesNumbers(),
    price: Yup.string()
      .max(10, { key: MAX_LENGTH_MESSAGE, values: { length: 10 } })
      .test({
        name: 'price',
        test: function (value) {
          const isNotEmpty = this.parent.isFree || !!value;

          return (
            isNotEmpty ||
            this.createError({
              message: REQUIRED_MESSAGE,
              path: '',
            })
          );
        },
      })
      .test(
        'is-decimal',
        {
          key: MAXIMUM_DIGITS_AFTER_DECIMAL,
          values: { value: 2 },
        },
        (val?: string) => {
          if (!!val) {
            return TWO_DIGITS_AFTER_COMMA_REGEXP.test(val);
          }
          return true;
        }
      ),
    pricing_benefits: Yup.string()
      .max(PRICING_PLAN_BENEFITS_MAX_LENGTH, {
        key: MAX_LENGTH_MESSAGE,
        values: { length: PRICING_PLAN_BENEFITS_MAX_LENGTH },
      })
      .required(REQUIRED_MESSAGE),
  })
);
