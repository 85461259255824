import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useFieldArray, useController } from 'react-hook-form';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

import type { UseMutationResult } from 'react-query';
import type { UseFormWatch } from 'react-hook-form';
import type {
  CampaignAssetFileResponse,
  CampaignResponse,
  OfferResponse,
} from 'types/models';
import type { AxiosError } from 'axios';
import {
  OffersFormValues,
  AssetsLeadsByMilestone,
  AssignRequestBody,
  LeadsRequiredPerMilestoneDictionary,
  OfferFormValues,
} from 'components/CampaignManage/models';

import NumberField from 'components/common/NumberField';
import InputWrapper from 'components/common/InputWrapper';
import OfferStatus from 'components/common/OfferStatus';
import Error from 'components/common/Error';
import ToggleSwitch from 'components/common/ToggleSwitch';

import IconSVG from 'components/UI/IconSVG';

import MilestoneVMOfferItem from 'components/CampaignManage/OfferItem/VMOfferItem/VMMilestoneOfferItem';
import ActionButtons from 'components/CampaignManage/OfferItem/ActionButtons';
import History from 'components/CampaignManage/OfferItem/History';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';

import useConvertCampaignData from 'hooks/useConvertCampaignData';

import { OfferStatuses, IconsNames, UserTypes } from 'constants/constants';
import { AssetDistributionType } from 'constants/assets';

import styles from '../index.module.scss';

type Props = {
  index: number;
  status?: OfferStatuses;
  campaign: CampaignResponse;
  assets: CampaignAssetFileResponse[];
  assignedLeadsBySavedAssets: AssetsLeadsByMilestone;
  assignMutation?: UseMutationResult<
    { data: OfferResponse; offerIndex: number },
    AxiosError,
    AssignRequestBody
  >;
  bidMutation: UseMutationResult<
    OfferResponse,
    AxiosError,
    AssignRequestBody['values']
  >;
  acceptMutation: UseMutationResult<OfferResponse, AxiosError, number>;
  watchAssignedOffersForm: UseFormWatch<OffersFormValues>;
  watchNewOffersForm: UseFormWatch<OffersFormValues>;
  requiredLeadsCountPerMilestone: LeadsRequiredPerMilestoneDictionary;
  offerId?: number;
  offerInitialData?: OfferFormValues;
  updateAssignedOfferAfterSubmit: (offer: OfferResponse) => void;
};

const VMOfferItem: FC<Props> = ({
  status,
  children,
  assets,
  campaign,
  assignedLeadsBySavedAssets,
  bidMutation,
  acceptMutation,
  index,
  watchNewOffersForm,
  watchAssignedOffersForm,
  requiredLeadsCountPerMilestone,
  assignMutation,
  offerId,
  offerInitialData,
  updateAssignedOfferAfterSubmit,
}) => {
  const [isRebidActive, setIsRebidActive] = useState<boolean>(false);
  const { t } = useTranslation();
  const { convertMilestoneDate } = useConvertCampaignData();
  const {
    control,
    trigger,
    watch,
    formState,
    unregister,
    clearErrors,
    setError,
  } = useFormContext<OffersFormValues>();
  const { fields } = useFieldArray({
    control: control,
    name: `offers.${index}.value.campaign_offer_milestones`,
  });

  const { field: cplField } = useController({
    control: control,
    name: `offers.${index}.value.cpl`,
  });
  const { field: vendorCplField } = useController({
    control: control,
    name: `offers.${index}.value.cpl_vendor`,
  });
  const { field: noPreferenceField } = useController({
    name: `offers.${index}.value.no_preference`,
    control: control,
  });
  const { field: commentField } = useController({
    name: `offers.${index}.value.comment`,
    control: control,
  });

  const isCPLError = cplField.value > campaign.campaign_details.cost_per_lead;
  useEffect(() => {
    if (isCPLError) {
      setError(`offers.${index}.value.cpl`, {
        message: '',
      });
    } else {
      clearErrors(`offers.${index}.value.cpl`);
    }
  }, [cplField.value]);

  const handleNoPreferenceChange = (isChecked: boolean) => {
    if (!isChecked) {
      fields.forEach((field, milestoneIndex) => {
        field.campaign_offer_assets?.forEach((asset, assetIndex) => {
          unregister(
            `offers.${index}.value.campaign_offer_milestones.${milestoneIndex}.campaign_offer_assets.${assetIndex}.leads_required`
          );
        });
      });
    }

    noPreferenceField.onChange(isChecked);
  };

  const isCounterbidStatus = status === OfferStatuses.counterbid;
  const isAcceptActionsVisible = isCounterbidStatus && !isRebidActive;
  const isAssignButtonVisible = !status;

  const isActionButtonsVisible =
    !isMobile &&
    (isAcceptActionsVisible || isAssignButtonVisible || isRebidActive);

  const isEditable = !isMobile && (isRebidActive || !status);

  const isAcceptDisabled =
    (vendorCplField.value || cplField.value || 0) >
    campaign.campaign_details.cost_per_lead;
  const isNoPreferenceAvailable =
    campaign.campaign_details.asset_distribution ===
      AssetDistributionType['no-preference'] && assets.length > 1;
  const lastRespondedBy = watch(`offers.${index}.value.last_responded_by`);
  const lastComment =
    lastRespondedBy === UserTypes.vendorManager
      ? watch(`offers.${index}.value.comment`)
      : watch(`offers.${index}.value.comment_vendor`);

  const isErrors = formState.errors.offers?.[index];
  const milestoneError = isErrors?.value?.campaign_offer_milestones?.message;
  const isDemoCampaign = campaign.campaign_details.cost_per_lead === 0;

  return (
    <div className={styles.card} aria-label="offer">
      <h2 className={styles.name}>{children}</h2>

      <div className={styles.cpl_wrapper}>
        <div className={styles.cpl}>
          <div className={styles.cpl_field}>
            <InputWrapper
              label={isEditable ? 'campaign.set-cpl' : 'common.field.cpl'}
              isRequired={isEditable && !isRebidActive}
              validationError={isErrors?.value?.cpl?.message}
              wrapperClasses={cn({
                [styles['label-error']]: isEditable && isCPLError,
              })}
            >
              <NumberField
                field={cplField}
                placeholder="$"
                disabled={isDemoCampaign || !isEditable}
                max={campaign.campaign_details.cost_per_lead}
              />
            </InputWrapper>
            {isEditable && (
              <span
                className={cn(styles.cpl_max, { [styles.error]: isCPLError })}
              >
                {isCPLError && (
                  <IconSVG name={IconsNames.error} className={styles.icon} />
                )}
                {t('common.error.max')}:{' '}
                {campaign.campaign_details.cost_per_lead}
              </span>
            )}

            {status && !isRebidActive && (
              <OfferStatus status={status} className={styles.status} />
            )}
          </div>
        </div>
      </div>
      {isRebidActive && (
        <InputWrapper
          label="offers.additional-comments"
          isRequired
          validationError={isErrors?.value?.comment?.message}
        >
          <TextareaAutoHeight
            methods={{
              ...commentField,
              onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (isErrors?.value?.comment?.message) {
                  trigger(`offers.${index}.value.comment`);
                }
                commentField.onChange(e);
              },
            }}
            control={control}
            placeholder={t('offers.why-rebid')}
          />
        </InputWrapper>
      )}

      <div className={styles.milestones}>
        <div className={styles['milestones-header']}>
          {isNoPreferenceAvailable && (
            <InputWrapper
              wrapperClasses={styles.preference}
              label="asset-distribution.no-preference"
            >
              <ToggleSwitch
                id={`offers-${index}-no-preference`}
                onChange={handleNoPreferenceChange}
                checked={noPreferenceField.value}
                disabled={!isEditable}
              />
            </InputWrapper>
          )}
          {milestoneError && (
            <Error
              message={t(milestoneError)}
              className={styles['milestone-error']}
            />
          )}
        </div>
        {fields.map((field, milestoneIndex) =>
          field.milestone ? (
            <MilestoneVMOfferItem
              offerIndex={index}
              index={milestoneIndex}
              key={field.id}
              milestone={field}
              milestoneId={field.milestone.id}
              assets={assets}
              isEditable={isEditable}
              assignedLeadsBySavedAssets={assignedLeadsBySavedAssets}
              watchAssignedOffersForm={watchAssignedOffersForm}
              watchNewOffersForm={watchNewOffersForm}
              requiredLeadsCountPerMilestone={requiredLeadsCountPerMilestone}
              assetDistribution={campaign.campaign_details.asset_distribution}
            >
              {convertMilestoneDate(
                campaign.campaign_delivery.milestone_deadline_type,
                field.milestone.delivery_date,
                milestoneIndex === fields.length - 1
              )}
            </MilestoneVMOfferItem>
          ) : null
        )}
      </div>

      {isActionButtonsVisible && (
        <ActionButtons
          isAssignButtonVisible={isAssignButtonVisible}
          isRebidButtonVisible={isRebidActive}
          isAcceptActionsVisible={isAcceptActionsVisible}
          offerIndex={index}
          campaignName={campaign.campaign_details.name}
          campaignId={campaign.id}
          handleAccept={id => acceptMutation?.mutate(id)}
          handleRebid={values => {
            bidMutation?.mutate(values, {
              onSettled: (_, error) => {
                if (!error) setIsRebidActive(prev => !prev);
              },
            });
          }}
          isRebidActive={isRebidActive}
          setIsRebidActive={setIsRebidActive}
          handleAssign={requestBody => {
            if (assignMutation) assignMutation.mutate(requestBody);
          }}
          disabledAcceptMessage={
            isAcceptDisabled ? t('offers.cpl-higher-max') : undefined
          }
          isAssignRebidDisabled={isCPLError}
          offerInitialData={offerInitialData}
          isDemoCampaign={isDemoCampaign}
        />
      )}
      {!!status && offerId && (
        <div className={styles.history}>
          {lastRespondedBy && lastComment && (
            <p className={styles.comment}>{lastComment}</p>
          )}

          <History
            isAccepted={status === OfferStatuses.accepted}
            isAwaitingApprove={status === OfferStatuses['wait-vendor-approve']}
            offerId={offerId}
            campaignId={campaign.id}
            milestoneDeadlineType={
              campaign.campaign_delivery.milestone_deadline_type
            }
            updateAssignedOfferAfterSubmit={updateAssignedOfferAfterSubmit}
          />
        </div>
      )}
    </div>
  );
};

export default VMOfferItem;
