import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import type { PaginationReturn } from 'types/models';
import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';

import { UserTypes, LeadStatus } from 'constants/constants';
import { PAGE_SIZE } from 'components/CampaignsListDeliveries/CampaignDetails';

export enum ContactStatus {
  pending = 'pending',
  sent = 'sent',
  clicked = 'clicked',
  'submitted-answers' = 'submitted-answers',
  failed = 'failed',
}

export type Contact = {
  email: string;
  company: string;
  linkedin_contact_profile_link: string;
  linkedin_company_profile_link: string;
  delivery_status: ContactStatus;
  campaign_lead_status: LeadStatus;
  sent_at: null | string;
  date_of_scoring: string;
  campaign_lead_disqualify_reasons?: string[];
};

export const useGetCampaignContacts = (page: number) => {
  const { axios, user } = useAuth();
  const { id } = useParams();

  const query = useQuery<PaginationReturn<Contact[]>, AxiosError>(
    ['vendor-contacts-list', user?.current_group_name, page, id],
    async () => {
      try {
        const { data } = await axios.get<PaginationReturn<Contact[]>>(
          `/vendor-campaigns/${id}/contacts/?page_size=${PAGE_SIZE}&page=${page}`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled: user?.current_group_name === UserTypes.vendor,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
