import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { AxiosError } from 'axios';

import { UserTypes } from 'constants/constants';

import useAuth, { User } from 'contexts/AuthContext';

import styles from './index.module.scss';

export type InviteData = {
  email: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  group_name: UserTypes;
  invited_name: string;
};

const useInvite = () => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();
  const queryClient = useQueryClient();

  const mutationData = useMutation<User, AxiosError, InviteData>(
    async userData => {
      try {
        const companyId = user?.company.id;
        if (!companyId) throw new Error('Company id is missed');

        const { data } = await axios.post<User>(
          `/companies/${user.company.id}/invite/`,
          userData
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async data => {
        await queryClient.refetchQueries([
          'invitesList',
          user?.current_group_name,
        ]);

        {
          return !!data.vendor_exists
            ? toast.success(
                <h1 className={styles.title}>
                  {t('invites.vendor-is-invited')}
                  <span>{t('invites.you-can-assign-campaign')}</span>
                </h1>,
                {
                  className: styles.successToast,
                  autoClose: false,
                }
              )
            : toast.success(<h1>{t('invites.success')}</h1>, {
                className: styles.successToast,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
              });
        }
      },
    }
  );

  return mutationData;
};

export default useInvite;
