import * as Yup from 'yup';

import {
  REQUIRED_MESSAGE,
  MAX_LENGTH_MESSAGE,
  DEFAULT_CHARFIELD_MAX_LENGTH,
  INVALID_VALUE,
  INVALID_URL_ERROR_MESSAGE,
} from 'utils/validations';

export const SCHEDULE_DEMO_MAX_CHARACTERS_AMOUNT = 20;
const PRODUCT_DESCRIPTION_MAX_LENGTH = 1000;

const FACEBOOK_LINK_REGEXP = /^$|https:\/\/(www\.)?facebook\.com/;
const LINKEDIN_LINK_REGEXP = /^$|https:\/\/(www\.)?linkedin\.com/;
const TWITTER_LINK_REGEXP = /^$|https:\/\/(www\.)?twitter\.com/;
const INSTAGRAM_LINK_REGEXP = /^$|https:\/\/(www\.)?instagram\.com/;

export const validationSchemaCreateProduct = Yup.lazy(() =>
  Yup.object().shape({
    name: Yup.string().required(REQUIRED_MESSAGE),
    description: Yup.string().max(PRODUCT_DESCRIPTION_MAX_LENGTH, {
      key: MAX_LENGTH_MESSAGE,
      values: { length: PRODUCT_DESCRIPTION_MAX_LENGTH },
    }),
    email: Yup.string()
      .email(INVALID_VALUE)
      .max(DEFAULT_CHARFIELD_MAX_LENGTH, {
        key: MAX_LENGTH_MESSAGE,
        values: { length: DEFAULT_CHARFIELD_MAX_LENGTH },
      }),
    website: Yup.string()
      .url(INVALID_URL_ERROR_MESSAGE)
      .max(DEFAULT_CHARFIELD_MAX_LENGTH, {
        key: MAX_LENGTH_MESSAGE,
        values: { length: DEFAULT_CHARFIELD_MAX_LENGTH },
      }),
    linkedin_link: Yup.string()
      .max(DEFAULT_CHARFIELD_MAX_LENGTH, {
        key: MAX_LENGTH_MESSAGE,
        values: { length: DEFAULT_CHARFIELD_MAX_LENGTH },
      })
      .matches(LINKEDIN_LINK_REGEXP, INVALID_URL_ERROR_MESSAGE),
    facebook_link: Yup.string()
      .max(DEFAULT_CHARFIELD_MAX_LENGTH, {
        key: MAX_LENGTH_MESSAGE,
        values: { length: DEFAULT_CHARFIELD_MAX_LENGTH },
      })
      .matches(FACEBOOK_LINK_REGEXP, INVALID_URL_ERROR_MESSAGE),
    instagram_link: Yup.string()
      .max(DEFAULT_CHARFIELD_MAX_LENGTH, {
        key: MAX_LENGTH_MESSAGE,
        values: { length: DEFAULT_CHARFIELD_MAX_LENGTH },
      })
      .matches(INSTAGRAM_LINK_REGEXP, INVALID_URL_ERROR_MESSAGE),
    twitter_link: Yup.string()
      .max(DEFAULT_CHARFIELD_MAX_LENGTH, {
        key: MAX_LENGTH_MESSAGE,
        values: { length: DEFAULT_CHARFIELD_MAX_LENGTH },
      })
      .matches(TWITTER_LINK_REGEXP, INVALID_URL_ERROR_MESSAGE),
    privacy_policy_url: Yup.string()
      .required(REQUIRED_MESSAGE)
      .url(INVALID_URL_ERROR_MESSAGE),
    schedule_demo_button_name: Yup.string().max(
      SCHEDULE_DEMO_MAX_CHARACTERS_AMOUNT,
      {
        key: MAX_LENGTH_MESSAGE,
        values: { length: SCHEDULE_DEMO_MAX_CHARACTERS_AMOUNT },
      }
    ),
    schedule_demo_link: Yup.string()
      .url(INVALID_URL_ERROR_MESSAGE)
      .max(DEFAULT_CHARFIELD_MAX_LENGTH, {
        key: MAX_LENGTH_MESSAGE,
        values: { length: DEFAULT_CHARFIELD_MAX_LENGTH },
      }),
  })
);
