import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';
import type { CampaignResponse } from 'types/models';

import ModalWindow from 'components/common/ModalWindow';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import { UserTypes } from 'constants/constants';
import { PrivatePaths } from 'constants/routes';

export const useGetCampaign = (campaignId?: string | number) => {
  const { axios, user } = useAuth();
  const { openModal } = useModal();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isVendor = user?.current_group_name === UserTypes.vendor;

  const getCampaignRequest = useQuery<
    | CampaignResponse
    | (CampaignResponse & {
        company_name: string;
        offer_cost_per_lead: number;
        contacts: number;
        pending_leads: number;
        qualified_leads: number;
        disqualified_leads: number;
        clicked_contacts: number;
      }),
    AxiosError
  >(
    [
      'campaign',
      campaignId,
      user?.current_group_name,
      user?.company.id.toString(),
    ],
    async () => {
      try {
        if (!campaignId) {
          navigate(`${PrivatePaths.INDEX}`);
        }
        const url = isVendor
          ? `/vendor-campaigns/${campaignId}/`
          : `/campaigns/${campaignId}/`;
        const { data } = await axios.get<
          | CampaignResponse
          | (CampaignResponse & {
              company_name: string;
              offer_cost_per_lead: number;
            })
        >(url);

        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      retry: (count, error) => {
        return error.response?.status !== 403 && error.response?.status !== 404;
      },
      refetchOnWindowFocus: false,
      onError: err => {
        if (err.response?.status !== 404 && err.response?.status !== 403) {
          openModal({
            Content: (
              <ModalWindow
                title={t('common.error.something-went-wrong')}
                errorMessage={getResponseError(err)}
              />
            ),
          });
        }

        navigate(`${PrivatePaths.INDEX}`);
      },
      enabled: !!user?.id,
    }
  );

  return getCampaignRequest;
};
