import { useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import InputWrapper from 'components/common/InputWrapper';

import { checkIsHasVariables } from 'utils/richTextEditor';

import styles from '../index.module.scss';

type Props = {
  index: number;
};

const DisplayOnAssetPage = ({ index }: Props) => {
  const formSettings = useFormContext<CreateCampaignValues>();
  const { register, control, setValue } = formSettings || {};
  const question = useWatch({
    control,
    name: `script_builder.questions.${index}`,
  });
  const isDisabled =
    checkIsHasVariables(question.name) ||
    question.answers.some(answer => checkIsHasVariables(answer.name));

  useEffect(() => {
    if (isDisabled && question.display_on_asset_page) {
      setValue(
        `script_builder.questions.${index}.display_on_asset_page`,
        false
      );
    }
  }, [isDisabled]);

  return (
    <InputWrapper
      label="common.field.display-on-asset-page"
      isErrorHidden
      wrapperClasses={styles.visibility}
    >
      <input
        type="checkbox"
        disabled={isDisabled}
        {...register?.(
          `script_builder.questions.${index}.display_on_asset_page`
        )}
      />
    </InputWrapper>
  );
};

export default DisplayOnAssetPage;
