import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';
import type { TALFileResponse } from 'types/models';

import ModalWindow from 'components/common/ModalWindow';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

const useGetTAL = (campaignId?: string | number) => {
  const { axios } = useAuth();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const getTALRequestSettings = useQuery<TALFileResponse[], AxiosError>(
    ['campaign', campaignId, 'TAL'],
    async () => {
      try {
        const { data } = await axios.get<TALFileResponse[]>(
          `/campaigns/${campaignId}/target-account-lists/`
        );

        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: !!campaignId,
      refetchOnWindowFocus: false,
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );
  return getTALRequestSettings;
};

export default useGetTAL;
