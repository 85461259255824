import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import ModalWindow from 'components/common/ModalWindow';

import useModal from 'contexts/ModalContext';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  instanceName: string;
  itemName: string;
  onDelete: () => void;
  onCancel?: () => void;
  className?: string;
};

const DeleteConfirmationModal = ({
  instanceName,
  onDelete,
  itemName,
  onCancel,
  className,
}: Props) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  return (
    <ModalWindow
      className={cn(styles.modal, className)}
      primaryTitle={`${t('common.button.delete')} ${instanceName}?`}
      description={t('common.modal.will-be-deleted', {
        name: itemName,
      })}
      negativeButtonProps={{
        children: t('common.button.delete'),
        iconProps: { name: IconsNames.trash },
        type: 'button',
        red: true,
        onClick: () => {
          onDelete();
        },
      }}
      positiveButtonProps={{
        children: t('common.button.cancel'),
        type: 'button',
        onClick: onCancel || closeModal,
        white: true,
      }}
      onClose={onCancel}
      buttonsWrapperClassName={styles.buttons}
    />
  );
};

export default DeleteConfirmationModal;
