import { useTranslation } from 'react-i18next';

const useTranslateSelectOptions = () => {
  const { t } = useTranslation();

  const translate = <T extends string>(list: T[], keyPrefix?: string) => {
    const isPrefix = keyPrefix || keyPrefix === '';
    const options = list.map(option => ({
      value: option,
      label: t(`${isPrefix ? keyPrefix : 'common.field.'}${option}`),
    }));

    return options;
  };

  return translate;
};

export default useTranslateSelectOptions;
