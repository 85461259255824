import { InputHTMLAttributes, useRef, useEffect, useState } from 'react';

import type { ControllerRenderProps } from 'react-hook-form';

import styles from './index.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  formSettings: any;
  field: Partial<ControllerRenderProps>;
}

const findFieldByType = (
  type: string,
  array: google.maps.GeocoderAddressComponent[]
) => array.find(field => field.types.includes(type));

const AddressField = ({ formSettings, field }: Props) => {
  const [value, setValue] = useState(field.value);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const ref = useRef(null);

  const handlePlaceSelect = () => {
    formSettings.clearErrors([
      'company.address',
      'company.country_code',
      'company.city',
    ]);
    const address = autocomplete?.getPlace().address_components;
    if (address) {
      const streetAddress = `${
        findFieldByType('street_number', address)?.long_name || ''
      } ${findFieldByType('route', address)?.long_name}`;
      setValue(streetAddress);
      formSettings.setValue('company.address', streetAddress);
      formSettings.setValue(
        'company.city',
        findFieldByType('locality', address)?.long_name ||
          findFieldByType('postal_town', address)?.long_name ||
          findFieldByType('administrative_area_level_2', address)?.long_name
      );
      const country = findFieldByType('country', address);
      const countryCode = country?.short_name;
      formSettings.setValue('company.country_code', {
        label: country?.long_name,
        value: countryCode,
      });
      if (countryCode === 'US' || countryCode === 'CA') {
        formSettings.setValue(
          'company.state',
          findFieldByType('administrative_area_level_1', address)?.long_name
        );
      }
    }
  };

  useEffect(() => {
    if (ref.current) {
      setAutocomplete(
        new google.maps.places.Autocomplete(ref.current, {
          fields: ['address_components'],
          types: ['address'],
        })
      );
    }
  }, [ref.current]);

  useEffect(() => {
    if (autocomplete && typeof autocomplete.addListener === 'function') {
      autocomplete.addListener('place_changed', handlePlaceSelect);
    }
  }, [autocomplete]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    formSettings.setValue('company.address', '');
    formSettings.setValue('company.country_code', null);
    formSettings.setValue('company.state', '');
    formSettings.setValue('company.city', '');
  };

  return (
    <div>
      <input
        className={styles.input}
        type="text"
        autoComplete="new-address-input"
        ref={ref}
        value={value}
        onChange={handleInputChange}
        placeholder=""
      />
    </div>
  );
};

export default AddressField;
