import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import type { AxiosError } from 'axios';
import type { CampaignResponse } from 'types/models';

import ModalWindow from 'components/common/ModalWindow';
import Loader from 'components/common/LoaderScreen/Loader';
import NoEnoughCreditsModal from 'components/PaymentsComponents/NoEnoughCreditsModal';
import Button from 'components/common/Button';

import { PrivatePaths } from 'constants/routes';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

type Props = {
  readOnly: boolean;
  campaignId: number;
};

const CopyCell = ({ readOnly, campaignId }: Props) => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();
  const { openModal } = useModal();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation<CampaignResponse, AxiosError>(
    async () => {
      try {
        const { data } = await axios.post<CampaignResponse>(
          `/campaigns/${campaignId}/duplicate/`
        );

        return data;
      } catch (error) {
        throw error;
      }
    },

    {
      onSuccess: async data => {
        await queryClient.invalidateQueries([
          'campaign-list',
          user?.current_group_name,
        ]);

        openModal({
          Content: (
            <ModalWindow
              title={data.campaign_details.name}
              successMessage={t('campaign.campaign-copied-successfully')}
            />
          ),
        });
      },
      onError: error => {
        if (error.response?.status === 402) {
          navigate(`/${PrivatePaths.PAYMENTS}`);
          openModal({
            Content: <NoEnoughCreditsModal error={error} />,
          });
          return;
        }
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(error)}
            />
          ),
        });
      },
      retry: (_, error) => error.response?.status !== 402,
    }
  );

  const duplicateCampaign = () => mutate();

  return isLoading ? (
    <Loader size={20} className={styles.loader} />
  ) : (
    <Button
      white
      className={styles.copy}
      type="button"
      disabled={readOnly}
      onClick={duplicateCampaign}
    >
      <span>{t('common.button.duplicate')}</span>
    </Button>
  );
};

export default CopyCell;
