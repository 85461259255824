import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import cn from 'classnames';

import type { ProductFormValues } from 'components/CreateProductContent/models';
import type { AxiosError } from 'axios';

import UploadFile from 'components/common/UploadFile';
import InputWrapper from 'components/common/InputWrapper';
import CropImageModal from 'components/common/CropImageModal';

import useModal from 'contexts/ModalContext';

import styles from './index.module.scss';

type Props = {
  isFirstStepDone: boolean;
  error?: AxiosError | null;
};

const MAX_FILES_SIZE = 3145728; // 3mb
const LOGO_SIZE = 144;

const TopInfo: React.FC<Props> = ({ isFirstStepDone, error }) => {
  const { t } = useTranslation();
  const { register, control, formState } = useFormContext<ProductFormValues>();
  const { openModal } = useModal();

  const {
    field: { value: BGImage, onChange: onChangeBGImage },
  } = useController({ control, name: 'background_image' });

  const insertBgPhoto = (
    file: File | null,
    renderLink: string | ArrayBuffer | null | undefined
  ) => {
    openModal({
      Content: (
        <CropImageModal
          image={renderLink}
          title={t('manage-products.cover-image')}
          imageHeight={300}
          imageWidth={1128}
          uploadImage={onChangeBGImage}
          file={file}
          objectFit="horizontal-cover"
        />
      ),
    });
  };

  const {
    field: { value: logotype, onChange: onChangeLogotype },
  } = useController({ control, name: 'product_photo' });

  const insertLogotype = (
    file: File | null,
    renderLink: string | ArrayBuffer | null | undefined
  ) => {
    openModal({
      Content: (
        <CropImageModal
          image={renderLink}
          title={t('manage-products.logotype')}
          imageHeight={LOGO_SIZE}
          imageWidth={LOGO_SIZE}
          uploadImage={onChangeLogotype}
          cropSize={{ width: LOGO_SIZE, height: LOGO_SIZE }}
        />
      ),
    });
  };

  return (
    <section
      className={cn(styles.topInfo, { [styles.disabled]: isFirstStepDone })}
    >
      <UploadFile
        accept={['image/jpeg', 'image/png', 'image/svg']}
        imageSrc={typeof BGImage === 'string' ? BGImage : BGImage?.renderLink}
        wrapperClasses={styles.bgImageUpload}
        inputClasses={styles.bgInput}
        maxSize={MAX_FILES_SIZE}
        secondaryTitle={t('manage-products.cover-image')}
        onChangeOptions={{
          maxFiles: 1,
          insertSingle: insertBgPhoto,
          withPreview: true,
        }}
        backgroundImageClassName={styles.preview}
      />

      <div className={styles.container}>
        <UploadFile
          accept={['image/jpeg', 'image/png', 'image/svg']}
          wrapperClasses={styles.logoUpload}
          secondaryTitle={t('manage-products.logotype')}
          maxSize={MAX_FILES_SIZE}
          errorFilledImageClassName={styles.error}
          imageSrc={
            typeof logotype === 'string' ? logotype : logotype?.renderLink
          }
          onChangeOptions={{
            maxFiles: 1,
            insertSingle: insertLogotype,
            withPreview: true,
          }}
          backgroundImageClassName={styles.preview}
        />

        <InputWrapper
          wrapperClasses={styles.input}
          validationError={formState.errors?.name?.message}
          submitError={error?.response?.data?.name?.[0]}
          isMediumInput
        >
          <input
            type="text"
            placeholder={t('manage-products.title-placeholder')}
            {...register('name')}
            aria-label="product name"
          />
        </InputWrapper>
      </div>
    </section>
  );
};

export default TopInfo;
