import { FC } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import type { CampaignAssetFileResponse } from 'types/models';
import type {
  OffersFormValues,
  CampaignOfferAsset,
} from 'components/CampaignManage/models';

import MilestoneItem from 'components/CampaignManage/OfferItem/MilestoneItem';
import MilestoneAssetItem from 'components/CampaignManage/OfferItem/MilestoneAssetItem';

type Props = {
  isEditable?: boolean;
  offerIndex: number;
  index: number;
  assets: CampaignAssetFileResponse[];
  milestoneId: number;
};

const MilestoneOfferItem: FC<Props> = ({
  isEditable = false,
  children,
  index,
  offerIndex,
  assets,
  milestoneId,
}) => {
  const { control, watch } = useFormContext<OffersFormValues>();
  const { field: milestoneField } = useController({
    control,
    name: `offers.${offerIndex}.value.campaign_offer_milestones.${index}`,
  });

  const difference =
    (milestoneField.value?.leads_required || 0) -
    (milestoneField.value?.leads_required_vendor || 0);

  const renderMilestoneAssetItem = ({
    asset,
    assetIndex,
    isError,
  }: {
    asset: CampaignOfferAsset;
    assetIndex: number;
    isError: boolean;
  }) => {
    const leadsRequiredValue = watch(
      `offers.${offerIndex}.value.campaign_offer_milestones.${index}.campaign_offer_assets.${assetIndex}.leads_required_vendor`
    );
    const vendorDifference =
      (asset.leads_required || 0) - (leadsRequiredValue || 0);

    return (
      <MilestoneAssetItem
        isEditable={isEditable}
        offerIndex={offerIndex}
        milestoneIndex={index}
        assetIndex={assetIndex}
        assetTitle={asset.asset_title}
        leadsFieldName="leads_required_vendor"
        difference={vendorDifference}
        isLeadsSumError={isError}
      />
    );
  };

  const isNoPreference = watch(`offers.${offerIndex}.value.no_preference`);

  return (
    <MilestoneItem
      offerIndex={offerIndex}
      index={index}
      difference={difference}
      milestoneId={milestoneId}
      leadsFieldName="leads_required_vendor"
      assets={assets}
      isEditable={isEditable}
      renderMilestoneAssetItem={
        isNoPreference || assets.length === 1
          ? undefined
          : renderMilestoneAssetItem
      }
      isAssetsSelectorHidden
      isSaveNullableAssets
    >
      {children}
    </MilestoneItem>
  );
};

export default MilestoneOfferItem;
