import { useQuery } from 'react-query';
import axios from 'axios';

import type { ProductAssetResponse } from 'types/models';
import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';

import { AssetContentType, ProductAssetsOrdering } from 'constants/assets';

export const useGetAssetsByContentType = ({
  productSlug,
  productId,
  companySlug,
  enabled,
  contentType,
  isPublic = false,
  productOrdering,
  keepPreviousData,
}: {
  productSlug?: string | null;
  companySlug?: string;
  contentType: AssetContentType;
  productId?: number;
  enabled?: boolean;
  isPublic?: boolean;
  productOrdering?: ProductAssetsOrdering;
  keepPreviousData?: boolean;
}) => {
  const { axios: axiosInstance } = useAuth();
  const query = useQuery<ProductAssetResponse[], AxiosError>(
    [
      'get-product-assets',
      contentType,
      companySlug,
      productId,
      productOrdering,
    ],
    async () => {
      try {
        const { data } = await (isPublic ? axios : axiosInstance).get<
          ProductAssetResponse[]
        >(
          `${
            isPublic ? process.env.REACT_APP_BACKEND_URL : ''
          }/companies/${companySlug}/products/${productSlug}/assets/?content_type=${contentType}&ordering=${
            productOrdering || ProductAssetsOrdering.custom
          }`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled: !!companySlug && !!productId && enabled && !!productSlug,
      refetchOnWindowFocus: false,
      keepPreviousData,
    }
  );

  return query;
};
