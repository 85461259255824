import { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import type { InputActionMeta, ActionMeta } from 'react-select';
import type { SelectOption } from 'types/models';

import Menu from 'components/common/ChooseOptionsSelect/Menu';

import { selectStyles } from 'utils/selectStyles';

type Props = {
  firstOptions: { options: SelectOption[]; label: string };
  secondOptions: { options: SelectOption[]; label: string };
  value?: any;
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
  onBlur?: (value: any) => void;
  defaultValue?: SelectOption;
  isClearable?: boolean;
  name?: string;
};

const ChooseOptionsSelect = ({
  secondOptions,
  firstOptions,
  value,
  onChange,
  onBlur,
  defaultValue,
  isClearable,
  name,
}: Props) => {
  const { t } = useTranslation();
  const [isSecondOption, setSecondOption] = useState(false);
  const [searchCount, setSearchCount] = useState({
    second: 0,
    first: 0,
  });

  const onInputChange = (inputValue: string, { action }: InputActionMeta) => {
    const isActiveSearch = action === 'input-change' || action === 'set-value';

    if (isActiveSearch) {
      const currentInput = inputValue.toLowerCase();

      const filterOptions = (options: { value: string }[]) =>
        options.filter(option =>
          option.value.toLowerCase().match(currentInput)
        );

      const firstFilteredItems = filterOptions(firstOptions.options);
      const secondFilteredItems = filterOptions(secondOptions.options);

      setSearchCount({
        second: secondFilteredItems.length,
        first: firstFilteredItems.length,
      });
    }
  };

  return (
    <Select
      styles={selectStyles}
      placeholder={t('common.field.search-here')}
      value={value}
      onChange={onChange}
      options={isSecondOption ? secondOptions.options : firstOptions.options}
      onInputChange={onInputChange}
      onBlur={onBlur}
      defaultValue={defaultValue}
      noOptionsMessage={() => t('common.field.no-options')}
      isClearable={isClearable}
      name={name}
      components={{
        Menu: props => (
          <Menu
            {...props}
            setIsSecondOptions={setSecondOption}
            isSecondOptions={isSecondOption}
            searchCount={searchCount}
            firstLabel={firstOptions.label}
            secondLabel={secondOptions.label}
          />
        ),
      }}
    />
  );
};

export default ChooseOptionsSelect;
