import cn from 'classnames';
import Select from 'react-select';
import { useState } from 'react';

import type { ProductAssetResponse, SelectOption } from 'types/models';

import DownloadForm from 'components/AssetDownloadContent/DownloadForm';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import {
  PROD_QUESTIONS_COMPANY_SLUG,
  PROD_QUESTIONS_PRODUCT_SLUG,
  PROD_QUESTIONS_ASSET_SLUGS,
  PROD_SELECT_OPTIONS,
} from 'constants/assets';

import defaultImage from 'assets/images/product/default-image.svg';

import styles from './index.module.scss';

type Props = {
  data: ProductAssetResponse;
  isModalContent?: boolean;
  setIsVideoPreview: React.Dispatch<React.SetStateAction<boolean>>;
  companySlug?: string;
  productSlug?: string;
  assetSlug?: string;
  contactShareId?: string;
};

const AssetContentPreview = ({
  data,
  isModalContent,
  setIsVideoPreview,
  companySlug,
  productSlug,
  assetSlug,
  contactShareId,
}: Props) => {
  const [selectValue, setSelectValue] = useState<SelectOption | null>(null);

  // TODO: delete this terrible crutch when script builder will be done
  const isQuestionVisible =
    companySlug === PROD_QUESTIONS_COMPANY_SLUG &&
    PROD_QUESTIONS_PRODUCT_SLUG.some(slug => slug === productSlug) &&
    PROD_QUESTIONS_ASSET_SLUGS.some(slug => slug === assetSlug);
  const isDownloadDisabled = isQuestionVisible && !selectValue;

  const isAssetAvailable = data.attached_file || data.attached_file_link;

  return (
    <>
      <div
        className={cn(styles.container, {
          [styles.skipShadow]: isModalContent,
        })}
      >
        <div className={styles.row}>
          {!!data.html_template_logo && (
            <img src={data.html_template_logo} className={cn(styles.logo)} />
          )}
          <div className={styles.saved}>
            <img
              src={data.landing_image || defaultImage}
              alt={data.title}
              className={cn(styles.preview, {
                [styles.default]: !data.landing_image,
              })}
            />
          </div>
        </div>

        <div
          className={cn(styles.row, { [styles.scrollable]: isModalContent })}
        >
          <h1 className={styles.title}>{data.title}</h1>
          <p className={styles.text}>{data.description}</p>

          {isQuestionVisible && (
            <>
              <p className={styles.question}>
                Does your company plan to upgrade or replace its current
                Workforce Management, HR Payroll system? If so, when?
              </p>
              <Select
                options={PROD_SELECT_OPTIONS}
                isSearchable={false}
                styles={getDefaultSelectStylesWithError({ isSmall: true })}
                value={selectValue}
                onChange={setSelectValue}
                isClearable={false}
                placeholder="Select"
              />
            </>
          )}

          {((Array.isArray(data.script_builder_questions) &&
            !isAssetAvailable) ||
            isAssetAvailable) && (
            <DownloadForm
              isDownloadDisabled={isDownloadDisabled}
              data={data}
              setIsVideoPreview={setIsVideoPreview}
              isModalContent={isModalContent}
              companySlug={companySlug}
              productSlug={productSlug}
              assetSlug={assetSlug}
              contactShareId={contactShareId}
              isAssetAvailable={!!isAssetAvailable}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AssetContentPreview;
