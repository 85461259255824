import React from 'react';
import cn from 'classnames';

import { ReactComponent as ArrowSVG } from 'assets/images/common/arrow.svg';
import { ReactComponent as CalendarSVG } from 'assets/images/common/calendar.svg';
import { ReactComponent as CheckMarkSVG } from 'assets/images/common/check-mark.svg';
import { ReactComponent as CopySVG } from 'assets/images/common/copy.svg';
import { ReactComponent as DeleteSVG } from 'assets/images/common/delete.svg';
import { ReactComponent as DownloadSVG } from 'assets/images/common/download.svg';
import { ReactComponent as DropdownIndicatorSVG } from 'assets/images/common/dropdown-indicator.svg';
import { ReactComponent as EditSVG } from 'assets/images/common/edit.svg';
import { ReactComponent as ErrorSVG } from 'assets/images/common/error.svg';
import { ReactComponent as FullScreenSVG } from 'assets/images/common/full-screen.svg';
import { ReactComponent as LocationSVG } from 'assets/images/common/location.svg';
import { ReactComponent as PhoneSVG } from 'assets/images/common/phone.svg';
import { ReactComponent as PlusSVG } from 'assets/images/common/plus.svg';
import { ReactComponent as RocketManSVG } from 'assets/images/common/rocket-man.svg';
import { ReactComponent as RocketSVG } from 'assets/images/common/rocket.svg';
import { ReactComponent as SelectArrowSVG } from 'assets/images/common/select-arrow.svg';
import { ReactComponent as StarSVG } from 'assets/images/common/star.svg';
import { ReactComponent as SuccessCheckmarkSVG } from 'assets/images/common/success-checkmark.svg';
import { ReactComponent as TrashSVG } from 'assets/images/common/trash.svg';
import { ReactComponent as UploadFileSVG } from 'assets/images/common/upload-file.svg';
import { ReactComponent as RightArrowSVG } from 'assets/images/common/right-arrow.svg';
import { ReactComponent as EyeSVG } from 'assets/images/common/eye.svg';
import { ReactComponent as CopySuccessSVG } from 'assets/images/common/copy-success.svg';
import { ReactComponent as LogotypeSVG } from 'assets/images/common/logotype.svg';
import { ReactComponent as RoundPlusSVG } from 'assets/images/common/round-plus.svg';
import { ReactComponent as CloseSVG } from 'assets/images/common/close.svg';
import { ReactComponent as DocumentDownloadSVG } from 'assets/images/common/document-download.svg';
import { ReactComponent as SpinnerSVG } from 'assets/images/common/spinner.svg';
import { ReactComponent as PauseSVG } from 'assets/images/common/pause.svg';
import { ReactComponent as DotsSVG } from 'assets/images/common/dots.svg';
import { ReactComponent as WebsiteSVG } from 'assets/images/common/website.svg';
import { ReactComponent as EmailSVG } from 'assets/images/common/email.svg';
import { ReactComponent as PlanetSVG } from 'assets/images/common/planet.svg';
import { ReactComponent as WalletSVG } from 'assets/images/common/wallet.svg';
import { ReactComponent as PaymentsSVG } from 'assets/images/common/payments.svg';
import { ReactComponent as ServersSVG } from 'assets/images/common/servers.svg';
import { ReactComponent as CampaignDeliveriesSVG } from 'assets/images/common/campaign-deliveries.svg';
import { ReactComponent as FileSVG } from 'assets/images/common/file.svg';
import { ReactComponent as MobileSVG } from 'assets/images/common/mobile.svg';
import { ReactComponent as TransferSVG } from 'assets/images/common/transfer.svg';
import { ReactComponent as DollarSVG } from 'assets/images/common/dollar.svg';
import { ReactComponent as CardSVG } from 'assets/images/common/card.svg';
import { ReactComponent as TermsSVG } from 'assets/images/common/terms.svg';
import { ReactComponent as PeopleSVG } from 'assets/images/common/people.svg';
import { ReactComponent as PersonMoneySVG } from 'assets/images/common/person-money.svg';
import { ReactComponent as LinkSVG } from 'assets/images/common/link.svg';
import { ReactComponent as LogOutSVG } from 'assets/images/common/log-out.svg';
import { ReactComponent as PersonSVG } from 'assets/images/common/person.svg';
import { ReactComponent as CompanySVG } from 'assets/images/common/company.svg';
import { ReactComponent as MagnifierSVG } from 'assets/images/common/magnifier.svg';
import { ReactComponent as ArrowOpenSVG } from 'assets/images/common/arrow-open.svg';
import { ReactComponent as ArrowSelectRightSVG } from 'assets/images/common/arrow-select-right.svg';
import { ReactComponent as PersonCardSVG } from 'assets/images/common/person-card.svg';
import { ReactComponent as ThumbUpSVG } from 'assets/images/common/thumb-up.svg';
import { ReactComponent as HourglassSVG } from 'assets/images/common/hourglass.svg';
import { ReactComponent as BulbSVG } from 'assets/images/common/bulb.svg';
import { ReactComponent as HistoryWatch } from 'assets/images/common/history-watch.svg';
import { ReactComponent as ThumbUpFilledSVG } from 'assets/images/common/thumb-up-filled.svg';
import { ReactComponent as InfoSVG } from 'assets/images/common/info.svg';
import { ReactComponent as WarningSVG } from 'assets/images/common/warning.svg';
import { ReactComponent as OutlinedFlagSVG } from 'assets/images/common/outlined-flag.svg';
import { ReactComponent as ShieldSVG } from 'assets/images/common/shield.svg';
import { ReactComponent as LockSVG } from 'assets/images/common/lock.svg';
import { ReactComponent as StarBorderedSVG } from 'assets/images/common/star-border.svg';
import { ReactComponent as StarHalfSVG } from 'assets/images/common/star-half.svg';
import { ReactComponent as ProductSVG } from 'assets/images/common/manage-products.svg';
import { ReactComponent as SettingsSVG } from 'assets/images/common/settings.svg';
import { ReactComponent as ListSVG } from 'assets/images/common/list.svg';
import { ReactComponent as TextSnippetSVG } from 'assets/images/common/text-snippet.svg';
import { ReactComponent as UserGroupsSVG } from 'assets/images/common/user-groups.svg';
import { ReactComponent as WarningFilledSVG } from 'assets/images/common/warning-filled.svg';
import { ReactComponent as HelloHandSVG } from 'assets/images/common/hello-hand.svg';
import { ReactComponent as FileStrokeSVG } from 'assets/images/common/file-stroke.svg';
import { ReactComponent as HelpOutlineSVG } from 'assets/images/common/help-outline.svg';
import { ReactComponent as NotesSVG } from 'assets/images/common/notes.svg';
import { ReactComponent as ComplianceLockLineSVG } from 'assets/images/common/compliance-lock-line.svg';
import { ReactComponent as BookSVG } from 'assets/images/common/book.svg';
import { ReactComponent as PlayArrowSVG } from 'assets/images/common/play-arrow.svg';
import { ReactComponent as CaseStudySVG } from 'assets/images/common/case-study.svg';
import { ReactComponent as InstagramSVG } from 'assets/images/common/instagram.svg';
import { ReactComponent as TwitterSVG } from 'assets/images/common/twitter.svg';
import { ReactComponent as LinkedinSVG } from 'assets/images/common/linkedin.svg';
import { ReactComponent as FacebookSVG } from 'assets/images/common/facebook.svg';
import { ReactComponent as ViewCarouselSVG } from 'assets/images/common/view-carousel.svg';
import { ReactComponent as FeeDollarSVG } from 'assets/images/common/fee-dollar.svg';
import { ReactComponent as DragIndicatorSVG } from 'assets/images/common/drag-indicator.svg';
import { ReactComponent as HorizontalRuleSVG } from 'assets/images/common/horizontal-rule.svg';
import { ReactComponent as FolderOpenSVG } from 'assets/images/common/folder-open.svg';
import { ReactComponent as OnDemandVideo } from 'assets/images/common/ondemand-video.svg';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

export interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {
  name: IconsNames;
}

const IconMap = {
  [IconsNames.calendar]: CalendarSVG,
  [IconsNames.arrow]: ArrowSVG,
  [IconsNames.check_mark]: CheckMarkSVG,
  [IconsNames.copy]: CopySVG,
  [IconsNames.delete]: DeleteSVG,
  [IconsNames.download]: DownloadSVG,
  [IconsNames.dropdown_indicator]: DropdownIndicatorSVG,
  [IconsNames.edit]: EditSVG,
  [IconsNames.error]: ErrorSVG,
  [IconsNames.full_screen]: FullScreenSVG,
  [IconsNames.location]: LocationSVG,
  [IconsNames.phone]: PhoneSVG,
  [IconsNames.plus]: PlusSVG,
  [IconsNames.rocket_man]: RocketManSVG,
  [IconsNames.rocket]: RocketSVG,
  [IconsNames.select_arrow]: SelectArrowSVG,
  [IconsNames.star]: StarSVG,
  [IconsNames.success_checkmark]: SuccessCheckmarkSVG,
  [IconsNames.trash]: TrashSVG,
  [IconsNames.upload_file]: UploadFileSVG,
  [IconsNames.right_arrow]: RightArrowSVG,
  [IconsNames.logotype]: LogotypeSVG,
  [IconsNames.round_plus]: RoundPlusSVG,
  [IconsNames.close]: CloseSVG,
  [IconsNames.document_download]: DocumentDownloadSVG,
  [IconsNames.spinner]: SpinnerSVG,
  [IconsNames.pause]: PauseSVG,
  [IconsNames.dots]: DotsSVG,
  [IconsNames.website]: WebsiteSVG,
  [IconsNames.email]: EmailSVG,
  [IconsNames.planet]: PlanetSVG,
  [IconsNames.copy_success]: CopySuccessSVG,
  [IconsNames.eye]: EyeSVG,
  [IconsNames.wallet]: WalletSVG,
  [IconsNames.payments]: PaymentsSVG,
  [IconsNames.servers]: ServersSVG,
  [IconsNames.campaignDeliveries]: CampaignDeliveriesSVG,
  [IconsNames.file]: FileSVG,
  [IconsNames.mobile]: MobileSVG,
  [IconsNames.transfer]: TransferSVG,
  [IconsNames.dollar]: DollarSVG,
  [IconsNames.card]: CardSVG,
  [IconsNames.terms]: TermsSVG,
  [IconsNames.people]: PeopleSVG,
  [IconsNames.person_money]: PersonMoneySVG,
  [IconsNames.link]: LinkSVG,
  [IconsNames.log_out]: LogOutSVG,
  [IconsNames.person]: PersonSVG,
  [IconsNames.company]: CompanySVG,
  [IconsNames.magnifier]: MagnifierSVG,
  [IconsNames.arrow_open]: ArrowOpenSVG,
  [IconsNames.arrow_select_right]: ArrowSelectRightSVG,
  [IconsNames.person_card]: PersonCardSVG,
  [IconsNames.thumb_up]: ThumbUpSVG,
  [IconsNames.hourglass]: HourglassSVG,
  [IconsNames.bulb]: BulbSVG,
  [IconsNames.history_watch]: HistoryWatch,
  [IconsNames.thumb_up_filled]: ThumbUpFilledSVG,
  [IconsNames.info]: InfoSVG,
  [IconsNames.warning]: WarningSVG,
  [IconsNames.outlined_flag]: OutlinedFlagSVG,
  [IconsNames.shield]: ShieldSVG,
  [IconsNames.lock]: LockSVG,
  [IconsNames.star_bordered]: StarBorderedSVG,
  [IconsNames.star_half]: StarHalfSVG,
  [IconsNames.product]: ProductSVG,
  [IconsNames.settings]: SettingsSVG,
  [IconsNames.list]: ListSVG,
  [IconsNames.text_snippet]: TextSnippetSVG,
  [IconsNames.user_groups]: UserGroupsSVG,
  [IconsNames.warning_filled]: WarningFilledSVG,
  [IconsNames.hello_hand]: HelloHandSVG,
  [IconsNames.file_stroke]: FileStrokeSVG,
  [IconsNames.help_outline]: HelpOutlineSVG,
  [IconsNames.notes]: NotesSVG,
  [IconsNames.compliance_lock_line]: ComplianceLockLineSVG,
  [IconsNames.book]: BookSVG,
  [IconsNames.play_arrow]: PlayArrowSVG,
  [IconsNames.case_study]: CaseStudySVG,
  [IconsNames.instagram]: InstagramSVG,
  [IconsNames.twitter]: TwitterSVG,
  [IconsNames.linkedin]: LinkedinSVG,
  [IconsNames.facebook]: FacebookSVG,
  [IconsNames.view_carousel]: ViewCarouselSVG,
  [IconsNames.fee_dollar]: FeeDollarSVG,
  [IconsNames.drag_indicator]: DragIndicatorSVG,
  [IconsNames.horizontal_rule]: HorizontalRuleSVG,
  [IconsNames.folder_open]: FolderOpenSVG,
  [IconsNames.on_demand_video]: OnDemandVideo,
};

const IconSVG = React.forwardRef<
  HTMLSpanElement,
  React.PropsWithChildren<IconProps>
>(({ name, className, ...props }, ref) => {
  const Icon = IconMap[name];

  return (
    <span {...props} className={cn(className, styles.icon)} ref={ref}>
      <Icon className={styles.icon} />
    </span>
  );
});

export default IconSVG;
