import { useTranslation } from 'react-i18next';

import { FilterItem } from 'components/common/ReportedLeadsTable/LeadDetailsFilter';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type FilterPillProps = {
  item: FilterItem;
  editHandler: (item: FilterItem) => void;
  removeHandler: (id: string) => void;
};

const FilterPill = ({ item, editHandler, removeHandler }: FilterPillProps) => {
  const { t } = useTranslation();

  const editFilterHandler = () => {
    editHandler(item);
  };

  const removeFilterHandler = () => {
    removeHandler(item.id);
  };

  return (
    <div className={styles.pill}>
      <p className={styles.title}>
        <span>{t(`common.field.${item.label}`)}</span>&quot;{item.value}&quot;
      </p>
      <div className={styles.pill_action}>
        <button onClick={editFilterHandler} className={styles.button}>
          <IconSVG name={IconsNames.edit} className={styles.edit} />
        </button>
        <button onClick={removeFilterHandler} className={styles.button}>
          <IconSVG name={IconsNames.delete} className={styles.icon} />
        </button>
      </div>
    </div>
  );
};

export default FilterPill;
