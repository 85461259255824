import { useParams } from 'react-router-dom';

import PageContainer from 'components/common/PageContainer';

import ContactsUploadsHistory from 'components/CampaignsListDeliveries/ContactsUploadHistory';

import { PrivatePaths } from 'constants/routes';

const CampaignDeliveriesUploads = () => {
  const { id } = useParams();

  return (
    <PageContainer
      path={['campaign-deliveries.uploads-history']}
      backLinkPath={`/${PrivatePaths.CAMPAIGN_DELIVERIES}/${id}`}
      isWhiteBackground
    >
      <ContactsUploadsHistory />
    </PageContainer>
  );
};

export default CampaignDeliveriesUploads;
