import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { VendorAvailableMilestone } from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/models';

import IconSVG from 'components/UI/IconSVG';

import AssetFileItem from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/SelectAsset/AssetFileItem';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  milestone: VendorAvailableMilestone;
  index: number;
};

const Milestone = ({ milestone, index }: Props) => {
  const { t } = useTranslation();

  return (
    <li
      className={cn(styles.wrapper, {
        [styles.disabled]: !milestone.is_available,
      })}
    >
      <div className={styles.header}>
        <p className={styles.info}>
          <span className={styles.infoHeader}>
            <IconSVG name={IconsNames.outlined_flag} className={styles.flag} />
            {index + 1} {t('common.field.milestone')}
          </span>
          <span>{milestone.delivery_date}</span>
        </p>
        <p className={styles.info}>
          {milestone.leads_required}
          <span>{t('campaign.leads-required')}</span>
        </p>
      </div>

      <div className={styles.fields}>
        <div className={styles.name}>
          <span>{t('common.field.name')}:</span>
          <span>{t('common.field.leads')}:</span>
        </div>
        <span className={styles.field}>{t('lead.status.qualified')}</span>
        <span className={styles.field}>{t('lead.status.remaining')}</span>
        <span className={styles.field}>{t('lead.status.required')}</span>
      </div>

      <ul>
        {milestone.campaign_offer_assets.map(asset => (
          <AssetFileItem key={asset.id} asset={asset} milestone={milestone} />
        ))}
      </ul>
    </li>
  );
};

export default Milestone;
