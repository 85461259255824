import { useFormContext } from 'react-hook-form';

import type { EmailServerResponse } from 'types/models';
import type { FormValues } from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/models';

import { getServerIcon, getESPServerName } from 'helpers/mailServers';

import { ServerVariants } from 'constants/constants';

import { ReactComponent as SMTPSVG } from 'assets/images/integrations/smtp.svg';

import styles from '../index.module.scss';

type Props = {
  server: EmailServerResponse;
};

const ServerCheckbox = ({ server }: Props) => {
  const { watch, setValue, getValues } = useFormContext<FormValues>();
  const Icon =
    server.integration_type === ServerVariants.esp
      ? getServerIcon(server.decrypted_credentials.provider_name)
      : null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const selectedServers = getValues().integration_ids;
    const isServerChecked = selectedServers?.some(item => item === value);
    if (isServerChecked) {
      setValue(
        'integration_ids',
        selectedServers?.filter(item => item !== value)
      );

      return;
    }

    setValue('integration_ids', [...(selectedServers || []), value]);
  };

  const isChecked = watch('integration_ids')?.some(item => item === server.id);
  const credentialsName =
    server.integration_type === ServerVariants.esp
      ? getESPServerName(server.decrypted_credentials.provider_name)
      : server.decrypted_credentials.host;

  return (
    <li className={styles.server} key={server.id}>
      <label htmlFor={`server-${server.id}`}>
        {Icon ? (
          <Icon className={styles.icon} />
        ) : (
          <SMTPSVG className={styles.icon} />
        )}
        <input
          type="checkbox"
          id={`server-${server.id}`}
          value={server.id}
          name={`server-${server.id}`}
          onChange={handleChange}
          checked={!!isChecked}
        />
        <span className={styles.name}>
          {server.integration_name || credentialsName}
        </span>
      </label>
    </li>
  );
};

export default ServerCheckbox;
