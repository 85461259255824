import { useMutation } from 'react-query';

import type { AxiosError } from 'axios';
import type { ContactsReportResponse } from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/models';

import useAuth from 'contexts/AuthContext';

const useUpdateContactsReport = () => {
  const { axios } = useAuth();

  const mutation = useMutation<
    ContactsReportResponse,
    AxiosError,
    Partial<ContactsReportResponse> & {
      campaignId: string | number;
      fileId: string | number;
    }
  >(async ({ campaignId, fileId, ...payload }) => {
    try {
      const { data } = await axios.patch<ContactsReportResponse>(
        `/vendor-campaigns/${campaignId}/contacts-uploads/${fileId}/`,
        payload
      );
      return data;
    } catch (error) {
      throw error;
    }
  });

  return mutation;
};

export default useUpdateContactsReport;
