import cn from 'classnames';

import Step from 'components/common/Steps/Step';

import { StepNavigation } from 'constants/constants';

import styles from './index.module.scss';

type Props<T extends string> = {
  step: { name: T; index: number };
  steps: Array<{
    name: T;
    disabled?: boolean;
    title: string;
  }>;
  goToStep: (index: T | StepNavigation) => void;
  className?: string;
  isStepNumberVisible?: boolean;
  isVertical?: boolean;
};

const Steps = <T extends string>({
  step,
  steps,
  goToStep,
  className,
  isStepNumberVisible,
  isVertical,
}: Props<T>) => (
  <ul
    className={cn(styles.wrapper, className, { [styles.vertical]: isVertical })}
  >
    {steps.map(({ name, disabled, title }, index) => (
      <Step<T>
        key={name}
        name={name}
        index={index}
        currentStep={step}
        lastStepIndex={steps.length - 1}
        goToStep={goToStep}
        disabled={disabled}
        title={title}
        isStepNumberVisible={isStepNumberVisible}
        isVertical={isVertical}
      />
    ))}
  </ul>
);

export default Steps;
