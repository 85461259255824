import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';

import ModalWindow from 'components/common/ModalWindow';
import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';

import useAuth, { User } from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import { validationSchemaEditAccountingPerson } from 'utils/validations';

import styles from './index.module.scss';

type FormFields = {
  accounting_person_last_name: string | null;
  accounting_person_first_name: string | null;
  accounting_person_email: string | null;
};

type Props = {
  onSuccess?: () => void;
};
const EditAccountingPersonModal = ({ onSuccess }: Props) => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();
  const { closeModal } = useModal();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(validationSchemaEditAccountingPerson),
    mode: 'onBlur',
    defaultValues: {
      accounting_person_last_name: user?.company.accounting_person_first_name,
      accounting_person_first_name: user?.company.accounting_person_last_name,
      accounting_person_email: user?.company.accounting_person_email,
    },
    reValidateMode: 'onChange',
  });
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation<
    User,
    AxiosError,
    FormFields
  >(
    async values => {
      try {
        const { data } = await axios.patch<User>('/accounts/profile/', values);
        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async data => {
        closeModal();
        queryClient.setQueryData('profile', data);
        if (onSuccess) onSuccess();
      },
    }
  );

  const handleFormSubmit = (data: FormFields) => {
    mutate(data);
  };

  return (
    <ModalWindow primaryTitle={t('profile.accounting-person-contacts')}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <InputWrapper
          label="common.field.first-name"
          validationError={errors?.accounting_person_first_name?.message}
        >
          <input type="text" {...register('accounting_person_first_name')} />
        </InputWrapper>
        <InputWrapper
          label="common.field.last-name"
          validationError={errors?.accounting_person_last_name?.message}
        >
          <input type="text" {...register('accounting_person_last_name')} />
        </InputWrapper>
        <InputWrapper
          label="common.field.email"
          validationError={errors?.accounting_person_email?.message}
        >
          <input type="email" {...register('accounting_person_email')} />
        </InputWrapper>
        {error && (
          <Error className={styles.error} message={getResponseError(error)} />
        )}
        <Button type="submit" className={styles.button}>
          {t('common.button.save')}
        </Button>
      </form>
      {isLoading && <LoaderScreen />}
    </ModalWindow>
  );
};

export default EditAccountingPersonModal;
