import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import type { OfferResponse } from 'types/models';

import { TabFilters } from 'components/common/ReportedLeadsTable';

import styles from '../index.module.scss';

type Props = {
  selectedVendor?: { id: string | undefined; companyName: string | undefined };
  setSelectedVendor: (vendor: {
    id: string | undefined;
    companyName: string | undefined;
  }) => void;
  acceptedOffers?: OfferResponse[];
  canceledOffers?: OfferResponse[];
  setPage: (page: number) => void;
};

const VendorTab = ({
  selectedVendor,
  setSelectedVendor,
  acceptedOffers,
  canceledOffers,
  setPage,
}: Props) => {
  const { t } = useTranslation();

  const inputHandler = ({
    currentTarget: { value },
    target: { parentNode },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedVendor({
      id: value,
      companyName: parentNode?.textContent || '',
    });
    setPage(1);
  };

  const allOffersArray = [...(acceptedOffers || []), ...(canceledOffers || [])];

  const uniqueOffersArray = [
    ...new Map(allOffersArray.map(item => [item.vendor, item])).values(),
  ];

  return (
    <ul className={styles.tabs}>
      <li className={styles.vendor}>
        <label
          htmlFor="vendor-all"
          className={cn({
            [styles.active]: !selectedVendor?.id,
          })}
        >
          <input
            type="radio"
            id="vendor-all"
            name={TabFilters.vendor}
            onChange={inputHandler}
            checked={!selectedVendor?.id}
            value={undefined}
          />
          {t('common.field.all')}
        </label>
      </li>
      {uniqueOffersArray.map(offer => {
        const isChecked = selectedVendor?.id === offer.vendor.toString();

        return (
          <li key={offer.vendor} className={styles.vendor}>
            <label
              key={offer.id}
              htmlFor={`vendor-${offer.vendor}`}
              className={cn({
                [styles.active]: isChecked,
              })}
            >
              <input
                type="radio"
                id={`vendor-${offer.vendor}`}
                name={TabFilters.vendor}
                onChange={inputHandler}
                checked={isChecked}
                value={offer.vendor}
              />
              {offer.vendor_display_name}
            </label>
          </li>
        );
      })}
    </ul>
  );
};

export default VendorTab;
