import { useMemo, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import Pagination from 'components/common/Pagination';
import Table from 'components/common/Table';
import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import Button from 'components/common/Button';

import DownloadTemplate from 'components/CampaignsListDeliveries/CampaignDetails/DownloadTemplate';
import ImportContactsButton from 'components/CampaignsListDeliveries/CampaignDetails/ImportContactsButton';
import ContactLeadStatus from 'components/common/ContactLeadStatus';
import InfoDetailsDeliveries from 'components/CampaignsListDeliveries/CampaignDetails/InfoDetailsDeliveries';
import EmptyData from 'components/CampaignsListDeliveries/EmptyData';

import { useGetCampaignContacts } from 'components/CampaignsListDeliveries/CampaignDetails/useGetCampaignContacts';

import { IconsNames, LeadStatus } from 'constants/constants';
import { PrivatePaths } from 'constants/routes';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

export enum TableAccessors {
  email = 'email',
  linkedinContactProfileLink = 'person-linkedin',
  linkedinCompanyProfileLink = 'company-linkedin',
  deliveryStatus = 'delivery-status',
  dateOfScoring = 'date-of-scoring',
  sentAt = 'sent-at',
  leadStatus = 'lead-status',
}

export const PAGE_SIZE = 10;

const CampaignDetailsDeliveries = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const infoRef = useRef(null);

  const {
    data: contacts,
    isLoading: isContactsLoading,
    isRefetching,
    error,
    dataUpdatedAt,
  } = useGetCampaignContacts(page);

  const columnsData = useMemo(
    () => [
      {
        Header: t(`common.field.${TableAccessors.email}`),
        accessor: TableAccessors.email,
      },
      {
        Header: t(`common.field.${TableAccessors.linkedinContactProfileLink}`),
        accessor: TableAccessors.linkedinContactProfileLink,
        Cell: ({ value }: { value: string }) => (
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {value}
          </a>
        ),
      },
      {
        Header: t(`common.field.${TableAccessors.linkedinCompanyProfileLink}`),
        accessor: TableAccessors.linkedinCompanyProfileLink,
        Cell: ({ value }: { value: string }) => (
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {value}
          </a>
        ),
      },
      {
        Header: t(`common.field.${TableAccessors.leadStatus}`),
        accessor: TableAccessors.leadStatus,
        Cell: ({
          value,
        }: {
          value: { status: LeadStatus; disqualifyReasons: string[] };
        }) => <ContactLeadStatus {...value} />,
      },
      {
        Header: t(`common.field.${TableAccessors.deliveryStatus}`),
        accessor: TableAccessors.deliveryStatus,
      },
      {
        Header: t(`common.field.${TableAccessors.sentAt}`),
        accessor: TableAccessors.sentAt,
      },
      {
        Header: t(`common.field.${TableAccessors.dateOfScoring}`),
        accessor: TableAccessors.dateOfScoring,
      },
    ],
    []
  );

  const rowsData = useMemo(() => {
    return contacts?.results.map(contact => ({
      [TableAccessors.email]: contact.email,
      [TableAccessors.linkedinContactProfileLink]:
        contact.linkedin_contact_profile_link,
      [TableAccessors.linkedinCompanyProfileLink]:
        contact.linkedin_company_profile_link,
      [TableAccessors.deliveryStatus]: t(
        `campaign-deliveries.status.${contact.delivery_status}`
      ),
      [TableAccessors.leadStatus]: {
        status: contact.campaign_lead_status,
        disqualifyReasons: contact.campaign_lead_disqualify_reasons,
      },
      [TableAccessors.sentAt]:
        contact.sent_at && dayjs(contact.sent_at).format('DD/MM/YYYY, h:mm A'),
      [TableAccessors.dateOfScoring]:
        contact.date_of_scoring &&
        dayjs(contact.date_of_scoring).format('DD/MM/YYYY'),
    }));
  }, [contacts, dataUpdatedAt]);

  return (
    <>
      <InfoDetailsDeliveries />
      <div className={styles.controls}>
        <h3 ref={infoRef}>{t('campaign-deliveries.uploaded-contacts')}</h3>
        {(isContactsLoading || isRefetching) && <Loader size={40} />}
        <Link to={PrivatePaths.UPLOADS_HISTORY} className={styles.history}>
          <Button iconProps={{ name: IconsNames.history_watch }} white>
            {t('campaign-deliveries.uploads-history')}
          </Button>
        </Link>
        <ImportContactsButton />
        <DownloadTemplate />
      </div>

      {error && <Error message={getResponseError(error)} />}

      {!!rowsData?.length && (
        <>
          <Table
            wrapperClassName={styles.table}
            tableOptions={{
              columns: columnsData,
              data: rowsData || [],
            }}
            headClassName={styles.thead}
            isEvenRowGrey
          />
          <Pagination
            totalCount={contacts?.count || 1}
            pageSize={PAGE_SIZE}
            currentPage={page}
            setPage={setPage}
            scrollToRef={infoRef}
            className={styles.pagination}
          />
        </>
      )}

      {!rowsData?.length && (
        <EmptyData text={t('campaign-deliveries.no-imports-yet')} />
      )}
    </>
  );
};

export default CampaignDetailsDeliveries;
