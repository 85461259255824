import type { TableOptions, PluginHook, Row, TableInstance } from 'react-table';

import TableCommon from 'components/common/Table/TableCommon';
import TableLayout from 'components/common/Table/TableLayout';

type Props = {
  wrapperClassName?: string;
  headClassName?: string;
  rowClassName?: string;
  tableOptions?: TableOptions<any>;
  plugins?: Array<PluginHook<{}>>;
  renderRowSubComponent?: ({ row }: { row: Row<{}> }) => JSX.Element;
  noDataComponent?: React.ReactNode;
  isFirstColumnFixed?: boolean;
  isEvenRowGrey?: boolean;
  tableProps?: TableInstance<any>;
};

const Table = ({
  wrapperClassName,
  headClassName,
  rowClassName,
  tableOptions,
  plugins,
  renderRowSubComponent,
  noDataComponent,
  isFirstColumnFixed = false,
  isEvenRowGrey,
  tableProps,
}: Props) => {
  return tableOptions ? (
    <TableCommon
      tableOptions={tableOptions}
      wrapperClassName={wrapperClassName}
      headClassName={headClassName}
      rowClassName={rowClassName}
      plugins={plugins}
      renderRowSubComponent={renderRowSubComponent}
      noDataComponent={noDataComponent}
      isFirstColumnFixed={isFirstColumnFixed}
      isEvenRowGrey={isEvenRowGrey}
    />
  ) : (
    <TableLayout
      tableProps={tableProps}
      wrapperClassName={wrapperClassName}
      headClassName={headClassName}
      rowClassName={rowClassName}
      plugins={plugins}
      renderRowSubComponent={renderRowSubComponent}
      noDataComponent={noDataComponent}
      isFirstColumnFixed={isFirstColumnFixed}
      isEvenRowGrey={isEvenRowGrey}
    />
  );
};

export default Table;
