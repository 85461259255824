import { TermsOfServiceIDs } from 'components/DocumentsContent/constants';

import styles from 'components/DocumentsContent/index.module.scss';

const SideMenu = () => {
  return (
    <div className={styles.sidemenu_container}>
      <div className={styles.sidemenu_content}>
        <h4 className={styles.heading}>Table of contents</h4>
        <ul className={styles.list}>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.interpretation}`}
              className={styles.anchor_link}
            >
              1. Interpretation
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.accounts}`}
              className={styles.anchor_link}
            >
              2. Accounts
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.eligibility}`}
              className={styles.anchor_link}
            >
              3. Eligibility
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.suspension_and_termination}`}
              className={styles.anchor_link}
            >
              4. Suspension and Termination
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.services_and_balance}`}
              className={styles.anchor_link}
            >
              5. Services and Balance
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.payments}`}
              className={styles.anchor_link}
            >
              6. Payments
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.taxes}`}
              className={styles.anchor_link}
            >
              7. Taxes
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.contractual_relationship_between_client_and_vendor}`}
              className={styles.anchor_link}
            >
              8. Contractual Relationship Between Client and Vendor
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.license}`}
              className={styles.anchor_link}
            >
              9. License
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.warranties_and_representations}`}
              className={styles.anchor_link}
            >
              10. Warranties and Representations
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.prohibited_use}`}
              className={styles.anchor_link}
            >
              11. Prohibited Use
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.personal_data_and_data_protection}`}
              className={styles.anchor_link}
            >
              12. Personal Data and Data Protection
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.third_party_content_and_services}`}
              className={styles.anchor_link}
            >
              13. Third-Party Content and Services
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.user_content}`}
              className={styles.anchor_link}
            >
              14. User Content
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.updates_availability_and_access}`}
              className={styles.anchor_link}
            >
              15. Updates, Availability, and Access
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.proprietary_rights}`}
              className={styles.anchor_link}
            >
              16. Properietary Rights
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.void_transactions}`}
              className={styles.anchor_link}
            >
              17. Void Transactions
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.no_advice}`}
              className={styles.anchor_link}
            >
              18. No Advice
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.no_warranty}`}
              className={styles.anchor_link}
            >
              19. No Warranty
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.waiver_of_fiduciary_duties}`}
              className={styles.anchor_link}
            >
              20. Waiver of Fiduciary Duties
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.no_partnership_agency_or_employment}`}
              className={styles.anchor_link}
            >
              21. No partnership, Agency, or Employment
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.limitation_of_liability}`}
              className={styles.anchor_link}
            >
              22. Limitation of Liability
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.indemnification}`}
              className={styles.anchor_link}
            >
              23. Indemnification
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.injunction}`}
              className={styles.anchor_link}
            >
              24. Injunction
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.force_majeure}`}
              className={styles.anchor_link}
            >
              25. Force Majeure
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.applicable_law}`}
              className={styles.anchor_link}
            >
              26. Applicable Law
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.disputes_resolution}`}
              className={styles.anchor_link}
            >
              27. Disputes Resolution
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.communication}`}
              className={styles.anchor_link}
            >
              28. Communication
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.modification}`}
              className={styles.anchor_link}
            >
              29. Modification
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.no_waiver}`}
              className={styles.anchor_link}
            >
              30. No Waiver
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.entire_agreement}`}
              className={styles.anchor_link}
            >
              31. Entire Agreement
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.personal_data}`}
              className={styles.anchor_link}
            >
              32. Personal Data
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.term_termination_and_survival}`}
              className={styles.anchor_link}
            >
              33. Term, Termination, and Survival
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.language}`}
              className={styles.anchor_link}
            >
              34. Language
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.assignability}`}
              className={styles.anchor_link}
            >
              35. Assignability
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${TermsOfServiceIDs.severability}`}
              className={styles.anchor_link}
            >
              36. Severability
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
