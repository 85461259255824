import { useEffect } from 'react';

const useEscapeEvent = (onClick: () => void) => {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClick();
    };
    window.addEventListener('keydown', close);

    return () => window.removeEventListener('keydown', close);
  }, []);
};

export default useEscapeEvent;
