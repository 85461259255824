import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  value: number;
};

const starsArray = [1, 2, 3, 4, 5];

const Rating = ({ value }: Props) => {
  const getIconName = (point: number) => {
    if (point < value && value < point + 1) {
      return IconsNames.star_half;
    }

    if (Math.floor(value) >= point) {
      return IconsNames.star;
    }

    return IconsNames.star_bordered;
  };

  return (
    <span className={styles.rating}>
      {starsArray.map(val => {
        return <IconSVG key={val} name={getIconName(val)} />;
      })}
    </span>
  );
};

export default Rating;
