import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import BeeLeadsSVG from 'assets/images/common/beeleads-logo.svg';

import { CommonPaths, PrivatePaths } from 'constants/routes';

import styles from './index.module.scss';

const Heading = () => {
  const getLinkClasses = ({ isActive }: { isActive?: boolean }) =>
    cn(styles.link, {
      [styles.active]: isActive,
    });

  return (
    <header className={styles.header}>
      <NavLink to={PrivatePaths.INDEX} className={styles.logo_container}>
        <div className={styles.logoWrapper}>
          <img src={BeeLeadsSVG} alt="" className={styles.logo} />
        </div>
      </NavLink>

      <nav className={styles.header__nav}>
        <ul className={styles.list}>
          <NavLink to={CommonPaths.TERMS} className={getLinkClasses}>
            <li>Terms of Service</li>
          </NavLink>
          <NavLink to={`${CommonPaths.CONTRACT}`} className={getLinkClasses}>
            <li>Service Contract</li>
          </NavLink>
          <NavLink to={`${CommonPaths.PROCESSING}`} className={getLinkClasses}>
            <li>Data Processing Addendum</li>
          </NavLink>
          <NavLink
            to={`${CommonPaths.PRIVACY_NOTICE}`}
            className={getLinkClasses}
          >
            <li>Privacy Notice</li>
          </NavLink>
        </ul>
      </nav>
    </header>
  );
};

export default Heading;
