import { useInfiniteQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { PaginationReturn } from 'types/models';
import type { OfferHistoryItem } from 'components/CampaignManage/models';

import ModalWindow from 'components/common/ModalWindow';
import OfferStatus from 'components/common/OfferStatus';
import Loader from 'components/common/LoaderScreen/Loader';
import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import ToggleSwitch from 'components/common/ToggleSwitch';

import { UserTypes, MilestoneType } from 'constants/constants';

import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';

import styles from '../index.module.scss';

type Props = {
  offerId: number;
  campaignId: number;
  milestoneDeadlineType: MilestoneType;
};

const HistoryModal = ({
  offerId,
  campaignId,
  milestoneDeadlineType,
}: Props) => {
  const { axios, user } = useAuth();
  const { t } = useTranslation();
  const { convertMilestoneDate } = useConvertCampaignData();

  const isVendor = user?.current_group_name === UserTypes.vendor;

  const {
    data: history,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery<PaginationReturn<OfferHistoryItem>>(
    ['offer-history', offerId, user?.current_group_name],
    async ({ pageParam }) => {
      let page;
      if (typeof pageParam === 'string') {
        const url = new URL(pageParam);
        page = url.searchParams.get('page');
      }
      const pageNumber = page || 1;
      try {
        const { data } = await axios.get(
          `/${
            isVendor ? 'vendor-campaigns' : 'campaigns'
          }/${campaignId}/offers/${offerId}/history/?page_size=4&page=${pageNumber}`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      getNextPageParam: page => {
        return page.next || undefined;
      },
      enabled: user?.current_group_name === UserTypes.vendorManager || isVendor,
    }
  );

  const historyItems = history?.pages.map(page => page.results).flat();

  return isLoading ? (
    <LoaderScreen />
  ) : (
    <ModalWindow primaryTitle="History">
      {!!historyItems?.length && (
        <ul className={styles.list}>
          {historyItems.map(item => {
            const isLastUpdatedByVendor =
              item.last_responded_by === UserTypes.vendor;

            return (
              <li key={item.id} className={styles.item}>
                <OfferStatus status={item.status} />
                <div className={styles.info}>
                  <p className={styles.title}>
                    {t(`user.type.${item.last_responded_by}`)}
                  </p>
                  <p className={styles.paragraph}>
                    {t('offers.comment')}:{' '}
                    {isLastUpdatedByVendor
                      ? item.comment_vendor || '-'
                      : item.comment || '-'}
                  </p>
                  <p className={styles.paragraph}>
                    {t('common.field.cpl')}:{' '}
                    {isLastUpdatedByVendor ? item.cpl_vendor : item.cpl}
                  </p>
                  {item.no_preference && (
                    <label className={styles.paragraph}>
                      <ToggleSwitch
                        disabled
                        checked
                        id={`offer-${item.id}-no-preference`}
                      />
                      {t('asset-distribution.no-preference')}
                    </label>
                  )}
                  <ul className={styles.milestones}>
                    {item.campaign_offer_milestones_history.map(
                      (milestone, index) => (
                        <li
                          key={`milestone-${index}`}
                          className={styles.milestone}
                        >
                          <p className={styles.heading}>
                            {convertMilestoneDate(
                              milestoneDeadlineType,
                              milestone.delivery_date,
                              false
                            )}
                          </p>
                          <p className={styles.paragraph}>
                            {t('offers.leads-amount')}:{' '}
                            {isLastUpdatedByVendor
                              ? milestone.leads_required_vendor
                              : milestone.leads_required}
                          </p>
                          <ul className={styles.assets}>
                            {milestone.campaign_offer_assets_history.map(
                              (asset, idx) => {
                                const leadsRequired = isLastUpdatedByVendor
                                  ? asset.leads_required_vendor
                                  : asset.leads_required;

                                return (
                                  <li key={`asset-${idx}`}>
                                    <p className={styles.heading}>
                                      {asset.title}
                                    </p>
                                    {!item.no_preference && !!leadsRequired && (
                                      <p className={styles.paragraph}>
                                        {t('offers.leads-amount')}:{' '}
                                        {leadsRequired}
                                      </p>
                                    )}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </li>
            );
          })}
          {!isFetchingNextPage && hasNextPage && (
            <Button
              type="button"
              onClick={() => fetchNextPage()}
              className={styles.more}
            >
              {t('common.button.load-more')}
            </Button>
          )}
          {isFetchingNextPage && <Loader className={styles.loader} />}
        </ul>
      )}
    </ModalWindow>
  );
};

export default HistoryModal;
