import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import FileItem from 'components/common/FileItem';
import Loader from 'components/common/LoaderScreen/Loader';
import UploadFile from 'components/common/UploadFile';
import Error from 'components/common/Error';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';

import Fieldset from 'components/CreateCampaign/Step3/Fieldset';
import VariantCheckboxWrapper from 'components/CreateCampaign/Step3/VariantCheckboxWrapper';

import useSaveCampaignAsset from 'components/CreateCampaign/useSaveCampaignAsset';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import { CustomDefaultValue } from 'constants/constants';
import { AssetHTMLStatus } from 'constants/assets';

import styles from './index.module.scss';

type Props = {
  index: number;
  isSubmitted: boolean;
  setIsSubmitError?: React.Dispatch<React.SetStateAction<boolean>>;
  isDetailsOpen: boolean;
};

const MAX_FILES_SIZE = 3145728; // 3mb

const getIsDefaultChecked = (value: CustomDefaultValue) =>
  value === CustomDefaultValue.default;

const HTMLForm = ({
  index,
  isSubmitted,
  setIsSubmitError,
  isDetailsOpen,
}: Props) => {
  const {
    register,
    formState: { errors },
    control,
    getValues,
    watch,
    trigger,
    clearErrors,
  } = useFormContext<CreateCampaignValues>();
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();

  const {
    field: { value: logoValue, onChange: handleLogoChange },
  } = useController({
    name: `campaign_files.assets.${index}.html_template_logo`,
    control,
  });
  const {
    field: { value: imageValue, onChange: handleImageChange },
  } = useController({
    name: `campaign_files.assets.${index}.html_template_image`,
    control,
  });
  const {
    mutate: save,
    isLoading,
    error,
  } = useSaveCampaignAsset(getValues().id);

  const privacyValue = watch(
    `campaign_files.assets.${index}.html_template_privacy_is_default`
  );

  const isCustomPrivacy = !getIsDefaultChecked(
    privacyValue || CustomDefaultValue.default
  );

  const onFormSubmit = async () => {
    const isValid = await trigger(`campaign_files.assets.${index}`);
    if (!isValid) return;
    const assetsData =
      Array.isArray(getValues().campaign_files?.assets) &&
      getValues().campaign_files?.assets;
    const newData = assetsData && assetsData[index];
    if (!newData) return;

    setIsSubmitError?.(false);

    save({
      asset: { ...newData, asset_html_status: AssetHTMLStatus.submitted },
      id: newData.id,
    });
  };

  const handleDeleteFile = (
    instanceName: string,
    onChangeCallback: (file: null) => void,
    name?: string
  ) => {
    openModal({
      Content: (
        <DeleteConfirmationModal
          instanceName={instanceName.toLowerCase()}
          itemName={name || t('common.field.file')}
          onDelete={() => {
            closeModal();
            onChangeCallback(null);
          }}
        />
      ),
    });
  };

  const isErrors =
    errors?.campaign_files?.assets && errors?.campaign_files?.assets[index];

  const labelClasses = cn({
    [styles.disabled]: isLoading,
  });

  const defaultPrivacyPolicy =
    getValues().campaign_files?.assets?.[index].default_privacy_value;

  return (
    <div className={styles.form}>
      <Fieldset legend={`1. ${t('campaign.heading-and-image')}`}>
        <div className={styles.row}>
          <InputWrapper
            label="common.field.heading"
            isRequired
            validationError={isErrors?.html_template_heading?.message}
            wrapperClasses={labelClasses}
            submitError={
              Array.isArray(error?.response?.data?.html_template_heading)
                ? error?.response?.data?.html_template_heading[0]
                : undefined
            }
          >
            <input
              type="text"
              {...register(
                `campaign_files.assets.${index}.html_template_heading`,
                { required: true }
              )}
            />
          </InputWrapper>
          <InputWrapper
            label="common.field.company-name"
            isRequired
            validationError={isErrors?.html_template_company_name?.message}
            wrapperClasses={labelClasses}
            submitError={
              Array.isArray(error?.response?.data?.html_template_company_name)
                ? error?.response?.data?.html_template_heading[0]
                : undefined
            }
          >
            <input
              type="text"
              {...register(
                `campaign_files.assets.${index}.html_template_company_name`,
                { required: true }
              )}
            />
          </InputWrapper>
        </div>
        <InputWrapper
          label="common.field.sub-heading"
          validationError={isErrors?.html_template_sub_heading?.message}
          wrapperClasses={labelClasses}
          submitError={
            Array.isArray(error?.response?.data?.html_template_sub_heading)
              ? error?.response?.data?.html_template_sub_heading[0]
              : undefined
          }
        >
          <input
            type="text"
            {...register(
              `campaign_files.assets.${index}.html_template_sub_heading`
            )}
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.email-subject"
          validationError={isErrors?.html_template_subject?.message}
          wrapperClasses={labelClasses}
          isRequired
          submitError={
            Array.isArray(error?.response?.data?.html_template_subject)
              ? error?.response?.data?.html_template_subject[0]
              : undefined
          }
        >
          <input
            type="text"
            {...register(
              `campaign_files.assets.${index}.html_template_subject`
            )}
          />
        </InputWrapper>

        <div className={styles.row}>
          <div className={styles.file}>
            <InputWrapper
              isRequired
              label="common.field.upload-logo-format"
              wrapperClasses={labelClasses}
              validationError={isErrors?.html_template_logo?.message}
              submitError={
                Array.isArray(error?.response?.data?.html_template_logo)
                  ? error?.response?.data?.html_template_logo[0]
                  : undefined
              }
              isPreventDefaultClick
            >
              <UploadFile
                accept={['image/png', 'image/jpeg', 'image/svg+xml']}
                onChangeOptions={{
                  maxFiles: 1,
                  insertSingle: file => {
                    if (file) {
                      clearErrors(
                        `campaign_files.assets.${index}.html_template_logo`
                      );
                    }
                    handleLogoChange(file);
                  },
                }}
                wrapperClasses={styles.upload}
                maxSize={MAX_FILES_SIZE}
              />
            </InputWrapper>
            {logoValue && (
              <FileItem
                {...(typeof logoValue === 'string'
                  ? {
                      link: logoValue,
                      name: t('common.button.view-file'),
                    }
                  : { name: logoValue.name })}
                deleteFile={() =>
                  handleDeleteFile(
                    t('common.field.logo'),
                    handleLogoChange,
                    typeof logoValue !== 'string' ? logoValue.name : ''
                  )
                }
              />
            )}
          </div>
          <div className={styles.file}>
            <InputWrapper
              isRequired
              label="common.field.upload-image-format"
              validationError={isErrors?.html_template_image?.message}
              wrapperClasses={labelClasses}
              submitError={
                Array.isArray(error?.response?.data?.html_template_image)
                  ? error?.response?.data?.html_template_image[0]
                  : undefined
              }
              isPreventDefaultClick
            >
              <UploadFile
                accept={['image/png', 'image/jpeg', 'image/svg+xml']}
                onChangeOptions={{
                  maxFiles: 1,
                  insertSingle: file => {
                    if (file) {
                      clearErrors(
                        `campaign_files.assets.${index}.html_template_image`
                      );
                    }
                    handleImageChange(file);
                  },
                }}
                wrapperClasses={styles.upload}
                maxSize={MAX_FILES_SIZE}
              />
            </InputWrapper>
            {imageValue && (
              <FileItem
                {...(typeof imageValue === 'string'
                  ? {
                      link: imageValue,
                      name: t('common.button.view-file'),
                    }
                  : { name: imageValue.name })}
                deleteFile={() =>
                  handleDeleteFile(
                    t('common.field.image'),
                    handleImageChange,
                    typeof imageValue !== 'string' ? imageValue.name : ''
                  )
                }
              />
            )}
          </div>
        </div>
      </Fieldset>

      <Fieldset legend={`2. ${t('campaign.content')}`}>
        <InputWrapper
          isRequired
          label="common.field.content-para"
          validationError={isErrors?.html_template_content_para?.message}
          wrapperClasses={labelClasses}
        >
          <TextareaAutoHeight
            methods={register(
              `campaign_files.assets.${index}.html_template_content_para`
            )}
            control={control}
            additionalEffectDepth={[isDetailsOpen]}
          />
        </InputWrapper>

        <VariantCheckboxWrapper
          title={t('common.field.privacy-policy-link')}
          titleClassname={styles.title}
          checkboxes={
            <>
              <label className={styles.radioLabel}>
                <input
                  type="radio"
                  {...register(
                    `campaign_files.assets.${index}.html_template_privacy_is_default`,
                    {
                      required: true,
                    }
                  )}
                  value={CustomDefaultValue.custom}
                />
                <span>{t('common.field.custom')}</span>
              </label>
              {isCustomPrivacy && (
                <InputWrapper
                  validationError={
                    isErrors?.html_template_privacy_value?.message
                  }
                  wrapperClasses={styles.privacyPolicyInput}
                >
                  <input
                    type="url"
                    {...register(
                      `campaign_files.assets.${index}.html_template_privacy_value`
                    )}
                  />
                </InputWrapper>
              )}
              <label className={styles.radioLabel}>
                <input
                  type="radio"
                  {...register(
                    `campaign_files.assets.${index}.html_template_privacy_is_default`,
                    {
                      required: true,
                    }
                  )}
                  value={CustomDefaultValue.default}
                />
                <span className="">
                  {t('common.field.default')}{' '}
                  <a
                    href={defaultPrivacyPolicy}
                    className={styles.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    ({t('common.field.link')})
                  </a>
                </span>
              </label>
            </>
          }
        ></VariantCheckboxWrapper>

        <InputWrapper
          label="common.field.footer-content"
          isRequired
          validationError={isErrors?.html_template_footer_content?.message}
          wrapperClasses={labelClasses}
        >
          <TextareaAutoHeight
            methods={register(
              `campaign_files.assets.${index}.html_template_footer_content`
            )}
            control={control}
            additionalEffectDepth={[isDetailsOpen]}
          />
        </InputWrapper>
      </Fieldset>
      {isLoading ? (
        <Loader className={styles.preview} size={48} />
      ) : (
        <div className={styles.actions}>
          {error && <Error message={getResponseError(error)} />}
          <Button
            type="button"
            className={styles.preview}
            withArrow
            onClick={onFormSubmit}
            isBig
          >
            {isSubmitted
              ? t('common.button.preview')
              : t('common.button.submit')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default HTMLForm;
