import PageContainer from 'components/common/PageContainer';
import Hubspot from 'components/Hubspot';

const AdminContent = () => {
  return (
    <PageContainer path={['integrations.title.admin']}>
      <Hubspot />
    </PageContainer>
  );
};

export default AdminContent;
