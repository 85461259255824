import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';

import IconSVG from 'components/UI/IconSVG';

import { LeadStatus, IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  status: LeadStatus;
  tooltipText?: string;
  disqualifyReasons?: string[];
};

const ContactLeadStatus = ({ status, disqualifyReasons }: Props) => {
  const { t } = useTranslation();
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    getArrowProps,
  } = usePopperTooltip(
    {
      interactive: true,
      offset: [10, 10],
      delayHide: 200,
    },
    { strategy: 'fixed' }
  );

  const isInProgress =
    status === LeadStatus['wait-qualification'] ||
    status === LeadStatus['qc-qualification'];
  const isDisqualified =
    status === LeadStatus['auto-qc-disqualified'] ||
    status === LeadStatus.disqualified ||
    status === LeadStatus['qc-disqualified'];

  const isIconVisible = isDisqualified || status === LeadStatus.qualified;

  return (
    <span className={styles.status}>
      {isIconVisible ? (
        <IconSVG
          name={IconsNames.thumb_up_filled}
          className={cn(styles.icon, {
            [styles.disqualified]: isDisqualified,
            [styles.qualified]: status === LeadStatus.qualified,
          })}
        />
      ) : (
        <span
          className={cn(styles.round, {
            [styles.pending]: isInProgress,
            [styles.reserved]: status === LeadStatus.reserved,
          })}
          title="indicator"
        />
      )}

      {isDisqualified
        ? t('lead.status.disqualified')
        : t(`lead.status.${status}`)}

      {!!disqualifyReasons?.length && (
        <IconSVG
          name={IconsNames.help_outline}
          ref={setTriggerRef}
          className={styles.tip}
        />
      )}
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: cn('tooltip-container', styles.tooltip),
          })}
        >
          <span {...getArrowProps({ className: 'tooltip-arrow' })} />

          <p className={styles.reasonsTitle}>
            {t('common.field.disqualification-reasons')}
          </p>
          <ul className={styles.reasonsList}>
            {disqualifyReasons?.map((reason, index) => (
              <li key={index}>{reason}</li>
            ))}
          </ul>
        </div>
      )}
    </span>
  );
};

export default ContactLeadStatus;
