export enum AssetDistributionType {
  'no-preference' = 'no-preference',
  'no-of-leads' = 'count',
  percentage = 'percentage',
  'equally-distributed' = 'equally-distributed',
}

export enum AssetContentType {
  productVideo = 'product-video',
  advertiseVideo = 'advertise-video',
  whitepaperEbook = 'whitepaper-ebook',
  caseStudy = 'case-study',
  webinarOnDemand = 'webinar-on-demand',
}

export const VIDEO_ASSET_CONTENT_TYPES = [
  AssetContentType.productVideo,
  AssetContentType.advertiseVideo,
  AssetContentType.webinarOnDemand,
];

export enum AssetTypes {
  it = 'it',
  finance = 'finance',
  hr = 'hr',
  marketing = 'marketing',
  sales = 'sales',
  accounting = 'accounting',
}

export enum AssetStatus {
  draft = 'draft',
  published = 'published',
}

export enum AssetHTMLStatus {
  draft = 'draft',
  submitted = 'submitted',
}

export enum AssetAttachedType {
  file = 'file',
  link = 'link',
  product = 'product',
}

export enum ProductAssetsOrdering {
  newToOld = 'new-to-old',
  oldToNew = 'old-to-new',
  popularity = 'popularity',
  custom = 'custom',
}

export const VIDEO_ASSET_ALLOWED_MIME_TYPES = ['video/mp4'];

export const PROD_QUESTIONS_COMPANY_SLUG = 'ukg';
export const PROD_QUESTIONS_PRODUCT_SLUG = ['ukg-ready', 'ukg-pro'];
export const PROD_QUESTIONS_ASSET_SLUGS = [
  'empowered-decision-making-for-purchasing-hr-t-6jca',
  'how-to-identify-when-its-time-to-leave-a-peo',
  'how-much-is-your-obsolete-hr-technology-costing-yo',
  'transforming-payroll-through-digitization',
  'transforming-payroll-through-digitization-exgn',
  'how-much-is-your-obsolete-hr-technology-costi-ukim',
  'how-to-identify-when-its-time-to-leave-a-peo-0dbo',
  'empowered-decision-making-for-purchasing-hr-t-loxy',
];
export const PROD_SELECT_OPTIONS = [
  { value: '4-6 months', label: '4-6 months' },
  { value: '7-12 months', label: '7-12 months' },
  { value: '13-24 months', label: '13-24 months' },
  { value: 'No plans to evaluate', label: 'No plans to evaluate' },
];
