import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import type { AxiosError } from 'axios';
import {
  CreateCampaignValues,
  DeliveryLabels,
} from 'components/CreateCampaign/models';

import InputWrapper from 'components/common/InputWrapper';
import ToggleSwitch from 'components/common/ToggleSwitch';
import Error from 'components/common/Error';
import Button from 'components/common/Button';
import DatePicker from 'components/common/DatePicker';

import Milestone from 'components/CreateCampaign/Step1/Delivery/Milestone';
import BlockHeader from 'components/CreateCampaign/Step1/BlockHeader';
import BlockWrapper from 'components/CreateCampaign/Step1/BlockWrapper';

import useTranslateSelectOptions from 'hooks/useTranslateSelectOptions';

import {
  IconsNames,
  MILESTONE_END_DATE_DIFFERENCE,
  MIN_CAMPAIGN_DURATION_DAYS,
} from 'constants/constants';
import {
  milestoneOptionsList,
  milestoneTemplate,
} from 'constants/selectOptions';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import styles from './index.module.scss';

type Props = {
  saveDraftErrors?: AxiosError | null;
};

const Delivery = ({ saveDraftErrors }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext<CreateCampaignValues>();
  const translateOptions = useTranslateSelectOptions();
  const milestoneOptions = translateOptions(milestoneOptionsList);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'campaign_delivery.milestones',
  });

  const addMilestone = () => append({ ...milestoneTemplate });

  const totalLeadAmount = watch('campaign_details.total_leads');
  const fieldsLeadRequested = watch('campaign_delivery.milestones');
  const startDate = watch('campaign_delivery.start_date');
  const endDate = watch('campaign_delivery.end_date');
  const leadsDifference =
    totalLeadAmount -
    fieldsLeadRequested?.reduce((acc, field) => {
      acc += field.total_leads_amount || 0;
      return acc;
    }, 0);

  const milestoneContextError = errors?.campaign_delivery?.milestones?.message;

  const countError =
    typeof milestoneContextError === 'string' && milestoneContextError;
  const milestoneDeadlineTypeError =
    errors.campaign_delivery?.milestone_deadline_type?.value?.message;

  const endDateFromDate = dayjs(startDate)
    .add(MIN_CAMPAIGN_DURATION_DAYS + 1, 'day')
    .toDate();

  return (
    <>
      <BlockHeader
        heading={t('campaign.delivery')}
        description={t('campaign.manage-delivery-dates')}
        iconClassName={styles.icon}
        iconName={IconsNames.calendar}
      />
      <BlockWrapper className={styles.wrapper}>
        <div className={styles.row}>
          <InputWrapper
            label="common.field.front-load"
            wrapperClasses={styles.switcher}
            isMediumInput
          >
            <Controller
              name="campaign_delivery.is_front_load_scoring"
              control={control}
              render={({ field }) => (
                <ToggleSwitch
                  id="frontLoad"
                  register={field}
                  checked={field.value}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper
            isMediumInput
            label={`common.field.${DeliveryLabels.start_date}`}
            wrapperClasses={styles.date}
            validationError={errors.campaign_delivery?.start_date?.message}
            submitError={
              saveDraftErrors?.response?.data?.campaign_delivery
                ?.start_date?.[0]
            }
          >
            <Controller
              name="campaign_delivery.start_date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value || ''}
                  fromMonth={new Date()}
                  onChange={val => {
                    onChange(val);
                    if (endDate) {
                      trigger('campaign_delivery.end_date');
                    }
                    fields.forEach((_, index) =>
                      trigger(
                        `campaign_delivery.milestones.${index}.delivery_date`
                      )
                    );
                  }}
                  placeholder={t('common.field.set-start-date')}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper
            isMediumInput
            label={`common.field.${DeliveryLabels.end_date}`}
            wrapperClasses={styles.date}
            validationError={errors.campaign_delivery?.end_date?.message}
            submitError={
              saveDraftErrors?.response?.data?.campaign_delivery?.end_date?.[0]
            }
          >
            <Controller
              name="campaign_delivery.end_date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value || ''}
                  onChange={val => {
                    onChange(val);
                    fields.forEach((_, index) =>
                      trigger(
                        `campaign_delivery.milestones.${index}.delivery_date`
                      )
                    );
                    setTimeout(() => {
                      trigger('campaign_delivery.end_date');
                    }, 0);
                  }}
                  placeholder={t('common.field.set-end-date')}
                  fromMonth={endDateFromDate}
                />
              )}
            />
          </InputWrapper>
        </div>

        <div className={styles.row}>
          <div className={styles.info}>
            <p className={styles.total}>
              {t('campaign.leads-allocated')}: {totalLeadAmount || '-'}
            </p>

            {countError && (
              <Error message={countError} className={styles.error} />
            )}
          </div>
          <div className={styles.type}>
            <InputWrapper
              isMediumInput
              label={`common.field.${DeliveryLabels.milestone}`}
              wrapperClasses={styles.select}
              validationError={milestoneDeadlineTypeError}
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_delivery
                    ?.milestone_deadline_type
                )
                  ? saveDraftErrors?.response?.data?.campaign_delivery
                      ?.milestone_deadline_type[0]
                  : undefined
              }
            >
              <Controller
                control={control}
                name="campaign_delivery.milestone_deadline_type"
                defaultValue={milestoneOptions[0]}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={getDefaultSelectStylesWithError({
                      error: !!milestoneDeadlineTypeError,
                      isSmall: true,
                    })}
                    defaultValue={milestoneOptions[0]}
                    options={milestoneOptions}
                  />
                )}
              />
            </InputWrapper>
            {!!endDate && (
              <p className={styles.max}>
                {t('common.error.max-milestone-date')}
                <span>
                  {dayjs(endDate)
                    .subtract(MILESTONE_END_DATE_DIFFERENCE, 'day')
                    .format('YYYY-MM-DD')}
                </span>
              </p>
            )}
          </div>

          <ul className={styles.list}>
            {fields.map((milestone, index) => (
              <Milestone
                key={milestone.id}
                data={milestone}
                index={index}
                remove={remove}
                leadsDifference={leadsDifference}
              />
            ))}
            <Button
              onClick={addMilestone}
              type="button"
              iconProps={{ name: IconsNames.plus }}
            >
              {t('common.button.add-milestone')}
            </Button>
          </ul>
        </div>
      </BlockWrapper>
    </>
  );
};

export default Delivery;
