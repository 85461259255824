import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { isDesktop } from 'react-device-detect';

import type { AxiosError } from 'axios';
import type { ProductResponse } from 'types/models';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import Button from 'components/common/Button';

import ProductItem from 'components/ManageProductsContent/ProductItem';

import { IconsNames, UserTypes } from 'constants/constants';
import { PrivatePaths } from 'constants/routes';

import getResponseError from 'helpers/getResponseError';

import useAuth from 'contexts/AuthContext';

import styles from './index.module.scss';

const ManageProductsContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, axios } = useAuth();

  const companySlug = user?.company.slug;

  const navigateToCreateProduct = () => {
    navigate(`${PrivatePaths.CREATE}`);
  };

  const {
    data: products,
    error: getProductsError,
    isLoading,
  } = useQuery<ProductResponse[], AxiosError>(
    ['products', companySlug],
    async () => {
      try {
        const { data } = await axios.get<ProductResponse[]>(
          `/companies/${companySlug}/products/`
        );
        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: !!user && user.current_group_name === UserTypes.admin,
      refetchOnWindowFocus: false,
    }
  );

  const isNoProducts = !isLoading && !products?.length && !getProductsError;

  return (
    <>
      <div className={styles.create}>
        {isNoProducts && <p>{t('manage-products.no-products')}</p>}
        {isDesktop && (
          <Button
            type="button"
            className={styles.button}
            onClick={navigateToCreateProduct}
            iconProps={{ name: IconsNames.plus, isRightPosition: true }}
          >
            {t('common.button.create-product')}
          </Button>
        )}
      </div>
      {isLoading && <Loader className={styles.loading} />}
      {getProductsError && (
        <Error message={getResponseError(getProductsError)} />
      )}

      <ul className={styles.list}>
        {!!products?.length &&
          products.map(product => (
            <ProductItem
              key={product.id}
              product={product}
              companySlug={companySlug}
            />
          ))}
      </ul>
    </>
  );
};

export default ManageProductsContent;
