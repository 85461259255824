import { useRef } from 'react';
import { useClickAway } from 'react-use';
import cn from 'classnames';

import PageTitle from 'components/common/PageContainer/PageTitle';
import Menu from 'components/common/PageContainer/Menu';
import CurrentBalance from 'components/common/CurrentBalance';
import SettingsMenu from 'components/common/PageContainer/SettingsMenu';

import useAside from 'contexts/AsideContext';
import useModal from 'contexts/ModalContext';
import useEscapeEvent from 'hooks/useEscapeEvent';

import styles from './index.module.scss';

type PageContainerProps = {
  path?: string[];
  readOnly?: boolean;
  color?: string;
  onBeforeAction?: (func: () => void) => void;
  rightComponent?: React.ReactNode | null | ((props: unknown) => JSX.Element);
  backLinkPath?: string;
  isWhiteBackground?: boolean;
  isWithoutPadding?: boolean;
};

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  path,
  readOnly = false,
  color,
  onBeforeAction,
  rightComponent,
  backLinkPath,
  isWhiteBackground,
  isWithoutPadding,
}) => {
  const { AsideContent, setAsideContent } = useAside();
  const { isModalVisible } = useModal();
  const asideRef = useRef(null);

  const closeAside = () => {
    if (!isModalVisible) setAsideContent(null);
  };
  useClickAway(asideRef, closeAside);
  useEscapeEvent(closeAside);

  return (
    <>
      <Menu readOnly={readOnly} />
      <div className={styles.container}>
        <header className={styles.header}>
          <CurrentBalance className={styles.balance} />
          <SettingsMenu onBeforeAction={onBeforeAction} readOnly={readOnly} />
        </header>
        <main
          className={cn(styles.main, {
            [styles.white]: isWhiteBackground,
            [styles.withoutPadding]: isWithoutPadding,
          })}
        >
          {!!path?.length && (
            <PageTitle
              path={path}
              color={color}
              backLinkPath={backLinkPath}
              rightComponent={rightComponent}
            />
          )}
          {children}
        </main>
        <aside
          className={cn(styles.aside, { [styles.open]: !!AsideContent })}
          ref={asideRef}
        >
          {AsideContent}
        </aside>
        <div id="balance-checkout" className={styles.checkout} />
      </div>
    </>
  );
};

export default PageContainer;
