import PageContainer from 'components/common/PageContainer';
import CampaignDetails from 'components/CampaignDetails';
import CampaignManage from 'components/CampaignManage';

import useAuth from 'contexts/AuthContext';

import { UserTypes } from 'constants/constants';

const Campaign = () => {
  const { user } = useAuth();

  const isAdmin = user?.current_group_name === UserTypes.admin;

  return (
    <PageContainer path={['campaign.campaign']}>
      {user && isAdmin && <CampaignDetails />}
      {user && !isAdmin && <CampaignManage />}
    </PageContainer>
  );
};

export default Campaign;
