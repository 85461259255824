import { useMutation } from 'react-query';

import type { UseFormSetValue } from 'react-hook-form';
import type {
  CreateCampaignValues,
  TALFile,
} from 'components/CreateCampaign/models';
import type { TALFileResponse } from 'types/models';
import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';

const useSaveTAL = ({
  campaignId,
  setFormValue,
}: {
  campaignId?: number | string;
  setFormValue?: UseFormSetValue<CreateCampaignValues>;
}) => {
  const { axios } = useAuth();
  const { convertTALRequestBody, convertTALResponse } =
    useConvertCampaignData();

  const mutation = useMutation<
    { file: TALFileResponse; index?: number },
    AxiosError,
    { file: Partial<TALFile>; index?: number }
  >(
    async ({ file, index }) => {
      try {
        const requestBody = { ...file };
        delete requestBody.tal_file;
        const { data } = await axios.patch<TALFileResponse>(
          `/campaigns/${campaignId}/target-account-lists/${file.id}/`,
          convertTALRequestBody(requestBody)
        );

        return { file: data, index };
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: ({ file, index }) => {
        if (setFormValue && typeof index === 'number') {
          setFormValue(
            `campaign_files.target_accounts.${index}`,
            convertTALResponse(file)
          );
        }
      },
    }
  );

  return mutation;
};

export default useSaveTAL;
