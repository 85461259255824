import { useState } from 'react';
import { POSITIVE_NUMBER_REGEXP } from 'utils/validations';

import { getNumberWithCommas } from 'helpers/formatNumbers';

type Props = {
  field: {
    onChange: (value?: number | string) => void;
    value: number | string | undefined;
  };
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  hasCommas?: boolean;
  prefix?: string;
  max?: number;
};

const NumberField = ({
  field,
  placeholder,
  disabled,
  max,
  hasCommas = false,
  prefix,
  className,
}: Props) => {
  const formatInputValue = (value: number) =>
    `${prefix || ''}${hasCommas ? getNumberWithCommas(value) : value}`;

  const [inputValue, setInputValue] = useState(
    formatInputValue(Number(field.value))
  );

  const inputHandler = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const valueToNumber = Number(value.replace(POSITIVE_NUMBER_REGEXP, ''));
    const resultValue = Number.isNaN(valueToNumber) ? 0 : valueToNumber;
    if (hasCommas || prefix) {
      setInputValue(formatInputValue(resultValue));
    }
    const isMoreThanMax =
      typeof max === 'number' &&
      resultValue > max &&
      resultValue > (field.value || 0);
    if (isMoreThanMax) return;

    field.onChange(resultValue);
  };

  const currentValue = hasCommas || prefix ? inputValue : field.value;

  return (
    <input
      type="text"
      {...field}
      onChange={inputHandler}
      placeholder={placeholder}
      disabled={disabled}
      value={currentValue === null ? '' : currentValue}
      className={className}
    />
  );
};

export default NumberField;
