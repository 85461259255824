const SECOND_PART_LENGTH = 5;
const DOTS = '...';

export default (string: string, maxLength: number) => {
  const firstPartEndIndex = maxLength - SECOND_PART_LENGTH - DOTS.length;

  if (!string) return;

  return string.length < maxLength
    ? string
    : `${string.slice(0, firstPartEndIndex)}...${string.slice(
        -SECOND_PART_LENGTH
      )}`;
};
