import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { OfferStatuses } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  status: OfferStatuses;
  isVendor?: boolean;
  className?: string;
};

const OfferStatus = ({ status, isVendor = false, className }: Props) => {
  const { t, i18n } = useTranslation();

  const statusText =
    isVendor && i18n.exists(`offers.statuses.${status}-vendor`)
      ? t(`offers.statuses.${status}-vendor`)
      : t(`offers.statuses.${status}`);

  return (
    <span
      className={cn(styles.status, className, {
        [styles['vendor-approval']]:
          status === OfferStatuses['wait-vendor-approve'],
        [styles.finished]: status === OfferStatuses.finished,
        [styles.declined]: status === OfferStatuses.declined,
        [styles.accepted]: status === OfferStatuses.accepted,
        [styles.counterbid]: status === OfferStatuses.counterbid,
        [styles.canceled]: status === OfferStatuses.canceled,
      })}
    >
      {statusText}
    </span>
  );
};

export default OfferStatus;
