import { Navigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import AppLayout from 'layouts/AppLayout';

import { PublicPaths } from 'constants/routes';
import { LocalStorageKeys } from 'constants/constants';

const PrivateRoute = () => {
  const [token] = useLocalStorage<string>(LocalStorageKeys.TOKEN);
  const [OTPDevice] = useLocalStorage<string>(LocalStorageKeys.OTP_DEVICE_ID);

  return token && OTPDevice ? (
    <AppLayout />
  ) : (
    <Navigate to={`/${PublicPaths.LOG_IN}`} />
  );
};

export default PrivateRoute;
