import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Sentry from '@sentry/react';

import type { AssetValues } from 'components/CreateCampaign/models';

import ModalWindow from 'components/common/ModalWindow';
import Steps from 'components/common/Steps';
import Button from 'components/common/Button';
import Error from 'components/common/Error';
import Step1 from 'components/common/CreateAssetModal/Step1';
import Step2 from 'components/common/CreateAssetModal/Step2';
import IconSVG from 'components/UI/IconSVG';

import useModal from 'contexts/ModalContext';

import { validationSchemaCreateAsset } from 'components/common/CreateAssetModal/validationSchema';
import getCroppedImg from 'utils/cropImage';

import { IconsNames, StepNavigation } from 'constants/constants';
import { AssetAttachedType, AssetContentType } from 'constants/assets';

import styles from './index.module.scss';

type Props = {
  defaultValues?: Partial<AssetValues>;
  assetId?: string | number;
  contentType?: AssetContentType;
  onSubmit: (asset: AssetValues) => void;
  isInCampaignUse?: boolean;
  isSelectProductPage?: boolean;
  isEditWarningVisible?: boolean;
};

enum StepName {
  'general' = 'general',
  'thumbnail' = 'thumbnail',
}

const steps = [
  {
    name: StepName.general,
    title: 'common.modal.general',
    index: 0,
    Component: Step1,
  },
  {
    name: StepName.thumbnail,
    title: 'common.modal.thumbnail',
    index: 1,
    Component: Step2,
  },
];

const CreateAssetModal = ({
  assetId,
  contentType,
  defaultValues,
  isInCampaignUse = false,
  isSelectProductPage = false,
  onSubmit,
  isEditWarningVisible = false,
}: Props) => {
  const [step, setStep] = useState(steps[0]);
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [getImageError, setGetImageError] = useState('');

  const methods = useForm<AssetValues>({
    mode: 'onChange',
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          asset_type: defaultValues.attached_file
            ? AssetAttachedType.file
            : AssetAttachedType.link,
        }
      : {
          asset_type: AssetAttachedType.file,
          content_type: contentType && {
            value: contentType,
            label: t(`common.field.${contentType}`),
          },
        },
    resolver: yupResolver(validationSchemaCreateAsset(isSelectProductPage)),
  });

  const goToStep = (name: StepName | StepNavigation) => {
    const stepIndex = steps.findIndex(item => item.name === name);
    if (typeof stepIndex === 'number') setStep(steps[stepIndex]);
  };

  const handleAssetSubmit = async (data: AssetValues) => {
    if (step.index !== steps.length - 1) {
      setStep(prevStep => steps[prevStep.index + 1]);
      return;
    }

    setIsLoading(true);
    try {
      const croppedLandingImage =
        data.landing_image?.file &&
        data.landing_image?.renderLink &&
        data.landingImageCroppingArea &&
        (await getCroppedImg(
          data.landing_image?.renderLink,
          data.landingImageCroppingArea
        ));
      setIsLoading(false);
      onSubmit({
        ...data,
        landing_image: croppedLandingImage
          ? croppedLandingImage
          : data.landing_image,
      });
      closeModal();
    } catch (err) {
      setIsLoading(false);
      setGetImageError(t('common.error.something-went-wrong'));
      Sentry.captureMessage(
        `Get cropping image error: ${
          data.landing_image?.renderLink
        }, ${JSON.stringify(data.landingImageCroppingArea)}`
      );
    }
  };

  const handleBack = () => {
    setStep(prevStep => steps[prevStep.index - 1]);
  };

  const lastStepSubmitButtonText = assetId
    ? t('common.button.save')
    : t('common.button.add');
  const modalTitlePostfix = t(
    `common.field.${contentType || 'asset'}`
  ).toLowerCase();

  return (
    <>
      <form
        onSubmit={methods.handleSubmit(handleAssetSubmit)}
        aria-label="create asset form"
      >
        <ModalWindow
          primaryTitle={
            assetId
              ? `${t('common.button.edit')} ${modalTitlePostfix}`
              : `${t('common.button.add')} ${modalTitlePostfix}`
          }
          className={styles.modal}
        >
          <div className={styles.wrapper}>
            <div>
              <Steps<StepName>
                steps={steps}
                step={step}
                goToStep={goToStep}
                className={styles.steps}
                isVertical
              />
              {isEditWarningVisible && (
                <p className={styles.warning}>
                  <IconSVG name={IconsNames.warning_filled} />
                  {t('manage-products.changes-will-be-applied')}
                </p>
              )}
            </div>
            <FormProvider {...methods}>
              <step.Component
                isSelectContentTypeAvailable={!contentType}
                isInCampaignUse={isInCampaignUse}
                isSelectProductPage={isSelectProductPage}
              />
            </FormProvider>
          </div>
          {getImageError && <Error message={getImageError} />}
          <div className={styles.buttons}>
            <Button white type="button" onClick={closeModal}>
              {t('common.button.cancel')}
            </Button>
            {step.index > 0 && (
              <Button white type="button" onClick={handleBack}>
                {t('common.button.back')}
              </Button>
            )}
            <Button type="submit" isLoading={isLoading}>
              {step.index === steps.length - 1
                ? lastStepSubmitButtonText
                : t('common.button.next')}
            </Button>
          </div>
        </ModalWindow>
      </form>
    </>
  );
};

export default CreateAssetModal;
