import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';

import { TwoFATypes, TwoFADevice } from 'components/TwoFA/models';

import Button from 'components/common/Button';
import InputWrapper from 'components/common/InputWrapper';

import useAuth from 'contexts/AuthContext';

import { validationSchemaCreateDevice } from 'utils/validations';

import { getDevicesDictionary } from 'helpers/twoFA';
import getResponseError from 'helpers/getResponseError';

import useAddAuthMethod from 'components/Profile/Security/useAddAuthMethod';

import styles from 'components/Profile/Security/index.module.scss';

type Props = {
  setCurrentStep: (value: number | ((prevVar: number) => number)) => void;
  setResponseData: React.Dispatch<React.SetStateAction<TwoFADevice | null>>;
};

const Step1 = ({ setCurrentStep, setResponseData }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const devicesDictionary = getDevicesDictionary(user?.devices);
  const devices = devicesDictionary?.[TwoFATypes.totp];

  const { register, handleSubmit, formState } = useForm<{ password: string }>({
    resolver: yupResolver(validationSchemaCreateDevice(true)),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [isShowPassword, setIsShowPassword] = useState(true);

  const { mutate, error, isLoading } = useAddAuthMethod({
    devices,
    type: TwoFATypes.totp,
    setCurrentStep,
    setResponseData,
  });

  const onSubmit = (data: { password: string }) => {
    mutate(data);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <h2 className={styles.title}>
        {t('auth.enter-method', { type: 'password' })}
      </h2>
      <p className={styles.text}>{t('auth.need-verify')}</p>
      <InputWrapper
        label="common.field.password"
        setIsHiddenInputContent={setIsShowPassword}
        validationError={formState.errors.password?.message}
        submitError={error ? getResponseError(error) : undefined}
        wrapperClasses={styles.inputWrapper}
        isMediumInput
      >
        <input
          type={isShowPassword ? 'password' : 'text'}
          {...register('password')}
        />
      </InputWrapper>
      <Button className={styles.button} type="submit" isLoading={isLoading}>
        {t('common.button.continue')}
      </Button>
    </form>
  );
};

export default Step1;
