import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import { PublicPaths } from 'constants/routes';

import styles from './index.module.scss';

type Props = {
  title: string;
  hideLink?: boolean;
  subtitle?: string;
  linkClass?: string;
};

const AuthHeader = ({ title, hideLink, subtitle, linkClass }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {!hideLink && (
        <Link
          to={`/${PublicPaths.LOG_IN}`}
          className={cn(styles.login, linkClass)}
        >
          <IconSVG name={IconsNames.arrow} className={styles.arrow} />
          <span>{t('auth.back-to-login')}</span>
        </Link>
      )}

      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
};

export default AuthHeader;
