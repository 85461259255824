import { useFieldArray, useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import Select from 'react-select';

import type {
  CreateCampaignValues,
  SALFile,
} from 'components/CreateCampaign/models';
import type { AxiosError } from 'axios';
import type { MultiValue, ActionMeta } from 'react-select';
import type { CompanySALFile } from 'types/models';

import UploadFile from 'components/common/UploadFile';
import InputWrapper from 'components/common/InputWrapper';
import LoaderScreen from 'components/common/LoaderScreen';

import SALFileItem from 'components/CreateCampaign/Step1/Specification/UploadSALFiles/SALFileItem';

import useAuth from 'contexts/AuthContext';
import useUploadMultipleFiles from 'hooks/useUploadMultipleFiles';

import getResponseError from 'helpers/getResponseError';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import { UserTypes } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  isGetLoading: boolean;
  getSALError: AxiosError | null;
};

const UploadSALFile = ({ isGetLoading, getSALError }: Props) => {
  const { t } = useTranslation();
  const { control, getValues, reset } = useFormContext<CreateCampaignValues>();
  const { axios, user } = useAuth();
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'campaign_files.suppression_accounts',
  });
  const { field } = useController({
    name: 'campaign_files.company_suppression_accounts',
    control,
  });

  const { data: companyFiles, isLoading: isCompanyFilesLoading } = useQuery(
    ['get-company-files-sal', user?.company.id],
    async () => {
      try {
        const { data } = await axios.get<CompanySALFile[]>(
          `/companies/${user?.company.id}/suppression-account-lists/`
        );

        return data.map(item => ({
          value: item.id,
          label: item.sal_file_name,
        }));
      } catch (err) {
        throw err;
      }
    },
    {
      enabled:
        !!user?.company.id && user.current_group_name === UserTypes.admin,
      refetchOnWindowFocus: false,
    }
  );

  const {
    mutate: handleSelectCompanyFiles,
    isLoading: isRemoveCompanyFileLoading,
    error: handleSelectError,
  } = useMutation<
    MultiValue<{ value: number; label: string }>,
    AxiosError,
    {
      id?: number;
      value: MultiValue<{ value: number; label: string }>;
      meta: ActionMeta<{
        value: number;
        label: string;
      }>;
    }
  >(
    async ({ id, value, meta }) => {
      try {
        if (id) {
          await axios.post(
            `/campaigns/${getValues().id}/suppression-account-lists/${id}/${
              meta.action === 'remove-value' ? 'remove' : 'add'
            }/`
          );
        }
        return value;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: field.onChange,
    }
  );

  const { mutate, isLoading, error } = useUploadMultipleFiles<SALFile>({
    insert,
    url: `/campaigns/${getValues().id}/suppression-account-lists/`,
    createFormData: file => {
      const formData = new FormData();
      formData.append('sal_file', file);
      formData.append('sal_file_name', file.name);

      return formData;
    },
    setCampaign: (campaignData: CreateCampaignValues) => reset(campaignData),
    campaignData: getValues(),
  });

  const insertMultipleFiles = (index: number, files: File[]) => {
    mutate({ index, files });
  };

  const isFilesLength = !!fields.length;
  const isSubmitErrors = error && getResponseError(error);

  return (
    <UploadFile
      accept={[
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ]}
      title="common.field.suppression-account-list"
      sign={t('common.field.multiple-sal-files')}
      onChangeOptions={{
        insertMultiple: insertMultipleFiles,
        uploadedFilesLength: fields.length,
      }}
      isLoading={isGetLoading}
      error={getSALError?.message || isSubmitErrors || undefined}
    >
      {!!companyFiles?.length && (
        <InputWrapper
          submitError={
            handleSelectError ? getResponseError(handleSelectError) : ''
          }
          wrapperClasses={styles.select}
          label="common.field.choose-sal"
        >
          <Select
            options={companyFiles}
            isLoading={isCompanyFilesLoading || isRemoveCompanyFileLoading}
            styles={getDefaultSelectStylesWithError({
              error: !!handleSelectError,
              isSmall: true,
            })}
            isMulti
            isClearable={false}
            placeholder={t('common.field.select')}
            noOptionsMessage={() => t('common.field.no-options')}
            {...field}
            onChange={(val, meta) => {
              handleSelectCompanyFiles({
                id: meta.removedValue?.value || meta.option?.value,
                value: val,
                meta,
              });
            }}
          />
        </InputWrapper>
      )}
      {isFilesLength && (
        <ul className={styles.list}>
          {fields.map((item, index) => (
            <SALFileItem
              key={item.id}
              index={index}
              remove={remove}
              item={item}
            />
          ))}
        </ul>
      )}
      {isLoading && <LoaderScreen />}
    </UploadFile>
  );
};

export default UploadSALFile;
