import cn from 'classnames';

import styles from './index.module.scss';

type Props = {
  legend: string;
  wrapperClassName?: string;
};

const FieldsetWrapper: React.FC<Props> = ({
  children,
  legend,
  wrapperClassName,
}) => (
  <fieldset className={styles.fieldset}>
    <legend className={styles.legend}>{legend}</legend>
    <div className={cn(styles.wrapper, wrapperClassName)}>{children}</div>
  </fieldset>
);

export default FieldsetWrapper;
