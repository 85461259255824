import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useQueryClient, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import type { AxiosError } from 'axios';
import type { CampaignResponse } from 'types/models';

import Button from 'components/common/Button';
import ModalWindow from 'components/common/ModalWindow';
import Loader from 'components/common/LoaderScreen/Loader';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import { IconsNames, CampaignStatuses } from 'constants/constants';

import useGetReportTemplateDownload from 'hooks/api/useGetReportTemplateDownload';

import getResponseError from 'helpers/getResponseError';

import styles from '../index.module.scss';

enum StatusCampaign {
  pause = 'pause',
  continue = 'continue',
}

const ActionButtons = ({ isDataEmpty }: { isDataEmpty: boolean }) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const { axios, user } = useAuth();
  const { campaignId } = useParams();
  const queryClient = useQueryClient();

  const campaignData = queryClient.getQueryData<CampaignResponse>([
    'campaign',
    campaignId?.toString(),
    user?.current_group_name,
    user?.company.id.toString(),
  ]);

  const isPaused =
    campaignData?.campaign_details?.status === CampaignStatuses.paused;

  const currentStatus = isPaused
    ? StatusCampaign.continue
    : StatusCampaign.pause;

  const { mutate, isLoading: isLoadingPause } = useMutation<
    { status: CampaignStatuses },
    AxiosError,
    void
  >(
    async () => {
      try {
        const url = isPaused
          ? `/campaigns/${campaignId}/continue/`
          : `/campaigns/${campaignId}/pause/`;
        const { data } = await axios.post<{ status: CampaignStatuses }>(url);
        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(
          [
            'campaign',
            campaignId?.toString(),
            user?.current_group_name,
            user?.company.id.toString(),
          ],
          {
            ...campaignData,
            campaign_details: {
              ...campaignData?.campaign_details,
              status: data.status,
            },
          }
        );

        closeModal();
      },
      onError: error =>
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(error)}
            />
          ),
        }),
    }
  );

  const handleStatusCampaign = () => {
    mutate();
  };

  const openConfirmModal = () => {
    openModal({
      Content: (
        <ModalWindow
          title={t('campaign.campaign-status', {
            currentStatusCapitalized: t(`common.button.${currentStatus}`),
          })}
          description={t('campaign.confirmation-status', {
            currentStatus: t(`common.button.${currentStatus}`).toLowerCase(),
          })}
          positiveButtonProps={{
            type: 'button',
            onClick: handleStatusCampaign,
            children: t('common.button.yes'),
            white: true,
          }}
          negativeButtonProps={{
            type: 'button',
            onClick: closeModal,
            children: t('common.button.cancel'),
          }}
        />
      ),
    });
  };

  const { isLoading: isLoadingDownloadReport, refetch } =
    useGetReportTemplateDownload(campaignId);

  const handleDownloadReport = () => {
    refetch();
  };

  const isFinishedCampaign =
    campaignData?.campaign_details.status === CampaignStatuses.finished;
  const isForcePausedCampaign =
    campaignData?.campaign_details.status === CampaignStatuses.forcePaused;

  return (
    <div className={styles.actions}>
      {!isFinishedCampaign && !isForcePausedCampaign && (
        <>
          {isLoadingPause && (
            <Loader className={styles.loadingDownloadReport} />
          )}
          {!isLoadingPause && (
            <Button
              type="button"
              iconProps={{
                name: isPaused
                  ? IconsNames.dropdown_indicator
                  : IconsNames.pause,
                className: isPaused ? styles.iconContinue : styles.iconPause,
                isRightPosition: true,
              }}
              white
              onClick={openConfirmModal}
            >
              {t(`common.button.${currentStatus}`)}
            </Button>
          )}
        </>
      )}

      {isLoadingDownloadReport && (
        <Loader className={styles.loadingDownloadReport} />
      )}
      {!isLoadingDownloadReport && (
        <Button
          disabled={isDataEmpty}
          iconProps={{
            name: IconsNames.download,
            className: cn(styles.icon, styles.download),
            isRightPosition: true,
          }}
          type="button"
          onClick={handleDownloadReport}
        >
          {t('common.button.download-report')}
        </Button>
      )}
    </div>
  );
};

export default ActionButtons;
