import { useParams, useNavigate, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';
import type { Path } from 'react-hook-form';

import AuthHeader from 'components/common/AuthHeader';
import Loader from 'components/common/LoaderScreen';
import AuthForm from 'components/common/AuthForm';
import Error from 'components/common/Error';

import { PublicPaths } from 'constants/routes';

import { validationSchemaResetPassword } from 'utils/validations';

import { InputData } from 'types/models';

import styles from './index.module.scss';

type ActivationResend = {
  email: string;
};
type Field = InputData<ActivationResend> & { name: Path<ActivationResend> };

const reActivationFields: Field[] = [
  {
    name: 'email',
    type: 'email',
    required: true,
    label: 'email',
  },
];

const ActivateAccount = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    mutate: activate,
    isLoading,
    error,
    reset,
  } = useMutation<undefined, AxiosError, { uid: string; token: string }>(
    async userData => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/accounts/activation/`,
          userData
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => {
        navigate(PublicPaths.LOG_IN);
      },
    }
  );

  useEffectOnce(() => {
    if (uid && token) {
      activate({ uid, token });
    } else {
      navigate(PublicPaths.LOG_IN);
    }
  });

  const {
    mutate: reActivate,
    isLoading: reActivationLoading,
    error: activationError,
    isSuccess,
  } = useMutation<ActivationResend, AxiosError, ActivationResend>(
    async email => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/accounts/activation/resend/`,
          email
        );

        return data;
      } catch (err) {
        throw err;
      }
    }
  );

  const onSubmit = (data: ActivationResend) => {
    reset();
    reActivate(data);
  };

  const isLinkBroken =
    error?.response?.status === 400 && error.response.data.uid;
  const isTokenExpired =
    error?.response?.status === 400 && error.response.data.token;
  const isAccountAlreadyActivated = error?.response?.status === 403;
  const loading = isLoading || reActivationLoading;
  const isReActivationFailed = activationError?.response?.status === 400;

  return (
    <div className={styles.wrapper}>
      <AuthHeader title={t('auth.activate-account')} hideLink />
      {isSuccess && <p>{t('auth.activation-email-success')}</p>}

      {(isReActivationFailed || isAccountAlreadyActivated) && (
        <div className={styles.activation}>
          <p className={styles.paragraph}>
            {t(
              isAccountAlreadyActivated
                ? 'auth.already-activated'
                : 'common.error.something-went-wrong'
            )}
          </p>
          <p className={styles.paragraph}>
            {t('auth.please-try-to')}
            <Link to={`/${PublicPaths.LOG_IN}`} className={styles.link}>
              {t('common.button.login')}
            </Link>
          </p>
          {isReActivationFailed && (
            <p className={styles.paragraph}>
              {t('common.field.or')}
              <Link to={`/${PublicPaths.SIGN_UP}`} className={styles.link}>
                {t('common.button.sign-up')}
              </Link>
              {t('auth.if-you-did-not')}
            </p>
          )}
        </div>
      )}

      {isLinkBroken && (
        <Error message={t('auth.no-such-account')} className={styles.error} />
      )}
      {isTokenExpired && (
        <Error message={t('auth.link-expired')} className={styles.error} />
      )}

      {(isLinkBroken || isTokenExpired || isReActivationFailed) && (
        <AuthForm<ActivationResend>
          fields={reActivationFields}
          btnTitle="submit"
          onSubmit={onSubmit}
          validationSchema={validationSchemaResetPassword}
        />
      )}

      {loading && <Loader />}
    </div>
  );
};

export default ActivateAccount;
