import type { Step4ViewProps } from 'components/CreateCampaign/models';
import type { CampaignResponse } from 'types/models';

import Details from 'components/CreateCampaign/Step4/CampaignSettings/Details';
import LeadDetails from 'components/CreateCampaign/Step4/CampaignSettings/LeadDetails';
import Specification from 'components/CreateCampaign/Step4/CampaignSettings/Specification';
import Delivery from 'components/CreateCampaign/Step4/CampaignSettings/Delivery';
import Asset from 'components/CreateCampaign/Step4/CampaignSettings/Asset';
import Script from 'components/CreateCampaign/Step4/CampaignSettings/Script';

type Props =
  | (Omit<Step4ViewProps, 'campaignData'> & { campaignData: CampaignResponse })
  | Step4ViewProps;

const CampaignSettings = ({
  campaignData,
  getSALRequestSettings,
  getTALRequestSettings,
  getReportTemplateRequestSettings,
  getAssetsRequestSettings,
  getLeadFileRequestSettings,
}: Props) => (
  <>
    <Details data={campaignData.campaign_details} />
    <LeadDetails
      isGDPRCompliance={campaignData.campaign_details.is_gdpr_compliant}
      isCASLCompliance={campaignData.campaign_details.is_casl_compliant}
      getReportTemplateRequestSettings={getReportTemplateRequestSettings}
      getLeadFileRequestSettings={getLeadFileRequestSettings}
    />
    <Specification
      specification={campaignData.campaign_specification}
      getSALRequestSettings={getSALRequestSettings}
      getTALRequestSettings={getTALRequestSettings}
    />
    <Asset
      details={campaignData.campaign_details}
      assets={
        campaignData && 'campaign_files' in campaignData
          ? campaignData.campaign_files?.assets
          : undefined
      }
      getAssetsRequestSettings={getAssetsRequestSettings}
    />
    <Script />
    <Delivery data={campaignData.campaign_delivery} />
  </>
);

export default CampaignSettings;
