import { useTranslation } from 'react-i18next';

import PageContainer from 'components/common/PageContainer';
import Button from 'components/common/Button';

import ServersList from 'components/IntegrationsContent/VendorContent/ServersList';
import FormServer from 'components/IntegrationsContent/VendorContent/FormServer';

import useModal from 'contexts/ModalContext';

import { IconsNames } from 'constants/constants';

const VendorContent = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();

  const openAddServerModal = () => {
    openModal({
      Content: <FormServer />,
    });
  };

  return (
    <PageContainer
      path={['integrations.title.vendor']}
      rightComponent={
        <Button
          type="button"
          onClick={openAddServerModal}
          iconProps={{ name: IconsNames.plus }}
        >
          <span>{t('integrations.server')}</span>
        </Button>
      }
    >
      <ServersList />
    </PageContainer>
  );
};

export default VendorContent;
