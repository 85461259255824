import { useQuery, useQueryClient } from 'react-query';

import type { AxiosError } from 'axios';
import type { ReportResponse } from 'types/models';

import useAuth from 'contexts/AuthContext';

import { ReportUploadStatus } from 'constants/constants';

export const useGetUploadedReport = ({
  campaignId,
  processedFileId,
  setProcessedFileId,
  enabled = true,
}: {
  campaignId?: number | string;
  processedFileId: number | null;
  setProcessedFileId: (id: number | null) => void;
  enabled?: boolean;
}) => {
  const { axios } = useAuth();
  const queryClient = useQueryClient();

  const query = useQuery<ReportResponse | void, AxiosError>(
    ['report', campaignId],
    async () => {
      try {
        const result = await axios.get<ReportResponse>(
          `/vendor-campaigns/${campaignId}/report-uploads/${processedFileId}/`
        );

        return result.data;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled: !!processedFileId && enabled,
      refetchInterval: 2000,
      initialData: undefined,
      onSuccess: file => {
        if (!file || !enabled) return;

        const isUploadingFinished =
          file.status === ReportUploadStatus.done ||
          file.status === ReportUploadStatus.failed;
        if (isUploadingFinished) {
          setProcessedFileId(null);
          queryClient.setQueryData<ReportResponse[]>(
            ['reports', campaignId?.toString()],
            reportsCache => [
              file,
              ...(reportsCache?.filter(item => item.id !== file.id) || []),
            ]
          );
          queryClient.invalidateQueries(['reported-leads']);
        }
      },
    }
  );

  return query;
};
