import PageContainer from 'components/common/PageContainer';
import ProfileContent from 'components/Profile';

const Profile = () => {
  return (
    <PageContainer isWithoutPadding isWhiteBackground>
      <ProfileContent />
    </PageContainer>
  );
};

export default Profile;
