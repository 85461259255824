import { useEffect } from 'react';
import { Controller, useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { AxiosError } from 'axios';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import DatePicker from 'components/common/DatePicker';
import InputWrapper from 'components/common/InputWrapper';
import NumberField from 'components/common/NumberField';
import IconSVG from 'components/UI/IconSVG';

import { IconsNames, MilestoneType } from 'constants/constants';

import { getMilestoneDeliveryDateSettings } from 'helpers/createCampaign';

import { validateMilestonesLeadsCount } from 'utils/validations';

import styles from './index.module.scss';

type Props = {
  index: number;
  data: { id: string };
  remove: (index: number) => void;
  saveDraftErrors?: AxiosError | null;
  leadsDifference: number;
};

const Milestone = ({
  index,
  data: { id },
  remove,
  leadsDifference,
  saveDraftErrors,
}: Props) => {
  const { t } = useTranslation();
  const {
    control,
    trigger,
    formState: { errors },
    getValues,
    clearErrors,
  } = useFormContext<CreateCampaignValues>();

  const milestones = useWatch({
    name: 'campaign_delivery.milestones',
    control,
  });

  useEffect(() => {
    if (errors.campaign_delivery?.milestones?.message) {
      const isLeadsCountValid = validateMilestonesLeadsCount(
        milestones,
        getValues().campaign_details.total_leads
      );
      if (isLeadsCountValid) {
        clearErrors('campaign_delivery.milestones');
      }
    }
  }, [errors.campaign_delivery?.milestones?.message, milestones]);

  const deleteField = () => remove(index);

  const isDeleteButtonVisible = (milestones?.length || 0) > 1;
  const milestoneSelectValue = useWatch({
    name: 'campaign_delivery.milestone_deadline_type',
    control,
  })?.value;
  const campaignStartDate = useWatch({
    name: 'campaign_delivery.start_date',
    control,
  });
  const campaignEndDate = useWatch({
    name: 'campaign_delivery.end_date',
    control,
  });
  const isMonthly = milestoneSelectValue === MilestoneType.monthly;

  const isError = errors && errors?.campaign_delivery?.milestones?.[index];
  const isSubmitError =
    saveDraftErrors?.response?.data?.campaign_delivery?.milestones;

  const { fromMonth, fromMonthMonthly, toMonth, monthlyError } =
    getMilestoneDeliveryDateSettings({
      t,
      campaignStartDate,
      campaignEndDate,
      isError,
    });

  return (
    <li key={id} className={styles.item}>
      <div className={cn({ [styles.date]: isMonthly })}>
        <Controller
          name={`campaign_delivery.milestones.${index}.delivery_date`}
          control={control}
          render={({ field: { value, onChange } }) => {
            const handleChange = (newValue: string) => {
              onChange(newValue);
              setTimeout(() => {
                const milestoneCountArray = new Array(milestones?.length).fill(
                  undefined
                );
                milestoneCountArray.forEach((_, ind) => {
                  trigger(`campaign_delivery.milestones.${ind}.delivery_date`);
                });
              }, 0);
            };

            return !isMonthly ? (
              <InputWrapper
                isMediumInput
                label="common.field.custom-date"
                wrapperClasses={styles.label}
                validationError={isError?.delivery_date?.message || ''}
                maxErrorLength={20}
                submitError={
                  Array.isArray(
                    saveDraftErrors?.response?.data?.campaign_delivery
                      ?.milestones
                  ) &&
                  Array.isArray(isSubmitError[index]?.delivery_date) &&
                  isSubmitError[index]?.delivery_date[0]
                    ? saveDraftErrors?.response?.data?.campaign_delivery
                        ?.name[0]
                    : undefined
                }
              >
                <DatePicker
                  value={value || ''}
                  onChange={handleChange}
                  isMonthly={isMonthly}
                  wrapperClassName={styles.date}
                  fromMonth={fromMonthMonthly}
                  toMonth={toMonth}
                />
              </InputWrapper>
            ) : (
              <DatePicker
                value={value || ''}
                onChange={handleChange}
                isMonthly={isMonthly}
                wrapperClassName={styles.date}
                fromMonth={fromMonth}
                isSmallSelector
                validationError={
                  monthlyError ||
                  (Array.isArray(
                    saveDraftErrors?.response?.data?.campaign_delivery
                      ?.milestones
                  ) &&
                    Array.isArray(isSubmitError[index]?.delivery_date) &&
                    isSubmitError[index]?.delivery_date[0])
                }
                toMonth={toMonth}
              />
            );
          }}
        />
      </div>
      <div className={styles.leads}>
        <InputWrapper
          isMediumInput
          label="common.field.leads"
          wrapperClasses={styles.label}
          validationError={
            isError?.total_leads_amount?.message ||
            isSubmitError?.[index]?.total_leads_amount?.[0] ||
            ''
          }
        >
          <Controller
            name={`campaign_delivery.milestones.${index}.total_leads_amount`}
            control={control}
            render={({ field }) => (
              <NumberField
                field={field}
                placeholder={t('common.field.no-of-leads')}
              />
            )}
          />
        </InputWrapper>
        <p
          className={cn(styles.leads__difference, {
            [styles.error]: leadsDifference < 0,
          })}
        >
          {t('common.field.of')}
          {leadsDifference}
        </p>
      </div>
      {isDeleteButtonVisible && (
        <button type="button" className={styles.delete} onClick={deleteField}>
          <IconSVG name={IconsNames.delete} />
        </button>
      )}
    </li>
  );
};

export default Milestone;
