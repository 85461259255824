import { useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import cn from 'classnames';

import {
  Transaction,
  PaymentStatus,
} from 'components/PaymentsComponents/models';

import Table from 'components/common/Table';
import Loader from 'components/common/LoaderScreen/Loader';
import IconSVG from 'components/UI/IconSVG';
import Button from 'components/common/Button';
import Pagination from 'components/common/Pagination';

import TransactionStatus from 'components/PaymentsComponents/TransactionStatus';

import useGetTransactionsHistory from 'components/PaymentsComponents/useGetTransactionsHistory';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

enum TableAccessors {
  id = 'id',
  transactionType = 'transaction-type',
  paymentType = 'payment-type',
  paymentTerms = 'payment-terms',
  createdAt = 'created-at',
  updatedAt = 'updated-at',
  status = 'status',
  amount = 'amount',
  credits = 'credits',
  createdBy = 'created-by',
  download = 'download',
}

export const PAGE_SIZE = 10;

const TransactionsHistory = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const scrollToRef = useRef(null);

  const { data, isLoading, dataUpdatedAt, isRefetching } =
    useGetTransactionsHistory({
      page,
      pageSize: PAGE_SIZE,
      keepPreviousData: true,
    });

  const columnsData = useMemo(
    () => [
      {
        Header: t(`common.field.${TableAccessors.id}`),
        accessor: TableAccessors.id,
      },
      {
        Header: t(`common.field.${TableAccessors.transactionType}`),
        accessor: TableAccessors.transactionType,
      },
      {
        Header: t(`common.field.${TableAccessors.paymentType}`),
        accessor: TableAccessors.paymentType,
      },
      {
        Header: t(`common.field.${TableAccessors.paymentTerms}`),
        accessor: TableAccessors.paymentTerms,
      },
      {
        Header: t(`common.field.${TableAccessors.createdAt}`),
        accessor: TableAccessors.createdAt,
        Cell: ({ value }: { value: string }) =>
          dayjs(value).format('D MMM, YYYY'),
      },
      {
        Header: t(`common.field.${TableAccessors.updatedAt}`),
        accessor: TableAccessors.updatedAt,
        Cell: ({ value }: { value: string }) =>
          dayjs(value).format('D MMM, YYYY'),
      },
      {
        Header: t(`common.field.${TableAccessors.status}`),
        accessor: TableAccessors.status,
        Cell: ({ value }: { value: PaymentStatus }) => (
          <TransactionStatus status={value} />
        ),
      },
      {
        Header: `${t(`common.field.${TableAccessors.amount}`)}, $`,
        accessor: TableAccessors.amount,
      },
      {
        Header: t(`common.field.${TableAccessors.credits}`),
        accessor: TableAccessors.credits,
      },
      {
        Header: t(`common.field.${TableAccessors.createdBy}`),
        accessor: TableAccessors.createdBy,
      },
      {
        Header: '',
        accessor: TableAccessors.download,
        Cell: ({ value }: { value: Transaction }) => {
          const isPendingInvoice =
            value.status === PaymentStatus.pending && value.pay_link;
          const link = isPendingInvoice
            ? isPendingInvoice
            : value.invoice_pdf_link;

          return link ? (
            <a
              href={link}
              download="invoice.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.downloadLink}
            >
              <Button
                type="button"
                white
                iconProps={{
                  name: isPendingInvoice
                    ? IconsNames.arrow_open
                    : IconsNames.download,
                }}
              >
                {isPendingInvoice
                  ? t('common.button.view-invoice')
                  : t('common.button.download')}
              </Button>
            </a>
          ) : null;
        },
      },
    ],
    []
  );

  const rowsData = useMemo(() => {
    return data?.items.map(transaction => {
      const paymentsType = transaction.payment_terms
        ? t('payments.method.payWithTerms')
        : t(`payments.method.${transaction.payment_method}`);

      return {
        [TableAccessors.id]: transaction.id,
        [TableAccessors.transactionType]: t(
          `payments.type.${transaction.payment_type}`
        ),
        [TableAccessors.paymentType]: transaction.payment_method
          ? paymentsType
          : '',
        [TableAccessors.paymentTerms]: transaction.payment_terms,
        [TableAccessors.createdAt]: transaction.created_at,
        [TableAccessors.updatedAt]: transaction.updated_at,
        [TableAccessors.status]: transaction.status,
        [TableAccessors.amount]: transaction.amount_currency,
        [TableAccessors.credits]: transaction.amount_credits,
        [TableAccessors.createdBy]: `${transaction.created_by.first_name} ${transaction.created_by.last_name}`,
        [TableAccessors.download]: transaction,
      };
    });
  }, [data, dataUpdatedAt]);

  return (
    <div ref={scrollToRef} className={styles.wrapper}>
      {(isLoading || isRefetching) && (
        <Loader
          size={isRefetching ? 24 : undefined}
          className={cn(styles.loader, { [styles.refetching]: isRefetching })}
        />
      )}
      {!isLoading && (
        <>
          <Table
            tableOptions={{ columns: columnsData, data: rowsData || [] }}
            wrapperClassName={styles.table}
            rowClassName={cn(styles.row, {
              [styles.noData]: !rowsData?.length,
            })}
            headClassName={styles.thead}
            isFirstColumnFixed
            isEvenRowGrey
            noDataComponent={
              <div className={styles.empty}>
                <IconSVG className={styles.icon} name={IconsNames.payments} />
                <p className={styles.title}>{t('payments.no-payments')}</p>
                <p className={styles.description}>
                  {t('payments.deposit-and-campaign-launch')}
                </p>
              </div>
            }
          />
          <Pagination
            totalCount={data?.total || 0}
            pageSize={PAGE_SIZE}
            currentPage={page}
            setPage={setPage}
            scrollToRef={scrollToRef}
            className={styles.pagination}
          />
        </>
      )}
    </div>
  );
};

export default TransactionsHistory;
