import { useTranslation } from 'react-i18next';

import type { UseQueryResult } from 'react-query';
import type { AxiosError } from 'axios';
import type { ReportTemplateResponse, LeadFileResponse } from 'types/models';

import Loader from 'components/common/LoaderScreen/Loader';

import styles from './index.module.scss';

type Props = {
  isCASLCompliance: boolean;
  isGDPRCompliance: boolean;
  getReportTemplateRequestSettings: UseQueryResult<
    ReportTemplateResponse | undefined,
    AxiosError
  >;
  getLeadFileRequestSettings: UseQueryResult<
    LeadFileResponse | undefined,
    AxiosError
  >;
};

const LeadDetails = ({
  isCASLCompliance,
  isGDPRCompliance,
  getReportTemplateRequestSettings: { data: reportTemplate, isLoading },
  getLeadFileRequestSettings: { data: leadFile, isLoading: isLeadFileLoading },
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className={styles.subheading}>{t('campaign.lead-details')}</h4>
      <ul className={styles.grid}>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.casl-compliant')}:</h5>
          <input
            type="checkbox"
            checked={isCASLCompliance}
            disabled
            className={styles.input}
          />
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.gdpr-compliant')}:</h5>
          <input
            type="checkbox"
            checked={isGDPRCompliance}
            disabled
            className={styles.input}
          />
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.report-template')}:</h5>
          {isLoading && <Loader size={20} />}
          {reportTemplate && (
            <a className={styles.link} href={reportTemplate.report_template}>
              {t('common.button.view-file')}
            </a>
          )}
          {!isLoading && !reportTemplate && '—'}
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.lead-file')}:</h5>
          {isLeadFileLoading && <Loader size={20} />}
          {leadFile && (
            <a className={styles.link} href={leadFile?.lead_file}>
              {t('common.button.view-file')}
            </a>
          )}
          {!isLeadFileLoading && !leadFile && '—'}
        </li>
      </ul>
    </>
  );
};

export default LeadDetails;
