import { useTranslation } from 'react-i18next';

import type { UseMutateFunction } from 'react-query';
import type { AxiosError } from 'axios';
import type { TwoFADevice } from 'components/TwoFA/models';

type Props = {
  device: TwoFADevice;
  sendOTP: UseMutateFunction<TwoFADevice, AxiosError, TwoFADevice, unknown>;
};

const DeviceItem = ({ device, sendOTP }: Props) => {
  const { t } = useTranslation();

  const handleSelectDevice = () => sendOTP(device);

  const emailDeviceName = 'email' in device && device.email;
  const phoneDeviceName = 'phone_number' in device && device.phone_number;

  return (
    <li key={device.persistent_id} onClick={handleSelectDevice}>
      {emailDeviceName || phoneDeviceName || t('auth.authentication-app')}
    </li>
  );
};

export default DeviceItem;
