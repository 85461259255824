import { FC } from 'react';
import cn from 'classnames';

import {
  TableContent,
  TableHeader,
  TableTitle,
  TableCompoundProps,
} from 'components/CampaignDetails/InfoSectionWrapper/compound';

import styles from './index.module.scss';

type CampaignTableComposition = {
  Header: FC<TableCompoundProps>;
  Content: FC<TableCompoundProps>;
  Title: FC<TableCompoundProps>;
};

const InfoSectionWrapper: FC<TableCompoundProps> & CampaignTableComposition = ({
  children,
  className,
  ...props
}) => (
  <div className={cn(styles.table, className)} {...props}>
    {children}
  </div>
);

InfoSectionWrapper.Header = TableHeader;
InfoSectionWrapper.Content = TableContent;
InfoSectionWrapper.Title = TableTitle;

export default InfoSectionWrapper;
