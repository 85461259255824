import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import cn from 'classnames';

import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import BlockWrapper from 'components/CreateCampaign/Step1/BlockWrapper';
import Error from 'components/common/Error';

import IconSVG from 'components/UI/IconSVG';

import { getIsAutomaticallyDistributed } from 'helpers/assets';

import { AssetDistributionType } from 'constants/assets';
import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const DistributionField = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<CreateCampaignValues>();

  const { field: distributionField } = useController({
    control,
    name: 'campaign_details.asset_distribution',
  });

  const handleSelectDistribution = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    const assetsValues = getValues('campaign_files.assets');
    if (getIsAutomaticallyDistributed(value)) {
      assetsValues?.forEach((_, inx) =>
        setValue(`campaign_files.assets.${inx}.distribution_value`, 0)
      );
    }
    distributionField.onChange(value);
  };

  const selectedDistribution = useWatch({
    control,
    name: 'campaign_details.asset_distribution',
  });
  const totalLeads = useWatch({
    control,
    name: 'campaign_details.total_leads',
  });
  const assets = useWatch({
    control,
    name: 'campaign_files.assets',
  });
  const specifiedDistributionSum = assets?.reduce((acc, item) => {
    acc += item.distribution_value || 0;
    return acc;
  }, 0);
  const isPercentage =
    selectedDistribution === AssetDistributionType.percentage;
  const maxDistributionCount = isPercentage ? 100 : totalLeads;
  const remaining = maxDistributionCount - (specifiedDistributionSum || 0);
  const distributionPossibleValues = Object.values(AssetDistributionType);
  const isAutomaticallyDistributed = getIsAutomaticallyDistributed(
    selectedDistribution || ''
  );

  return (
    <>
      <BlockWrapper
        className={cn(styles.wrapper, {
          [styles.open]:
            isOpen || (selectedDistribution && !isAutomaticallyDistributed),
        })}
      >
        <details open={isOpen}>
          <summary
            className={styles.summary}
            onClick={() => setIsOpen(prev => !prev)}
            ref={distributionField.ref}
          >
            <p>
              {t('common.field.allocation-type')}
              {errors.campaign_details?.asset_distribution?.type ===
                'required' && (
                <Error
                  className={styles.error}
                  message={t(
                    errors.campaign_details?.asset_distribution.message?.toString() ||
                      ''
                  )}
                />
              )}
              <span className={styles.selected}>
                {selectedDistribution && !isOpen
                  ? t(`asset-distribution.${selectedDistribution}`)
                  : ''}
              </span>
            </p>
            <IconSVG
              name={IconsNames.arrow}
              className={cn(styles.arrow, { [styles.arrow__open]: isOpen })}
            />
          </summary>
        </details>
        {isOpen &&
          distributionPossibleValues.map(value => (
            <label key={value} className={styles.label}>
              <input
                type="radio"
                {...distributionField}
                value={value}
                onChange={handleSelectDistribution}
                checked={selectedDistribution === value}
              />
              {t(`asset-distribution.${value}`)}
            </label>
          ))}
      </BlockWrapper>
      {!isAutomaticallyDistributed && !!selectedDistribution && (
        <div className={styles.remaining}>
          <p>
            <IconSVG
              name={
                remaining === 0
                  ? IconsNames.success_checkmark
                  : IconsNames.warning_filled
              }
              className={cn({
                [styles.warning]: remaining !== 0,
              })}
            />
            {remaining}
            {isPercentage && '%'} {t('lead.status.remaining').toLowerCase()}
          </p>
        </div>
      )}
    </>
  );
};

export default DistributionField;
