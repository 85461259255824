import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { FileRejection } from 'react-dropzone';

import Error from 'components/common/Error';
import Loader from 'components/common/LoaderScreen/Loader';
import IconSVG from 'components/UI/IconSVG';

import { IconsNames, FILE_NAME_MAX_LENGTH } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  accept?: string | string[];
  sign?: string;
  title?: string;
  wrapperClasses?: string;
  inputClasses?: string;
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
  topText?: string;
  imageSrc?: string;
  isTitleHidden?: boolean;
  iconName?: IconsNames;
  maxSize?: number;
  secondaryTitle?: string;
  isSmallIcon?: boolean;
  errorFilledImageClassName?: string;
  backgroundImageClassName?: string;
  onChangeOptions:
    | {
        maxFiles?: undefined;
        uploadedFilesLength: number;
        insertMultiple: (
          uploadedFilesLength: number,
          files: File[],
          renderLink?: string
        ) => void;
      }
    | {
        withPreview?: boolean;
        maxFiles: 1;
        insertSingle: (
          file: File | null,
          renderLink?: string | ArrayBuffer | null | undefined
        ) => void;
      };
};

const UploadFile = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>(
  (
    {
      sign,
      title,
      wrapperClasses,
      accept,
      children,
      onChangeOptions,
      isLoading,
      error,
      disabled,
      topText,
      imageSrc,
      isTitleHidden,
      inputClasses,
      maxSize,
      secondaryTitle,
      iconName,
      isSmallIcon,
      errorFilledImageClassName,
      backgroundImageClassName,
    },
    ref
  ) => {
    const [validationError, setError] = useState('');
    const { t } = useTranslation();

    const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setError('');
      const isMaxSizeError =
        !!maxSize && acceptedFiles.some(file => file.size > maxSize);
      if (isMaxSizeError) {
        setError(
          `${t('common.error.max-file-size', {
            size: Math.round(maxSize / 1024 ** 2),
          })}${t('common.field.mb')}`
        );
        return;
      }
      if (rejectedFiles.length) {
        const firstRejected = rejectedFiles[0];
        setError(firstRejected.errors[0].message);
      }

      if (onChangeOptions.maxFiles === 1) {
        if (onChangeOptions.withPreview) {
          const reader = new FileReader();
          reader.onload = (event: ProgressEvent<FileReader>) => {
            const readImg = event.target?.result;
            onChangeOptions.insertSingle(acceptedFiles[0], readImg);
          };
          reader.readAsDataURL(acceptedFiles[0]);
        } else {
          onChangeOptions.insertSingle(acceptedFiles[0]);
        }

        return;
      }

      const { insertMultiple, uploadedFilesLength } = onChangeOptions;
      insertMultiple(uploadedFilesLength, acceptedFiles);
    };

    const { getRootProps, getInputProps } = useDropzone({
      maxFiles: onChangeOptions.maxFiles,
      onDrop,
      accept,
      disabled: isLoading || disabled,
      validator: file => {
        if (file.name.length > 100) {
          return {
            message: t('common.error.file-name-length', {
              length: FILE_NAME_MAX_LENGTH,
            }),
            code: 'code',
          };
        }

        return null;
      },
    });

    const errorMessage = validationError || error;

    return (
      <>
        <div className={wrapperClasses}>
          <div className={styles.wrapper}>
            {title && <h3 className={styles.heading}>{t(title)}</h3>}
            <div
              {...getRootProps()}
              className={cn(styles.dropzone, inputClasses)}
            >
              {isLoading && (
                <div className={styles.loader}>
                  <Loader />
                </div>
              )}
              {imageSrc && (
                <div
                  className={cn(
                    styles.backgroundImage,
                    backgroundImageClassName
                  )}
                >
                  <img src={imageSrc} alt="preview" />
                </div>
              )}

              {topText && (
                <p className={cn(styles.topTitle, styles.topTitle)}>
                  {topText}
                </p>
              )}
              <IconSVG
                name={iconName ? iconName : IconsNames.upload_file}
                className={cn(styles.icon, {
                  [styles.smallIcon]: isSmallIcon,
                })}
              />
              <input
                /* @ts-ignore */
                {...{ ...getInputProps(), ref: ref || getInputProps().ref }}
                aria-label="upload file"
              />
              {!isTitleHidden && (
                <>
                  <p
                    className={cn(styles.title, {
                      [styles.customTitle]: !!secondaryTitle,
                    })}
                  >
                    {secondaryTitle || t('common.field.drag-drop')}
                  </p>
                  {!!sign && <p className={styles.sign}>{sign}</p>}
                </>
              )}

              <Error
                message={!imageSrc ? errorMessage || '' : ''}
                className={styles.error}
              />
            </div>
          </div>
          {children}
        </div>
        {imageSrc && errorMessage && (
          <Error
            message={errorMessage || ''}
            className={cn(styles.error, errorFilledImageClassName)}
          />
        )}
      </>
    );
  }
);

export default UploadFile;
