import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import cn from 'classnames';

import type { AxiosError } from 'axios';
import type { CampaignResponse } from 'types/models';

import CampaignsTable from 'components/common/CampaignsTable';
import LoaderScreen from 'components/common/LoaderScreen';
import ModalWindow from 'components/common/ModalWindow';
import Button from 'components/common/Button';
import NoEnoughCreditsModal from 'components/PaymentsComponents/NoEnoughCreditsModal';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';
import useFetchProductsOptions from 'hooks/api/useFetchProductsOptions';

import getResponseError from 'helpers/getResponseError';

import { PrivatePaths } from 'constants/routes';
import { IconsNames } from 'constants/constants';

import rocketMan from 'assets/images/common/rocket-man.svg';
import rocket from 'assets/images/common/rocket.svg';

import styles from './index.module.scss';

type Props = {
  readOnly?: boolean;
  color?: string;
};

const AdminHomeContent = ({ readOnly = false, color }: Props) => {
  const { user, axios } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useModal();
  const { refetch } = useFetchProductsOptions(false);

  const { mutate, isLoading } = useMutation<
    CampaignResponse | void,
    AxiosError
  >(
    async () => {
      try {
        const { data: productsOptions } = await refetch();

        if (!productsOptions?.length) {
          return openModal({
            Content: (
              <ModalWindow
                className={styles.modal}
                title={t('common.modal.to-create-campaign-create-product')}
                positiveButtonProps={{
                  type: 'button',
                  className: styles.product,
                  onClick: () => {
                    navigate(
                      `/${PrivatePaths.PRODUCTS}/${PrivatePaths.CREATE}`
                    );
                    closeModal();
                  },
                  iconProps: { name: IconsNames.plus, isRightPosition: true },
                  children: t('common.button.create-product'),
                }}
              />
            ),
          });
        }

        const { data } = await axios.post<CampaignResponse>('/campaigns/', {
          campaign_details: {
            name: 'Untitled campaign',
            contacts_per_company: 3,
          },
        });

        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: async data => {
        if (data) {
          navigate(
            `/${PrivatePaths.CAMPAIGN}/${PrivatePaths.CREATE}/${data.id}`
          );
          queryClient.setQueryData(
            ['campaign-create-disable-requests', data.id.toString()],
            {
              ...data,
              isJustCreated: true,
            }
          );
        }
      },
      retry: (_, error) => error.response?.status !== 402,
      onError: err => {
        if (err.response?.status === 402) {
          navigate(`/${PrivatePaths.PAYMENTS}`);
          openModal({
            Content: (
              <NoEnoughCreditsModal
                error={err}
                onCheckoutModalClose={async () => {
                  await queryClient.refetchQueries('balance');
                }}
              />
            ),
          });
          return;
        }
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const handleCreateCampaign = () => {
    if (readOnly) return;

    mutate();
  };

  return (
    <>
      <div className={styles.cards}>
        <div className={styles.panel}>
          <div className={styles.text}>
            <p className={styles.admin}>{t('home.admin-portal')}</p>
            <h2 className={styles.run}>{t('home.run-campaign')}</h2>
            <p className={styles.welcome}>
              {t('home.welcome', { name: user?.first_name })}
            </p>
          </div>
          <img
            src={rocketMan}
            alt="rocket"
            aria-hidden="true"
            className={styles.image}
          />
        </div>

        {!isMobile && (
          <div className={styles.create}>
            <img
              src={rocket}
              alt="rocket"
              aria-hidden="true"
              className={styles.rocket}
              width={80}
              height={80}
            />

            <Button
              type="button"
              className={cn(styles.button, { [styles.disabled]: readOnly })}
              style={{ backgroundColor: color || undefined }}
              onClick={handleCreateCampaign}
              withArrow
              isBig
            >
              <span>{t('home.create-campaign')}</span>
            </Button>
          </div>
        )}
      </div>
      <CampaignsTable readOnly={readOnly} />
      {isLoading && <LoaderScreen />}
    </>
  );
};

export default AdminHomeContent;
