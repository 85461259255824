import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { Location } from 'react-router-dom';

import useModal from 'contexts/ModalContext';
import useBlocker from 'hooks/useBlocker';

const useCallbackPrompt = (
  when: boolean,
  renderModalContent: (
    cancelNavigation: () => void,
    setConfirmedNavigation: () => void
  ) => React.ReactNode | null | ((props: unknown) => JSX.Element),
  skipRenderModal?: () => boolean
): (boolean | (() => void))[] => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<{
    location: Location;
  } | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [isReplaceConfirmedNavigation, setIsReplaceConfirmedNavigation] =
    useState(false);
  const { openModal } = useModal();

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const confirmNavigation = useCallback((shouldReplace?: boolean) => {
    setIsReplaceConfirmedNavigation(Boolean(shouldReplace));
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation: { location: Location }) => {
      if (
        !confirmedNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        if (skipRenderModal && skipRenderModal()) {
          setLastLocation(nextLocation);
          confirmNavigation();
          return false;
        }
        openModal({
          Content: () =>
            renderModalContent(cancelNavigation, confirmNavigation),
        });
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation]
  );

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname, {
        replace: isReplaceConfirmedNavigation,
      });
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
};

export default useCallbackPrompt;
