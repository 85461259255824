import { useTranslation } from 'react-i18next';
import { useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import Table from 'components/common/Table';
import CampaignStatus from 'components/common/CampaignStatus';

import useObserver from 'hooks/useObserver';
import useFetchСampaigns from 'hooks/api/useFetchСampaigns';

import getResponseError from 'helpers/getResponseError';

import { PrivatePaths } from 'constants/routes';
import {
  CampaignStatuses,
  VendorCampaignListTableAccessors,
} from 'constants/constants';

import styles from './index.module.scss';

const VendorManagerHomeContent = () => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const {
    fetchNextPage,
    data: campaigns,
    isLoading,
    isFetchingNextPage,
    error,
  } = useFetchСampaigns();

  const columnsData = useMemo(
    () => [
      {
        Header: t('common.field.campaign-name'),
        accessor: VendorCampaignListTableAccessors.name,
      },
      {
        Header: t('common.field.start-date'),
        accessor: VendorCampaignListTableAccessors.start_date,
      },
      {
        Header: t('common.field.end-date'),
        accessor: VendorCampaignListTableAccessors.end_date,
      },
      {
        Header: t('common.field.cpl'),
        accessor: VendorCampaignListTableAccessors.cpl,
      },
      {
        Header: t('common.field.total-leads'),
        accessor: VendorCampaignListTableAccessors.total_leads,
      },
      {
        Header: t('common.field.status'),
        accessor: VendorCampaignListTableAccessors.status,
        Cell: ({ value }: { value: CampaignStatuses }) => (
          <CampaignStatus status={value} />
        ),
      },
      {
        Header: t('campaign.manage'),
        accessor: VendorCampaignListTableAccessors.manage,
        Cell: ({ value }: { value: string }) => (
          <Link to={value} className={styles.view}>
            {t('common.button.view-details')}
          </Link>
        ),
      },
    ],
    []
  );

  const rowsData = useMemo(() => {
    return campaigns?.pages
      .map(page => page.results)
      .flat()
      .map(campaign => ({
        [VendorCampaignListTableAccessors.name]: campaign.campaign_details.name,
        [VendorCampaignListTableAccessors.start_date]:
          campaign.campaign_delivery?.start_date,
        [VendorCampaignListTableAccessors.end_date]:
          campaign.campaign_delivery?.end_date,
        [VendorCampaignListTableAccessors.cpl]:
          campaign.campaign_details?.cost_per_lead,
        [VendorCampaignListTableAccessors.total_leads]:
          campaign.campaign_details.total_leads,
        [VendorCampaignListTableAccessors.status]:
          campaign.campaign_details.status,
        [VendorCampaignListTableAccessors.manage]: `/${PrivatePaths.CAMPAIGN}/${campaign.id}`,
      }));
  }, [campaigns]);

  useObserver(loadMoreRef, (isIntersecting: boolean) => {
    if (isIntersecting) fetchNextPage();
  });

  const isNoCampaigns = !isLoading && !rowsData?.length && !error;
  const isLoader = isLoading || isFetchingNextPage;
  const isCampaigns = !isLoading && !!rowsData?.length;

  return (
    <div className={styles.wrapper}>
      {isCampaigns && (
        <Table tableOptions={{ columns: columnsData, data: rowsData }} />
      )}

      {error && <Error message={getResponseError(error)} />}
      {isLoader && <Loader className={styles.loader} />}
      {isNoCampaigns && <p>{t('campaign.no-campaigns')}</p>}
      <div ref={loadMoreRef} />
    </div>
  );
};

export default VendorManagerHomeContent;
