import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';

import ModalWindow from 'components/common/ModalWindow';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';
import { downloadFile } from 'helpers/downloadFile';

const useGetReportTemplateDownload = (campaignId?: string | number) => {
  const { axios, user } = useAuth();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const { isLoading, refetch } = useQuery<void, AxiosError>(
    ['download-report', user?.current_group_name, campaignId],
    async () => {
      try {
        const { data, headers } = await axios.get<string>(
          `/campaigns/${campaignId}/download-report/`,
          {
            responseType: 'arraybuffer',
          }
        );

        downloadFile(
          data,
          headers['content-type'],
          headers['content-disposition']
        );
      } catch (error) {
        throw error;
      }
    },
    {
      retry: (_, error) => error.response?.status !== 404,
      enabled: false,
      onError: err => {
        if (err.response?.status !== 404) {
          openModal({
            Content: (
              <ModalWindow
                title={t('common.error.something-went-wrong')}
                errorMessage={getResponseError(err)}
              />
            ),
          });
        }
      },
    }
  );

  return { isLoading, refetch };
};

export default useGetReportTemplateDownload;
