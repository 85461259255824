import React, { useRef, useEffect } from 'react';
import cn from 'classnames';

import type { TableToggleAllRowsSelectedProps } from 'react-table';

import styles from './index.module.scss';

const IndeterminateCheckbox: React.FC<
  TableToggleAllRowsSelectedProps & {
    customOnChange?: React.ChangeEventHandler<HTMLInputElement>;
    isMinus?: boolean;
  }
> = React.forwardRef(
  ({ indeterminate, customOnChange, isMinus, ...rest }, ref) => {
    const defaultRef = useRef<
      { indeterminate?: boolean } & React.LegacyRef<HTMLInputElement>
    >(null);

    useEffect(() => {
      if (defaultRef.current) {
        defaultRef.current.indeterminate = indeterminate;
      }
    }, [defaultRef, indeterminate]);

    return (
      <>
        <input
          className={cn(styles.checkbox, { [styles.minus]: isMinus })}
          type="checkbox"
          // @ts-ignore
          ref={defaultRef || ref}
          onChange={customOnChange || rest.onChange}
          {...rest}
        />
      </>
    );
  }
);

export default IndeterminateCheckbox;
