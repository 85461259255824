import { components } from 'react-select';
import { useTranslation } from 'react-i18next';

import type { MenuProps, GroupBase } from 'react-select';
import type { SelectOption } from 'types/models';

import Button from 'components/common/Button';

import styles from './index.module.scss';

interface Props<T extends SelectOption>
  extends MenuProps<T, boolean, GroupBase<T>> {
  selectAll: () => void;
  isSelectAllVisible: boolean;
}

const SelectAllMenu = <T extends SelectOption>(props: Props<T>) => {
  const { t } = useTranslation();

  return (
    <components.Menu {...props}>
      <div className={styles.wrapper}>
        {props.isSelectAllVisible && (
          <Button
            type="button"
            onClick={props.selectAll}
            white
            isSmall
            className={styles.button}
          >
            {t('common.button.select-all')}
          </Button>
        )}
      </div>
      {props.children}
    </components.Menu>
  );
};

export default SelectAllMenu;
