import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterItem } from 'components/common/ReportedLeadsTable/LeadDetailsFilter';

import styles from './index.module.scss';

type FilterListItemProps = {
  item: FilterItem;
  selectHandler: (selectedFilter: FilterItem) => void;
};

const FilterListItem: FC<FilterListItemProps> = ({ item, selectHandler }) => {
  const { t } = useTranslation();

  const selectFilterHandler = () => {
    selectHandler(item);
  };

  return (
    <li onClick={selectFilterHandler} key={item.id} className={styles.wrapper}>
      {t(`common.field.${item.label}`)}
    </li>
  );
};

export default FilterListItem;
