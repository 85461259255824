import { useTranslation } from 'react-i18next';

import ModalWindow from 'components/common/ModalWindow';
import IconSVG from 'components/UI/IconSVG';
import Button from 'components/common/Button';

import useModal from 'contexts/ModalContext';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const Finish = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  return (
    <ModalWindow closeButtonClassName={styles.close} className={styles.wrapper}>
      <div className={styles.content}>
        <IconSVG name={IconsNames.success_checkmark} className={styles.icon} />
        <h2 className={styles.thanks}>
          {t('campaign-deliveries.thank-you-for-importing')}
        </h2>
        <p className={styles.description}>
          {t('campaign-deliveries.your-contacts-processing')}
        </p>

        <Button onClick={closeModal} type="button" className={styles.button}>
          {t('common.button.done')}
        </Button>
      </div>
    </ModalWindow>
  );
};

export default Finish;
