import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import type { ContactUploadResponse } from 'types/models';
import type { AxiosError } from 'axios';

import Table from 'components/common/Table';
import Loader from 'components/common/LoaderScreen/Loader';
import Button from 'components/common/Button';
import Error from 'components/common/Error';

import EmptyData from 'components/CampaignsListDeliveries/EmptyData';
import UploadsStatus from 'components/CampaignsListDeliveries/ContactsUploadHistory/UploadStatus';

import useAuth from 'contexts/AuthContext';

import { UserTypes, ReportUploadStatus, IconsNames } from 'constants/constants';

import getResponseError from 'helpers/getResponseError';

import { ReactComponent as XLSSVG } from 'assets/images/files/excel.svg';

import styles from './index.module.scss';

enum TableAccessors {
  name = 'file-name',
  status = 'status',
  successed = 'successed',
  failed = 'failed',
  total = 'total',
  details = 'details',
}

const ContactsUploadsHistory = () => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const {
    data: uploads,
    isLoading,
    dataUpdatedAt,
    error,
  } = useQuery<ContactUploadResponse[], AxiosError>(
    ['contacts-uploads', id],
    async () => {
      try {
        const { data } = await axios.get<ContactUploadResponse[]>(
          `/vendor-campaigns/${id}/contacts-uploads/`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    { enabled: user?.current_group_name === UserTypes.vendor }
  );

  const columnsData = useMemo(
    () => [
      {
        Header: t(`common.field.${TableAccessors.name}`),
        accessor: TableAccessors.name,
        Cell: ({ value }: { value: string }) => (
          <p className={styles.name}>
            <XLSSVG />
            {value}
          </p>
        ),
      },
      {
        Header: t(`common.field.${TableAccessors.status}`),
        accessor: TableAccessors.status,
        Cell: ({ value }: { value: ReportUploadStatus }) => (
          <UploadsStatus status={value} />
        ),
      },
      {
        Header: t(`common.field.${TableAccessors.successed}`),
        accessor: TableAccessors.successed,
      },
      {
        Header: t(`common.field.${TableAccessors.failed}`),
        accessor: TableAccessors.failed,
      },
      {
        Header: t(`common.field.${TableAccessors.total}`),
        accessor: TableAccessors.total,
      },
      {
        Header: '',
        accessor: TableAccessors.details,
        Cell: ({ value }: { value: ContactUploadResponse }) => {
          const onViewDetails = () => {
            queryClient.setQueryData(
              ['contacts-uploads-details', id?.toString(), value.id.toString()],
              value
            );
          };

          return value.execution_details.length ? (
            <Link to={value.id.toString()} className={styles.details}>
              <Button
                type="button"
                onClick={onViewDetails}
                white
                iconProps={{ name: IconsNames.arrow, isRightPosition: true }}
              >
                {t('common.button.details')}
              </Button>
            </Link>
          ) : null;
        },
      },
    ],
    []
  );

  const rowsData = useMemo(() => {
    return uploads?.map(upload => ({
      [TableAccessors.name]: upload.report_file_name,
      [TableAccessors.status]: upload.status,
      [TableAccessors.successed]: upload.success_rows_number || '–',
      [TableAccessors.total]: upload.total_rows_number || '–',
      [TableAccessors.failed]: upload.failed_rows_number || '–',
      [TableAccessors.details]: upload,
    }));
  }, [uploads, dataUpdatedAt]);

  return (
    <div>
      {error && <Error message={getResponseError(error)} />}
      {!rowsData?.length && !isLoading && (
        <EmptyData text={t('campaign-deliveries.no-upload-history-yet')} />
      )}
      {!!rowsData?.length && (
        <Table
          wrapperClassName={styles.table}
          tableOptions={{
            columns: columnsData,
            data: rowsData || [],
          }}
          headClassName={styles.thead}
          rowClassName={styles.row}
          isEvenRowGrey
        />
      )}
      {isLoading && <Loader size={36} className={styles.loading} />}
    </div>
  );
};

export default ContactsUploadsHistory;
