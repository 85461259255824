import { useMutation } from 'react-query';

import type { AxiosError, AxiosResponse } from 'axios';
import type { CampaignResponse } from 'types/models';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import NoEnoughCreditsModal from 'components/PaymentsComponents/NoEnoughCreditsModal';

import { CampaignCreatingStep } from 'constants/constants';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

const useUploadMultipleFiles = <FileType,>({
  insert,
  createFormData,
  url,
  campaignData,
  setCampaign,
}: {
  insert: (index: number, file: FileType[]) => void;
  createFormData: (file: File) => FormData;
  url: string;
  campaignData: CreateCampaignValues;
  setCampaign: (campaign: CreateCampaignValues) => void;
}) => {
  const { axios } = useAuth();
  const { openModal } = useModal();

  const requestSettings = useMutation<
    { index: number; files: FileType[]; campaign: null | CreateCampaignValues },
    AxiosError,
    { index: number; files: File[] }
  >(
    async ({ files, index }) => {
      try {
        const talFilesPromises: Promise<AxiosResponse<FileType>>[] = [];
        files.forEach(async file => {
          const formData = createFormData(file);

          const data = axios.post<FileType>(url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          talFilesPromises.push(data);
        });

        const result = await Promise.all(talFilesPromises);
        const savedFiles = result.map(axiosResponse => axiosResponse.data);

        const returnData: {
          files: FileType[];
          index: number;
          campaign: null | CreateCampaignValues;
        } = {
          files: savedFiles,
          index,
          campaign: null,
        };

        if (
          campaignData.campaign_details.creating_step !==
          CampaignCreatingStep['first-step']
        ) {
          const { data } = await axios.patch<CampaignResponse>(
            `/campaigns/${campaignData.id}/`,
            { creating_step: CampaignCreatingStep['first-step'] }
          );
          returnData.campaign = {
            ...campaignData,
            campaign_details: {
              ...campaignData.campaign_details,
              creating_step: data.campaign_details.creating_step,
            },
          };
        }

        return returnData;
      } catch (error) {
        throw error;
      }
    },
    {
      onError: err => {
        if (err.response?.status === 402) {
          openModal({
            Content: <NoEnoughCreditsModal error={err} />,
          });
        }
      },
      onSuccess: ({ files, index, campaign }) => {
        insert(index, files);
        if (campaign) setCampaign(campaign);
      },
    }
  );

  return requestSettings;
};

export default useUploadMultipleFiles;
