import { useQuery, useMutation } from 'react-query';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';

import Button from 'components/common/Button';
import Loader from 'components/common/LoaderScreen/Loader';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import { UserTypes } from 'constants/constants';

import { ReactComponent as HubspotSVG } from 'assets/images/integrations/hubspot.svg';

import styles from './index.module.scss';

enum ConnectionStatus {
  enabled = 'enabled',
  disabled = 'disabled',
  failed = 'failed',
}

const HUBSPOT_OAUTH_ID = 'hubspot-oauth';

type HubspotConnectResponse = {
  authorize_url: string;
};

type CRMIntegration = {
  company_id: number;
  crm_platform: string;
  fail_reason: string;
  id: string;
  status: ConnectionStatus;
};

const Hubspot = () => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();
  const { openModal, closeModal } = useModal();

  const {
    data: hubspotStatus,
    isLoading,
    refetch: refetchStatus,
    isSuccess,
  } = useQuery<CRMIntegration | undefined, AxiosError>(
    ['hubspot-connections', user?.current_group_name, user?.company.id],
    async () => {
      try {
        const { data } = await axios.get<CRMIntegration[]>(
          `/crm-integrations/api/${user?.company.id}/connections/`
        );

        return data.find(crm => crm.crm_platform === HUBSPOT_OAUTH_ID);
      } catch (err) {
        throw err;
      }
    },
    { enabled: user?.current_group_name === UserTypes.admin }
  );

  const { mutate: getConnectUrl } = useMutation<
    HubspotConnectResponse,
    AxiosError
  >(
    async () => {
      try {
        const { data } = await axios.post<HubspotConnectResponse>(
          `/crm-integrations/api/${user?.company.id}/connect/hubspot-oauth/init/`
        );
        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: response => {
        window.location.replace(response.authorize_url);
      },
    }
  );

  const { isLoading: isDeleteLoading, mutate: deleteConnection } = useMutation(
    async () => {
      try {
        closeModal();
        await axios.delete(
          `/crm-integrations/api/${user?.company.id}/connections/${hubspotStatus?.id}/`
        );
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => refetchStatus(),
    }
  );

  const isEnabled = hubspotStatus?.status === ConnectionStatus.enabled;
  const isFailed = hubspotStatus?.status === ConnectionStatus.failed;
  const isDisabled = hubspotStatus?.status === ConnectionStatus.disabled;

  const handleClick = () => {
    if (isFailed) {
      deleteConnection();
      getConnectUrl();
      return;
    }
    if (isEnabled) {
      openModal({
        Content: (
          <DeleteConfirmationModal
            instanceName={t('integrations.connection')}
            itemName={`Hubspot ${t('integrations.connection')}`}
            onDelete={deleteConnection}
          />
        ),
      });
      return;
    }
    getConnectUrl();
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.header}>
          <div className={styles.image}>
            <HubspotSVG className={styles.icon} />
          </div>
          {isSuccess && !isDeleteLoading && (
            <div className={styles.controls}>
              <Button
                white={isEnabled}
                type="button"
                className={styles.button}
                onClick={handleClick}
                isSmall
              >
                {!hubspotStatus && t('common.button.connect')}
                {isEnabled && t('common.button.disconnect')}
                {(isFailed || isDisabled) && t('common.button.reconnect')}
              </Button>
              <span
                className={cn(styles.status, {
                  [styles['status--connected']]: isEnabled,
                  [styles['status--reconnect']]: isFailed || isDisabled,
                })}
              >
                {(isFailed || isDisabled) && t('integrations.status.failed')}
                {!hubspotStatus && t('integrations.status.disconnected')}
                {isEnabled && t('integrations.status.connected')}
              </span>
            </div>
          )}
          {(isLoading || isDeleteLoading) && <Loader size={32} />}
        </div>
        <span className={styles.name}>Hubspot</span>
        <p className={styles.description}>
          {isFailed || isDisabled
            ? t('integrations.connection-failed')
            : t('integrations.synchronise-your-leads')}
        </p>
      </div>
    </>
  );
};

export default Hubspot;
