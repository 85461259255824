import PageContainer from 'components/common/PageContainer';

import GroupsTable from 'components/UserGroupsContent/GroupsTable';
import NewGroup from 'components/UserGroupsContent/NewGroup';

const UserGroup = () => {
  return (
    <PageContainer path={['user-groups.heading']}>
      <NewGroup />
      <GroupsTable />
    </PageContainer>
  );
};

export default UserGroup;
