import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';

import Button from 'components/common/Button';
import InputWrapper from 'components/common/InputWrapper';
import PhoneField from 'components/common/PhoneField';
import Error from 'components/common/Error';

import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';
import useUpdateProfileData from 'components/Profile/useUpdateProfileData';

import { validationSchemaProfileContacts } from 'utils/validations';

import styles from './index.module.scss';

type FormValues = {
  email?: string;
  phone_number?: string;
};

const ProfileContacts = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const { register, control, handleSubmit, formState } = useForm<FormValues>({
    resolver: yupResolver(validationSchemaProfileContacts),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      email: user?.email,
      phone_number: user?.phone_number,
    },
  });

  const { mutate, error } = useUpdateProfileData();

  const onSubmit = (data: FormValues) => {
    const formattedData = {
      phone_number: data?.phone_number,
    };
    mutate(formattedData);
  };

  return (
    <>
      <form
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
        aria-label="profile contacts"
      >
        <div className={styles.container}>
          <InputWrapper
            label="common.field.email"
            validationError={formState.errors?.email}
            isMediumInput
          >
            <input
              type="text"
              disabled
              placeholder="jackharlow@leadforce.ai"
              {...register('email')}
            />
          </InputWrapper>
          <InputWrapper
            label="common.field.phone"
            validationError={formState.errors?.phone_number}
            isMediumInput
            aria-lavel="phone_number"
          >
            <PhoneField
              control={control}
              name={'phone_number'}
              placeholder={t('manage-products.phone-placeholder')}
              arial-label="phone field"
            />
          </InputWrapper>
        </div>
        <Button
          type="submit"
          disabled={!formState.isDirty}
          className={cn(styles.submit, {
            [styles.disabled]: false,
          })}
          aria-label="save"
        >
          {t('common.button.save')}
        </Button>
      </form>
      {error && (
        <div className={styles.error}>
          <Error message={error ? getResponseError(error) : ''} />
        </div>
      )}
    </>
  );
};

export default ProfileContacts;
