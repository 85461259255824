import { useMutation } from 'react-query';

import type { UseFormSetValue } from 'react-hook-form';
import type {
  CreateCampaignValues,
  SALFile,
} from 'components/CreateCampaign/models';
import type { SALFileResponse } from 'types/models';
import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';

const useSaveSAL = ({
  campaignId,
  setFormValue,
}: {
  campaignId?: number | string;
  setFormValue?: UseFormSetValue<CreateCampaignValues>;
}) => {
  const { axios } = useAuth();
  const { convertSALResponse, convertSALRequestBody } =
    useConvertCampaignData();

  const mutation = useMutation<
    { file: SALFileResponse; index?: number },
    AxiosError,
    { file: Partial<SALFile>; index?: number }
  >(
    async ({ file, index }) => {
      try {
        const requestBody = { ...file };
        delete requestBody.sal_file;
        const { data } = await axios.patch<SALFileResponse>(
          `/campaigns/${campaignId}/suppression-account-lists/${file.id}/`,
          convertSALRequestBody(requestBody)
        );

        return { file: data, index };
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: ({ file, index }) => {
        if (setFormValue && typeof index === 'number') {
          setFormValue(
            `campaign_files.suppression_accounts.${index}`,
            convertSALResponse(file)
          );
        }
      },
    }
  );

  return mutation;
};

export default useSaveSAL;
