import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'components/common/Button';

import defaultImage from 'assets/images/product/default-image.svg';

import getLongStringView from 'helpers/getLongStringView';

import styles from './index.module.scss';

const MAX_NAME_LENGTH = 25;

type Props = {
  assetTitle?: string;
  assetLogo?: string;
  assetSlug: string;
};

const AssetShortcut = ({ assetTitle, assetLogo, assetSlug }: Props) => {
  const { t } = useTranslation();

  return (
    <Link to={assetSlug} className={styles.wrapper}>
      <img className={styles.logo} src={assetLogo ? assetLogo : defaultImage} />

      <div className={styles.infoContainer}>
        <p className={styles.title}>{t('manage-products.asset-for-you')}</p>
        {assetTitle && (
          <h4 className={styles.assetName}>
            {getLongStringView(assetTitle, MAX_NAME_LENGTH)}
          </h4>
        )}
        <Button className={styles.button} isSmall>
          {t('common.button.explore')}
        </Button>
      </div>
    </Link>
  );
};

export default AssetShortcut;
