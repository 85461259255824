import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  option: string;
  selectVariable: (variable: string) => void;
  setSearchValue: (value: string) => void;
  selectedVariable?: string;
};

const Option = ({
  option,
  selectVariable,
  setSearchValue,
  selectedVariable,
}: Props) => {
  const { t, i18n } = useTranslation();

  const onClick = () => {
    selectVariable(option);
    setSearchValue('');
  };

  const isSelectedVariable = selectedVariable === option;

  return (
    <li
      key={option}
      onClick={onClick}
      contentEditable={false}
      className={cn({
        [styles.selected]: isSelectedVariable,
      })}
    >
      <p contentEditable={false} className={styles.option}>
        <IconSVG name={IconsNames.check_mark} className={styles.checkmark} />
        <span aria-label="script variable option">
          {i18n.exists(`common.field.${option}`)
            ? t(`common.field.${option}`)
            : option}
        </span>
      </p>
    </li>
  );
};

export default Option;
