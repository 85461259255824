import { Outlet } from 'react-router-dom';

import Heading from 'components/DocumentsContent/Heading';

const DocumentsContent = () => {
  return (
    <>
      <Heading />
      <Outlet />
    </>
  );
};

export default DocumentsContent;
