import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type Lazy from 'yup/lib/Lazy';
import type { SubmitHandler, DeepPartial, FieldValues } from 'react-hook-form';
import type { AxiosError } from 'axios';

import InputField from 'components/common/AuthForm/InputField';
import Button from 'components/common/Button';
import Error from 'components/common/Error';
import LoaderScreen from 'components/common/LoaderScreen';

import type { InputData } from 'types/models';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

type FormProps<FormValues extends FieldValues> = {
  fields: Array<InputData<FormValues> | InputData<FormValues>[]>;
  btnTitle: string;
  sign?: JSX.Element;
  onSubmit: SubmitHandler<FormValues>;
  validationSchema: Lazy<any>;
  loading?: boolean;
  submitError?: AxiosError | null;
  defaultValues?: DeepPartial<FormValues> | null;
  cleanAfterSubmit?: boolean;
  disableChanges?: boolean;
  additionalButton?: JSX.Element;
  formClasses?: string;
  isInvited?: boolean;
  isLightBlueButton?: boolean;
};

const Form = <FormValues extends FieldValues>({
  fields,
  btnTitle,
  sign,
  onSubmit,
  validationSchema,
  loading,
  submitError,
  defaultValues,
  cleanAfterSubmit,
  disableChanges,
  additionalButton,
  formClasses,
  isInvited = false,
  isLightBlueButton = false,
}: FormProps<FormValues>) => {
  const methods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: defaultValues ? defaultValues : undefined,
  });
  const { t } = useTranslation();

  const submit = (data: FormValues, event?: React.BaseSyntheticEvent) => {
    event?.preventDefault();
    onSubmit(data);

    if (cleanAfterSubmit) {
      methods.reset();
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={cn(styles.form, formClasses)}
          onSubmit={methods.handleSubmit(submit)}
        >
          {fields.map((fieldData, index) => {
            if (Array.isArray(fieldData)) {
              return (
                <fieldset className={styles.fieldset} key={index}>
                  {fieldData.map(field => (
                    <InputField<FormValues>
                      key={String(field.name)}
                      data={field}
                      register={methods.register}
                      disableChanges={disableChanges}
                      isInvited={isInvited}
                      validationError={
                        submitError?.response?.data || methods.formState.errors
                      }
                      formSettings={methods}
                    />
                  ))}
                </fieldset>
              );
            }

            const { name } = fieldData;

            return (
              <InputField<FormValues>
                key={String(name)}
                data={fieldData}
                register={methods.register}
                disableChanges={disableChanges}
                validationError={
                  submitError?.response?.data || methods.formState.errors
                }
                isInvited={isInvited}
                formSettings={methods}
              />
            );
          })}

          {sign}

          <Button type="submit" darkBlue={!isLightBlueButton} isBig>
            {t(`common.button.${btnTitle}`)}
          </Button>

          {additionalButton}

          <div className={styles.error}>
            <Error message={submitError ? getResponseError(submitError) : ''} />
          </div>

          {loading && <LoaderScreen />}
        </form>
      </FormProvider>
    </>
  );
};

export default Form;
