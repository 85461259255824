import { useTranslation } from 'react-i18next';

import { PricingPlanResponse } from 'types/models';

import Button from 'components/common/Button';

import styles from './index.module.scss';

type Props = {
  planData: PricingPlanResponse;
  isGetADemoAvailable?: boolean;
  onOpenGetDemo?: () => void;
};

const PricingPlan: React.FC<Props> = ({
  planData,
  isGetADemoAvailable,
  onOpenGetDemo,
}) => {
  const { t } = useTranslation();

  const formatPrice = (receivedPrice: number) =>
    receivedPrice === 0 ? t('auth.free') : `$${receivedPrice} `;

  const { name, price, period, pricing_benefits: benefits } = planData;

  return (
    <li className={styles.pricingCard}>
      <h3 className={styles.heading}>{name}</h3>
      <p className={styles.price}>{formatPrice(+price)}</p>
      <span className={styles.period}>{t(`common.field.${period}`)}</span>
      <div className={styles.benefitsWrapper}>
        <p className={styles.benefits}>{benefits}</p>
      </div>
      {isGetADemoAvailable && (
        <Button className={styles.choose} onClick={onOpenGetDemo} white>
          {t('common.button.choose')}
        </Button>
      )}
    </li>
  );
};

export default PricingPlan;
