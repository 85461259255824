import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';
import type { EmailServer } from 'components/IntegrationsContent/VendorContent/models';

import ModalWindow from 'components/common/ModalWindow';

import getResponseError from 'helpers/getResponseError';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

const useAddEmailServer = () => {
  const { axios, user } = useAuth();
  const { t } = useTranslation();
  const { openModal } = useModal();
  const queryClient = useQueryClient();

  const addServer = useMutation<EmailServer, AxiosError, EmailServer>(
    async server => {
      try {
        const { data } = await axios.post<EmailServer>(
          '/mailing-integrations/',
          server
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => {
        openModal({
          Content: (
            <ModalWindow
              successMessage={t('integrations.form.server-successfuly')}
            />
          ),
        });

        await queryClient.refetchQueries('servers-list');
        await queryClient.refetchQueries([
          'check-servers-integrations',
          user?.company.id,
        ]);
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  return addServer;
};

export default useAddEmailServer;
