import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Error from 'components/common/Error';
import Loader from 'components/common/LoaderScreen/Loader';
import IconSVG from 'components/UI/IconSVG';

import useGetBalance from 'hooks/api/useGetBalance';

import { getNumberWithCommas } from 'helpers/formatNumbers';
import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  className?: string;
};

const CurrentBalance = ({ className }: Props) => {
  const { t } = useTranslation();
  const { data: balance, error, isLoading: isLoadingBalance } = useGetBalance();

  return (
    <div className={cn(styles.value, className)}>
      {isLoadingBalance ? (
        <Loader size={36} />
      ) : (
        <span className={styles.icon}>
          <IconSVG name={IconsNames.wallet} />
        </span>
      )}
      <p className={styles.current}>
        {getNumberWithCommas(balance?.in_account || 0)}
        <span className={styles.sign}>{t('common.field.balance')}</span>
      </p>
      {!!error && (
        <Error message={getResponseError(error)} className={styles.error} />
      )}
    </div>
  );
};

export default CurrentBalance;
