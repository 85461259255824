import { useTranslation } from 'react-i18next';

import IconSVG from 'components/UI/IconSVG';
import Button from 'components/common/Button';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';

import useModal from 'contexts/ModalContext';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  iconName: IconsNames;
  onDelete?: (() => void) | null;
  sectionName?: string;
  iconClassName?: string;
};

const BlockHeading: React.FC<Props> = ({
  iconName,
  children,
  onDelete,
  sectionName,
  iconClassName,
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();

  const openConfirmDeleteModal = () => {
    openModal({
      Content: (
        <DeleteConfirmationModal
          instanceName={t('campaign.section')}
          itemName={t('script.all-the-following', {
            sectionName,
          })}
          onDelete={() => {
            closeModal();
            onDelete?.();
          }}
        />
      ),
    });
  };

  return (
    <h4 className={styles.heading}>
      <IconSVG name={iconName} className={iconClassName} />
      {children}
      {onDelete && (
        <Button
          type="button"
          isOnlyIcon
          iconProps={{ name: IconsNames.trash }}
          className={styles.delete}
          onClick={openConfirmDeleteModal}
          aria-label={`delete section ${sectionName}`}
        />
      )}
    </h4>
  );
};

export default BlockHeading;
