import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Button from 'components/common/Button';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  isNextDisabled: boolean;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  stepsCount: number;
  currentStep: number;
  className?: string;
  onGoToNextStep?: () => void;
  isNextStepLoading?: boolean;
};

const NavigationButtons = ({
  isNextDisabled,
  setStep,
  className,
  currentStep,
  stepsCount,
  onGoToNextStep,
  isNextStepLoading,
}: Props) => {
  const { t } = useTranslation();

  const handleGoToNextStep = () => {
    if (isNextStepLoading) return;
    if (onGoToNextStep) {
      return onGoToNextStep();
    }
    setStep(prev => prev + 1);
  };

  const nextButtonTitle =
    currentStep + 1 === stepsCount
      ? t('common.button.finish')
      : t('common.button.continue');

  return (
    <div className={cn(styles.wrapper, className)}>
      {currentStep !== 0 && (
        <Button
          iconProps={{
            name: IconsNames.arrow_select_right,
            className: styles.next,
          }}
          onClick={() => setStep(prev => prev - 1)}
          white
          aria-label="previous step"
        >
          {t('common.button.back')}
        </Button>
      )}
      <Button
        iconProps={{
          name: IconsNames.arrow_select_right,
          isRightPosition: true,
        }}
        disabled={isNextDisabled}
        onClick={handleGoToNextStep}
        aria-label="next step"
        isLoading={isNextStepLoading}
      >
        {nextButtonTitle}
      </Button>
    </div>
  );
};

export default NavigationButtons;
