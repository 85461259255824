import { useMemo } from 'react';
import cn from 'classnames';
import DOMPurify from 'dompurify';

import Editor, { RichTextEditorProps } from 'components/RichText/Editor';

import { SlateElementTypes } from './models';

import styles from './index.module.scss';

type PreviewProps = {
  htmlString: string;
  className?: string;
};

const RichText = (props: RichTextEditorProps | PreviewProps) => {
  const isPreviewMode = 'htmlString' in props;

  const previewHTML = useMemo(() => {
    if (isPreviewMode) {
      const document = new DOMParser().parseFromString(
        props.htmlString.replace(/\\/g, ''),
        'text/html'
      );
      const variables = document.querySelectorAll(
        `span[data-type="${SlateElementTypes.variable}"]`
      );

      variables.forEach(variable => {
        variable.innerHTML = variable.textContent?.replaceAll(/{|}/g, '') || '';
      });

      return document.documentElement.innerHTML;
    }

    return '';
  }, [isPreviewMode]);

  return isPreviewMode ? (
    <div
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previewHTML) }}
      className={cn(styles.preview, props.className)}
    />
  ) : (
    <Editor {...props} />
  );
};

export default RichText;
