import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalWindow from 'components/common/ModalWindow';

import SmtpForm from 'components/IntegrationsContent/VendorContent/SmtpForm';
import EspForm from 'components/IntegrationsContent/VendorContent/EspForm';

import { ServerVariants } from 'constants/constants';

import styles from './index.module.scss';

const FormServer = () => {
  const { t } = useTranslation();
  const [formVariant, setFormVariant] = useState<string>(ServerVariants.smtp);

  const isSmtpForm = formVariant === ServerVariants.smtp;

  const inputHandler = ({ currentTarget: { id } }: React.ChangeEvent) => {
    setFormVariant(id);
  };

  return (
    <>
      <ModalWindow
        primaryTitle={t('integrations.server')}
        className={styles.wrapper}
      >
        <div className={styles.container} aria-label="server form modal">
          <p className={styles.title}>{t('integrations.form.type')}</p>

          <div className={styles.radioBlock}>
            <label htmlFor={ServerVariants.smtp}>
              <input
                type="radio"
                name="server"
                id={ServerVariants.smtp}
                checked={isSmtpForm}
                onChange={inputHandler}
              />
              <span>SMTP</span>
            </label>
            <label htmlFor={ServerVariants.esp}>
              <input
                type="radio"
                name="server"
                id={ServerVariants.esp}
                checked={!isSmtpForm}
                onChange={inputHandler}
              />
              <span>ESP</span>
            </label>
          </div>

          {isSmtpForm ? <SmtpForm /> : <EspForm />}
        </div>
      </ModalWindow>
    </>
  );
};

export default FormServer;
