import type { CSSObjectWithLabel } from 'react-select';

const controlBorder = {
  border: '1px solid rgb(222, 222, 223)',
  borderRadius: '8px',
  boxShadow: 'none',
  ':hover': {
    border: '1px solid #1D55C5',
    cursor: 'pointer',
  },
  ':focus': {
    border: '1px solid #1D55C5',
  },
};

export const roleSelectStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    ...controlBorder,
    backgroundColor: 'transparent',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
    width: '100%',
    cursor: 'pointer',
  }),
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '100%',
    minWidth: '160px',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    lineHeight: '24px',
    fontWeight: '500',
    color: '#1C1C1C',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    margin: 0,
    height: '100%',
    backgroundColor: 'rgb(229, 231, 235)',
    display: 'none',
  }),
  option: (
    provided: CSSObjectWithLabel,
    { isSelected }: { isDisabled: boolean; isSelected: boolean }
  ) => ({
    ...provided,
    backgroundColor: isSelected ? '#f1f1f1' : '#fff',
    color: '#1c1c1c',
    padding: 10,
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  }),
};

export const selectStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    ...controlBorder,
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    lineHeight: '24px',
    padding: '12px 16px',
    borderRadius: '8px',
    margin: '0',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: '0',
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    lineHeight: '24px',
    padding: '12px 16px',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  option: (
    provided: CSSObjectWithLabel,
    { isSelected }: { isDisabled: boolean; isSelected: boolean }
  ) => ({
    ...provided,
    backgroundColor: isSelected ? '#E7F0FF' : '#fff',
    color: '#000',
    padding: 10,
    '&:hover': {
      backgroundColor: '#E7F0FF',
    },
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    cursor: 'pointer',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: '12px 16px',
  }),
};

export const getDefaultSelectStylesWithError = (props?: {
  error?: boolean;
  isSmall?: boolean;
  isNoBackgroundSelectedOption?: boolean;
  isSelectorWithOutsideValueBox?: boolean;
  fullHeight?: boolean;
}) => {
  const {
    error,
    isSmall,
    isNoBackgroundSelectedOption,
    fullHeight,
    isSelectorWithOutsideValueBox,
  } = props || {};

  return {
    ...selectStyles,
    control: error
      ? (provided: CSSObjectWithLabel) => ({
          ...provided,
          ...controlBorder,
          border: '1px solid #e92c2c',
        })
      : (provided: CSSObjectWithLabel, state: any) => {
          return {
            ...provided,
            ...controlBorder,
            border: state.isFocused
              ? '1px solid #1D55C5'
              : '1px solid rgb(222, 222, 223)',
          };
        },
    valueContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: '0',
      height: 'auto',
    }),
    ...(isNoBackgroundSelectedOption && {
      option: (provided: CSSObjectWithLabel) => ({
        ...provided,
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: '#f1f1f1',
        },
      }),
    }),
    ...(isSelectorWithOutsideValueBox && {
      option: (provided: CSSObjectWithLabel) => ({
        ...provided,
        width: '100%',
        height: '100%',
        padding: 0,
      }),
      multiValue: (provided: CSSObjectWithLabel) => ({
        ...provided,
        display: 'none',
      }),
    }),
    ...(isSmall
      ? {
          input: (provided: CSSObjectWithLabel) => ({
            ...provided,
            lineHeight: '10px',
            padding: '4px 8px',
            borderRadius: '8px',
            margin: '0',
          }),
          placeholder: (provided: CSSObjectWithLabel) => ({
            ...provided,
            padding: '0px 8px',
            alignSelf: 'start',
            lineHeight: '38px',
          }),
          indicatorContainer: (provided: CSSObjectWithLabel) => ({
            ...provided,
            cursor: 'pointer',
            padding: '4px',
          }),
          singleValue: (provided: CSSObjectWithLabel) => ({
            ...provided,
            padding: '7px 16px',
            height: '40px',
            alignSelf: 'start',
            lineHeight: '26px',
          }),
          valueContainer: (provided: CSSObjectWithLabel, state: any) => {
            return {
              ...provided,
              padding: '0',
              height: '38px',
              borderRadius: '8px',
              'overflow-y': state.isMulti ? 'scroll' : 'hidden',
            };
          },
        }
      : {}),
    ...(fullHeight
      ? {
          valueContainer: (provided: CSSObjectWithLabel) => ({
            ...provided,
            height: 'auto',
          }),
        }
      : {}),
  };
};

export const leadDetailsSelectStyles = {
  control: (
    provided: CSSObjectWithLabel,
    { menuIsOpen }: { menuIsOpen: boolean }
  ) => ({
    ...provided,
    borderStyle: 'none',
    borderRadius: '8px',
    boxShadow: menuIsOpen
      ? '0px 0px 0px 2px #21274b,'
      : '0px 0px 0px 1px rgb(229, 231, 235)',
    paddingLeft: '6px',
    ':hover': {
      boxShadow: '0px 0px 0px 2px #21274b,',
    },
    ':focus': {
      boxShadow: '0px 0px 0px 2px blue',
    },
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    margin: 0,
    padding: '11px 0 ',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => {
    return {
      ...provided,
      padding: '0 8px',
      ':hover': {
        padding: '0 8px',
      },
    };
  },
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  option: (
    provided: CSSObjectWithLabel,
    { isSelected }: { isDisabled: boolean; isSelected: boolean }
  ) => ({
    ...provided,
    backgroundColor: isSelected ? '#f1f1f1' : '#fff',
    color: '#000',
    padding: 10,
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    zIndex: 5,
  }),
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginLeft: '8px',
  }),
};

export const getCalendarSelectorStyles = (
  isMonthly?: boolean,
  isError?: boolean
) => {
  const monthlyStyles = {
    ...controlBorder,
    border: isError ? '1px solid #e92c2c' : controlBorder.border,
  };

  return {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      ...(isMonthly ? monthlyStyles : { border: 'none', boxShadow: 'none' }),
      fontSize: 14,
    }),
    container: (provided: CSSObjectWithLabel) => ({
      ...provided,
      minWidth: isMonthly ? undefined : '136px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      gridArea: '1/1/1/1',
    }),
    valueContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    option: (
      provided: CSSObjectWithLabel,
      { isSelected }: { isDisabled: boolean; isSelected: boolean }
    ) => ({
      ...provided,
      backgroundColor: isSelected ? '#f1f1f1' : '#fff',
      color: '#000',
      padding: 5,
      fontSize: 14,
      '&:hover': {
        backgroundColor: '#f1f1f1',
      },
    }),
  };
};

export const authSelectStyles = {
  control: (
    provided: CSSObjectWithLabel,
    { menuIsOpen }: { menuIsOpen: boolean }
  ) => ({
    ...provided,
    borderStyle: 'none',
    borderRadius: '8px',
    boxShadow: menuIsOpen
      ? '0px 0px 0px 1px #21274b'
      : '0px 0px 0px 1px #e5e7eb',
    paddingLeft: '16px',
    ':hover': {
      boxShadow: '0px 0px 0px 1px #21274b',
    },
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    margin: 0,
    padding: '11px 0 ',
    color: '#585757',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
    fontSize: '20px',
    lineHeight: '24px',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#585757',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => {
    return {
      ...provided,
      padding: '0 8px',
      ':hover': {
        padding: '0 8px',
      },
    };
  },
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  option: (
    provided: CSSObjectWithLabel,
    { isSelected }: { isDisabled: boolean; isSelected: boolean }
  ) => ({
    ...provided,
    backgroundColor: isSelected ? '#f1f1f1' : '#fff',
    color: '#000',
    padding: 10,
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    zIndex: 5,
  }),
};
