import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import LoaderScreen from 'components/common/LoaderScreen';

import IconSVG from 'components/UI/IconSVG';

import { useGetCampaign } from 'hooks/api/useGetCampaign';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const InfoDetailsDeliveries = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: campaign, isLoading } = useGetCampaign(id);

  return (
    <>
      <ul className={styles.info}>
        <li className={cn(styles.card, styles.blue)}>
          <IconSVG name={IconsNames.person_card} className={styles.icon} />
          <p className={styles.count}>
            {campaign?.contacts}
            <span>{t('common.field.contacts-uploaded')}</span>
          </p>
        </li>
        <li className={cn(styles.card, styles.violet)}>
          <IconSVG
            name={IconsNames.download}
            className={cn(styles.icon, styles.download)}
          />
          <p className={styles.count}>
            {campaign?.clicked_contacts}
            <span>{t('common.field.downloads')}</span>
          </p>
        </li>
        <li className={cn(styles.card, styles.orange)}>
          <IconSVG name={IconsNames.hourglass} className={styles.icon} />
          <p className={styles.count}>
            {campaign?.pending_leads}
            <span>{t('common.field.leads-pending-qc')}</span>
          </p>
        </li>
        <li className={cn(styles.card, styles.green)}>
          <IconSVG name={IconsNames.thumb_up} className={styles.icon} />
          <p className={styles.count}>
            {campaign?.qualified_leads}
            <span>{t('common.field.leads-qualified')}</span>
          </p>
        </li>
        <li className={cn(styles.card, styles.red)}>
          <IconSVG
            name={IconsNames.thumb_up}
            className={cn(styles.icon, styles.rotate)}
          />
          <p className={styles.count}>
            {campaign?.disqualified_leads}
            <span>{t('common.field.leads-disqualified')}</span>
          </p>
        </li>
      </ul>
      {isLoading && <LoaderScreen />}
    </>
  );
};

export default InfoDetailsDeliveries;
