import { useTranslation } from 'react-i18next';
import { useForm, useController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { AxiosError } from 'axios';

import ModalWindow from 'components/common/ModalWindow';
import InputWrapper from 'components/common/InputWrapper';
import NumberField from 'components/common/NumberField';
import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import { validationSchemaWithdrawAmount } from 'utils/validations';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

type FormValues = {
  amount: number;
};

const WithdrawModal = () => {
  const { axios, user } = useAuth();
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchemaWithdrawAmount),
    mode: 'onChange',
    defaultValues: { amount: 0 },
  });
  const { field } = useController({ control, name: 'amount' });
  const queryClient = useQueryClient();

  const { refetch } = useQuery<void, AxiosError>(
    ['get-onboarding-link', user?.id],
    async () => {
      try {
        const { data } = await axios.get<{ onboarding_link: string }>(
          '/payments/onboarding-link/'
        );
        window.open(data.onboarding_link, '_blank')?.focus();
        closeModal();
      } catch (err) {
        throw err;
      }
    },
    {
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
      enabled: false,
    }
  );

  const { error, mutate, isLoading, isSuccess } = useMutation<
    void,
    AxiosError,
    number
  >(
    async amount => {
      try {
        await axios.post('/payments/create-payout/', {
          amount,
        });
      } catch (err) {
        throw err;
      }
    },
    {
      onError: async err => {
        if (err.response?.status === 409) {
          await refetch();
        }
      },
      onSuccess: async () => {
        await queryClient.refetchQueries('get-vendor-balance');
      },
    }
  );

  const handleFormSubmit = (data: FormValues) => {
    mutate(data.amount);
  };

  return isSuccess ? (
    <ModalWindow successMessage={t('payments.withdraw-success')} />
  ) : (
    <ModalWindow
      primaryTitle={t('common.button.withdraw')}
      className={styles.wrapper}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <InputWrapper
          label={t('payments.how-much-withdraw')}
          validationError={errors?.amount?.message}
          submitError={error ? getResponseError(error) : undefined}
          wrapperClasses={styles.amount}
        >
          <NumberField
            field={field}
            hasCommas
            prefix="$"
            className={styles.amount}
            placeholder=" "
          />
        </InputWrapper>
        <div className={styles.buttons}>
          <Button type="submit" white>
            {t('common.button.cancel')}
          </Button>
          <Button type="submit" disabled={!isValid}>
            {t('common.button.withdraw')}
          </Button>
        </div>
      </form>
      {isLoading && <LoaderScreen />}
    </ModalWindow>
  );
};

export default WithdrawModal;
