import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useFormContext } from 'react-hook-form';

import type { AxiosError } from 'axios';
import type { CampaignResponse } from 'types/models';
import type {
  CreateCampaignStepProps,
  CreateCampaignValues,
} from 'components/CreateCampaign/models';

import Button from 'components/common/Button';
import Loader from 'components/common/LoaderScreen/Loader';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';
import NoEnoughCreditsModal from 'components/PaymentsComponents/NoEnoughCreditsModal';

import ReportTemplateMapping from 'components/CreateCampaign/Step2/ReportTemplateMapping';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import useGetReportTemplate from 'components/CreateCampaign/useGetReportTemplate';

import { StepNavigation, CampaignCreatingStep } from 'constants/constants';

import { getNextStep } from 'helpers/createCampaign';
import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

const LeadMapping = ({ goToStep, currentStep }: CreateCampaignStepProps) => {
  const { t } = useTranslation();
  const { axios } = useAuth();
  const [isNextStepDisabled, setIsNextStepDisabled] = useState(true);
  const { getValues, setValue } = useFormContext<CreateCampaignValues>();
  const { openModal } = useModal();

  const { isLoading } = useGetReportTemplate({
    campaign: getValues(),
    setFormValue: setValue,
  });

  const {
    mutate,
    isLoading: isGoToNextStepLoading,
    error,
  } = useMutation<CampaignResponse, AxiosError, CampaignCreatingStep>(
    async step => {
      try {
        const { data } = await axios.patch<CampaignResponse>(
          `/campaigns/${getValues().id}/`,
          { campaign_details: { creating_step: step } }
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => goToStep(StepNavigation.next),
      onError: err => {
        if (err.response?.status === 402) {
          openModal({
            Content: <NoEnoughCreditsModal error={err} />,
          });
          return;
        }
      },
    }
  );

  const goToNextStep = () => {
    const {
      data: { name: stepName },
    } = getNextStep(currentStep, getValues());
    mutate(stepName);
  };

  const isReportTemplate = !!getValues().campaign_files?.report_template;

  return (
    <>
      {isLoading && <Loader className={styles.loader} />}
      {isReportTemplate && !isLoading && (
        <>
          <ReportTemplateMapping
            setIsNextStepDisabled={setIsNextStepDisabled}
            isNextStepDisabled={isNextStepDisabled}
          />
          <div className={styles.controls}>
            {error && error?.response?.status !== 402 && (
              <Error message={getResponseError(error)} />
            )}
            <Button
              withArrow
              className={styles.submit}
              onClick={goToNextStep}
              disabled={isNextStepDisabled}
              isBig
            >
              {t('common.button.submit')}
            </Button>
          </div>
        </>
      )}

      {isGoToNextStepLoading && <LoaderScreen />}
    </>
  );
};

export default LeadMapping;
