import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useFormContext } from 'react-hook-form';

import type { CampaignResponse, CampaignAssetFileResponse } from 'types/models';
import type { AxiosError } from 'axios';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import Button from 'components/common/Button';
import Error from 'components/common/Error';
import LoaderScreen from 'components/common/LoaderScreen';
import NoEnoughCreditsModal from 'components/PaymentsComponents/NoEnoughCreditsModal';

import Accordion from 'components/CreateCampaign/Step3/Accordion';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import { getNextStep } from 'helpers/createCampaign';
import getResponseError from 'helpers/getResponseError';

import { StepNavigation, CampaignCreatingStep } from 'constants/constants';
import { AssetHTMLStatus } from 'constants/assets';

import styles from './index.module.scss';

type Props = {
  goToStep: (
    step: CampaignCreatingStep | StepNavigation,
    newData?: Partial<CreateCampaignValues>
  ) => void;
  assets: CampaignAssetFileResponse[];
  currentStep: { name: CampaignCreatingStep; index: number };
};

const FormsList = ({ goToStep, currentStep }: Props) => {
  const { getValues, setValue, watch } = useFormContext<CreateCampaignValues>();
  const [isSubmitError, setIsSubmitError] = useState<boolean>(false);
  const { t } = useTranslation();
  const { axios } = useAuth();
  const { openModal } = useModal();

  const assets = watch('campaign_files.assets');

  const { mutate, isLoading, error } = useMutation<
    CampaignResponse,
    AxiosError,
    CampaignCreatingStep
  >(
    async step => {
      try {
        const { data } = await axios.patch<CampaignResponse>(
          `/campaigns/${getValues().id}/`,
          { campaign_details: { creating_step: step } }
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async data => {
        setValue(
          'campaign_details.creating_step',
          data.campaign_details.creating_step
        );
        goToStep(data.campaign_details.creating_step);
      },
      onError: err => {
        if (err.response?.status === 402) {
          openModal({
            Content: <NoEnoughCreditsModal error={err} />,
          });
        }
      },
    }
  );

  const handleSubmit = () => {
    const isAllSubmitted = assets?.every(
      item => item.asset_html_status === AssetHTMLStatus.submitted
    );
    if (!isAllSubmitted) return setIsSubmitError(true);

    const {
      data: { name },
    } = getNextStep(currentStep, {
      ...getValues(),
      campaign_files: {
        ...getValues().campaign_files,
      },
    });

    mutate(name);
  };

  const isUpdateCampaignError = error && error?.response?.status !== 402;

  return (
    <>
      {isLoading && <LoaderScreen />}
      {assets?.map((asset, index) => {
        const isSubmitted =
          asset.asset_html_status === AssetHTMLStatus.submitted;

        return (
          <section key={asset.id}>
            <h2 className={styles.name}>{asset.title}</h2>
            <Accordion
              heading={t('campaign.create-html')}
              isSubmitted={isSubmitted}
              campaignId={getValues().id}
              asset={asset}
              updateCampaign={mutate}
              setIsSubmitError={setIsSubmitError}
              index={index}
              campaignName={getValues().campaign_details.name}
            />
          </section>
        );
      })}
      <div className={styles.control}>
        {(isSubmitError || isUpdateCampaignError) && (
          <Error
            message={
              error
                ? getResponseError(error)
                : t('common.error.submit-all-forms')
            }
            className={styles.error}
          />
        )}
        <Button
          className={styles.submit}
          onClick={handleSubmit}
          type="button"
          isBig
        >
          {t('common.button.proceed-to')}
          <span className={styles.submit__bold}>
            {t('campaign.review')} &amp; {t('common.button.submit')}
          </span>
        </Button>
      </div>
    </>
  );
};

export default FormsList;
