import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from 'components/App';

import { AuthProvider } from 'contexts/AuthContext';
import { ModalProvider } from 'contexts/ModalContext';
import { LoaderScreenProvider } from 'contexts/LoaderScreenContext';
import { AsideProvider } from 'contexts/AsideContext';
import { EnabledFeaturesProvider } from 'contexts/EnabledFeaturesContext';

import { initializeSentry } from 'services/initSentry';

import 'i18n';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';

import 'assets/styles/index.scss';

initializeSentry();

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <LoaderScreenProvider>
          <AuthProvider>
            <EnabledFeaturesProvider>
              <ModalProvider>
                <AsideProvider>
                  <App />
                  <ReactQueryDevtools initialIsOpen={false} />
                </AsideProvider>
              </ModalProvider>
            </EnabledFeaturesProvider>
          </AuthProvider>
        </LoaderScreenProvider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
