import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import Loader from 'components/common/LoaderScreen/Loader';
import CampaignPausedWarning from 'components/common/CampaignPausedWarning';

import InfoSectionWrapper from 'components/CampaignDetails/InfoSectionWrapper';
import CampaignSettings from 'components/CreateCampaign/Step4/CampaignSettings';
import ReportMapping from 'components/CreateCampaign/Step4/ReportMapping';
import HTML from 'components/CreateCampaign/Step4/HTML';
import CurrentBalanceDepositNow from 'components/PaymentsComponents/CurrentBalanceDepositNow';

import useGetAssets from 'hooks/api/useGetAssets';
import useGetLeadMapping from 'hooks/api/useGetLeadMapping';
import useGetSAL from 'hooks/api/useGetSAL';
import useGetReportTemplate from 'hooks/api/useGetReportTemplate';
import useGetTAL from 'hooks/api/useGetTAL';
import { useGetCampaign } from 'hooks/api/useGetCampaign';

import { CampaignStatuses } from 'constants/constants';

import styles from './index.module.scss';

const CampaignInformation = () => {
  const { t } = useTranslation();
  const { campaignId } = useParams();

  const { isLoading, data: campaign } = useGetCampaign(campaignId);
  const getReportTemplateRequestSettings = useGetReportTemplate(campaign?.id);
  const getLeadFileRequestSettings = useGetLeadMapping(campaign?.id);
  const getTALRequestSettings = useGetTAL(campaign?.id);
  const getSALRequestSettings = useGetSAL(campaign?.id);
  const getAssetsRequestSettings = useGetAssets(campaign?.id);

  const isForcePaused =
    campaign?.campaign_details.status === CampaignStatuses.forcePaused;

  return (
    <>
      {isLoading && <Loader className={styles.loading} />}

      {campaign && (
        <>
          <CurrentBalanceDepositNow
            campaignBalance={campaign.balance}
            className={isForcePaused ? styles.balance : ''}
          />
          {isForcePaused && <CampaignPausedWarning />}

          <InfoSectionWrapper className={isForcePaused ? styles.settings : ''}>
            <InfoSectionWrapper.Header>
              <InfoSectionWrapper.Title>
                {t('campaign.campaign-settings')}
              </InfoSectionWrapper.Title>
            </InfoSectionWrapper.Header>
            <InfoSectionWrapper.Content>
              <CampaignSettings
                campaignData={campaign}
                getAssetsRequestSettings={getAssetsRequestSettings}
                getTALRequestSettings={getTALRequestSettings}
                getSALRequestSettings={getSALRequestSettings}
                getReportTemplateRequestSettings={
                  getReportTemplateRequestSettings
                }
                getLeadFileRequestSettings={getLeadFileRequestSettings}
              />
            </InfoSectionWrapper.Content>
          </InfoSectionWrapper>
          <InfoSectionWrapper className={styles.table}>
            <InfoSectionWrapper.Header>
              <InfoSectionWrapper.Title>
                {t('campaign.lead-mapping')}
              </InfoSectionWrapper.Title>
            </InfoSectionWrapper.Header>
            <InfoSectionWrapper.Content>
              <ReportMapping
                getReportTemplateRequestSettings={
                  getReportTemplateRequestSettings
                }
              />
            </InfoSectionWrapper.Content>
          </InfoSectionWrapper>
          <InfoSectionWrapper className={cn(styles.table, styles.assets)}>
            <InfoSectionWrapper.Header>
              <InfoSectionWrapper.Title>
                {t('campaign.html')}
              </InfoSectionWrapper.Title>
            </InfoSectionWrapper.Header>
            <InfoSectionWrapper.Content>
              <HTML
                getAssetsRequestSettings={getAssetsRequestSettings}
                campaignData={campaign}
              />
            </InfoSectionWrapper.Content>
          </InfoSectionWrapper>
        </>
      )}
    </>
  );
};

export default CampaignInformation;
