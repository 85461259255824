import { useQuery } from 'react-query';

import type { UseFormSetValue } from 'react-hook-form';
import type { AxiosError } from 'axios';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { SALFileResponse } from 'types/models';

import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';

import { SALFileStatus } from 'constants/constants';

const useGetSALFiles = ({
  campaign,
  setFormValue,
}: {
  campaign: CreateCampaignValues;
  setFormValue: UseFormSetValue<CreateCampaignValues>;
}) => {
  const { axios } = useAuth();
  const { convertSALResponse } = useConvertCampaignData();

  const getSALRequestSettings = useQuery<SALFileResponse[], AxiosError>(
    ['campaign', campaign.id, 'SAL'],
    async () => {
      try {
        const { data } = await axios.get<SALFileResponse[]>(
          `/campaigns/${campaign?.id}/suppression-account-lists/`
        );

        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: !!campaign?.id && !campaign.isJustCreated,
      onSuccess: salFiles => {
        const converted = salFiles.map(item => convertSALResponse(item));
        setFormValue(
          'campaign_files.suppression_accounts',
          converted.filter(item => item.status === SALFileStatus.draft)
        );
        setFormValue(
          'campaign_files.company_suppression_accounts',
          converted
            .filter(item => item.status === SALFileStatus.finished)
            .map(item => ({ value: item.id, label: item.sal_file_name }))
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  return getSALRequestSettings;
};

export default useGetSALFiles;
