import { useQuery } from 'react-query';

import type { AxiosError } from 'axios';
import type { UseQueryOptions } from 'react-query';

import useAuth from 'contexts/AuthContext';

type VendorBalanceResponse = { in_account: string };

const useGetVendorBalance = (
  options?: UseQueryOptions<VendorBalanceResponse, AxiosError>
) => {
  const { axios, user } = useAuth();

  const request = useQuery<VendorBalanceResponse, AxiosError>(
    ['get-vendor-balance', user?.company.id],
    async () => {
      try {
        const { data } = await axios.get<VendorBalanceResponse>(
          '/payments/vendor-balance/'
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    options
  );

  return request;
};

export default useGetVendorBalance;
