import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';

import {
  ESPServer,
  EmailServices,
  typesWithApiKey,
  typesWithSecretKey,
} from 'components/IntegrationsContent/VendorContent/models';
import type { SingleValue } from 'react-select';
import type { ESPServerResponse } from 'types/models';

import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';

import useModal from 'contexts/ModalContext';

import useAddEmailServer from 'components/IntegrationsContent/VendorContent/useAddEmailServer';
import useUpdateServer from 'components/IntegrationsContent/VendorContent/useUpdateServer';
import useDeleteServer from 'components/IntegrationsContent/VendorContent/useDeleteServer';

import getResponseError from 'helpers/getResponseError';
import { getESPServerName } from 'helpers/mailServers';

import { validationSchemaIntegrationsServerEsp } from 'utils/validations';
import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import { IconsNames, ServerVariants } from 'constants/constants';

import styles from '../FormServer/index.module.scss';

type FormValues = {
  integration_name: string;
  credentials: Omit<ESPServer['credentials'], 'provider_name'> & {
    provider_name: ProviderNameOption;
  };
};

type ProviderNameOption = { value: EmailServices; label: string };

type Props = {
  data?: ESPServerResponse;
};

const EspForm = ({ data }: Props) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const isDefaultValues = !!data;
  const [isEditDisabled, setIsEditDisabled] = useState(isDefaultValues);
  const [isShowKey, setIsShowKey] = useState(false);
  const [isShowToken, setIsShowToken] = useState(false);

  const services = Object.keys(EmailServices) as EmailServices[];
  const servicesOptions = services.map(service => ({
    value: service,
    label: getESPServerName(service),
  }));

  const initialServicesListOptions = servicesOptions.find(
    item => data?.decrypted_credentials.provider_name === item.value
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      ...data,
      credentials: {
        ...data?.decrypted_credentials,
        provider_name: data ? initialServicesListOptions : servicesOptions[0],
      },
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchemaIntegrationsServerEsp),
  });

  const { field } = useController({
    name: 'credentials.provider_name',
    control,
  });

  const { isLoading, error, mutate: addServer } = useAddEmailServer();
  const { isLoading: isUpdateServerLoading, mutate: updateServer } =
    useUpdateServer();
  const { mutate: deleteServer, isLoading: isDeleteLoading } =
    useDeleteServer();

  const selectValue = watch('credentials.provider_name')?.value;
  const isAPIKey = typesWithApiKey.some(service => service === selectValue);
  const isSecretKey = typesWithSecretKey.some(
    service => service === selectValue
  );

  const handleFormSubmit = (values: FormValues) => {
    const defaultData: ESPServer = {
      id: data?.id,
      integration_type: ServerVariants.esp,
      integration_name: values.integration_name,
      credentials: {
        provider_name: values.credentials?.provider_name.value,
        from_email: values.credentials?.from_email,
      },
    };

    const isAPIKeyData = typesWithApiKey.some(
      service => service === values?.credentials?.provider_name.value
    );

    if (isAPIKeyData) {
      const payload = {
        ...defaultData,
        credentials: {
          ...defaultData.credentials,
          api_key: values.credentials?.api_key,
        },
      };
      if (data) {
        updateServer(payload);
      } else {
        addServer(payload);
      }
    }

    const isSecretKeyData = typesWithSecretKey.some(
      service => service === values?.credentials?.provider_name.value
    );

    if (isSecretKeyData) {
      const payload = {
        ...defaultData,
        credentials: {
          ...defaultData.credentials,
          api_key: values.credentials?.api_key,
          secret_key: values.credentials?.secret_key,
        },
      };
      if (data) {
        updateServer(payload);
      } else {
        addServer(payload);
      }
    }

    if (EmailServices.postmark === values?.credentials.provider_name.value) {
      const payload = {
        ...defaultData,
        credentials: {
          ...defaultData.credentials,
          server_token: values.credentials.server_token,
        },
      };
      if (data) {
        updateServer(payload);
      } else {
        addServer(payload);
      }
    }
  };

  const selectChangeHandler = (value: SingleValue<ProviderNameOption>) => {
    if (value) {
      reset({ credentials: { provider_name: value } });
    }
  };

  return (
    <>
      {isLoading && <LoaderScreen />}
      <>
        <form className={styles.form} onSubmit={handleSubmit(handleFormSubmit)}>
          <InputWrapper
            label="common.field.name"
            wrapperClasses={styles.label}
            validationError={errors.integration_name?.message}
            isMediumInput
          >
            <input
              type="text"
              placeholder={!isEditDisabled ? t('common.field.name') : ''}
              disabled={isEditDisabled}
              {...register('integration_name')}
            />
          </InputWrapper>
          <InputWrapper
            wrapperClasses={styles.select}
            label="integrations.form.choose-service"
            isMediumInput
          >
            <Select
              options={servicesOptions}
              styles={getDefaultSelectStylesWithError({ isSmall: true })}
              isDisabled={isEditDisabled}
              {...field}
              placeholder={t('integrations.form.choose-service')}
              noOptionsMessage={() => t('common.field.no-options')}
              onChange={selectChangeHandler}
            />
          </InputWrapper>

          {isAPIKey && (
            <InputWrapper
              label="integrations.form.api-key"
              validationError={errors.credentials?.api_key?.message}
              wrapperClasses={styles.labelPassword}
              isMediumInput
              setIsHiddenInputContent={setIsShowKey}
            >
              <input
                type={!isShowKey ? 'password' : 'text'}
                placeholder={t('integrations.form.api-key')}
                disabled={isEditDisabled}
                {...register('credentials.api_key')}
              />
            </InputWrapper>
          )}

          {isSecretKey && (
            <>
              <InputWrapper
                label="integrations.form.api-key"
                validationError={errors.credentials?.api_key?.message}
                wrapperClasses={styles.labelPassword}
                isMediumInput
                setIsHiddenInputContent={setIsShowKey}
              >
                <input
                  type={!isShowKey ? 'password' : 'text'}
                  placeholder={t('integrations.form.api-key')}
                  disabled={isEditDisabled}
                  {...register('credentials.api_key')}
                />
              </InputWrapper>
              <InputWrapper
                label="integrations.form.secret-key"
                validationError={errors.credentials?.secret_key?.message}
                wrapperClasses={styles.labelPassword}
                isMediumInput
                setIsHiddenInputContent={setIsShowToken}
              >
                <input
                  type={!isShowToken ? 'password' : 'text'}
                  placeholder={t('integrations.form.secret-key')}
                  disabled={isEditDisabled}
                  {...register('credentials.secret_key')}
                />
              </InputWrapper>
            </>
          )}

          {selectValue === EmailServices.postmark && (
            <InputWrapper
              label="integrations.form.server-token"
              validationError={errors.credentials?.server_token?.message}
              wrapperClasses={styles.labelPassword}
              isMediumInput
              setIsHiddenInputContent={setIsShowToken}
            >
              <input
                type={!isShowToken ? 'password' : 'text'}
                placeholder={t('integrations.form.server-token')}
                disabled={isEditDisabled}
                {...register('credentials.server_token')}
              />
            </InputWrapper>
          )}

          <InputWrapper
            label="integrations.form.from-email"
            validationError={errors.credentials?.from_email?.message}
            wrapperClasses={styles.label}
            tip={t('integrations.form.email-tip')}
            isMediumInput
          >
            <input
              type="email"
              placeholder={t('integrations.form.from-email')}
              disabled={isEditDisabled}
              {...register('credentials.from_email')}
            />
          </InputWrapper>

          {!data?.is_in_use && (
            <>
              {isDefaultValues && isEditDisabled && (
                <div className={styles.edit}>
                  <Button
                    onClick={() => setIsEditDisabled(false)}
                    type="button"
                    className={styles.wrapperButton}
                    iconProps={{
                      name: IconsNames.edit,
                      className: styles.iconEdit,
                    }}
                  >
                    {t('common.button.edit')}
                  </Button>
                </div>
              )}

              {isDefaultValues && !isEditDisabled && (
                <div className={styles.buttonsBlock}>
                  {data && (
                    <Button
                      onClick={() => data.id && deleteServer(data.id)}
                      type="button"
                      transparent
                      className={styles.delete}
                      iconProps={{
                        name: IconsNames.trash,
                      }}
                    >
                      {t('common.button.delete')}
                    </Button>
                  )}
                  <div className={styles.buttons}>
                    <Button onClick={closeModal} type="button" white>
                      {t('common.button.cancel')}
                    </Button>
                    <Button type="submit" className={styles.button}>
                      <span>{t('common.button.save-changes')}</span>
                    </Button>
                  </div>
                </div>
              )}

              {!isDefaultValues && (
                <div className={styles.buttons}>
                  <Button onClick={closeModal} type="button" white>
                    {t('common.button.cancel')}
                  </Button>
                  <Button type="submit" className={styles.button}>
                    <span>{t('common.button.add')}</span>
                  </Button>
                </div>
              )}
            </>
          )}
        </form>
      </>
      {error && <Error message={getResponseError(error)} />}
      {(isLoading || isDeleteLoading || isUpdateServerLoading) && (
        <LoaderScreen />
      )}
    </>
  );
};

export default EspForm;
