import { useMemo, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import type { FormValues } from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/models';

import IconSVG from 'components/UI/IconSVG';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';

import useDeleteContactsReport from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/useDeleteContactsReport';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import { IconsNames } from 'constants/constants';

import { ReactComponent as ExcelSVG } from 'assets/images/files/excel.svg';

import styles from '../index.module.scss';

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  campaignId: string | number;
};

const MappingTable = ({ campaignId, setStep }: Props) => {
  const { watch, control, getValues, reset } = useFormContext<FormValues>();
  const { t } = useTranslation();
  const { mutate: deleteReport } = useDeleteContactsReport();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const handleDeleteFile = () => {
    setIsDeleteConfirmationModalVisible(false);
    const fileId = getValues().id;
    if (fileId) {
      deleteReport({
        campaignId,
        fileId,
      });
    }
    setStep(prev => prev - 1);
    reset({
      ...getValues(),
      mapping_fields: {
        email: null,
        linkedin_contact_profile_link: null,
        linkedin_company_profile_link: null,
      },
      report_file_name: undefined,
      id: undefined,
      headers: undefined,
    });
  };

  const fileName = watch('report_file_name');
  const headers = watch('headers');
  const selectedMappingHeaders = Object.values(watch('mapping_fields'));

  const options = useMemo(() => {
    const result = headers
      ?.map(header => ({ value: header, label: header }))
      .filter(option => {
        const selectedIndex = selectedMappingHeaders.findIndex(
          item => item?.value === option.value
        );

        return selectedIndex < 0;
      });

    return result;
  }, [headers, selectedMappingHeaders]);

  return (
    <>
      <div className={styles.mapping}>
        <div className={styles.header}>
          <h4>
            <ExcelSVG />
            <span>{fileName}</span>
          </h4>
          <button
            type="button"
            className={styles.delete}
            onClick={() => setIsDeleteConfirmationModalVisible(true)}
            aria-label="delete report"
          >
            <IconSVG name={IconsNames.trash} />
          </button>
        </div>

        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t('common.field.required-fields')}</th>
              <th>{t('common.field.choose-fields')}</th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.row}>
              <td>{t('common.field.email')}</td>
              <td>
                <Controller
                  name="mapping_fields.email"
                  control={control}
                  render={({ field }) => (
                    <label className={styles.label}>
                      <Select
                        className={styles.select}
                        placeholder={t('common.field.select-column')}
                        styles={getDefaultSelectStylesWithError({
                          isSmall: true,
                        })}
                        options={options}
                        isClearable
                        aria-label="email"
                        {...field}
                      />
                      {!field.value ? (
                        <span className={styles.state} />
                      ) : (
                        <IconSVG
                          name={IconsNames.success_checkmark}
                          className={styles.mapped}
                        />
                      )}
                    </label>
                  )}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td>{t('common.field.linkedin-contact-profile-link')}</td>
              <td>
                <Controller
                  name="mapping_fields.linkedin_contact_profile_link"
                  control={control}
                  render={({ field }) => (
                    <label className={styles.label}>
                      <Select
                        className={styles.select}
                        placeholder={t('common.field.select-column')}
                        styles={getDefaultSelectStylesWithError({
                          isSmall: true,
                        })}
                        options={options}
                        isClearable
                        aria-label="linkedin contact profile link"
                        {...field}
                      />
                      {!field.value ? (
                        <span className={styles.state} />
                      ) : (
                        <IconSVG
                          name={IconsNames.success_checkmark}
                          className={styles.mapped}
                        />
                      )}
                    </label>
                  )}
                />
              </td>
            </tr>
            <tr className={styles.row}>
              <td>{t('common.field.linkedin-company-profile-link')}</td>
              <td>
                <Controller
                  name="mapping_fields.linkedin_company_profile_link"
                  control={control}
                  render={({ field }) => (
                    <label className={styles.label}>
                      <Select
                        className={styles.select}
                        placeholder={t('common.field.select-column')}
                        styles={getDefaultSelectStylesWithError({
                          isSmall: true,
                        })}
                        options={options}
                        isClearable
                        aria-label="linkedin company profile link"
                        {...field}
                      />
                      {!field.value ? (
                        <span className={styles.state} />
                      ) : (
                        <IconSVG
                          name={IconsNames.success_checkmark}
                          className={styles.mapped}
                        />
                      )}
                    </label>
                  )}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {isDeleteConfirmationModalVisible && (
        <DeleteConfirmationModal
          instanceName={t('common.field.file').toLowerCase()}
          itemName={fileName || t('common.filed.file')}
          onDelete={handleDeleteFile}
          onCancel={() => setIsDeleteConfirmationModalVisible(false)}
          className={styles.confirmation}
        />
      )}
    </>
  );
};

export default MappingTable;
