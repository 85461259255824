import { useParams } from 'react-router-dom';

import LoaderScreen from 'components/common/LoaderScreen';

import AssetDownloadContent from 'components/AssetDownloadContent';

import { useGetPublicAsset } from 'hooks/api/useGetPublicAsset';

const AssetDownload = () => {
  const { companySlug, productSlug, assetSlug, contactShareId } = useParams();

  const { data, isLoading } = useGetPublicAsset({
    companySlug,
    productSlug,
    assetSlug,
    contactShareId,
  });

  return (
    <>
      {data && (
        <AssetDownloadContent
          data={data}
          companySlug={companySlug}
          productSlug={productSlug}
          assetSlug={assetSlug}
          contactShareId={contactShareId}
        />
      )}
      {isLoading && <LoaderScreen />}
    </>
  );
};

export default AssetDownload;
