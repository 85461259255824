import { useLayoutEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { isMobile, isDesktop } from 'react-device-detect';

import PublicRoute from 'components/App/Routes/PublicRoute';
import AuthorizedRoute from 'components/App/Routes/AuthorizedRoute';
import ProtectedRoute from 'components/App/Routes/ProtectedRoute';

import SignIn from 'views/Auth/LogIn';
import SignUp from 'views/Auth/SignUp';
import PasswordRecovery from 'views/Auth/ResetPassword';
import NewPassword from 'views/Auth/NewPassword';
import ActivateAccount from 'views/Auth/Activate';
import Invite from 'views/Auth/Invite';

import Home from 'views/Main/Home';
import Payments from 'views/Main/Payments';
import Invites from 'views/Main/Invites';
// import Settings from 'views/Main/Settings';
import Campaign from 'views/Main/Campaign';
import CreateCampaign from 'views/Main/CreateCampaign';
import UserGroups from 'views/Main/UserGroups';
import Integrations from 'views/Main/Integrations';
import CampaignDeliveries from 'views/Main/CampaignDeliveries';
import CampaignDeliveriesDetails from 'views/Main/CampaignDeliveriesDetails';
import ManageProducts from 'views/Main/ManageProducts';
import ManageRequests from 'views/Main/ManageRequests';
import CreateProduct from 'views/Main/CreateProduct';
import Payouts from 'views/Main/Payouts';
import CampaignDeliveriesUploads from 'views/Main/CampaignDeliveriesUploads';
import CampaignDeliveriesUploadsDetails from 'views/Main/CampaignDeliveriesUploadsDetails';
import Profile from 'views/Main/Profile';

import HealthCheck from 'views/Common/HealthCheck';
import Documents from 'views/Common/Documents';
import AssetDownload from 'views/Common/AssetDownload';
import Product from 'views/Common/Product';

import ProfileGeneral from 'components/Profile/General';
import ProfileContacts from 'components/Profile/Contacts';
import ProfileCompany from 'components/Profile/Company';
import ProfileSecurity from 'components/Profile/Security';

import ServiceContract from 'components/DocumentsContent/ServiceContract';
import TermsOfService from 'components/DocumentsContent/TermsOfService';
import DataProcessing from 'components/DocumentsContent/DataProcessing';
import PrivacyNotice from 'components/DocumentsContent/PrivacyNotice';

import useAuth from 'contexts/AuthContext';

import { UserTypes } from 'constants/constants';
import { PublicPaths, CommonPaths, PrivatePaths } from 'constants/routes';
import HubspotConnect from 'views/Main/Integrations/HubspotConnect';

const App = () => {
  const { user } = useAuth();
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  const isAdmin = user?.current_group_name === UserTypes.admin;
  const isVendorManager = user?.current_group_name === UserTypes.vendorManager;
  const isVendor = user?.current_group_name === UserTypes.vendor;

  return (
    <Routes>
      <Route element={<PublicRoute />} path={PublicPaths.INDEX}>
        <Route element={<SignIn />} path={PublicPaths.LOG_IN} />
        <Route element={<SignUp />} path={PublicPaths.SIGN_UP} />
        <Route element={<ActivateAccount />} path={PublicPaths.ACTIVATE} />
        <Route element={<Invite />} path={PublicPaths.INVITE} />
        <Route
          element={<PasswordRecovery />}
          path={PublicPaths.RESET_PASSWORD}
        />
        <Route element={<NewPassword />} path={PublicPaths.NEW_PASSWORD} />
      </Route>

      <Route element={<AuthorizedRoute />} path={PrivatePaths.INDEX}>
        <Route element={<Home />} index />
        <Route element={<Profile />} path={PrivatePaths.PROFILE}>
          <Route element={<ProfileGeneral />} path={PrivatePaths.GENERAL} />
          <Route element={<ProfileContacts />} path={PrivatePaths.CONTACTS} />
          <Route element={<ProfileCompany />} path={PrivatePaths.COMPANY} />
          <Route element={<ProfileSecurity />} path={PrivatePaths.SECURITY} />
        </Route>

        <Route path={PrivatePaths.CAMPAIGN}>
          <Route
            path=":campaignId"
            element={
              <ProtectedRoute
                isAllowed={isAdmin || isVendorManager || isVendor}
              >
                <Campaign />
              </ProtectedRoute>
            }
          />
          <Route
            element={
              <ProtectedRoute isAllowed={isAdmin && !isMobile}>
                <CreateCampaign />
              </ProtectedRoute>
            }
            path={`${PrivatePaths.CREATE}/:id`}
          />
        </Route>

        <Route
          element={
            <ProtectedRoute isAllowed={isAdmin}>
              <Payments />
            </ProtectedRoute>
          }
          path={PrivatePaths.PAYMENTS}
        />

        <Route
          element={
            <ProtectedRoute isAllowed={isVendor}>
              <Payouts />
            </ProtectedRoute>
          }
          path={PrivatePaths.PAYOUTS}
        />

        <Route
          element={
            <ProtectedRoute isAllowed={isAdmin || isVendorManager}>
              <Invites />
            </ProtectedRoute>
          }
          path={PrivatePaths.INVITES}
        />

        {/* for next scope */}
        {/* <Route
          element={
            <ProtectedRoute isAllowed={isAdmin}>
              <Settings />
            </ProtectedRoute>
          }
          path={PrivatePaths.SETTINGS}
        /> */}
        <Route
          element={
            <ProtectedRoute isAllowed={isAdmin}>
              <UserGroups />
            </ProtectedRoute>
          }
          path={PrivatePaths.USER_GROUPS}
        />
        <Route
          element={
            <ProtectedRoute isAllowed={isAdmin || isVendor}>
              <Integrations />
            </ProtectedRoute>
          }
          path={PrivatePaths.INTEGRATIONS}
        />
        <Route path={PrivatePaths.PRODUCTS}>
          <Route
            element={
              <ProtectedRoute isAllowed={isAdmin}>
                <ManageProducts />
              </ProtectedRoute>
            }
            index
          />
          <Route
            element={
              <ProtectedRoute isAllowed={isAdmin && isDesktop}>
                <CreateProduct />
              </ProtectedRoute>
            }
            path={PrivatePaths.CREATE}
          />
        </Route>

        <Route
          element={
            <ProtectedRoute isAllowed={isAdmin}>
              <ManageRequests />
            </ProtectedRoute>
          }
          path={PrivatePaths.REQUESTS}
        />

        <Route path={PrivatePaths.CAMPAIGN_DELIVERIES}>
          <Route
            index
            element={
              <ProtectedRoute isAllowed={isVendor}>
                <CampaignDeliveries />
              </ProtectedRoute>
            }
          />
          <Route path=":id">
            <Route
              index
              element={
                <ProtectedRoute isAllowed={isVendor}>
                  <CampaignDeliveriesDetails />
                </ProtectedRoute>
              }
            />
            <Route path={PrivatePaths.UPLOADS_HISTORY}>
              <Route
                index
                element={
                  <ProtectedRoute isAllowed={isVendor}>
                    <CampaignDeliveriesUploads />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":reportId"
                element={
                  <ProtectedRoute isAllowed={isVendor}>
                    <CampaignDeliveriesUploadsDetails />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Route>

        <Route
          element={<HubspotConnect />}
          path={`${PrivatePaths.CRM_CALLBACK}/${PrivatePaths.CONNECT_HUBSPOT}/`}
        />
      </Route>

      <Route element={<HealthCheck />} path={CommonPaths.HEALTH_CHECK} />

      <Route
        path={`${CommonPaths.SHARE}/${CommonPaths.CONTACT_SHARE_ID}/${CommonPaths.COMPANY_SLUG}/${CommonPaths.PRODUCT_SLUG}`}
      >
        <Route index element={<Product />} />
        <Route path={CommonPaths.DOWNLOAD_ASSET} element={<AssetDownload />} />
      </Route>

      <Route path={`${CommonPaths.COMPANY_SLUG}/${CommonPaths.PRODUCT_SLUG}`}>
        <Route index element={<Product />} />
        <Route path={CommonPaths.DOWNLOAD_ASSET} element={<AssetDownload />} />
      </Route>

      <Route element={<Documents />} path={CommonPaths.DOCUMENTS}>
        <Route path={CommonPaths.TERMS} element={<TermsOfService />} />
        <Route path={CommonPaths.CONTRACT} element={<ServiceContract />} />
        <Route path={CommonPaths.PROCESSING} element={<DataProcessing />} />
        <Route path={CommonPaths.PRIVACY_NOTICE} element={<PrivacyNotice />} />
      </Route>

      <Route path="*" element={<Navigate to={PrivatePaths.INDEX} />} />
    </Routes>
  );
};

export default App;
