import { PrivacyNoticeIDs } from 'components/DocumentsContent/constants';

import styles from 'components/DocumentsContent/index.module.scss';

const SideMenu = () => {
  return (
    <div className={styles.sidemenu_container}>
      <div className={styles.sidemenu_content}>
        <h4 className={styles.heading}>Table of contents</h4>
        <ul className={styles.list}>
          <li className={styles.list_item}>
            <a
              href={`#${PrivacyNoticeIDs.introduction}`}
              className={styles.anchor_link}
            >
              I. Introduction
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${PrivacyNoticeIDs.contact_details}`}
              className={styles.anchor_link}
            >
              II. Contact Details
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${PrivacyNoticeIDs.our_principles}`}
              className={styles.anchor_link}
            >
              III. Our Principles
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${PrivacyNoticeIDs.what_data_we_collect}`}
              className={styles.anchor_link}
            >
              IV. What Data We Collect
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${PrivacyNoticeIDs.how_we_use_data}`}
              className={styles.anchor_link}
            >
              V. How We Use Data
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${PrivacyNoticeIDs.how_we_share_data}`}
              className={styles.anchor_link}
            >
              VI. How We Share Data
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${PrivacyNoticeIDs.how_long_we_retain_data}`}
              className={styles.anchor_link}
            >
              VII. How Long We Retain Data
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${PrivacyNoticeIDs.what_are_your_rights}`}
              className={styles.anchor_link}
            >
              VIII. What Are Your Rights
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${PrivacyNoticeIDs.third_party_links}`}
              className={styles.anchor_link}
            >
              IX. Third-party links
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${PrivacyNoticeIDs.data_of_minors}`}
              className={styles.anchor_link}
            >
              X. Data of Minors
            </a>
          </li>
          <li className={styles.list_item}>
            <a
              href={`#${PrivacyNoticeIDs.changes_to_this_privacy_notice}`}
              className={styles.anchor_link}
            >
              XI. Changes to This Privacy Notice
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
