import { useSlateStatic, useReadOnly } from 'slate-react';
import React, { useState, useRef } from 'react';
import { Transforms } from 'slate';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useTranslation } from 'react-i18next';

import IconSVG from 'components/UI/IconSVG';
import SimpleTooltip from 'components/UI/SimpleTooltip';

import OptionsList from 'components/RichText/VariableList';

import { IconsNames } from 'constants/constants';
import { SlateElementTypes } from 'components/RichText/models';

import styles from './index.module.scss';

type Props = {
  containerRef: React.RefObject<HTMLDivElement>;
};

const AddVariableButton = ({ containerRef }: Props) => {
  const { t } = useTranslation();
  const [isListVisible, setListVisibility] = useState(false);
  const slate = useSlateStatic();
  const isReadOnly = useReadOnly();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const tooltipOptions = usePopperTooltip({
    interactive: true,
    placement: 'top',
  });

  const selectVariable = (variable: string) => {
    if (isReadOnly) return;

    Transforms.insertNodes(slate, [
      {
        type: SlateElementTypes.variable,
        children: [{ text: variable }],
        value: variable,
      },
    ]);
    setListVisibility(false);
  };

  const toggleList = () => {
    setListVisibility(!isListVisible);
  };

  const containerBoundingClient = containerRef.current?.getBoundingClientRect();
  const variablePosition = buttonRef.current?.getBoundingClientRect();

  return (
    <>
      <button
        type="button"
        className={styles.plus}
        onClick={() => {
          if (!isListVisible) setListVisibility(true);
        }}
        ref={buttonRef}
        aria-label="add variable"
      >
        <IconSVG name={IconsNames.plus} ref={tooltipOptions.setTriggerRef} />
      </button>
      {isListVisible &&
        containerRef.current &&
        ReactDOM.createPortal(
          <OptionsList
            selectVariable={selectVariable}
            toggleList={toggleList}
            setListVisibility={setListVisibility}
            style={{
              right:
                (containerBoundingClient?.right || 0) -
                (variablePosition?.right || 0),
              top:
                (variablePosition?.bottom || 0) -
                (containerBoundingClient?.top || 0) +
                14,
            }}
          />,
          containerRef.current
        )}

      {tooltipOptions.visible && (
        <SimpleTooltip options={tooltipOptions}>
          {t('common.button.add-variable')}
        </SimpleTooltip>
      )}
    </>
  );
};

export default AddVariableButton;
