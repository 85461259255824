import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { CampaignAssetFileResponse } from 'types/models';

import styles from './index.module.scss';

type Props = {
  data: CampaignAssetFileResponse;
  isSelected: boolean;
  onSelectAsset: (
    isSelected: boolean,
    assetData: CampaignAssetFileResponse
  ) => void;
};

const SelectAssetItem = ({ data, isSelected, onSelectAsset }: Props) => {
  const { t } = useTranslation();

  return (
    <li className={cn(styles.item, { [styles.selected]: isSelected })}>
      <input
        type="checkbox"
        name={data.title}
        checked={isSelected}
        onChange={() => onSelectAsset(isSelected, data)}
        aria-label={data.title}
      />
      <span className={styles.title}>{data.title}</span>
      <span className={styles.type}>
        {t(`common.field.${data.content_type}`)}
      </span>
      <span className={styles.product}>{data.product_name}</span>
    </li>
  );
};

export default SelectAssetItem;
