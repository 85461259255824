import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';

import { SelectOption } from 'types/models';

import InputWrapper from 'components/common/InputWrapper';
import JobTitleAutocomplete from 'components/common/JobTitleAutocomplete';
import CountryField from 'components/common/AuthForm/CountryField';
import Button from 'components/common/Button';
import Error from 'components/common/Error';
import AddressField from 'components/common/AuthForm/AddressField';

import useAuth from 'contexts/AuthContext';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';
import { validationSchemaProfileCompany } from 'utils/validations';
import getResponseError from 'helpers/getResponseError';

import useTranslateSelectOptions from 'hooks/useTranslateSelectOptions';

import useUpdateProfileData from 'components/Profile/useUpdateProfileData';

import { userDepartmentOptions } from 'constants/selectOptions';

import styles from './index.module.scss';

type FormValues = {
  department: SelectOption;
  job_title: SelectOption;

  company: {
    address: string;
    country_code: SelectOption | string;
    state: SelectOption | string;
    city: SelectOption | string;
  };
};

const ProfileCompany = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();

  const regionNames = new Intl.DisplayNames([i18n.language], {
    type: 'region',
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(validationSchemaProfileCompany),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      company: {
        address: user?.company.address,
        country_code: {
          value: user?.company.country_code,
          label: user?.company
            ? regionNames.of(user?.company.country_code)
            : '',
        },
        city: user?.company.city,
        state: user?.company.state,
      },
      job_title: { value: user?.job_title, label: user?.job_title },
      department: {
        value: user?.company.department,
        label: `${user?.company.department[0].toUpperCase()}${user?.company.department.slice(
          1
        )}`,
      },
    },
  });

  const translateOptions = useTranslateSelectOptions();
  const translatedOptions = translateOptions(userDepartmentOptions || []);

  const { mutate, error } = useUpdateProfileData();

  const onSubmit = (data: FormValues) => {
    const { company } = data;

    const formattedData = {
      department:
        typeof data.department === 'string'
          ? data.department
          : data.department.value,
      job_title: data.job_title.value,
      company_country_code:
        typeof company.country_code === 'string'
          ? ''
          : company.country_code.value,
      company_city: company.city,
      company_state: company.state
        ? typeof company.state === 'string'
          ? company.state
          : company.state?.value
        : undefined,
      company_address: company.address,
    };

    mutate(formattedData);
  };

  return (
    <>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={styles.container}>
          <div className={styles.block}>
            <h3 className={styles.heading}>
              {t('profile.department-job-title')}
            </h3>
            <InputWrapper
              label="common.field.department"
              wrapperClasses={styles.inputWrapper}
            >
              <Controller
                name="department"
                control={methods.control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={translatedOptions}
                    styles={getDefaultSelectStylesWithError({
                      isSmall: true,
                    })}
                    placeholder={t('manage-products.job-title-placeholder')}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper
              label="common.field.job-title"
              wrapperClasses={styles.inputWrapper}
              isMediumInput
            >
              <Controller
                name="job_title"
                control={methods.control}
                render={({ field }) => (
                  <JobTitleAutocomplete
                    field={field}
                    styles={getDefaultSelectStylesWithError({
                      isSmall: true,
                    })}
                    placeholder={t('manage-products.job-title-placeholder')}
                  />
                )}
              />
            </InputWrapper>
          </div>
          <div className={styles.block}>
            <h3 className={styles.heading}>{t('common.field.location')}</h3>
            <InputWrapper
              wrapperClasses={styles.inputWrapper}
              label="common.field.company-address"
              isMediumInput
            >
              <Controller
                name="company.address"
                control={methods.control}
                render={({ field }) => (
                  <AddressField field={field} formSettings={methods} />
                )}
              />
            </InputWrapper>
            <InputWrapper wrapperClasses={styles.inputWrapper}>
              <CountryField
                formSettings={methods}
                name="company"
                isSmallInput
                isMediumLabel
              />
            </InputWrapper>
          </div>
        </div>
        <Button
          type="submit"
          className={cn(styles.submit, {
            [styles.disabled]: false,
          })}
        >
          {t('common.button.save')}
        </Button>
      </form>
      {error && (
        <div className={styles.error}>
          <Error message={error ? getResponseError(error) : ''} />
        </div>
      )}
    </>
  );
};

export default ProfileCompany;
