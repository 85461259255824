import type { MultiValue } from 'react-select';
import type { Milestone, CampaignResponse } from 'types/models';
import type { MilestoneValues } from 'components/CreateCampaign/models';

import { OfferStatuses, UserTypes } from 'constants/constants';

export enum OfferAction {
  accept = 'accept',
  rebid = 're-bid',
  decline = 'decline',
  bid = 'bid',
  assign = 'assign',
  cancel = 'cancel',
}

export type CampaignOfferAsset = {
  id?: number;
  leads_required?: number;
  leads_required_vendor?: number;
  asset: number;
  asset_title: string;
};

export type OfferFormValues = {
  id?: number;
  cpl: number;
  cpl_vendor?: number;
  comment?: string;
  comment_vendor?: string;
  vendor: number;
  status?: OfferStatuses;
  vendor_display_name: string;
  no_preference?: boolean;
  campaign_offer_milestones: Partial<{
    milestone: Milestone;
    leads_required: number;
    leads_required_vendor: number;
    selected_assets: MultiValue<{ value: number; label: string }> | null;
    campaign_offer_assets: CampaignOfferAsset[];
  }>[];
  last_responded_by?: UserTypes.vendor | UserTypes.vendorManager;
};

export type OffersFormValues = {
  offers: MultiValue<MembersOption>;
};

export type OfferSelectedAsset = {
  vendor: number;
  vendor_company_name: string;
};

export type AssetsLeadsByMilestone = Map<
  number,
  { [key: string]: Milestone['leads_per_asset'][0] }
>;

export type LeadsRequiredPerMilestoneDictionary = Map<number, number>;

export type AssignRequestBody = {
  index: number;
  values: Omit<OfferFormValues, 'campaign_offer_milestones'> & {
    campaign_offer_milestones: (Omit<
      OfferFormValues['campaign_offer_milestones'][0],
      'milestone'
    > & {
      milestone: number | null | undefined;
    })[];
  };
};

export type MembersOption = {
  value: OfferFormValues;
  label: string;
};

export type ReportedLeadsCount = {
  disqualified: number;
  qualified: number;
  wait_qualification: number;
};

export type MilestonesFormValues = {
  campaign_details: CampaignResponse['campaign_details'];
  campaign_delivery: CampaignResponse['campaign_delivery'] & {
    milestones: MilestoneValues[];
  };
};

export type OfferHistoryItem = {
  id: number;
  created_at: string;
  cpl: number;
  cpl_vendor: number;
  comment: string;
  comment_vendor: string;
  status: OfferStatuses;
  no_preference: boolean;
  last_responded_by: UserTypes.vendor | UserTypes.vendorManager;
  campaign_offer_milestones_history: {
    leads_required: number;
    leads_required_vendor: number;
    delivery_date: string;
    campaign_offer_assets_history: {
      leads_required: number;
      leads_required_vendor: number;
      title: string;
    }[];
  }[];
};
