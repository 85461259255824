import { useTranslation } from 'react-i18next';

import type { FeatureResponse } from 'components/CreateProductContent/models';
import type { ShareContactData } from 'types/models';

import SectionWrapper from 'components/CreateProductContent/SectionWrapper';
import MarketoForm from 'components/ProductPage/MarketoForm';

import IconSVG from 'components/UI/IconSVG';

import Button from 'components/common/Button';

import useModal from 'contexts/ModalContext';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  benefits?: FeatureResponse[];
  disadvantages?: FeatureResponse[];
  isGetADemoAvailable: boolean;
  onOpenGetDemo: () => void;
  buttonName?: string;
  productId?: number;
  companySlug?: string;
  shareContactData?: ShareContactData;
};

const PROD_BAMBOOHR_PRODUCT_ID = 82;
const PROD_BAMBOOHR_COMPANY_SLUG = 'bamboohr';
const DEV_TEST_COMPANY_SLUG = '1-company';
const DEV_TEST_PRODUCT_ID = 33;

const CompanyFeatures: React.FC<Props> = ({
  benefits,
  disadvantages,
  isGetADemoAvailable,
  onOpenGetDemo,
  buttonName,
  productId,
  companySlug,
  shareContactData,
}) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const handleOpenGetDemo = () => {
    const isMarketoForm =
      (productId === PROD_BAMBOOHR_PRODUCT_ID &&
        companySlug === PROD_BAMBOOHR_COMPANY_SLUG) ||
      (productId === DEV_TEST_PRODUCT_ID &&
        companySlug === DEV_TEST_COMPANY_SLUG);

    if (isMarketoForm) {
      return openModal({
        Content: <MarketoForm shareContactData={shareContactData} />,
      });
    }

    onOpenGetDemo();
  };

  const isFeaturesVisible = !!benefits?.length || !!disadvantages?.length;
  return (
    <>
      {isGetADemoAvailable && (
        <Button className={styles.demo} onClick={handleOpenGetDemo}>
          {buttonName ? buttonName : t('common.button.get-demo')}
        </Button>
      )}

      {isFeaturesVisible && (
        <SectionWrapper
          className={styles.companyFeatures}
          iconName={IconsNames.star_bordered}
          title={t('manage-products.features')}
        >
          {!!benefits?.length && (
            <>
              <p className={styles.type}>{t('manage-products.advantages')}</p>
              <ul className={styles.list}>
                {benefits.map(item => (
                  <li key={item.id} className={styles.benefits}>
                    <IconSVG name={IconsNames.check_mark} />
                    {item.name}
                  </li>
                ))}
              </ul>
            </>
          )}

          {!!disadvantages?.length && (
            <>
              <p className={styles.type}>
                {t('manage-products.disadvantages')}
              </p>
              <ul className={styles.list}>
                {disadvantages.map(item => (
                  <li key={item.id} className={styles.disadvantages}>
                    <IconSVG name={IconsNames.horizontal_rule} />
                    {item.name}
                  </li>
                ))}
              </ul>
            </>
          )}
        </SectionWrapper>
      )}
    </>
  );
};

export default CompanyFeatures;
