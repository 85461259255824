import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDebounce } from 'react-use';

import type { ControllerRenderProps } from 'react-hook-form';
import type { InputActionMeta } from 'react-select';

import useFetchDeliveryEmails from 'hooks/api/useFetchDeliveryEmails';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';
import { checkIsEmailValid } from 'utils/validations';

type Props = {
  field: Partial<ControllerRenderProps>;
  isError?: boolean;
  isSmall?: boolean;
};

const SelectEmail: React.FC<Props> = ({ field, isError, isSmall = false }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  useDebounce(() => setDebouncedValue(searchValue), 250, [searchValue]);

  const { isLoading, data: options } = useFetchDeliveryEmails({
    searchValue,
    debouncedValue,
  });

  const handleInputChange = (value: string, meta: InputActionMeta) => {
    if (meta.action === 'input-change') {
      setSearchValue(value);
    }
  };

  return (
    <CreatableSelect
      {...field}
      isMulti
      loadingMessage={() => t('common.field.loading')}
      styles={getDefaultSelectStylesWithError({ error: isError, isSmall })}
      placeholder={t('common.field.select')}
      noOptionsMessage={() => t('common.field.no-options')}
      isLoading={isLoading}
      options={options}
      isClearable
      isValidNewOption={checkIsEmailValid}
      onInputChange={handleInputChange}
      inputValue={searchValue}
    />
  );
};

export default SelectEmail;
