export const createDownloadFileLink = (href: string, name: string) => {
  const link = document.createElement('a');
  link.href = href;
  link.download = name;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(link.href);
    link?.parentNode?.removeChild(link);
  }, 2000);
};

export const downloadFile = (
  data: string,
  typeOfFile: string,
  nameOfFile: string
) => {
  const fileName = nameOfFile.includes('filename=')
    ? nameOfFile.split('filename=')[1]
    : nameOfFile;

  const blob = new Blob([data], { type: typeOfFile });
  createDownloadFileLink(window.URL.createObjectURL(blob), fileName);
};
