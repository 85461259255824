import { useParams } from 'react-router-dom';

import PageContainer from 'components/common/PageContainer';

import CampaignDetailsDeliveries from 'components/CampaignsListDeliveries/CampaignDetails';

import { useGetCampaign } from 'hooks/api/useGetCampaign';

import { PrivatePaths } from 'constants/routes';

const CampaignDeliveriesDetails = () => {
  const { id } = useParams();
  const { data: campaign } = useGetCampaign(id);

  return (
    <PageContainer
      path={
        campaign?.campaign_details.name
          ? [campaign?.campaign_details.name]
          : undefined
      }
      backLinkPath={`/${PrivatePaths.CAMPAIGN_DELIVERIES}`}
      isWhiteBackground
    >
      <CampaignDetailsDeliveries />
    </PageContainer>
  );
};

export default CampaignDeliveriesDetails;
