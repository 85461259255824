import { useMemo, useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import * as Sentry from '@sentry/react';

import type { AxiosError } from 'axios';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';

import Option from 'components/RichText/VariableList/Option';

import IconSVG from 'components/UI/IconSVG';

import useEscapeEvent from 'hooks/useEscapeEvent';
import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';
import { VariablesInTemplates } from 'constants/scriptTemplates';

import styles from './index.module.scss';

type Props = {
  selectVariable: (variable: string) => void;
  toggleList: () => void;
  setListVisibility: (isVisible: boolean) => void;
  selectedVariable?: string;
  style?: React.CSSProperties;
};

const OptionsList = ({
  selectVariable,
  toggleList,
  setListVisibility,
  selectedVariable,
  style,
}: Props) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const containerRef = useRef(null);
  const { axios } = useAuth();
  useClickAway(containerRef, toggleList);
  useEscapeEvent(() => setListVisibility(false));

  const {
    data: variables,
    isLoading,
    error,
  } = useQuery<string[], AxiosError>(
    ['script-builder-variables'],
    async () => {
      try {
        const { data } = await axios.get<string[]>(
          '/script-builder-variables/'
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: list => {
        const notExistedVariables = Object.values(VariablesInTemplates).filter(
          variable => !list.includes(variable)
        );
        if (!notExistedVariables.length) {
          Sentry.captureMessage(
            `Script builder variables missed: ${notExistedVariables.join(', ')}`
          );
        }
      },
    }
  );

  const handleSearchChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
  };

  const optionsList = useMemo(() => {
    return (
      variables?.filter(option => option.toLowerCase().match(searchValue)) || []
    );
  }, [searchValue, variables]);

  const isEmptyOptions = optionsList.length === 0;

  return (
    <div
      className={styles.options}
      contentEditable={false}
      ref={containerRef}
      style={style}
    >
      <div className={styles.search}>
        <IconSVG name={IconsNames.magnifier} className={styles.magnifier} />
        <input
          type="text"
          value={searchValue}
          onChange={handleSearchChange}
          contentEditable={false}
          placeholder={t('common.field.search')}
        />
        {!!searchValue && (
          <IconSVG
            name={IconsNames.close}
            className={styles.clear}
            onClick={() => setSearchValue('')}
          />
        )}
      </div>
      {isEmptyOptions && !isLoading && !error && (
        <p className={styles.empty} contentEditable={false}>
          {t('script.no-options')}
        </p>
      )}
      <ul
        className={styles.list}
        contentEditable={false}
        aria-label="script variables list"
      >
        {isLoading && <Loader size={32} className={styles.loading} />}
        {error && (
          <Error message={getResponseError(error)} className={styles.error} />
        )}
        {optionsList.map(option => (
          <Option
            key={option}
            selectVariable={selectVariable}
            option={option}
            setSearchValue={setSearchValue}
            selectedVariable={selectedVariable}
          />
        ))}
      </ul>
    </div>
  );
};

export default OptionsList;
