import type { AssetValues } from 'components/CreateCampaign/models';
import type { CampaignAssetFileResponse } from 'types/models';

import IconSVG from 'components/UI/IconSVG';

import { CustomDefaultValue, IconsNames } from 'constants/constants';
import { AssetAttachedType, AssetDistributionType } from 'constants/assets';

import { ReactComponent as ExcelSVG } from 'assets/images/files/excel.svg';
import { ReactComponent as ImageSVG } from 'assets/images/files/image.svg';
import { ReactComponent as PDFSVG } from 'assets/images/files/pdf.svg';
import { ReactComponent as TXTSVG } from 'assets/images/files/txt.svg';
import { ReactComponent as WordSVG } from 'assets/images/files/word.svg';
import { ReactComponent as XLSSVG } from 'assets/images/files/xls.svg';
import { ReactComponent as ZIPSVG } from 'assets/images/files/zip.svg';
import { ReactComponent as VideoSVG } from 'assets/images/files/video.svg';
import { ReactComponent as PowerPointSVG } from 'assets/images/files/powerpoint.svg';
import { ReactComponent as AISVG } from 'assets/images/files/ai.svg';
import { ReactComponent as APKSVG } from 'assets/images/files/apk.svg';
import { ReactComponent as CSSSVG } from 'assets/images/files/css.svg';
import { ReactComponent as FontSVG } from 'assets/images/files/font.svg';
import { ReactComponent as ISOSVG } from 'assets/images/files/iso.svg';
import { ReactComponent as AudioSVG } from 'assets/images/files/audio.svg';
import { ReactComponent as PHPSVG } from 'assets/images/files/php.svg';
import { ReactComponent as PSDSVG } from 'assets/images/files/psd.svg';
import { ReactComponent as SVGSVG } from 'assets/images/files/svg.svg';
import { ReactComponent as JSSVG } from 'assets/images/files/js.svg';
import { ReactComponent as FileSVG } from 'assets/images/files/file.svg';

const getIsDefaultChecked = (value: CustomDefaultValue) =>
  value === CustomDefaultValue.default;

export const createAssetFormData = ({
  asset,
  privacyPolicyLink,
}: {
  asset: Partial<AssetValues | CampaignAssetFileResponse>;
  privacyPolicyLink?: string;
}) => {
  const formData = new FormData();

  const entries = Object.entries(asset);
  entries.forEach(([key, value]) => {
    if (typeof value === 'string') {
      formData.append(key, value);
    }
    if (value && typeof value === 'object' && 'value' in value) {
      formData.append(key, String(value.value));
    }
    if (typeof value === 'number') {
      formData.append(key, value.toString());
    }
  });

  if (privacyPolicyLink) {
    formData.append('product_privacy_policy_url', privacyPolicyLink);
  }

  if (typeof asset.attached_file === 'object' && !!asset.attached_file) {
    formData.append('attached_file', asset.attached_file);
  } else {
    formData.delete('attached_file');
  }

  if (typeof asset.landing_image === 'object' && asset.landing_image?.file) {
    formData.append('landing_image', asset.landing_image.file);
  } else {
    formData.delete('landing_image');
  }

  if (
    asset.html_template_logo &&
    typeof asset.html_template_logo === 'object'
  ) {
    formData.append('html_template_logo', asset.html_template_logo);
  } else {
    formData.delete('html_template_logo');
  }

  if (
    asset.html_template_image &&
    typeof asset.html_template_image === 'object'
  ) {
    formData.append('html_template_image', asset.html_template_image);
  } else {
    formData.delete('html_template_image');
  }

  const isDefaultPrivacy = getIsDefaultChecked(
    asset.html_template_privacy_is_default || CustomDefaultValue.default
  );
  formData.set('html_template_privacy_is_default', String(isDefaultPrivacy));
  if (isDefaultPrivacy) {
    formData.delete('html_template_privacy_value');
  }
  formData.set('html_template_terms_is_default', String(true));
  formData.delete('product_logo');

  return formData;
};

const dictionary = [
  {
    extensions: [
      'xlsx',
      'xlsm',
      'xlsb',
      'xltx',
      'xltm',
      'xlt',
      'xlam',
      'xla',
      'xlw',
      'xlr',
      'csv',
      'tsv',
    ],
    icon: ExcelSVG,
  },
  {
    extensions: [
      'png',
      'jpeg',
      'jpg',
      'gif',
      'ico',
      'tif',
      'tiff',
      'psb',
      'ami',
      'apx',
      'bmp',
      'bpg',
      'brk',
      'cur',
      'dds',
      'dng',
      'exr',
      'fpx',
      'gbr',
      'img',
      'jbig2',
      'jb2',
      'jng',
      'jxr',
      'pbm',
      'pgf',
      'pic',
      'raw',
      'webp',
      'eps',
      'afphoto',
      'ase',
      'aseprite',
      'clip',
      'cpt',
      'heif',
      'heic',
      'kra',
      'mdp',
      'ora',
      'pdn',
      'reb',
      'sai',
      'tga',
      'xcf',
      'jfif',
    ],
    icon: ImageSVG,
  },
  {
    extensions: ['ai'],
    icon: AISVG,
  },
  {
    extensions: ['doc', 'docx', 'docm', 'dot', 'dotm', 'dotx', 'rtf'],
    icon: WordSVG,
  },
  {
    extensions: ['xls'],
    icon: XLSSVG,
  },
  {
    extensions: ['pdf'],
    icon: PDFSVG,
  },
  {
    extensions: ['css', 'scss', 'sass', 'less', 'json'],
    icon: CSSSVG,
  },
  {
    extensions: [
      'woff',
      'woff2',
      'ttf',
      'eot',
      'suit',
      'otf',
      'bmap',
      'fnt',
      'odttf',
      'ttc',
      'font',
      'fonts',
      'sui',
      'ntf',
      'mrf',
    ],
    icon: FontSVG,
  },
  {
    extensions: ['apk'],
    icon: APKSVG,
  },
  {
    extensions: [
      'zip',
      'tar',
      'gz',
      'xz',
      'br',
      'bzip2',
      'gzip',
      'brotli',
      '7z',
      'rar',
      'tgz',
    ],
    icon: ZIPSVG,
  },
  {
    extensions: ['txt'],
    icon: TXTSVG,
  },
  {
    extensions: [
      'pptx',
      'ppt',
      'pptm',
      'potx',
      'potm',
      'ppsx',
      'ppsm',
      'pps',
      'ppam',
      'ppa',
    ],
    icon: PowerPointSVG,
  },
  {
    extensions: [
      'webm',
      'mkv',
      'flv',
      'vob',
      'ogv',
      'ogg',
      'gifv',
      'avi',
      'mov',
      'qt',
      'wmv',
      'yuv',
      'rm',
      'rmvb',
      'mp4',
      'm4v',
      'mpg',
      'mp2',
      'mpeg',
      'mpe',
      'mpv',
      'm2v',
    ],
    icon: VideoSVG,
  },

  { extensions: ['iso'], icon: ISOSVG },

  { extensions: ['mp3', 'flac', 'm4a', 'wma', 'aiff'], icon: AudioSVG },

  { extensions: ['php'], icon: PHPSVG },

  { extensions: ['psd'], icon: PSDSVG },

  { extensions: ['svg'], icon: SVGSVG },

  { extensions: ['js', 'esx', 'mjs', 'jsx', 'tsx'], icon: JSSVG },
];

const getFileIcon = (name: string) => {
  const splittedName = name.split('.');
  const extension = splittedName[splittedName.length - 1];

  const icon = dictionary.find(settings =>
    settings.extensions.some(ext => ext === extension)
  )?.icon;

  return icon || FileSVG;
};

export const getFileIconFromURL = (link: string) => {
  const url = new URL(link);

  return getFileIcon(url.pathname);
};

export const getFileIconFromFileName = (fileName: string) =>
  getFileIcon(fileName);

export const getAssetIconByAssetType = ({
  assetType,
  fileName,
  fileLink,
  className,
}: {
  assetType: AssetAttachedType;
  fileName?: string;
  fileLink?: string;
  className?: string;
}) => {
  switch (assetType) {
    case AssetAttachedType.product:
      return () => <IconSVG name={IconsNames.product} className={className} />;
    case AssetAttachedType.file:
      const isFileLink = fileLink && getFileIconFromURL(fileLink);
      const isFileName = fileName && getFileIconFromFileName(fileName);

      return isFileLink || isFileName || null;
    default:
      return () => <IconSVG name={IconsNames.link} className={className} />;
  }
};

export const getAssetLink = (asset: {
  company_slug?: string;
  asset_type: AssetAttachedType;
  product_slug?: string;
  attached_file_link?: string;
  attached_file?: string | File;
}) => {
  switch (asset.asset_type) {
    case AssetAttachedType.product:
      return `${window.location.origin}/${asset.company_slug}/${asset.product_slug}/`;
    case AssetAttachedType.link:
      return asset.attached_file_link;
    case AssetAttachedType.file:
      return typeof asset.attached_file === 'string'
        ? asset.attached_file
        : asset.attached_file_link;

    default:
      return '';
  }
};

const isPortugueseCampaign = (campaignId?: number, campaignName?: string) =>
  campaignId === 288 ||
  campaignName === 'Instructure Single Touch Content Syndication - PT';
const isSpanishCampaign = (campaignId?: number, campaignName?: string) =>
  campaignId === 409 ||
  campaignName === 'Instructure Single Touch Content Syndication - SPA';

export const getDefaultEmailTermsAndConditions = (
  campaignId?: number,
  campaignName?: string,
  email?: string
) => {
  let terms =
    'This email was sent to {Email}, you can email us at info@beeleads.com to manage your email preferences.';
  if (isPortugueseCampaign(campaignId, campaignName)) {
    terms =
      'Este e-mail foi enviado para {Email}, você pode enviar um e-mail para info@beeleads.com para gerenciar suas preferências de e-mail.';
  }
  if (isSpanishCampaign(campaignId, campaignName)) {
    terms =
      'Este correo electrónico fue enviado a {Email}, puede enviarnos un correo electrónico a info@beeleads.com para administrar sus preferencias de correo electrónico.';
  }

  return email ? terms.replace(/{Email}/g, email) : terms;
};

export const getDefaultTermsAndConditions = (
  campaignId?: number,
  campaignName?: string
) => {
  let terms =
    'By clicking/downloading the asset, you agree to allow the sponsor and leadforce AI Inc. and its partners to have your contact information and contact you using any mode of communication. You will also receive communications from the sponsor and leadforce AI Inc. and its partners about products, offerings and industry trends, however you can unsubscribe to these at any time.';
  if (isPortugueseCampaign(campaignId, campaignName)) {
    terms =
      'Você tem a opção de clicar/baixar o recurso, neste caso você concorda em permitir que o patrocinador, a Leadforce AI Inc. e seus parceiros tenham suas informações de contato e contatem você usando qualquer meio de comunicação. Você também receberá comunicações do patrocinador, da Leadforce AI Inc. e seus parceiros sobre produtos, ofertas e tendências do setor; no entanto, você tem a opção de cancelar sua assinatura a qualquer momento.';
  }
  if (isSpanishCampaign(campaignId, campaignName)) {
    terms =
      'Tiene la opción de hacer clic/descargar el recurso, en cuyo caso acepta permitir que el patrocinador, Leadforce AI Inc. y sus socios tengan su información de contacto y se comuniquen con usted mediante cualquier modo de comunicación. También recibirá comunicaciones del patrocinador, Leadforce AI Inc. y sus socios sobre productos, ofertas y tendencias de la industria; sin embargo, tiene la opción de cancelar su subscripción en cualquier momento.';
  }

  return terms;
};

export const getPrivacyPolicyLinkTitle = (
  campaignId?: number,
  campaignName?: string
) => {
  let title = 'Privacy Policy';
  if (isPortugueseCampaign(campaignId, campaignName)) {
    title = 'Política de Privacidade';
  }
  if (isSpanishCampaign(campaignId, campaignName)) {
    title = 'Política de privacidad';
  }
  return title;
};
export const getUnsubscribeLinkTitle = (
  campaignId?: number,
  campaignName?: string
) => {
  let title = 'Unsubscribe';
  if (isPortugueseCampaign(campaignId, campaignName)) {
    title = 'Anular a subscrição';
  }
  if (isSpanishCampaign(campaignId, campaignName)) {
    title = 'Cancelar suscripción';
  }
  return title;
};
export const getLearnMoreLinkTitle = (
  campaignId?: number,
  campaignName?: string
) => {
  let title = 'Learn More';
  if (isPortugueseCampaign(campaignId, campaignName)) {
    title = 'Saiba mais';
  }
  if (isSpanishCampaign(campaignId, campaignName)) {
    title = 'Saber más';
  }
  return title;
};

export const getIsAutomaticallyDistributed = (value: string) =>
  value === AssetDistributionType['no-preference'] ||
  value === AssetDistributionType['equally-distributed'];
