import * as Yup from 'yup';

import {
  REQUIRED_MESSAGE,
  MAX_LENGTH_MESSAGE,
  YOUTUBE_LINK_REGEXP,
  INVALID_VALUE,
} from 'utils/validations';

import { AssetAttachedType, VIDEO_ASSET_CONTENT_TYPES } from 'constants/assets';

export const validationSchemaCreateAsset = (
  isSelectProductAvailable?: boolean
) =>
  Yup.lazy(() =>
    Yup.object().shape({
      content_type: Yup.mixed().test('content_type', function (value) {
        if (this.parent.asset_type !== AssetAttachedType.product && !value) {
          return this.createError({
            path: this.path,
            message: REQUIRED_MESSAGE,
          });
        }
        return true;
      }),
      title: Yup.string()
        .required(REQUIRED_MESSAGE)
        .max(150, { key: MAX_LENGTH_MESSAGE, values: { length: 150 } }),
      description: Yup.string().required(REQUIRED_MESSAGE),
      type: Yup.mixed().required(REQUIRED_MESSAGE),
      asset_type: Yup.string(),
      attached_file: Yup.mixed().test('attached_file', function (value) {
        if (this.parent.asset_type === AssetAttachedType.file && !value) {
          return this.createError({
            path: this.path,
            message: REQUIRED_MESSAGE,
          });
        }
        return true;
      }),
      attached_file_link: Yup.string()
        .url('common.error.invalid-url')
        .test('attached_file_link', function (value) {
          if (this.parent.asset_type === AssetAttachedType.link) {
            if (!value) {
              return this.createError({
                path: this.path,
                message: REQUIRED_MESSAGE,
              });
            }

            const isYoutubeLink = VIDEO_ASSET_CONTENT_TYPES.some(
              type => this.parent.content_type?.value === type
            );
            if (isYoutubeLink) {
              const match = value.match(YOUTUBE_LINK_REGEXP);

              if (!match || match[2].length !== 11) {
                return this.createError({
                  message: INVALID_VALUE,
                  path: this.path,
                });
              }
            }
          }
          return true;
        }),
      attached_file_link_name: Yup.string()
        .nullable()
        .max(30, {
          key: MAX_LENGTH_MESSAGE,
          values: { length: 30 },
        }),

      ...(isSelectProductAvailable
        ? { product: Yup.object().required(REQUIRED_MESSAGE) }
        : {}),
    })
  );
