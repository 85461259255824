import { createContext, useState, useRef, useContext } from 'react';
import ReactDOM from 'react-dom';

export type LoaderScreenState = {
  visible: boolean;
  Content: React.ReactNode | null;
};

const initialState = {
  visible: false,
  Content: null,
};

export const LoaderScreenContext = createContext<{
  closeLoaderScreen: () => void;
  openLoaderScreen: (data: Omit<LoaderScreenState, 'visible'>) => void;
}>({
  closeLoaderScreen: () => {},
  openLoaderScreen: () => {},
});

export const LoaderScreenProvider: React.FC = ({ children }) => {
  const [loaderScreen, setLoaderScreen] =
    useState<LoaderScreenState>(initialState);
  const loaderContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const openLoaderScreen = (data: Omit<LoaderScreenState, 'visible'>) => {
    setLoaderScreen({ ...data, visible: true });
  };
  const closeLoaderScreen = () => setLoaderScreen(initialState);

  const value = {
    openLoaderScreen,
    closeLoaderScreen,
  };

  return (
    <LoaderScreenContext.Provider value={value}>
      <div ref={loaderContainerRef} />
      {children}
      {loaderScreen.visible &&
        loaderContainerRef &&
        ReactDOM.createPortal(
          loaderScreen.Content && <>{loaderScreen.Content}</>,
          loaderContainerRef.current
        )}
    </LoaderScreenContext.Provider>
  );
};

const useLoaderScreen = () => useContext(LoaderScreenContext);

export default useLoaderScreen;
