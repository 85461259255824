import { Transforms, Path, Node as SlateNode, Text } from 'slate';
import { ReactEditor } from 'slate-react';
import { jsx } from 'slate-hyperscript';
import DOMPurify from 'dompurify';
import { renderToStaticMarkup } from 'react-dom/server';

import type { Descendant } from 'slate';
import type { CampaignScriptValues } from 'components/CreateCampaign/models';

import IconSVG from 'components/UI/IconSVG';

import { SlateElementTypes } from 'components/RichText/models';

import { ASSET_PDF_DYNAMIC_VARIABLES } from 'constants/scriptTemplates';
import { ScriptQuestionType, IconsNames } from 'constants/constants';

import { ReactComponent as LogoSVG } from 'assets/images/logo.svg';

export const withCustomElements = (editor: ReactEditor) => {
  const { isInline } = editor;

  editor.isInline = element =>
    element.type === SlateElementTypes.variable || isInline(element);

  editor.isVoid = element => element.type === SlateElementTypes.variable;

  return editor;
};

export const withRegexValidation = (
  replaceRegexp: RegExp,
  editor: ReactEditor
) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (Text.isText(node) && node.text.match(replaceRegexp)) {
      Transforms.insertText(editor, node.text.replaceAll(replaceRegexp, ''), {
        at: path,
      });
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

export const findNodePath = (editor: ReactEditor, node: SlateNode) => {
  let path: Path | undefined;
  try {
    path = ReactEditor.findPath(editor, node);
  } catch (error) {
    path = undefined;
  }

  return path;
};

export const convertDescendantToString = (
  descendants: Descendant[] | Descendant
): string => {
  if ('value' in descendants && descendants.value) {
    return descendants.value;
  }

  if (Array.isArray(descendants)) {
    return descendants
      .map(descendant => convertDescendantToString(descendant))
      .join('');
  }

  if ('children' in descendants && Array.isArray(descendants.children)) {
    return descendants.children
      .map(descendant => convertDescendantToString(descendant))
      .join('');
  }

  return DOMPurify.sanitize(SlateNode.string(descendants));
};

export const checkIsHasVariables = (
  descendants: Descendant[] | Descendant
): boolean => {
  let hasVariables: boolean = false;

  if ('value' in descendants && descendants.value) {
    hasVariables = true;
    return hasVariables;
  }

  if (Array.isArray(descendants) && !hasVariables) {
    hasVariables = descendants.some(descendant =>
      checkIsHasVariables(descendant)
    );
  }

  if (
    'children' in descendants &&
    Array.isArray(descendants.children) &&
    !hasVariables
  ) {
    hasVariables = descendants.children.some(descendant =>
      checkIsHasVariables(descendant)
    );
  }

  return hasVariables;
};

const deserializeHTMLToEditorData = (
  element: Document | HTMLElement | ChildNode
): Descendant[] | Descendant | null | (Descendant | null)[] => {
  const { textContent, nodeType, nodeName, childNodes } = element;
  const isHtmlElement = element instanceof HTMLElement;

  if (nodeType === Node.TEXT_NODE) {
    return jsx('text', {}, textContent);
  } else if (nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const children = Array.from(childNodes)
    .map(node => deserializeHTMLToEditorData(node))
    .flat();
  if (!children.length) {
    children.push(jsx('text', {}, ''));
  }
  if (!isHtmlElement) return children;

  const { type } = element.dataset;
  const isVariable = type === SlateElementTypes.variable;

  switch (nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    default:
      return jsx(
        'element',
        {
          type,
          ...(isVariable
            ? {
                value: element.textContent?.replaceAll(/{|}/g, ''),
              }
            : {}),
        },
        children
      );
  }
};

export const convertHTMLToEditorData = (htmlString: string) => {
  const document = new DOMParser().parseFromString(
    htmlString.replace(/\\/g, ''),
    'text/html'
  );
  const result = deserializeHTMLToEditorData(
    document.body.getElementsByTagName('main')[0] || document.body
  );
  if (!result) return [];
  const filteredResult = Array.isArray(result)
    ? (result.filter(Boolean) as Array<Descendant>)
    : [result];

  return filteredResult;
};

export const convertEditorDataToHTML = (
  nodeData: Descendant[] | Descendant,
  isDynamicVariables = true
): string => {
  if (Text.isText(nodeData)) return nodeData.text;

  if (Array.isArray(nodeData)) {
    return nodeData
      .map(node => convertEditorDataToHTML(node, isDynamicVariables))
      .join('');
  }

  const { type, children, value } = nodeData;

  const nodeChildren = DOMPurify.sanitize(
    children
      ?.map(node => convertEditorDataToHTML(node, isDynamicVariables))
      .join('') || ''
  );
  const dataAttributes = `data-type="${type}"`;

  switch (type) {
    case SlateElementTypes.paragraph:
      return `<p ${dataAttributes}>${nodeChildren}</p>`;
    case SlateElementTypes.variable:
      const variableValue =
        isDynamicVariables ||
        ASSET_PDF_DYNAMIC_VARIABLES.some(val => val === value)
          ? `{{${value}}}`
          : value;
      return `<span ${dataAttributes}>${variableValue}</span>`;
    default:
      return nodeChildren;
  }
};

export const createScriptHTMLForPDF = (scriptData: CampaignScriptValues) => {
  const CASLComplianceHTML = !!scriptData.compliance_casl_block
    ? `<h2>${renderToStaticMarkup(
        <IconSVG name={IconsNames.compliance_lock_line} className="icon" />
      )} CASL compliance</h2>${convertEditorDataToHTML(
        scriptData.compliance_casl_block,
        false
      )}`
    : '';
  const GDPRComplianceHTML = !!scriptData.compliance_gdpr_block
    ? `<h2>${renderToStaticMarkup(
        <IconSVG name={IconsNames.compliance_lock_line} className="icon" />
      )} GDPR compliance</h2>${convertEditorDataToHTML(
        scriptData.compliance_gdpr_block,
        false
      )}`
    : '';
  const questionsHTML = scriptData.questions?.length
    ? `<h2>${renderToStaticMarkup(
        <IconSVG name={IconsNames.help_outline} className="icon" />
      )} Qualifying questions</h2><ul>${scriptData.questions
        .map(
          question =>
            `<li>
              <h3>${convertEditorDataToHTML(question.name, false)}</h3>
              <i class="answer-type">${
                question.answer_type === ScriptQuestionType.checkbox
                  ? 'multiple answers available'
                  : 'single answer available'
              }</i>
              <ul>${question.answers
                .map(
                  answer =>
                    `<li>${convertEditorDataToHTML(answer.name, false)}</li>`
                )
                .join('')}
              </ul>
            </li>`
        )
        .join('')}</ul>`
    : '';

  const logoHTML = renderToStaticMarkup(<LogoSVG />);

  return `<!DOCTYPE html>
              <html lang="en">
                <head>
                  <meta charset="utf-8" />
                  <meta name="viewport" content="width=device-width, initial-scale=1" />
                  <link rel="preconnect" href="https://fonts.googleapis.com">
                  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                  <link href="https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,600;1,400&display=swap" rel="stylesheet">
                </head>
                <style>
                  header {
                    margin-bottom: 30px;
                  }
                  main {
                    font-family: Arimo, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    color: #1c1c1c;
                  }
                  h2 {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;
                    margin-top: 38px;
                  }
                  h3 {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 0;
                  }
                  h3 p {
                    margin: 0;
                  }
                  footer { 
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 30px;
                  }
                  span[data-type="${SlateElementTypes.variable}"] {
                    padding: 4px 8px;
                    background-color: #e7f0ff;
                    border-radius: 200px;
                  }
                  .answer-type {
                    color: #969696;
                    font-style: italic;
                    display: block;
                    margin-top: 0;
                  }
                  .icon {
                    position: relative;
                    bottom: -2px;
                    display: inline-block;
                    margin-right: 8px;
                  }
                  .closing {
                    transform: scaleX(-1);
                  }
                </style>
                <body>
                   <header>
                    <table style="width: 100%; border-collapse: collapse;">
                      <tr>
                        <td style="width: 1px; white-space: nowrap;">
                         ${logoHTML}
                        </td>
                        <td style="text-align: end;">
                          <img style="display: inline-block; max-width: 130px;" width="120" src="{{html_template_logo}}" />
                        </td>
                      </tr>
                    </table>
                  </header>
                  <main>
                    <h2>${renderToStaticMarkup(
                      <IconSVG name={IconsNames.hello_hand} className="icon" />
                    )} Introduction</h2>
                    ${convertEditorDataToHTML(
                      scriptData.introduction_block,
                      false
                    )}
                    <h2>${renderToStaticMarkup(
                      <IconSVG name={IconsNames.file_stroke} className="icon" />
                    )} Asset</h2>
                    ${convertEditorDataToHTML(scriptData.asset_block, false)}
                    ${CASLComplianceHTML}
                    ${GDPRComplianceHTML}
                    ${questionsHTML}
                    <h2>${renderToStaticMarkup(
                      <IconSVG
                        name={IconsNames.hello_hand}
                        className="icon closing"
                      />
                    )} Closing</h2>
                    ${convertEditorDataToHTML(scriptData.closing_block, false)}
                  </main>
                  <footer>
                  ${logoHTML}
                  </footer>
                </body>
              </html>`;
};
