import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import type { UseFormReturn, UseFieldArrayRemove } from 'react-hook-form';
import type { MilestonesFormValues } from 'components/CampaignManage/models';

import InputWrapper from 'components/common/InputWrapper';
import NumberField from 'components/common/NumberField';
import DatePicker from 'components/common/DatePicker';
import Button from 'components/common/Button';

import { MilestoneType, IconsNames } from 'constants/constants';

import { getMilestoneDeliveryDateSettings } from 'helpers/createCampaign';

import styles from './index.module.scss';

type Props = {
  type: MilestoneType;
  disabled?: boolean;
  index: number;
  formMethods?: UseFormReturn<MilestonesFormValues, object>;
  startDate: string | null;
  endDate: string | null;
  remove?: UseFieldArrayRemove;
};

const MilestoneItem = ({
  type,
  disabled,
  index,
  formMethods,
  startDate,
  endDate,
  remove,
}: Props) => {
  const { t } = useTranslation();

  const isError =
    formMethods?.formState?.errors?.campaign_delivery?.milestones &&
    formMethods?.formState?.errors?.campaign_delivery?.milestones[index];

  const isMonthly = type === MilestoneType.monthly;

  const removeMilestone = () => remove && remove(index);

  const { fromMonth, fromMonthMonthly, toMonth, monthlyError } =
    getMilestoneDeliveryDateSettings({
      t,
      campaignStartDate: startDate,
      campaignEndDate: endDate,
      isError,
    });

  return (
    <li className={styles.wrapper}>
      <Button
        onClick={removeMilestone}
        type="button"
        className={styles.wrap_button}
        iconProps={{ name: IconsNames.delete }}
      />

      <div className={styles.milestone}>
        <Controller
          name={`campaign_delivery.milestones.${index}.delivery_date`}
          control={formMethods?.control}
          render={({ field: { value, onChange } }) =>
            !isMonthly ? (
              <InputWrapper
                label="common.field.custom-date"
                wrapperClasses={styles.label}
                validationError={isError?.delivery_date?.message || ''}
              >
                <DatePicker
                  value={value || ''}
                  onChange={onChange}
                  isMonthly={isMonthly}
                  fromMonth={fromMonthMonthly}
                  toMonth={toMonth}
                />
              </InputWrapper>
            ) : (
              <DatePicker
                value={value || ''}
                onChange={onChange}
                isMonthly={isMonthly}
                wrapperClassName={styles.date}
                fromMonth={fromMonth}
                validationError={monthlyError}
                toMonth={toMonth}
              />
            )
          }
        />
      </div>
      <div className={styles.leads}>
        <InputWrapper
          label="campaign.requested-leads"
          wrapperClasses={styles.label}
          validationError={isError?.total_leads_amount?.message || ''}
        >
          <Controller
            name={`campaign_delivery.milestones.${index}.total_leads_amount`}
            control={formMethods?.control}
            render={({ field }) => (
              <NumberField
                field={field}
                placeholder={t('common.field.no-of-leads')}
                disabled={disabled}
              />
            )}
          />
        </InputWrapper>
      </div>
    </li>
  );
};

export default MilestoneItem;
