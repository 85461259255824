import { useEffect, useState } from 'react';

const useTimer = (time: number) => {
  const [timeLeft, setTimeLeft] = useState<number>(time);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  return { timeLeft, setTimeLeft };
};

export default useTimer;
