import { FC } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

export type TableCompoundProps = {
  className?: string;
};

export const TableHeader: FC<TableCompoundProps> = ({
  children,
  className,
  ...props
}) => (
  <div {...props} className={cn(styles.header, className)}>
    {children}
  </div>
);

export const TableContent: FC<TableCompoundProps> = ({
  children,
  className,
  ...props
}) => (
  <div {...props} className={cn(styles.content, className)}>
    {children}
  </div>
);

export const TableTitle: FC<TableCompoundProps> = ({
  children,
  className,
  ...props
}) => (
  <h3 {...props} className={cn(styles.title, className)}>
    {children}
  </h3>
);
