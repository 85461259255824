import { useQuery } from 'react-query';

import type { AxiosError } from 'axios';
import type { BalanceResponse } from 'components/PaymentsComponents/models';

import useAuth from 'contexts/AuthContext';

const useGetBalance = (enabled = true) => {
  const { axios, user } = useAuth();

  const request = useQuery<BalanceResponse, AxiosError>(
    ['balance', user?.company.id],
    async () => {
      try {
        const { data } = await axios.get<BalanceResponse>(
          '/payments/general-balance/'
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    { enabled: !!user?.company.id && enabled }
  );

  return request;
};

export default useGetBalance;
