import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { CampaignResponse } from 'types/models';

import ToggleSwitch from 'components/common/ToggleSwitch';

import useConvertCampaignData from 'hooks/useConvertCampaignData';

import styles from './index.module.scss';

type Props = {
  data:
    | CreateCampaignValues['campaign_delivery']
    | CampaignResponse['campaign_delivery'];
};

const Delivery = ({ data }: Props) => {
  const { t } = useTranslation();
  const { convertMilestoneDate } = useConvertCampaignData();

  const {
    start_date: startDate,
    end_date: endDate,
    milestone_deadline_type: milestone,
    milestones: milestoneData,
    is_front_load_scoring: frontLoad,
  } = data || {};

  return (
    <>
      <h4 className={styles.subheading}>{t('campaign.delivery')}</h4>
      <ul className={styles.data}>
        <li className={cn(styles.data__item, styles.item)}>
          <h5 className={styles.title}>
            {t('common.field.campaign-start-date')}:
          </h5>
          <p className={styles.text}>{startDate || '—'}</p>
        </li>
        <li className={cn(styles.data__item, styles.item)}>
          <h5 className={styles.title}>{t('common.field.front-load')}:</h5>
          <ToggleSwitch disabled checked={frontLoad} id="front load" />
        </li>
        <li className={cn(styles.data__item, styles.item)}>
          <h5 className={styles.title}>
            {t('common.field.campaign-end-date')}:
          </h5>
          <p className={styles.text}>{endDate || '—'}</p>
        </li>
        <li className={cn(styles.item, styles.milestone)}>
          <h5 className={styles.title}>{t('common.field.milestone')}:</h5>
          <p className={styles.text}>
            {typeof milestone === 'string'
              ? t(`common.field.${milestone}`)
              : milestone?.label}
          </p>
        </li>

        {milestoneData && (
          <ul className={styles.milestone__list}>
            {milestoneData.map(
              (
                { id, delivery_date: date, total_leads_amount: leadsCount },
                index
              ) => (
                <li className={styles.item} key={id}>
                  <p className={styles.milestone__item}>
                    <span className={styles.milestone__value}>
                      {t('common.field.date')}:
                    </span>
                    {convertMilestoneDate(
                      typeof milestone === 'string'
                        ? milestone
                        : milestone.value,
                      date,
                      index === milestoneData.length - 1
                    ) || '—'}
                  </p>
                  <p className={styles.milestone__item}>
                    <span className={styles.milestone__value}>
                      {t('common.field.no-of-leads')}:
                    </span>
                    {leadsCount || '—'}
                  </p>
                </li>
              )
            )}
          </ul>
        )}
      </ul>
    </>
  );
};

export default Delivery;
