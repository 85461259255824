import { components } from 'react-select';
import type { MenuProps, GroupBase } from 'react-select';

import { SelectOption } from 'types/models';

import styles from './index.module.scss';

interface Props
  extends MenuProps<SelectOption, false, GroupBase<SelectOption>> {
  setIsSecondOptions: (isSecondOptions: boolean) => void;
  isSecondOptions: boolean;
  searchCount: { first: number; second: number };
  firstLabel: string;
  secondLabel: string;
}

const Menu: React.FC<Props> = ({
  setIsSecondOptions,
  isSecondOptions,
  children,
  searchCount: { first, second },
  firstLabel,
  secondLabel,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setIsSecondOptions(value === 'company');
  };

  const isInputValue = !!props.selectProps.inputValue;
  const firstLabelClasses = `${styles.label} ${
    !isSecondOptions && styles.label__checked
  }`;
  const secondLabelClasses = `${styles.label} ${
    isSecondOptions && styles.label__checked
  }`;

  return (
    <components.Menu {...props}>
      <div className={styles.wrapper}>
        <div>
          <label className={firstLabelClasses} htmlFor="first">
            <span>{firstLabel}</span>
            <input
              type="radio"
              name="options"
              value="lead"
              id="first"
              checked={!isSecondOptions}
              onChange={handleChange}
              className={styles.input}
            />
          </label>
          {isInputValue && (
            <p className={styles.count}>{first} results found</p>
          )}
        </div>
        <div>
          <label className={secondLabelClasses} htmlFor="second">
            <span>{secondLabel}</span>
            <input
              type="radio"
              name="options"
              value="company"
              id="second"
              checked={isSecondOptions}
              onChange={handleChange}
              className={styles.input}
            />
          </label>
          {isInputValue && (
            <p className={styles.count}>{second} results found</p>
          )}
        </div>
      </div>
      {children}
    </components.Menu>
  );
};

export default Menu;
