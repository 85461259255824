import { useTranslation } from 'react-i18next';

import type { Country } from 'components/CreateCampaign/models';
import IconSVG from 'components/UI/IconSVG';

import { IconsNames, Regions } from 'constants/constants';

import styles from '../index.module.scss';

type CountryItemProps = {
  country: Country;
  selectedCountries: Country[];
  onChange: (newCountries: Country[]) => void;
  regions?: Regions[];
  setRegions: (newRegions: Regions[]) => void;
};

const CountryItem = ({
  country,
  selectedCountries,
  onChange,
  regions,
  setRegions,
}: CountryItemProps) => {
  const { t, i18n } = useTranslation();

  const deleteCountry = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const newCountriesList = selectedCountries.filter(item => {
      if ('key' in item && 'key' in country) {
        return item.key !== country.key;
      }
      if ('iso2' in country && 'iso2' in item) {
        return item.iso2 !== country.iso2;
      }
    });

    const isRegionSelected = newCountriesList.some(
      countryItem => countryItem.region === country.region
    );

    if (!isRegionSelected) {
      const newRegions = regions?.filter(item => item !== country.region);
      if (newRegions) setRegions(newRegions);
    }

    onChange(newCountriesList);
  };

  const countryDisplayNames = new Intl.DisplayNames([i18n.language], {
    type: 'region',
  });
  const name =
    'iso2' in country
      ? countryDisplayNames.of(country.iso2)
      : t(`region.states.${country.key}`);

  return (
    <li className={styles.item}>
      <span>{name}</span>
      <span onClick={deleteCountry} className={styles.deleteButton}>
        <IconSVG name={IconsNames.delete} className={styles.delete} />
      </span>
    </li>
  );
};

export default CountryItem;
