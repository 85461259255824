import React from 'react';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  fieldName: string;
  value?: string;
  isArrow?: boolean;
};

const SavedField = ({ fieldName, value, isArrow = true }: Props) => (
  <p className={styles.saved}>
    <span className={styles.name}>
      {fieldName}
      {!isArrow && ':'}
    </span>
    {isArrow && (
      <IconSVG name={IconsNames.right_arrow} className={styles.arrow} />
    )}
    <span className={styles.value}>{value}</span>
  </p>
);

export default SavedField;
