import { useState, useMemo } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload } from 'react-use';

import type { AxiosError } from 'axios';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { CampaignResponse } from 'types/models';

import PageContainer from 'components/common/PageContainer';
import Steps from 'components/common/Steps';
import LoaderScreen from 'components/common/LoaderScreen';
import ModalWindow from 'components/common/ModalWindow';
import Error from 'components/common/Error';
import NoEnoughCreditsModal from 'components/PaymentsComponents/NoEnoughCreditsModal';

import SaveDraftModal from 'components/CreateCampaign/SaveDraftModal';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';
import useGetReportTemplate from 'components/CreateCampaign/useGetReportTemplate';
import useCallbackPrompt from 'hooks/useCallbackPrompt';
import useGetAssets from 'components/CreateCampaign/useGetAssets';

import { PrivatePaths } from 'constants/routes';
import { StepNavigation, CampaignCreatingStep } from 'constants/constants';

import { checkSteps, getNextStep } from 'helpers/createCampaign';
import getResponseError from 'helpers/getResponseError';

import { validationSchemaCreateCampaign } from 'views/Main/CreateCampaign/validationSchema';

const CreateCampaign = () => {
  const { axios, user } = useAuth();
  const { convertResponse, defaultData, convertRequestBody } =
    useConvertCampaignData();
  const { id } = useParams();
  const { t } = useTranslation();
  const [step, setStep] = useState({
    name: CampaignCreatingStep['first-step'],
    index: 0,
  });
  const [isLeaveActionClicked, setIsLeaveActionClicked] = useState(false);
  const navigate = useNavigate();
  const { closeModal, openModal } = useModal();
  useBeforeUnload(true, t('common.modal.you-have-unsaved-changes'));
  const queryClient = useQueryClient();

  const formMethods = useForm<CreateCampaignValues>({
    defaultValues: { id: Number(id), ...defaultData },
    resolver: useMemo(
      () => yupResolver(validationSchemaCreateCampaign(step.name)),
      [step.name]
    ),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const {
    isLoading,
    isRefetching,
    data: campaign,
  } = useQuery<CampaignResponse & { isJustCreated?: boolean }, AxiosError>(
    ['campaign-create', id?.toString(), user?.company.id],
    async () => {
      try {
        const isJustCreated = queryClient.getQueryData<
          CampaignResponse & { isJustCreated?: boolean }
        >(['campaign-create-disable-requests', id?.toString()])?.isJustCreated;

        const { data } = await axios.get<CampaignResponse>(`/campaigns/${id}/`);

        return !isJustCreated ? data : { ...data, isJustCreated };
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: data => {
        const convertedData = convertResponse(data);
        formMethods.reset(convertedData);

        const stepsData = checkSteps(convertedData);
        const stepDataIndex = stepsData.findIndex(
          item => item.name === convertedData.campaign_details.creating_step
        );
        if (stepDataIndex !== -1) {
          setStep({
            name: stepsData[stepDataIndex].name,
            index: stepDataIndex,
          });
        }
      },
      onError: error => {
        if (error.response?.status === 402) {
          navigate(`/${PrivatePaths.PAYMENTS}`);
          openModal({
            Content: <NoEnoughCreditsModal error={error} />,
          });
          return;
        }
        if (error.response?.status !== 404) {
          openModal({
            Content: (
              <ModalWindow
                title={t('common.error.something-went-wrong')}
                errorMessage={getResponseError(error)}
              />
            ),
          });
        }

        navigate(PrivatePaths.INDEX);
      },
      retry: (count, error) =>
        error.response?.status !== 403 &&
        error.response?.status !== 404 &&
        error.response?.status !== 402,
      refetchOnWindowFocus: false,
      enabled: !!user?.company.id,
    }
  );

  useGetReportTemplate({
    campaign,
    setFormValue: formMethods.setValue,
  });
  useGetAssets({
    campaign,
    setFormValue: formMethods.setValue,
  });

  const steps = checkSteps(formMethods.getValues());

  const goToStep = (
    navigation: CampaignCreatingStep | StepNavigation,
    newData?: Partial<CreateCampaignValues>
  ) => {
    const isPrevNextNavigation =
      navigation === StepNavigation.next || navigation === StepNavigation.prev;
    const updatedCampaign = { ...formMethods.getValues(), ...newData };

    if (isPrevNextNavigation) {
      const nextStep = getNextStep(step, updatedCampaign);
      setStep({ name: nextStep.data.name, index: nextStep.index });
    } else {
      const updatedSteps = checkSteps(updatedCampaign);
      const stepDataIndex = updatedSteps.findIndex(
        item => item.name === navigation
      );

      if (stepDataIndex !== -1) {
        setStep({
          name: updatedSteps[stepDataIndex].name,
          index: stepDataIndex,
        });
      }
    }

    window.scrollTo(0, 0);
  };

  const {
    mutate: saveDraft,
    isLoading: isSaveDraftLoading,
    error,
  } = useMutation<
    { campaign: CampaignResponse; callback: () => void },
    AxiosError,
    {
      campaignData: CreateCampaignValues;
      callback: () => void;
    }
  >(
    async ({ campaignData, callback }) => {
      try {
        const { data } = await axios.put<CampaignResponse>(
          `/campaigns/${campaignData.id}/`,
          convertRequestBody(campaignData, step.name)
        );
        return { campaign: data, callback };
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async data => {
        queryClient.removeQueries([
          'campaign-create-disable-requests',
          data.campaign.id.toString(),
        ]);
        queryClient.removeQueries(['campaign-list', user?.current_group_name]);
        data.callback();
      },
      onError: err => {
        if (err.response?.status === 402) {
          openModal({
            Content: <NoEnoughCreditsModal error={err} />,
          });

          return;
        }
        closeModal();
      },
    }
  );

  useCallbackPrompt(
    !isLeaveActionClicked && !!campaign?.id,
    (cancelNavigation, confirmNavigation) => (
      <SaveDraftModal
        confirmNavigation={confirmNavigation}
        step={step.name}
        formMethods={formMethods}
        saveDraft={saveDraft}
        campaignId={id || campaign?.id || formMethods.getValues().id}
      />
    ),
    () =>
      formMethods.getValues().campaign_details.creating_step ===
      CampaignCreatingStep.completed
  );

  const handleBeforeAction = (callback: () => void) => {
    setIsLeaveActionClicked(true);
    openModal({
      Content: (
        <SaveDraftModal
          confirmNavigation={callback}
          step={step.name}
          formMethods={formMethods}
          saveDraft={saveDraft}
          campaignId={id || campaign?.id || formMethods.getValues().id}
        />
      ),
    });
  };

  const CurrentComponent = steps[step.index]?.Component;
  const isStepComponentVisible = !isLoading && !isRefetching && user?.id;
  const isLoaderScreenVisible = isLoading || isRefetching || isSaveDraftLoading;

  return (
    <PageContainer
      path={['campaign.create-campaign']}
      onBeforeAction={handleBeforeAction}
    >
      <Steps step={step} steps={steps} goToStep={goToStep} />
      {isLoaderScreenVisible && <LoaderScreen />}

      {isStepComponentVisible && (
        <FormProvider {...formMethods}>
          {error && <Error message={t('common.error.fix-errors')} />}
          <CurrentComponent
            goToStep={goToStep}
            steps={steps}
            currentStep={step}
          />
        </FormProvider>
      )}
    </PageContainer>
  );
};

export default CreateCampaign;
