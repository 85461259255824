import { useTranslation } from 'react-i18next';

import type {
  AssetValues,
  CreateCampaignValues,
} from 'components/CreateCampaign/models';
import type { CampaignAssetFileResponse, CampaignResponse } from 'types/models';
import type { AxiosError } from 'axios';
import type { UseQueryResult } from 'react-query';

import Button from 'components/common/Button';
import Loader from 'components/common/LoaderScreen/Loader';
import ModalWrapper from 'components/CreateCampaign/Step3/ModalWrapper';
import { HTMLPreview } from 'components/CreateCampaign/Step3/SavedView';

import useModal from 'contexts/ModalContext';

import styles from './index.module.scss';

const HTML = ({
  getAssetsRequestSettings: { data, isLoading },
  campaignData,
}: {
  getAssetsRequestSettings: UseQueryResult<
    CampaignAssetFileResponse[],
    AxiosError
  >;
  campaignData: CreateCampaignValues | CampaignResponse;
}) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const isAssetsLength = !!data?.length;

  const handleOpenPreview = (
    asset: AssetValues | CampaignAssetFileResponse
  ) => {
    openModal({
      Content: (
        <ModalWrapper>
          <HTMLPreview
            data={asset}
            className={styles.html}
            campaignId={campaignData.id}
            campaignName={campaignData.campaign_details.name}
          />
        </ModalWrapper>
      ),
    });
  };

  return (
    <>
      {isAssetsLength && (
        <ul className={styles.assets}>
          {data?.map(asset => (
            <li className={styles.item} key={asset.id}>
              <h4 className={styles.item__subheading}>{asset.title}</h4>
              <Button
                type="button"
                withArrow
                className={styles.item__button}
                onClick={() => handleOpenPreview(asset)}
              >
                {t('common.button.view-html')}
              </Button>
            </li>
          ))}
        </ul>
      )}
      {isLoading && <Loader className={styles.loader} />}
      {!isLoading && !isAssetsLength && <p>{t('campaign.no-assets')}</p>}
    </>
  );
};

export default HTML;
