import { useTranslation } from 'react-i18next';

import type { ReportedLeadsCount } from 'components/CampaignManage/models';

import styles from '../index.module.scss';

type Props = {
  leadsCount: ReportedLeadsCount;
};

const LeadsRatioVM = ({
  leadsCount: {
    disqualified,
    qualified,
    wait_qualification: waitQualification,
  },
}: Props) => {
  const { t } = useTranslation();

  return (
    <p className={styles.info}>
      <span>{t('lead.leads-ratio')}</span>
      <span>
        <span className={styles.delivered}>
          {disqualified + qualified + waitQualification}
        </span>
        /<span className={styles.qualified}>{qualified}</span>/
        <span className={styles.disqualified}>{disqualified}</span>
      </span>
    </p>
  );
};

export default LeadsRatioVM;
