import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import type { PricingPlanResponse } from 'types/models';
import type { AxiosError } from 'axios';

import Error from 'components/common/Error';

import PricingPlanItem from 'components/CreateProductContent/PricingPlans/PricingPlanItem';
import CreatePricingPlanModal from 'components/CreateProductContent/PricingPlans/CreatePricingPlanModal';
import SectionWrapper from 'components/CreateProductContent/SectionWrapper';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  isFirstStepDone: boolean;
  companySlug?: string;
  productId?: number;
  isProductJustSubmitted: boolean;
};

const PricingPlans: React.FC<Props> = ({
  isFirstStepDone,
  companySlug,
  productId,
  isProductJustSubmitted,
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const { axios } = useAuth();

  const [searchParams] = useSearchParams();
  const productSlug = searchParams.get('productSlug');

  const { data: pricingPlans, error } = useQuery<
    PricingPlanResponse[],
    AxiosError
  >(
    ['get pricing plans', companySlug, productId?.toString()],
    async () => {
      try {
        const { data } = await axios.get<PricingPlanResponse[]>(
          `/companies/${companySlug}/products/${productSlug}/pricing-plans/`
        );
        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled:
        !!productSlug &&
        !!companySlug &&
        !!productId &&
        !isProductJustSubmitted,
      refetchOnWindowFocus: false,
    }
  );

  const openCreateModal = () => {
    openModal({
      Content: (
        <CreatePricingPlanModal
          companySlug={companySlug}
          closeModal={closeModal}
          productId={productId}
        />
      ),
    });
  };

  return (
    <SectionWrapper
      disabled={!isFirstStepDone}
      title={t('manage-products.pricing-plans')}
      iconName={IconsNames.view_carousel}
      buttonProps={{
        children: t('common.button.add'),
        onClick: openCreateModal,
        iconProps: { name: IconsNames.plus },
      }}
    >
      {!!error && <Error message={getResponseError(error)} />}
      {!!pricingPlans?.length && (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t('common.field.name')}</th>
              <th>{t('common.field.price')}</th>
              <th>{t('common.field.period')}</th>
              <th>{t('manage-products.benefits')}</th>
            </tr>
          </thead>
          <tbody>
            {pricingPlans.map(plan => (
              <PricingPlanItem
                key={plan.id}
                planData={plan}
                companySlug={companySlug}
                productSlug={productSlug}
                productId={productId}
              />
            ))}
          </tbody>
        </table>
      )}
    </SectionWrapper>
  );
};

export default PricingPlans;
