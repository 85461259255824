import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import cn from 'classnames';

import IconSVG from 'components/UI/IconSVG';
import ModalWindow from 'components/common/ModalWindow';

import { IconsNames } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  link: string;
};

const DepositLinkModal = ({ link }: Props) => {
  const { t } = useTranslation();
  const [, copyToClipboard] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    copyToClipboard(link);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <ModalWindow primaryTitle={t('payments.copy-deposit-link')}>
      <div className={styles.depositLink}>
        <p>{link}</p>
        <button
          type="button"
          onClick={handleCopyClick}
          className={cn(styles.copy, { [styles.success]: isCopied })}
        >
          {isCopied && <IconSVG name={IconsNames.copy_success} />}
          {t(isCopied ? 'common.button.copied' : 'common.button.copy')}
        </button>
      </div>
    </ModalWindow>
  );
};

export default DepositLinkModal;
