import { useForm, useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';

import type { PricingPlanValues } from 'components/CreateProductContent/models';
import type { AxiosError } from 'axios';
import type { PricingPlanResponse } from 'types/models';

import ModalWindow from 'components/common/ModalWindow';
import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import Error from 'components/common/Error';
import ToggleSwitch from 'components/common/ToggleSwitch';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';

import {
  validationSchemaCreatePricingPlan,
  PRICING_PLAN_BENEFITS_MAX_LENGTH,
} from 'components/CreateProductContent/PricingPlans/CreatePricingPlanModal/validationSchema';

import getResponseError from 'helpers/getResponseError';

import useAuth from 'contexts/AuthContext';

import styles from './index.module.scss';

export enum Periods {
  monthly = 'monthly',
  annual = 'annual',
}

type Props = {
  companySlug?: string;
  closeModal: () => void;
  defaultValues?: Partial<PricingPlanValues>;
  planId?: number;
  isEdit?: boolean;
  productId?: number;
};

const CreatePricingPlanModal: React.FC<Props> = ({
  companySlug,
  closeModal,
  planId,
  isEdit,
  defaultValues,
  productId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { axios } = useAuth();
  const [searchParams] = useSearchParams();
  const productSlug = searchParams.get('productSlug');

  const { register, setValue, control, handleSubmit, formState, clearErrors } =
    useForm<PricingPlanValues>({
      resolver: yupResolver(validationSchemaCreatePricingPlan),
      mode: 'onBlur',
      reValidateMode: 'onChange',
      defaultValues: defaultValues
        ? {
            ...defaultValues,
            isFree: Number(defaultValues?.price) === 0,
            price:
              Number(defaultValues?.price) === 0 ? '' : defaultValues?.price,
          }
        : { period: Periods.monthly, isFree: false },
    });

  const { field: isFreeField } = useController({ control, name: 'isFree' });

  const benefits = useWatch({ name: 'pricing_benefits', control });

  const periodOptions = [
    { label: t('common.field.monthly'), value: Periods.monthly },
    { label: t('common.field.annual'), value: Periods.annual },
  ];

  const {
    mutate: postPricingPlan,
    isLoading,
    error,
  } = useMutation<PricingPlanResponse, AxiosError, PricingPlanValues>(
    async pricingPlanData => {
      try {
        {
          if (planId) {
            const { data } = await axios.patch(
              `/companies/${companySlug}/products/${productSlug}/pricing-plans/${planId}/`,
              pricingPlanData
            );
            return data;
          } else {
            const { data } = await axios.post(
              `/companies/${companySlug}/products/${productSlug}/pricing-plans/`,
              pricingPlanData
            );
            return data;
          }
        }
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: data => {
        queryClient.setQueryData<PricingPlanResponse[]>(
          ['get pricing plans', companySlug, productId?.toString()],
          oldData => {
            const oldPlansArray = oldData ? [...oldData] : [];
            const planIndex = oldPlansArray.findIndex(
              plan => plan.id === data.id
            );
            const insertIndex =
              planIndex !== -1 ? planIndex : oldPlansArray.length;

            oldPlansArray[insertIndex] = data;

            return oldData ? oldPlansArray : [data];
          }
        );
        closeModal();
      },
    }
  );

  const onSubmit = (data: PricingPlanValues) => {
    const formattedData = {
      ...data,
      price: data.isFree === true ? '0' : data.price,
    };

    postPricingPlan(formattedData);
  };

  return (
    <>
      {isLoading && <LoaderScreen />}

      <ModalWindow
        primaryTitle={`${
          defaultValues ? t('common.button.edit') : t('common.button.add')
        } ${t('manage-products.pricing-plan')}`}
        className={styles.modalContainer}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className={styles.content}>
            <InputWrapper
              label="common.field.name"
              isMediumInput
              validationError={formState.errors?.name?.message}
            >
              <input
                type="text"
                placeholder={t('common.field.enter-name')}
                {...register('name')}
              />
            </InputWrapper>
            <InputWrapper
              label="common.field.price"
              isMediumInput
              validationError={formState.errors?.price?.message}
            >
              <input
                placeholder="$0"
                {...register('price')}
                type="number"
                step="0.01"
                min="0"
                lang="en"
                disabled={isFreeField.value}
              />
            </InputWrapper>
            <InputWrapper
              label="auth.free"
              wrapperClasses={styles.toggle}
              isErrorHidden
            >
              <ToggleSwitch
                id="price"
                checked={isFreeField.value}
                onChange={isChecked => {
                  if (isChecked) {
                    clearErrors('price');
                    setValue('price', '0');
                  }
                  isFreeField.onChange(isChecked);
                }}
              />
            </InputWrapper>

            {!isFreeField.value && (
              <>
                <p className={styles.period}>{t('common.field.period')}</p>
                {periodOptions.map(option => (
                  <label
                    className={styles.radio}
                    key={option.value}
                    htmlFor={option.value}
                  >
                    <input
                      {...register('period')}
                      value={option.value}
                      type="radio"
                      id={option.value}
                    />
                    {option.label}
                  </label>
                ))}
              </>
            )}

            <InputWrapper
              isMediumInput
              label="manage-products.benefits"
              validationError={formState.errors?.pricing_benefits?.message}
              wrapperClasses={styles.benefits}
            >
              <TextareaAutoHeight
                methods={register('pricing_benefits')}
                control={control}
                placeholder={t('manage-products.enter-benefits')}
              />
              {!formState.errors?.pricing_benefits?.message && (
                <span className={styles.characters}>
                  {benefits?.length || 0}/{PRICING_PLAN_BENEFITS_MAX_LENGTH}
                  {` ${t('common.field.characters')}`}
                </span>
              )}
            </InputWrapper>
          </div>
          <div className={styles.buttonContainer}>
            <Button type="button" onClick={closeModal} white>
              {t('common.button.cancel')}
            </Button>
            <Button type="submit">
              {isEdit ? t('common.button.edit') : t('common.button.add')}
            </Button>
          </div>
          {error && <Error message={getResponseError(error)} />}
        </form>
      </ModalWindow>
    </>
  );
};

export default CreatePricingPlanModal;
