import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { PricingPlanResponse } from 'types/models';

import PricingCard from 'components/ProductPage/PricingPlans/PricingCard';
import Button from 'components/common/Button';
import SectionWrapper from 'components/CreateProductContent/SectionWrapper';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  pricingPlans?: PricingPlanResponse[];
  isGetADemoAvailable?: boolean;
  onOpenGetDemo: () => void;
  isAssetsExists: boolean;
};

const PRICING_CARDS_DEFAULT_DISPLAY_COUNT = 3;

const PricingPlans: React.FC<Props> = ({
  pricingPlans,
  onOpenGetDemo,
  isGetADemoAvailable,
  isAssetsExists,
}) => {
  const { t } = useTranslation();
  const [isAllVisible, setIsAllVisible] = useState(false);

  const visiblePlans = isAllVisible
    ? pricingPlans
    : pricingPlans?.slice(0, PRICING_CARDS_DEFAULT_DISPLAY_COUNT);
  const isToggleButtonVisible =
    !!pricingPlans?.length &&
    pricingPlans?.length > PRICING_CARDS_DEFAULT_DISPLAY_COUNT;

  return (
    <SectionWrapper
      title={t('manage-products.pricing-plans')}
      iconName={IconsNames.view_carousel}
      withoutBorder
      className={cn(styles.container, { [styles.hasBorder]: isAssetsExists })}
    >
      <ul className={styles.wrapper}>
        {!!visiblePlans?.length &&
          visiblePlans.map(plan => {
            return (
              <PricingCard
                key={plan.id}
                planData={plan}
                isGetADemoAvailable={isGetADemoAvailable}
                onOpenGetDemo={onOpenGetDemo}
              />
            );
          })}
      </ul>
      {isToggleButtonVisible && (
        <Button
          onClick={() => setIsAllVisible(prev => !prev)}
          white
          className={styles.toggle}
        >
          {isAllVisible
            ? t('common.button.collapse')
            : t('common.button.expand')}
        </Button>
      )}
    </SectionWrapper>
  );
};

export default PricingPlans;
