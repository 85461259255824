import PageContainer from 'components/common/PageContainer';

import CampaignDeliveriesContent from 'components/CampaignsListDeliveries';

const CampaignDeliveries = () => {
  return (
    <PageContainer path={['campaign-deliveries.heading']} isWhiteBackground>
      <CampaignDeliveriesContent />
    </PageContainer>
  );
};

export default CampaignDeliveries;
