import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import type {
  UseFieldArrayRemove,
  FieldArrayWithId,
  FieldError,
} from 'react-hook-form';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { AxiosError } from 'axios';

import FileCard from 'components/common/FileCard';
import InputWrapper from 'components/common/InputWrapper';
import ModalWindow from 'components/common/ModalWindow';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';
import { getFileIconFromFileName } from 'helpers/assets';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import styles from '../index.module.scss';

const FIELD_PATH = 'campaign_files.suppression_accounts';

type Props = {
  remove: UseFieldArrayRemove;
  index: number;
  item: FieldArrayWithId<
    CreateCampaignValues,
    'campaign_files.suppression_accounts',
    'id'
  >;
};

const SALFileItem = ({ remove, index, item }: Props) => {
  const { t } = useTranslation();
  const { axios } = useAuth();
  const {
    control,
    watch,
    formState: { errors },
    getValues,
  } = useFormContext<CreateCampaignValues>();
  const { openModal, closeModal } = useModal();

  const suppressionAccountsFiles = watch(FIELD_PATH);
  const fieldData =
    !!suppressionAccountsFiles?.length && suppressionAccountsFiles[index];
  const { headers } = fieldData || {};
  const selectedHeaders = Object.values(fieldData || {}).reduce<string[]>(
    (acc, val) => {
      const isSelectOption =
        val && typeof val === 'object' && !Array.isArray(val) && 'value' in val;
      if (isSelectOption) {
        acc.push(val.value);
      }

      return acc;
    },
    []
  );

  const { isLoading: isDeleteLoading, mutate: deleteFile } = useMutation<
    void,
    AxiosError
  >(
    async () => {
      try {
        closeModal();
        const { id } = getValues(`${FIELD_PATH}.${index}`);
        await axios.delete(
          `/campaigns/${getValues().id}/suppression-account-lists/${id}/`
        );
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: () => remove(index),
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const handleDeleteFile = () => {
    openModal({
      Content: (
        <DeleteConfirmationModal
          instanceName={t(
            'common.field.suppression-account-list'
          ).toLowerCase()}
          itemName={item.sal_file_name}
          onDelete={deleteFile}
        />
      ),
    });
  };

  const mappingOptions = headers
    ?.filter(header => !selectedHeaders.includes(header))
    .map(header => ({
      value: header,
      label: header,
    }));

  const isValidationError =
    errors?.campaign_files?.suppression_accounts?.length &&
    (errors?.campaign_files?.suppression_accounts[
      index
    ] as typeof errors.campaign_files.suppression_accounts[0] &
      FieldError & { type: FieldError });

  const isCompanyNameError =
    isValidationError && isValidationError.company_name;
  const isCompanyDomainError =
    isValidationError && isValidationError.company_domain;

  const Icon = item.sal_file_name
    ? getFileIconFromFileName(item.sal_file_name)
    : null;

  return (
    <FileCard
      index={index}
      remove={handleDeleteFile}
      fileLink={item.sal_file}
      fileName={item.sal_file_name}
      isLoading={isDeleteLoading}
      validationError={
        (isValidationError &&
          'message' in isValidationError &&
          isValidationError.message) ||
        (isValidationError && isValidationError.sal_file?.message) ||
        undefined
      }
      Icon={Icon ? <Icon className={styles.icon} /> : undefined}
    >
      <>
        <InputWrapper label="common.field.first-name">
          <Controller
            name={`${FIELD_PATH}.${index}.first_name`}
            control={control}
            render={({ field }) => (
              <Select
                styles={getDefaultSelectStylesWithError({ isSmall: true })}
                {...field}
                options={mappingOptions}
                placeholder={t('common.field.select')}
                noOptionsMessage={() => t('common.field.no-options')}
                isClearable
              />
            )}
          />
        </InputWrapper>
        <InputWrapper label="common.field.last-name">
          <Controller
            name={`${FIELD_PATH}.${index}.last_name`}
            control={control}
            render={({ field }) => (
              <Select
                styles={getDefaultSelectStylesWithError({ isSmall: true })}
                {...field}
                placeholder={t('common.field.select')}
                noOptionsMessage={() => t('common.field.no-options')}
                options={mappingOptions}
                isClearable
              />
            )}
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.company-name"
          validationError={
            isCompanyNameError && 'value' in isCompanyNameError
              ? isCompanyNameError?.value?.message
              : undefined
          }
        >
          <Controller
            name={`${FIELD_PATH}.${index}.company_name`}
            control={control}
            render={({ field }) => (
              <Select
                styles={getDefaultSelectStylesWithError({
                  error: !!isCompanyNameError,
                  isSmall: true,
                })}
                {...field}
                placeholder={t('common.field.select')}
                noOptionsMessage={() => t('common.field.no-options')}
                options={mappingOptions}
                isClearable
              />
            )}
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.company-domain"
          validationError={
            isCompanyDomainError && 'value' in isCompanyDomainError
              ? isCompanyDomainError.value?.message
              : undefined
          }
        >
          <Controller
            name={`${FIELD_PATH}.${index}.company_domain`}
            control={control}
            render={({ field }) => (
              <Select
                styles={getDefaultSelectStylesWithError({
                  error: !!isCompanyDomainError,
                  isSmall: true,
                })}
                {...field}
                placeholder={t('common.field.select')}
                noOptionsMessage={() => t('common.field.no-options')}
                options={mappingOptions}
                isClearable
              />
            )}
          />
        </InputWrapper>
        <InputWrapper label="common.field.phone-number">
          <Controller
            name={`${FIELD_PATH}.${index}.phone`}
            control={control}
            render={({ field }) => (
              <Select
                styles={getDefaultSelectStylesWithError({ isSmall: true })}
                {...field}
                placeholder={t('common.field.select')}
                noOptionsMessage={() => t('common.field.no-options')}
                options={mappingOptions}
                isClearable
              />
            )}
          />
        </InputWrapper>
        <InputWrapper label="common.field.email-address">
          <Controller
            name={`${FIELD_PATH}.${index}.email`}
            control={control}
            render={({ field }) => (
              <Select
                styles={getDefaultSelectStylesWithError({ isSmall: true })}
                {...field}
                placeholder={t('common.field.select')}
                noOptionsMessage={() => t('common.field.no-options')}
                options={mappingOptions}
                isClearable
              />
            )}
          />
        </InputWrapper>
      </>
    </FileCard>
  );
};

export default SALFileItem;
