import { useTranslation } from 'react-i18next';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const CampaignPausedWarning = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrapper}>
      <IconSVG name={IconsNames.warning_filled} className={styles.icon} />
      <h2 className={styles.heading}>
        {t('campaign.campaign-paused')}
        <span>{t('campaign.campaign-was-paused-by-admin')}</span>
      </h2>
    </section>
  );
};

export default CampaignPausedWarning;
