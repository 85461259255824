import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';

import type { AxiosError } from 'axios';
import type { ProductAssetResponse } from 'types/models';
import type { UseMutateFunction } from 'react-query';

import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';
import EditableAssetItem from 'components/common/EditableAssetItem';
import CreateAssetModal from 'components/common/CreateAssetModal';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';
import { useSaveProductAsset } from 'components/CreateProductContent/useSaveProductAsset';

import getResponseError from 'helpers/getResponseError';

import { ProductAssetsOrdering } from 'constants/assets';

type Props = {
  asset: ProductAssetResponse;
  productSlug?: string | null;
  companySlug?: string;
  index: number;
  productId?: number;
  onMoveAsset: UseMutateFunction<
    void,
    unknown,
    {
      order: number;
      slug: string;
    }
  >;
  isLoading: boolean;
  isDragDropEnabled: boolean;
  assetsOrdering?: ProductAssetsOrdering;
};

const AssetItem = ({
  asset,
  productSlug,
  companySlug,
  index,
  productId,
  onMoveAsset,
  isLoading,
  isDragDropEnabled,
  assetsOrdering,
}: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { axios } = useAuth();
  const queryClient = useQueryClient();
  const ref = useRef<HTMLLIElement>(null);
  const { mutate, isLoading: isCreateAssetLoading } = useSaveProductAsset({
    companySlug,
    productSlug,
    productId,
  });

  const isDragEnabled = !isLoading && isDragDropEnabled;

  const [{ isDragging }, dragRef] = useDrag({
    type: asset.content_type,
    canDrag: isDragEnabled,
    item: { index, ...asset },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const assetListQueryKey = [
    'get-product-assets',
    asset.content_type,
    companySlug,
    productId,
    assetsOrdering,
  ];

  const [, dropRef] = useDrop<ProductAssetResponse & { index: number }>({
    accept: asset.content_type,
    drop: item => {
      if (item.index !== item.order) {
        onMoveAsset({ order: item.index, slug: item.slug });
      }
    },
    canDrop: () => isDragEnabled,
    hover: (item, monitor) => {
      if (!monitor?.getClientOffset()) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (!hoverBoundingRect) return;
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY =
        Number(monitor?.getClientOffset()?.y) - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      queryClient.setQueryData<ProductAssetResponse[]>(
        assetListQueryKey,
        oldData => {
          const newArray = [...(oldData || [])];

          const draggedAsset = oldData?.[dragIndex];
          if (draggedAsset) {
            newArray.splice(dragIndex, 1);
            newArray.splice(hoverIndex, 0, draggedAsset);
          }

          return newArray;
        }
      );
      item.index = hoverIndex;
    },
  });

  const { mutate: deleteAsset, isLoading: isDeleteLoading } = useMutation<
    void,
    AxiosError
  >(
    async () => {
      try {
        await axios.delete(
          `/companies/${companySlug}/products/${productSlug}/assets/${asset.slug}/`
        );
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => {
        queryClient.setQueryData<ProductAssetResponse[]>(
          assetListQueryKey,
          oldData => oldData?.filter(item => item.id !== asset.id) || []
        );
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const handleEditClick = () => {
    if (companySlug && productSlug) {
      openModal({
        Content: (
          <CreateAssetModal
            defaultValues={{
              ...asset,
              content_type: {
                label: t(`common.field.${asset.content_type}`),
                value: asset.content_type,
              },
              landing_image: { renderLink: asset.landing_image },
              type: {
                value: asset.type,
                label: t(`common.field.${asset.type}`),
              },
            }}
            assetId={asset.id}
            contentType={asset.content_type}
            isInCampaignUse={asset.active_campaign_exists}
            onSubmit={mutate}
            isEditWarningVisible={asset.active_campaign_exists}
          />
        ),
      });
    }
  };

  const dragDropRef = dragRef(dropRef(ref));

  return (
    <>
      <EditableAssetItem
        dragDropRef={dragDropRef}
        asset={asset}
        isDragging={isDragging}
        isDragDropEnabled={isDragDropEnabled}
        onDeleteAsset={deleteAsset}
        onEdit={handleEditClick}
        isDeleteDisabled={asset.active_campaign_exists}
      />
      {(isDeleteLoading || isCreateAssetLoading) && <LoaderScreen />}
    </>
  );
};

export default AssetItem;
