import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import axios from 'axios';
import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';

import type { AxiosError } from 'axios';
import type { ScheduleDemoResponse } from 'components/ProductPage/models';

import ModalWindow from 'components/common/ModalWindow';
import Button from 'components/common/Button';
import IconSVG from 'components/UI/IconSVG';

import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';
import { timezones } from 'constants/selectOptions';

import styles from './index.module.scss';

dayjs.extend(dayjsUTC);

export const createAddToCalendarLink = ({
  timeslot,
  timezone,
  leadCompanyName,
  companyName,
}: {
  timeslot: string;
  timezone: string;
  leadCompanyName: string;
  companyName: string;
}) => {
  const eventStartDate = dayjs.utc(timeslot).format('YYYYMMDDTHHmmss');
  const eventEndDate = dayjs
    .utc(timeslot)
    .add(30, 'm')
    .format('YYYYMMDDTHHmmss');

  const timezoneCode = timezones.find(zone => zone.value === timezone)?.code;

  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    `${companyName} <> ${leadCompanyName}`
  )}&dates=${eventStartDate}%2F${eventEndDate}&ctz=${timezoneCode}`;
};

export const useScheduleDemo = ({
  companySlug,
  productSlug,
  companyName,
}: {
  companySlug?: string;
  productSlug?: string;
  companyName: string;
}) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const request = useMutation<
    ScheduleDemoResponse,
    AxiosError,
    ScheduleDemoResponse
  >(
    async getDemoData => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/companies/${companySlug}/products/${productSlug}/schedule-demo/`,
          getDemoData
        );

        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: data => {
        openModal({
          Content: (
            <ModalWindow className={styles.modal}>
              <div className={styles.modal_success}>
                <div className={styles.iconWrapper}>
                  <IconSVG
                    name={IconsNames.success_checkmark}
                    className={styles.icon}
                  />
                </div>
                <h1 className={styles.heading}>
                  {t('common.modal.thanks-for-request')}
                </h1>
                <p className={styles.description}>
                  {t('common.modal.contact-you-soon')}
                </p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={createAddToCalendarLink({
                    timeslot: data.timeslot,
                    timezone: data.client_timezone,
                    leadCompanyName: data.company_name,
                    companyName,
                  })}
                >
                  <Button className={styles.calendar}>
                    {t('common.button.add-to-my-calendar')}
                  </Button>
                </a>
              </div>
            </ModalWindow>
          ),
        });
      },
      onError: error => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(error)}
            />
          ),
        });
      },
    }
  );

  return request;
};
