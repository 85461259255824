import defaultLogo from 'assets/images/product/default-image.svg';

import styles from './index.module.scss';

type Props = {
  name?: string;
  productPhoto?: string;
};

const TopInfo: React.FC<Props> = ({ name, productPhoto }) => {
  return (
    <section className={styles.topInfo}>
      <img
        src={productPhoto || defaultLogo}
        alt=""
        className={styles.productLogo}
      />
      <h1 className={styles.title}>{name}</h1>
    </section>
  );
};

export default TopInfo;
