import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { UseFormReturn } from 'react-hook-form';

import Error from 'components/common/Error';

import styles from './index.module.scss';

type Props = {
  formSettings: UseFormReturn<any, object>;
  name: string;
  label?: string;
  isSmallInput?: boolean;
  isMediumLabel?: boolean;
};

const CountryField = ({
  formSettings,
  name,
  label,
  isSmallInput,
  isMediumLabel,
}: Props) => {
  const { t } = useTranslation();
  const { field: countryField, fieldState: countryFieldState } = useController({
    name: `${name}.country_code`,
    control: formSettings.control,
  });
  const { field: stateField, fieldState: stateFieldState } = useController({
    name: `${name}.state`,
    control: formSettings.control,
  });
  const { field: cityField, fieldState: cityFieldState } = useController({
    name: `${name}.city`,
    control: formSettings.control,
  });

  const countryValue = formSettings.getValues('company.country_code')?.value;
  const isStateVisible =
    countryValue === undefined ||
    countryValue === 'US' ||
    countryValue === 'CA';

  return (
    <>
      <label className={styles.label}>
        <span
          className={cn(styles.label__text, {
            [styles.label__mediumText]: isMediumLabel,
          })}
        >
          {label
            ? t(`common.field.${label}`)
            : t('common.field.company-country')}
        </span>
        <Error
          message={countryFieldState.error?.message || ''}
          className={styles.error}
        />
        <input
          className={cn(styles.input, {
            [styles.inputSmall]: isSmallInput,
          })}
          type="text"
          autoComplete="state-input"
          placeholder={t('auth.enter-company-address')}
          disabled
          {...countryField}
          value={countryField?.value?.label || ''}
        />
      </label>
      {isStateVisible && (
        <label className={styles.label}>
          <span
            className={cn(styles.label__text, {
              [styles.label__mediumText]: isMediumLabel,
            })}
          >
            {t('common.field.company-state')}
          </span>
          <Error
            message={stateFieldState.error?.message || ''}
            className={styles.error}
          />
          <input
            className={cn(styles.input, {
              [styles.inputSmall]: isSmallInput,
            })}
            type="text"
            autoComplete="state-input"
            placeholder={t('auth.enter-company-address')}
            {...stateField}
          />
        </label>
      )}
      <label className={styles.label}>
        <span
          className={cn(styles.label__text, {
            [styles.label__mediumText]: isMediumLabel,
          })}
        >
          {t('common.field.company-city')}
        </span>
        <Error
          message={cityFieldState.error?.message || ''}
          className={styles.error}
        />
        <input
          className={cn(styles.input, {
            [styles.inputSmall]: isSmallInput,
          })}
          type="text"
          autoComplete="city-input"
          placeholder={t('auth.enter-company-address')}
          {...cityField}
        />
      </label>
    </>
  );
};

export default CountryField;
