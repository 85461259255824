import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';

import ModalWindow from 'components/common/ModalWindow';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';

import getResponseError from 'helpers/getResponseError';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

const useDeleteServer = () => {
  const { axios } = useAuth();
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();

  const deleteServer = useMutation<void, AxiosError, number | string>(
    async (id: number | string) => {
      try {
        closeModal();
        await axios.delete(`/mailing-integrations/${id}/`);
      } catch (err) {
        throw err;
      }
    },

    {
      onSuccess: async () => {
        closeModal();
        await queryClient.refetchQueries('servers-list');
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const handleDeleteServer = (id: number | string) => {
    openModal({
      Content: (
        <DeleteConfirmationModal
          instanceName={t('manage-products.feature').toLowerCase()}
          itemName={t('manage-products.feature')}
          onDelete={() => deleteServer.mutate(id)}
        />
      ),
    });
  };

  return { ...deleteServer, mutate: handleDeleteServer };
};

export default useDeleteServer;
