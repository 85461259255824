import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import type { OfferResponse } from 'types/models';
import type { AxiosError } from 'axios';

import LoaderScreen from 'components/common/LoaderScreen';
import CampaignStatus from 'components/common/CampaignStatus';
import CampaignPausedWarning from 'components/common/CampaignPausedWarning';

import CampaignSpecifications from 'components/CampaignManage/CampaignSpecifications';
import DeliveryDetails from 'components/CampaignManage/DeliveryDetails';
import VendorManagerSettings from 'components/CampaignManage/VendorManagerSettings';
import AssetsSection from 'components/CampaignManage/AssetsSection';
import VendorSettings from 'components/CampaignManage/VendorSettings';
import CurrentBalanceDepositNow from 'components/PaymentsComponents/CurrentBalanceDepositNow';

import useAuth from 'contexts/AuthContext';
import useGetAssets from 'hooks/api/useGetAssets';
import { useGetCampaign } from 'hooks/api/useGetCampaign';

import { UserTypes, CampaignStatuses } from 'constants/constants';

import styles from './index.module.scss';

const CampaignManage = () => {
  const { user, axios } = useAuth();
  const { campaignId } = useParams();

  const isVendorManager =
    user && user.current_group_name === UserTypes.vendorManager;

  const { isLoading, data: campaign, isSuccess } = useGetCampaign(campaignId);

  const getOffers = useQuery<OfferResponse[], AxiosError>(
    [
      'vendor-manager-offers',
      campaign?.id.toString(),
      user?.company.id.toString(),
    ],
    async () => {
      try {
        const { data } = await axios.get<OfferResponse[]>(
          `/campaigns/${campaign?.id}/offers/`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      enabled:
        isSuccess &&
        !!user &&
        user.current_group_name === UserTypes.vendorManager,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      staleTime: Infinity,
    }
  );

  const getAssetsRequestSettings = useGetAssets(campaign?.id);

  const isVendorManagerSettings =
    isVendorManager &&
    isSuccess &&
    !isLoading &&
    getAssetsRequestSettings?.data;
  const isVendorSettings =
    !isVendorManager && isSuccess && getAssetsRequestSettings.data;
  const isForcePaused =
    campaign?.campaign_details.status === CampaignStatuses.forcePaused;

  return (
    <div className={styles.wrapper}>
      {campaign?.campaign_details.status && (
        <CampaignStatus
          status={campaign?.campaign_details.status}
          className={styles.status}
        />
      )}
      {isLoading && <LoaderScreen />}
      {campaign && (
        <>
          {isVendorManager && (
            <CurrentBalanceDepositNow campaignBalance={campaign.balance} />
          )}
          {isVendorManager && isForcePaused && <CampaignPausedWarning />}
          <CampaignSpecifications
            campaignDetails={campaign.campaign_details}
            campaignSpecification={campaign.campaign_specification}
            companyName={campaign?.company_name}
            campaignId={campaignId}
          />
          <AssetsSection
            getAssetsRequestSettings={getAssetsRequestSettings}
            campaignId={campaign.id}
            campaignName={campaign.campaign_details.name}
          />
          <DeliveryDetails
            campaignDelivery={campaign.campaign_delivery}
            campaignDetails={campaign.campaign_details}
            campaignId={campaign?.id}
            isAssetsDistributionVisible={
              (getAssetsRequestSettings.data?.length || 0) > 1
            }
          />
          {isVendorManagerSettings &&
            getOffers.isSuccess &&
            !getOffers.isRefetching && (
              <VendorManagerSettings
                campaign={campaign}
                assets={getAssetsRequestSettings.data}
                offers={getOffers.data}
              />
            )}
          {isVendorSettings && (
            <VendorSettings
              campaign={campaign}
              assets={getAssetsRequestSettings.data}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CampaignManage;
