import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { usePopperTooltip } from 'react-popper-tooltip';

import {
  OfferAction,
  OffersFormValues,
  AssignRequestBody,
  OfferFormValues,
} from 'components/CampaignManage/models';

import Button from 'components/common/Button';
import ModalWindow from 'components/common/ModalWindow';

import DeclineModal from 'components/CampaignManage/OfferItem/DeclineModal';

import useModal from 'contexts/ModalContext';

import styles from '../index.module.scss';

type Props = {
  isAssignButtonVisible: boolean;
  isRebidButtonVisible: boolean;
  isAcceptActionsVisible: boolean;
  offerIndex: number;
  campaignName: string;
  campaignId: number;
  handleAccept: (offerId: number) => void;
  handleAssign?: (offer: AssignRequestBody) => void;
  handleRebid: (offer: AssignRequestBody['values']) => void;
  isRebidActive: boolean;
  setIsRebidActive: React.Dispatch<React.SetStateAction<boolean>>;
  disabledAcceptMessage?: string;
  isAssignRebidDisabled?: boolean;
  isVendor?: boolean;
  offerInitialData?: OfferFormValues;
  isDemoCampaign: boolean;
};

const ActionButtons = ({
  isAssignButtonVisible,
  isRebidButtonVisible,
  isAcceptActionsVisible,
  offerIndex,
  campaignId,
  campaignName,
  handleAccept,
  handleRebid,
  handleAssign,
  isRebidActive,
  setIsRebidActive,
  disabledAcceptMessage,
  isAssignRebidDisabled,
  isVendor = false,
  offerInitialData,
  isDemoCampaign,
}: Props) => {
  const { t } = useTranslation();
  const formMethods = useFormContext<OffersFormValues>();
  const { openModal, closeModal } = useModal();
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();
  const offerId = formMethods.watch(`offers.${offerIndex}.value.id`);

  const openConfirmModal = (onConfirm: () => void, action: OfferAction) => {
    openModal({
      Content: (
        <ModalWindow
          primaryTitle={campaignName}
          title={t('offers.confirm-action-question', { action })}
          className={styles.modal}
          positiveButtonProps={{
            children: t('common.button.yes'),
            onClick: () => {
              closeModal();
              onConfirm();
            },
          }}
          negativeButtonProps={{
            children: t('common.button.cancel'),
            onClick: closeModal,
          }}
        />
      ),
    });
  };

  const handleAssignOrRebidSubmit = async () => {
    const isValid = await formMethods.trigger(`offers.${offerIndex}.value`);
    if (!isValid) return;

    const actionId = isRebidActive ? OfferAction.bid : OfferAction.assign;
    const values = formMethods.getValues().offers[offerIndex].value;
    const requestBody = {
      ...values,
      campaign_offer_milestones: values?.campaign_offer_milestones
        ?.filter(
          milestone =>
            Number(
              isVendor
                ? milestone.leads_required_vendor
                : milestone.leads_required
            ) > 0
        )
        .map(item => {
          const isAssetsAdded =
            !!item.leads_required && item.leads_required > 0;

          const vendorOfferAssets =
            isVendor &&
            !values.no_preference &&
            item.campaign_offer_assets?.filter(
              asset => (asset.leads_required_vendor || 0) > 0
            );

          const offerMilestoneAssets = values.no_preference
            ? item.campaign_offer_assets?.map(
                ({ id, asset, asset_title: assetTitle }) => ({
                  id,
                  asset,
                  asset_title: assetTitle,
                })
              )
            : vendorOfferAssets || item.campaign_offer_assets;

          return {
            leads_required: item.leads_required,
            leads_required_vendor: item.leads_required_vendor,
            milestone: item.milestone?.id,
            campaign_offer_assets: isAssetsAdded ? offerMilestoneAssets : [],
          };
        }),
    };

    if (!isRebidActive && handleAssign) {
      return openConfirmModal(
        () => handleAssign({ values: requestBody, index: offerIndex }),
        actionId
      );
    }

    openConfirmModal(() => handleRebid(requestBody), actionId);
  };

  const openDeclineModal = () => {
    if (offerId) {
      openModal({
        Content: (
          <DeclineModal
            campaignName={campaignName}
            offerId={offerId}
            campaignId={campaignId}
            setFormValue={formMethods.setValue}
            offersValues={formMethods.getValues()}
          />
        ),
      });
    }
  };

  const actionHandler = ({
    currentTarget: { id },
  }: React.MouseEvent<HTMLButtonElement>) => {
    switch (id) {
      case OfferAction.rebid:
        setIsRebidActive(prev => {
          if (prev && offerInitialData) {
            formMethods.clearErrors();
            formMethods.setValue(
              `offers.${offerIndex}.value`,
              offerInitialData
            );
            formMethods.setValue(
              `offers.${offerIndex}.value.campaign_offer_milestones`,
              offerInitialData.campaign_offer_milestones
            );
          }
          return !prev;
        });
        return;
      case OfferAction.accept:
        if (offerId) {
          openConfirmModal(() => handleAccept(offerId), id);
        }
        return;
      case OfferAction.decline:
        if (offerId) {
          openDeclineModal();
        }
        return;
      case OfferAction.assign:
        handleAssignOrRebidSubmit();
        return;
      case OfferAction.bid:
        handleAssignOrRebidSubmit();
        return;
    }
  };

  return (
    <div className={styles.actions}>
      {isAssignButtonVisible && (
        <Button
          className={styles.button}
          onClick={actionHandler}
          id={OfferAction.assign}
          type="button"
          disabled={isAssignRebidDisabled}
        >
          {t('common.button.assign')}
        </Button>
      )}

      {isAcceptActionsVisible && (
        <>
          {!isDemoCampaign && (
            <Button
              onClick={actionHandler}
              className={styles.button}
              id={OfferAction.rebid}
              white
              type="button"
            >
              {t('common.button.re-bid')}
            </Button>
          )}
          <div ref={disabledAcceptMessage ? setTriggerRef : undefined}>
            <Button
              className={cn(styles.button, styles.accept)}
              onClick={actionHandler}
              id={OfferAction.accept}
              green
              type="button"
              disabled={!!disabledAcceptMessage}
            >
              {t('common.button.accept')}
            </Button>
          </div>
          {visible && (
            <p
              ref={disabledAcceptMessage ? setTooltipRef : undefined}
              {...getTooltipProps({
                className: cn('tooltip-container', styles.tooltip),
              })}
            >
              {disabledAcceptMessage}
              <div
                {...getArrowProps({
                  className: cn('tooltip-arrow', styles.arrow),
                })}
              />
            </p>
          )}
          <Button
            className={cn(styles.button, styles.decline)}
            onClick={actionHandler}
            id={OfferAction.decline}
            red
            type="button"
          >
            {t('common.button.decline')}
          </Button>
        </>
      )}

      {isRebidButtonVisible && (
        <>
          <Button
            className={styles.button}
            type="button"
            id={OfferAction.bid}
            onClick={actionHandler}
            disabled={isAssignRebidDisabled}
          >
            {t('common.button.bid')}
          </Button>
          <Button
            onClick={actionHandler}
            className={styles.button}
            id={OfferAction.rebid}
            type="button"
            white
          >
            {t('common.button.cancel')}
          </Button>
        </>
      )}
    </div>
  );
};

export default ActionButtons;
