import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import type {
  FormValues,
  ContactsMappingFields,
} from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/models';

import Button from 'components/common/Button';
import Error from 'components/common/Error';
import Loader from 'components/common/LoaderScreen/Loader';

import NavigationButtons from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/NavigationButtons';
import MappingTable from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/UploadFile/MappingTable';

import useUpdateContactsReport from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/useUpdateContactsReport';
import { useUploadContactsReport } from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/useUploadContactsReport';

import { IconsNames } from 'constants/constants';

import getResponseError from 'helpers/getResponseError';

import { ReactComponent as CloudSVG } from 'assets/images/deliveries/cloud.svg';

import styles from './index.module.scss';

type Props = {
  campaignId: number | string;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  stepsCount: number;
  currentStep: number;
};

const UploadFile = ({
  setStep,
  stepsCount,
  currentStep,
  campaignId,
}: Props) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<null | File>(null);
  const { setValue, watch, getValues, reset } = useFormContext<FormValues>();
  const { mutate: updateReport, isLoading: isNextStepLoading } =
    useUpdateContactsReport();

  const {
    isLoading,
    error,
    mutate: sendFile,
    reset: resetMutation,
  } = useUploadContactsReport({ campaignId, getValues, reset });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    onDrop: ([fileItem]) => {
      setValue('report_file_name', fileItem.name);
      sendFile(fileItem);
    },
    disabled: isLoading,
    accept: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
  });

  const handleSubmitMapping = () => {
    const { id, mapping_fields: fields } = getValues();
    if (id) {
      updateReport(
        {
          campaignId,
          fileId: id,
          mapping_fields: Object.entries(fields).reduce((acc, [key, value]) => {
            acc[key as keyof ContactsMappingFields] = value?.value || null;
            return acc;
          }, {} as ContactsMappingFields),
        },
        { onSuccess: () => setStep(prev => prev + 1) }
      );
    }
  };

  const mappingFields = watch('mapping_fields');
  const fileId = watch('id');

  const mappingFieldsValues = Object.values(mappingFields);
  const isNextStepDisabled = mappingFieldsValues.some(field => !field);

  return (
    <section
      className={cn(styles.wrapper, { [styles.disabled]: isNextStepLoading })}
    >
      <h3 className={styles.title}>
        {t('campaign-deliveries.upload-match-contacts')}
      </h3>

      {!fileId && !isLoading && (
        <div className={styles.content} aria-label="contacts report dropzone">
          <div
            {...getRootProps()}
            className={cn(styles.dropzone, { [styles.active]: isDragActive })}
          >
            <CloudSVG
              className={cn(styles.cloud, {
                [styles.grey]: !isDragActive,
                [styles.bounced]: isDragActive,
              })}
            />
            <p className={styles.drop}>{t('common.field.drop-file-here')}</p>
            <span className={styles.or}>{t('common.field.or')}</span>
            <div>
              <Button
                type="button"
                iconProps={{ name: IconsNames.file }}
                className={styles.button}
                isBig
              >
                {t('common.button.browse')}
                <input
                  type="file"
                  {...getInputProps()}
                  aria-label="report file"
                />
              </Button>
              <p className={styles.text}>
                {t('campaign-deliveries.upload-xlsx-file')}
              </p>
            </div>
            {error && <Error message={getResponseError(error)} />}
          </div>
        </div>
      )}

      {!!fileId && (
        <>
          <MappingTable setStep={setStep} campaignId={campaignId} />
          <NavigationButtons
            isNextDisabled={isNextStepDisabled}
            setStep={setStep}
            stepsCount={stepsCount}
            className={styles.navigation}
            currentStep={currentStep}
            onGoToNextStep={handleSubmitMapping}
            isNextStepLoading={isNextStepLoading}
          />
        </>
      )}

      {isLoading && (
        <div className={styles.loading}>
          <Loader size={48} className={styles.spinner} />
          <p className={styles.uploading}>{t('common.modal.uploading')}...</p>
          <p className={styles.name}>{file?.name}</p>
          <Button
            type="button"
            transparent
            onClick={() => {
              setFile(null);
              resetMutation();
            }}
          >
            {t('common.button.cancel')}
          </Button>
        </div>
      )}
    </section>
  );
};

export default UploadFile;
