import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import cn from 'classnames';

import type { AxiosError } from 'axios';
import type { UserGroup } from 'types/models';

import Button from 'components/common/Button';
import Loader from 'components/common/LoaderScreen/Loader';
import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';
import DeleteConfirmationModal from 'components/common/ModalWindow/DeleteConfirmationModal';

type Props = {
  group: UserGroup;
  rowIndex: number;
  editableRowIndex: number | null;
  setEditableRowIndex: (rowIndex: number | null) => void;
  isLoading: boolean;
};

const ActionCell = ({
  group,
  setEditableRowIndex,
  rowIndex,
  editableRowIndex,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const { axios } = useAuth();
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useModal();
  const { reset } = useFormContext();

  const { id, company, name, users } = group;

  const { isLoading: isDeleteLoading, mutate: deleteGroup } = useMutation<
    void,
    AxiosError
  >(
    async () => {
      try {
        closeModal();
        await axios.delete(`/companies/${company}/user-groups/${id}/`);
      } catch (errorDelete) {
        throw errorDelete;
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('groupsList');
        closeModal();
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const openDeleteModal = () => {
    openModal({
      Content: (
        <DeleteConfirmationModal
          instanceName={t('user-groups.user-group')}
          itemName={name}
          onDelete={deleteGroup}
        />
      ),
    });
  };

  const editGroup = () => {
    setEditableRowIndex(rowIndex);
    reset({
      ...group,
      users: users.map(item => ({ value: item, label: item })),
    });
  };

  const isEditMode = editableRowIndex === rowIndex;

  return (
    <div
      className={cn(styles.buttons, {
        [styles.buttons__edit]: isEditMode,
      })}
    >
      {isEditMode ? (
        isLoading ? (
          <Loader />
        ) : (
          <>
            <Button withArrow type="submit" className={styles.save}>
              {t('common.button.save')}
            </Button>
            <Button
              type="button"
              className={styles.save}
              onClick={() => setEditableRowIndex(null)}
            >
              {t('common.button.cancel')}
            </Button>
          </>
        )
      ) : (
        <>
          <Button
            type="button"
            onClick={editGroup}
            iconProps={{ name: IconsNames.edit }}
          >
            {t('common.button.edit')}
          </Button>
          <Button
            type="button"
            onClick={openDeleteModal}
            className={styles.delete}
            iconProps={{ name: IconsNames.trash }}
            red
          >
            {t('common.button.delete')}
          </Button>
        </>
      )}
      {isDeleteLoading && <LoaderScreen />}
    </div>
  );
};

export default ActionCell;
