import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { CampaignResponse } from 'types/models';

import ToggleSwitch from 'components/common/ToggleSwitch';

import styles from './index.module.scss';

type Props = {
  data:
    | CreateCampaignValues['campaign_details']
    | CampaignResponse['campaign_details'];
};

const Details = ({
  data: {
    name,
    total_leads: totalLeads,
    cost_per_lead: CPL,
    contacts_per_company: CPC,
    is_qc_required: QC,
    delivery_emails: deliveryEmails,
    notice,
    owner_user_group: userGroup,
  },
}: Props) => {
  const { t } = useTranslation();

  const isUserGroupVisible = userGroup && typeof userGroup !== 'number';

  return (
    <>
      <h4 className={styles.subheading}>{t('campaign.campaign-details')}</h4>
      <ul className={styles.list}>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.campaign-name')}: </h5>
          <p className={styles.text}>{name || '—'}</p>
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.total-leads')}:</h5>
          <p className={styles.text}>{totalLeads || '—'}</p>
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>
            {t('common.field.leads-management')}:
          </h5>
          <p className={styles.text}>Leadforce</p>
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.cpl')}:</h5>
          <p className={styles.text}>{CPL || '—'}</p>
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.qc')}: </h5>
          <ToggleSwitch id="QC" checked={QC} disabled />
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.cpc')}:</h5>
          <p className={styles.text}>{CPC || '—'}</p>
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>
            {t('common.field.owner-user-group')}:
          </h5>
          <p className={styles.text}>
            {!isUserGroupVisible && '—'}
            {isUserGroupVisible && 'value' in userGroup && userGroup.value.name}
            {isUserGroupVisible &&
              'name' in userGroup &&
              isUserGroupVisible &&
              userGroup?.name}
          </p>
        </li>
        <li className={styles.item}>
          <h5 className={styles.title}>{t('common.field.delivery-emails')}:</h5>
          <ul className={styles.options}>
            {deliveryEmails?.map(email => {
              const value = typeof email === 'string' ? email : email.value;

              return (
                <li className={styles.item} key={value}>
                  {value}
                </li>
              );
            }) || '—'}
          </ul>
        </li>
        <li className={styles.notice}>
          <h5 className={styles.title}>
            {t('common.field.special-instructions')}:
          </h5>
          <p className={cn(styles.text)}>{notice || '—'}</p>
        </li>
      </ul>
    </>
  );
};

export default Details;
