import { components } from 'react-select';
import cn from 'classnames';

import type { OptionProps, GroupBase } from 'react-select';
import type { SelectOption } from 'types/models';

import { CustomDefaultValue } from 'constants/constants';

import styles from './index.module.scss';

const Option = <T extends SelectOption, IsMulti extends boolean>(
  props: OptionProps<T, IsMulti, GroupBase<T>>
) => {
  const isNotCustomOption =
    // @ts-ignore
    !props.data.__isNew__ && props.data.value !== CustomDefaultValue.custom;

  return (
    <components.Option {...props}>
      <div className={styles.container}>
        {isNotCustomOption && (
          <span
            className={cn(styles.checkbox, {
              [styles.checked]: props.isSelected,
            })}
          />
        )}
        <span className={styles.label}>{props.label}</span>
      </div>
    </components.Option>
  );
};

export default Option;
