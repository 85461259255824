import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import cn from 'classnames';

import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import Button from 'components/common/Button';
import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';

import BlockHeader from 'components/CreateCampaign/Step1/BlockHeader';
import DistributionField from 'components/CreateCampaign/Step1/Asset/DistributionField';
import AssetItem from 'components/CreateCampaign/Step1/Asset/AssetItem';
import AddButton from 'components/CreateCampaign/Step1/Asset/AddButton';
import BlockWrapper from 'components/CreateCampaign/Step1/BlockWrapper';

import useGetAssets from 'components/CreateCampaign/useGetAssets';

import { getIsAutomaticallyDistributed } from 'helpers/assets';
import getResponseError from 'helpers/getResponseError';

import { IconsNames } from 'constants/constants';
import { AssetDistributionType } from 'constants/assets';

import styles from './index.module.scss';

const Asset = () => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<CreateCampaignValues>();
  const useFieldArrayReturn = useFieldArray({
    control,
    name: 'campaign_files.assets',
    keyName: 'key',
  });

  const distributionType = watch('campaign_details.asset_distribution');
  const assets = watch('campaign_files.assets');
  const campaignTotalLeads = watch('campaign_details.total_leads');

  const distributionCount = assets?.reduce((acc, field) => {
    acc += field.distribution_value || 0;
    return acc;
  }, 0);
  const distributionDifference =
    distributionType === AssetDistributionType['no-of-leads']
      ? campaignTotalLeads - (distributionCount || 0)
      : 100 - (distributionCount || 0);

  useEffect(() => {
    if (distributionDifference === 0) {
      clearErrors('campaign_details.asset_distribution');
    }
  }, [distributionDifference]);

  const handleResetDistribution = () => {
    const assetsValues = getValues('campaign_files.assets');

    assetsValues?.forEach((_, inx) =>
      setValue(`campaign_files.assets.${inx}.distribution_value`, 0)
    );
  };

  const isDistributionSelectorVisible = assets && assets?.length > 1;
  const isAutomaticallyDistributed = distributionType
    ? getIsAutomaticallyDistributed(distributionType)
    : true;

  const { isLoading, error } = useGetAssets({
    campaign: getValues(),
    enabled: false,
  });

  return (
    <>
      <BlockHeader
        heading={t('common.field.asset')}
        description={t('campaign.select-and-manage-asset-files')}
        iconClassName={styles.icon}
        iconName={IconsNames.file}
        errorMessage={errors.campaign_files?.assets?.message}
        ControlComponent={!isLoading && !error ? <AddButton /> : undefined}
        className={cn({
          [styles.header]: !useFieldArrayReturn.fields.length,
          [styles.noMargin]: isLoading || error,
        })}
      />

      {isDistributionSelectorVisible && <DistributionField />}

      {isLoading && <Loader className={styles.loading} />}

      {error && (
        <Error message={getResponseError(error)} className={styles.error} />
      )}

      {!!useFieldArrayReturn.fields.length && (
        <ul
          className={cn(styles.list, {
            [styles.distributed]:
              !isAutomaticallyDistributed && isDistributionSelectorVisible,
          })}
        >
          {useFieldArrayReturn.fields.map((field, index) => (
            <AssetItem
              key={field.key}
              item={field}
              remove={useFieldArrayReturn.remove}
              index={index}
              distributionDifference={distributionDifference}
            />
          ))}
        </ul>
      )}
      {!isAutomaticallyDistributed && isDistributionSelectorVisible && (
        <BlockWrapper className={styles.reset}>
          <div>
            <Button type="button" white onClick={handleResetDistribution}>
              {t('common.button.reset')}
            </Button>
          </div>
        </BlockWrapper>
      )}
    </>
  );
};

export default Asset;
