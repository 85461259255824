import Select, { components } from 'react-select';
import { useController } from 'react-hook-form';

import type { UseFormReturn, FieldErrors } from 'react-hook-form';
import type { GroupBase, InputProps } from 'react-select';

import { getTimeZones } from '@vvo/tzdb';

import {
  authSelectStyles,
  getDefaultSelectStylesWithError,
} from 'utils/selectStyles';

const timezones = getTimeZones();

type Props = {
  formSettings: UseFormReturn<any, object>;
  name: string;
  message?: FieldErrors;
  label?: string;
  isSmall?: boolean;
};

const Input = ({ ...rest }: InputProps<any, false, GroupBase<any>>) => {
  return (
    <components.Input
      {...rest}
      autoComplete={`password-${rest.selectProps.name}`}
      id={`password-${rest.selectProps.name}`}
    />
  );
};

const TimezoneSelectField = ({ name, formSettings, isSmall }: Props) => {
  const { field } = useController({
    name,
    control: formSettings.control,
  });

  return (
    <>
      <Select
        styles={
          isSmall
            ? getDefaultSelectStylesWithError({
                isSmall: true,
              })
            : authSelectStyles
        }
        options={timezones.map(item => ({
          label: item.name,
          value: item.name,
        }))}
        placeholder=""
        {...field}
        components={{ Input }}
      />
    </>
  );
};

export default TimezoneSelectField;
