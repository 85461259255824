import { useFormContext, useWatch } from 'react-hook-form';
import cn from 'classnames';

import type { AssetValues } from 'components/CreateCampaign/models';

import IconSVG from 'components/UI/IconSVG';
import Loader from 'components/common/LoaderScreen/Loader';

import useFetchProductsOptions from 'hooks/api/useFetchProductsOptions';

import { IconsNames, StepNavigation } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  goToStep: (step: StepNavigation) => void;
};

const Select = ({ goToStep }: Props) => {
  const { setValue, reset, getValues, control } = useFormContext<AssetValues>();
  const { data: productsList, isLoading } = useFetchProductsOptions();
  const selectedProduct = useWatch({ control, name: 'product' });

  return (
    <ul className={styles.list}>
      {isLoading && <Loader className={styles.loader} />}
      {productsList?.map(product => (
        <li
          className={cn(styles.item, {
            [styles.selected]: selectedProduct?.value === product.value,
          })}
          key={product.value}
          onClick={() => {
            setValue('product', product);
            const values = getValues();

            reset({
              ...values,
              title: product.label,
              description: product.description,
              product,
            });

            goToStep(StepNavigation.next);
          }}
        >
          <IconSVG name={IconsNames.product} />
          {product.label}
          <IconSVG name={IconsNames.arrow} className={styles.arrow} />
        </li>
      ))}
    </ul>
  );
};

export default Select;
