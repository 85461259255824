import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';
import cn from 'classnames';

import type {
  FormValues,
  VendorAvailableMilestone,
} from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/models';

import Button from 'components/common/Button';

import { getAssetIconByAssetType, getAssetLink } from 'helpers/assets';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  asset: VendorAvailableMilestone['campaign_offer_assets'][0];
  milestone: VendorAvailableMilestone;
};

const AssetFileItem = ({ asset, milestone }: Props) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext<FormValues>();
  const { field } = useController<FormValues>({
    name: 'asset',
    control,
  });
  const isDisabled = milestone.no_preference
    ? !milestone.is_available
    : asset.leads_required <= asset.leads_qualified;

  const handleInputChange = () => {
    if (isDisabled) return;
    field.onChange(asset.asset);
    setValue('milestone', milestone.id);
  };

  const Icon =
    asset.asset_type &&
    getAssetIconByAssetType({
      assetType: asset.asset_type,
      fileLink: asset.asset_attached_file,
      className: styles.link,
    });
  const assetLink = getAssetLink({
    ...asset,
    attached_file_link: asset.asset_attached_file_link,
    attached_file: asset.asset_attached_file,
  });

  const selectedAsset = watch('asset');
  const selectedMilestone = watch('milestone');
  const isChecked =
    selectedAsset === asset.asset && selectedMilestone === milestone.id;
  const leadsRequired = milestone.no_preference
    ? milestone.leads_required
    : asset.leads_required;
  const leadsRemaining = milestone.no_preference
    ? milestone.leads_required - asset.leads_qualified
    : asset.leads_required - asset.leads_qualified;

  return (
    <li>
      <label
        className={cn(styles.asset, {
          [styles.checked]: isChecked,
          [styles.disabled]: isDisabled,
        })}
      >
        <input
          type="radio"
          {...field}
          onChange={handleInputChange}
          checked={isChecked}
          value={asset.id}
        />
        <p className={styles.title}>
          {Icon && <Icon className={styles.file} />}
          {asset.asset_title}
          <a
            href={assetLink}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.open}
          >
            <Button
              type="button"
              white
              iconProps={{
                name: IconsNames.arrow_open,
                isRightPosition: true,
                className: styles.arrow,
              }}
            >
              {t('common.button.open-in-a-new-tab')}
            </Button>
          </a>
        </p>
        <span className={styles.count}>{asset.leads_qualified}</span>
        <span className={styles.count}>{leadsRemaining}</span>
        <span className={styles.count}>{leadsRequired}</span>
      </label>
    </li>
  );
};

export default AssetFileItem;
