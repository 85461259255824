import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import ModalWindow from 'components/common/ModalWindow';
import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';

import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import { validationSchemaChangePassword } from 'utils/validations';

import styles from './index.module.scss';

type FormValues = {
  current_password: string;
  new_password: string;
  re_new_password: string;
};

const ChangePasswordModal = () => {
  const { axios } = useAuth();
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const [isCurrentPasswordHidden, setIsCurrentPasswordHidden] = useState(true);
  const [isNewPasswordHidden, setIsNewPasswordHidden] = useState(true);
  const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true);

  const { mutate, error } = useMutation<void, AxiosError, FormValues>(
    async (userData: FormValues) => {
      try {
        await axios.post('/accounts/password/change/', userData);
      } catch (err) {
        throw err;
      }
    }
  );

  const { register, formState, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(validationSchemaChangePassword),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = (data: FormValues) => {
    mutate(data);
  };

  return (
    <ModalWindow
      primaryTitle={t('profile.change-password')}
      className={styles.modal}
    >
      <form
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
        aria-label="form change password"
      >
        <InputWrapper
          label="common.field.current-password"
          setIsHiddenInputContent={setIsCurrentPasswordHidden}
          validationError={formState.errors.current_password?.message}
          submitError={error ? getResponseError(error) : undefined}
          wrapperClasses={styles.inputWrapper}
          isMediumInput
        >
          <input
            type={isCurrentPasswordHidden ? 'password' : 'text'}
            {...register('current_password')}
            aria-label="current password"
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.new-password"
          setIsHiddenInputContent={setIsNewPasswordHidden}
          validationError={formState.errors.new_password?.message}
          submitError={error ? getResponseError(error) : undefined}
          wrapperClasses={styles.inputWrapper}
          isMediumInput
        >
          <input
            type={isNewPasswordHidden ? 'password' : 'text'}
            {...register('new_password')}
            aria-label="new password"
          />
        </InputWrapper>
        <InputWrapper
          label="common.field.confirm-password"
          setIsHiddenInputContent={setIsConfirmPasswordHidden}
          validationError={formState.errors.re_new_password?.message}
          submitError={error ? getResponseError(error) : undefined}
          wrapperClasses={styles.inputWrapper}
          isMediumInput
        >
          <input
            type={isConfirmPasswordHidden ? 'password' : 'text'}
            {...register('re_new_password')}
            aria-label="re new password"
          />
        </InputWrapper>
        <div className={styles.buttonsWrapper}>
          <Button onClick={closeModal} white>
            {t('common.button.cancel')}
          </Button>
          <Button type="submit">{t('common.button.change')}</Button>
        </div>
      </form>
    </ModalWindow>
  );
};

export default ChangePasswordModal;
