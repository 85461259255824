import cn from 'classnames';

import Error from 'components/common/Error';
import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  heading: string;
  description: string;
  iconName: IconsNames;
  iconClassName: string;
  errorMessage?: string;
  ControlComponent?: React.ReactNode;
  className?: string;
};

const BlockHeader: React.FC<Props> = ({
  heading,
  description,
  iconName,
  iconClassName,
  errorMessage,
  children,
  ControlComponent,
  className,
}) => (
  <>
    <div className={cn(styles.wrapper, className)}>
      <span className={cn(styles.icon, iconClassName)}>
        <IconSVG name={iconName} />
      </span>
      <h2 className={styles.heading}>
        {heading}
        <span className={styles.description}>
          {description}
          {errorMessage && (
            <Error message={errorMessage} className={styles.error} />
          )}
        </span>
      </h2>
      {ControlComponent}
    </div>
    <div className={styles.children}>{children}</div>
  </>
);

export default BlockHeader;
