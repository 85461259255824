export enum PublicPaths {
  INDEX = '/',
  LOG_IN = 'log-in',
  SIGN_UP = 'sign-up',
  RESET_PASSWORD = 'reset-password',
  TWO_FA = 'two-fa',
  TWO_FA_PHONE = 'phone',
  TWO_FA_EMAIL = 'email',
  TWO_FA_APP = 'app',
  NEW_PASSWORD = 'password/reset/confirm/:uid/:token',
  ACTIVATE = 'activation/:uid/:token',
  INVITE = 'register-invited/:email/:token',
}

export enum PrivatePaths {
  INDEX = '/',
  PROFILE = 'profile',
  INVITES = 'invites',
  PAYMENTS = 'payments',
  SETTINGS = 'settings',
  CAMPAIGN = 'campaign',
  CREATE = 'create',
  USER_GROUPS = 'groups',
  ADD = 'add',
  CHANGE_PASSWORD = 'change-password',
  EDIT = 'edit',
  PRODUCTS = 'products',
  REQUESTS = 'requests',
  INTEGRATIONS = 'integrations',
  CAMPAIGN_DELIVERIES = 'campaign-deliveries',
  PAYOUTS = 'payouts',
  UPLOADS_HISTORY = 'uploads-history',
  GENERAL = 'general',
  CONTACTS = 'contacts',
  COMPANY = 'company',
  SECURITY = 'security',
  CRM_CALLBACK = 'crm-callback',
  CONNECT_HUBSPOT = 'hubspot-oauth',
}

export enum CommonPaths {
  SHARE = 'share',
  CONTACT_SHARE_ID = ':contactShareId',
  HEALTH_CHECK = 'healthCheck',
  COMPANY_SLUG = ':companySlug',
  PRODUCT_SLUG = ':productSlug',
  DOWNLOAD_ASSET = ':assetSlug',
  DOCUMENTS = 'documents',
  TERMS = 'terms',
  CONTRACT = 'contract',
  PROCESSING = 'processing',
  PRIVACY_NOTICE = 'privacy-notice',
}
