import { components } from 'react-select';

import type { SingleValueProps, GroupBase } from 'react-select';

import IconSVG, { IconProps } from 'components/UI/IconSVG';

import styles from './index.module.scss';

const IconSingleValue = <
  T extends { value: unknown; label: unknown; iconProps: IconProps },
  IsMulti extends boolean
>({
  children,
  ...props
}: SingleValueProps<T, IsMulti, GroupBase<T>>) => {
  return (
    <components.SingleValue {...props} className={styles.container}>
      <IconSVG {...props.data.iconProps} />
      {children}
    </components.SingleValue>
  );
};

export default IconSingleValue;
