import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import IconSVG from 'components/UI/IconSVG';
import Button from 'components/common/Button';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  path: string[];
  color?: string;
  backLinkPath?: string;
  rightComponent?: React.ReactNode | null | ((props: unknown) => JSX.Element);
};

const PageTitle = ({ path, color, backLinkPath, rightComponent }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrappRow}>
      <h2 className={styles.title}>
        {backLinkPath && (
          <Link to={backLinkPath}>
            <Button
              className={styles.back}
              type="button"
              white
              iconProps={{ name: IconsNames.arrow }}
            />
          </Link>
        )}
        {path.map((element, index) => {
          const isLastIndex = index === path.length - 1;

          return (
            <div key={element}>
              <span style={{ color: color || undefined }}>{t(element)}</span>

              {!isLastIndex && (
                <IconSVG name={IconsNames.arrow} className={styles.arrow} />
              )}
            </div>
          );
        })}
      </h2>
      {rightComponent && <div>{rightComponent}</div>}
    </div>
  );
};

export default PageTitle;
