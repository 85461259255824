import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useController } from 'react-hook-form';

import type { UseFormSetValue, UseFormWatch, Control } from 'react-hook-form';
import {
  PaymentMethodName,
  TermValues,
  PaymentOptions,
  DepositFormValues,
} from 'components/PaymentsComponents/models';

import InputWrapper from 'components/common/InputWrapper';
import IconOption from 'components/UI/ReactSelect/IconOption';
import IconSingleValue from 'components/UI/ReactSelect/IconSingleValue';

import { IconsNames } from 'constants/constants';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import styles from './index.module.scss';

type Props = {
  setValue: UseFormSetValue<DepositFormValues>;
  watch: UseFormWatch<DepositFormValues>;
  control: Control<DepositFormValues, unknown>;
  methodError?: string;
  daysCountError?: string;
};

const SelectPaymentMethod = ({
  watch,
  control,
  setValue,
  methodError,
  daysCountError,
}: Props) => {
  const { t } = useTranslation();
  const { field: methodField } = useController({
    name: 'method',
    control,
  });
  const { field: daysField } = useController({ name: 'term', control });
  const { field: optionField } = useController({ name: 'option', control });

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('method', null);
    if (e.currentTarget.value === PaymentOptions.now) {
      setValue('term', null);
    }
    optionField.onChange(e.currentTarget.value);
  };

  const paymentOption = watch('option');
  const isPayLater = paymentOption === PaymentOptions.later;

  const daysOptions = Object.values(TermValues)
    .filter(item => typeof item === 'number')
    .map(item => ({
      value: item as TermValues,
      label: item,
    }));

  return (
    <>
      <p className={styles.title}>{t('payments.payment-options')}</p>
      <label className={styles.term} htmlFor={PaymentOptions.now}>
        <input
          type="radio"
          {...optionField}
          value={PaymentOptions.now}
          id={PaymentOptions.now}
          checked={!isPayLater}
          onChange={handleOptionChange}
        />
        <span>{t(`payments.${PaymentOptions.now}`)}</span>
      </label>
      <label className={styles.term} htmlFor={PaymentOptions.later}>
        <input
          type="radio"
          {...optionField}
          value={PaymentOptions.later}
          id={PaymentOptions.later}
          checked={isPayLater}
          onChange={handleOptionChange}
        />
        <span>{t(`payments.${PaymentOptions.later}`)}</span>
      </label>
      <div className={styles.selectors}>
        <InputWrapper label="common.field.method" validationError={methodError}>
          <Select
            isSearchable={false}
            styles={getDefaultSelectStylesWithError({
              isSmall: true,
              error: !!methodError,
            })}
            aria-label="select method"
            placeholder={t('common.field.select-method')}
            {...methodField}
            options={[
              {
                value: PaymentMethodName.creditCard,
                label: t(
                  `payments.method.${PaymentMethodName.creditCard}`
                ) as string,
                iconProps: {
                  name: IconsNames.card,
                  className: styles.icon,
                },
              },
              {
                value: PaymentMethodName.bank,
                label: t(`payments.method.${PaymentMethodName.bank}`) as string,
                iconProps: {
                  name: IconsNames.transfer,
                  className: styles.icon,
                },
              },
            ]}
            components={{
              Option: IconOption,
              SingleValue: IconSingleValue,
            }}
          />
        </InputWrapper>
        {paymentOption === PaymentOptions.later && (
          <InputWrapper
            label="common.field.days-amount"
            wrapperClasses={styles.days}
            validationError={daysCountError}
          >
            <Select
              styles={getDefaultSelectStylesWithError({
                isSmall: true,
                error: !!daysCountError,
              })}
              isSearchable={false}
              placeholder={t('common.field.select-amount')}
              options={daysOptions}
              aria-label="days amount"
              {...daysField}
              menuPosition="fixed"
            />
          </InputWrapper>
        )}
      </div>
    </>
  );
};

export default SelectPaymentMethod;
