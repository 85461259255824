import { useTranslation } from 'react-i18next';
import { useFormContext, useController } from 'react-hook-form';

import type { Path } from 'react-hook-form';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import InputWrapper from 'components/common/InputWrapper';

import {
  createComplianceTemplate,
  ScriptCompliance,
} from 'constants/scriptTemplates';

import styles from './index.module.scss';

type Props = {
  isPreviewMode?: boolean;
  isCASLCompliant?: boolean;
  isGDPRCompliant?: boolean;
};

const SelectCompliance = ({
  isPreviewMode,
  isCASLCompliant,
  isGDPRCompliant,
}: Props) => {
  const { t } = useTranslation();
  const formSettings = useFormContext<CreateCampaignValues>();
  const { setValue, control } = formSettings || {};
  const { field: fieldCASL } =
    control && !isPreviewMode
      ? useController({
          control,
          name: 'script_builder.is_casl_compliant',
        })
      : { field: null };
  const { field: fieldGDPR } = control
    ? useController({
        control,
        name: 'script_builder.is_gdpr_compliant',
      })
    : { field: null };

  const handleChangeCompliance = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { name, id, checked, value },
    } = e;

    if (value === ScriptCompliance.casl && checked) {
      setValue('script_builder.compliance_gdpr_block', null);
      setValue('script_builder.is_gdpr_compliant', false);
    }
    if (value === ScriptCompliance.gdpr && checked) {
      setValue('script_builder.compliance_casl_block', null);
      setValue('script_builder.is_casl_compliant', false);
    }

    setValue(
      id as Path<CreateCampaignValues>,
      checked ? createComplianceTemplate(t(`script.${value}`), t) : null
    );
    setValue(name as Path<CreateCampaignValues>, checked);
  };

  return (
    <div className={styles.compliance}>
      <InputWrapper
        label="script.casl"
        isErrorHidden
        wrapperClasses={styles.checkbox}
      >
        <input
          type="checkbox"
          id="script_builder.compliance_casl_block"
          {...(fieldCASL || { checked: isCASLCompliant })}
          onChange={handleChangeCompliance}
          disabled={isPreviewMode}
          value={ScriptCompliance.casl}
          checked={isPreviewMode ? isCASLCompliant : fieldCASL?.value}
        />
      </InputWrapper>
      <InputWrapper
        label="script.gdpr"
        isErrorHidden
        wrapperClasses={styles.checkbox}
      >
        <input
          type="checkbox"
          id="script_builder.compliance_gdpr_block"
          {...(fieldGDPR || {
            checked: isGDPRCompliant,
          })}
          checked={isPreviewMode ? isGDPRCompliant : fieldGDPR?.value}
          onChange={handleChangeCompliance}
          disabled={isPreviewMode}
          value={ScriptCompliance.gdpr}
        />
      </InputWrapper>
    </div>
  );
};

export default SelectCompliance;
