import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { AxiosError } from 'axios';
import { OfferResponse } from 'types/models';

import Button from 'components/common/Button';

import ModalWindow from 'components/common/ModalWindow';
import HistoryModal from 'components/CampaignManage/OfferItem/History/HistoryModal';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import { MilestoneType } from 'constants/constants';
import { OfferAction } from 'components/CampaignManage/models';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

type Props = {
  offerId: number;
  campaignId: number;
  milestoneDeadlineType: MilestoneType;
  isAccepted?: boolean;
  isAwaitingApprove?: boolean;
  updateAssignedOfferAfterSubmit?: (offer: OfferResponse) => void;
};

const History = ({
  offerId,
  campaignId,
  milestoneDeadlineType,
  isAccepted,
  isAwaitingApprove,
  updateAssignedOfferAfterSubmit,
}: Props) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const { axios } = useAuth();

  const { mutate: cancelOffer, isLoading: isLoadingOnCancel } = useMutation<
    OfferResponse,
    AxiosError
  >(
    async () => {
      try {
        const { data } = await axios.post<OfferResponse>(
          `/campaigns/${campaignId}/offers/${offerId}/cancel/`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: data => {
        updateAssignedOfferAfterSubmit?.(data);
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const handleOpenHistoryModal = () => {
    openModal({
      Content: (
        <HistoryModal
          campaignId={campaignId}
          offerId={offerId}
          milestoneDeadlineType={milestoneDeadlineType}
        />
      ),
    });
  };

  const handleOpenCancelModal = () => {
    openModal({
      Content: (
        <ModalWindow
          title={t('offers.confirm-action-question', {
            action: OfferAction.cancel,
          })}
          className={styles.modal}
          positiveButtonProps={{
            children: t('common.button.yes'),
            onClick: () => {
              cancelOffer();
              closeModal();
            },
          }}
          negativeButtonProps={{
            children: t('common.button.no'),
            onClick: closeModal,
          }}
        ></ModalWindow>
      ),
    });
  };

  return (
    <div className={styles.buttonsWrapper}>
      {(isAccepted || isAwaitingApprove) && (
        <Button
          className={cn(styles.button, styles.cancel)}
          red
          type="button"
          onClick={handleOpenCancelModal}
          isLoading={isLoadingOnCancel}
        >
          {t('common.button.cancel')}
        </Button>
      )}
      <Button
        className={styles.button}
        type="button"
        onClick={handleOpenHistoryModal}
      >
        {t('common.button.view-more')}
      </Button>
    </div>
  );
};

export default History;
