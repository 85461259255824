import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import Button from 'components/common/Button';
import ModalWindow from 'components/common/ModalWindow';

import { downloadFile } from 'helpers/downloadFile';

import styles from './index.module.scss';

type Props = {
  assetId: number;
};

const ScriptItem = ({ assetId }: Props) => {
  const { t } = useTranslation();
  const { campaignId } = useParams();
  const { openModal } = useModal();
  const { axios } = useAuth();

  const { isLoading, refetch, isRefetching } = useQuery<void, AxiosError>(
    ['script-file-download', campaignId, assetId],
    async () => {
      try {
        const { data, headers } = await axios.get<string>(
          `/campaigns/${campaignId}/assets/${assetId}/script-file-download/`,
          {
            responseType: 'arraybuffer',
          }
        );

        downloadFile(
          data,
          headers['content-type'],
          headers['content-disposition']
        );
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: false,
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const handleCopyScript = () => {
    refetch();
  };

  return (
    <Button
      onClick={handleCopyScript}
      isLoading={isLoading || isRefetching}
      className={styles.button}
    >
      {t('campaign.script-copy')}
    </Button>
  );
};

export default ScriptItem;
