import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptySVG } from 'assets/images/deliveries/empty.svg';

import styles from './index.module.scss';

type Props = {
  text: string;
};

const EmptyData = ({ text }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.empty}>
      <EmptySVG className={styles.image} aria-label="empty data" />
      <h3 className={styles.title}>
        {t('campaign-deliveries.seems-empty-here')}
      </h3>
      <p className={styles.description}>{text}</p>
    </div>
  );
};

export default EmptyData;
