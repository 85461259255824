import dayjs from 'dayjs';

import CreateCampaignForm from 'components/CreateCampaign/Step1';
import LeadMapping from 'components/CreateCampaign/Step2';
import CreateHTML from 'components/CreateCampaign/Step3';
import Review from 'components/CreateCampaign/Step4';
import CampaignSettings from 'components/CreateCampaign/Step4/CampaignSettings';
import ReportMappingView from 'components/CreateCampaign/Step4/ReportMapping';
import CreateHTMLView from 'components/CreateCampaign/Step4/HTML';

import type { FieldError, Merge, FieldErrorsImpl } from 'react-hook-form';
import type { TFunction } from 'i18next';
import type {
  CreateCampaignValues,
  TALFile,
  SALFile,
} from 'components/CreateCampaign/models';
import type {
  SALFileResponse,
  TALFileResponse,
  ValidationError,
} from 'types/models';

import {
  CampaignCreatingStep,
  MILESTONE_END_DATE_DIFFERENCE,
  DEFAULT_POSSIBLE_YEARS,
} from 'constants/constants';

export const checkSteps = ({
  campaign_files: files,
}: Partial<CreateCampaignValues>) => {
  const isMappingStepDisabled = !files?.report_template;
  const isHTMLStepDisabled = !files?.assets?.length;
  const stepsData = [
    {
      title: 'campaign.campaign-settings',
      Component: CreateCampaignForm,
      View: CampaignSettings,
      disabled: false,
      name: CampaignCreatingStep['first-step'],
    },
    {
      title: 'campaign.report-mapping',
      Component: LeadMapping,
      View: ReportMappingView,
      disabled: isMappingStepDisabled,
      name: CampaignCreatingStep['second-step'],
    },
    {
      title: 'campaign.html',
      Component: CreateHTML,
      View: CreateHTMLView,
      disabled: isHTMLStepDisabled,
      name: CampaignCreatingStep['third-step'],
    },
    {
      title: 'campaign.review',
      Component: Review,
      disabled: false,
      name: CampaignCreatingStep['fourth-step'],
    },
  ];

  return stepsData;
};

export const getNextStep = (
  currentStep: { name: CampaignCreatingStep; index: number },
  campaignData: CreateCampaignValues
) => {
  const updatedSteps = checkSteps(campaignData);

  let nextStepIndex = currentStep.index + 1;
  let nextStep = updatedSteps[nextStepIndex];

  while (nextStep.disabled) {
    nextStepIndex += 1;
    nextStep = updatedSteps[nextStepIndex];
  }
  return { data: nextStep, index: nextStepIndex };
};

export const parseSALFile = (
  salFile: SALFileResponse
): Omit<SALFile, 'id'> & { id: number } => {
  const {
    company_domain: domain,
    company_name: companyName,
    email,
    last_name: lastName,
    phone,
    first_name: firstName,
  } = salFile;

  return {
    ...salFile,
    company_domain:
      typeof domain === 'string'
        ? {
            value: domain,
            label: domain,
          }
        : domain,
    company_name:
      typeof companyName === 'string'
        ? {
            value: companyName,
            label: companyName,
          }
        : companyName,
    email:
      typeof email === 'string'
        ? {
            value: email,
            label: email,
          }
        : null,
    last_name:
      typeof lastName === 'string'
        ? {
            value: lastName,
            label: lastName,
          }
        : lastName,
    phone:
      typeof phone === 'string'
        ? {
            value: phone,
            label: phone,
          }
        : phone,
    first_name:
      typeof firstName === 'string'
        ? {
            value: firstName,
            label: firstName,
          }
        : firstName,
  };
};

export const parseTALFileResponse = (
  talFile: TALFileResponse
): Omit<TALFile, 'id'> & { id: number } => {
  const { company_name: companyName, company_domain: domain, name } = talFile;

  return {
    ...talFile,
    company_domain:
      typeof domain === 'string'
        ? {
            value: domain,
            label: domain,
          }
        : domain,
    company_name:
      typeof companyName === 'string'
        ? {
            value: companyName,
            label: companyName,
          }
        : companyName,
    name: name,
  };
};

export const getMilestoneDeliveryDateSettings = ({
  t,
  campaignStartDate,
  campaignEndDate,
  isError,
}: {
  t: TFunction;
  campaignStartDate: string | null;
  campaignEndDate: string | null;
  isError?: { delivery_date?: FieldError | undefined };
}) => {
  const fromMonth = campaignStartDate
    ? new Date(campaignStartDate)
    : new Date();

  const isSelectedDateLastDayOfMonth =
    dayjs(fromMonth).endOf('month').daysInMonth() ===
    dayjs(fromMonth).get('date');

  const nextDay = fromMonth;
  nextDay.setDate(nextDay.getDate() + 1);
  const fromMonthMonthly = isSelectedDateLastDayOfMonth ? nextDay : fromMonth;
  const toMonth = campaignEndDate
    ? dayjs(campaignEndDate).add(MILESTONE_END_DATE_DIFFERENCE, 'day').toDate()
    : dayjs(new Date())
        .add(MILESTONE_END_DATE_DIFFERENCE, 'day')
        .add(DEFAULT_POSSIBLE_YEARS, 'years')
        .toDate();

  const deliveryDateError = isError?.delivery_date?.message as
    | string
    | ValidationError;
  const translateMonthlyError =
    typeof deliveryDateError === 'string' &&
    t(isError?.delivery_date?.message || '');
  const translateMonthlyObjectError =
    deliveryDateError &&
    typeof deliveryDateError !== 'string' &&
    t(deliveryDateError?.key, deliveryDateError?.values);

  return {
    fromMonth,
    fromMonthMonthly,
    toMonth,
    monthlyError: translateMonthlyError || translateMonthlyObjectError || '',
  };
};

export const getDeliveryErrorsArray = (
  deliveryErrors:
    | Merge<
        FieldError,
        FieldErrorsImpl<CreateCampaignValues['campaign_delivery']>
      >
    | undefined
) => {
  const errorsEntries = deliveryErrors && Object.entries(deliveryErrors);

  const errorsData = errorsEntries?.reduce<
    ({ message: string | undefined; key: string } | undefined)[]
  >((acc, [key, value]) => {
    const isFieldErrorString =
      value && typeof value !== 'string' && 'message' in value && value.message;
    if (isFieldErrorString) {
      acc.push({ message: value.message?.toString(), key });
      return acc;
    }
    const isCustomError =
      value && typeof value !== 'string' && 'value' in value;
    if (isCustomError) {
      acc.push({ message: value.value?.message, key });
      return acc;
    }
    if (Array.isArray(value)) {
      acc.push({ message: value[0].delivery_date?.message, key });
      return acc;
    }
    return acc;
  }, []);

  return errorsData;
};
