import { useInfiniteQuery } from 'react-query';
import { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { PaginationReturn, VendorCampaignResponse } from 'types/models';
import type { AxiosError } from 'axios';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import Table from 'components/common/Table';
import CampaignStatus from 'components/common/CampaignStatus';

import { PrivatePaths } from 'constants/routes';
import {
  CampaignStatuses,
  VendorCampaignListTableAccessors,
  UserTypes,
} from 'constants/constants';

import useAuth from 'contexts/AuthContext';
import useObserver from 'hooks/useObserver';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

const VendorHomeContent = () => {
  const { axios, user } = useAuth();
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const {
    fetchNextPage,
    data: campaigns,
    isLoading,
    isFetchingNextPage,
    error,
  } = useInfiniteQuery<PaginationReturn<VendorCampaignResponse>, AxiosError>(
    ['vendor-campaigns-list', user?.current_group_name],
    async ({ pageParam }) => {
      let page;
      if (typeof pageParam === 'string') {
        const url = new URL(pageParam);
        page = url.searchParams.get('page');
      }
      const pageNumber = page || 1;

      try {
        const { data } = await axios.get<
          PaginationReturn<VendorCampaignResponse>
        >(`/vendor-campaigns/?page_size=15&page=${pageNumber}`);
        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      getNextPageParam: page => page.next || undefined,
      refetchOnWindowFocus: false,
      enabled: user?.current_group_name === UserTypes.vendor,
      refetchOnMount: 'always',
    }
  );

  useObserver(loadMoreRef, (isIntersecting: boolean) => {
    if (isIntersecting) fetchNextPage();
  });

  const columnsData = useMemo(
    () => [
      {
        Header: t('common.field.company-name'),
        accessor: VendorCampaignListTableAccessors.company_name,
      },
      {
        Header: t('common.field.campaign-name'),
        accessor: VendorCampaignListTableAccessors.name,
      },
      {
        Header: t('common.field.start-date'),
        accessor: VendorCampaignListTableAccessors.start_date,
      },
      {
        Header: t('common.field.end-date'),
        accessor: VendorCampaignListTableAccessors.end_date,
      },
      {
        Header: t('common.field.total-leads'),
        accessor: VendorCampaignListTableAccessors.total_leads,
      },
      {
        Header: t('common.field.status'),
        accessor: VendorCampaignListTableAccessors.status,
        Cell: ({ value }: { value: CampaignStatuses }) => (
          <CampaignStatus status={value} />
        ),
      },
      {
        Header: t('campaign.manage'),
        accessor: VendorCampaignListTableAccessors.manage,
        Cell: ({ value }: { value: string }) => (
          <Link to={value} className={styles.view}>
            {t('common.button.view-details')}
          </Link>
        ),
      },
    ],
    []
  );

  const rowsData = useMemo(() => {
    return campaigns?.pages
      .map(page => page.results)
      .flat()
      .map(campaign => ({
        [VendorCampaignListTableAccessors.company_name]: campaign.company_name,
        [VendorCampaignListTableAccessors.name]: campaign.name,
        [VendorCampaignListTableAccessors.start_date]: campaign.start_date,
        [VendorCampaignListTableAccessors.end_date]: campaign.end_date,
        [VendorCampaignListTableAccessors.total_leads]: campaign.leads_required,
        [VendorCampaignListTableAccessors.status]: campaign.status,
        [VendorCampaignListTableAccessors.manage]: `/${PrivatePaths.CAMPAIGN}/${campaign.id}`,
      }));
  }, [campaigns]);

  const isNoCampaigns = !isLoading && !rowsData?.length && !error;
  const isLoader = isLoading || isFetchingNextPage;
  const isCampaigns = !isLoading && !!rowsData?.length;

  return (
    <>
      {isCampaigns && (
        <Table
          tableOptions={{ columns: columnsData, data: rowsData }}
          wrapperClassName={styles.table}
        />
      )}

      {error && <Error message={getResponseError(error)} />}
      {isLoader && <Loader className={styles.loader} />}
      {isNoCampaigns && <p>{t('campaign.no-campaigns')}</p>}

      <div ref={loadMoreRef} />
    </>
  );
};

export default VendorHomeContent;
