import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { UserStatuses } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  status: string;
};

const UserStatus = ({ status }: Props) => {
  const { t } = useTranslation();

  const isActive = status === UserStatuses.active;
  const isInvited = status === UserStatuses.invited;
  const isSuspended = status === UserStatuses.suspended;
  const isDeclined = status === UserStatuses.declined;
  const isAwaitingApproval = status === UserStatuses.awaiting_approval;

  const statusStyles = classnames(styles.status, {
    [styles.invited]: isInvited,
    [styles.active]: isActive,
    [styles.suspended]: isSuspended,
    [styles.declined]: isDeclined,
    [styles.awaiting]: isAwaitingApproval,
  });

  return <span className={statusStyles}>{t(`user.status.${status}`)}</span>;
};

export default UserStatus;
