import { createContext, useContext, useCallback } from 'react';
import { useQuery } from 'react-query';
import * as Sentry from '@sentry/react';

import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';

import { ToggledFeatures } from 'constants/features';

export const EnabledFeaturesContext = createContext<{
  getIsFeatureEnabled: (featureName: ToggledFeatures) => boolean;
}>({ getIsFeatureEnabled: () => false });

export const EnabledFeaturesProvider: React.FC = ({ children }) => {
  const { axios, user, isGetUserDataEnabled } = useAuth();

  const { data } = useQuery(
    ['enabled-features', user?.id],
    async () => {
      try {
        const { data: features } = await axios.get<ToggledFeatures[]>(
          '/features/'
        );

        return features;
      } catch (err) {
        Sentry.captureMessage(
          `GET features failed: ${(err as AxiosError).message}`
        );
        throw err;
      }
    },
    {
      enabled: !isGetUserDataEnabled,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      initialData: [],
    }
  );

  const getIsFeatureEnabled = useCallback(
    (featureName: ToggledFeatures) =>
      data?.some(feature => feature === featureName) || false,
    [JSON.stringify(data)]
  );

  return (
    <EnabledFeaturesContext.Provider value={{ getIsFeatureEnabled }}>
      {children}
    </EnabledFeaturesContext.Provider>
  );
};

const useEnabledFeatures = () => useContext(EnabledFeaturesContext);

export default useEnabledFeatures;
