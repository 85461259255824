import PageContainer from 'components/common/PageContainer';
import ManageRequestsContent from 'components/ManageRequestsContent';

const ManageRequests = () => {
  return (
    <PageContainer path={['manage-requests.heading']}>
      <ManageRequestsContent />
    </PageContainer>
  );
};

export default ManageRequests;
