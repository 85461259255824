import { useEffect, useState } from 'react';
import {
  Controller,
  useController,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { AxiosError } from 'axios';

import {
  revenueOptions,
  managementLevelOptions,
  companySizeOptions,
  industriesOptions,
} from 'constants/selectOptions';

import InputWrapper from 'components/common/InputWrapper';
import RegionsSelect from 'components/CreateCampaign/Step1/Specification/RegionsSelect';
import CustomValueSelect from 'components/CreateCampaign/Step1/Specification/CustomValueSelect';
import UploadTALFile from 'components/CreateCampaign/Step1/Specification/UploadTALFiles';
import UploadSALFile from 'components/CreateCampaign/Step1/Specification/UploadSALFiles';
import JobTitleAutocomplete from 'components/common/JobTitleAutocomplete';
import MultiSelectWithAll from 'components/common/MultiSelectWithAll';
import ModalWindow from 'components/common/ModalWindow';

import BlockHeader from 'components/CreateCampaign/Step1/BlockHeader';
import BlockWrapper from 'components/CreateCampaign/Step1/BlockWrapper';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import useTranslateSelectOptions from 'hooks/useTranslateSelectOptions';
import useGetSALFiles from 'components/CreateCampaign/useGetSALFiles';
import useGetTALFiles from 'components/CreateCampaign/useGetTALFiles';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';

import { IconsNames, LocalStorageKeys } from 'constants/constants';

import useModal from 'contexts/ModalContext';

import styles from './index.module.scss';

type Props = {
  saveDraftErrors?: AxiosError | null;
};

const Specification = ({ saveDraftErrors }: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const [isFullJobsListVisible, setJobsListVisibility] = useState(false);
  const {
    control,
    formState: { errors },
    register,
    getValues,
    setValue,
  } = useFormContext<CreateCampaignValues>();
  const translateOptions = useTranslateSelectOptions();
  const selectedRegions = useController({
    control,
    name: 'campaign_specification.selected_regions',
  });
  const { isLoading: isGetSALLoading, error: getSALError } = useGetSALFiles({
    campaign: getValues(),
    setFormValue: setValue,
  });
  const { isLoading: isGetTALLoading, error: getTALError } = useGetTALFiles({
    campaign: getValues(),
    setFormValue: setValue,
  });

  const [noticeFieldValue, setNoticeFieldValue] = useState<string>('');

  const managementLevelSelectOptions = translateOptions(managementLevelOptions);
  const industriesSelectOptions = translateOptions(industriesOptions);
  const revenueSelectOptions = translateOptions(revenueOptions);
  const companySizeSelectOptions = translateOptions(companySizeOptions);

  const jobTitleRequiredError =
    errors?.campaign_specification?.job_titles &&
    'message' in errors?.campaign_specification?.job_titles &&
    errors?.campaign_specification?.job_titles.message;

  const jobTitleError =
    Array.isArray(errors?.campaign_specification?.job_titles) &&
    errors.campaign_specification?.job_titles.find(
      (err: { value?: { message?: string } }) => err?.value
    )?.value?.message;
  const jobTitleErrorMessage = jobTitleError || jobTitleRequiredError || '';

  const managementError =
    errors?.campaign_specification?.management_level &&
    'message' in errors?.campaign_specification?.management_level &&
    errors?.campaign_specification?.management_level.message;

  const isJobTitleFilled = !!useWatch({
    name: 'campaign_specification.job_titles',
  }).length;

  const isManagementLevelFilled = !!useWatch({
    name: 'campaign_specification.management_level',
  }).length;

  const isIndustryFilled = !!useWatch({
    name: 'campaign_specification.industry',
  }).length;

  const isGeographyFilled = !!useWatch({
    name: 'campaign_specification.geography',
  }).length;

  const isRevenueFilled = !!useWatch({
    name: 'campaign_specification.revenue',
  }).length;

  const isCompanySizeFilled = !!useWatch({
    name: 'campaign_specification.size',
  }).length;

  const isTALFileUploaded = !!useWatch({
    name: 'campaign_files.target_accounts',
  })?.length;

  const isTALFileSelected = !!useWatch({
    name: 'campaign_files.company_target_accounts',
  })?.length;

  const isTALModalVisible =
    (isTALFileUploaded || isTALFileSelected) &&
    isJobTitleFilled &&
    isManagementLevelFilled &&
    (isIndustryFilled ||
      isGeographyFilled ||
      isRevenueFilled ||
      isCompanySizeFilled ||
      !!noticeFieldValue);

  useEffect(() => {
    const isTALModalShownOnce = sessionStorage.getItem(
      `${LocalStorageKeys.CAMPAIGN_ID}${getValues().id}`
    );

    if (isTALModalVisible && !isTALModalShownOnce) {
      openModal({
        Content: <ModalWindow title={t('campaign.leads-will-be-scored')} />,
      });

      sessionStorage.setItem(
        `${LocalStorageKeys.CAMPAIGN_ID}${getValues().id}`,
        JSON.stringify({ isTALModalShownOnce: true })
      );
    }
  }, [isTALModalVisible]);

  return (
    <>
      <BlockHeader
        heading={t('campaign.campaign-specification')}
        description={t('campaign.set-up-detailed-info')}
        iconClassName={styles.icon}
        iconName={IconsNames.settings}
      />
      <BlockWrapper>
        <div className={styles.wrapper}>
          <div className={styles.column}>
            <InputWrapper
              isPreventDefaultClick={true}
              label="common.field.job-title"
              validationError={jobTitleErrorMessage}
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_specification
                    ?.job_titles
                )
                  ? saveDraftErrors?.response?.data?.campaign_specification
                      ?.job_titles[0]
                  : undefined
              }
            >
              <Controller
                name="campaign_specification.job_titles"
                control={control}
                render={({ field }) => {
                  const toggleJobsListVisibility = () => {
                    setJobsListVisibility(!isFullJobsListVisible);
                  };

                  const isToggleButtonVisible =
                    Array.isArray(field.value) &&
                    Number(field.value?.length) > 5;
                  return (
                    <div className={styles.jobsWrapper}>
                      {isToggleButtonVisible && (
                        <div
                          className={styles.toggle}
                          onClick={e => {
                            e.stopPropagation();
                            toggleJobsListVisibility();
                          }}
                        >
                          {isFullJobsListVisible
                            ? t('common.button.collapse')
                            : t('common.button.expand')}
                        </div>
                      )}
                      <JobTitleAutocomplete
                        field={field}
                        isMulti
                        styles={getDefaultSelectStylesWithError({
                          error: !!jobTitleErrorMessage,
                          isNoBackgroundSelectedOption: true,
                          isSmall: true,
                          fullHeight: isFullJobsListVisible,
                        })}
                      />
                    </div>
                  );
                }}
              />
            </InputWrapper>

            <InputWrapper
              isPreventDefaultClick={true}
              label="common.field.industry"
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_specification
                    ?.industry
                )
                  ? saveDraftErrors?.response?.data?.campaign_specification
                      ?.industry[0]
                  : undefined
              }
            >
              <Controller
                name="campaign_specification.industry"
                control={control}
                render={({ field }) => (
                  <MultiSelectWithAll
                    options={industriesSelectOptions}
                    placeholder={t('common.field.select')}
                    {...field}
                    noOptionsMessage={() => t('common.field.no-options')}
                  />
                )}
              />
            </InputWrapper>

            <InputWrapper
              isPreventDefaultClick={true}
              label="common.field.revenue"
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_specification
                    ?.revenue
                )
                  ? saveDraftErrors?.response?.data?.campaign_specification
                      ?.revenue[0]
                  : undefined
              }
            >
              <Controller
                name="campaign_specification.revenue"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomValueSelect
                    onChange={onChange}
                    value={value}
                    options={revenueSelectOptions}
                    customFieldPath="custom_revenue"
                  />
                )}
              />
            </InputWrapper>
          </div>

          <div className={styles.column}>
            <InputWrapper
              isPreventDefaultClick={true}
              label="common.field.management-level"
              validationError={managementError || ''}
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_specification
                    ?.management_level
                )
                  ? saveDraftErrors?.response?.data?.campaign_specification
                      ?.management_level[0]
                  : undefined
              }
            >
              <Controller
                name="campaign_specification.management_level"
                control={control}
                render={({ field }) => (
                  <MultiSelectWithAll
                    isMulti
                    isError={!!managementError}
                    options={managementLevelSelectOptions}
                    {...field}
                    placeholder={t('common.field.select')}
                    noOptionsMessage={() => t('common.field.no-options')}
                  />
                )}
              />
            </InputWrapper>

            <InputWrapper
              isPreventDefaultClick={true}
              label="common.field.geography"
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_specification
                    ?.geography
                )
                  ? saveDraftErrors?.response?.data?.campaign_specification
                      ?.geography[0]
                  : undefined
              }
            >
              <Controller
                name="campaign_specification.geography"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RegionsSelect
                    onChange={onChange}
                    value={value}
                    selectedRegions={selectedRegions}
                  />
                )}
              />
            </InputWrapper>

            <InputWrapper
              isPreventDefaultClick={true}
              label="common.field.company-size"
              submitError={
                Array.isArray(
                  saveDraftErrors?.response?.data?.campaign_specification?.size
                )
                  ? saveDraftErrors?.response?.data?.campaign_specification
                      ?.size[0]
                  : undefined
              }
            >
              <Controller
                name="campaign_specification.size"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomValueSelect
                    onChange={onChange}
                    value={value}
                    options={companySizeSelectOptions}
                    customFieldPath="custom_size"
                  />
                )}
              />
            </InputWrapper>
          </div>

          <InputWrapper
            isPreventDefaultClick={true}
            label="common.field.special-instructions"
            wrapperClasses={styles.notice}
            validationError={errors?.campaign_specification?.notice?.message}
            submitError={
              Array.isArray(
                saveDraftErrors?.response?.data?.campaign_specification?.notice
              )
                ? saveDraftErrors?.response?.data?.campaign_specification
                    ?.notice[0]
                : undefined
            }
          >
            <TextareaAutoHeight
              methods={{
                ...register('campaign_specification.notice', {
                  onBlur: e => {
                    setNoticeFieldValue(e.target.value);
                  },
                }),
              }}
              control={control}
              placeholder={t('common.field.special-instructions')}
            />
          </InputWrapper>

          <div className={styles.files}>
            <div className={styles.upload}>
              <UploadTALFile
                isGetLoading={isGetTALLoading}
                getTALError={getTALError}
              />
            </div>
            <div className={styles.upload}>
              <UploadSALFile
                isGetLoading={isGetSALLoading}
                getSALError={getSALError}
              />
            </div>
          </div>
        </div>
      </BlockWrapper>
    </>
  );
};

export default Specification;
