import cn from 'classnames';

import IconSVG, { IconProps } from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  iconProps?: IconProps & { isRightPosition?: boolean };
  withArrow?: boolean;
  white?: boolean;
  green?: boolean;
  red?: boolean;
  darkBlue?: boolean;
  transparent?: boolean;
  isBig?: boolean;
  isSmall?: boolean;
  isDarkBlueBordered?: boolean;
  isLoading?: boolean;
  setTooltipTriggerRef?: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  isOnlyIcon?: false;
}
export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  iconProps: IconProps & { isRightPosition?: boolean };
  setTooltipTriggerRef?: React.Dispatch<
    React.SetStateAction<HTMLElement | null>
  >;
  isOnlyIcon: true;
}

const Button: React.FC<ButtonProps | IconButtonProps> = props => {
  const {
    children,
    className,
    isOnlyIcon,
    iconProps,
    setTooltipTriggerRef,

    withArrow,
    white,
    green,
    red,
    darkBlue,
    transparent,
    isDarkBlueBordered,

    isBig,
    isSmall,

    isLoading = false,

    ...rest
  } = props as ButtonProps;

  const buttonClasses = cn(
    styles.button,
    {
      [styles['button--big']]: isBig,
      [styles['button--small']]: isSmall,

      [styles['button--red']]: red,
      [styles['button--green']]: green,
      [styles['button--white']]: white,
      [styles['button--transparent']]: transparent,
      [styles['button--dark-blue']]: darkBlue,
      [styles['button--dark-blue-bordered']]: isDarkBlueBordered,

      [styles['button--icon']]: iconProps,
      [styles['button--arrow']]: withArrow,
      [styles['button--icon-right']]: iconProps?.isRightPosition,
      [styles['button--only-icon']]: isOnlyIcon,

      [styles['button--loading']]: isLoading,
    },
    className
  );

  const iconComponentProps = iconProps && { ...iconProps };
  delete iconComponentProps?.isRightPosition;

  return (
    <button {...rest} className={buttonClasses} ref={setTooltipTriggerRef}>
      {isLoading ? (
        <span className={styles.dots}>
          <span className={styles.loading} />
        </span>
      ) : (
        <>
          {iconComponentProps && <IconSVG {...iconComponentProps} />}
          {children && !isOnlyIcon && <span>{children}</span>}
          {withArrow && (
            <IconSVG name={IconsNames.right_arrow} className={styles.arrow} />
          )}
        </>
      )}
    </button>
  );
};

export default Button;
