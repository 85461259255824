import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import type { ReactEditor } from 'slate-react';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import RichText from 'components/RichText';

import BlockHeading from 'components/CreateCampaign/Step1/ScriptBuilder/BlockHeading';
import FocusedWrapper from 'components/RichText/FocusedWrapper';

import { IconsNames } from 'constants/constants';

type Props = {
  htmlString?: string;
};

const CASL = ({ htmlString }: Props) => {
  const { t } = useTranslation();
  const CASLEditorRef = useRef<ReactEditor>();
  const formSettings = useFormContext<CreateCampaignValues>();
  const { control } = formSettings || {};

  const isPreviewMode = typeof htmlString === 'string';
  const { field: CASLField } = !isPreviewMode
    ? useController({
        name: 'script_builder.compliance_casl_block',
        control,
      })
    : { field: null };

  return !!CASLField?.value || (isPreviewMode && !!htmlString.length) ? (
    <>
      <BlockHeading iconName={IconsNames.compliance_lock_line}>
        {t('script.casl')} {t('script.compliance').toLowerCase()}
      </BlockHeading>
      <FocusedWrapper isPreviewMode={isPreviewMode}>
        {props =>
          isPreviewMode ? (
            <RichText htmlString={htmlString} />
          ) : (
            <RichText
              value={CASLField?.value || undefined}
              onChange={val => CASLField?.onChange(val)}
              editorRef={CASLEditorRef}
              placeholder={`${t('script.casl')} ${t(
                'script.compliance'
              ).toLowerCase()}`}
              {...props}
            />
          )
        }
      </FocusedWrapper>
    </>
  ) : null;
};

export default CASL;
