import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import IconSVG from 'components/UI/IconSVG';
import Button from 'components/common/Button';

import DepositNowButton from 'components/PaymentsComponents/DepositNowButton';
import WithdrawModal from 'components/PaymentsComponents/WithdrawModal';
import TransferFundsModal from 'components/PaymentsComponents/TransferFundsModal';
import EditAccountingPersonModal from 'components/PaymentsComponents/EditAccountingPersonModal';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import useGetVendorBalance from 'hooks/api/useGetVendorBalance';
import useGetBalance from 'hooks/api/useGetBalance';

import { IconsNames, UserTypes } from 'constants/constants';

import { getNumberWithCommas } from 'helpers/formatNumbers';

import styles from './index.module.scss';

type Props = {
  campaignBalance?: string;
  onModalClose?: () => void;
  className?: string;
};

const DepositNow = ({ campaignBalance, onModalClose, className }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { openModal } = useModal();

  const isAdmin = user?.current_group_name === UserTypes.admin;
  const { data: balance, refetch } = useGetBalance(!campaignBalance && isAdmin);
  const { data: vendorBalance } = useGetVendorBalance({
    enabled: !campaignBalance,
  });

  const handleWithdrawClick = () => {
    const isAccountingPersonInfo =
      user?.company.accounting_person_first_name &&
      user?.company.accounting_person_last_name &&
      user?.company.accounting_person_email;
    if (!isAccountingPersonInfo) {
      return openModal({
        Content: (
          <EditAccountingPersonModal
            onSuccess={() =>
              openModal({
                Content: <WithdrawModal />,
              })
            }
          />
        ),
      });
    }

    openModal({
      Content: <WithdrawModal />,
    });
  };

  const handleTransferClick = () => {
    if (vendorBalance)
      openModal({
        Content: (
          <TransferFundsModal
            isAdmin={isAdmin}
            vendorBalance={vendorBalance?.in_account}
          />
        ),
      });
  };

  const currentVisibleBalance = isAdmin
    ? getNumberWithCommas(balance?.in_account || 0)
    : getNumberWithCommas(vendorBalance?.in_account || 0);
  const isVendorBalanceActionsDisabled =
    !vendorBalance?.in_account || Number(vendorBalance?.in_account) <= 0;

  return (
    <section
      className={cn(styles.deposit, className, {
        [styles.campaign]: campaignBalance,
      })}
    >
      <div className={styles.value}>
        <span className={styles.icon}>
          <IconSVG name={IconsNames.wallet} />
        </span>
        <p className={styles.current}>
          {user && (campaignBalance || currentVisibleBalance)}
          <span className={styles.sign}>
            {campaignBalance
              ? t('campaign.campaign-balance')
              : t('payments.current-balance')}
          </span>
        </p>
      </div>
      {!campaignBalance && (
        <>
          <div className={styles.buttons}>
            <Button
              white
              iconProps={{ name: IconsNames.transfer }}
              disabled={isVendorBalanceActionsDisabled}
              className={styles.transfer}
              onClick={handleTransferClick}
              isBig
              aria-label="Transfer funds"
            >
              {t('common.button.transfer-funds')}
            </Button>
            {user?.current_group_name === UserTypes.admin ? (
              <DepositNowButton
                className={styles.button}
                checkoutOptions={{
                  onClose: async () => {
                    await refetch();
                    if (onModalClose) {
                      onModalClose();
                    }
                  },
                }}
              />
            ) : (
              <Button
                iconProps={{ name: IconsNames.dollar }}
                onClick={handleWithdrawClick}
                disabled={isVendorBalanceActionsDisabled}
                isBig
              >
                {t('common.button.withdraw')}
              </Button>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default DepositNow;
