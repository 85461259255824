import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import type { AxiosError } from 'axios';
import {
  CreateCampaignValues,
  DeliveryLabels,
  CreateCampaignStepProps,
} from 'components/CreateCampaign/models';
import { PaymentMethodName } from 'components/PaymentsComponents/models';

import Button from 'components/common/Button';
import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import IconSVG from 'components/UI/IconSVG';
import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';
import ToggleSwitch from 'components/common/ToggleSwitch';

import InfoSectionWrapper from 'components/CampaignDetails/InfoSectionWrapper';
import DepositNowButton from 'components/PaymentsComponents/DepositNowButton';
import SelectPaymentMethod from 'components/PaymentsComponents/SelectPaymentMethod';
import DepositLinkModal from 'components/CreateCampaign/Step4/TotalCost/DepositLinkModal';
import NoEnoughCreditsModal from 'components/PaymentsComponents/NoEnoughCreditsModal';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import useGetExpectedPrice from 'components/PaymentsComponents/useGetExpectedPrice';
import useCreatePayment from 'hooks/api/useCreatePayment';

import getResponseError from 'helpers/getResponseError';
import { getDeliveryErrorsArray } from 'helpers/createCampaign';

import {
  IconsNames,
  CampaignCreatingStep,
  CampaignStatuses,
} from 'constants/constants';
import { PrivatePaths } from 'constants/routes';

import styles from './index.module.scss';

type Props = {
  isDemoCampaign?: boolean;
  goToStep: CreateCampaignStepProps['goToStep'];
};

type TotalCostResponse = {
  cost_per_lead: string;
  total_leads: number;
  platform_fee: string;
  qc_fee: string;
  total_price: string;
  lack_amount: string;
};

const DEFAULT_REFETCH_PRICE_INTERVAL = 2500;
const PAYMENT_CREATED_REFETCH_PRICE_INTERVAL = 1500;

const TotalCost = ({ isDemoCampaign, goToStep }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { axios, user } = useAuth();
  const { openModal } = useModal();
  const [isUsingGeneralWallet, setIsUsingGeneralWallet] = useState(false);
  const navigate = useNavigate();
  const [isPaymentCreated, setIsPaymentCreated] = useState(false);
  const [isBalanceModalClosed, setIsModalClosed] = useState(false);
  const {
    formMethods: {
      setValue,
      watch,
      control,
      getValues,
      formState: { errors, isValid },
    },
    formatPayload,
    mutation: { data, isLoading: isGetPriceLoading },
  } = useGetExpectedPrice();
  const campaignFormSettings = useFormContext<CreateCampaignValues>();
  const { mutate: createPayment, isLoading: isCreatePaymentLoading } =
    useCreatePayment();

  const handleSetTotalDepositAmount = (
    useGeneralWallet: boolean,
    totalData: TotalCostResponse
  ) => {
    const lackAmountNumber = Number(totalData.lack_amount);
    const totalPriceNumber = Number(totalData.total_price);
    const lackAmountDiscount =
      lackAmountNumber < 0
        ? totalPriceNumber + lackAmountNumber
        : totalPriceNumber;
    const discount = useGeneralWallet ? lackAmountDiscount : undefined;
    const lackAmount = lackAmountNumber < 0 ? lackAmountNumber * -1 : 0;

    setValue('amount', !useGeneralWallet ? totalPriceNumber : lackAmount);
    setValue('discount', discount?.toString());
  };

  const campaign = campaignFormSettings.getValues();

  const { mutateAsync: submitCampaign, isLoading: isSubmitLoading } =
    useMutation<void, AxiosError>(
      async () => {
        try {
          await axios.patch(`/campaigns/${campaign.id}/`, {
            campaign_details: {
              creating_step: CampaignCreatingStep.completed,
              status: CampaignStatuses.inProgress,
            },
          });
        } catch (err) {
          throw err;
        }
      },
      {
        onSuccess: async () => {
          await queryClient.refetchQueries([
            'campaign-list',
            user?.current_group_name,
          ]);
          await queryClient.invalidateQueries([
            'campaign',
            campaign.id,
            user?.current_group_name,
            user?.company.id.toString(),
          ]);
          openModal({
            Content: (
              <ModalWindow
                className={styles.successModal}
                successMessage={t('campaign.created-successfully', {
                  name: campaign.campaign_details.name,
                })}
              />
            ),
          });
          navigate(PrivatePaths.INDEX);
        },
        onError: err => {
          campaignFormSettings.setValue(
            'campaign_details.creating_step',
            CampaignCreatingStep['fourth-step']
          );
          if (err.response?.status === 402) {
            openModal({
              Content: <NoEnoughCreditsModal error={err} />,
            });

            return;
          }
          openModal({
            Content: (
              <ModalWindow
                title={t('common.error.something-went-wrong')}
                errorMessage={getResponseError(err)}
              />
            ),
          });
        },
      }
    );

  const handleValidateCampaign = async () => {
    const isDeliveryValid = await campaignFormSettings.trigger(
      'campaign_delivery'
    );

    if (!isDeliveryValid) {
      goToStep(CampaignCreatingStep['first-step']);
      await campaignFormSettings.trigger('campaign_delivery', {
        shouldFocus: true,
      });
      const errorsData = getDeliveryErrorsArray(
        campaignFormSettings.formState.errors?.campaign_delivery
      );

      openModal({
        Content: (
          <ModalWindow title={t('common.error.something-went-wrong')}>
            {errorsData?.map(err =>
              err?.message ? (
                <Error
                  message={`${t(
                    // @ts-ignore
                    `common.field.${DeliveryLabels[err.key]}`
                  )}: ${t(err.message)}`}
                  key={err.key}
                />
              ) : null
            )}
          </ModalWindow>
        ),
      });
    }
    return isDeliveryValid;
  };

  const handleSubmit = async () => {
    const isDeliveryValid = await handleValidateCampaign();
    if (!isDeliveryValid) return;
    campaignFormSettings.setValue(
      'campaign_details.creating_step',
      CampaignCreatingStep.completed
    );
    submitCampaign();
  };

  const {
    data: total,
    isLoading,
    error,
    refetch,
  } = useQuery<TotalCostResponse, AxiosError>(
    ['total-cost', campaign?.id],
    async () => {
      try {
        const response = await axios.get(`/campaigns/${campaign?.id}/price/`);
        return response.data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async response => {
        if (Number(response.lack_amount) >= 0) {
          await queryClient.refetchQueries('balance');

          if (isPaymentCreated) {
            await handleSubmit();
            return;
          }
        }
        handleSetTotalDepositAmount(isUsingGeneralWallet, response);
        setValue('line_items', [
          {
            title: `Campaign leads (${campaign.campaign_details.name})`,
            quantity: response.total_leads,
            price: Number(response.cost_per_lead),
          },
          ...(Number(response.qc_fee)
            ? [
                {
                  title: 'QC fee',
                  quantity: response.total_leads,
                  price: Number(response.qc_fee),
                },
              ]
            : []),
          {
            title: 'Platform fee',
            quantity: response.total_leads,
            price: Number(response.platform_fee),
          },
        ]);
      },
      enabled: !!campaign.id,
      refetchInterval: response => {
        const currentInterval = isPaymentCreated
          ? PAYMENT_CREATED_REFETCH_PRICE_INTERVAL
          : DEFAULT_REFETCH_PRICE_INTERVAL;
        return (response?.lack_amount || 0) < 0 ? currentInterval : false;
      },
    }
  );

  const checkoutOptions = {
    onSuccess: () => {
      setIsPaymentCreated(true);
    },
    onClose: async () => {
      setIsModalClosed(true);
      await refetch();
    },
  };

  const handleReceiveDepositLink = async (
    e: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const isDeliveryValid = await handleValidateCampaign();
    if (!isDeliveryValid) return;

    const paymentValues = getValues();
    const payload = formatPayload({
      ...paymentValues,
      campaign_id: campaign.id.toString(),
    });
    createPayment(
      {
        ...payload,
        isSkipBalanceModal: payload.terms_method !== PaymentMethodName.invoice,
        checkoutOptions,
      },
      {
        onError: err => {
          openModal({
            Content: (
              <ModalWindow
                title={t('common.error.something-went-wrong')}
                errorMessage={getResponseError(err)}
              />
            ),
          });
        },
        onSuccess: response => {
          if (response.isSkipBalanceModal) {
            openModal({
              Content: <DepositLinkModal link={response.link} />,
            });
          }
        },
      }
    );
  };

  const handleToggleChange = () => {
    setIsUsingGeneralWallet(prev => {
      if (total) handleSetTotalDepositAmount(!prev, total);
      return !prev;
    });
  };
  const discount = Number(watch('discount') || 0);
  const isPaymentRequired = watch('amount') > 0;
  const totalWithDiscount = Number(total?.total_price || 0) - discount;
  const amountWithDiscount = data?.amount ? Number(data.amount) - discount : 0;
  const totalPrice = isPaymentRequired
    ? amountWithDiscount || totalWithDiscount
    : totalWithDiscount;
  const isLoaderScreenVisible =
    isCreatePaymentLoading ||
    isSubmitLoading ||
    (isPaymentCreated && isBalanceModalClosed);

  return (
    <>
      {!isDemoCampaign && (
        <InfoSectionWrapper>
          <InfoSectionWrapper.Header>
            <InfoSectionWrapper.Title>
              {t('common.field.total-cost')}
            </InfoSectionWrapper.Title>
          </InfoSectionWrapper.Header>
          {total && (
            <InfoSectionWrapper.Content className={styles.wrapper}>
              <ul>
                <li className={styles.cost}>
                  <span className={styles.icon}>
                    <IconSVG name={IconsNames.people} />
                  </span>
                  <p>
                    {total.total_leads}
                    <span>{t('common.field.total-leads')}</span>
                  </p>
                </li>
                <li className={styles.cost}>
                  <span className={styles.icon}>
                    <IconSVG name={IconsNames.person_money} />
                  </span>
                  <p>
                    ${total.cost_per_lead}
                    <span>{t('common.field.max-cpl')}</span>
                  </p>
                </li>
                <li className={styles.cost}>
                  <span className={styles.icon}>
                    <IconSVG name={IconsNames.fee_dollar} />
                  </span>
                  <p>
                    ${total.platform_fee}
                    <span>{t('common.field.platform-fee')}</span>
                  </p>
                </li>
                <li className={styles.cost}>
                  <span className={styles.icon}>
                    <IconSVG name={IconsNames.fee_dollar} />
                  </span>
                  <p>
                    ${total.qc_fee}
                    <span>{t('common.field.qc-fee')}</span>
                  </p>
                </li>
                <li className={styles.cost}>
                  <span className={styles.icon}>
                    <IconSVG name={IconsNames.fee_dollar} />
                  </span>
                  <p>
                    {isPaymentRequired && data?.fee
                      ? `$${Number(data.fee).toFixed(2)}`
                      : '-'}
                    <span>{t('common.field.processing-fee')}</span>
                  </p>
                </li>
              </ul>
              <div className={styles.total}>
                <div className={styles.amount}>
                  <p>
                    $
                    {isGetPriceLoading ? (
                      <Loader size={26} className={styles.loading} />
                    ) : (
                      Number(totalPrice).toFixed(2)
                    )}
                    <span className={styles.sign}>
                      {t('common.field.total-deposit-amount')}
                    </span>
                  </p>
                  <label className={styles.toggle}>
                    <ToggleSwitch
                      id="use-current-balance"
                      checked={isUsingGeneralWallet}
                      onChange={handleToggleChange}
                    />
                    <span>{t('payments.use-current-balance')}</span>
                  </label>
                </div>

                {isPaymentRequired && (
                  <div className={styles.insufficient}>
                    <form>
                      <SelectPaymentMethod
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        daysCountError={errors.term?.message}
                        methodError={errors.method?.message}
                      />
                      {user?.is_superadmin ? (
                        <Button
                          type="submit"
                          className={styles.deposit}
                          disabled={!isValid}
                          onClick={handleReceiveDepositLink}
                          isBig
                          iconProps={{
                            name: IconsNames.link,
                          }}
                        >
                          {t('common.button.receive-deposit-link')}
                        </Button>
                      ) : (
                        <DepositNowButton
                          checkoutOptions={checkoutOptions}
                          disabled={!isValid}
                          className={styles.deposit}
                          getIsPaymentEnabled={handleValidateCampaign}
                          getPaymentOptions={() =>
                            formatPayload({
                              ...getValues(),
                              campaign_id: campaign?.id.toString(),
                            })
                          }
                        />
                      )}
                    </form>
                  </div>
                )}
              </div>
            </InfoSectionWrapper.Content>
          )}
          {isLoading && <Loader className={styles.loader} />}
          {error && (
            <InfoSectionWrapper.Content>
              <p className={styles.error}>
                {t('common.error.something-went-wrong')}
              </p>
              <Error message={getResponseError(error)} />
            </InfoSectionWrapper.Content>
          )}
        </InfoSectionWrapper>
      )}
      <Button
        className={styles.submit}
        onClick={handleSubmit}
        disabled={isPaymentRequired}
        isBig
      >
        {t('common.button.submit')}
      </Button>
      {isLoaderScreenVisible && <LoaderScreen />}
    </>
  );
};

export default TotalCost;
