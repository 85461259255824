import { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import Table from 'components/common/Table';

import useObserver from 'hooks/useObserver';
import useFetchRequests from 'hooks/api/useFetchRequests';

import useAuth from 'contexts/AuthContext';

import { timezones } from 'constants/selectOptions';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

dayjs.extend(utc);

export enum TableAccessors {
  first_name = 'first_name',
  last_name = 'last_name',
  job_title = 'job_title',
  company_name = 'company_name',
  email = 'email',
  phone = 'phone',
  date = 'date',
  time = 'time',
}

const ManageRequestsContent = () => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { user } = useAuth();

  const {
    fetchNextPage,
    data: requests,
    isLoading,
    isFetchingNextPage,
    error,
  } = useFetchRequests();

  const rowsData = useMemo(() => {
    const getTimezoneLabel = (value: string) => {
      const userTimezone = timezones.filter(
        timezone => timezone.value === value
      )[0];

      return userTimezone.label;
    };

    return requests?.pages
      .map(page => page.results)
      .flat()
      .map(request => ({
        [TableAccessors.first_name]: request.first_name,
        [TableAccessors.last_name]: request.last_name,
        [TableAccessors.job_title]: request.job_title,
        [TableAccessors.company_name]: request.company_name,
        [TableAccessors.email]: request.email,
        [TableAccessors.phone]: `+${request.phone_number}`,
        [TableAccessors.date]: dayjs(request.timeslot).format('D MMM, YYYY'),
        [TableAccessors.time]: (
          <p className={styles.time}>
            {dayjs(request.timeslot).utcOffset(0).format('hh:mm A')},
            <span className={styles.timezone}>
              {getTimezoneLabel(request.client_timezone)}
            </span>
          </p>
        ),
      }));
  }, [requests]);

  const columnsData = useMemo(
    () => [
      {
        Header: t('common.field.first-name'),
        accessor: TableAccessors.first_name,
      },
      {
        Header: t('common.field.last-name'),
        accessor: TableAccessors.last_name,
      },
      {
        Header: t('common.field.job-title'),
        accessor: TableAccessors.job_title,
      },
      {
        Header: t('common.field.company-name'),
        accessor: TableAccessors.company_name,
      },
      {
        Header: t('common.field.email'),
        accessor: TableAccessors.email,
      },
      {
        Header: t('common.field.phone-number'),
        accessor: TableAccessors.phone,
      },
      {
        Header: t('common.field.date'),
        accessor: TableAccessors.date,
      },
      {
        Header: t('common.field.time'),
        accessor: TableAccessors.time,
      },
    ],
    [user]
  );

  useObserver(loadMoreRef, (isIntersecting: boolean) => {
    if (isIntersecting) fetchNextPage();
  });

  const isNoRequests = !isLoading && !rowsData?.length;
  const isLoader = isLoading || isFetchingNextPage;

  return (
    <>
      {!isLoading && !!rowsData?.length && (
        <Table
          tableOptions={{ columns: columnsData, data: rowsData }}
          wrapperClassName={styles.table}
          headClassName={styles.thead}
          rowClassName={styles.row}
        />
      )}
      {error && <Error message={getResponseError(error)} />}
      {isLoader && <Loader className={styles.loader} />}
      {isNoRequests && <p>{t('manage-requests.no-requests')}</p>}
      <div ref={loadMoreRef} />
    </>
  );
};

export default ManageRequestsContent;
