import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from 'i18n/resources';

use(initReactI18next).init(
  {
    debug: false,
    fallbackLng: 'en-US',
    resources,
    lng: 'en-US',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  },
  (err, t) => {
    if (err) {
      console.log('something went wrong loading', err);
    }
    return t('');
  }
);

export default i18n;
