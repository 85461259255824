import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { Editable, withReact, Slate, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { createEditor } from 'slate';
import cn from 'classnames';

import type { Descendant } from 'slate';

import Variable from 'components/RichText/Variable';
import AddVariableButton from 'components/RichText/AddVariable';
import Error from 'components/common/Error';

import { SlateElementTypes } from 'components/RichText/models';

import { withCustomElements, withRegexValidation } from 'utils/richTextEditor';

import { emptyTemplate } from 'constants/scriptTemplates';

import styles from './index.module.scss';

export interface RichTextEditorProps
  extends Omit<
    React.TextareaHTMLAttributes<HTMLDivElement>,
    'value' | 'onChange'
  > {
  value?: Descendant[];
  onChange: (value: Descendant[]) => void;
  editorRef: React.MutableRefObject<ReactEditor | undefined>;
  wrapperRef: React.RefObject<HTMLDivElement>;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  isFocused: boolean;
  placeholder?: string;
  className?: string;
  isReadOnly?: boolean;
  setWarningViewsCount?: React.Dispatch<React.SetStateAction<number>>;
  validationError?: string;
  forbiddenCharactersRegexp?: RegExp;
}

const RichText = ({
  value,
  onChange,
  editorRef,
  placeholder,
  setIsFocused,
  wrapperRef,
  isFocused,
  className,
  isReadOnly = false,
  validationError,
  forbiddenCharactersRegexp,
  ...props
}: RichTextEditorProps) => {
  const { t } = useTranslation();

  const editor = useMemo(() => {
    if (!editorRef.current) {
      let baseEditor = withCustomElements(
        withHistory(withReact(createEditor()))
      );
      if (forbiddenCharactersRegexp) {
        baseEditor = withRegexValidation(forbiddenCharactersRegexp, baseEditor);
      }

      editorRef.current = baseEditor;
    }
    return editorRef.current;
  }, [editorRef]);

  const renderElement = useCallback(
    ({ element, children, attributes }) => {
      switch (element.type) {
        case SlateElementTypes.variable:
          return (
            <Variable
              attributes={attributes}
              element={element}
              containerRef={wrapperRef}
            >
              {children}
            </Variable>
          );

        default:
          return (
            <p {...attributes} id={element.id}>
              {children}
            </p>
          );
      }
    },
    [wrapperRef]
  );

  return (
    <>
      <Slate editor={editor} value={value || emptyTemplate} onChange={onChange}>
        <div className={cn(styles.wrapper, className)}>
          <Editable
            renderElement={renderElement}
            placeholder={placeholder || t('script.add-script')}
            spellCheck
            className={cn(styles.editable, {
              [styles.focused]: isFocused && !isReadOnly,
              [styles.error]: !!validationError,
            })}
            readOnly={Boolean(isReadOnly)}
            onFocus={() => {
              if (!isReadOnly) setIsFocused(true);
            }}
            {...props}
          />
          {isFocused && !isReadOnly && (
            <AddVariableButton containerRef={wrapperRef} />
          )}
        </div>
      </Slate>
      {validationError && <Error message={validationError} />}
    </>
  );
};

export default RichText;
