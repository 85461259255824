import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import type {
  Country,
  CreateCampaignValues,
  CampaignFiles,
  ReportTemplate,
  TALFile,
  SALFile,
  CampaignScriptValues,
} from 'components/CreateCampaign/models';
import type {
  CampaignResponse,
  SALFileResponse,
  TALFileResponse,
  ReportTemplateResponse,
  Milestone,
  CampaignAssetFileResponse,
  SelectOption,
  CampaignScript,
} from 'types/models';

import {
  Regions,
  ScriptType,
  CampaignStatuses,
  CampaignCreatingStep,
  MilestoneType,
  MILESTONE_END_DATE_DIFFERENCE,
  selectAllOptionValue,
  MAX,
  allReportMappingOptions,
  CustomDefaultValue,
} from 'constants/constants';
import {
  createIntroductionTemplate,
  createWhitepaperTemplate,
  createClosingTemplate,
  createComplianceTemplate,
} from 'constants/scriptTemplates';
import {
  milestoneTemplate,
  milestoneOptionsList,
  geographyRegions,
} from 'constants/selectOptions';

import {
  convertHTMLToEditorData,
  convertEditorDataToHTML,
  createScriptHTMLForPDF,
} from 'utils/richTextEditor';

import useTranslateSelectOptions from 'hooks/useTranslateSelectOptions';

import { parseSALFile, parseTALFileResponse } from 'helpers/createCampaign';

const useConvertCampaignData = () => {
  const useTranslationResponse = useTranslation();
  const { t, i18n } = useTranslationResponse;
  const translateOptions = useTranslateSelectOptions();

  const defaultData = {
    campaign_details: {
      name: 'Untitled campaign',
      contacts_per_company: 3,
      is_qc_required: false,
      notice: '',
      cost_per_lead: 0,
      delivery_emails: [],
      total_leads: 0,
      status: CampaignStatuses.draft,
      creating_step: CampaignCreatingStep['first-step'],
      owner_user_group: null,
      is_casl_compliant: false,
      is_gdpr_compliant: false,
    },
    campaign_specification: {
      id: null,
      job_titles: [
        { label: t('common.field.all'), value: selectAllOptionValue },
      ],
    },
    script_builder: {
      is_casl_compliant: false,
      is_gdpr_compliant: false,
      script_type: ScriptType.whitepaper,
      introduction: createIntroductionTemplate(t),
      asset: createWhitepaperTemplate(t),
      closing: createClosingTemplate(t),
      questions: [],
    },
    campaign_delivery: {
      is_front_load_scoring: false,
      milestones: [milestoneTemplate],
      start_date: dayjs().format('YYYY-MM-DD'),
      end_date: '',
      milestone_deadline_type: {
        value: milestoneOptionsList[0],
        label: t(`common.field.${milestoneOptionsList[0]}`),
      },
    },
  };

  const convertResponse = ({
    campaign_details: campaignDetails,
    campaign_specification: campaignSpecification,
    campaign_delivery: campaignDelivery,
    id,
    campaign_files: campaignFiles,
    isJustCreated,
  }: CampaignResponse & {
    campaign_files?: CampaignFiles;
    isJustCreated?: boolean;
  }): CreateCampaignValues => {
    const getDefaultAndCustomOptions = (values?: string[]) => {
      const defaultOptions: SelectOption[] = [];
      const custom: { from: number; to: number | string }[] = [];
      values?.forEach(option => {
        if (i18n.exists(`common.field.${option}`)) {
          defaultOptions.push({
            label: t(`common.field.${option}`),
            value: option,
          });
        } else {
          custom.push({
            from: Number(option.split('_')[0]),
            to: Number(option.split('_')[1])
              ? Number(option.split('_')[1])
              : MAX,
          });
        }
      });

      return { defaultOptions, custom };
    };

    const locations = Object.values(geographyRegions).flat();
    const selectedRegions: Regions[] = [];
    const selectedLocations: Country[] = [];
    campaignSpecification?.geography?.forEach(location => {
      const locationData = locations.find(
        item =>
          ('iso2' in item && item.iso2 === location) ||
          ('key' in item && item.key === location)
      );
      if (locationData) selectedLocations.push(locationData);
      if (locationData) selectedRegions.push(locationData.region);
    });

    const sizeOptions = getDefaultAndCustomOptions(campaignSpecification?.size);
    const revenueOptions = getDefaultAndCustomOptions(
      campaignSpecification?.revenue
    );

    return {
      id,
      isJustCreated,
      campaign_details: {
        ...defaultData.campaign_details,
        ...campaignDetails,
        delivery_emails: translateOptions(
          campaignDetails?.delivery_emails || [],
          ''
        ),
        owner_user_group:
          campaignDetails.owner_user_group &&
          typeof campaignDetails.owner_user_group !== 'number'
            ? {
                value: campaignDetails.owner_user_group,
                label: campaignDetails.owner_user_group.name,
              }
            : null,
      },
      campaign_specification: {
        ...campaignSpecification,
        job_titles: campaignSpecification?.job_titles?.length
          ? translateOptions(campaignSpecification?.job_titles || [], '')
          : [{ label: t('common.field.all'), value: selectAllOptionValue }],
        management_level: translateOptions(
          campaignSpecification?.management_level || []
        ),
        industry: translateOptions(campaignSpecification?.industry || []),
        size: sizeOptions.defaultOptions,
        custom_size: sizeOptions.custom,
        revenue: revenueOptions.defaultOptions,
        custom_revenue: revenueOptions.custom,
        geography: selectedLocations,
        selected_regions: selectedRegions,
      },
      campaign_delivery: {
        ...defaultData.campaign_delivery,
        ...(campaignDelivery
          ? {
              ...campaignDelivery,
              milestone_deadline_type: {
                value: campaignDelivery?.milestone_deadline_type,
                label: t(
                  `common.field.${campaignDelivery?.milestone_deadline_type}`
                ),
              },
              milestones: campaignDelivery.milestones.map(milestone => ({
                ...milestone,
                id: milestone.id,
              })),
            }
          : {}),
      },
      campaign_files: campaignFiles,
    };
  };

  const convertMilestonesRequestBody = ({
    milestones,
    campaignEndDate,
    milestoneDeadlineType,
  }: {
    milestones: Milestone[];
    campaignEndDate: string | null;
    milestoneDeadlineType: MilestoneType;
  }) => {
    const isMonthlyMilestones = milestoneDeadlineType === MilestoneType.monthly;
    const updatedMilestones = isMonthlyMilestones
      ? milestones.map(milestone => {
          const milestonesMaxDeliveryDate = campaignEndDate
            ? dayjs(campaignEndDate)
            : null;
          const milestonesMaxDeliveryDateMonth =
            milestonesMaxDeliveryDate && milestonesMaxDeliveryDate.month();
          const milestonesMaxDeliveryDateYear =
            milestonesMaxDeliveryDate && milestonesMaxDeliveryDate.year();
          const milestoneMonth = dayjs(milestone.delivery_date).month();
          const milestoneYear = dayjs(milestone.delivery_date).year();

          const milestoneDefaultData = {
            ...milestone,
            ...(milestone.id ? { id: Number(milestone.id) } : {}),
          };

          if (
            milestoneYear === milestonesMaxDeliveryDateYear &&
            milestonesMaxDeliveryDateMonth === milestoneMonth
          ) {
            return {
              ...milestoneDefaultData,
              delivery_date: milestonesMaxDeliveryDate
                ? milestonesMaxDeliveryDate
                    .subtract(MILESTONE_END_DATE_DIFFERENCE, 'day')
                    .format('YYYY-MM-DD')
                : null,
            };
          }

          return {
            ...milestoneDefaultData,
            delivery_date: dayjs(milestone.delivery_date).format('YYYY-MM-DD'),
          };
        })
      : milestones;

    return updatedMilestones;
  };

  const convertRequestBody = (
    {
      campaign_details: campaignDetails,
      campaign_specification: campaignSpecification,
      campaign_delivery: campaignDelivery,
      campaign_files: campaignFiles,
      id,
    }: CreateCampaignValues,
    step: CampaignCreatingStep
  ): Omit<CampaignResponse, 'balance'> => {
    const convertSelectOptions = <T extends string>(
      options: { value: T; label: string }[]
    ) => options.map(option => option.value);

    const countries = campaignSpecification.geography?.map(country =>
      'iso2' in country ? country.iso2 : country.key
    );

    const jobTitles =
      campaignSpecification?.job_titles?.length &&
      campaignSpecification?.job_titles[0].value === selectAllOptionValue &&
      [];

    const convertCustomOption = (option: {
      from: number;
      to: number | string;
    }) => `${option.from}_${Number(option.to) ? Number(option.to) : MAX}`;

    return {
      id,
      campaign_details: {
        ...campaignDetails,
        creating_step: step,
        delivery_emails: convertSelectOptions(
          campaignDetails.delivery_emails || []
        ),
        owner_user_group: campaignDetails.owner_user_group
          ? campaignDetails.owner_user_group.value.id
          : null,
        is_casl_compliant: campaignDetails.is_casl_compliant || false,
        is_gdpr_compliant: campaignDetails.is_gdpr_compliant || false,
        asset_distribution:
          (campaignFiles?.assets?.length || 0) > 1
            ? campaignDetails.asset_distribution
            : null,
      },
      campaign_specification: {
        ...campaignSpecification,
        job_titles:
          jobTitles ||
          convertSelectOptions(campaignSpecification?.job_titles || []),
        management_level: convertSelectOptions(
          campaignSpecification?.management_level || []
        ),
        industry: convertSelectOptions(campaignSpecification?.industry || []),
        size: [
          ...convertSelectOptions(campaignSpecification.size || []),
          ...campaignSpecification.custom_size?.map(convertCustomOption),
        ],
        revenue: [
          ...convertSelectOptions(campaignSpecification.revenue || []),
          ...campaignSpecification.custom_revenue?.map(convertCustomOption),
        ],
        geography: countries,
      },
      campaign_delivery: {
        ...campaignDelivery,
        start_date: campaignDelivery.start_date
          ? campaignDelivery.start_date
          : null,
        end_date: campaignDelivery.end_date ? campaignDelivery.end_date : null,
        milestone_deadline_type:
          campaignDelivery?.milestone_deadline_type.value,
        milestones: convertMilestonesRequestBody({
          // @ts-ignore
          milestones: campaignDelivery.milestones,
          milestoneDeadlineType: campaignDelivery.milestone_deadline_type.value,
          campaignEndDate: campaignDelivery.end_date,
        }),
      },
    };
  };

  const convertSALResponse = (salFile: SALFileResponse) =>
    parseSALFile(salFile);

  const convertSALRequestBody = (salFile: Partial<SALFile>) => ({
    ...salFile,
    last_name: salFile.last_name ? salFile.last_name.value : null,
    first_name: salFile.first_name ? salFile.first_name.value : null,
    company_name: salFile.company_name ? salFile.company_name.value : null,
    company_domain: salFile.company_domain
      ? salFile.company_domain.value
      : null,
    email: salFile.email ? salFile.email.value : null,
    phone: salFile.phone ? salFile.phone.value : null,
  });

  const convertTALResponse = (talFile: TALFileResponse) =>
    parseTALFileResponse(talFile);

  const convertTALRequestBody = (talFile: Partial<TALFile>) => ({
    ...talFile,
    company_name: talFile.company_name?.value,
    company_domain: talFile.company_domain?.value,
  });

  const convertReportMappingRequestBody = (
    file: ReportTemplate
  ): ReportTemplateResponse => {
    const requestBody = { ...file };
    file.custom_attributes?.forEach(attribute => {
      if (attribute.name) {
        const translation = `common.field.${attribute.name.replace(/_/g, '-')}`;
        const isExists = i18n.exists(translation);

        requestBody[attribute.name as keyof ReportTemplateResponse] = t(
          isExists ? translation : attribute.name
        );
      }
    });

    return requestBody;
  };

  const convertReportTemplateResponse = (
    fileResponse: ReportTemplateResponse
  ): ReportTemplate => {
    const reportValues = {
      ...fileResponse,
      custom_attributes: [] as { name: string }[],
    };
    const entries = Object.entries(fileResponse);

    entries.forEach(([key, value]) => {
      const isCustomAttribute =
        typeof value === 'string' &&
        allReportMappingOptions.includes(key) &&
        !fileResponse.headers.includes(value);

      if (isCustomAttribute) {
        reportValues.custom_attributes.push({ name: value });
      }
    });

    return reportValues;
  };

  const convertMilestoneDate = (
    milestoneDeadlineType: MilestoneType,
    deliveryDate: string | null,
    isLastMilestone: boolean,
    fullDateFormat = 'DD/MM/YYYY',
    shortDateFormat = 'MMM YYYY'
  ) => {
    const isMonthly = milestoneDeadlineType === MilestoneType.monthly;
    const isShowFullDate = (isMonthly && isLastMilestone) || !isMonthly;

    return dayjs(deliveryDate)
      .locale(i18n.language)
      .format(isShowFullDate ? fullDateFormat : shortDateFormat);
  };

  const convertGeography = (countriesList?: (string | Country)[]) => {
    return (
      !!countriesList?.length &&
      countriesList.map(country => {
        const countryDisplayNames = new Intl.DisplayNames([i18n.language], {
          type: 'region',
        });

        const nameFromSelect =
          typeof country !== 'string' &&
          'iso2' in country &&
          countryDisplayNames.of(country.iso2);
        const isCountryISOCode =
          typeof country === 'string' &&
          country.length === 2 &&
          countryDisplayNames.of(country);
        const isRegionString =
          typeof country === 'string' &&
          country.length !== 2 &&
          t(`region.states.${country}`);
        const isRegion =
          typeof country !== 'string' &&
          'key' in country &&
          t(`region.states.${country.key}`);

        const value =
          nameFromSelect ||
          isRegionString ||
          isCountryISOCode ||
          isRegion ||
          country.toString();

        return value;
      })
    );
  };

  const convertAssetFileResponse = (asset: CampaignAssetFileResponse) => {
    return {
      ...asset,
      asset_id: asset.id,
      html_template_privacy_is_default: asset.html_template_privacy_is_default
        ? CustomDefaultValue.default
        : CustomDefaultValue.custom,
      html_template_terms_is_default: asset.html_template_terms_is_default
        ? CustomDefaultValue.default
        : CustomDefaultValue.custom,
    };
  };

  const convertScriptResponse = (
    script: CampaignScript
  ): CampaignScriptValues => {
    const caslDefaultValue =
      script.is_casl_compliant &&
      !script.compliance_casl_block &&
      createComplianceTemplate('CASL', t);
    const gdprDefaultValue =
      script.is_gdpr_compliant &&
      !script.compliance_gdpr_block &&
      createComplianceTemplate('GDPR', t);

    return {
      ...script,
      introduction_block: script.introduction_block
        ? convertHTMLToEditorData(script.introduction_block)
        : createIntroductionTemplate(t),
      asset_block: script.asset_block
        ? convertHTMLToEditorData(script.asset_block)
        : createWhitepaperTemplate(t),
      closing_block: script.closing_block
        ? convertHTMLToEditorData(script.closing_block)
        : createClosingTemplate(t),
      compliance_gdpr_block:
        gdprDefaultValue ||
        (script.compliance_gdpr_block
          ? convertHTMLToEditorData(script.compliance_gdpr_block)
          : undefined),
      compliance_casl_block:
        caslDefaultValue ||
        (script.compliance_casl_block
          ? convertHTMLToEditorData(script.compliance_casl_block)
          : undefined),
      notes_block: script.notes_block
        ? convertHTMLToEditorData(script.notes_block)
        : undefined,
      questions: script.questions.map(question => ({
        ...question,
        name: convertHTMLToEditorData(question.name),
        answers: question.answers.map(answer => ({
          ...answer,
          name: convertHTMLToEditorData(answer.name),
        })),
      })),
    };
  };

  const convertCampaignScriptRequestBody = (
    scriptValues: CampaignScriptValues
  ): CampaignScript => {
    return {
      ...scriptValues,
      introduction_block: convertEditorDataToHTML(
        scriptValues.introduction_block
      ),
      asset_block: convertEditorDataToHTML(scriptValues.asset_block),
      closing_block: convertEditorDataToHTML(scriptValues.closing_block),
      compliance_gdpr_block: scriptValues.compliance_gdpr_block
        ? convertEditorDataToHTML(scriptValues.compliance_gdpr_block)
        : null,
      compliance_casl_block: scriptValues.compliance_casl_block
        ? convertEditorDataToHTML(scriptValues.compliance_casl_block)
        : null,
      notes_block: scriptValues.notes_block
        ? convertEditorDataToHTML(scriptValues.notes_block)
        : undefined,
      script_text: createScriptHTMLForPDF(scriptValues),
      questions: scriptValues.questions.map(question => ({
        ...question,
        name: convertEditorDataToHTML(question.name),
        answers: question.answers.map(answer => ({
          ...answer,
          is_other: answer.is_other || false,
          name: convertEditorDataToHTML(answer.name),
        })),
      })),
    };
  };

  return {
    convertResponse,
    convertSALResponse,
    convertTALResponse,
    defaultData,
    convertRequestBody,
    convertReportMappingRequestBody,
    convertReportTemplateResponse,
    convertSALRequestBody,
    convertTALRequestBody,
    convertMilestoneDate,
    convertMilestonesRequestBody,
    convertGeography,
    convertAssetFileResponse,
    convertScriptResponse,
    convertCampaignScriptRequestBody,
  };
};

export default useConvertCampaignData;
