import { useTranslation } from 'react-i18next';

import type { TwoFADevice } from 'components/TwoFA/models';

import Button from 'components/common/Button';
import Error from 'components/common/Error';

import useTimer from 'hooks/useTimer';
import useSendOTPCode from 'hooks/api/useSendOTPCode';

import getResponseError from 'helpers/getResponseError';

type Props = {
  device: TwoFADevice;
  className?: string;
  isBigButton?: boolean;
};

const ResendCode = ({ className, device, isBigButton }: Props) => {
  const { t } = useTranslation();
  const { timeLeft, setTimeLeft } = useTimer(60);
  const { mutate, error } = useSendOTPCode();

  const resendCodeClickHandler = () => {
    setTimeLeft(60);
    mutate(device);
  };

  return (
    <>
      <Button
        onClick={resendCodeClickHandler}
        disabled={timeLeft > 0}
        className={className}
        isDarkBlueBordered
        isBig={isBigButton}
      >
        {t('auth.re-send-code')} {timeLeft > 0 ? `(${timeLeft})` : null}
      </Button>
      <Error message={error ? getResponseError(error) : ''} />
    </>
  );
};

export default ResendCode;
