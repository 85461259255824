import { useMutation, useQueryClient } from 'react-query';

import type { AxiosError } from 'axios';
import type { ReportTemplateResponse } from 'types/models';

import useAuth from 'contexts/AuthContext';
import useConvertCampaignData from 'hooks/useConvertCampaignData';

const useSaveReportTemplate = (campaignId?: number | string) => {
  const { axios } = useAuth();
  const queryClient = useQueryClient();
  const { convertReportMappingRequestBody } = useConvertCampaignData();

  const mutation = useMutation<
    ReportTemplateResponse,
    AxiosError,
    ReportTemplateResponse
  >(
    async reportTemplate => {
      try {
        const requestBody = convertReportMappingRequestBody({
          ...reportTemplate,
        }) as Partial<ReportTemplateResponse>;
        delete requestBody.report_template;
        const { data } = await axios.patch<ReportTemplateResponse>(
          `/campaigns/${campaignId}/report-template-mapping/`,
          requestBody
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: data => {
        queryClient.setQueriesData(
          ['campaign', campaignId?.toString(), 'report-mapping'],
          data
        );
      },
    }
  );

  return mutation;
};

export default useSaveReportTemplate;
