import React from 'react';

import styles from './index.module.scss';

interface RadioButtonProps
  extends React.ButtonHTMLAttributes<HTMLInputElement> {
  label: string;
}

type Props = {
  radioProps: RadioButtonProps[];
};

const RadioButtonsTab = ({ radioProps }: Props) => {
  return (
    <div className={styles.radio}>
      {radioProps.map(({ id, label, ...props }) => (
        <label htmlFor={id} key={id}>
          <input type="radio" id={id} {...props} />
          <span className={styles.label}>{label}</span>
        </label>
      ))}
    </div>
  );
};

export default RadioButtonsTab;
