import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';

import type { AxiosError } from 'axios';
import type { ReportResponse } from 'types/models';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import Button from 'components/common/Button';

import UploadedReport from 'components/CampaignManage/VendorSettings/UploadedReport';

import IconSVG from 'components/UI/IconSVG';

import useAuth from 'contexts/AuthContext';
import useAsideContext from 'contexts/AsideContext';
import { useGetUploadedReport } from 'components/CampaignManage/VendorSettings/useGetUploadedReport';

import { IconsNames, ReportUploadStatus } from 'constants/constants';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

type Props = {
  campaignId?: number;
  setParentProcessedFileId: React.Dispatch<React.SetStateAction<number | null>>;
};

const ReportModal = ({ campaignId, setParentProcessedFileId }: Props) => {
  const [processedFileId, setProcessedFileId] = useState<null | number>(null);
  const { t } = useTranslation();
  const { axios } = useAuth();
  const { setAsideContent } = useAsideContext();

  const { data, isLoading, error, isFetched } = useQuery<
    ReportResponse[],
    AxiosError
  >(
    ['reports', campaignId?.toString()],
    async () => {
      try {
        const result = await axios.get<ReportResponse[]>(
          `/vendor-campaigns/${campaignId}/report-uploads/`
        );
        const isFileInProgress = result.data.find(
          file =>
            file.status !== ReportUploadStatus.done &&
            file.status !== ReportUploadStatus.failed
        );
        if (isFileInProgress) {
          setProcessedFileId(isFileInProgress.id);
          setParentProcessedFileId(isFileInProgress.id);
        }

        return result.data.filter(
          file =>
            file.status === ReportUploadStatus.done ||
            file.status === ReportUploadStatus.failed
        );
      } catch (err) {
        throw err;
      }
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  const { data: uploadProgressData, isLoading: uploadProgressLoading } =
    useGetUploadedReport({
      campaignId,
      processedFileId,
      setProcessedFileId: id => {
        setProcessedFileId(id);
        setParentProcessedFileId(id);
      },
    });

  const {
    mutate,
    isLoading: isUploadLoading,
    error: isUploadError,
  } = useMutation<
    ReportResponse,
    AxiosError,
    { file: File; inputRef: React.MutableRefObject<HTMLInputElement> }
  >(
    async ({ file }) => {
      try {
        const formData = new FormData();
        formData.append('report_file', file);

        const result = await axios.post<ReportResponse>(
          `/vendor-campaigns/${campaignId}/report-uploads/`,
          formData
        );

        return result.data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: file => {
        setProcessedFileId(file.id);
        setParentProcessedFileId(file.id);
      },
      onMutate: ({ inputRef }) => {
        inputRef.current.value = '';
      },
    }
  );
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,

    onDrop: ([file]) =>
      // @ts-ignore
      mutate({ file, inputRef: getInputProps().ref }),
    disabled: isLoading || isUploadLoading || uploadProgressLoading,
    accept: [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
  });

  return (
    <>
      <div className={styles.header}>
        <h3>{t('reports.reports')}</h3>
        <button
          type="button"
          className={styles.close}
          onClick={() => setAsideContent(null)}
        >
          <IconSVG name={IconsNames.close} />
        </button>
      </div>
      {!isLoading && (
        <Button>
          <label htmlFor="upload-report" className={styles.button}>
            <input type="file" id="upload-report" {...getInputProps()} />
            {t('common.button.upload-report')}
            {(isUploadLoading || uploadProgressLoading) && (
              <IconSVG name={IconsNames.spinner} className={styles.spinner} />
            )}
          </label>
        </Button>
      )}

      {uploadProgressData && processedFileId && (
        <div className={styles.uploading}>
          <UploadedReport data={uploadProgressData} />
        </div>
      )}

      {isUploadError && <Error message={getResponseError(isUploadError)} />}

      {!!data?.length && (
        <ul className={styles.list}>
          {data?.map(report => (
            <li key={report.id}>
              <UploadedReport data={report} />
            </li>
          ))}
        </ul>
      )}

      {isLoading && (
        <div className={styles.dropzone}>
          <Loader />
        </div>
      )}

      {isFetched && !data?.length && !uploadProgressData && (
        <div {...getRootProps()} className={styles.dropzone}>
          <span className={styles.image}>
            <IconSVG name={IconsNames.document_download} />
          </span>
          <p className={styles.work}>{t('reports.work-better')}</p>
        </div>
      )}

      {error && <Error message={getResponseError(error)} />}
    </>
  );
};

export default ReportModal;
