import cn from 'classnames';

import IconSVG from 'components/UI/IconSVG';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  iconName?: IconsNames;
  title?: string;
  className?: string;
};

const BlockWrapper: React.FC<Props> = ({
  iconName,
  title,
  children,
  className,
}) => (
  <div className={cn(styles.wrapper, className)}>
    {title && iconName && (
      <h3 className={styles.title}>
        <IconSVG name={iconName} /> {title}
      </h3>
    )}
    {children}
  </div>
);

export default BlockWrapper;
