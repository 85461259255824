import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { UseQueryResult } from 'react-query';
import type { AxiosError } from 'axios';
import type {
  AssetValues,
  CreateCampaignValues,
} from 'components/CreateCampaign/models';
import type { CampaignAssetFileResponse, CampaignResponse } from 'types/models';

import Loader from 'components/common/LoaderScreen/Loader';
import Button from 'components/common/Button';

import getLongStringView from 'helpers/getLongStringView';
import { getAssetLink } from 'helpers/assets';

import styles from './index.module.scss';

type Props = {
  details:
    | CreateCampaignValues['campaign_details']
    | CampaignResponse['campaign_details'];
  assets?: AssetValues[] | CampaignAssetFileResponse[];
  getAssetsRequestSettings: UseQueryResult<
    CampaignAssetFileResponse[],
    AxiosError
  >;
};

const Asset = ({
  details: { asset_distribution: assetsDistribution },
  assets,
  getAssetsRequestSettings: { isLoading, data },
}: Props) => {
  const { t } = useTranslation();

  const assetsData = !!data?.length ? data : assets;
  const isAssetDistributionVisible =
    assetsDistribution && (assetsData?.length || 0) > 1;

  return (
    <>
      <h4 className={styles.subheading}>{t('common.field.asset')}</h4>
      {isAssetDistributionVisible && (
        <h5 className={styles.title}>
          {t('common.field.distribution')}:
          <span className={styles.text}>
            {assetsDistribution
              ? t(`asset-distribution.${assetsDistribution}`)
              : '—'}
          </span>
        </h5>
      )}
      {isLoading && <Loader className={styles.loader} />}
      <ul className={styles.assets}>
        {!!assetsData?.length
          ? assetsData.map(asset => {
              const {
                title,
                description,
                distribution_value: value,
                attached_file_name: name,
                attached_file_link: attachedLink,
                type,
                product,
                product_name: productName,
              } = asset;

              const assetLink = getAssetLink(asset);

              return (
                <li
                  key={title}
                  className={cn(styles.assets__item, styles.item)}
                >
                  <p className={styles.text}>
                    <span>{t('common.field.asset-name')}:</span>
                    {title}
                  </p>
                  <p className={styles.text}>
                    <span>{t('common.field.asset-description')}:</span>
                    {description}
                  </p>
                  <p className={styles.text}>
                    <span>{t('common.field.asset-type')}:</span>
                    {t(`common.field.${type}`)}
                  </p>
                  {!!value && (
                    <p className={styles.text}>
                      <span>{t('common.field.asset-distribution')}:</span>
                      {value}
                    </p>
                  )}
                  {!!product && (
                    <p className={styles.text}>
                      <span>{t('common.field.product-page')}:</span>
                      {productName}
                    </p>
                  )}
                  <div className={styles.center}>
                    <a
                      className={styles.link}
                      href={assetLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className={styles.assets__link}>
                        {getLongStringView(
                          name || attachedLink || productName || '',
                          30
                        )}
                      </Button>
                    </a>
                  </div>
                </li>
              );
            })
          : t('campaign.no-assets')}
      </ul>
    </>
  );
};

export default Asset;
