import { useState, useMemo, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import FilterCard from 'components/common/ReportedLeadsTable/LeadDetailsFilter/FilterCard';
import FilterPill from 'components/common/ReportedLeadsTable/LeadDetailsFilter/FilterPill';
import FilterListItem from 'components/common/ReportedLeadsTable/LeadDetailsFilter/FilterListItem';
import Button from 'components/common/Button';

import { IconsNames } from 'constants/constants';

import { filterListLeadDetailsItems } from 'constants/selectOptions';

import useEscapeEvent from 'hooks/useEscapeEvent';

import styles from './index.module.scss';

export type FilterItem = {
  id: string;
  label: string;
  value?: string;
  type?: string;
};

type LeadDetailsFilterProps = {
  filterList: FilterItem[];
  setFilterList: (newFilter: FilterItem[]) => void;
  className?: string;
};

const LeadDetailsFilter = ({
  filterList,
  setFilterList,
  className,
}: LeadDetailsFilterProps) => {
  const { t } = useTranslation();
  const [isOptionsListVisible, setIsOptionsListVisible] =
    useState<boolean>(false);
  const [showFilterCard, setShowFilterCard] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterItem>({
    id: '',
    label: '',
  });
  const listRef = useRef(null);
  useEscapeEvent(() => setIsOptionsListVisible(false));
  useClickAway(listRef, () => setIsOptionsListVisible(false));
  const showListHandler = () => setIsOptionsListVisible(prev => !prev);

  const filterWithoutActive: FilterItem[] = useMemo(
    () =>
      filterListLeadDetailsItems.filter(
        item =>
          !filterList.find(filterListItem => item.id === filterListItem.id)
      ),
    [filterList]
  );

  const selectListItemHandler = (filterItem: FilterItem) => {
    setSelectedFilter(filterItem);
    setIsOptionsListVisible(false);
    setShowFilterCard(prev => !prev);
  };

  const closeFilterCardHandler = () => {
    setShowFilterCard(false);
  };

  const removeFilterHandler = (id: string) => {
    const newFilterList = filterList.filter(item => item.id !== id);
    setFilterList(newFilterList);
  };

  const selectFilterToListHandler = (newFilter: FilterItem) => {
    if (filterList.some(item => item.id === newFilter.id)) {
      const filterListWithoutCurrentItem = filterList.filter(
        item => item.id !== newFilter.id
      );

      const newFilterList = [...filterListWithoutCurrentItem, newFilter];
      setFilterList(newFilterList);
    } else {
      const newFilterList = [...filterList, newFilter];
      setFilterList(newFilterList);
    }
  };

  const editFilterHandler = (editFilter: FilterItem) => {
    setSelectedFilter(editFilter);
    setShowFilterCard(true);
  };

  return (
    <div className={cn(styles.wrapper, className)}>
      {filterList.length > 0 &&
        filterList.map(item => (
          <FilterPill
            item={item}
            key={item.id}
            editHandler={editFilterHandler}
            removeHandler={removeFilterHandler}
          />
        ))}
      <div className={styles.add}>
        <Button
          onClick={showListHandler}
          type="button"
          className={styles.add_button}
          white
          iconProps={{ name: IconsNames.plus }}
        >
          {t('common.button.add-filter')}
        </Button>
        <ul
          className={cn(styles.add_list, {
            [styles.show]: isOptionsListVisible,
          })}
          ref={listRef}
        >
          {filterWithoutActive.map(item => (
            <FilterListItem
              key={item.id}
              item={item}
              selectHandler={selectListItemHandler}
            />
          ))}
        </ul>
        <FilterCard
          applyHandler={selectFilterToListHandler}
          closeHandler={closeFilterCardHandler}
          filterItem={selectedFilter}
          active={showFilterCard}
        />
      </div>
    </div>
  );
};

export default LeadDetailsFilter;
