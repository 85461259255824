import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ToastContainer, Zoom } from 'react-toastify';

import { TwoFATypes, TwoFADevice } from 'components/TwoFA/models';

import IconSVG from 'components/UI/IconSVG';
import DeactivateDeviceModal from 'components/Profile/Security/DeactivateDeviceModal';
import ChangePasswordModal from 'components/Profile/Security/ChangePasswordModal';
import AppAuth from 'components/Profile/Security/AppAuth';
import EmailAuth from 'components/Profile/Security/EmailAuth';
import SmsAuth from './SmsAuth';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import { getDevicesDictionary } from 'helpers/twoFA';

import { IconsNames } from 'constants/constants';

import 'react-toastify/dist/ReactToastify.css';
import styles from './index.module.scss';

const getIconName = (type: TwoFATypes) => {
  switch (type) {
    case TwoFATypes.email:
      return IconsNames.email;
    case TwoFATypes.sms:
      return IconsNames.phone;
    case TwoFATypes.totp:
      return IconsNames.mobile;

    default:
      return IconsNames.email;
  }
};

const ProfileSecurity = () => {
  const { user } = useAuth();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const devicesDictionary = getDevicesDictionary(user?.devices);
  const devicesTypes =
    devicesDictionary && (Object.keys(devicesDictionary) as TwoFATypes[]);
  const allActiveDevicesLength = user?.devices.filter(
    device => device.confirmed
  ).length;

  const handleDeactivate = (type: TwoFATypes, devices: TwoFADevice[]) => {
    openModal({
      Content: <DeactivateDeviceModal devices={devices} type={type} />,
    });
  };

  const handleCreateNewDevice = (type: TwoFATypes) => {
    switch (type) {
      case TwoFATypes.totp:
        openModal({
          Content: <AppAuth />,
        });
        return;
      case TwoFATypes.email:
        openModal({
          Content: <EmailAuth />,
        });
        return;
      case TwoFATypes.sms:
        openModal({
          Content: <SmsAuth />,
        });
        return;
    }
  };

  const handleChangePassword = () => {
    openModal({
      Content: <ChangePasswordModal />,
    });
  };

  return (
    <div className={styles.container}>
      <ToastContainer transition={Zoom} />
      <h3 className={styles.title}>{t('profile.otp')}</h3>
      {devicesDictionary &&
        devicesTypes?.map(type => {
          const isActiveDevices = !!devicesDictionary[type].filter(
            device => device.confirmed
          ).length;

          return (
            <div key={type} className={styles.card}>
              <div className={styles.left}>
                <IconSVG name={getIconName(type)} />
                <span>{t(`common.field.${type}`)}</span>
              </div>
              <button
                className={styles.button}
                disabled={isActiveDevices && allActiveDevicesLength === 1}
                onClick={() =>
                  !isActiveDevices
                    ? handleCreateNewDevice(type)
                    : handleDeactivate(type, devicesDictionary[type])
                }
              >
                <IconSVG
                  name={isActiveDevices ? IconsNames.close : IconsNames.plus}
                />
                {!isActiveDevices
                  ? t('common.button.connect')
                  : t('common.button.delete')}
              </button>
            </div>
          );
        })}
      <h3 className={styles.title}>{t('common.field.password')}</h3>
      <div className={styles.card}>
        <div className={styles.left}>
          <IconSVG name={IconsNames.lock} />
          <span>{t('common.field.password')}</span>
        </div>
        <button
          className={cn(styles.button, styles.password)}
          onClick={handleChangePassword}
        >
          <IconSVG name={IconsNames.edit} />
          {t('common.button.change')}
        </button>
      </div>
    </div>
  );
};

export default ProfileSecurity;
