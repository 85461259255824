import { useQuery } from 'react-query';

import type { UseFormSetValue } from 'react-hook-form';
import type { AxiosError } from 'axios';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { LeadFileResponse } from 'types/models';

import useAuth from 'contexts/AuthContext';

const useGetLeadFile = ({
  campaign,
  setFormValue,
}: {
  campaign: CreateCampaignValues;
  setFormValue: UseFormSetValue<CreateCampaignValues>;
}) => {
  const { axios } = useAuth();

  const getLeadFileRequest = useQuery<LeadFileResponse | undefined, AxiosError>(
    ['campaign', campaign.id, 'lead-file'],
    async () => {
      try {
        const { data } = await axios.get<LeadFileResponse>(
          `/campaigns/${campaign.id}/lead-mapping/`
        );

        return data;
      } catch (error) {
        if ((error as AxiosError).response?.status !== 404) throw error;
      }
    },
    {
      enabled: !!campaign?.id && !campaign.isJustCreated,
      retry: (count, error) => error.response?.status !== 404,
      refetchOnWindowFocus: false,
      onSuccess: leadFile => {
        setFormValue('campaign_files.lead_file', leadFile);
      },
    }
  );

  return getLeadFileRequest;
};

export default useGetLeadFile;
