import React, { useEffect, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import cn from 'classnames';

import type {
  UseFormRegisterReturn,
  Control,
  ControllerRenderProps,
} from 'react-hook-form';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  methods: UseFormRegisterReturn | ControllerRenderProps;
  placeholder?: string;
  className?: string;
  ariaLabel?: string;
  control: Control<any>;
  additionalEffectDepth?: unknown[];
}

const TextareaAutoHeight = ({
  methods,
  placeholder,
  className,
  ariaLabel,
  control,
  additionalEffectDepth,
}: Props) => {
  const textAreaValue = useWatch({
    name: methods.name,
    control,
  });

  const placeholderRef = useRef<HTMLDivElement | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (textAreaRef.current && placeholderRef?.current) {
      placeholderRef.current.style.height = textAreaRef.current.style.height;
      textAreaRef.current.style.overflow = 'hidden';

      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef.current.scrollHeight;

      textAreaRef.current.style.height = scrollHeight + 'px';

      placeholderRef.current.style.height = '0px';
    }
  }, [
    textAreaValue,
    placeholderRef.current,
    textAreaRef.current,
    ...(additionalEffectDepth || []),
  ]);

  return (
    <>
      <textarea
        {...methods}
        ref={elem => {
          methods.ref(elem);
          textAreaRef.current = elem;
        }}
        placeholder={placeholder}
        className={cn(styles.textarea, className)}
        aria-label={ariaLabel}
      />

      <div ref={placeholderRef} />
    </>
  );
};

export default TextareaAutoHeight;
