import PageContainer from 'components/common/PageContainer';
import InvitesContent from 'components/InvitesContent';

const Invites = () => (
  <PageContainer path={['invites.heading']}>
    <InvitesContent />
  </PageContainer>
);

export default Invites;
