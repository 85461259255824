import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { CommonPaths } from 'constants/routes';

import styles from './index.module.scss';

const AcceptTerms = ({ className = '' }) => {
  const { t } = useTranslation();

  return (
    <span className={cn(styles.label, className)}>
      {t('auth.accept')}
      <Link
        target="_blank"
        to={`/${CommonPaths.DOCUMENTS}/${CommonPaths.TERMS}`}
      >
        {t('auth.terms-and-conditions')}
      </Link>
    </span>
  );
};

export default AcceptTerms;
