import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import type { CampaignResponse } from 'types/models';
import type { FormValues } from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/models';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';

import Milestone from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/SelectAsset/Milestone';
import NavigationButtons from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/NavigationButtons';

import useUpdateContactsReport from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/useUpdateContactsReport';
import { useGetVendorMilestones } from 'components/CampaignsListDeliveries/CampaignDetails/UploadContactsModal/SelectAsset/useGetVendorMilestones';

import getResponseError from 'helpers/getResponseError';

import { ReactComponent as EmptySVG } from 'assets/images/deliveries/empty.svg';

import styles from './index.module.scss';

type Props = {
  campaignId: number | string;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  stepsCount: number;
  currentStep: number;
  campaign: CampaignResponse;
};

const SelectAsset = ({
  campaignId,
  setStep,
  stepsCount,
  currentStep,
  campaign,
}: Props) => {
  const { t } = useTranslation();
  const { watch, getValues, setValue } = useFormContext<FormValues>();
  const { mutate: updateReport, isLoading: isNextStepLoading } =
    useUpdateContactsReport();

  const {
    data: milestonesData,
    isLoading,
    error,
  } = useGetVendorMilestones({ campaign });

  const handleUpdateAsset = () => {
    const { id, asset, product_asset: productAsset } = getValues();
    if (id && asset !== productAsset) {
      updateReport(
        {
          campaignId,
          fileId: id,
          product_asset: asset,
        },
        {
          onSuccess: () => {
            setValue('product_asset', asset);
            setStep(prev => prev + 1);
          },
        }
      );

      return;
    }
    setStep(prev => prev + 1);
  };

  const selectedAsset = watch('asset');
  const selectedMilestone = watch('milestone');
  const isEmptyData = !milestonesData?.length;
  const isNextStepDisabled = !selectedAsset || !selectedMilestone;

  return (
    <section
      className={cn(styles.wrapper, { [styles.disabled]: isNextStepLoading })}
    >
      <h3 className={styles.title}>{t('campaign-deliveries.select-asset')}</h3>

      <div
        className={cn(styles.content, {
          [styles.contentEmpty]: isLoading || isEmptyData,
        })}
      >
        {isLoading && (
          <div className={styles.loading}>
            <Loader size={42} />
          </div>
        )}
        {error && (
          <div className={styles.error}>
            <p className={styles.wrong}>
              {t('common.error.something-went-wrong')}
            </p>
            <Error message={getResponseError(error)} />
          </div>
        )}
        {!isLoading && isEmptyData && (
          <figure className={styles.empty}>
            <EmptySVG />
            <figcaption>
              <p>{t('campaign-deliveries.seems-empty-here')}</p>
              <p>{t('campaign-deliveries.no-files-assigned')}</p>
            </figcaption>
          </figure>
        )}

        {!isEmptyData && (
          <ul className={styles.list}>
            {milestonesData.map((milestone, index) => (
              <Milestone
                milestone={milestone}
                key={milestone.id}
                index={index}
              />
            ))}
          </ul>
        )}

        <NavigationButtons
          isNextDisabled={isNextStepDisabled}
          setStep={setStep}
          stepsCount={stepsCount}
          className={styles.navigation}
          currentStep={currentStep}
          onGoToNextStep={handleUpdateAsset}
          isNextStepLoading={isNextStepLoading}
        />
      </div>
    </section>
  );
};

export default SelectAsset;
