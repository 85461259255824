import { useForm, Controller } from 'react-hook-form';
import DayPicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import InputWrapper from 'components/common/InputWrapper';
import TimezoneSelectField from 'components/common/TimezoneFieldSelector';
import CountrySelectField from 'components/common/CountrySelectField';
import Button from 'components/common/Button';

import useAuth from 'contexts/AuthContext';

import { validationSchemaProfileGeneral } from 'utils/validations';

import useUpdateProfileData from 'components/Profile/useUpdateProfileData';

import styles from './index.module.scss';

type FormValues = {
  country_code: {
    value: string;
    label: string;
  };
  timezone: {
    value: string;
    label: string;
  };
  date_of_birth: Date | string;
};

const ProfileGeneral = () => {
  const { t, i18n } = useTranslation();
  const { mutate } = useUpdateProfileData();
  const { user } = useAuth();

  const regionNames = new Intl.DisplayNames([i18n.language], {
    type: 'region',
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(validationSchemaProfileGeneral),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      country_code: {
        value: user?.country_code || '',
        label: user?.country_code ? regionNames.of(user.country_code) : '',
      },
      timezone: {
        value: user?.timezone || '',
        label: user?.timezone || '',
      },
      date_of_birth: user?.date_of_birth
        ? dayjs(user?.date_of_birth).format('MM/DD/YYYY')
        : undefined,
    },
  });
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');

  const onSubmit = (data: FormValues) => {
    const formattedData = {
      timezone: data.timezone?.value || '',
      country_code: data.country_code?.value || '',
      date_of_birth: data.date_of_birth
        ? dayjs(data.date_of_birth).format('YYYY-MM-DD').toString()
        : null,
    };

    mutate(formattedData);
  };

  return (
    <>
      <form
        className={styles.form}
        onSubmit={methods.handleSubmit(onSubmit)}
        aria-label="profile general"
      >
        <div className={styles.container}>
          <InputWrapper label="common.field.date-of-birth" isMediumInput>
            <Controller
              name={'date_of_birth'}
              control={methods.control}
              render={({ field: { value, onChange } }) => (
                <DayPicker
                  selected={value ? dayjs(value).toDate() : undefined}
                  onChange={onChange}
                  placeholderText="MM/DD/YYYY"
                  open={false}
                  customInput={
                    <MaskedInput
                      pipe={autoCorrectedDatePipe}
                      mask={[
                        /\d/,
                        /\d/,
                        '/',
                        /\d/,
                        /\d/,
                        '/',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      keepCharPositions={true}
                      guide={true}
                    />
                  }
                />
              )}
            />
          </InputWrapper>
          <InputWrapper label="common.field.country" isMediumInput>
            <CountrySelectField
              formSettings={methods}
              name={'country_code'}
              isSmall
            />
          </InputWrapper>
          <InputWrapper label="common.field.timezone" isMediumInput>
            <TimezoneSelectField
              formSettings={methods}
              name={'timezone'}
              isSmall
            />
          </InputWrapper>
        </div>
        <Button
          disabled={!methods.formState.isDirty}
          className={cn(styles.submit, {
            [styles.disabled]: false,
          })}
          aria-label="save"
        >
          {t('common.button.save')}
        </Button>
      </form>
    </>
  );
};

export default ProfileGeneral;
