import { useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import Pagination from 'components/common/Pagination';
import Table from 'components/common/Table';
import IconSVG from 'components/UI/IconSVG';
import Loader from 'components/common/LoaderScreen/Loader';
import Button from 'components/common/Button';
import Error from 'components/common/Error';

import EmptyData from 'components/CampaignsListDeliveries/EmptyData';

import { useGetAcceptedCampaignsList } from 'components/CampaignsListDeliveries/useGetAcceptedCampaignsList';

import { IconsNames } from 'constants/constants';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

export const PAGE_SIZE = 10;

export enum TableAccessors {
  name = 'name',
  startDate = 'start-date',
  endDate = 'end-date',
  contactsUploaded = 'contacts-uploaded',
  leadsClicked = 'downloads',
  leadsPendingQC = 'leads-pending-qc',
  leadsQualified = 'leads-qualified',
  leadsDisqualified = 'leads-disqualified',
  view = 'view',
}

const CampaignsListDeliveries = () => {
  const [page, setPage] = useState(1);
  const infoRef = useRef(null);
  const { t } = useTranslation();

  const {
    data: campaigns,
    isLoading,
    error,
    dataUpdatedAt,
    isRefetching,
  } = useGetAcceptedCampaignsList(page);

  const columnsData = useMemo(
    () => [
      {
        Header: t(`common.field.${TableAccessors.name}`),
        accessor: TableAccessors.name,
      },
      {
        Header: t(`common.field.${TableAccessors.startDate}`),
        accessor: TableAccessors.startDate,
      },
      {
        Header: t(`common.field.${TableAccessors.endDate}`),
        accessor: TableAccessors.endDate,
      },
      {
        Header: t(`common.field.${TableAccessors.contactsUploaded}`),
        accessor: TableAccessors.contactsUploaded,
      },
      {
        Header: t(`common.field.${TableAccessors.leadsClicked}`),
        accessor: TableAccessors.leadsClicked,
      },
      {
        Header: t(`common.field.${TableAccessors.leadsPendingQC}`),
        accessor: TableAccessors.leadsPendingQC,
      },
      {
        Header: t(`common.field.${TableAccessors.leadsQualified}`),
        accessor: TableAccessors.leadsQualified,
      },
      {
        Header: t(`common.field.${TableAccessors.leadsDisqualified}`),
        accessor: TableAccessors.leadsDisqualified,
      },
      {
        Header: '',
        accessor: TableAccessors.view,
        Cell: ({ value }: { value: number }) => (
          <Link to={value.toString()} className={styles.details}>
            <Button
              type="button"
              white
              iconProps={{ name: IconsNames.arrow, isRightPosition: true }}
            >
              {t('common.button.view-details')}
            </Button>
          </Link>
        ),
      },
    ],
    []
  );

  const rowsData = useMemo(() => {
    return campaigns?.results.map(campaign => ({
      [TableAccessors.name]: campaign.name,
      [TableAccessors.contactsUploaded]: campaign.contacts,
      [TableAccessors.endDate]: dayjs(campaign.end_date).format('DD MMM, YYYY'),
      [TableAccessors.leadsClicked]: campaign.clicked_contacts,
      [TableAccessors.leadsDisqualified]: campaign.disqualified_leads,
      [TableAccessors.leadsPendingQC]: campaign.pending_leads,
      [TableAccessors.startDate]: dayjs(campaign.start_date).format(
        'DD MMM, YYYY'
      ),
      [TableAccessors.view]: campaign.id,
      [TableAccessors.leadsQualified]: campaign.qualified_leads,
    }));
  }, [campaigns, dataUpdatedAt]);

  return (
    <div ref={infoRef} className={styles.wrapper}>
      {(isLoading || isRefetching) && (
        <Loader className={styles.loader} size={24} />
      )}
      {error && <Error message={getResponseError(error)} />}
      {!!rowsData?.length && (
        <>
          <Table
            wrapperClassName={styles.table}
            tableOptions={{
              columns: columnsData,
              data: rowsData || [],
            }}
            headClassName={styles.thead}
            rowClassName={styles.row}
            isEvenRowGrey
            noDataComponent={
              <div className={styles.empty}>
                <IconSVG
                  className={styles.icon}
                  name={IconsNames.campaignDeliveries}
                />
                <p className={styles.title}>
                  {t('campaign-deliveries.no-campaigns-assigned')}
                </p>
                <p className={styles.description}>
                  {t('campaign-deliveries.your-campaign-deliveries')}
                </p>
              </div>
            }
          />

          <Pagination
            totalCount={campaigns?.count || 0}
            pageSize={PAGE_SIZE}
            currentPage={page}
            setPage={setPage}
            scrollToRef={infoRef}
            className={styles.pagination}
          />
        </>
      )}

      {!rowsData?.length && !isLoading && (
        <EmptyData text={t('campaign-deliveries.no-campaign-deliveries-yet')} />
      )}
    </div>
  );
};

export default CampaignsListDeliveries;
