import { useFormContext, useController } from 'react-hook-form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { useDebounce } from 'react-use';

import type { AxiosError } from 'axios';
import type { SingleValue } from 'react-select';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { UserGroup } from 'types/models';

import useAuth from 'contexts/AuthContext';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import getResponseError from 'helpers/getResponseError';

const UserGroupsField = () => {
  const { control, setError, setValue, getValues } =
    useFormContext<CreateCampaignValues>();
  const { t } = useTranslation();
  const { user, axios } = useAuth();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  useDebounce(() => setDebouncedValue(searchValue), 250, [searchValue]);

  const { field } = useController({
    name: 'campaign_details.owner_user_group',
    control,
  });

  const { isLoading, data: options } = useQuery<
    { value: UserGroup; label: string }[],
    AxiosError
  >(
    ['user-groups', debouncedValue],
    async () => {
      try {
        const { data } = await axios.get<{ results: UserGroup[] }>(
          `/companies/${user?.company.id}/user-groups/?page_size=20&search=${searchValue}`
        );
        const selectOptions = data.results.length
          ? data.results.map(group => ({
              value: group,
              label: group.name,
            }))
          : [];

        return selectOptions;
      } catch (error) {
        throw error;
      }
    },
    {
      keepPreviousData: true,
      onError: err => {
        setError('campaign_details.owner_user_group', {
          message: getResponseError(err),
        });
      },
      refetchOnWindowFocus: false,
    }
  );

  const handleChange = (
    newGroupOption: SingleValue<{ value: UserGroup; label: string }>
  ) => {
    const deliveryEmails = getValues('campaign_details.delivery_emails');
    let newDeliveryEmails = deliveryEmails;

    if (newDeliveryEmails.length) {
      field.value?.value.users.forEach(val => {
        newDeliveryEmails = newDeliveryEmails.filter(
          item => item.value !== val
        );
      });
    }

    const isNewGroupValue = newGroupOption && newGroupOption !== field.value;
    if (isNewGroupValue) {
      newDeliveryEmails = [...newDeliveryEmails, ...newGroupOption.value.users]
        .map(item => (typeof item === 'string' ? item : item.value))
        .filter((item, index, self) => self.indexOf(item) === index)
        .map(item => ({
          value: item,
          label: item,
        }));
    }

    setValue('campaign_details.delivery_emails', newDeliveryEmails);
    field.onChange(newGroupOption);
  };

  return (
    <Select
      {...field}
      onChange={handleChange}
      loadingMessage={() => t('common.field.loading')}
      styles={getDefaultSelectStylesWithError({ isSmall: true })}
      placeholder={t('common.field.start-typing')}
      noOptionsMessage={() => t('user-groups.no-options')}
      isLoading={isLoading}
      options={options}
      isClearable
      onInputChange={option => setSearchValue(option)}
    />
  );
};

export default UserGroupsField;
