import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import type { AxiosError } from 'axios';
import type { CampaignLeadResponse } from 'types/models';

import Button from 'components/common/Button';
import ModalWindow from 'components/common/ModalWindow';
import LoaderScreen from 'components/common/LoaderScreen';
import ContactLeadStatus from 'components/common/ContactLeadStatus';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import { LeadStatus, IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  lead: CampaignLeadResponse;
  campaignId: number;
  isVendor?: boolean;
};

enum LeadActions {
  qualify = 'qualify',
  disqualify = 'disqualify',
}

const QualityCell = ({ lead, campaignId, isVendor = false }: Props) => {
  const { openModal, closeModal } = useModal();
  const { axios, user } = useAuth();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<void, AxiosError, LeadActions>(
    async (action: LeadActions) => {
      try {
        await axios.post(
          `/campaigns/${campaignId}/leads/${lead.id}/${action}/`
        );
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => {
        closeModal();
        await queryClient.refetchQueries('reported-leads');

        queryClient.refetchQueries([
          'campaign',
          campaignId.toString(),
          user?.current_group_name,
          user?.company.id.toString(),
        ]);
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  const openConfirmModal = ({ currentTarget: { id } }: React.MouseEvent) => {
    openModal({
      Content: (
        <ModalWindow
          title={
            <h3>
              {t('common.modal.are-you-sure')}
              <span>
                {t(`lead.${id}`)[0].toLowerCase()}
                {t(`lead.${id}`).slice(1)}
              </span>
              {lead.first_name} {lead.last_name}?
            </h3>
          }
        >
          <div className={styles.modalButtons}>
            <Button className={styles.modalButton} onClick={closeModal} white>
              {t('common.button.no')}
            </Button>
            <Button
              className={styles.modalButton}
              onClick={() => mutate(id as LeadActions)}
            >
              {t('common.button.yes')}
            </Button>
          </div>
        </ModalWindow>
      ),
    });
  };

  const isAwaitingQualification =
    lead.status === LeadStatus['wait-qualification'] ||
    lead.status === LeadStatus['qc-qualification'];

  return isAwaitingQualification && !isVendor ? (
    <div className={styles.buttons}>
      <Button
        type="button"
        className={styles.button}
        id={LeadActions.qualify}
        onClick={openConfirmModal}
        white
        iconProps={{
          name: IconsNames.thumb_up_filled,
          className: styles.qualify,
        }}
      />
      <Button
        type="button"
        className={styles.button}
        id={LeadActions.disqualify}
        iconProps={{
          name: IconsNames.thumb_up_filled,
          className: styles.disqualify,
        }}
        onClick={openConfirmModal}
        white
      />
      {isLoading && <LoaderScreen />}
    </div>
  ) : (
    <ContactLeadStatus
      status={lead.status}
      disqualifyReasons={lead.disqualify_reasons}
    />
  );
};

export default QualityCell;
