import { useSearchParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useMutation } from 'react-query';

import LoaderScreen from 'components/common/LoaderScreen';

import useAuth from 'contexts/AuthContext';

import { PrivatePaths } from 'constants/routes';

const HubspotConnect = () => {
  const [searchParams] = useSearchParams();
  const hubspotCode = searchParams.get('code');
  const { user, axios } = useAuth();
  const navigate = useNavigate();

  const { mutate: connect, isLoading: isConnectLoading } = useMutation(
    async () => {
      try {
        await axios.post(
          `/crm-integrations/api/${user?.company.id}/connect/hubspot-oauth/complete/`,
          { code: hubspotCode }
        );
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => {
        navigate(`/${PrivatePaths.INTEGRATIONS}`);
      },
    }
  );

  useEffect(() => {
    if (hubspotCode && !!user?.company.id) connect();
  }, [user?.company.id]);

  return isConnectLoading ? <LoaderScreen /> : null;
};

export default HubspotConnect;
