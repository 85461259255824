import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import type { AssetValues } from 'components/CreateCampaign/models';
import type { ProductAssetResponse } from 'types/models';
import type { AxiosError } from 'axios';

import ModalWindow from 'components/common/ModalWindow';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';
import { createAssetFormData } from 'helpers/assets';

export const useSaveProductAsset = ({
  assetSlug,
  companySlug,
  productSlug,
  productId,
  isInCampaignUse = false,
}: {
  assetSlug?: string;
  companySlug?: string | null;
  productSlug?: string | null;
  isInCampaignUse?: boolean;
  productId?: number;
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const { axios } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation<
    ProductAssetResponse,
    AxiosError,
    AssetValues & { slug?: string }
  >(
    async file => {
      try {
        const requestOptions = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };
        const formData = createAssetFormData({
          asset: {
            ...file,
            order: typeof file.order === 'number' ? file.order : 0,
          },
        });

        if (isInCampaignUse) {
          formData.delete('title');
        }

        if (!assetSlug && !file.slug) {
          const { data } = await axios.post<ProductAssetResponse>(
            `/companies/${companySlug}/products/${productSlug}/assets/`,
            formData,
            requestOptions
          );

          return data;
        }

        const { data } = await axios.patch<ProductAssetResponse>(
          `/companies/${companySlug}/products/${productSlug}/assets/${
            assetSlug || file.slug
          }/`,
          formData,
          requestOptions
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async asset => {
        await queryClient.refetchQueries<ProductAssetResponse[]>([
          'get-product-assets',
          asset.content_type,
          companySlug,
          productId,
        ]);

        closeModal();
      },
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  return mutation;
};
