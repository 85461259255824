import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import type { AssetValues } from 'components/CreateCampaign/models';

import ModalWindow from 'components/common/ModalWindow';
import Steps from 'components/common/Steps';
import Button from 'components/common/Button';
import Select from 'components/CreateCampaign/Step1/Asset/AddProductPageModal/Select';
import AddInfo from 'components/CreateCampaign/Step1/Asset/AddProductPageModal/AddInfo';

import useModal from 'contexts/ModalContext';

import { validationSchemaCreateAsset } from 'components/common/CreateAssetModal/validationSchema';

import { StepNavigation } from 'constants/constants';
import { AssetAttachedType } from 'constants/assets';

import styles from './index.module.scss';

type Props = {
  defaultValues?: Partial<AssetValues>;
  assetId?: string | number;
  onSubmit: (asset: AssetValues) => void;
};

enum StepName {
  'select' = 'select',
  'add-info' = 'add-info',
}

const steps = [
  {
    name: StepName.select,
    title: 'common.modal.select',
    index: 0,
    Component: Select,
  },
  {
    name: StepName['add-info'],
    title: 'common.modal.add-info',
    index: 1,
    Component: AddInfo,
  },
];

const AddProductPageModal = ({ assetId, defaultValues, onSubmit }: Props) => {
  const [step, setStep] = useState(steps[0]);
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const methods = useForm<AssetValues>({
    mode: 'onChange',
    defaultValues: defaultValues
      ? defaultValues
      : {
          asset_type: AssetAttachedType.product,
        },
    resolver: yupResolver(validationSchemaCreateAsset(false)),
  });

  const goToStep = (name: StepName | StepNavigation) => {
    if (name === StepNavigation.next) {
      setStep(steps[step.index + 1]);
      return;
    }
    const stepIndex = steps.findIndex(item => item.name === name);
    if (typeof stepIndex === 'number') setStep(steps[stepIndex]);
  };

  const handleBack = () => {
    setStep(prevStep => steps[prevStep.index - 1]);
  };

  const lastStepSubmitButtonText = assetId
    ? t('common.button.save')
    : t('common.button.add');
  const isNextButtonVisible = step.index === 0 && defaultValues?.product?.value;
  const isSubmitButtonVisible = step.index === steps.length - 1;

  return (
    <form
      onSubmit={methods.handleSubmit(onSubmit)}
      aria-label="create asset form"
    >
      <ModalWindow
        primaryTitle={`${t(
          assetId ? 'common.button.edit' : 'common.button.add'
        )} ${t('common.button.existing-product-page').toLowerCase()}`}
        className={styles.modal}
      >
        <div className={styles.wrapper}>
          <Steps<StepName>
            steps={steps}
            step={step}
            goToStep={goToStep}
            className={styles.steps}
            isVertical
          />
          <FormProvider {...methods}>
            <step.Component goToStep={goToStep} />
          </FormProvider>
        </div>
        <div className={styles.buttons}>
          <Button white type="button" onClick={closeModal}>
            {t('common.button.cancel')}
          </Button>
          {step.index > 0 && (
            <Button white type="button" onClick={handleBack}>
              {t('common.button.back')}
            </Button>
          )}
          {isSubmitButtonVisible && (
            <Button type="submit">{lastStepSubmitButtonText}</Button>
          )}
          {isNextButtonVisible && (
            <Button
              type="button"
              onClick={() => {
                goToStep(StepNavigation.next);
              }}
            >
              {t('common.button.next')}
            </Button>
          )}
        </div>
      </ModalWindow>
    </form>
  );
};

export default AddProductPageModal;
