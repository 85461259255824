import { useQuery } from 'react-query';

import type { AxiosError } from 'axios';
import type { UseQueryOptions } from 'react-query';
import type { Transaction } from 'components/PaymentsComponents/models';

import useAuth from 'contexts/AuthContext';

type PaymentsPaginationReturn = {
  items: Transaction[];
  total: number;
  size: number;
  page: number;
};

interface Props extends UseQueryOptions<PaymentsPaginationReturn, AxiosError> {
  page: number;
  pageSize: number;
}

const useGetTransactionsHistory = ({
  page,
  pageSize,
  ...queryOptions
}: Props) => {
  const { axios, user } = useAuth();

  const query = useQuery<PaymentsPaginationReturn, AxiosError>(
    [
      'transactions-history',
      user?.company.id.toString(),
      user?.current_group_name,
      page,
    ],
    async () => {
      try {
        const result = await axios.get<PaymentsPaginationReturn>(
          `/payments/transactions-history/?page_size=${
            pageSize || 10
          }&page=${page}`
        );

        return result.data;
      } catch (err) {
        throw err;
      }
    },
    {
      ...queryOptions,
      enabled: !!user?.company.id && queryOptions.enabled,
    }
  );

  return query;
};

export default useGetTransactionsHistory;
