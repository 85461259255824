import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import useAuth from 'contexts/AuthContext';

import IconSVG from 'components/UI/IconSVG';
import BeeLeadsSVG from 'assets/images/common/beeleads-logo.svg';

import { PrivatePaths } from 'constants/routes';
import { UserTypes, IconsNames } from 'constants/constants';

import { ReactComponent as HomeSVG } from 'assets/images/menu/home.svg';
import { ReactComponent as InvitesSVG } from 'assets/images/menu/invites.svg';
import { ReactComponent as ManageRequestsSVG } from 'assets/images/menu/manage-requests.svg';
import { ReactComponent as IntegrationsSVG } from 'assets/images/menu/integrations.svg';

import styles from './index.module.scss';

type Props = {
  logoSrc?: string;
  color?: string;
  readOnly?: boolean;
};

const Menu = ({ readOnly = false, color = '', logoSrc = '' }: Props) => {
  const { user } = useAuth();
  const [isOpenMobile, setOpenMobile] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setOpenMobile(!isOpenMobile);
  };

  const getLinkClasses = ({ isActive }: { isActive?: boolean }) =>
    cn(styles.link, {
      [styles.active]: isActive,
      [styles.disabled]: readOnly,
    });
  const getHomeLinkClasses = (props: { isActive?: boolean }) =>
    cn(getLinkClasses(props), { [styles.active]: readOnly });

  const isAdminRole = user?.current_group_name === UserTypes.admin;
  const isVMRole = user?.current_group_name === UserTypes.vendorManager;
  const isVendor = user?.current_group_name === UserTypes.vendor;
  const isVendorRole = user?.current_group_name === UserTypes.vendor;

  const isAdminOrVMRole = isAdminRole || isVMRole;
  const isAdminOrVendor = isAdminRole || isVendor;

  return (
    <>
      <button
        type="button"
        className={cn(styles.burger, {
          [styles['burger--open']]: isOpenMobile,
          [styles['burger--read-only']]: readOnly,
        })}
        onClick={toggleMenu}
      >
        <div
          className={cn(styles.burgerIcon, {
            [styles['burgerIcon--open']]: isOpenMobile,
          })}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div className={styles.wrapper}>
        <div
          className={cn(styles.container, {
            [styles.open]: isOpenMobile,
            [styles['container--read-only']]: readOnly,
          })}
          style={
            color
              ? { backgroundColor: `#${color.replace('#', '')}` }
              : undefined
          }
        >
          <nav className={cn(styles.nav, { [styles.static]: readOnly })}>
            <NavLink
              to={PrivatePaths.INDEX}
              className={cn(styles.logo, {
                [styles.disabled]: readOnly,
                [styles.custom]: logoSrc,
              })}
            >
              {logoSrc ? (
                <img src={logoSrc} alt="Logo" />
              ) : (
                <img src={BeeLeadsSVG} alt="" className={styles.logo} />
              )}
            </NavLink>

            <ul className={styles.list}>
              <li>
                <NavLink
                  to={PrivatePaths.INDEX}
                  className={getHomeLinkClasses}
                  onClick={toggleMenu}
                >
                  <HomeSVG className={styles.icon} />
                  <span>{t('home.heading')}</span>
                </NavLink>
              </li>
              {isAdminRole && (
                <>
                  <li>
                    <NavLink
                      to={`/${PrivatePaths.PRODUCTS}`}
                      className={getLinkClasses}
                      onClick={toggleMenu}
                    >
                      <IconSVG
                        className={styles.icon}
                        name={IconsNames.product}
                      />
                      <span>{t('manage-products.heading')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/${PrivatePaths.REQUESTS}`}
                      className={getLinkClasses}
                      onClick={toggleMenu}
                    >
                      <ManageRequestsSVG className={styles.icon} />
                      <span>{t('manage-requests.heading')}</span>
                    </NavLink>
                  </li>
                </>
              )}

              {isAdminOrVMRole && (
                <li>
                  <NavLink
                    to={`/${PrivatePaths.INVITES}`}
                    className={getLinkClasses}
                    onClick={toggleMenu}
                  >
                    <InvitesSVG className={styles.icon} />
                    <span>{t('invites.heading')}</span>
                  </NavLink>
                </li>
              )}

              {isAdminRole && (
                <>
                  <li>
                    <NavLink
                      to={`/${PrivatePaths.PAYMENTS}`}
                      className={getLinkClasses}
                      onClick={toggleMenu}
                    >
                      <IconSVG
                        className={styles.icon}
                        name={IconsNames.payments}
                      />
                      <span>{t('payments.heading')}</span>
                    </NavLink>
                  </li>
                  {/* <li>
                  <NavLink
                    to={PrivatePaths.SETTINGS}
                    className={getLinkClasses}
                    onClick={toggleMenu}
                  >
                      <IconSVG
                        className={styles.icon}
                        name={IconsNames.settings}
                      />
                    <span>{t('settings.heading')}</span>
                  </NavLink>
                </li> */}
                  <li>
                    <NavLink
                      to={`/${PrivatePaths.USER_GROUPS}`}
                      className={getLinkClasses}
                      onClick={toggleMenu}
                    >
                      <IconSVG
                        className={styles.icon}
                        name={IconsNames.product}
                      />
                      <span>{t('user-groups.heading')}</span>
                    </NavLink>
                  </li>
                </>
              )}

              {isAdminOrVendor && (
                <>
                  <li>
                    <NavLink
                      to={`/${PrivatePaths.INTEGRATIONS}`}
                      className={getLinkClasses}
                      onClick={toggleMenu}
                    >
                      <IntegrationsSVG className={styles.icon} />
                      <span>{t('integrations.heading')}</span>
                    </NavLink>
                  </li>
                </>
              )}
              {isVendorRole && (
                <>
                  <li>
                    <NavLink
                      to={`/${PrivatePaths.CAMPAIGN_DELIVERIES}`}
                      className={getLinkClasses}
                      onClick={toggleMenu}
                    >
                      <IconSVG
                        className={styles.icon}
                        name={IconsNames.campaignDeliveries}
                      />
                      <span>{t('campaign-deliveries.heading')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/${PrivatePaths.PAYOUTS}`}
                      className={getLinkClasses}
                      onClick={toggleMenu}
                    >
                      <IconSVG
                        className={styles.icon}
                        name={IconsNames.payments}
                      />
                      <span>{t('payments.payouts')}</span>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Menu;
