import { useMemo, useState, useRef, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import cn from 'classnames';
import { useClickAway } from 'react-use';
import { useTranslation } from 'react-i18next';

import type { UseControllerReturn } from 'react-hook-form';
import type {
  CreateCampaignValues,
  Country,
} from 'components/CreateCampaign/models';

import Option from 'components/CreateCampaign/Step1/Specification/RegionsSelect/Option';
import CountryItem from 'components/CreateCampaign/Step1/Specification/RegionsSelect/Country';
import IconSVG from 'components/UI/IconSVG';
import Button from 'components/common/Button';

import { IconsNames, Regions } from 'constants/constants';

import { geographyRegions } from 'constants/selectOptions';

import useEscapeEvent from 'hooks/useEscapeEvent';

import styles from './index.module.scss';

type Props = {
  onChange: (country: Country[]) => void;
  value?: Country[];
  selectedRegions: UseControllerReturn<
    CreateCampaignValues,
    'campaign_specification.selected_regions'
  >;
};

const RegionsSelect = ({
  onChange,
  value,
  selectedRegions: {
    field: { value: regions, onChange: setRegions },
  },
}: Props) => {
  const { t } = useTranslation();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isFullListVisible, setListVisibility] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (searchValue) {
      setMenuOpen(true);
    }
  }, [searchValue]);

  const containerRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useClickAway(containerRef, () => {
    if (isMenuOpen) setMenuOpen(false);
  });
  useEscapeEvent(() => setMenuOpen(false));

  const isDemoCampaign =
    useWatch({ name: 'campaign_details.cost_per_lead' }) === 0;

  const options = useMemo(() => {
    return isDemoCampaign
      ? Object.keys(geographyRegions).filter(
          item => item === Regions['us-states']
        )
      : Object.keys(geographyRegions);
  }, [isDemoCampaign]) as Array<Regions>;

  const toggleRegion = (label: Regions) => {
    const isRegionSelected = regions?.includes(label);

    let newRegions: Regions[] | undefined;
    let newCountries: Country[];

    if (isRegionSelected) {
      newRegions = regions?.filter(region => region !== label);
      newCountries = value
        ? value.filter(country => country.region !== label)
        : [];
    } else {
      newRegions = [...(regions || []), label];
      const newCountriesList = [...(value || []), ...geographyRegions[label]];

      newCountries = isDemoCampaign
        ? newCountriesList.filter(item => {
            if ('key' in item)
              return item.key !== `${Regions['us-states']}.california`;
          })
        : newCountriesList;
    }

    setRegions(newRegions);
    onChange(newCountries);
    setListVisibility(false);
  };

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  const resetValues = () => {
    onChange([]);
    setMenuOpen(false);
    setRegions([]);
  };

  const toggleCountry = (country: Country) => {
    const countryId = 'iso2' in country ? country.iso2 : country.key;

    const filteredCountries =
      value?.filter(item =>
        'iso2' in item ? item.iso2 !== countryId : item.key !== countryId
      ) || [];

    if (filteredCountries.length === value?.length) {
      filteredCountries.push(country);
    }

    onChange(filteredCountries);
  };

  const toggleCountriesListVisibility = () => {
    setListVisibility(!isFullListVisible);
  };

  const isPlaceholderVisible =
    !isMenuOpen && !value?.length && !isInputFocused && !searchValue;
  const isCountriesLength = !!value?.length;
  const isToggleButtonVisible = Number(value?.length) > 5;

  return (
    <div
      ref={containerRef}
      className={styles.wrapper}
      onClick={() => {
        inputRef.current?.focus();
        toggleMenu();
      }}
    >
      {isToggleButtonVisible && (
        <Button
          white
          type="button"
          className={styles.toggle}
          onClick={e => {
            e.stopPropagation();
            toggleCountriesListVisibility();
          }}
        >
          {isFullListVisible
            ? t('common.button.collapse')
            : t('common.button.expand')}
        </Button>
      )}
      {isPlaceholderVisible && (
        <p className={styles.placeholder}>{t('common.field.select')}</p>
      )}
      <ul
        className={cn(styles.countries, {
          [styles.countries__scroll]: !isFullListVisible,
        })}
      >
        {isCountriesLength &&
          value.map(country => (
            <CountryItem
              country={country}
              key={'key' in country ? country.key : country.iso2}
              selectedCountries={value}
              onChange={onChange}
              regions={regions}
              setRegions={setRegions}
            />
          ))}
        <li
          className={cn(styles.item, styles.inputWrapper, {
            [styles.fullHeight]: !value?.length,
          })}
        >
          <input
            type="text"
            className={styles.input}
            ref={inputRef}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            onChange={e => {
              setSearchValue(e.target.value);
            }}
          />
        </li>
      </ul>

      {isCountriesLength && (
        <div className={styles.reset} onClick={resetValues}>
          <IconSVG name={IconsNames.delete} />
        </div>
      )}
      <button type="button" className={styles.open}>
        <IconSVG name={IconsNames.select_arrow} />
      </button>

      <ul
        className={cn(styles.options, {
          [styles.options__hide]: !isMenuOpen,
        })}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {options.map(option => {
          return (
            <Option
              label={option}
              key={option}
              toggleRegion={toggleRegion}
              countries={geographyRegions[option]}
              toggleCountry={toggleCountry}
              selectedCountries={value}
              isDemoCampaign={isDemoCampaign}
              searchValue={searchValue}
            />
          );
        })}
      </ul>
      <div
        className={cn(styles.options, styles.noOptions, {
          [styles.options__hide]: !isMenuOpen,
        })}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {t('common.field.no-options')}
      </div>
    </div>
  );
};

export default RegionsSelect;
