import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleButtonVisibility = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener('scroll', handleButtonVisibility);

    return () => {
      window.removeEventListener('scroll', handleButtonVisibility);
    };
  }, []);

  return showButton ? (
    <Button
      className={styles.button}
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
      iconProps={{ name: IconsNames.arrow, className: styles.icon }}
    >
      {t('common.button.back-to-top')}
    </Button>
  ) : null;
};

export default ScrollToTopButton;
