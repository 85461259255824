import { useFormContext, useFieldArray } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import type {
  CreateCampaignStepProps,
  CreateCampaignValues,
} from 'components/CreateCampaign/models';

import Loader from 'components/common/LoaderScreen/Loader';

import FormsList from 'components/CreateCampaign/Step3/FormsList';

import useAuth from 'contexts/AuthContext';

import styles from './index.module.scss';

const CreateHTML = ({ goToStep, currentStep }: CreateCampaignStepProps) => {
  const { control, getValues } = useFormContext<CreateCampaignValues>();
  const { fields } = useFieldArray({
    control,
    name: 'campaign_files.assets',
    keyName: 'key',
  });
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const getAssetsQueryState = queryClient.getQueryState([
    'campaign',
    getValues().id.toString(),
    'assets',
    user?.company.id,
  ]);

  return (
    <div className={styles.wrapper}>
      {getAssetsQueryState?.isFetching && <Loader className={styles.loading} />}
      {!!fields?.length && (
        <FormsList
          goToStep={goToStep}
          assets={fields}
          currentStep={currentStep}
        />
      )}
    </div>
  );
};

export default CreateHTML;
