import { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import type { InvitedUser, PaginationReturn } from 'types/models';
import type {
  InfiniteData,
  InfiniteQueryObserverResult,
  FetchNextPageOptions,
} from 'react-query';
import type { AxiosError } from 'axios';

import Loader from 'components/common/LoaderScreen/Loader';
import Status from 'components/common/UserStatus';
import InvitesAction from 'components/common/InvitesAction';
import Table from 'components/common/Table';
import Rating from 'components/common/Rating';

import CampaignsModal from 'components/InvitesContent/VMUsersTable/CampaignsModal';

import useObserver from 'hooks/useObserver';

import { UserStatuses } from 'constants/constants';

import styles from './index.module.scss';

enum TableAccessors {
  date = 'date',
  name = 'name',
  email = 'email',
  campaigns = 'campaigns',
  leads = 'leads',
  averageCpl = 'average-cpl',
  deliverability = 'deliverability',
  quality = 'quality',
  user = 'user',
  status = 'status',
  action = 'action',
  vendorName = 'vendor-name',
}

type Props = {
  data: InfiniteData<PaginationReturn<InvitedUser>> | undefined;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<
    InfiniteQueryObserverResult<PaginationReturn<InvitedUser>, AxiosError>
  >;
};

const VMUsersTable = ({
  data,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
}: Props) => {
  const { t } = useTranslation();
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const rowsData = useMemo(() => {
    return data?.pages
      .map(page => page.results)
      .flat()
      .map(user => ({
        [TableAccessors.name]: `${user.first_name || ''} ${
          user.last_name || ''
        }`,
        [TableAccessors.date]: user.created_at,
        [TableAccessors.status]: user.status,
        [TableAccessors.email]: user.email,
        [TableAccessors.campaigns]: {
          campaigns: user.campaigns,
          email: user.email,
        },
        [TableAccessors.user]: user.invited_by.first_name,
        [TableAccessors.action]: {
          status: user.status,
          email: user.email,
          id: user.id,
        },
        [TableAccessors.leads]: user.leads,
        [TableAccessors.averageCpl]: user.average_cpl,
        [TableAccessors.deliverability]: user.deliverability,
        [TableAccessors.quality]: user.quality,
        [TableAccessors.vendorName]: user.display_name,
      }));
  }, [data]);

  const columnsData = useMemo(
    () => [
      {
        Header: t(`common.field.${TableAccessors.date}`),
        accessor: TableAccessors.date,
        Cell: ({ value }: { value: string }) =>
          dayjs(value).format('DD/MM/YYYY'),
      },
      {
        Header: t(`common.field.${TableAccessors.name}`),
        accessor: TableAccessors.name,
      },
      {
        Header: t(`common.field.${TableAccessors.vendorName}`),
        accessor: TableAccessors.vendorName,
      },
      {
        Header: t(`common.field.${TableAccessors.email}`),
        accessor: TableAccessors.email,
      },
      {
        Header: t(`common.field.${TableAccessors.campaigns}`),
        accessor: TableAccessors.campaigns,
        Cell: ({
          value,
        }: {
          value: { campaigns: string[]; email: string };
        }) => <CampaignsModal {...value} />,
      },
      {
        Header: t(`common.field.${TableAccessors.leads}`),
        accessor: TableAccessors.leads,
      },
      {
        Header: t(`common.field.${TableAccessors.averageCpl}`),
        accessor: TableAccessors.averageCpl,
        Cell: ({ value }: { value: number }) => value.toFixed(2),
      },
      {
        Header: t(`common.field.${TableAccessors.deliverability}`),
        accessor: TableAccessors.deliverability,
        Cell: ({ value }: { value: number }) => <Rating value={value} />,
      },
      {
        Header: t(`common.field.${TableAccessors.quality}`),
        accessor: TableAccessors.quality,
        Cell: ({ value }: { value: number }) => (value ? value.toFixed(2) : 0),
      },
      {
        Header: t(`common.field.${TableAccessors.user}`),
        accessor: TableAccessors.user,
      },
      {
        Header: t(`common.field.${TableAccessors.status}`),
        accessor: TableAccessors.status,
        Cell: ({ value }: { value: UserStatuses }) => <Status status={value} />,
      },
      {
        Header: t(`common.field.${TableAccessors.action}`),
        accessor: TableAccessors.action,
        Cell: ({
          value,
        }: {
          value: { status: UserStatuses; id: number; email: string };
        }) => <InvitesAction {...value} />,
      },
    ],
    []
  );

  const getNextPage = (isIntersecting: boolean) => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  };

  useObserver(loadMoreRef, getNextPage);

  return (
    <>
      {!!rowsData?.length && (
        <Table
          tableOptions={{ columns: columnsData, data: rowsData }}
          wrapperClassName={styles.table}
          headClassName={styles.thead}
        />
      )}
      <div ref={loadMoreRef} />
      {isFetchingNextPage && <Loader />}
      {!rowsData?.length && <p>{t('invites.no-users')}</p>}
    </>
  );
};

export default VMUsersTable;
