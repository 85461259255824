import cn from 'classnames';

import styles from './index.module.scss';

type Props = {
  title: string;
  checkboxes: React.ReactNode;
  titleClassname?: string;
};

const VariantCheckboxWrapper: React.FC<Props> = ({
  title,
  checkboxes,
  children,
  titleClassname,
}) => {
  return (
    <div className={styles.container}>
      <p className={cn(styles.title, titleClassname)}>{title}</p>
      <div className={styles.checkboxes}>{checkboxes}</div>
      {children}
    </div>
  );
};

export default VariantCheckboxWrapper;
