import { useTranslation } from 'react-i18next';
import { useForm, useController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import cn from 'classnames';

import type { AxiosError } from 'axios';

import ModalWindow from 'components/common/ModalWindow';
import InputWrapper from 'components/common/InputWrapper';
import NumberField from 'components/common/NumberField';
import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import { validationSchemaWithdrawAmount } from 'utils/validations';

import getResponseError from 'helpers/getResponseError';
import { getNumberWithCommas } from 'helpers/formatNumbers';

import styles from './index.module.scss';

type FormValues = {
  amount: number;
};

type Props = {
  isAdmin: boolean;
  vendorBalance: string;
};

const TransferFundsModal = ({ isAdmin, vendorBalance }: Props) => {
  const { axios } = useAuth();
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchemaWithdrawAmount),
    mode: 'onChange',
    defaultValues: { amount: 0 },
  });
  const { field } = useController({ control, name: 'amount' });

  const { error, mutate, isLoading, isSuccess } = useMutation<
    void,
    AxiosError,
    number
  >(async amount => {
    try {
      await axios.post('/payments/transfer-funds/', {
        amount,
      });
    } catch (err) {
      throw err;
    }
  });

  const handleFormSubmit = (data: FormValues) => {
    mutate(data.amount);
  };

  return isSuccess ? (
    <ModalWindow
      title={t('common.button.transfer-funds')}
      successMessage={t('payments.transfer-success')}
    />
  ) : (
    <ModalWindow
      primaryTitle={t('common.button.transfer-funds')}
      className={styles.wrapper}
    >
      {!isAdmin && (
        <h3 className={styles.enter}>{t('payments.enter-transfer-amount')}</h3>
      )}
      <p className={cn(styles.notice, { [styles['notice--admin']]: isAdmin })}>
        {t(isAdmin ? 'payments.funds-transferred' : 'payments.transfer-notice')}
      </p>
      {isAdmin && (
        <div className={styles.vendor}>
          <p className={styles.count}>{getNumberWithCommas(vendorBalance)}</p>
          <p className={styles.current}>{t('payments.current-balance')}</p>
        </div>
      )}
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <InputWrapper
          label={t('payments.how-much-transfer')}
          validationError={errors?.amount?.message}
          submitError={error ? getResponseError(error) : undefined}
        >
          <NumberField
            field={field}
            hasCommas
            prefix="$"
            placeholder={t('payments.transfer-amount')}
          />
        </InputWrapper>
        <div className={styles.buttons}>
          <Button type="button" white onClick={closeModal}>
            {t('common.button.cancel')}
          </Button>
          <Button type="submit" disabled={!isValid}>
            {t('common.button.transfer')}
          </Button>
        </div>
      </form>
      {isLoading && <LoaderScreen />}
    </ModalWindow>
  );
};

export default TransferFundsModal;
