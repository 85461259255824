import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ProductResponse } from 'types/models';

import Button from 'components/common/Button';

import SectionWrapper from 'components/CreateProductContent/SectionWrapper';
import AssetItem from 'components/ProductPage/AssetsList/AssetItem';

import { useGetAssetsByContentType } from 'hooks/api/useGetAssetsByContentType';

import { IconsNames } from 'constants/constants';
import { AssetContentType } from 'constants/assets';

import styles from './index.module.scss';

type Props = {
  companySlug?: string;
  contentType: AssetContentType;
  title: string;
  iconName: IconsNames;
  productData: ProductResponse;
};

const ASSETS_DEFAULT_DISPLAY_COUNT = 6;

const AssetsList = ({
  title,
  productData,
  companySlug,
  contentType,
  iconName,
}: Props) => {
  const { t } = useTranslation();
  const [isAllVisible, setIsAllVisible] = useState(false);
  const { data: assets } = useGetAssetsByContentType({
    productId: productData.id,
    productSlug: productData.slug,
    companySlug,
    contentType,
    productOrdering: productData.product_asset_ordering,
    isPublic: true,
  });

  const visibleAssets = isAllVisible
    ? assets
    : assets?.slice(0, ASSETS_DEFAULT_DISPLAY_COUNT);
  const isToggleButtonVisible =
    !!assets?.length && assets?.length > ASSETS_DEFAULT_DISPLAY_COUNT;

  return visibleAssets?.length ? (
    <SectionWrapper
      title={title}
      iconName={iconName}
      withoutBorder
      className={styles.section}
    >
      <ul className={styles.list}>
        {visibleAssets?.map(asset => (
          <AssetItem data={asset} key={asset.id} />
        ))}
      </ul>

      {isToggleButtonVisible && (
        <Button
          onClick={() => setIsAllVisible(prev => !prev)}
          white
          className={styles.expand}
        >
          {isAllVisible
            ? t('common.button.collapse')
            : t('common.button.expand')}
        </Button>
      )}
    </SectionWrapper>
  ) : null;
};

export default AssetsList;
