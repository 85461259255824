import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type {
  Control,
  UseFormSetValue,
  UseFormGetValues,
  UseFormTrigger,
} from 'react-hook-form';
import type {
  AnswerValue,
  FormValues,
} from 'components/AssetDownloadContent/models';

import RichText from 'components/RichText';

import { ScriptQuestionType } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  questionIndex: number;
  answerType: ScriptQuestionType;
  answer: AnswerValue;
  control: Control<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  answerIndex: number;
  trigger: UseFormTrigger<FormValues>;
};

const Answer = ({
  questionIndex,
  answer,
  answerType,
  control,
  answerIndex,
  getValues,
  setValue,
  trigger,
}: Props) => {
  const { t } = useTranslation();
  const { field: customAnswerField } = useController({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.custom_answer`,
  });
  const isChecked = useWatch({
    control,
    name: `questions.${questionIndex}.answers.${answerIndex}.checked`,
  });

  const handleSelectAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const questionAnswers = getValues().questions[questionIndex].answers;

    const updatedAnswers = questionAnswers.map(item => {
      const checkBoxValue =
        answer.id === item.id ? e.currentTarget.checked : item.checked;

      return {
        ...item,
        checked:
          answerType === ScriptQuestionType.radiobutton
            ? answer.id === item.id
            : checkBoxValue,
      };
    });
    setValue(`questions.${questionIndex}.answers`, updatedAnswers);
    trigger('questions');
  };

  return (
    <li className={styles.wrapper}>
      <label
        htmlFor={answer.id?.toString()}
        className={cn(styles.label, { [styles.other]: answer.is_other })}
      >
        <input
          type={
            answerType === ScriptQuestionType.checkbox ? answerType : 'radio'
          }
          id={answer.id?.toString()}
          name={questionIndex?.toString()}
          value={answer.id}
          onChange={handleSelectAnswer}
          checked={isChecked}
        />
        {answer.is_other ? (
          <span>{t('script.other')}</span>
        ) : (
          <RichText htmlString={answer.name} className={styles.name} />
        )}
      </label>

      {answer.is_other && isChecked && (
        <input
          type="text"
          {...customAnswerField}
          className={styles.custom}
          placeholder={`${t('script.other')}...`}
        />
      )}
    </li>
  );
};

export default Answer;
