import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const EditWarning = () => {
  const { t } = useTranslation();

  return <p className={styles.tooltip}>{t('script.take-into-account')}</p>;
};

export default EditWarning;
