import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { AxiosError } from 'axios';
import type { CampaignAssetFileResponse } from 'types/models';

import ModalWindow from 'components/common/ModalWindow';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';

import { UserTypes } from 'constants/constants';

const useGetAssets = (campaignId: number | string | undefined) => {
  const { axios, user } = useAuth();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const getAssetsRequestSettings = useQuery<
    CampaignAssetFileResponse[],
    AxiosError
  >(
    ['campaign', campaignId?.toString(), 'assets', user?.current_group_name],
    async () => {
      try {
        const isVendor = user?.current_group_name === UserTypes.vendor;
        const { data } = await axios.get<CampaignAssetFileResponse[]>(
          `/${
            isVendor ? 'vendor-campaigns' : 'campaigns'
          }/${campaignId}/assets/`
        );

        return data;
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: !!campaignId,
      refetchOnWindowFocus: false,
      onError: err => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={getResponseError(err)}
            />
          ),
        });
      },
    }
  );

  return getAssetsRequestSettings;
};

export default useGetAssets;
