import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useFieldArray, useController } from 'react-hook-form';
import { isMobile } from 'react-device-detect';

import type { UseMutationResult } from 'react-query';
import type {
  CampaignAssetFileResponse,
  CampaignResponse,
  OfferResponse,
} from 'types/models';
import type { AxiosError } from 'axios';
import {
  OffersFormValues,
  AssignRequestBody,
  OfferFormValues,
} from 'components/CampaignManage/models';

import NumberField from 'components/common/NumberField';
import InputWrapper from 'components/common/InputWrapper';
import OfferStatus from 'components/common/OfferStatus';
import Error from 'components/common/Error';
import ToggleSwitch from 'components/common/ToggleSwitch';
import TextareaAutoHeight from 'components/common/TextareaAutoHeight';

import MilestoneOfferItem from 'components/CampaignManage/OfferItem/VendorOfferItem/VendorMilestoneOfferItem';
import ActionButtons from 'components/CampaignManage/OfferItem/ActionButtons';
import History from 'components/CampaignManage/OfferItem/History';

import useConvertCampaignData from 'hooks/useConvertCampaignData';

import { OfferStatuses, UserTypes } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  index: number;
  status?: OfferStatuses;
  campaign: CampaignResponse;
  assets: CampaignAssetFileResponse[];
  bidMutation: UseMutationResult<
    OfferResponse,
    AxiosError,
    AssignRequestBody['values']
  >;
  acceptMutation: UseMutationResult<OfferResponse, AxiosError, number>;
  offerId?: number;
  offerInitialData?: OfferFormValues;
};

const VendorOfferItem: FC<Props> = ({
  status,
  children,
  assets,
  campaign,
  bidMutation,
  acceptMutation,
  index,
  offerId,
  offerInitialData,
}) => {
  const [isRebidActive, setIsRebidActive] = useState<boolean>(false);
  const { t } = useTranslation();
  const { convertMilestoneDate } = useConvertCampaignData();
  const formMethods = useFormContext<OffersFormValues>();
  const { fields } = useFieldArray({
    control: formMethods.control,
    name: `offers.${index}.value.campaign_offer_milestones`,
  });

  const { field: cplField } = useController({
    control: formMethods.control,
    name: `offers.${index}.value.cpl`,
  });

  const { field: vendorCplField } = useController({
    control: formMethods.control,
    name: `offers.${index}.value.cpl_vendor`,
  });

  const { field: noPreferenceField } = useController({
    name: `offers.${index}.value.no_preference`,
    control: formMethods.control,
  });

  const { field: commentField } = useController({
    name: `offers.${index}.value.comment_vendor`,
    control: formMethods.control,
  });

  const isAwaitingApproval = status === OfferStatuses['wait-vendor-approve'];
  const isCounterbidStatus = status === OfferStatuses.counterbid;

  const isAcceptActionsVisible =
    isAwaitingApproval && !isRebidActive && !isCounterbidStatus;
  const isActionButtonsVisible =
    !isMobile && (isAcceptActionsVisible || isRebidActive);

  const isEditable = !isMobile && isRebidActive;

  const lastRespondedBy = formMethods.watch(
    `offers.${index}.value.last_responded_by`
  );
  const lastComment =
    lastRespondedBy === UserTypes.vendorManager
      ? formMethods.watch(`offers.${index}.value.comment`)
      : formMethods.watch(`offers.${index}.value.comment_vendor`);

  const isOfferErrors = formMethods.formState.errors.offers?.[index]?.value;
  const milestoneError = isOfferErrors?.campaign_offer_milestones?.message;
  const isDemoCampaign = campaign.campaign_details.cost_per_lead === 0;

  return (
    <div className={styles.card}>
      <h2 className={styles.name}>{children}</h2>

      <div className={styles.cpl_wrapper}>
        <div className={styles.cpl}>
          <div className={styles.cpl_field}>
            <InputWrapper label="common.field.cpl">
              <NumberField field={cplField} placeholder="$" disabled />
            </InputWrapper>

            {status && !isRebidActive && (
              <OfferStatus status={status} isVendor className={styles.status} />
            )}
          </div>
        </div>
        {isRebidActive && (
          <div className={styles.cpl_field}>
            <InputWrapper
              label="campaign.set-new-cpl"
              isRequired
              validationError={isOfferErrors?.cpl_vendor?.message}
            >
              <NumberField field={vendorCplField} />
            </InputWrapper>
          </div>
        )}
      </div>
      {isRebidActive && (
        <InputWrapper
          label="offers.additional-comments"
          isRequired
          validationError={isOfferErrors?.comment_vendor?.message}
        >
          <TextareaAutoHeight
            placeholder={t('offers.why-rebid')}
            methods={{
              ...commentField,
              onChange: (e: ChangeEvent<HTMLTextAreaElement>) => {
                if (isOfferErrors?.comment_vendor?.message) {
                  formMethods.trigger(`offers.${index}.value.comment_vendor`);
                }
                commentField.onChange(e);
              },
            }}
            control={formMethods.control}
          />
        </InputWrapper>
      )}

      <div className={styles.milestones}>
        <div className={styles['milestones-header']}>
          {noPreferenceField.value && (
            <InputWrapper
              wrapperClasses={styles.preference}
              label="asset-distribution.no-preference"
            >
              <ToggleSwitch
                id={`offers-${index}-no-preference`}
                checked={noPreferenceField.value}
                disabled
              />
            </InputWrapper>
          )}
          {milestoneError && (
            <Error
              message={t(milestoneError)}
              className={styles['milestone-error']}
            />
          )}
        </div>
        {fields.map((field, milestoneIndex) =>
          field.milestone ? (
            <MilestoneOfferItem
              offerIndex={index}
              index={milestoneIndex}
              key={field.id}
              milestoneId={field.milestone.id}
              assets={assets}
              isEditable={isEditable}
            >
              {convertMilestoneDate(
                campaign.campaign_delivery.milestone_deadline_type,
                field.milestone.delivery_date,
                milestoneIndex === fields.length - 1
              )}
            </MilestoneOfferItem>
          ) : null
        )}
      </div>

      {isActionButtonsVisible && (
        <ActionButtons
          isAssignButtonVisible={false}
          isRebidButtonVisible={isRebidActive}
          isAcceptActionsVisible={isAcceptActionsVisible}
          offerIndex={index}
          campaignName={campaign.campaign_details.name}
          campaignId={campaign.id}
          handleAccept={id => acceptMutation?.mutate(id)}
          handleRebid={values => {
            bidMutation?.mutate(values, {
              onSettled: (_, error) => {
                if (!error) setIsRebidActive(prev => !prev);
              },
            });
          }}
          isRebidActive={isRebidActive}
          setIsRebidActive={setIsRebidActive}
          isVendor
          offerInitialData={offerInitialData}
          isDemoCampaign={isDemoCampaign}
        />
      )}
      {offerId && (
        <div className={styles.history}>
          {lastRespondedBy && lastComment && (
            <p className={styles.comment}>{lastComment}</p>
          )}

          <History
            offerId={offerId}
            campaignId={campaign.id}
            milestoneDeadlineType={
              campaign.campaign_delivery.milestone_deadline_type
            }
          />
        </div>
      )}
    </div>
  );
};

export default VendorOfferItem;
