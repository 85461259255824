import { Link } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import * as Sentry from '@sentry/react';

import type { ProductAssetResponse } from 'types/models';

import Button from 'components/common/Button';
import IconSVG from 'components/UI/IconSVG';
import Loader from 'components/common/LoaderScreen/Loader';

import { useIncreaseAssetDownloads } from 'hooks/api/useIncreaseAssetDownloads';

import { YOUTUBE_LINK_REGEXP } from 'utils/validations';

import { getLearnMoreLinkTitle } from 'helpers/assets';

import { IconsNames } from 'constants/constants';

import defaultImage from 'assets/images/product/default-image.svg';

import styles from './index.module.scss';

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
  }
}

type Props = {
  data: ProductAssetResponse;
  isModalContent?: boolean;
  companySlug?: string;
};

const YOUTUBE_PLAYER_ID = 'youtube-player';
const YOUTUBE_IFRAME_API_URL = 'https://www.youtube.com/iframe_api';

const AssetVideoPreview = ({ data, isModalContent, companySlug }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPosterVisible, setIsPosterVisible] = useState(true);

  const isYoutubeLink = !!data.attached_file_link;
  const [isLoadingYoutubeVideo, setIsLoadingYoutubeVideo] =
    useState(isYoutubeLink);

  const { mutate: increasePopularity } = useIncreaseAssetDownloads({
    companySlug,
    email: data.share_contact_data?.email,
    assetId: data.id,
  });

  const youtubeEmbedId =
    isYoutubeLink && data.attached_file_link.match(YOUTUBE_LINK_REGEXP)?.[2];

  const loadVideo = () => {
    new window.YT.Player(YOUTUBE_PLAYER_ID, {
      events: {
        onReady: () => {
          setIsLoadingYoutubeVideo(false);
        },
        onStateChange: e => {
          if (e.data === YT.PlayerState.PLAYING && isPosterVisible) {
            setIsPosterVisible(false);
            increasePopularity();
          }
        },
        onError: e => {
          Sentry.captureMessage(
            `Youtube iframe error: ${e.data}, attached Youtube link: ${data.attached_file_link}`
          );
        },
      },
      playerVars: {
        controls: 0,
        origin: window.location.origin,
      },
    });
  };

  useEffect(() => {
    if (!window.YT) {
      const iframeScriptTag = document.createElement('script');
      iframeScriptTag.src = YOUTUBE_IFRAME_API_URL;
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag?.parentNode?.insertBefore(iframeScriptTag, firstScriptTag);

      window.onYouTubeIframeAPIReady = loadVideo;
    } else {
      loadVideo();
    }
  }, []);

  const handlePlayVideo = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    setIsPosterVisible(false);
    if (videoRef.current && 'play' in videoRef.current) {
      videoRef.current?.play();
    }
  };

  return (
    <div
      className={cn(styles.container, { [styles.scrollable]: isModalContent })}
    >
      <div
        className={cn(styles.videoContainer, {
          [styles.disabled]: isLoadingYoutubeVideo,
        })}
      >
        {!isYoutubeLink ? (
          <video
            controls
            className={cn(styles.video, { [styles.visible]: !isPosterVisible })}
            ref={videoRef}
            onPlay={() => {
              if (isPosterVisible) {
                setIsPosterVisible(false);
                increasePopularity();
              }
            }}
          >
            <source src={data.attached_file} type="video/mp4" />
          </video>
        ) : (
          <iframe
            src={`https://www.youtube.com/embed/${youtubeEmbedId}?enablejsapi=1&origin=${window.location.origin}`}
            allowFullScreen
            className={cn(styles.video, { [styles.visible]: !isPosterVisible })}
            id={YOUTUBE_PLAYER_ID}
          />
        )}

        {isPosterVisible && (
          <div className={styles.preview} onClick={handlePlayVideo}>
            <img src={data.landing_image || defaultImage} />
            <span>
              {isLoadingYoutubeVideo ? (
                <Loader size={20} />
              ) : (
                <IconSVG name={IconsNames.play_arrow} />
              )}
            </span>
          </div>
        )}
      </div>

      <h1 className={styles.title}>{data.title}</h1>
      <p className={styles.description}>{data.description}</p>
      {!isModalContent && (
        <Link to=".." className={styles.toProductLink}>
          <Button transparent className={styles.button} type="button" isBig>
            {getLearnMoreLinkTitle(data.share_contact_data?.campaign)}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default AssetVideoPreview;
