import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';

import type { EmailServerResponse } from 'types/models';

import SmtpForm from 'components/IntegrationsContent/VendorContent/SmtpForm';
import EspForm from 'components/IntegrationsContent/VendorContent/EspForm';

import SimpleTooltip from 'components/UI/SimpleTooltip';

import { ServerVariants } from 'constants/constants';

import styles from './index.module.scss';

const EditableForm = (data: EmailServerResponse) => {
  const { t } = useTranslation();

  const tooltipOptions = usePopperTooltip({
    interactive: true,
    offset: [0, 20],
    placement: 'right-start',
  });

  const isSmtpForm = data.integration_type === ServerVariants.smtp;

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('integrations.form.type')}</p>
      <div className={styles.blockType}>
        <p className={styles.type} ref={tooltipOptions.setTriggerRef}>
          {isSmtpForm ? (
            <span>{t('integrations.smtp')}</span>
          ) : (
            <span>{t('integrations.esp')}</span>
          )}
        </p>
        {tooltipOptions.visible && (
          <SimpleTooltip options={tooltipOptions}>
            {t('integrations.server-cannot-changed')}
          </SimpleTooltip>
        )}
      </div>

      {isSmtpForm ? <SmtpForm data={data} /> : <EspForm data={data} />}
    </div>
  );
};

export default EditableForm;
