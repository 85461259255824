import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import cn from 'classnames';
import { useQuery } from 'react-query';

import type { AxiosError } from 'axios';
import {
  VendorTransaction,
  PayoutType,
  PaymentStatus,
} from 'components/PaymentsComponents/models';

import Table from 'components/common/Table';
import Loader from 'components/common/LoaderScreen/Loader';
import IconSVG from 'components/UI/IconSVG';

import TransactionStatus from 'components/PaymentsComponents/TransactionStatus';

import useAuth from 'contexts/AuthContext';

import { IconsNames } from 'constants/constants';

import { getNumberWithCommas } from 'helpers/formatNumbers';

import styles from './index.module.scss';

enum TableAccessors {
  id = 'id',
  payoutType = 'payout-type',
  createdAt = 'created-at',
  updatedAt = 'updated-at',
  status = 'status',
  amountCurrency = 'amount',
  createdBy = 'created-by',
  details = 'details',
  paymentMethod = 'payment-method',
}

const VendorTransactionsHistory = () => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();

  const { data, isLoading, dataUpdatedAt } = useQuery<
    VendorTransaction[],
    AxiosError
  >(
    [
      'transactions-history',
      user?.company.id.toString(),
      user?.current_group_name,
    ],
    async () => {
      try {
        const result = await axios.get<VendorTransaction[]>(
          '/payments/vendor-transactions-history/'
        );

        return result.data;
      } catch (err) {
        throw err;
      }
    },
    { enabled: !!user?.company.id }
  );

  const columnsData = useMemo(
    () => [
      {
        Header: t(`common.field.${TableAccessors.id}`),
        accessor: TableAccessors.id,
      },
      {
        Header: t(`common.field.${TableAccessors.amountCurrency}`),
        accessor: TableAccessors.amountCurrency,
        Cell: ({ value }: { value: number }) => (
          <span className={styles.amount}>
            {getNumberWithCommas(value.toFixed(2))}
          </span>
        ),
      },
      {
        Header: t(`common.field.${TableAccessors.payoutType}`),
        accessor: TableAccessors.payoutType,
      },
      {
        Header: t(`common.field.${TableAccessors.details}`),
        accessor: TableAccessors.details,
        Cell: ({ value }: { value: VendorTransaction }) => {
          return (
            <span>
              {value.payout_type === PayoutType.withdraw &&
                t('payments.bank-transfer')}
              {value.payout_type === PayoutType.leadQualify &&
                t('payments.payment-for-campaign', {
                  campaign: value.campaign.name,
                })}
              {value.payout_type === PayoutType.qcCharge &&
                t('payments.disqualify-payment')}
              {value.payout_type === PayoutType.transfer &&
                t('payments.fund-transfered-to-general')}
            </span>
          );
        },
      },
      {
        Header: t(`common.field.${TableAccessors.createdAt}`),
        accessor: TableAccessors.createdAt,
        Cell: ({ value }: { value: string }) =>
          dayjs(value).format('D MMM, YYYY'),
      },
      {
        Header: t(`common.field.${TableAccessors.updatedAt}`),
        accessor: TableAccessors.updatedAt,
        Cell: ({ value }: { value: string }) =>
          dayjs(value).format('D MMM, YYYY'),
      },
      {
        Header: t(`common.field.${TableAccessors.paymentMethod}`),
        accessor: TableAccessors.paymentMethod,
      },
      {
        Header: t(`common.field.${TableAccessors.createdBy}`),
        accessor: TableAccessors.createdBy,
      },
      {
        Header: t(`common.field.${TableAccessors.status}`),
        accessor: TableAccessors.status,
        Cell: ({ value }: { value: PaymentStatus }) => (
          <TransactionStatus status={value} isPayout />
        ),
      },
    ],
    []
  );

  const rowsData = useMemo(() => {
    return data?.map(transaction => {
      return {
        [TableAccessors.id]: transaction.id,
        [TableAccessors.payoutType]: t(
          `payments.payout-type.${transaction.payout_type}`
        ),
        [TableAccessors.paymentMethod]:
          transaction.payout_type === PayoutType.withdraw
            ? t('payments.payout-type.bank')
            : t('payments.payout-type.system'),
        [TableAccessors.createdAt]: transaction.created_at,
        [TableAccessors.updatedAt]: transaction.updated_at,
        [TableAccessors.status]: transaction.status,
        [TableAccessors.amountCurrency]:
          Number(transaction.amount_currency) ||
          Number(transaction.amount_credits),
        [TableAccessors.details]: transaction,
        [TableAccessors.createdBy]: `${transaction.created_by.first_name} ${transaction.created_by.last_name}`,
      };
    });
  }, [data, dataUpdatedAt]);

  return (
    <>
      {isLoading && <Loader className={styles.loader} />}
      {!isLoading && (
        <Table
          tableOptions={{ columns: columnsData, data: rowsData || [] }}
          wrapperClassName={styles.table}
          rowClassName={cn(styles.row, { [styles.noData]: !rowsData?.length })}
          headClassName={styles.thead}
          isFirstColumnFixed
          isEvenRowGrey
          noDataComponent={
            <div className={styles.empty}>
              <IconSVG className={styles.icon} name={IconsNames.payments} />
              <p className={styles.title}>{t('payments.no-payouts-yet')}</p>
            </div>
          }
        />
      )}
    </>
  );
};

export default VendorTransactionsHistory;
