import { useState, useMemo } from 'react';

import type { Descendant } from 'slate';

import { convertDescendantToString } from 'utils/richTextEditor';

const MAX_WARNING_SHOWS_COUNT = 1;
const WARNING_VISIBILITY_TIMEOUT = 3500;

export const useEditWarning = ({
  defaultInitialTemplate,
  currentTemplate,
}: {
  defaultInitialTemplate: Descendant[];
  currentTemplate?: Descendant[];
}) => {
  const [warningViewsCount, setWarningViewsCount] = useState(0);
  const [currentValueTemplate, setCurrentValueTemplate] =
    useState(currentTemplate);

  const stringInitialTemplate = useMemo(
    () => convertDescendantToString(defaultInitialTemplate),
    []
  );
  const stringCurrentTemplate = useMemo(
    () =>
      currentValueTemplate
        ? convertDescendantToString(currentValueTemplate)
        : '',
    [currentValueTemplate]
  );

  const isInitialTemplate = stringInitialTemplate === stringCurrentTemplate;

  const showWarningOnChange = (value: Descendant[]) => {
    if (isInitialTemplate && warningViewsCount < MAX_WARNING_SHOWS_COUNT) {
      const isUpdated =
        convertDescendantToString(value) !== stringInitialTemplate;

      if (isUpdated) {
        setWarningViewsCount(prev => prev + 1);

        setTimeout(() => {
          setWarningViewsCount(prev => prev + 1);
        }, WARNING_VISIBILITY_TIMEOUT);
      }
    }
  };

  return {
    showWarningOnChange,
    isWarningVisible: warningViewsCount === MAX_WARNING_SHOWS_COUNT,
    setCurrentValueTemplate,
  };
};
