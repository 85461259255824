import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { UseMutateFunction } from 'react-query';
import type { AxiosError } from 'axios';
import { TwoFATypes, TwoFADevice } from 'components/TwoFA/models';

import Button from 'components/common/Button';

import DeviceItem from 'components/TwoFA/TwoFASelect/DeviceItem';

import { IconsNames } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  type: TwoFATypes;
  onDeviceChange: React.Dispatch<React.SetStateAction<TwoFADevice | undefined>>;
  devicesList: TwoFADevice[];
  mutate: UseMutateFunction<TwoFADevice, AxiosError, TwoFADevice>;
};

const DevicesList = ({ type, onDeviceChange, devicesList, mutate }: Props) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const { t } = useTranslation();

  const isTOTP = type === TwoFATypes.totp;

  const handleButtonClick = isTOTP
    ? () => onDeviceChange(devicesList[0])
    : () => setIsListOpen(prev => !prev);

  return (
    <>
      <Button
        onClick={handleButtonClick}
        className={cn(styles.button, { [styles.otp]: isTOTP })}
        isDarkBlueBordered
        isBig
        iconProps={
          isTOTP
            ? undefined
            : {
                name: IconsNames.select_arrow,
                className: cn({ [styles.icon]: isListOpen }),
              }
        }
      >
        {t(`common.field.${type}`)}
      </Button>

      {isListOpen && (
        <ul className={styles.list}>
          {devicesList.map(item => (
            <DeviceItem
              sendOTP={mutate}
              device={item}
              key={item.persistent_id}
            />
          ))}
        </ul>
      )}
    </>
  );
};

export default DevicesList;
