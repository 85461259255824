import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { UseFieldArrayRemove } from 'react-hook-form';

import Loader from 'components/common/LoaderScreen/Loader';
import FileItem from 'components/common/FileItem';
import Error from 'components/common/Error';

import styles from './index.module.scss';

type Props =
  | {
      index: number;
      remove: UseFieldArrayRemove | (() => void);
      fileName: string;
      additionalField?: React.ReactNode;
      validationError?: string;
      isLoading?: boolean;
      attachedLink?: string;
      Icon?: React.ReactNode;
    }
  | {
      index: number;
      remove: UseFieldArrayRemove | (() => void);
      fileName: string;
      fileLink: string;
      additionalField?: React.ReactNode;
      validationError?: string;
      isLoading?: boolean;
      attachedLink?: string;
      Icon?: React.ReactNode;
    };

const FileCard: React.FC<Props> = props => {
  const {
    children,
    index,
    remove,
    additionalField,
    validationError,
    isLoading,
    Icon,
  } = props;
  const { t } = useTranslation();

  return (
    <li
      className={cn(styles.item, { [styles['item--error']]: validationError })}
    >
      {isLoading && (
        <div className={styles.loading}>
          <Loader />
        </div>
      )}
      <FileItem
        {...('fileLink' in props
          ? { name: props.fileName, link: props.fileLink }
          : { name: props.fileName })}
        attachedLink={props.attachedLink}
        link={props.attachedLink}
        deleteFile={remove}
        index={index}
        additionalField={additionalField}
        className={styles.header}
        Icon={Icon}
      >
        {children}
        {validationError && (
          <Error message={t(validationError)} className={styles.error} />
        )}
      </FileItem>
    </li>
  );
};

export default FileCard;
