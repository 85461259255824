import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useFormContext, useController } from 'react-hook-form';

import type { ReactEditor } from 'slate-react';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';

import RichText from 'components/RichText';
import FocusedWrapper from 'components/RichText/FocusedWrapper';

import BlockHeading from 'components/CreateCampaign/Step1/ScriptBuilder/BlockHeading';

import { IconsNames } from 'constants/constants';

import styles from '../index.module.scss';

type Props = {
  htmlString?: string;
};

const Closing = ({ htmlString }: Props) => {
  const { t } = useTranslation();
  const closingEditorRef = useRef<ReactEditor>();
  const formSettings = useFormContext<CreateCampaignValues>();
  const { control } = formSettings || {};

  const isPreviewMode = !!htmlString;
  const { field: closingField } = !isPreviewMode
    ? useController({
        name: 'script_builder.closing_block',
        control,
      })
    : { field: null };

  const validationError =
    formSettings?.formState.errors.script_builder?.closing_block?.message;

  return (
    <>
      <BlockHeading
        iconName={IconsNames.hello_hand}
        iconClassName={styles.closing}
      >
        {t('script.closing')}
      </BlockHeading>

      <FocusedWrapper isPreviewMode={isPreviewMode}>
        {props =>
          isPreviewMode ? (
            <RichText htmlString={htmlString} />
          ) : (
            <RichText
              value={closingField?.value}
              onChange={val => closingField?.onChange(val)}
              editorRef={closingEditorRef}
              placeholder={t('script.closing')}
              validationError={validationError}
              {...props}
            />
          )
        }
      </FocusedWrapper>
    </>
  );
};

export default Closing;
