import { useParams } from 'react-router-dom';

import PageContainer from 'components/common/PageContainer';

import UploadErrorDetails from 'components/CampaignsListDeliveries/ContactsUploadHistory/UploadErrorDetails';

import useGetUploadDetails from 'components/CampaignsListDeliveries/ContactsUploadHistory/useGetUploadDetails';

import { PrivatePaths } from 'constants/routes';

const CampaignDeliveriesUploadsItem = () => {
  const { reportId, id } = useParams();
  const { data } = useGetUploadDetails({ campaignId: id, reportId });

  return (
    <PageContainer
      path={[data?.report_file_name || '']}
      backLinkPath={`/${PrivatePaths.CAMPAIGN_DELIVERIES}/${id}/${PrivatePaths.UPLOADS_HISTORY}`}
      isWhiteBackground
    >
      <UploadErrorDetails />
    </PageContainer>
  );
};

export default CampaignDeliveriesUploadsItem;
