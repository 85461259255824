import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from 'react-query';

import type { AxiosError } from 'axios';

import CampaignTable from 'components/CampaignDetails/InfoSectionWrapper';
import InputWrapper from 'components/common/InputWrapper';
import Button from 'components/common/Button';
import LoaderScreen from 'components/common/LoaderScreen';
import SelectEmail from 'components/common/SelectEmail';

import useAuth from 'contexts/AuthContext';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type FieldValues = {
  name: string;
  email?: string;
  users: { value: string; label: string }[];
};

export type AddGroupData = {
  name: string;
  users: string[];
};

import { validationSchemaEditGroup } from 'utils/validations';

const NewGroup = () => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();
  const queryClient = useQueryClient();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(validationSchemaEditGroup),
    mode: 'onBlur',
  });

  const { mutate, isLoading, error } = useMutation<
    AddGroupData,
    AxiosError,
    AddGroupData
  >(
    async groupData => {
      try {
        const companyId = user?.company.id;
        if (!companyId) throw new Error(t('common.error.missed-id'));

        const { data } = await axios.post(
          `/companies/${user.company.id}/user-groups/`,
          groupData
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries('groupsList');
        reset({ users: [], name: '' });
      },
    }
  );

  const submit = (data: FieldValues) => {
    const requestData = {
      name: data.name,
      users: data.users.map(item => item.value),
    };

    mutate(requestData);
  };
  const usersError = errors?.users?.message;

  return (
    <CampaignTable className={styles.wrapper}>
      <CampaignTable.Header>
        <CampaignTable.Title>{t('user-groups.new-group')}</CampaignTable.Title>
      </CampaignTable.Header>
      <CampaignTable.Content>
        <form className={styles.form} onSubmit={handleSubmit(submit)}>
          <InputWrapper
            label="common.field.group-name"
            validationError={errors.name?.message}
            submitError={error?.response?.data?.name[0]}
          >
            <input
              type="text"
              {...register('name')}
              placeholder={t('common.field.name')}
            />
          </InputWrapper>
          <InputWrapper
            label="common.field.user-email"
            validationError={usersError}
          >
            <Controller
              name="users"
              control={control}
              render={({ field }) => (
                <SelectEmail field={field} isError={!!usersError} />
              )}
            />
          </InputWrapper>
          <Button type="submit" iconProps={{ name: IconsNames.plus }}>
            {t('common.button.add-group')}
          </Button>
          {isLoading && <LoaderScreen />}
        </form>
      </CampaignTable.Content>
    </CampaignTable>
  );
};

export default NewGroup;
