import { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import type { InvitedUser, PaginationReturn } from 'types/models';

import InviteForm from 'components/InvitesContent/InviteForm';
import Users from 'components/InvitesContent/Users';
import VMUsersTable from 'components/InvitesContent/VMUsersTable';
import Loader from 'components/common/LoaderScreen/Loader';

import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import { UserTypes } from 'constants/constants';

import styles from './index.module.scss';

const PAGE_SIZE = 25;

const InvitesContent = () => {
  const { t } = useTranslation();
  const { axios, user } = useAuth();

  const getInvites = async (pageParam: string | number) => {
    let getPageParam;
    if (typeof pageParam === 'string') {
      const url = new URL(pageParam);
      getPageParam = url.searchParams.get('page');
    }
    const pageNumber = typeof pageParam === 'number' ? pageParam : getPageParam;
    try {
      const { data } = await axios.get(
        `/companies/${user?.company.id}/members/?page=${pageNumber}&page_size=${PAGE_SIZE}`
      );

      return data;
    } catch (err) {
      throw err;
    }
  };

  const isAdmin = !!user && user.current_group_name === UserTypes.admin;
  const isVM = !!user && user?.current_group_name === UserTypes.vendorManager;

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: invitesData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<PaginationReturn<InvitedUser>, AxiosError>(
    ['invitesList', user?.current_group_name],
    ({ pageParam = 1 }: { pageParam?: number | string }) =>
      getInvites(pageParam),
    {
      getNextPageParam: lastPage => (lastPage.next ? lastPage.next : undefined),
      enabled: Boolean(user?.company.id) && (isAdmin || isVM),
    }
  );

  return (
    <div>
      <InviteForm />
      {isLoading && (
        <div className={styles.wrapper}>
          <Loader />
        </div>
      )}
      {isError && (
        <div className={styles.wrapper}>
          <span className={styles.error}>
            {!!error && getResponseError(error)}
          </span>
          <span className={styles.message}>{t('common.error.try-again')}</span>
        </div>
      )}
      {isSuccess && isVM && (
        <VMUsersTable
          data={invitesData}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      )}
      {isSuccess && isAdmin && (
        <Users
          isFetchingNextPage={isFetchingNextPage}
          data={invitesData}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      )}
    </div>
  );
};

export default InvitesContent;
