import cn from 'classnames';

import IconSVG from 'components/UI/IconSVG';

import getLongStringView from 'helpers/getLongStringView';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props =
  | {
      name: string;
      deleteFile: ((index?: number) => void) | (() => void);
      additionalField?: React.ReactNode;
      index?: number;
      attachedLink?: string;
      className?: string;
      Icon?: React.ReactNode;
    }
  | {
      link: string;
      name: string;
      deleteFile: (index?: number) => void | (() => void);
      additionalField?: React.ReactNode;
      index?: number;
      attachedLink?: string;
      className?: string;
      Icon?: React.ReactNode;
    };

const FileItem: React.FC<Props> = props => {
  const { deleteFile, index, children, additionalField } = props;
  const deleteCurrentFile = () => {
    deleteFile(index);
  };

  const title = !!props.attachedLink ? props.attachedLink : props.name;

  return (
    <>
      <div className={cn(styles.item, props.className)}>
        {props.Icon}
        {'link' in props ? (
          <a
            className={styles.item__link}
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getLongStringView(title, 30)}
          </a>
        ) : (
          <span className={styles.item__name}>
            {getLongStringView(title, 30)}
          </span>
        )}

        {additionalField}
        <button
          onClick={deleteCurrentFile}
          type="button"
          className={styles.item__button}
        >
          <IconSVG name={IconsNames.close} />
        </button>
      </div>
      {children}
    </>
  );
};

export default FileItem;
