import { useTranslation } from 'react-i18next';
import { useRef, useMemo } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import type { CampaignResponse } from 'types/models';

import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import Table from 'components/common/Table';
import CampaignStatus from 'components/common/CampaignStatus';
import CopyCell from 'components/common/CampaignsTable/CopyCell';
import DownloadCell from 'components/common/CampaignsTable/DownloadCell';
import Button from 'components/common/Button';

import { PrivatePaths } from 'constants/routes';
import { CampaignStatuses } from 'constants/constants';

import useObserver from 'hooks/useObserver';
import useFetchСampaigns from 'hooks/api/useFetchСampaigns';

import useAuth from 'contexts/AuthContext';

import getResponseError from 'helpers/getResponseError';

import styles from './index.module.scss';

export enum TableAccessors {
  name = 'name',
  date = 'date',
  status = 'status',
  download = 'download',
  copy = 'copy',
  link = 'link',
  balance = 'balance',
}

const readOnlyTemplate = [
  {
    name: 'RedHat 0102',
    date: '06-01-21',
    status: CampaignStatuses.inProgress,
    isDownload: true,
    copy: {} as CampaignResponse,
    link: '',
  },
  {
    name: 'RedHat 0102',
    date: '06-01-21',
    status: CampaignStatuses.inProgress,
    isDownload: true,
    copy: {} as CampaignResponse,
    link: '',
  },
  {
    name: 'RedHat 0102',
    date: '06-01-21',
    status: CampaignStatuses.inProgress,
    isDownload: true,
    copy: {} as CampaignResponse,
    link: '',
  },
  {
    name: 'RedHat 0102',
    date: '06-01-21',
    status: CampaignStatuses.inProgress,
    isDownload: true,
    copy: {} as CampaignResponse,
    link: '',
  },
];

const CampaignsTable = ({ readOnly = false }: { readOnly?: boolean }) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { user } = useAuth();

  const {
    fetchNextPage,
    data: campaigns,
    isLoading,
    isFetchingNextPage,
    error,
  } = useFetchСampaigns(readOnly);

  const rowsData = useMemo(() => {
    if (readOnly) return readOnlyTemplate;

    return campaigns?.pages
      .map(page => page.results)
      .flat()
      .map(campaign => ({
        [TableAccessors.name]: campaign.campaign_details.name,
        [TableAccessors.date]: campaign.campaign_delivery?.start_date,
        [TableAccessors.status]: campaign.campaign_details.status,
        [TableAccessors.balance]: campaign.balance,
        [TableAccessors.download]:
          campaign.campaign_details.status === CampaignStatuses.finished &&
          campaign.id,
        [TableAccessors.copy]: campaign.id,
        [TableAccessors.link]:
          (campaign.campaign_details.status || CampaignStatuses.draft) ===
          CampaignStatuses.draft
            ? {
                path: `/${PrivatePaths.CAMPAIGN}/${PrivatePaths.CREATE}/${campaign.id}`,
                status: CampaignStatuses.draft,
              }
            : {
                path: `/${PrivatePaths.CAMPAIGN}/${campaign.id}`,
                status: campaign.campaign_details.status,
              },
      }));
  }, [campaigns]);

  const columnsData = useMemo(
    () => [
      {
        Header: t('common.field.campaign-name'),
        accessor: TableAccessors.name,
      },
      {
        Header: t('common.field.start-date'),
        accessor: TableAccessors.date,
      },
      {
        Header: t('common.field.status'),
        accessor: TableAccessors.status,
        Cell: ({ value }: { value: CampaignStatuses }) => (
          <CampaignStatus status={value} />
        ),
      },
      {
        Header: t('common.field.balance'),
        accessor: TableAccessors.balance,
        Cell: ({ value }: { value: string }) => (
          <span className={styles.balance}>{value}</span>
        ),
      },
      {
        Header: '',
        accessor: TableAccessors.download,
        Cell: ({ value }: { value: false | number }) =>
          value ? (
            <DownloadCell campaignId={value} readOnly={readOnly} />
          ) : null,
      },
      {
        Header: '',
        accessor: TableAccessors.copy,
        Cell: ({ value }: { value: number }) => (
          <CopyCell readOnly={readOnly} campaignId={value} />
        ),
      },
      {
        Header: '',
        accessor: TableAccessors.link,
        Cell: ({
          value,
        }: {
          value: { path: string; status: CampaignStatuses };
        }) => (
          <Link
            to={value.path}
            className={cn(styles.view, {
              [styles.disable]: readOnly,
            })}
          >
            <Button white>{t('common.button.view-details')}</Button>
          </Link>
        ),
      },
    ],
    [user]
  );

  useObserver(loadMoreRef, (isIntersecting: boolean) => {
    if (isIntersecting) fetchNextPage();
  });

  const isNoCampaigns = !isLoading && !rowsData?.length && !error;
  const isLoader = isLoading || isFetchingNextPage;

  return (
    <>
      {!isLoading && !!rowsData?.length && (
        <Table
          tableOptions={{ columns: columnsData, data: rowsData }}
          wrapperClassName={readOnly ? styles.table : undefined}
          headClassName={styles.thead}
          rowClassName={styles.row}
        />
      )}

      {error && <Error message={getResponseError(error)} />}
      {isLoader && <Loader className={styles.loader} />}
      {isNoCampaigns && <p>{t('campaign.no-campaigns')}</p>}
      <div ref={loadMoreRef} />
    </>
  );
};

export default CampaignsTable;
