import { useTranslation } from 'react-i18next';

import Loader from 'components/common/LoaderScreen/Loader';
import Button from 'components/common/Button';

import useGetReportTemplateDownload from 'hooks/api/useGetReportTemplateDownload';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  readOnly: boolean;
  campaignId: number;
};

const DownloadCell = ({ readOnly, campaignId }: Props) => {
  const { t } = useTranslation();

  const { isLoading: isLoadingDownloadReport, refetch } =
    useGetReportTemplateDownload(campaignId);

  const handleDownloadReport = () => {
    refetch();
  };

  return isLoadingDownloadReport ? (
    <Loader className={styles.loader} size={20} />
  ) : (
    <Button
      iconProps={{
        name: IconsNames.download,
        isRightPosition: true,
      }}
      type="button"
      onClick={handleDownloadReport}
      disabled={readOnly}
      isSmall
    >
      {t('common.button.download')}
    </Button>
  );
};

export default DownloadCell;
