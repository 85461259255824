import cn from 'classnames';

import styles from './index.module.scss';

const TwoFATitle: React.FC<{ className?: string }> = ({
  children,
  className,
}) => <h4 className={cn(styles.title, className)}>{children}</h4>;

export default TwoFATitle;
