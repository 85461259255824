import { useSlateStatic, useReadOnly } from 'slate-react';
import { Transforms } from 'slate';
import { useState, useRef } from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';

import type { RenderElementProps } from 'slate-react';

import VariablesList from 'components/RichText/VariableList';
import { findNodePath } from 'utils/richTextEditor';

import styles from './index.module.scss';

const Variable = ({
  attributes,
  children,
  element,
  containerRef,
}: RenderElementProps & {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const [isListVisible, setListVisibility] = useState(false);
  const isReadOnly = useReadOnly();
  const editor = useSlateStatic();
  const variableRef = useRef<HTMLSpanElement>(null);

  const selectVariable = (variable: string) => {
    if (isReadOnly) return;
    const path = findNodePath(editor, element);

    const newProperties = {
      ...element,
      value: variable,
    };
    Transforms.setNodes(editor, newProperties, { at: path });
    editor.onChange();

    setListVisibility(false);
  };

  const toggleList = () => {
    setListVisibility(prev => {
      if (isReadOnly) return false;
      return !prev;
    });
  };

  const containerBoundingClient = containerRef.current?.getBoundingClientRect();
  const variablePosition = variableRef.current?.getBoundingClientRect();

  return (
    <span className={styles.wrapper} ref={variableRef}>
      <span
        {...attributes}
        className={cn(styles.variable, { [styles.active]: isListVisible })}
        contentEditable={false}
        onClick={toggleList}
      >
        {element.value}
      </span>
      {isListVisible &&
        containerRef.current &&
        ReactDOM.createPortal(
          <VariablesList
            selectVariable={selectVariable}
            toggleList={toggleList}
            setListVisibility={setListVisibility}
            selectedVariable={element.value}
            style={{
              left:
                (variablePosition?.left || 0) -
                (containerBoundingClient?.left || 0),
              top:
                (variablePosition?.bottom || 0) -
                (containerBoundingClient?.top || 0) +
                14,
            }}
          />,
          containerRef.current
        )}
      {children}
    </span>
  );
};

export default Variable;
