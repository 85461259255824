import { Outlet } from 'react-router-dom';

import styles from './index.module.scss';

const AppContainer = () => {
  return (
    <div className={styles.container}>
      <Outlet />
    </div>
  );
};

export default AppContainer;
