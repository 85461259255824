import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BeeLeadsWhiteSVG from 'assets/images/common/beeleads-logo-white.svg';

import authBanner from 'assets/images/common/rocket-man.svg';

import styles from './index.module.scss';

const AuthLayout = () => {
  const { t } = useTranslation();

  return (
    <main className={styles.main}>
      <section className={styles.banner}>
        <div className={styles.logoWrapper}>
          <img src={BeeLeadsWhiteSVG} alt="" className={styles.logo} />
        </div>
        <h2 className={styles.title}>
          {t('auth.lead-generation')}
          <span>{t('auth.free')}</span>
        </h2>
        <img
          alt="rocket"
          src={authBanner}
          aria-hidden
          className={styles.image}
        />
      </section>
      <section className={styles.content}>
        <Outlet />
      </section>
    </main>
  );
};

export default AuthLayout;
