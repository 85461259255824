import { useFormContext, useController } from 'react-hook-form';
import type { MultiValue } from 'react-select';
import type { CreateCampaignValues } from 'components/CreateCampaign/models';
import type { SelectOption } from 'types/models';

import SelectEmail from 'components/common/SelectEmail';

const DeliveryEmailsField = () => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<CreateCampaignValues>();
  const { field } = useController({
    name: 'campaign_details.delivery_emails',
    control,
  });

  const handleChange = (newDeliveryEmails: MultiValue<SelectOption>) => {
    const currentUserGroupEmails = getValues(
      'campaign_details.owner_user_group'
    )?.value.users;

    if (currentUserGroupEmails && !newDeliveryEmails.length) {
      setValue('campaign_details.owner_user_group', null);
      field.onChange(newDeliveryEmails);
      return;
    }

    let isUserGroupSelected = false;

    currentUserGroupEmails?.every(email => {
      isUserGroupSelected = newDeliveryEmails.some(
        item => item.value === email
      );
      if (isUserGroupSelected) return false;
    });

    if (!isUserGroupSelected) {
      setValue('campaign_details.owner_user_group', null);
    }

    field.onChange(newDeliveryEmails);
  };

  return (
    <SelectEmail
      field={{ ...field, onChange: handleChange }}
      isError={!!errors?.campaign_details?.delivery_emails}
      isSmall
    />
  );
};

export default DeliveryEmailsField;
