import { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import type { InvitedUser, PaginationReturn } from 'types/models';
import type {
  InfiniteData,
  InfiniteQueryObserverResult,
  FetchNextPageOptions,
} from 'react-query';
import type { AxiosError } from 'axios';

import InvitesAction from 'components/common/InvitesAction';
import Status from 'components/common/UserStatus';
import Loader from 'components/common/LoaderScreen/Loader';
import Table from 'components/common/Table';

import useObserver from 'hooks/useObserver';

import { UserStatuses, UserTypes } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  data: InfiniteData<PaginationReturn<InvitedUser>> | undefined;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<
    InfiniteQueryObserverResult<PaginationReturn<InvitedUser>, AxiosError>
  >;
};

enum TableAccessors {
  date = 'date',
  name = 'name',
  email = 'email',
  role = 'role',
  user = 'user',
  status = 'status',
  action = 'action',
}

const Users = ({
  data,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
}: Props) => {
  const { t } = useTranslation();

  const loadMoreRef = useRef<HTMLDivElement>(null);

  const getNextPage = (isIntersecting: boolean) => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  };

  const rowsData = useMemo(() => {
    return data?.pages
      .map(page => page.results)
      .flat()
      .map(user => ({
        [TableAccessors.name]: `${user.first_name || ''} ${
          user.last_name || ''
        }`,
        [TableAccessors.date]: user.created_at,
        [TableAccessors.status]: user.status,
        [TableAccessors.email]: user.email,
        [TableAccessors.role]: user.current_group,
        [TableAccessors.user]: user.invited_by.first_name,
        [TableAccessors.action]: {
          status: user.status,
          email: user.email,
          id: user.id,
        },
      }));
  }, [data]);

  const columnsData = useMemo(
    () => [
      {
        Header: t(`common.field.${TableAccessors.date}`),
        accessor: TableAccessors.date,
        Cell: ({ value }: { value: string }) =>
          dayjs(value).format('DD/MM/YYYY'),
      },
      {
        Header: t(`common.field.${TableAccessors.name}`),
        accessor: TableAccessors.name,
      },
      {
        Header: t(`common.field.${TableAccessors.email}`),
        accessor: TableAccessors.email,
      },
      {
        Header: t(`common.field.${TableAccessors.role}`),
        accessor: TableAccessors.role,
        Cell: ({ value }: { value: UserTypes }) => t(`user.type.${value}`),
      },
      {
        Header: t(`common.field.${TableAccessors.user}`),
        accessor: TableAccessors.user,
      },
      {
        Header: t(`common.field.${TableAccessors.status}`),
        accessor: TableAccessors.status,
        Cell: ({ value }: { value: UserStatuses }) => <Status status={value} />,
      },
      {
        Header: t(`common.field.${TableAccessors.action}`),
        accessor: TableAccessors.action,
        Cell: ({
          value: { id, email, status },
        }: {
          value: { status: UserStatuses; id: number; email: string };
        }) => <InvitesAction email={email} id={id} status={status} />,
      },
    ],
    []
  );

  useObserver(loadMoreRef, getNextPage);

  return (
    <>
      {!!rowsData?.length && (
        <Table
          tableOptions={{ columns: columnsData, data: rowsData }}
          wrapperClassName={styles.table}
          headClassName={styles.thead}
        />
      )}

      <div ref={loadMoreRef} />
      {isFetchingNextPage && <Loader />}
      {!rowsData?.length && <p>{t('invites.no-users')}</p>}
    </>
  );
};

export default Users;
