import { useQuery } from 'react-query';

import type { AxiosError } from 'axios';
import type { UseQueryOptions } from 'react-query';
import type { CampaignScript } from 'types/models';

import useAuth from 'contexts/AuthContext';

interface Props
  extends Omit<
    UseQueryOptions<CampaignScript, AxiosError>,
    'queryKey' | 'queryFn'
  > {
  campaignId?: string | number;
}

export const useGetCampaignScript = ({ campaignId, ...options }: Props) => {
  const { axios } = useAuth();
  const query = useQuery<CampaignScript, AxiosError>(
    ['campaign-script-builder', campaignId],
    async () => {
      try {
        const { data } = await axios.get(
          `/campaigns/${campaignId}/script-builder/`
        );

        return data;
      } catch (err) {
        return err;
      }
    },
    options
  );

  return query;
};
