import type { AssetValues } from 'components/CreateCampaign/models';
import type { CampaignAssetFileResponse } from 'types/models';

import ModalWrapper from 'components/CreateCampaign/Step3/ModalWrapper';

import generateHtmlTemplate from 'utils/generateHtmlTemplate';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import { ReactComponent as FullScreenSVG } from 'assets/images/common/full-screen.svg';

import styles from './index.module.scss';

type SavedViewProps = {
  data: AssetValues | CampaignAssetFileResponse;
  campaignId?: number;
  campaignName?: string;
};
type HTMLPreviewProps = SavedViewProps & { className?: string };

export const HTMLPreview = ({
  data,
  className,
  campaignId,
  campaignName,
}: HTMLPreviewProps) => {
  const { user } = useAuth();

  return user &&
    'slug' in data &&
    typeof data.html_template_logo === 'string' &&
    typeof data.html_template_image === 'string' ? (
    <div
      dangerouslySetInnerHTML={{
        __html: generateHtmlTemplate({
          logoSrc: data.html_template_logo,
          imageSrc: data.html_template_image,
          content: data.html_template_content_para,
          footerContent: data.html_template_footer_content,
          heading: data.html_template_heading,
          subHeading: data.html_template_sub_heading,
          privacyLink: data.html_template_privacy_value || '',
          companySlug: data.company_slug,
          assetSlug: data.slug,
          productSlug: data.product_slug,
          buttonName: data.attached_file_link_name,
          assetType: data.asset_type,
          campaignId,
          campaignName,
        }),
      }}
      className={className}
    />
  ) : null;
};

const SavedView = ({ data, campaignId, campaignName }: SavedViewProps) => {
  const { openModal } = useModal();

  const handleFullScreenOpen = () =>
    openModal({
      Content: (
        <ModalWrapper>
          <HTMLPreview
            data={data}
            className={styles.html}
            campaignId={campaignId}
            campaignName={campaignName}
          />
        </ModalWrapper>
      ),
    });

  return (
    <div className={styles.container}>
      <HTMLPreview
        data={data}
        campaignName={campaignName}
        campaignId={campaignId}
      />
      <button
        type="button"
        className={styles.button}
        onClick={handleFullScreenOpen}
      >
        <FullScreenSVG className={styles.icon} />
      </button>
    </div>
  );
};

export default SavedView;
