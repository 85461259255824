import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import Button from 'components/common/Button';

import ReportModal from 'components/CampaignManage/VendorSettings/ReportModal';

import useAside from 'contexts/AsideContext';
import { useGetUploadedReport } from 'components/CampaignManage/VendorSettings/useGetUploadedReport';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  campaignId: number;
};

const Report = ({ campaignId }: Props) => {
  const { t } = useTranslation();
  const { setAsideContent, AsideContent } = useAside();
  useEffect(() => () => setAsideContent(null), []);
  const [processedFileId, setProcessedFileId] = useState<null | number>(null);

  useGetUploadedReport({
    campaignId,
    processedFileId,
    setProcessedFileId,
    enabled: !AsideContent,
  });

  const openReportModal = () => {
    setAsideContent(() => (
      <ReportModal
        campaignId={campaignId}
        setParentProcessedFileId={setProcessedFileId}
      />
    ));
  };

  return (
    <div className={styles.wrapper}>
      <Button
        type="button"
        onClick={openReportModal}
        iconProps={{ name: IconsNames.right_arrow, isRightPosition: true }}
      >
        {t('common.button.report')}
      </Button>
    </div>
  );
};

export default Report;
