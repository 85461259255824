import { useTranslation } from 'react-i18next';

import type { UseQueryResult } from 'react-query';
import type { AxiosError } from 'axios';
import type { ReportTemplateResponse } from 'types/models';

import SavedField from 'components/common/FileCard/SavedField';
import Loader from 'components/common/LoaderScreen/Loader';

import styles from './index.module.scss';

const ReportMapping = ({
  getReportTemplateRequestSettings: { data, isLoading },
}: {
  getReportTemplateRequestSettings: UseQueryResult<
    ReportTemplateResponse | undefined,
    AxiosError
  >;
}) => {
  const { t } = useTranslation();

  const reportEntries = data && Object.entries(data);

  return (
    <>
      <h4 className={styles.subheading}>{t('common.field.report-template')}</h4>
      {isLoading && <Loader className={styles.loader} />}
      <ul className={styles.mapping}>
        {reportEntries?.map(([key, value], index) => {
          const isNotMappingFields =
            key === 'report_template' ||
            key === 'report_template_name' ||
            key === 'headers' ||
            key === 'custom_fields';

          return (
            !isNotMappingFields &&
            value && (
              <li key={index} className={styles.item}>
                <SavedField
                  fieldName={t(`common.field.${key.replace(/_/g, '-')}`)}
                  value={value}
                />
              </li>
            )
          );
        }) || '—'}
      </ul>
    </>
  );
};

export default ReportMapping;
