import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useForm, useController, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { yupResolver } from '@hookform/resolvers/yup';

import type { AxiosError } from 'axios';
import type {
  CampaignResponse,
  CampaignAssetFileResponse,
  OfferResponse,
} from 'types/models';
import type {
  AssignRequestBody,
  OffersFormValues,
} from 'components/CampaignManage/models';

import LoaderScreen from 'components/common/LoaderScreen';
import Loader from 'components/common/LoaderScreen/Loader';
import Error from 'components/common/Error';
import ModalWindow from 'components/common/ModalWindow';
import ReportedLeadsTable from 'components/common/ReportedLeadsTable';

import VendorOfferItem from 'components/CampaignManage/OfferItem/VendorOfferItem';
import Report from 'components/CampaignManage/VendorSettings/Report';

import { OfferStatuses, UserTypes } from 'constants/constants';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import getResponseError from 'helpers/getResponseError';
import { convertOfferResponseToFormValues } from 'helpers/offers';

import { validationSchemaReBidVendorOffer } from 'utils/validations';

import styles from './index.module.scss';

type Props = {
  campaign: CampaignResponse;
  assets: CampaignAssetFileResponse[];
};

const VendorSettings = ({ campaign, assets }: Props) => {
  const { user, axios } = useAuth();
  const queryClient = useQueryClient();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const offersFormMethods = useForm<OffersFormValues>({
    defaultValues: {
      offers: [],
    },
    mode: 'onSubmit',
    resolver: yupResolver(validationSchemaReBidVendorOffer),
  });
  const { field: offersFields } = useController({
    control: offersFormMethods.control,
    name: 'offers',
  });

  const onError = (err: AxiosError) => {
    openModal({
      Content: (
        <ModalWindow
          title={t('common.error.something-went-wrong')}
          errorMessage={getResponseError(err)}
        />
      ),
    });
  };

  const vendorOffersQueryKey = [
    'vendor-offers',
    user?.id.toString(),
    campaign.id.toString(),
  ];

  const updateOfferAfterSubmit = async (offer: OfferResponse) => {
    const offersCache = queryClient.getQueryData<OfferResponse[]>([
      'vendor-offers',
      user?.id.toString(),
      campaign.id.toString(),
    ]);
    if (offersCache) {
      const offerIndex = offersCache?.findIndex(item => item.id === offer.id);
      const updatedOffers = [...(offersCache || [])];
      updatedOffers[offerIndex] = offer;
      offersFormMethods.reset({
        offers: updatedOffers.map(item =>
          convertOfferResponseToFormValues({ offer: item, campaign })
        ),
      });
    }
    await queryClient.invalidateQueries(vendorOffersQueryKey);
    queryClient.invalidateQueries('vendor-campaigns-list');
  };

  const {
    data: offersData,
    isLoading: isOffersLoading,
    error: getOffersError,
  } = useQuery<OfferResponse[], AxiosError>(
    vendorOffersQueryKey,
    async () => {
      try {
        const { data } = await axios.get<OfferResponse[]>(
          `/vendor-campaigns/${campaign.id}/offers/`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: offers => {
        offersFormMethods.reset({
          offers: offers.map(offer =>
            convertOfferResponseToFormValues({ offer, campaign })
          ),
        });
      },
      enabled:
        !!campaign.id &&
        !!user?.id &&
        user.current_group_name === UserTypes.vendor,
      refetchOnMount: true,
    }
  );

  const bidMutation = useMutation<
    OfferResponse,
    AxiosError,
    AssignRequestBody['values']
  >(
    async values => {
      try {
        const requestBody = {
          ...values,
          campaign_offer_milestones: values.campaign_offer_milestones.map(
            item => ({
              leads_required_vendor: item.leads_required_vendor,
              milestone: item.milestone,
              campaign_offer_assets: item.campaign_offer_assets?.map(asset => ({
                leads_required_vendor: asset.leads_required_vendor,
                asset: asset.asset,
              })),
            })
          ),
        };

        const { data } = await axios.post<OfferResponse>(
          `/vendor-campaigns/${campaign.id}/offers/${values.id}/rebid/`,
          requestBody
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: offer => {
        updateOfferAfterSubmit(offer);
        queryClient.setQueryData(
          ['vendor-campaigns-list', user?.current_group_name],
          undefined
        );
      },
      onError,
    }
  );

  const acceptMutation = useMutation<OfferResponse, AxiosError, number>(
    async (offerId: number) => {
      try {
        const { data } = await axios.post<OfferResponse>(
          `/vendor-campaigns/${campaign.id}/offers/${offerId}/accept/`
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: updateOfferAfterSubmit,
      onError,
    }
  );

  const acceptedOffers = offersData?.filter(
    offer => offer.status === OfferStatuses.accepted
  );

  const canceledOffers = offersData?.filter(
    offer => offer.status === OfferStatuses.canceled
  );

  const finishedOffers = offersData?.filter(
    offer => offer.status === OfferStatuses.finished
  );

  const isLoaderScreenVisible =
    bidMutation.isLoading || acceptMutation.isLoading;
  const isDemoCampaign = campaign.campaign_details.cost_per_lead === 0;

  return (
    <>
      <div className={styles.wrapper}>
        {!!offersFields?.value.length && (
          <FormProvider {...offersFormMethods}>
            <form className={styles.offers}>
              {offersFields.value.map((offerData, index) => {
                const offerResponseData = offersData?.find(
                  offer => offer.id === offerData.value.id
                );
                const offerInitialData = offerResponseData
                  ? convertOfferResponseToFormValues({
                      offer: offerResponseData,
                      campaign,
                    })
                  : undefined;

                return (
                  <VendorOfferItem
                    status={offerData.value.status}
                    key={offerData.value.id}
                    campaign={campaign}
                    assets={assets}
                    bidMutation={bidMutation}
                    acceptMutation={acceptMutation}
                    index={index}
                    offerId={offerData.value.id}
                    offerInitialData={offerInitialData?.value}
                  />
                );
              })}
            </form>
          </FormProvider>
        )}

        {isOffersLoading && <Loader className={styles.loading} />}
        {getOffersError && <Error message={getResponseError(getOffersError)} />}
        {isLoaderScreenVisible && <LoaderScreen />}
      </div>

      {(!!acceptedOffers?.length ||
        !!canceledOffers?.length ||
        !!finishedOffers?.length) && (
        <>
          {!isMobile && !isDemoCampaign && !!acceptedOffers?.length && (
            <Report campaignId={campaign.id} />
          )}
          <ReportedLeadsTable
            campaign={campaign}
            acceptedOffers={acceptedOffers}
            canceledOffers={canceledOffers}
          />
        </>
      )}
    </>
  );
};

export default VendorSettings;
