import { ChangeEvent, FC, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import type { SingleValue } from 'react-select';
import type { SelectOption } from 'types/models';

import Button from 'components/common/Button';
import { FilterItem } from 'components/common/ReportedLeadsTable/LeadDetailsFilter';

import IconSVG from 'components/UI/IconSVG';

import useEscapeEvent from 'hooks/useEscapeEvent';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type FilterCardProps = {
  active: boolean;
  filterItem: FilterItem;
  closeHandler: () => void;
  applyHandler: (newFilterItem: FilterItem) => void;
};

const FilterCard: FC<FilterCardProps> = ({
  active,
  closeHandler,
  filterItem,
  applyHandler,
}) => {
  const value = filterItem.value || '';
  const defaultSelectValue = filterItem.value
    ? {
        value: filterItem.value,
        label: filterItem.value,
      }
    : null;
  const [inputValue, setInputValue] = useState<string>(value);
  const [selectValue, setSelectValue] =
    useState<SingleValue<SelectOption>>(defaultSelectValue);
  const { t } = useTranslation();
  const cardRef = useRef(null);

  const closeFilterHandler = () => {
    setInputValue('');
    closeHandler();
  };
  useEscapeEvent(closeFilterHandler);
  useClickAway(cardRef, closeFilterHandler);

  useEffect(() => {
    setInputValue(value);
    setSelectValue(defaultSelectValue);
  }, [filterItem]);

  const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  const applyFilterHandler = () => {
    const isSelector =
      filterItem.type === 'revenue' || filterItem.type === 'employee';
    applyHandler({
      ...filterItem,
      value: isSelector ? selectValue?.label : inputValue,
    });
    setSelectValue(null);
    setInputValue('');
    closeHandler();
  };

  return (
    <div
      className={cn(styles.wrapper, { [styles.active]: active })}
      ref={cardRef}
    >
      <div className={styles.header}>
        <h4 className={styles.title}>
          {t(`common.field.${filterItem.label}`)}
        </h4>
        <span onClick={closeFilterHandler} className={styles.close}>
          <IconSVG name={IconsNames.delete} />
        </span>
      </div>
      <div className={styles.body}>
        <input
          value={inputValue}
          onChange={inputChangeHandler}
          className={styles.input}
          autoComplete="filter-value"
          type="text"
        />
      </div>
      <div className={styles.footer}>
        <Button
          disabled={inputValue.length === 0 && !selectValue}
          onClick={applyFilterHandler}
          className={styles.button}
        >
          {t('common.button.apply')}
        </Button>
      </div>
    </div>
  );
};

export default FilterCard;
