import { FC, Fragment } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import cn from 'classnames';

import type { ActionMeta } from 'react-select';
import type { CampaignAssetFileResponse, ValidationError } from 'types/models';
import type {
  OffersFormValues,
  CampaignOfferAsset,
} from 'components/CampaignManage/models';

import InputWrapper from 'components/common/InputWrapper';
import NumberField from 'components/common/NumberField';
import Error from 'components/common/Error';

import IconSVG from 'components/UI/IconSVG';

import { getDefaultSelectStylesWithError } from 'utils/selectStyles';

import { IconsNames } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  isEditable?: boolean;
  offerIndex: number;
  index: number;
  assets: CampaignAssetFileResponse[];
  milestoneId: number;
  difference: number;
  leadsFieldName: 'leads_required' | 'leads_required_vendor';
  renderMilestoneAssetItem?: (options: {
    asset: CampaignOfferAsset;
    assetIndex: number;
    isError: boolean;
  }) => React.ReactNode;
  isAssetsSelectorHidden?: boolean;
  isSaveNullableAssets?: boolean;
};

const MilestoneItem: FC<Props> = ({
  isEditable = false,
  children,
  index,
  offerIndex,
  assets,
  difference,
  leadsFieldName,
  renderMilestoneAssetItem,
  isAssetsSelectorHidden = false,
  isSaveNullableAssets = false,
}) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext<OffersFormValues>();
  const {
    fields: selectedAssets,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `offers.${offerIndex}.value.campaign_offer_milestones.${index}.campaign_offer_assets`,
  });

  const isOnlyOneCampaignAsset = assets.length === 1;
  const milestoneRequestedLeads = watch(
    `offers.${offerIndex}.value.campaign_offer_milestones.${index}.${leadsFieldName}`
  );

  const handleSelectChange = (
    meta: ActionMeta<{ value: number; label: string }>
  ) => {
    if (meta.action === 'remove-value') {
      const removedValue = selectedAssets.findIndex(
        item => item.asset === meta.removedValue.value
      );
      remove(removedValue);
    }

    if (meta.action === 'select-option' && meta.option) {
      append({
        asset: meta.option.value,
        leads_required_vendor: 0,
        leads_required: 0,
        asset_title: meta.option.label,
        ...(isOnlyOneCampaignAsset
          ? { [leadsFieldName]: milestoneRequestedLeads }
          : {}),
      });
    }

    if (meta.action === 'clear') remove();
  };

  const isAssetsFieldAvailable =
    !!milestoneRequestedLeads && milestoneRequestedLeads > 0;

  const isLeadsRequiredNegative = isEditable && difference < 0;

  const isErrors =
    errors.offers?.[offerIndex]?.value?.campaign_offer_milestones;
  const isMilestoneErrors = isErrors?.[index];

  const isLeadsRequiredError = (isMilestoneErrors?.leads_required?.message ||
    isMilestoneErrors?.leads_required_vendor?.message) as
    | string
    | ValidationError;
  const translatedStringError =
    typeof isLeadsRequiredError === 'string' && t(isLeadsRequiredError);
  const assetsLeadsSumError =
    isLeadsRequiredError &&
    typeof isLeadsRequiredError !== 'string' &&
    t(isLeadsRequiredError?.key || '', isLeadsRequiredError?.values);

  const isSelectedAssetsError = isMilestoneErrors?.selected_assets?.message;

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>{children}</h4>

      <Controller
        name={`offers.${offerIndex}.value.campaign_offer_milestones.${index}.${leadsFieldName}`}
        control={control}
        defaultValue={0}
        render={({ field }) => (
          <InputWrapper
            label="campaign.lead-required"
            isSmallInput
            wrapperClasses={cn(styles.leads, {
              [styles['label-error']]: isLeadsRequiredNegative,
            })}
            validationError={translatedStringError || undefined}
          >
            <div className={styles.leads_field}>
              <NumberField
                field={{
                  ...field,
                  onChange: value => {
                    if (isErrors && isErrors.message) {
                      trigger(
                        `offers.${offerIndex}.value.campaign_offer_milestones`
                      );
                    }
                    if (assetsLeadsSumError) {
                      trigger(
                        `offers.${offerIndex}.value.campaign_offer_milestones.${index}.${leadsFieldName}`
                      );
                    }

                    const isEmptyValue = Number(value) === 0;
                    const isSetAssetAutomatically =
                      isOnlyOneCampaignAsset &&
                      !isEmptyValue &&
                      !selectedAssets.length;

                    if (isSetAssetAutomatically) {
                      const { id, title } = assets[0];
                      append({
                        asset: id,
                        asset_title: title,
                        [leadsFieldName]: value,
                      });
                      setValue(
                        `offers.${offerIndex}.value.campaign_offer_milestones.${index}.selected_assets`,
                        [{ value: id, label: title }]
                      );
                    }
                    const isSetAssetLeadsRequiredAutomatically =
                      (isOnlyOneCampaignAsset &&
                        !isEmptyValue &&
                        selectedAssets.length) ||
                      (isSaveNullableAssets && isEmptyValue);
                    if (isSetAssetLeadsRequiredAutomatically) {
                      selectedAssets.forEach((asset, assetIdx) => {
                        setValue(
                          `offers.${offerIndex}.value.campaign_offer_milestones.${index}.campaign_offer_assets.${assetIdx}.${leadsFieldName}`,
                          Number(value)
                        );
                      });
                    }

                    const isResetSelectedAssets =
                      isEmptyValue && !isSaveNullableAssets;

                    if (isResetSelectedAssets) {
                      setValue(
                        `offers.${offerIndex}.value.campaign_offer_milestones.${index}.campaign_offer_assets`,
                        []
                      );
                      setValue(
                        `offers.${offerIndex}.value.campaign_offer_milestones.${index}.selected_assets`,
                        null
                      );
                    }

                    field.onChange(value);
                  },
                }}
                disabled={!isEditable}
                max={difference + (field?.value || 0)}
              />
              {isEditable && (
                <span
                  className={cn(styles.difference, {
                    [styles.error]: isLeadsRequiredNegative,
                  })}
                  aria-label={`offer ${offerIndex} milestone ${index} remainder`}
                >
                  /{difference}
                  {isLeadsRequiredNegative && (
                    <IconSVG name={IconsNames.error} className={styles.icon} />
                  )}
                </span>
              )}
            </div>
          </InputWrapper>
        )}
      />

      {isAssetsFieldAvailable && (
        <div className={styles.assets}>
          {isEditable && !isAssetsSelectorHidden && !isOnlyOneCampaignAsset && (
            <div className={styles.assets_item}>
              <InputWrapper
                label="campaign.asset-name"
                wrapperClasses={styles.select}
                validationError={isSelectedAssetsError || undefined}
              >
                <Controller
                  control={control}
                  name={`offers.${offerIndex}.value.campaign_offer_milestones.${index}.selected_assets`}
                  render={({ field }) => (
                    <Select
                      isMulti
                      options={assets?.map(asset => ({
                        value: asset.id,
                        label: asset.title,
                      }))}
                      styles={getDefaultSelectStylesWithError({
                        isSmall: true,
                        error: !!isSelectedAssetsError,
                      })}
                      placeholder={t('common.field.select')}
                      {...field}
                      onChange={(value, meta) => {
                        handleSelectChange(meta);
                        field.onChange(value);
                        if (isSelectedAssetsError) {
                          trigger(
                            `offers.${offerIndex}.value.campaign_offer_milestones.${index}.selected_assets`
                          );
                        }
                      }}
                    />
                  )}
                />
              </InputWrapper>
            </div>
          )}
          {selectedAssets.map((asset, assetIndex) => (
            <Fragment key={asset.id}>
              {renderMilestoneAssetItem ? (
                renderMilestoneAssetItem({
                  asset,
                  assetIndex,
                  isError: !!assetsLeadsSumError,
                })
              ) : (
                <span className={styles.asset}>{asset.asset_title}</span>
              )}
            </Fragment>
          ))}
          {!!assetsLeadsSumError && <Error message={assetsLeadsSumError} />}
        </div>
      )}
    </div>
  );
};

export default MilestoneItem;
