import { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type { Area, Size, CropperProps } from 'react-easy-crop';

import UploadFile from 'components/common/UploadFile';

import styles from './index.module.scss';

const ZOOM_STEP = 0.01;
const ZOOM_MIN = 0.01;
const ZOOM_MAX = 3;

export type Props = {
  image?: string | ArrayBuffer | null;
  imageHeight: number;
  imageWidth: number;
  setCroppedArea: React.Dispatch<React.SetStateAction<Area | null>>;
  value?: { file?: File | null; renderLink?: string };
  uploadImage?: (
    value: { file?: File | null; renderLink?: string } | null
  ) => void;
  className?: string;
  controllerClassName?: string;
  cropSize?: Size;
  objectFit?: CropperProps['objectFit'];
};

const ImageCropper = ({
  image,
  imageWidth,
  imageHeight,
  setCroppedArea,
  uploadImage,
  value,
  className,
  controllerClassName,
  cropSize,
  objectFit,
}: Props) => {
  const { t } = useTranslation();
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const onCropComplete = useCallback(
    (croppedAreaPercentage: Area, croppedAreaPixels: Area) => {
      setCroppedArea(croppedAreaPixels);
    },
    []
  );

  const handleScale = (e: React.ChangeEvent<HTMLInputElement>) => {
    const scaleValue = parseFloat(e.target.value);
    setZoom(scaleValue);
  };

  const onPlus = () => {
    if (zoom >= ZOOM_MAX - ZOOM_STEP) return;
    setZoom(prev => prev + ZOOM_STEP);
  };

  const onMinus = () => {
    if (zoom <= ZOOM_MIN + ZOOM_STEP) return;
    setZoom(prev => prev - ZOOM_STEP);
  };

  const isUploadFile = uploadImage && !value?.file;

  return (
    <>
      <div className={cn(styles.editorWrapper, className)}>
        {(value?.file || image) && (
          <Cropper
            image={value?.renderLink || (image as string)}
            crop={crop}
            zoom={zoom}
            aspect={imageWidth / imageHeight}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            restrictPosition={false}
            cropSize={cropSize}
            objectFit={objectFit}
          />
        )}
        {isUploadFile && (
          <UploadFile
            accept={['image/jpeg', 'image/png', 'image/svg']}
            imageSrc={value?.renderLink}
            wrapperClasses={styles.dropzone}
            secondaryTitle={t('common.button.upload')}
            onChangeOptions={{
              maxFiles: 1,
              insertSingle: (file, link) =>
                uploadImage({ file, renderLink: link as string }),
              withPreview: true,
            }}
          />
        )}
      </div>
      {!isUploadFile && (
        <div className={cn(styles.zoomWrapper, controllerClassName)}>
          <div className={styles.minus} onClick={onMinus}>
            <span />
          </div>
          <div className={styles.inputWrapper}>
            <input
              name="zoom"
              type="range"
              onChange={handleScale}
              min={ZOOM_MIN}
              max={ZOOM_MAX}
              step={ZOOM_STEP}
              value={zoom}
              className={styles.zoom}
            />
            <div
              className={styles.progressBar}
              style={{
                width: `${((zoom - ZOOM_MIN) / (ZOOM_MAX - ZOOM_MIN)) * 100}%`,
              }}
            />
          </div>
          <div className={styles.plus} onClick={onPlus}>
            <span />
            <span />
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCropper;
